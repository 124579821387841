<div class="containter">
  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide>
      <div class="card">
        <div class="card-body">
          <tr>
            <td class="word-blue"><strong>$ Drop Size</strong></td>
          </tr>
          <tr>
            <td class="word-black">
              <strong> $ {{ currentMonthDollar }}</strong>
            </td>
          </tr>
          <div *ngIf="currentMonthDollar < pastMonthDollar">
            <tr>
              <td class="word-red">
                <strong
                  >$ {{ calculate(currentMonthDollar, pastMonthDollar) }} ({{
                    calculateDropSize
                  }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="pastMonthDollar"
              [value]="currentMonthDollar"
              color="#E31818"
            ></app-bullet>
          </div>
          <div *ngIf="currentMonthDollar >= pastMonthDollar">
            <tr>
              <td class="word-green">
                <strong
                  >$ {{ calculate(currentMonthDollar, pastMonthDollar) }} (+{{
                    calculateDropSize
                  }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="pastMonthDollar"
              [value]="currentMonthDollar"
              color="#2DF119"
            ></app-bullet>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card">
        <div class="card-body">
          <tr>
            <td class="word-blue"><strong>UPT</strong></td>
          </tr>
          <tr>
            <td class="word-black">
              <strong>{{ currentMonthLiter }}</strong>
            </td>
          </tr>
          <div *ngIf="currentMonthLiter < pastMonthLiter">
            <tr>
              <td class="word-red">
                <strong
                  >{{ calculate(currentMonthLiter, pastMonthLiter) }} ({{
                    calculateUPT
                  }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="pastMonthLiter"
              [value]="currentMonthLiter"
              color="#E31818"
            ></app-bullet>
          </div>
          <div *ngIf="currentMonthLiter >= pastMonthLiter">
            <tr>
              <td class="word-green">
                <strong
                  >{{ calculate(currentMonthLiter, pastMonthLiter) }} (+{{
                    calculateUPT
                  }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="pastMonthLiter"
              [value]="currentMonthLiter"
              color="#2DF119"
            ></app-bullet>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card">
        <div class="card-body">
          <tr>
            <td class="word-blue"><strong>Average Price</strong></td>
          </tr>
          <tr>
            <td class="word-black">
              <strong>$ {{ divideCurrent }}</strong>
            </td>
          </tr>
          <div *ngIf="divideCurrent < dividePast">
            <tr>
              <td class="word-red">
                <strong
                  >$ {{ calculate(divideCurrent, dividePast) }} ({{
                    calculateAveragePrice
                  }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="dividePast"
              [value]="divideCurrent"
              color="#E31818"
            ></app-bullet>
          </div>
          <div *ngIf="divideCurrent >= dividePast">
            <tr>
              <td class="word-green">
                <strong
                  >$ {{ calculate(divideCurrent, dividePast) }} (+{{
                    calculateAveragePrice
                  }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="dividePast"
              [value]="divideCurrent"
              color="#2DF119"
            ></app-bullet>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card">
        <div class="card-body">
          <tr>
            <td class="word-blue"><strong>Activation</strong></td>
          </tr>
          <tr>
            <td class="word-black">
              <strong>{{ customersCurrents }}</strong>
            </td>
          </tr>
          <div *ngIf="customersCurrents < customersPasts">
            <tr>
              <td class="word-red">
                <strong
                  >{{ calculate(customersCurrents, customersPasts) }} ({{
                    calculateActivation
                  }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="customersPasts"
              [value]="customersCurrents"
              color="#E31818"
            ></app-bullet>
          </div>
          <div *ngIf="customersCurrents >= customersPasts">
            <tr>
              <td class="word-green">
                <strong
                  >{{ calculate(customersCurrents, customersPasts) }} (+{{
                    calculateActivation
                  }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="customersPasts"
              [value]="customersCurrents"
              color="#2DF119"
            ></app-bullet>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="card">
        <div class="card-body">
          <tr>
            <td class="word-blue"><strong>% Activation</strong></td>
          </tr>
          <tr>
            <td class="word-black">
              <strong>{{ percentCustomersCurrents }}%</strong>
            </td>
          </tr>
          <div *ngIf="percentCustomersCurrents < percentCustomersPasts">
            <tr>
              <td class="word-red">
                <strong
                  >{{
                    calculate(percentCustomersCurrents, percentCustomersPasts)
                  }}% ({{ calculatePercentActivation }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="percentCustomersPasts"
              [value]="percentCustomersCurrents"
              color="#E31818"
            ></app-bullet>
          </div>
          <div *ngIf="percentCustomersCurrents >= percentCustomersPasts">
            <tr>
              <td class="word-green">
                <strong
                  >{{
                    calculate(percentCustomersCurrents, percentCustomersPasts)
                  }}% (+{{ calculatePercentActivation }}%) LM</strong
                >
              </td>
            </tr>
            <app-bullet
              [target]="percentCustomersPasts"
              [value]="percentCustomersCurrents"
              color="#2DF119"
            ></app-bullet>
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
