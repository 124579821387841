import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { SellersService } from "src/app/@pages/sellers/sellers.service";
import * as XLSX from "xlsx";
import { startWith, map, takeUntil } from "rxjs/operators";
import { ISeller } from "src/app/@interfaces/seller.interface";
import { Observable, Subject } from "rxjs";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { ReportsService } from "src/app/@pages/reports/reports.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-customers-by-seller",
  templateUrl: "./customers-by-seller.component.html",
  styleUrls: ["./customers-by-seller.component.css"],
})
export class CustomersBySellerComponent implements OnInit {
  @Input() customersList: ICustomer[] = [];
  arrayExcel: ICustomer[] = [];
  sellerList: Array<ISeller> = [];
  name = "Report.xlsx";
  filteredOptionsSeller: Observable<ISeller[]> | undefined;
  sellerForm: UntypedFormControl = this.fb.control("", Validators.required);
  companyName = '';
  companyId = '';
  unsubscribe$ = new Subject();

  constructor(
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private seller: SellersService,
    private fb: UntypedFormBuilder,
    private reportsService: ReportsService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.spinner.hide();
    this.seller
    .listSellers()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.sellerList = result;
    });
    this.filteredOptionsSeller = this.sellerForm.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterS(value))
    );
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.companyName = company!.name;
  }

  exportExcel(customers: ICustomer[]) {
    this.spinner.show();
    this.filter(customers);
    const element = document.getElementById("season-tble");
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const book: XLSX.WorkBook = XLSX.utils.book_new();
    this.reportsService.export(book, worksheet, this.name);
  }

  filter(customers: ICustomer[]) {
    const sellerId = this.sellerForm.value.id_seller;
    if (sellerId) {
      const filter = customers.filter(
        (customer) => customer.seller![0].id_seller === sellerId
      );
      this.arrayExcel = filter;
    } else {
      this.arrayExcel = customers;
    }
  }

  clean() {
    this.sellerForm.setValue("");
  }

  displayFnSeller(seller: any) {
    return seller && seller ? seller.nombre : undefined;
  }

  _filterS(value: string): ISeller[] {
    const filterValue = value.toString().toLowerCase();
    return this.sellerList.filter((option) =>
      option.nombre.toLowerCase().toString().includes(filterValue)
    );
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
