import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../@shared/authentication/auth.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-purchases",
  templateUrl: "./purchases.component.html",
  styleUrls: ["./purchases.component.css"],
})
export class PurchasesComponent implements OnInit {

  constructor(
    private spinnerService: NgxSpinnerService,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.spinnerService.show();
  }
}
