import { Pipe, PipeTransform } from '@angular/core';
import { ISeller } from '../../@interfaces/seller.interface';

@Pipe({
  name: 'filterSeller'
})
export class FilterSellerPipe implements PipeTransform {
  
  transform(sellers: ISeller[], searchValue: string): ISeller[] {
    if (!sellers || !searchValue) {
      return sellers;
    }
    return sellers.filter(sellers =>
      sellers.id_seller?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      sellers.doc_nit?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      sellers.nombre?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      sellers.email?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      sellers.country![0].name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
  }
}
