import { Component, Input } from "@angular/core";

export interface DrillInfo {
  text: string;
  node?: any;
}

export interface DrillData {
  name: string;
  items: any[];
}

@Component({
  selector: "app-drill-down",
  templateUrl: "./drill-down.component.html",
  styleUrls: ["./drill-down.component.css"],
})
export class DrillDownComponent {
  @Input() drillData: DrillData[] = [];
  drillInfos: DrillInfo[] = [];

  constructor() {}

  nodeClick(e: any) {
    e.node.drillDown();
  }

  drill(e: any) {
    this.drillInfos = [];
    for (let node = e.node.getParent(); node; node = node.getParent()) {
      this.drillInfos.unshift({
        text: node.label() || "All Objects",
        node,
      });
    }
    if (this.drillInfos.length) {
      this.drillInfos.push({
        text: e.node.label(),
      });
    }
  }

  drillInfoClick(node: any) {
    if (node) {
      node.drillDown();
    }
  }

  customizeTooltip(arg: any) {
    return {
      text: `${arg.node.data.name}: ${arg.value}`,
    };
  }
}
