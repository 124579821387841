<mat-dialog-content>
    <div class="row">
        <img src="../../../../../assets/icons/icono.png" onerror="this.src='assets/img/cantfindimage.jpg'"
            style="margin-left: 1px; width: 20px; height: 20px;">
        <div class="col-md-6" style="margin-top: 10px" *ngFor="let contfac of quotationToModify.contfac">
            <h2 mat-dialog-tittle>{{'Credit Note' | translate}} - {{'Invoice' | translate}} {{contfac.invoice}}</h2>
            <h2></h2>
        </div>
    </div>
    
    <form [formGroup]="registerForm">
        <div class="row" style="justify-content: center; display:flex">
            <div class="col" id="div-responsive">
                <mat-form-field>
                    <mat-label>{{'Customer' | translate}}</mat-label>
                    <ng-container formArrayName="itemRows">
                        <input readonly type="text" matInput [matAutocomplete]="autoCustomer" [formControl]="newCustomer">
                    </ng-container>
                    <mat-autocomplete #autoCustomer="matAutocomplete" [displayWith]="displayFnCustomer">
                        <mat-option *ngFor="let customer of filteredOptionsCustomer | async" [value]="customer">
                            {{customer.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col" id="div-responsive">
                <mat-form-field>
                    <mat-label>{{'Tax' | translate}} (%)</mat-label>
                    <input readonly type="number" placeholder="0" matInput formControlName="tax" (keyup)="calculation()"
                        autocomplete="off">
                </mat-form-field>
            </div>
            <div class="col" id="div-responsive">
                <mat-form-field appearance="fill">
                    <mat-label>{{'Date' | translate}}</mat-label>
                    <input readonly matInput [matDatepicker]="picker" formControlName="date" autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col" id="div-responsive">
                <mat-form-field appearance="fill">
                    <mat-label>{{'Due Date' | translate}}</mat-label>
                    <input readonly matInput [matDatepicker]="picker2" formControlName="dueDate" autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col" id="div-responsive">
                <mat-form-field appearance="fill">
                    <mat-label>{{'Select Concept' | translate}}</mat-label>
                    <mat-select (selectionChange)="changeValues()" formControlName="concepto">
                      <mat-option *ngFor="let concepto of concept" [value]="concepto.value">
                        {{concepto.viewValue | translate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        </div>
        <table class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>
                        <mat-label>{{'Warehouse' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Articles' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Quantities' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Prices' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Discounts' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Subtotals' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Subtotals' | translate}} N</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Quantities' | translate}} N</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Prices' | translate}} N</mat-label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container formArrayName="itemRows" class="row">
                    <ng-container *ngFor="let itemrow of rowsArr.controls; let i=index" [formGroupName]="i">
                        <tr>
                            <td data-label="Warehouse" style="width: 160px;">    
                                {{itemrow.value.warehouse.nombre}}
                            </td>
                            <td data-label="Articles" style="text-align: left; width: 400px;">
                                <input readonly type="text" class="form-control" name="article" placeholder="{{'Type Article' | translate}}"
                                    [matAutocomplete]="autoArticle" formControlName="article" style="width: 400px;">
                                <mat-autocomplete #autoArticle="matAutocomplete" [displayWith]="displayFnArticle">
                                    <mat-option *ngFor="let articles of filteredOptionsArticle | async"
                                        [value]="articles">
                                        {{articles.codigo}} - {{articles.descripcion}}
                                    </mat-option>
                                </mat-autocomplete>
                            </td>
                            <td data-label="Quantities">
                                <input  class="form-control" placeholder="0" type="text" (keyup)="calculation(); disableInputs(i, 'quantity', 'price', 'quantity2')"
                                    formControlName="quantity" (click)="disableInputs(i, 'quantity', 'price', 'quantity2')">
                            </td>
                            <td data-label="Prices">
                                <input class="form-control" placeholder="0" type="text" (keyup)="calculation(); disableInputs(i, 'price', 'quantity', 'price2')"
                                    formControlName="price" style="width: 100px;" (click)="disableInputs(i, 'price', 'quantity', 'price2')">
                            </td>
                            <td data-label="Discounts">
                                <input readonly class="form-control" placeholder="0" type="text" (keyup)="calculation()"
                                    formControlName="discount" >
                            </td>
                            <td data-label="Subtotals" style="width:100px">
                                <div style="margin: 5px; text-align: center;">
                                    {{itemrow.value.subtotal | currency:'USD'}}
                                </div>
                            </td>
                            <td data-label="Subtotals N" style="width:100px">
                                <div style="margin: 5px; text-align: center;">
                                    {{itemrow.value.subtotalVec | currency:'USD'}}
                                </div>
                            </td>
                            <td data-label="Quantities N" style="width:100px">
                                <div style="margin: 5px; text-align: center;">
                                    {{itemrow.value.cantidadVec}}
                                </div>
                            </td>
                            <td data-label="Prices N" style="width:100px">
                                <div style="margin: 5px; text-align: center;">
                                    {{itemrow.value.precioVec | currency:'USD'}}
                                </div>
                            </td>
                           
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table><br>
    </form>
    
        <div class="row">
            <div  style="margin-bottom: 20px;">
                <button mat-raised-button (click)="openDialogVerCredit(id_fact)">{{'View notes' | translate}}</button>
            </div>
            <div class="col-md-1" style="margin-bottom: 20px;">
                <button mat-raised-button (click)="crear()">{{'Create' | translate}}</button>
            </div>
            <div class="col-md-1" style="margin-bottom: 20px;">
                <button mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
            </div>
            <div class="col-md-1">
                <h3><strong>{{'Total' | translate}}: </strong>{{totalQuot | currency: 'USD'}}</h3>
            </div>
            <div class="col-md-1">
                <h3><strong>{{'Total' | translate}} N: </strong>{{totalQuot2 | currency: 'USD'}}</h3>
            </div>
        </div>
</mat-dialog-content>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)" >
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
