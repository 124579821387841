import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TributaryComponent } from './tributary.component';
import { TributaryRoutingModule } from './provider-routing.module';
import { DxButtonModule, DxDataGridModule } from 'devextreme-angular';
import { MaterialModule } from '../../material.module';
import { TableModule } from '../../@shared/components/table/table.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TributaryComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TableModule,
    TributaryRoutingModule,
    DxDataGridModule,
    DxButtonModule,
    TableModule,
    NgxSpinnerModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class TributaryModule { }
