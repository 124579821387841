import { createSelector } from "@ngrx/store";
import { AppState } from "../app,state";
import { CompaniesState } from "src/app/@interfaces/company.interface";

export const selectCompanies = (state: AppState) => state.companiesState;

export const selectCompaniesList = createSelector(
  selectCompanies,
  (state: CompaniesState) => state.companies
);

export const selectCompaniesLoading = createSelector(
  selectCompanies,
  (state: CompaniesState) => state.loading
);

export const selectCompaniesFailure = createSelector(
  selectCompanies,
  (state: CompaniesState) => state.error
);
