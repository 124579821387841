import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IWarehouseOutputAndEntry, IWarehouseTransfer } from 'src/app/@interfaces/warehouse.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/@shared/authentication/auth.service';
import { EventEmitterService } from 'src/app/@core/menu/emitterFuncions.service';
import { takeUntil } from 'rxjs/operators';
import { WarehouseService } from '../warehouse/warehouse.service';
import { DialogComponent } from 'src/app/@shared/components/dialog/dialog.component';
import { IInventoryReclassification } from 'src/app/@interfaces/inventoryReclassification.interface';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-inventory-movements',
  templateUrl: './inventory-movements.component.html',
  styleUrls: ['./inventory-movements.component.css']
})
export class InventoryMovementsComponent implements OnInit {
  isRoleAdmin: boolean = false;
  warehouseTransfers: IWarehouseTransfer[] = [];
  warehouseOutputsAndEntries: IWarehouseOutputAndEntry[] = [];
  inventoryReclassifications: IInventoryReclassification[] = [];
  lenght: number[] = [0, 0, 0];
  types = [
    { id: 1, value: 'Output'},
    { id: 2, value: 'Entry'}
  ]
  
  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;
  constructor(
    private dialog: MatDialog,
    private warehouseService: WarehouseService,
    private spinnerService: NgxSpinnerService,
    private eventEmitterService: EventEmitterService,
    private authService: AuthService,
     private userService: UsersService
  ) { }

  async ngOnInit(): Promise<void> {
    this.spinnerService.show();
    await this.authService.checkAuthStatus();
    this.toggleExpandCard();
    this.getEvent();
    this.data();
    this.isRoleAdmin = await this.userService.isRoleAdmin()
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((name: string) => {
      this.toggleExpandCard();
    });
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 250);
  }

  data() {
    this.warehouseService
    .getAllWarehouseTransfers(0, 15)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.warehouseTransfers = result.transfers;
      this.lenght[0] = result.count;
    });

    this.warehouseService
    .getAllWarehouseOutputsAndEntries(1, 0, 15)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.warehouseOutputsAndEntries = result.warehouseOutputEntries;
      this.lenght[1] = result.count;
    });

    this.warehouseService
    .getAllInventoryReclassifications(0, 15)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.inventoryReclassifications = result.inventoryReclassification;
      this.lenght[2] = result.count;
      this.spinnerService.hide();
    });
  }

  updateDataCounts(event: any) {
    this.warehouseService.updateWarehouseTransfers(event.newValue, event.page, event.size)
  }

  openTransferDialog() {
    this.dialog.open(DialogComponent, {
      data: { registerWarehouseTransfer: true },
    });
  }

  openOutputOrEntryDialog() {
    this.dialog.open(DialogComponent, {
      data: { registerWarehouseOutputOrEntry: true, types: this.types },
    })
  }

  openReclassificationDialog() {
    this.dialog.open(DialogComponent, {
      data: { registerReclassification: true },
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
