import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";

@Injectable({
  providedIn: "root",
})
export class ConverseFilesService {
  constructor(private translate: TranslateService) {}

  transformFile(event: any, fileType: string, base: boolean) {
    return new Promise<any>((resolve, reject) => {
      const fileCaptured = event.target.files[0];
      const format = fileCaptured.type;
      if (format !== fileType) {
        const spliceStringType = fileType.split("/");
        Swal.fire(
          "Error",
          this.translate.instant(`Must be a ${spliceStringType[1]} file`),
          "error"
        ).then((result) => {
          if (result.isConfirmed) {
            reject(new Error("File type invalid"));
          }
        });
      } else {
        if (base === false) {
          resolve(fileCaptured);
        }
        this.extractBase64(fileCaptured).then((file: any) => {
          const base64 = file.base.replace("data:" + format + ";base64,", "");
          if (base === true) {
            resolve(base64);
          }
        });
      }
    });
  }

  extractBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        let read;
        reader.readAsDataURL($event);
        read = reader.onload = () => {
          resolve({ base: reader.result });
        };
        read = reader.onerror = (error) => {
          resolve({ base: null });
        };
        return read;
      } catch (e) {
        return null;
      }
    });

  xmlToJs(xml: string) {
    const doc = new DOMParser().parseFromString(xml, "application/xml");
    const result: any = {};
    if (doc.children.length === 0) {
      return null;
    }
    if (doc.children.length === 1) {
      result[doc.children[0].nodeName] = this.nodeToObj(doc.children[0]);
    } else {
      result[doc.children[0].nodeName] = Array.from(doc.children).map((node) =>
        this.nodeToObj(node)
      );
    }
    return result;
  }

  nodeToObj(node: any) {
    const result: any = {};
    if (node.children.length === 0) {
      result[node.nodeName] = node.textContent;
    } else {
      for (const childNode of Array.from<any>(node.children)) {
        if (childNode.nodeName in result) {
          if (!Array.isArray(result[childNode.nodeName])) {
            result[childNode.nodeName] = [result[childNode.nodeName]];
          }
          result[childNode.nodeName].push(this.nodeToObj(childNode));
        } else {
          result[childNode.nodeName] = this.nodeToObj(childNode);
        }
      }
    }
    return result;
  }

  converseUTF8(originalString: string) {
    return new Promise<string>((resolve, reject) => {
      const bytes = this.stringToBytes(originalString);
      const blob = new Blob([bytes.buffer], {
        type: "text/plain; charset=ISO-8859-1",
      });
      blob
        .text()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  stringToBytes(text: string) {
    const length = text.length;
    const result = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      const code = text.charCodeAt(i);
      const byte = code > 255 ? 32 : code;
      result[i] = byte;
    }
    return result;
  }

  removeAccents(str: string) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  validText(text: string) {
    const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ.,\s]+$/;
    return regex.test(text);
  }

  handleFile(event: any, fileType: string) {
    return new Promise<any[]>((resolve, reject) => {
      const fileCaptured = event.target.files[0];
      const format = fileCaptured.type;
      if (format !== fileType) {
        const spliceStringType = fileType.split("/");
        Swal.fire(
          "Error",
          this.translate.instant(`Must be a ${spliceStringType[1]} file`),
          "error"
        ).then((result) => {
          if (result.isConfirmed) {
            reject(new Error("File type invalid"));
          }
        });
      } else {
        resolve(this.getJsonFromExcel(fileCaptured));
      }
    });
  }

  getJsonFromExcel(file: any) {
    return new Promise<any[]>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (e: any) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
        });
        resolve(jsonData);
      };
    });
  }

  generateTxt(text: string) {
    const blob = new Blob([text], { type: "text/plain" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "FILE.txt";
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }
}
