<div>
    <div class="alert alert-info">
        {{'Drill' | translate}}
    </div>
    <div class="row">
        <div class="col">
            <app-drill-down [drillData]="drillData"></app-drill-down>
        </div>
    </div>
</div>


