<div class="dialog-container">
  <div class="spinner-container">
    <ng-container *ngIf="!isComplete; else completedImage">
      <mat-spinner color="900" diameter="30"></mat-spinner>
    </ng-container>
    <ng-template #completedImage>
      <img
        src="assets/icons/checkmark.png"
        alt="Completed"
        class="completed-image"
      />
    </ng-template>
  </div>

  <div>
    <ng-container *ngIf="!isComplete">
        <div class="main-text">Descarga en progreso</div>
        <div class="sub-text">Descargando reporte de operación</div>
    </ng-container>
    <ng-container *ngIf="isComplete">
        <div class="main-text">Descarga completa</div>
        <div class="sub-text">Tu archivo se descargo exitosamente</div>
    </ng-container>
  </div>

  <div class="close-container">
        <a (click)="closeDialog()">
            <mat-icon class="close-icon">close</mat-icon>
        </a>
  </div>

  <app-siigo-table
    [chartAccountsData]="chartAccounts"
    [headerDateRange]="dialogData.headerDate"
  ></app-siigo-table>
</div>    
