import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { PasswordRoutingModule } from "./password-routing.module";
import { PasswordComponent } from "./password.component";
import { MatIconModule } from "@angular/material/icon";
import { DialogModule } from "src/app/@shared/components/dialog/dialog.module";
import { MaterialModule } from "src/app/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [PasswordComponent],
  imports: [
    CommonModule,
    PasswordRoutingModule,
    ReactiveFormsModule,
    MatIconModule,
    DialogModule,
    MaterialModule,
    TranslateModule,
    NgxSpinnerModule,
  ],
  exports: [PasswordComponent],
})
export class PasswordModule {}
