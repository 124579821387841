import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ICustomer } from 'src/app/@interfaces/customer.interface';
import { IPaymentsList } from 'src/app/@interfaces/payments.interface';
import { IProvider } from 'src/app/@interfaces/provider.interface';
import { CustomerService } from 'src/app/@pages/customer/customer.service';
import { ProviderService } from 'src/app/@pages/provider/provider.service';
import { TreasuryService } from 'src/app/@pages/treasury/treasury.service';
import { takeUntil } from 'rxjs/operators';
import { PortfolioService } from '../../../../../@pages/portfolio/portfolio.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';

@Component({
  selector: 'app-register-treasury',
  templateUrl: './register-treasury.component.html',
  styleUrls: ['./register-treasury.component.css']
})
export class RegisterTreasuryComponent implements OnInit, OnDestroy {
  registerForm: UntypedFormGroup = this.fb.group({
    payment: ["", [Validators.required]],
    date: ["", [Validators.required]],
    value: ["", [Validators.required]],
    id_ref: ["", [Validators.required]],
  });
  customerForm: UntypedFormControl = this.fb.control("", Validators.required);
  providerForm: UntypedFormControl = this.fb.control("", Validators.required);

  paymentsList: IPaymentsList[] = [];
  customerList: ICustomer[] = [];
  providerList: IProvider[] = [];
  unsubscribe$ = new Subject();
  user = '';
  rol = '';
  constructor(
    private fb: UntypedFormBuilder,
    private treasuryService: TreasuryService,
    private spinnerService: NgxSpinnerService,
    private customerService: CustomerService,
    private providerService: ProviderService,
    private portfolioService: PortfolioService,
    private translate: TranslateService,
    private router: Router,
    private cookieAuthService: CookieAuthService
  ) { }

  async ngOnInit() {
    this.getAuthValues();
    this.data();
    this.getInputValues();
  }

  getAuthValues() {
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  validRegister() {
    if (this.registerForm.valid && (this.customerForm.valid || this.providerForm.valid)) {
      this.register();
    }else {
      Swal.fire({
        icon: "error",
        title: this.translate.instant("Please, fill in all the required fields!"),
        text: 'Error'
      });
    }
  }

  register() {
    this.spinnerService.show();
    const type = this.customerForm.value ? "Customers": "Providers";
    this.treasuryService.createAdvencePayment(
      parseInt(this.registerForm.value.payment.id_payment),
      parseInt(this.customerForm.value.id_cliente),
      parseInt(this.providerForm.value.id_provider),
      type.substring(0, 2).toLocaleUpperCase(),
      type,
      new Date(this.registerForm.value.date).toDateString(),
      this.registerForm.value.value,
      this.registerForm.value.id_ref
    ).pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.spinnerService.hide().then((closed) => {
        Swal.fire({
          title: this.translate.instant("Created"),
          text: this.translate.instant("Payment created successfully!"),
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#f44336",
          confirmButtonText: "Ok",
        }).then((confirmed) => {
          if (confirmed.isConfirmed) {
            this.router
            .navigateByUrl("/table", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/treasury"]);
            });
          }
        })
      });
    },(error) => {
      this.spinnerService.hide().then((closed) => {
        Swal.fire({
          icon: "error",
          title: this.translate.instant("Something was wrong"),
          text: error
        });
      });
    })
  }

  data() {
    if (this.rol === "1" || this.rol === "3") {
      this.portfolioService
      .getCashierList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.paymentsList = this.paymentsList.concat(result);
      });

      this.portfolioService
      .getBankList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.paymentsList = this.paymentsList.concat(result);
      });
    } else {
      this.portfolioService
      .getCashierListByUser(parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.paymentsList = this.paymentsList.concat(result);
      });

      this.portfolioService
      .getBankListByUser(parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.paymentsList = this.paymentsList.concat(result);
      });
    }
  }

  getInputValues() {
    if (this.rol === "1" || this.rol === "3") {
      this.customerForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        if (!value.__typename) {
          this.customerService
          .searchCustomer(value, null, null)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result: any) => {
            this.customerList = result.customers;
          });
        }
      });
    } else {
      this.customerForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        if (!value.__typename) {
          this.customerService
          .searchCustomerByUser(value, parseInt(this.user!), null, null)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result: any) => {
            this.customerList = result;
          });
        }
      });
    }
    this.providerForm.valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((value: any) => {
      if (!value.__typename) {
        this.providerService
        .searchProvider(value, null, null)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.providerList = result.providers;
        });
      }
    });
  }

  displayFnCustomer(customer: any) {
    return customer && customer ? customer.nombre : undefined;
  }

  displayFnProvider(provider: any) {
    return provider && provider ? provider.nombre : undefined;
  }

  comparePayment(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_payment === item2.id_payment;
  }

  textValid(text: string) {
    return (
      this.registerForm.get(text)?.invalid &&
      this.registerForm.get(text)?.touched
    );
  }

  disableCustomerForm() {
    if (this.providerForm.value) {
      this.customerForm.disable();
    }else {
      this.customerForm.enable();
    }
  }

  disableProviderForm() {
    if (this.customerForm.value) {
      this.providerForm.disable();
    } else {
      this.providerForm.enable();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
