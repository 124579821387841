<div class="alert alert-info">
  {{ "Average sales value" | translate }}
</div>
<app-average-indicator
  [currentMonthDollar]="currentDollar"
  [pastMonthDollar]="pastDollar"
  [currentMonthLiter]="currentLiter"
  [pastMonthLiter]="pastLiter"
  [divideCurrent]="divideCurrent"
  [dividePast]="dividePast"
  [customersCurrents]="customersCurrents"
  [customersPasts]="customersPasts"
  [percentCustomersCurrents]="percentCustomersCurrents"
  [percentCustomersPasts]="percentCustomersPasts"
  [calculateDropSize]="calculateDropSize"
  [calculateUPT]="calculateUPT"
  [calculateAveragePrice]="calculateAveragePrice"
  [calculateActivation]="calculateActivation"
  [calculatePercentActivation]="calculatePercentActivation"
></app-average-indicator>
