import { Injectable } from "@angular/core";

export interface DataItem {
  arg: string;
  val: number;
  parentID: string;
}

@Injectable()
export class Service {
  filterData(name: any, data: DataItem[]): DataItem[] {
    return data.filter((item) => item.parentID === name);
  }
}
