<div [ngClass]="{'search-margin' : showTable !== 10}" class="search-input">
    <app-search (search)="handleSearch($event)"></app-search>
</div>
<div *ngIf="user.length > 0">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>{{'Document' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Name' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Username' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Email' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Rol' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Company' | translate}}</mat-label>
                </th>
                <th colspan="2">
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let users of user | paginate: {itemsPerPage: 15, currentPage: page}; let i = index">
                <td>
                    {{i+1}}
                </td>
                <td>
                    {{users.document}}
                </td>
                <td>
                    {{users.name}}
                </td>
                <td>
                    {{users.username}}
                </td>
                <td>
                    {{users.email}}
                </td>
                <td >
                    <div *ngFor="let rol of users.roles">
                        {{rol.name}}
                    </div>
                </td>
                <td>
                    <div *ngFor="let company of users.companies">
                        {{company.name}} <br>
                    </div>
                </td>
                
                <td>
                    <a class="goLink" (click)="dialogModifyUser(true,users.id)">
                        <mat-icon>edit</mat-icon>
                    </a>
                </td>
                <ng-container *ngIf="users.status != 2; else unlock">
                    <td>
                        <a class="goLink" (click)="deactiveUser(users.id)">
                            <mat-icon>block</mat-icon>
                        </a>
                    </td>
                </ng-container>
                <ng-template #unlock>
                    <td>
                        <a class="goLink" (click)="activateUser(users.id)">
                            <mat-icon>check</mat-icon>
                        </a>
                    </td>
                </ng-template>
            </tr>
        </tbody>
    </table>
    <pagination-controls (pageChange)="page = $event" class="my-pagination"></pagination-controls>
</div>
<div *ngIf="inventory.length > 0">

    <table class="table table-bordered">
        <thead class="thead-dark">
          <tr>
            <th>
              <mat-label>No.</mat-label>
            </th>
            <th (click)="setOrder('codigo')">
              <mat-label>{{'Barcode' | translate}}</mat-label>
            </th>
            <th>
              <mat-label>{{'Description' | translate}}</mat-label>
            </th>
            <th>
              <mat-label>{{'Quant/Presentation' | translate}}</mat-label>
            </th>
            <th *ngIf="isRoleAdmin || isRoleFullOperator" (click)="setOrder('costo')"> 
              <mat-label>{{'Price' | translate}}</mat-label>
              <mat-icon>sort</mat-icon>
            </th>
            <th>
              <mat-label>{{'Unit' | translate}}</mat-label>
            </th>
            <th>
              <mat-label>{{'Group' | translate}}</mat-label>
            </th>
            <th>
              <mat-label>{{'Alternative Codes' | translate}}</mat-label>
            </th>
            <th (click)="setOrder('cumulative_amount')">
              <mat-label>{{'Amount' | translate}}</mat-label>
              <mat-icon>sort</mat-icon>
            </th>
            <th *ngIf="isRoleAdmin || isRoleFullOperator" (click)="setOrder('cumulative_value')">
              <mat-label>{{'Cost' | translate}}</mat-label>
              <mat-icon>sort</mat-icon>
            </th>
            <th>
              <mat-label>{{'Options' | translate}}</mat-label>
            </th>
            <th>
              <mat-label>{{'Details' | translate}}</mat-label>
            </th>
          </tr>
        </thead>
        
        <tbody>
          <tr *ngFor="let article of inventory | tableIds:currentPageIndex;">
            <td>{{article.id}}</td>
            <td>{{article.codigo}}</td>
            <td>{{article.descripcion}}</td>
            <td>{{article.presentacion}}</td>
            <td *ngIf="isRoleAdmin || isRoleFullOperator">{{article.costo | thousandsPipe}}</td>
            <td>{{article.unidad}}</td>
            <td *ngFor="let grupoArt of article.grupoArt">{{grupoArt.nombre}}</td>
            <td>{{article.alternativeCodes}}</td>
            <td>{{article.cumulative_amount}}</td>
            <td *ngIf="isRoleAdmin || isRoleFullOperator">{{article.cumulative_value | thousandsPipe}}</td>
            <td style="text-align: center;">
              <a class="goLink" (click)="dialogModifyArticle(true, article.id_articulo)">
                <mat-icon>edit</mat-icon>
              </a>
            </td>
            <td style="text-align: center;">
              <a class="goLink" (click)="dialogDetailsArticle(true, article)">
                <mat-icon>list_alt</mat-icon>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-paginator #inventoryPaginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="pageEvent = $event; filterValue.length === 0 ? capturePage($event, showTable) : filter($event)"
        aria-label="Select page">
        </mat-paginator>
    </div>

</div>
<div *ngIf="customer.length > 0">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>{{'Document/Nit' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Name' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Address' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Phone' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Email' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Delivery' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Country' | translate}}</mat-label>
                </th>
                
                <th (click)="setOrder('positive_balance')">
                    <mat-label>{{'Positive Balance' | translate}}</mat-label>
                    <mat-icon>sort</mat-icon> 
                  </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let customers of customer | tableIds:currentPageIndex">
                <td>
                    {{customers.id}}
                </td>
                <td>
                    {{customers.doc_nit}}
                </td>
                <td>
                    {{customers.nombre}}
                </td>
                <td>
                    {{customers.direccion}}
                </td>
                <td>
                    {{customers.telefono}}
                </td>
                <td>
                    {{customers.email}}
                </td>
                <td>
                    {{customers.delivery}}
                </td>
                <td *ngFor="let country of customers.country">
                    {{country.name}}
                </td>
                <td>
                    {{customers.positive_balance}}
                </td>
                <td style="text-align: center;">
                    <a class="goLink" (click)="dialogModifyCustomer(true,customers.id_cliente)">
                        <mat-icon>edit</mat-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="pageEvent = $event; filterValue.length === 0 ? capturePage($event, showTable) : filter($event)"
        aria-label="Select page">
        </mat-paginator>
    </div>
</div>

<div *ngIf="seller.length > 0">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>ID</mat-label>
                </th>
                <th>
                    <mat-label>{{'Document/Nit' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Name' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Email' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Country' | translate}}</mat-label>
                </th>
                <th *ngIf="(rol === '1')">
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sellers of seller | paginate: {itemsPerPage: 15, currentPage: page} | tableIds:currentPageIndex">
                <td>
                    {{sellers.id}}
                </td>
                <td>
                    {{sellers.id_seller}}
                </td>
                <td>
                    {{sellers.doc_nit}}
                </td>
                <td>
                    {{sellers.nombre}}
                </td>
                <td>
                    {{sellers.email}}
                </td>
                <td *ngFor="let country of sellers.country">
                    {{country.name}}
                </td>
                <td style="text-align: center;" *ngIf="(rol === '1')">
                    <a class="goLink" (click)="dialogModifySeller(true,sellers.id_seller)">
                        <mat-icon>edit</mat-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <pagination-controls (pageChange)="page = $event" class="my-pagination"></pagination-controls>
</div>

<div *ngIf="provider.length > 0">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>{{'Document/Nit' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Name' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Address' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Phone' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Email' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Country' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let providers of provider | tableIds:currentPageIndex">
                <td>
                    {{providers.id}}
                </td>
                <td>
                    {{providers.doc_nit}}
                </td>
                <td>
                    {{providers.nombre}}
                </td>
                <td>
                    {{providers.direccion}}
                </td>
                <td>
                    {{providers.telefono}}
                </td>
                <td>
                    {{providers.email}}
                </td>
                <td *ngFor="let country of providers.country">
                    {{country.name}}
                </td>
                <td style="text-align: center;">
                    <a class="goLink" (click)="dialogModifyProvider(true,providers.id_provider!)">
                        <mat-icon>edit</mat-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="pageEvent = $event; filterValue.length === 0 ? capturePage($event, showTable) : filter($event)"
        aria-label="Select page">
        </mat-paginator>
    </div>
</div>

<div *ngIf="activitiesCiiu.length > 0">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>{{'ICA Code' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Activity Code' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Activity Description' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Actual Rate' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'City' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let activity of activitiesCiiu | paginate: {itemsPerPage: 15, currentPage: page}; let i = index">
                <td>
                    {{i+1}}
                </td>
                <td>
                    {{activity.ica_code}}
                </td>
                <td>
                    {{activity.activity_code}}
                </td>
                <td>
                    {{activity.activity_description}}
                </td>
                <td>
                    {{activity.actual_rate}}
                </td>
                <td *ngFor="let city of activity.cities">
                    {{city.name}}
                </td>
                <td style="text-align: center;">
                    <a class="goLink" (click)="dialogModifyActivity(true,activity.id_activity)">
                        <mat-icon>edit</mat-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <pagination-controls (pageChange)="page = $event" class="my-pagination"></pagination-controls>
</div>

<div *ngIf="advancePayments.length > 0">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>{{'Transaction No' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Payment Type' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Of' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Type' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Date' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Value' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'ID Reference' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let payments of advancePayments | tableIds:currentPageIndex">
                <td>
                    {{payments.id}}
                </td>
                <td>
                    {{payments.id_correlative}}
                </td>
                <td>
                    {{payments.paymentType!.description}}
                </td>
                <ng-container *ngIf="payments.customer">
                    <td>
                        {{payments.customer.nombre}}
                    </td>
                </ng-container>
                <ng-container *ngIf="payments.provider">
                    <td>
                        {{payments.provider.nombre}}
                    </td>
                </ng-container>
                <td>
                    {{payments.type | translate}}
                </td>
                <td>
                    {{payments.date}}
                </td>
                <td>
                    {{payments.value}}
                </td>
                <td>
                    {{payments.id_ref}}
                </td>
                <td style="text-align: center;">
                    <a class="goLink" (click)="deleteAdvancePayments(payments.id_treasury)">
                        <mat-icon>delete_forever</mat-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="pageEvent = $event; filterValue.length > 0 ? filter($event) : capturePage($event, showTable)"
        aria-label="Select page">
        </mat-paginator>
    </div>
</div>

<div *ngIf="transfers.length > 0">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>{{'Transaction No' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Type' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Date' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Payment Transfer' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Transfer Of' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Transfer To' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let transfer of transfers | tableIds:currentPageIndex">
                <td>
                    {{transfer.id}}
                </td>
                <td>
                    {{transfer.id_correlative}}
                </td>
                <td>
                    {{transfer.type | translate}}
                </td>
                <td>
                    {{transfer.date | date}}
                </td>
                <ng-container *ngIf="transfer.invoice_payment">
                    <td>
                        {{transfer.invoice_payment.identifier}} - ${{transfer.invoice_payment.value}}
                    </td>
                </ng-container>
                <ng-container *ngIf="transfer.purchase_payment">
                    <td>
                        {{transfer.purchase_payment.identifier}} - ${{transfer.purchase_payment.value}}
                    </td>
                </ng-container>
                <ng-container *ngIf="transfer.custom_value">
                    <td>
                        {{"Custom" | translate}} - ${{transfer.custom_value}}
                    </td>
                </ng-container>
                <td>
                    {{transfer.original.description}}
                </td>
                <td>
                    {{transfer.transferred.description}}
                </td>
                <td style="text-align: center;">
                    <a class="goLink" (click)="deleteTransfers(transfer.id_transfer)">
                        <mat-icon>delete_forever</mat-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="pageEvent = $event; filterValue.length === 0 ? capturePage($event, showTable) : filter($event)"
        aria-label="Select page">
        </mat-paginator>
    </div>
</div>

<div *ngIf="warehouseTransfers.length > 0">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>{{'Date' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Article' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Transfer Of' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Transfer To' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Quantity' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Created By' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let transfer of warehouseTransfers | tableIds:currentPageIndex">
                <td>
                    {{transfer.id}}
                </td>
                <td>
                    {{transfer.date | date}}
                </td>
                <td>
                    {{transfer.article.descripcion}}
                </td>
                <td>
                    {{transfer.original.nombre}}
                </td>
                <td>
                    {{transfer.transferred.nombre}}
                </td>
                <td>
                    {{transfer.value}}
                </td>
                <td>
                    {{transfer.user.name}}
                </td>
                <td style="text-align: center;">
                    <a *ngIf="deletePermission" class="goLink" (click)="deleteWarehouseTransfer(transfer.id_transfer, transfer)">
                        <mat-icon>delete_forever</mat-icon>
                    </a>
                    <a *ngIf="!deletePermission" class="goLink">
                        <mat-icon class="delete-transfer">delete_forever</mat-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [showFirstLastButtons]="true"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event; filterValue.length === 0 ? capturePage($event, showTable) : filter($event)"
        aria-label="Select page">
        </mat-paginator>
    </div>
</div>

<div *ngIf="warehouseOutputsAndEntries.length > 0">
    <div class="custom-container">
        <div class="select-type">
            <mat-form-field>
                <mat-label>{{'Type' | translate}}</mat-label>
                <mat-select disableRipple [formControl]="typeForm">
                    <mat-option class="multiline-mat-option" *ngFor="let type of types" [value]="type">
                        {{type.value | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>{{'Type' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Date' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Article' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Warehouse' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label >{{'Quantity' | translate}}</mat-label>
                </th>
                <ng-container *ngIf="typeForm.value.id === 2">
                    <th>
                        <mat-label>{{'Cost' | translate}}</mat-label>
                    </th>
                </ng-container>
                <th>
                    <mat-label>{{'Reason' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Created By' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let movement of warehouseOutputsAndEntries | tableIds:currentPageIndex">
                <td>
                    {{movement.id}}
                </td>
                <ng-container *ngIf="movement.type === 1">
                    <td>
                        {{'Output' | translate}}
                    </td>
                </ng-container>
                <ng-container *ngIf="movement.type === 2">
                    <td>
                        {{'Entry' | translate}}
                    </td>
                </ng-container>
                <td>
                    {{movement.date | date}}
                </td>
                <td>
                    {{movement.article.descripcion}}
                </td>
                <td>
                    {{movement.warehouse.nombre}}
                </td>
                <td>
                    {{movement.amount}}
                </td>
                <ng-container *ngIf="movement.type === 2 ">
                    <td>
                        {{movement.cost}}
                    </td>
                </ng-container>
                <td>
                    {{movement.description}}
                </td>
                <td>
                    {{movement.user.name}}
                </td>
                <td style="text-align: center;">
                    <a *ngIf="deletePermission" class="goLink" (click)="deleteWarehouseOutputOrEntry(movement.id_movement, movement)">
                        <mat-icon>delete_forever</mat-icon>
                    </a>
                    <a *ngIf="!deletePermission" class="goLink">
                        <mat-icon class="delete-transfer">delete_forever</mat-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-paginator [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="pageEvent = $event; filterValue.length === 0 ? capturePage($event, showTable) : filter($event)"
        aria-label="Select page">
        </mat-paginator>
    </div>
</div>

<div *ngIf="inventoryReclassifications.length > 0">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th>
                    <mat-label>{{'Date' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Exit Article' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Exit Warehouse' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Quantity' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Entry Article' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Entry Warehouse' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Created By' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let reclassification of inventoryReclassifications | tableIds:currentPageIndex">
                <td>
                    {{reclassification.id}}
                </td>
                <td>
                    {{reclassification.date | date}}
                </td>
                <td>
                    {{reclassification.exitArticle.descripcion}}
                </td>
                <td>
                    {{reclassification.exitWarehouse.nombre}}
                </td>
                <td>
                    {{reclassification.value}}
                </td>
                <td>
                    {{reclassification.entryArticle.descripcion}}
                </td>
                <td>
                    {{reclassification.entryWarehouse.nombre}}
                </td>
                <td>
                    {{reclassification.user.name}}
                </td>
                <td style="text-align: center;">
                    <a *ngIf="deletePermission" class="goLink" (click)="deleteInventoryReclassification(reclassification.id_reclassification, reclassification)">
                        <mat-icon>delete_forever</mat-icon>
                    </a>
                    <a *ngIf="!deletePermission" class="goLink">
                        <mat-icon class="delete-transfer">delete_forever</mat-icon>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-paginator [length]="inventoryReclassifications.length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="pageEvent = $event; filterValue.length === 0 ? capturePage($event, showTable) : filter($event)"
        aria-label="Select page">
        </mat-paginator>
    </div>
</div>

<div *ngIf="showTable === 3">
    <div *ngIf="inventory.length === 0" class="alert alert-danger">
        <app-no-results></app-no-results>
    </div>
</div>

<div *ngIf="showTable === 4">
    <div *ngIf="seller.length === 0" class="alert alert-danger">
        <app-no-results></app-no-results>
    </div>
</div>

<div *ngIf="showTable === 5">
    <div *ngIf="provider.length === 0" class="alert alert-danger">
        <app-no-results></app-no-results>
    </div>
</div>

<div *ngIf="showTable === 6">
    <div *ngIf="activitiesCiiu.length === 0" class="alert alert-danger">
        <app-no-results></app-no-results>
    </div>
</div>

<div *ngIf="showTable === 7">
    <div *ngIf="advancePayments.length === 0" class="alert alert-danger">
        <app-no-results></app-no-results>
    </div>
</div>

<div *ngIf="showTable === 8">
    <div *ngIf="transfers.length === 0" class="alert alert-danger">
        <app-no-results></app-no-results>
    </div>
</div>

<div *ngIf="showTable === 9">
    <div *ngIf="warehouseTransfers.length === 0" class="alert alert-danger">
        <app-no-results></app-no-results>
    </div>
</div>

<div *ngIf="showTable === 10">
    <div *ngIf="warehouseOutputsAndEntries.length === 0" class="custom-container">
        <div class="select-type">
            <mat-form-field>
                <mat-label>{{'Type' | translate}}</mat-label>
                <mat-select disableRipple [formControl]="typeForm">
                    <mat-option class="multiline-mat-option" *ngFor="let type of types" [value]="type">
                        {{type.value | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="warehouseOutputsAndEntries.length === 0" class="alert alert-danger">
        <app-no-results></app-no-results>
    </div>
</div>

<div *ngIf="showTable === 11">
    <div *ngIf="inventoryReclassifications.length === 0" class="alert alert-danger">
        <app-no-results></app-no-results>
    </div>
</div>
