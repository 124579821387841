import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DxVectorMapComponent } from "devextreme-angular";
import { IMapsData } from "src/app/@interfaces/Home/sales-by-region.interface";
import {
  FeatureCollection,
  Marker,
} from "src/app/@interfaces/marker.interface";

function importar(): any {
  const mapsData = require("devextreme/dist/js/vectormap-data/world.js");
  return mapsData;
}

@Component({
  selector: "app-maps",
  templateUrl: "./maps.component.html",
  styleUrls: ["./maps.component.css"],
})
export class MapsComponent implements OnChanges {
  //Map
  map: any = importar();
  worldMap: any = this.map.world;

  @Input() arrayData: IMapsData[] = [];
  @ViewChild("vectormap", { static: false }) dataGrid:
    | DxVectorMapComponent
    | undefined;
  @Input() markers: FeatureCollection | undefined;
  @Input() typePres: string = "All";
  @Input() streetMarkers: Marker[] = [];
  @Input() prospectiveCustomers: Marker[] = [];
  prospectiveCustomersMarker = "../../../../../assets/icons/Map-Marker-PNG-Blue.png";
  markerUrl = "../../../../../assets/icons/map-marker.png";

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  render() {
    if (this.dataGrid?.instance) {
      this.dataGrid?.instance.render();
    }
  }

  customizeText(arg: any) {
    return ["< 5000K", "5000K to 24000K", "> 25000K"][arg.index];
  }

  customizeItems(items: any) {
    return items.reverse();
  }

  customizeTooltip(arg: any): any {
    if (arg.layer.type === "marker") {
      return {
        text: arg.attribute("tooltip"),
      };
    }
  }

  markerClick(e: any) {
    if (e.target && e.target.layer.type === "marker") {
      e.component.center(e.target.coordinates()).zoomFactor(40);
    }
  }
}
