import { gql } from "apollo-angular";
import { SUBSCRIPTION_INV_OBJECT, SUBSCRIPTION_QUOT_OBJECT } from "./fragment";

export const SUBS_NEW_QUOT = gql`
  subscription newQuotation {
    newQuotation {
      ...SubscriptionQuotation
    }
  }
  ${SUBSCRIPTION_QUOT_OBJECT}
`;

export const SUBS_INV_QUOT = gql`
  subscription invoiceQuota {
    invoiceQuota {
      ...SubscriptionInvoice
    }
  }
  ${SUBSCRIPTION_INV_OBJECT}
`;
