<mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="start center">
        <img fxFlexAlign="start" class="logo" src="../../../../../assets/icons/icono.png" onerror="this.src='assets/img/cantfindimage.jpg'"/>
        <div>
          <h2 mat-dialog-tittle>{{ "Inventory Outputs And Entries" | translate }}</h2>
        </div>
    </div>

    <div class="selected-data">
        <span class="caption">{{"Select A Type" | translate}}</span>
    </div>

    <div class="custom-container">
        <form [formGroup]="outputForm" fxflex fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.lt-lg="column">
            <div class="custom-items-container transfer-item">
                <mat-form-field class="custom-items-container">
                    <mat-label>{{'Type' | translate}}</mat-label>
                    <mat-select disableRipple formControlName="type">
                        <mat-option class="multiline-mat-option" *ngFor="let type of types" [value]="type">
                            {{type.value | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="outputForm.value.type.id === 2" class="custom-items-container">
                <mat-form-field class="custom-items-container">
                    <mat-label>{{'Cost' | translate}}</mat-label>
                    <input type="number" matInput formControlName="cost" autocomplete="off">
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="selected-data">
        <span *ngIf="!outputForm.value.type" class="caption">{{"Outputs Or Entries" | translate}}</span>
        <span *ngIf="outputForm.value.type" class="caption">{{outputForm.value.type.value | translate}}</span>
    </div>

    <div class="custom-container">
        <form [formGroup]="outputForm" fxflex fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayout.lt-lg="column">
            <div class="custom-items-container transfer-item">
                <mat-form-field class="custom-items-container">
                    <mat-label>{{'Article' | translate}}</mat-label>
                    <input id="input-top" type="text" matInput [matAutocomplete]="autoArticle" formControlName="article">
                    <mat-autocomplete #autoArticle="matAutocomplete" [displayWith]="displayFnArticle">
                        <mat-option class="multiline-mat-option" *ngFor="let article of articleList" [value]="article">
                            {{article.descripcion}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="custom-items-container">
                <mat-form-field class="custom-items-container">
                    <mat-label>{{'Quantity' | translate}}</mat-label>
                    <input type="number" matInput formControlName="value" autocomplete="off">  
                </mat-form-field>
            </div>
            <div class="custom-items-container transfer-item">
                <mat-form-field class="custom-items-container"> 
                    <mat-label>{{'Warehouse' | translate}}</mat-label>
                    <input id="input-top" type="text" matInput [matAutocomplete]="autoWarehouse" formControlName="warehouse">
                    <mat-autocomplete #autoWarehouse="matAutocomplete" [displayWith]="displayFnWarehouse">
                        <mat-option class="multiline-mat-option" *ngFor="let warehouse of warehouseFilter | async" [value]="warehouse">
                            {{warehouse.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="custom-items-container transfer-item">
                <mat-form-field class="custom-items-container"> 
                    <mat-label>{{'Reason' | translate}}</mat-label>
                    <input id="input-top" type="text" matInput formControlName="description">
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="selected-data">
        <span class="caption">{{"Date" | translate}}</span>
    </div>

    <div class="inputs-container" fxflex fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.xs="column" fxLayout.lt-lg="column">
        <div class="date-container">
            <mat-form-field appearance="fill">
                <mat-label>{{'Date' | translate}}</mat-label>
                <input class="date-input" matInput [matDatepicker]="picker" [formControl]="dateForm">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="custom-items-container transfer-button">
            <button class="register-custom-button" mat-raised-button (click)="register();">{{'Register' | translate}}</button>
        </div>
        <div class="custom-items-container transfer-button">
            <button mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
        </div>
    </div>
</mat-dialog-content>
