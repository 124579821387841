<div style="float: left; padding: 25px 0px 0px 10px">
  <a
    ><button mat-raised-button class="Warn" (click)="openDialog()">
      {{ "Register" | translate }}
    </button></a
  >
</div>

<div style="float: left; padding: 25px 0px 0px 10px">
  <input
    class="form-control"
    [formControl]="citiesForm"
    name="city"
    placeholder="{{ 'Filter by city' | translate }}"
    [matAutocomplete]="autoCity"
    style="width: 300px"
  />
  <mat-autocomplete #autoCity="matAutocomplete" [displayWith]="displayFnCity">
    <mat-option
      *ngFor="let city of filteredOptionsCities | async"
      [value]="city"
    >
      {{ city.name }}
    </mat-option>
  </mat-autocomplete>
</div>

<div *ngIf="activitiesList.length >= 0">
  <app-table [activitiesCiiu]="activitiesList" [showTable]="6"></app-table>
</div>

<ngx-spinner
  type="ball-scale-multiple"
  template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />"
  bdColor="rgba(51,51,51,0.8)"
>
  <p style="font-size: 20px; color: #ffffff">{{ "Loading..." | translate }}</p>
</ngx-spinner>
