import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ICountry } from "src/app/@interfaces/country.interface";
import { IProvider } from "src/app/@interfaces/provider.interface";
import { ProviderService } from "src/app/@pages/provider/provider.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { IDocumenType } from "src/app/@interfaces/authentication/documenType";
import { BillyCities } from "src/app/@interfaces/Billy/billy.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { IDeclarantTypes } from "../../../../../@interfaces/declarantTypes.interface";
import { IRegimeTypes } from "src/app/@interfaces/regimeTypes.interface";
import { CustomerService } from "../../../../../@pages/customer/customer.service";
import { ICompanyType, IStorageCompany } from "../../../../../@interfaces/company.interface";
import { IContributorType } from "src/app/@interfaces/contributorTypes.interface";
import { UsersService } from "src/app/@pages/users/users.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { CitiesFormService } from "../../../services/forms/cities-form.service";
import { CitiesFilterService } from "../../../services/filters/cities-filter.service";

@Component({
  selector: "app-modify-provider",
  templateUrl: "./modify-provider.component.html",
  styleUrls: ["./modify-provider.component.css"],
})
export class ModifyProviderComponent implements OnInit, OnDestroy {
  @Input() id_provider: number = 0;
  cityList: string[] = [];
  filterCities$: Observable<string[]> = new Observable();
  billyCities = {} as BillyCities;
  countryList: Array<ICountry> = [];
  documenTypeList: Array<IDocumenType> = [];
  contributorTypeList: Array<IContributorType> = [];
  declarantsList: Array<IDeclarantTypes> = [];
  regimeList: Array<IRegimeTypes> = [];
  companyTypeList: Array<ICompanyType> = [];
  providerModify = {} as IProvider;
  providerForm: UntypedFormGroup = this.fb.group({
    doc_nit: ['', [Validators.required, Validators.pattern(this.validatorS.documentPattern)]],
    types_docs: ['', [Validators.required]],
    name: ['', [Validators.required, Validators.maxLength(60)]],
    address: ['', [Validators.required, Validators.maxLength(100)]],
    phone: ['', [Validators.required, Validators.pattern(this.validatorS.documentPattern), Validators.maxLength(15)]],
    email: ['', [Validators.required, Validators.pattern(this.validatorS.emailPattern), Validators.maxLength(50)]],
    country: ['', Validators.required],
    city: ['', Validators.required],
    citySelect: [''],
    contributorType: ["", [Validators.required]],
    regime: [''],
    declarant: [''],
    type: [''],
    activity_code: ['']
  });

  companyId = '';
  unsubscribe$ = new Subject();
  companyObject = {} as IStorageCompany;

  constructor(
    private translate: TranslateService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private providerService: ProviderService,
    private dialog: MatDialog,
    private customerService: CustomerService,
    private userService: UsersService,
    private cookieAuthService: CookieAuthService,
    private citiesFormService: CitiesFormService,
    private citiesFilterService: CitiesFilterService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.loadData();
    if (this.companyObject.countries[0].name === "Colombia") {
      this.setValid();
    }
    this.citiesFromStore();
  }

  citiesFromStore() {
    this.citiesFormService.setInitialCitiesFromStore();
    this.providerForm.get('country')?.valueChanges.subscribe((result) => {
      this.spinnerService.show();
      this.setCity(result)
    })
    this.filterCities$ = this.providerForm.get('citySelect')!.valueChanges.pipe(
      startWith(""),
      map((value) => this.citiesFilterService.filterCities(value, this.cityList))
    );
  }

  loadData() {
    this.providerService
    .getProvider(this.id_provider)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.providerModify = result;
      this.setProvider(this.providerModify);
      this.providerService
      .getDeclarantByRegime(
        parseInt(this.providerModify?.id_regime!.toString())
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.declarantsList = result;
      });
    });

    this.customerService
    .listCountries()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.countryList = result;
    });

    this.userService
    .listDocumenTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.documenTypeList = result;
    });

    this.providerService
    .getAllRegimeTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.regimeList = result;
    });

    this.providerService
    .getAllCompanyTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.companyTypeList = result;
    });

    this.customerService
    .listContributorTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.contributorTypeList = result;
    })
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject.Id_company!.toString();
  }

  setValid() {
    this.providerForm.get("regime")?.setValidators(Validators.required);
    this.providerForm.get("declarant")?.setValidators(Validators.required);
    this.providerForm.get("type")?.setValidators(Validators.required);
    this.providerForm.get("activity_code")?.setValidators(Validators.required);
  }

  setProvider(providerSets: IProvider) {
    this.providerForm.patchValue({
      doc_nit: providerSets.doc_nit,
      types_docs: providerSets.types_docs![0],
      name: providerSets.nombre,
      email: providerSets.email,
      address: providerSets.direccion,
      phone: providerSets.telefono,
      country: providerSets.country![0],
      contributorType: providerSets.contributorType,
      regime: providerSets.regime_types![0],
      declarant: providerSets.declarant_types![0],
      type: providerSets.company_type![0],
      activity_code: providerSets.activity_code,
    });
    this.setCity(true, providerSets.city!);
  }

  update() {
    this.providerForm.markAllAsTouched();
    if (this.providerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Please, fill in all the required fields!"),
      });
      return;
    }
    this.providerService
    .updateProvider(
      this.id_provider,
      this.providerForm.value.doc_nit,
      parseInt(this.providerForm.value.types_docs.id_docs),
      this.providerForm.value.name,
      this.providerForm.value.address,
      this.providerForm.value.phone,
      this.providerForm.value.email,
      parseInt(this.providerForm.value.country.id_country),
      this.providerForm.value.city,
      parseInt(this.providerForm.value.contributorType.id_contributor),
      parseInt(this.providerForm.value.regime?.id_regime),
      parseInt(this.providerForm.value.declarant?.id_declarant),
      parseInt(this.providerForm.value.type?.id_type),
      this.providerForm.value.activity_code
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.nombre !== "providerExist") {
        Swal.fire(
          this.translate.instant("success"),
          this.translate.instant("Provider updated successfully"),
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            this.router
            .navigateByUrl("/table", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/provider"]);
            });
          }
        });
      } else if (result.nombre === "providerExist") {
        Swal.fire(
          this.translate.instant("Provider was not updated"),
          this.translate.instant("This document provider already exist registered"),
          "warning"
        );
      } else {
        Swal.fire(
          "error",
          this.translate.instant("Something was wrong"),
          "error"
        );
      }
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  textValid(text: string) {
    return (
      this.providerForm.get(text)?.invalid &&
      this.providerForm.get(text)?.touched
    );
  }

  async setCity(setcities: boolean, citySelected?: string) {
    const country = this.providerForm.value.country;
    if (country.name === "Colombia") {
      this.billyCities = await this.citiesFormService.getBillyCities('170');
      if (setcities) {
        this.searchCityByBilly(citySelected!, this.billyCities);
      }
      this.spinnerService.hide();
    } else {
      this.providerForm.patchValue({
        city: citySelected,
        citySelect: citySelected,
      });
      this.cityList = await this.citiesFormService.getCityList(country);
      this.spinnerService.hide();
    }
  }

  searchCityByBilly(citySelected: string, cities: BillyCities) {
    const city = cities.data.find(
      (item: any) => item.attributes.name === citySelected
    );
    let cityData: any = {};
    if (city) {
      cityData.attributes = city.attributes;
      cityData.id = city.id;
      cityData.type = city.type;
      this.providerForm.patchValue({
        city: cityData.attributes.name,
        citySelect: cityData,
      });
    }
  }

  filterDeclarantList() {
    this.providerForm.get("declarant")?.reset();
    this.providerService
    .getDeclarantByRegime(parseInt(this.providerForm.value.regime.id_regime))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.declarantsList = result;
    });
  }

  getCitySelect() {
    if (this.providerForm.value.country.name === "Colombia") {
      this.providerForm
      .get("city")
      ?.setValue(this.providerForm.value.citySelect.attributes.name);
    } else {
      this.providerForm
      .get("city")
      ?.setValue(this.providerForm.value.citySelect);
    }
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  compareDocType(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_docs === item2.id_docs;
  }

  compareCountry(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_country === item2.id_country;
  }

  compareRegime(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_regime === item2.id_regime;
  }

  compareDeclarant(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_declarant === item2.id_declarant;
  }

  compareType(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_type === item2.id_type;
  }

  compareContributor(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_contributor === item2.id_contributor;
  }

  compareCityBilly(item1: any, item2: any) {
    if (!item1 || !item2) {
      return false;
    }
    return item1.attributes.name === item2.attributes.name;
  }

  compareCity(item1: any, item2: any) {
    if (!item1 || !item2) {
      return false;
    }
    return item1 === item2;
  }

  displayFnCity(city: any) {
    return city && city ? city : undefined;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
