import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { IArticles } from "src/app/@interfaces/article.interface";
import { IWarehouse } from "src/app/@interfaces/warehouse.interface";
import { InventoryService } from "src/app/@pages/inventory/inventory.service";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ITaxes } from "src/app/@interfaces/taxes.interface";
import { ITypes } from "src/app/@interfaces/types.interface";
import { IUnits } from "src/app/@interfaces/units.interface";
import { TranslateService } from "@ngx-translate/core";
import { IGrupoArt } from "src/app/@interfaces/grupoart.interface";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { DialogComponent } from "../../dialog.component";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-modify-article",
  templateUrl: "./modify-article.component.html",
  styleUrls: ["./modify-article.component.css"],
})
export class ModifyArticleComponent implements OnInit, OnDestroy {
  @Input() id_article: number = 0;
  warehouseList: Array<IWarehouse> = [];
  taxesList: Array<ITaxes> = [];
  typesList: Array<ITypes> = [];
  unitsList: Array<IUnits> = [];
  grupoartList: Array<IGrupoArt> = [];
  articleModify = {} as IArticles;
  articleForm: UntypedFormGroup = this.fb.group({
    code: ["", Validators.required],
    description: ["", Validators.required],
    presentation: ["",[Validators.required, Validators.pattern(this.validatorS.quantityPattern),],],
    price: ["",[Validators.required, Validators.pattern(this.validatorS.quantityPattern),],],
    quantity: ["",[Validators.required, Validators.pattern(this.validatorS.quantityPattern),],],
    group: ["", Validators.required],
    presentation_id: ["", Validators.required],
    unit: ["", Validators.required],
    warehouse: [""],
    tax: ["", Validators.required],
    type: ["", Validators.required],
    unitBilly: ["", Validators.required],
    quantityInitial: ["",[Validators.pattern(this.validatorS.quantityPattern),],],
    valueInitial: ["",[Validators.pattern(this.validatorS.quantityPattern),],],
  });

  unsubscribe$ = new Subject();
  companyId = '';
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  alternativeCodes: string[] = [];

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private warehouseS: WarehouseService,
    private inventoryS: InventoryService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.inventoryS
    .getArticle(this.id_article)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.articleModify = result;
      this.setArticle(this.articleModify);
      this.alternativeCodes = result.alternativeCodes?.split(",");
    });
    this.warehouseS
    .listWarehouses()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.warehouseList = result;
    });
    this.inventoryS
    .listTaxes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.taxesList = result;
    });

    this.inventoryS
    .listTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.typesList = result;
    });

    this.inventoryS
    .listUnits()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.unitsList = result;
    });

    this.inventoryS
    .listGrupoArt()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.grupoartList = result;
    });
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  setArticle(articleSet: IArticles) {
    this.articleForm.patchValue({
      code: articleSet.codigo,
      description: articleSet.descripcion,
      presentation: articleSet.presentacion,
      price: articleSet.costo,
      quantity: articleSet.cantidad,
      group: articleSet.grupoArt![0],
      presentation_id: articleSet.id_type2,
      unit: articleSet.unidad,
      warehouse: articleSet.almacen![0],
      tax: articleSet.tax![0],
      type: articleSet.type![0],
      unitBilly: articleSet.unit![0],
    });
  }

  update() {
    this.articleForm.markAllAsTouched();
    if (this.articleForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant(
          "Please, fill in all the required fields!"
        ),
      });
      return;
    } else {
      let presentation;
      if (this.articleForm.value.group.presentations) {
        presentation = parseInt(
          this.articleForm.value.group.presentations[0].id_type
        );
      } else {
        presentation = parseInt(this.articleForm.value.presentation_id);
      }
      this.inventoryS
      .updateArticle(
        this.id_article,
        this.articleForm.value.code,
        this.articleForm.value.description,
        this.articleForm.value.presentation,
        this.articleForm.value.price,
        this.articleForm.value.quantity,
        parseInt(this.articleForm.value.group.id_grupo),
        this.articleForm.value.unit,
        parseInt(this.articleForm.value.tax.id_tax),
        parseInt(this.articleForm.value.type.id_type),
        parseInt(this.articleForm.value.unitBilly.id_unit),
        presentation,
        this.alternativeCodes?.toString()
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          Swal.fire(
            this.translate.instant("Done!"),
            this.translate.instant("Article has been updated successfully"),
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              this.router
              .navigateByUrl("/table", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["/inventory"]);
              });
            }
          });
        } else {
          Swal.fire(
            "Error",
            this.translate.instant("Something was wrong"),
            "error"
          );
        }
      });
    }
  }

  warehousesDiaglog(article: IArticles) {
    this.dialog.open(DialogComponent, {
      data: { warehouseOpenings: true, article: article }
    })
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  textValid(text: string) {
    return (
      this.articleForm.get(text)?.invalid && this.articleForm.get(text)?.touched
    );
  }

  compareGroups(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_grupo === item2.id_grupo;
  }

  compareUnits(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_unit === item2.id_unit;
  }

  compareType(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_type === item2.id_type;
  }

  compareTax(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_tax === item2.id_tax;
  }

  compareWarehouse(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_almacen === item2.id_almacen;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) {
      this.alternativeCodes.push(value);
    }
  }

  remove(alternativeCode: string): void {
    const index = this.alternativeCodes.indexOf(alternativeCode);
    if (index >= 0) {
      this.alternativeCodes.splice(index, 1);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
