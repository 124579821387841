<mat-dialog-content> 
<form [formGroup]="articleForm">
    <h2><strong>{{'Modify Article' | translate}}</strong></h2>
    <div class="text-margin">
        <span><strong>{{'Product code' | translate}}:</strong></span>
        <input readonly class="form-control" type="text" placeholder="{{'Code' | translate}}" formControlName="code">
        <span class="form-text text-danger" *ngIf="textValid('code')">{{'You must type a product code' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Product description' | translate}}:</strong></span>
        <input class="form-control" type="text" placeholder="{{'Description' | translate}}" formControlName="description">
        <span class="form-text text-danger" *ngIf="textValid('description')">{{'You must type a description' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Presentation' | translate}}:</strong></span>
        <input class="form-control" type="text" placeholder="{{'Presentation' | translate}}" formControlName="presentation">
        <span class="form-text text-danger" *ngIf="textValid('presentation')">{{'You must type a product presentation' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Price' | translate}}</strong></span>
        <input class="form-control" type="number" placeholder="{{'Price' | translate}}" formControlName="price">
        <span class="form-text text-danger" *ngIf="textValid('price')">{{'You must type a correct price' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Quantity' | translate}}:</strong></span>
        <input class="form-control" type="number" placeholder="{{'Quantity' | translate}}" formControlName="quantity">
        <span class="form-text text-danger" *ngIf="textValid('quantity')">{{'You must type a correct quantity' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Group' | translate}}:</strong></span>
        <select class="form-control" formControlName="group" [compareWith]="compareGroups">
            <option *ngFor="let group of grupoartList" [ngValue]="group">
                {{group.nombre}} - {{group.presentations[0].value}}
            </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('group')">{{'You must select a group' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Billing Unit' | translate}}:</strong></span>
        <input class="form-control" type="text" placeholder="{{'Unit' | translate}}" formControlName="unit">
        <span class="form-text text-danger" *ngIf="textValid('unit')">{{'You must type a unit' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>2° {{'Unit' | translate}}:</strong></span>
        <select class="form-control" formControlName="unitBilly" [compareWith]="compareUnits">
            <option *ngFor="let unit of unitsList" [ngValue]="unit">
                {{unit.description}}
            </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('unitBilly')">{{'You must select a unit' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Type' | translate}}:</strong></span>
        <select class="form-control" formControlName="type" [compareWith]="compareType">
            <option *ngFor="let type of typesList" [ngValue]="type">
                {{type.description}}
            </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('type')">{{'You must select a type' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Tax' | translate}}:</strong></span>
        <select class="form-control" formControlName="tax" [compareWith]="compareTax">
            <option *ngFor="let tax of taxesList" [ngValue]="tax">
                {{tax.type}} - ({{tax.value}}%)
            </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('tax')">{{'You must select a tax' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Alternative Codes' | translate}}:</strong></span>
        <mat-form-field class="example-chip-list" appearance="fill">
            <mat-chip-list #chipList>
            <mat-chip *ngFor="let codes of alternativeCodes" (removed)="remove(codes)">
                {{codes}}
                <button matChipRemove>
                <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <input placeholder="{{'Codes...' | translate}}"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </mat-form-field>
    </div>
    <div class="text-margin">
        <div>
            <button class="warehouse-button" (click)="warehousesDiaglog(articleModify)" mat-raised-button>{{'Warehouses' | translate}}</button>
        </div>
    </div>
    <div class="row action-buttons" id="buttons-div">
        <div class="col" id="buttons">
            <button (click)="update()" mat-raised-button>{{'Modify' | translate}}</button>
        </div>
        <div class="col" id="buttons">
            <button mat-raised-button [mat-dialog-close]>{{'Cancel' | translate}}</button>
        </div>
    </div>
</form>
</mat-dialog-content>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
