import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Data } from "../../../components/multiple-pane/multiple-pane.component";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { IInitPerItemData, ILiterPerItemData, ITopPerItemData } from "src/app/@interfaces/Analysis/liter-per-item.interface";
import { CalculateAnalysis } from '../../../../../@shared/components/services/charts/calculate-analysis.service';
import { ITopCustomerNotes } from "src/app/@interfaces/Analysis/dollar-per-client.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { ConverseArrayService } from '../../../../../@shared/components/services/data-types/converse-array.service';

@Component({
  selector: "app-liter-per-item-graph",
  templateUrl: "./liter-per-item-graph.component.html",
  styleUrls: ["./liter-per-item-graph.component.css"],
})
export class LiterPerItemGraphComponent implements OnChanges {
  @Input() months: number = 0;
  @Input() typePres: string = "All";
  @Input() typeGraph: string = "";
  @Input() literPerItemData = {} as ILiterPerItemData;
  @Input() notesByDescription: ITopCustomerNotes[] = [];
  
  literPerItemDataToUse = {} as ILiterPerItemData;
  notesByDescriptionToUse: ITopCustomerNotes[] = [];

  dataLiters: Data[] = [];
  dataQuants: Data[] = [];
  month: number = 0;
  companyObject = {} as IStorageCompany;

  constructor(
    private calculateAnalysis: CalculateAnalysis,
    private cookieAuthService: CookieAuthService,
    private converseArrayService: ConverseArrayService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "literPerItemData") {
        const values: ILiterPerItemData = changes[item].currentValue;
        if (values) {
          this.literPerItemDataToUse = values;
        }
      }
      if (item === "notesByDescription") {
        const note: Array<ITopCustomerNotes> = changes[item].currentValue;
        if (note.length > 0) {
          this.notesByDescriptionToUse = note;
        }
      }
      if (item === "months") {
        const value: number = changes[item].currentValue;
        if (value > 0) {
          this.month = value;
        }
      }

      if (this.literPerItemDataToUse.initialData || this.notesByDescriptionToUse.length > 0 || this.month > 0) {
        this.topCalculation(this.literPerItemDataToUse, this.notesByDescriptionToUse, this.month);
      }
    }
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  topCalculation(literPerItemData: ILiterPerItemData, notesByDescription: ITopCustomerNotes[], months: number) {
    const reduceArticles = this.converseArrayService.reduceArrays(literPerItemData.articlesArray);
    const calculated = this.calculation(reduceArticles, literPerItemData.initialData, notesByDescription);

    const litersSorted = calculated.litersArray.sort((a, b) => b.liters - a.liters);
    const top10Liters = litersSorted.slice(0, 20);

    const quantitiesSorted = calculated.quantitiesArray.sort((a, b) => b.quantities - a.quantities);
    const top10Quantities = quantitiesSorted.slice(0, 20);

    this.dataQuants = this.calculateAnalysis.constructAvgArray(top10Quantities, months, "quantities");
    this.dataLiters = this.calculateAnalysis.constructAvgArray(top10Liters, months, "liters");
  }

  calculation(reduceArticles: string[], initialData: IInitPerItemData[], notesByDescription: ITopCustomerNotes[]) {
    const result = reduceArticles.map(element => {
      const filterByElement = initialData.filter(data => data.item === element);
      const calculatedByElement = this.calculateByElement(filterByElement, notesByDescription);
      return {
        liters: calculatedByElement.topLiters.amount,
        item: calculatedByElement.topLiters.name,
        quantities: calculatedByElement.topQuantities.amount,
      };
    });
    const litersArray = result.map(item => ({ liters: item.liters, item: item.item }));
    const quantitiesArray = result.map(item => ({ quantities: item.quantities, item: item.item }));
    return { litersArray: litersArray, quantitiesArray: quantitiesArray };
  }

  calculateByElement(filterByElement: IInitPerItemData[], notesByDescription: ITopCustomerNotes[]) {
    const reduceByElement = filterByElement.reduce((acc, item) => {
      acc.quantities += item.quantity;
      acc.liters = acc.quantities * item.liter;
      acc.item = item.item;
      acc.code = item.code;
      return acc;
    }, {
      quantities: 0,
      liters: 0,
      item: '',
      code: ''
    });
    const topLiters: ITopPerItemData = {
      amount: reduceByElement.liters,
      name: reduceByElement.item,
      code: reduceByElement.code,
    };
    const topQuantities: ITopPerItemData = {
      amount: reduceByElement.quantities,
      name: reduceByElement.item,
      code: reduceByElement.code,
    };
    return this.applyNoteValues(notesByDescription, topLiters, topQuantities);
  }

  applyNoteValues(notesByDescription: ITopCustomerNotes[], topLiters: ITopPerItemData, topQuantities: ITopPerItemData) {
    const updatedTopLiters = notesByDescription.reduce((accTopLiters, item) => {
      if (accTopLiters.name === item.articulo) {
        accTopLiters.amount += item.litros * 1;
      }
      return accTopLiters;
    }, topLiters);
  
    const updatedTopQuantities = notesByDescription.reduce((accTopQuantities, item) => {
      if (accTopQuantities.name === item.articulo) {
        accTopQuantities.amount += item.cantidad * 1;
      }
      return accTopQuantities;
    }, topQuantities);
  
    return {
      topLiters: updatedTopLiters,
      topQuantities: updatedTopQuantities,
    };
  }
}
