import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { catchError, map } from "rxjs/operators";
import {
  CREATE_USER,
  UPDATE_USER,
  ADD_USER_COMPANY,
  REMOVE_USER_COMPANY,
  UPDATE_PASS,
} from "src/app/@graphql/operations/mutation";
import {
  GET_ME,
  GET_ROL,
  GET_USER,
  GET_USERS,
  VERIFY_DOCUMENTS,
  GET_ALL_DOC_TYPES,
  GET_USER_SELLER_BY_USER,
  GET_ALL_USER_SELLERS,
} from "src/app/@graphql/operations/query";
import { ApiService } from "src/app/@graphql/services/api.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class UsersService extends ApiService {
  constructor(
    apollo: Apollo,
    private cookieAuthService: CookieAuthService
  ) {
    super(apollo);
  }

  listUsers() {
    return this.query(GET_USERS).pipe(
      map((result: any) => {
        return result.getAllUsers;
      })
    );
  }
  getUser(id: number) {
    return this.query(GET_USER, { id }).pipe(
      map((result: any) => {
        return result.getUser;
      })
    );
  }
  me(token: string) {
    return this.query(
      GET_ME,
      {},
      {
        headers: new HttpHeaders({
          authorization: token,
        }),
      }
    ).pipe(
      map((result: any) => {
        return result.me;
      })
    );
  }
  getRoles() {
    return this.query(GET_ROL).pipe(
      map((result: any) => {
        return result.getAllRoles;
      })
    );
  }
  verifyDocuments(document_type: number, document: number) {
    return this.query(VERIFY_DOCUMENTS, { document_type, document }).pipe(
      map((result: any) => {
        return result.verifyDoc;
      })
    );
  }
  registerUser(
    document: number,
    name: string,
    email: string,
    username: string,
    password: string,
    id_rol: number,
    id_docs: number,
    id_company: number,
    id_prefix: number
  ) {
    return this.mutation(CREATE_USER, {
      document,
      name,
      email,
      username,
      password,
      id_rol,
      id_docs,
      id_company,
      id_prefix
    }).pipe(
      map((result: any) => {
        return result.registerUser;
      })
    );
  }
  updateUser(
    id: number,
    document: number,
    name: String,
    email: String,
    username: String,
    id_rol: number,
    status: number,
    id_docs: number,
  ) {
    return this.mutation(UPDATE_USER, {
      id,
      document,
      name,
      email,
      username,
      id_rol,
      status,
      id_docs,
    }).pipe(
      map((result: any) => {
        return result.updateUser;
      })
    );
  }
  updateUserStatus(id: number, status: number) {
    return this.mutation(UPDATE_USER, { id, status }).pipe(
      map((result: any) => {
        return result.updateUser;
      })
    );
  }
  addCompanies(id_user: number, id_company: number, id_prefix: number) {
    return this.mutation(ADD_USER_COMPANY, { id_user, id_company, id_prefix }).pipe(
      map((result: any) => {
        return result.addCompany;
      })
    );
  }
  removeCompanies(id_user: number, id_company: number) {
    return this.mutation(REMOVE_USER_COMPANY, { id_user, id_company }).pipe(
      map((result: any) => {
        return result.removeCompany;
      })
    );
  }
  updatePass(id_user: number, password: string) {
    return this.mutation(UPDATE_PASS, { id_user, password }).pipe(
      map((result: any) => {
        return result.updatePassword;
      })
    );
  }

  listDocumenTypes() {
    return this.query(GET_ALL_DOC_TYPES).pipe(
      map((result: any) => {
        return result.getAllDocTypes;
      })
    );
  }

  async isRoleAdmin() {
    const rol = this.cookieAuthService.getRolId!;
    return rol === "1";
  }

  async isRoleFullOperator() {
    const rol = this.cookieAuthService.getRolId!;
    return rol === "3";
  }

  getSellerByUser(id_user: number, company: string) {
    return this.query(GET_USER_SELLER_BY_USER, { id_user, company }).pipe(
      map((result: any) => {
        return result.getUserSellerByUser;
      })
    );
  }

  getAllSellerUser() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_USER_SELLERS, { company }).pipe(
      map((result: any) => {
        return result.getAllUserSellers;
      })
    );
  }

  async getInitialRouteByRole() {
    const rol = this.cookieAuthService.getRolId!;
    return rol === "4" ? "/inventory" : "/sales";
  }
}
