import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { IPaymentsList } from "src/app/@interfaces/payments.interface";
import { PortfolioService } from "src/app/@pages/portfolio/portfolio.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { UsersService } from "../../../../../@pages/users/users.service";
import { IUser } from "src/app/@interfaces/user.interface";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-crud-banks",
  templateUrl: "./crud-banks.component.html",
  styleUrls: ["./crud-banks.component.css"],
})
export class CrudBanksComponent implements OnInit {
  registerForm: UntypedFormGroup = this.fb.group({
    bank: ["", [Validators.required]],
    user: ["", [Validators.required]],
  });

  @Input() listBanks: Array<IPaymentsList> = [];
  @Input() groupPayment: string = "";
  userList: Array<IUser> = [];
  unsubscribe$ = new Subject();
  constructor(
    private user: UsersService,
    private fb: UntypedFormBuilder,
    private portfolio: PortfolioService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.portfolio
    .getBankList()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.listBanks = result;
    });
    this.user
    .listUsers()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.userList = result;
    });
  }

  remove(id: number, index: number) {
    Swal.fire({
      title: this.translate.instant("Are you sure?"),
      text: this.translate.instant("You won not be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5400",
      cancelButtonColor: "rgb(0, 0, 0)",
      confirmButtonText: "Confirm",
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.portfolio
        .removePayments(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (!result) {
            Swal.fire(
              "Error",
              this.translate.instant("Something was wrong"),
              "error"
            );
          } else {
            Swal.fire(
              this.translate.instant("Good"),
              this.translate.instant("Bank removed"),
              "success"
            ).then((data) => {
              if (data.isConfirmed) {
                if (this.groupPayment === "Portfolio") {
                  this.router
                  .navigateByUrl("/dialog", { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate(["/portfolio"]);
                  });
                } else {
                  this.router
                  .navigateByUrl("/dialog", { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate(["/accountsPayable"]);
                  });
                }
                this.portfolio
                .getBankList()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((result) => {
                  this.listBanks = result;
                });
              }
            });
          }
        });
      }
    });
  }

  update() {
    if (this.registerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Bank Invalid, Please Check!"),
      });
      return;
    }
    this.portfolio
    .createPayments(
      "Bank",
      this.registerForm.get("bank")?.value,
      parseInt(this.registerForm.get("user")?.value.id)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      Swal.fire({
        title: this.translate.instant("Created"),
        text: this.translate.instant("Bank created successfully!"),
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#f44336",
        confirmButtonText: "Ok",
      }).then((data) => {
        if (data.isConfirmed) {
          if (this.groupPayment === "Portfolio") {
            this.router
            .navigateByUrl("/dialog", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/portfolio"]);
            });
          } else {
            this.router
            .navigateByUrl("/dialog", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/accountsPayable"]);
            });
          }
          this.portfolio
          .getBankList()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result) => {
            this.listBanks = result;
          });
        }
      });
    },(error) => {
      Swal.fire(
        this.translate.instant("Something was wrong"),
        this.translate.instant("Failed"),
        error
      );
    });
  }

  textValid(text: string, index?: number) {
    return (
      this.registerForm.get(text)?.invalid &&
      this.registerForm.get(text)?.touched
    );
  }

  getUser(list: IUser[], id: number): string {
    let name = "";
    list.forEach((element) => {
      if (parseInt(element.id.toString()) === parseInt(id.toString())) {
        name = element.name;
      }
    });
    return name;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
