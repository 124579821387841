import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { TableModule } from 'src/app/@shared/components/table/table.module';
import { SearchModule } from 'src/app/@shared/components/search/search.module';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { MaterialModule } from 'src/app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [UsersComponent],
  imports: [
    CommonModule,
    MaterialModule,
    UsersRoutingModule,
    TableModule,
    SearchModule,
    MatIconModule,
    DialogModule,
    TranslateModule,
    NgxSpinnerModule
  ]
})
export class UsersModule { }
