import { Component, OnInit, Input } from "@angular/core";
import { IArticles } from "src/app/@interfaces/article.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-details-article",
  templateUrl: "./details-article.component.html",
  styleUrls: ["./details-article.component.css"],
})
export class DetailsArticleComponent implements OnInit {
  @Input() article = {} as IArticles;
  companyId = '';

  constructor(private cookieAuthService: CookieAuthService) {}

  ngOnInit(): void {
    this.getAuthValues();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }
}
