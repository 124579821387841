
<div class="row">

    <div class="col-md-6">
        <div class="alert alert-info">
            {{'Total sales this month' | translate}} ({{'Quotation' | translate}})
        </div>
        <div style="display: block; max-width: 600px; max-height: 900px;" #divGrafica>
            <app-bar-chart [barChartLabels]="dataVentasTodayLabel" [barChartSingleData]="dataVentasToday" [barChartData]="barChartDataSets"></app-bar-chart>
        </div>
    </div>
    
    <div class="col-md-6">
        <div class="alert alert-info">
            {{'Total sales this month' | translate}} ({{'Invoice' | translate}})
        </div>
        <div style="display: block; max-width: 600px; max-height: 900px;" #divGrafica>
            <app-bar-chart [barChartLabels]="barChartInvLabel" [barChartSingleData]="barChartDataInv" [barChartData]="barChartDataSets5"></app-bar-chart>
        </div>
    </div>

</div>

<div class="row">

    <div class="col-md-6">
        <div class="alert alert-info">
            {{'Total sales this month' | translate}} ({{'Delivery' | translate}})
        </div>
        <div style="display: block; max-width: 600px; max-height: 900px;" #divGrafica>
            <app-bar-chart [barChartLabels]="barChartDelLabel" [barChartSingleData]="barChartDataDel" [barChartData]="barChartDataSets6"></app-bar-chart>
        </div>
    </div>

    <div class="col-md-6">
        <div class="alert alert-info">
            {{'Quantities sales this month' | translate}} ({{'Quotation' | translate}})
        </div>
        <div style="display: block; max-width: 600px; max-height: 900px;" #divGrafica>
            <app-multiple-pane indicator="Lts" nameTop="Promedio" nameBot="Total" [data]="barChartDataCants1"></app-multiple-pane>
        </div>
    </div>

</div>

<div class="row">

    <div class="col-md-6">
        <div class="alert alert-info">
            {{'Quantities sales this month' | translate}} ({{'Delivery' | translate}})
        </div>
        <div style="display: block; max-width: 600px; max-height: 900px;" #divGrafica>
            <app-multiple-pane indicator="Lts" nameTop="Promedio" nameBot="Total" [data]="barChartDataCants2"></app-multiple-pane>
        </div>
    </div>

    <div class="col-md-6">
        <div class="alert alert-info">
            {{'Quantities sales this month' | translate}} ({{'Invoice' | translate}})
        </div>
        <div style="display: block; max-width: 600px; max-height: 900px;" #divGrafica>
            <app-multiple-pane indicator="Lts" nameTop="Promedio" nameBot="Total" [data]="barChartDataCants3"></app-multiple-pane>
        </div>
    </div>
    
</div>
