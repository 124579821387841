import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import { TributaryService } from "../../../../../@pages/tributary/tributary.service";
import { Subject } from "rxjs";
import { ICities } from "../../../../../@interfaces/cities.interface";
import { takeUntil } from "rxjs/operators";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { IActivitiesCiiu } from "../../../../../@interfaces/activitiesciiu.interface";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-modify-activities",
  templateUrl: "./modify-activities.component.html",
  styleUrls: ["./modify-activities.component.css"],
})
export class ModifyActivitiesComponent implements OnInit, OnDestroy {
  @Input() id_activity = 0;
  activityForm: UntypedFormGroup = this.fb.group({
    ica_code: ["", [Validators.required]],
    activity_code: ["", [Validators.required]],
    activity_description: ["", [Validators.required]],
    actual_rate: ["", [Validators.required, Validators.maxLength(60)]],
    city: ["", [Validators.required, Validators.maxLength(100)]],
  });
  citiesList: ICities[] = [];
  unsubscribe$ = new Subject();
  activityModify = {} as IActivitiesCiiu;
  companyObject = {} as IStorageCompany;

  constructor(
    private fb: UntypedFormBuilder,
    private tributaryService: TributaryService,
    private translate: TranslateService,
    private router: Router,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    const country_id = this.companyObject.countries[0].id_country;
    this.tributaryService
    .getCitiesByCompany(parseInt(country_id!.toString()))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.citiesList = result;
    });
    this.tributaryService
    .getSingleActivity(this.id_activity)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.activityModify = result;
      this.setActivity(this.activityModify);
    });
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  setActivity(activitySets: IActivitiesCiiu) {
    this.activityForm.patchValue({
      ica_code: activitySets.ica_code,
      activity_code: activitySets.activity_code,
      activity_description: activitySets.activity_description,
      actual_rate: activitySets.actual_rate,
      city: activitySets.cities[0],
    });
  }

  textValid(text: string) {
    return (
      this.activityForm.get(text)?.invalid &&
      this.activityForm.get(text)?.touched
    );
  }

  update() {
    this.activityForm.markAllAsTouched();
    if (this.activityForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant(
          "Please, fill in all the required fields!"
        ),
      });
      return;
    } else {
      this.saveOnDataBase();
    }
  }

  saveOnDataBase() {
    this.tributaryService
    .modifyActivity(
      this.id_activity,
      parseInt(this.activityForm.value.city.id_city),
      this.activityForm.value.ica_code,
      this.activityForm.value.activity_code,
      this.activityForm.value.activity_description,
      parseFloat(this.activityForm.value.actual_rate)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        Swal.fire(
          this.translate.instant("success"),
          this.translate.instant("Activity updated successfully"),
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            this.router
            .navigateByUrl("/table", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/tributary"]);
            });
          }
        });
      } else {
        Swal.fire(
          "error",
          this.translate.instant("Something was wrong"),
          "error"
        );
      }
    },(error) => {
      Swal.fire(
        "error",
        this.translate.instant("Something was wrong"),
        "error"
      );
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  compareCity(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_city === item2.id_city;
  }
}
