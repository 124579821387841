import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ITopCustomerNotes } from "src/app/@interfaces/Analysis/dollar-per-client.interface";
import { IInvoicesMapsData, IMapsData } from "src/app/@interfaces/Home/sales-by-region.interface";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import {
  DataFeature,
  FeatureCollection,
  Marker,
} from "src/app/@interfaces/marker.interface";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { AnalysisService } from "src/app/@pages/analysis/analysis.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-sales-by-region",
  templateUrl: "./sales-by-region.component.html",
  styleUrls: ["./sales-by-region.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesByRegionComponent implements OnChanges {
  @Input() quotations: Array<IQuota> = [];
  @Input() notes: Array<INotasGrap> = [];
  @Input() presentationType: string = "All";

  allQuotations: Array<IQuota> = [];
  allNotes: Array<INotasGrap> = [];

  dataToMaps: IMapsData[] = [];
  mapFeatures: FeatureCollection | undefined;
  streetMarkers: Marker[] = [];
  prospectiveCustomers: Marker[] = [];

  highSale: number = 0;
  lowSale: number = 0;
  halves: number = 0;

  currentYear = new Date().getFullYear();
  companyObject = {} as IStorageCompany;

  constructor(
    private analysisService: AnalysisService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "quotations") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.allQuotations = values;
        }
      }
      if (item === "notes") {
        const note: Array<INotasGrap> = changes[item].currentValue;
        if (note.length > 0) {
          this.allNotes = note;
        }
      }
      if (this.allQuotations.length > 0 || (this.allNotes.length > 0)) {
        this.getSalesByAdressCustomer(this.allQuotations, this.allNotes);
      }
    }
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  getSalesByAdressCustomer(invoices: IQuota[], notes: INotasGrap[]) {
    let filterInvoices = this.filterInvoices(invoices);
    let featuresMaps: FeatureCollection | undefined;
    let toMaps: IMapsData[] = [];

    const invoiceValues = this.getInvoiceValues(filterInvoices);
    const reduceArrayInit = this.analysisService.reduceArrayMaps(
      invoiceValues.arrayData
    );
    const arrayGroupNotes = this.analysisService.getNoteValues(
      notes,
      invoiceValues.arrayInvoices
    );
    const reduceNotes = this.analysisService.reduceNotes(arrayGroupNotes);
    let reduceCoordinates = invoiceValues.arrayCoordinates.filter((item, index) => {
      return invoiceValues.arrayCoordinates.indexOf(item) === index;
    });

    const arraysMaps = this.constructArraysMaps(reduceCoordinates, reduceArrayInit, reduceNotes);
    featuresMaps = this.constructFeatureMaps(arraysMaps.featuresArray);
    toMaps = arraysMaps.arrayFilter;

    this.setDatesToMainProperties(
      arraysMaps.arrayUsdAmount,
      toMaps,
      featuresMaps,
      arraysMaps.arrayCurrentCustomerStreet,
      arraysMaps.arrayProspectiveCustomerStreet
    );
  }

  constructArraysMaps(reduceCoordinates: string[], reduceArrayInit: IInvoicesMapsData[], reduceNotes: ITopCustomerNotes[]) {
    let objectFeatures: DataFeature;
    let featuresArray: DataFeature[] = [];
    let arrayUsdAmount: number[] = [];
    let arrayFilter: IMapsData[] = [];
    let currentCustomerStreet = {} as Marker;
    let arrayCurrentCustomerStreet: Marker[] = [];
    let prospectiveCustomerStreet = {} as Marker;
    let arrayProspectiveCustomerStreet: Marker[] = [];
    reduceCoordinates.forEach((coordinate) => {
      let filterByName = reduceArrayInit.filter(
        (dato) => dato.coordinates === coordinate
      );
      const calculatedByCustomer = this.calculateByCustomer(
        filterByName,
        reduceNotes
      );
      objectFeatures = this.constructObjectFeatures(calculatedByCustomer);
      if (calculatedByCustomer.isProspective) {
        prospectiveCustomerStreet = this.constructStreetArray(calculatedByCustomer);
        arrayProspectiveCustomerStreet.push(prospectiveCustomerStreet);
      }else {
        currentCustomerStreet = this.constructStreetArray(calculatedByCustomer);
        arrayCurrentCustomerStreet.push(currentCustomerStreet);
      }
      featuresArray.push(objectFeatures);
      arrayUsdAmount.push(parseFloat(calculatedByCustomer.usdAmount));
      arrayFilter.push({
        usdAmount: new Intl.NumberFormat("de-DE").format(
          parseFloat(calculatedByCustomer.usdAmount)
        ),
        pastAmount: new Intl.NumberFormat("de-DE").format(
          parseFloat(calculatedByCustomer.pastAmount)
        ),
        coordinates: calculatedByCustomer.coordinates,
        customerName: calculatedByCustomer.customerName,
        isProspective: calculatedByCustomer.isProspective
      });
    });
    return { featuresArray: featuresArray, arrayFilter: arrayFilter, arrayUsdAmount: arrayUsdAmount, arrayCurrentCustomerStreet: arrayCurrentCustomerStreet, arrayProspectiveCustomerStreet: arrayProspectiveCustomerStreet }
  }

  setDatesToMainProperties(arrayUsdAmount: number[], toMaps: IMapsData[], featuresMaps: FeatureCollection, arrayCurrentCustomerStreet: Marker[], arrayProspectiveCustomerStreet: Marker[]) {
    let arraySort = arrayUsdAmount.sort((a, b) => b - a);
    let lengthAmount = arraySort.length;
    let firtsValue = arraySort[0];
    let secondValue = arraySort[lengthAmount - 1];
    let halve = (firtsValue - secondValue) / 2;
    this.dataToMaps = toMaps;
    this.mapFeatures = featuresMaps;
    this.highSale = firtsValue;
    this.lowSale = secondValue;
    this.halves = halve;
    this.streetMarkers = arrayCurrentCustomerStreet;
    this.prospectiveCustomers = arrayProspectiveCustomerStreet;
  }

  constructFeatureMaps(featuresArray: DataFeature[]) {
    return {
      type: "FeatureCollection",
      features: featuresArray.map((data) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: data.coordinates,
        },
        properties: {
          text: data.text,
          value: [data.value.reduce((previus, current) => previus)],
          tooltip: `<b>${data.text}</b>\n <b>Closed Sales:</b> ${data.value[0]}\n <b>Pending Sales:</b> ${data.value[1]}`,
        },
      })),
    };
  }

  constructStreetArray(calculatedByCustomer: IMapsData) {
    let locationStreet: string[] = [];
    if (isNaN(parseFloat(calculatedByCustomer.coordinates.split(",")[0])) === false && isNaN(parseFloat(calculatedByCustomer.coordinates.split(",")[1])) === false) {
      locationStreet = [
        calculatedByCustomer.coordinates.split(",")[0],
        calculatedByCustomer.coordinates.split(",")[1],
      ];
    } else {
      locationStreet = ["0", "0"];
    }
    return {
      location: locationStreet,
      tooltip: {
        isShown: false,
        text: `<b>${
          calculatedByCustomer.customerName
        }</b> <br> <b>Closed Sales:</b> ${parseFloat(
          calculatedByCustomer.usdAmount
        )} <br> <b>Pending Sales:</b> ${parseFloat(
          calculatedByCustomer.pastAmount
        )}`,
      },
    };
  }

  constructObjectFeatures(calculatedByUser: IMapsData) {
    return {
      coordinates: [
        calculatedByUser.coordinates.split(",")[1],
        calculatedByUser.coordinates.split(",")[0],
      ],
      text: calculatedByUser.customerName,
      value: [
        parseFloat(calculatedByUser.usdAmount),
        parseFloat(calculatedByUser.pastAmount),
      ],
    };
  }

  calculateByCustomer(filterByName: IInvoicesMapsData[], arrayGroupValues: ITopCustomerNotes[]): IMapsData {
    let pastAmount = 0, usdAmount = 0;
    let name = "", coordinates = "";
    let isProspective = false;
    filterByName.forEach((client) => {
      const filterNotes = arrayGroupValues.filter((data) =>
        data.fact.toString().trim() === client.invoice.toString().trim() &&
        data.articulo.trim() === client.article.trim()
      );
      filterNotes.forEach((vals) => {
        usdAmount += vals.litros * 1;
      });
      pastAmount += client.pastCalculation;
      usdAmount += client.calculation;
      name = client.customerName;
      coordinates = client.coordinates;
      isProspective = client.isProspective;
    });
    return { pastAmount: pastAmount.toFixed(2), usdAmount: usdAmount.toFixed(2), customerName: name, coordinates: coordinates, isProspective: isProspective };
  }

  filterInvoices(invoices: IQuota[]) {
    let filterInvoices: IQuota[] = [];
    filterInvoices = invoices.filter((data) => data.estado === 4 || data.estado === 1);
    return filterInvoices;
  }

  getInvoiceValues(invoices: IQuota[]) {
    let arrayData: IInvoicesMapsData[] = [];
    let arrayCoordinates: string[] = [];
    let arrayInvoices: number[] = [];
    invoices.forEach((invoice) => {
      arrayInvoices.push(invoice.id_factura!);
      let coordinates = "", name = "", article = "";
      let calculation = 0, liters = 0, quantity = 0, idInvoice = 0;
      let quantityPast = 0, litersPast = 0, calculationPast = 0;
      invoice.cliente.forEach((cliente) => {
        !cliente.coordinates
          ? arrayCoordinates.push(cliente.nombre)
          : arrayCoordinates.push(cliente.coordinates!);
        invoice.venta.forEach((sales) => {
          sales.articulo.forEach((element) => {
            !cliente.coordinates
              ? (coordinates = cliente.nombre)
              : (coordinates = cliente.coordinates!);
            !cliente.coordinates
              ? (name = cliente.nombre)
              : (name = cliente.nombre);
            quantity = invoice.estado === 4 ? sales.cantidad : 0;
            liters =
              invoice.estado === 4 ? parseFloat(element.presentacion!) : 0;
            calculation = invoice.estado === 4 ? quantity * liters : 0;
            idInvoice = invoice.id_factura!;
            article = element.codigo!;
            quantityPast = invoice.estado === 1 ? sales.cantidad : 0;
            litersPast =
              invoice.estado === 1 ? parseFloat(element.presentacion!) : 0;
            calculationPast = invoice.estado === 1 ? quantityPast * litersPast : 0;
            arrayData.push({
              invoice: idInvoice,
              article: article,
              coordinates: coordinates,
              calculation: calculation,
              pastCalculation: calculationPast,
              customerName: name,
              isProspective: parseInt(cliente.customer_type!.id_customerType.toString()) === 3 ? true : false
            });
          });
        });
      });
    });
    return {
      arrayData: arrayData,
      arrayCoordinates: arrayCoordinates,
      arrayInvoices: arrayInvoices,
    };
  }
}
