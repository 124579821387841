<div>
    <div style="text-align: center;">   
        <div class="row">
            <div class="col">
                <span>{{'Set Goal' | translate}}:</span>
                <input class="form-control" placeholder="{{'Type Goal' | translate}}" type="text" [ngModel]="tarjet" (ngModelChange)="tarjet = $event">
            </div>
        </div>
    </div>
    <div style="text-align: center; margin-top: 10px;">
        <button (click)="save(allQuots, allNotas)" mat-raised-button>{{'Save' | translate}}</button>
    </div>
</div>
<app-line [lineChartData]="lineChartData" [lineChartLabels]="lineChartLabels" [lineChartColors]="lineChartColors"></app-line>