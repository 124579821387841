
<mat-tab-group #tabs mat-align-tabs="center" dynamicHeight="true">
    <mat-tab label="{{'Remittance' | translate}}">
        <div class="selected-data">
            <span class="caption">{{'Select deliveries to download the report:' | translate}}</span>
        </div>
        <app-list [exportData]="true" [data]="data"></app-list>
    </mat-tab>
</mat-tab-group>

<div>
    <table id="season-tble" class="table table-striped">
        <thead>
            <tr>
                <th [ngSwitch]="companyId" colspan="6">
                    <p hidden>{{companyName}} - {{'Bill of lading' | translate}}</p>
                    <img *ngSwitchCase="1" class="centradol2" style="width: 250px; height: 50px;" src="../../../assets/img/v1rotate.png">
                    <img *ngSwitchCase="2" class="centradol2" style="width: 250px; height: 50px;" src="../../../assets/img/IbexportsBanner.png">
                    <img *ngSwitchCase="4" class="centradol2" style="width: 250px; height: 50px;" src="../../../assets/img/JensBanner.png">
                    <img *ngSwitchCase="5" class="centradol2" style="width: 250px; height: 100px;" src="../../../assets/img/logoVersa.png">
                    <img *ngSwitchCase="6" class="centradol2" style="width: 250px; height: 100px;" src="../../../assets/img/Parabricentro.png">
                    <img *ngSwitchCase="7" class="centradol2" style="width: 250px; height: 50px;" src="../../../assets/img/v1rotate.png">
                    <img *ngSwitchCase="8" class="centradol2" style="width: 250px; height: 100px;" src="../../../assets/img/Parabricentro.png">
                    <img *ngSwitchCase="9" class="centradol2" style="width: 250px; height: 100px;" src="../../../assets/img/v1rotate.png">
                </th>
            </tr>
        </thead>
        <thead>
            <tr>
                <th colspan="6">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</th>
            </tr>
        </thead>
        <thead>
            <tr>
                <th colspan="6">{{'Products to be loaded' | translate}} {{date | date: 'fullDate'}}</th>
            </tr>
        </thead>
        <thead>
            <tr>
                <th colspan="6">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</th>
            </tr>
        </thead>
        <thead><tr><th colspan="6"></th></tr></thead>
        <thead>
            <tr>
                <th style="width: 16.66%;">
                    <mat-label>{{'ITEM' | translate}}</mat-label>
                </th>
                <th style="width: 16.66%;">
                    <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
                </th>
                <th style="width: 16.66%;">
                    <mat-label>{{'PRESENTATION' | translate}}</mat-label>
                </th>
                <th style="width: 16.66%;">
                    <mat-label>{{'QTY' | translate}}</mat-label>
                </th>
                <th style="width: 16.66%;">
                    <mat-label>{{'LITERS' | translate}}</mat-label>
                </th>
                <th style="width: 16.66%;">
                    <mat-label>{{'KILOS' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of articlesExport; let i = index">
                <td>{{i+1}}</td>
                <td>{{item.description}}</td>
                <td>{{item.presentation}}</td>
                <td>{{item.cant}}</td>
                <td>{{item.liters}}</td>
                <td></td>
            </tr>
        </tbody>
        <thead><tr><th colspan="6"></th></tr></thead>
        <thead>
            <tr>
                <th style="width: 16.66%;">
                    <mat-label>{{'NUM DOC' | translate}}</mat-label>
                </th>
                <th style="width: 16.66%;">
                    <mat-label>{{'CUSTOMER' | translate}}</mat-label>
                </th>
                <th style="width: 16.66%;">
                    <mat-label>{{'CITY' | translate}}</mat-label>
                </th>
                <th style="width: 16.66%;">
                    <mat-label>{{'CANT' | translate}}</mat-label>
                </th>
                <th style="width: 16.66%;" colspan="2">
                    <mat-label>{{'SHIPPED' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of customersExport; let i = index">
                <td>{{item.delivery.join(', ')}}</td>
                <td>{{item.customer}}</td>
                <td>{{item.city}}</td>
                <td>{{item.cant}}</td>
                <td colspan="2"></td>
            </tr>
        </tbody>
        <thead><tr><th colspan="6"></th></tr></thead>
        <thead [formGroup]="driverForm">
            <tr>
                <th>
                    <mat-label>{{'DRIVER' | translate}}</mat-label>
                </th>
                <th colspan="2">
                    <input class="form-control" type="text" formControlName="driver"> <div hidden>{{driverForm.get('driver')?.value}}</div>
                </th>
                <th>
                    <mat-label>{{'PLATE' | translate}}</mat-label>
                </th>
                <th colspan="2">
                    <input class="form-control" type="text" formControlName="plate"> <div hidden>{{driverForm.get('plate')?.value}}</div>
                </th>
            </tr>
        </thead>
        <thead [formGroup]="driverForm">
            <tr>
                <th>
                    <mat-label>{{'DESTINATION' | translate}}</mat-label>
                </th>
                <th colspan="2">
                    <input class="form-control" type="text" formControlName="destination"> <div hidden>{{driverForm.get('destination')?.value}}</div>
                </th>
                <th>
                    <mat-label>{{'VEH MODEL' | translate}}</mat-label>
                </th>
                <th colspan="2">
                    <input class="form-control" type="text" formControlName="veh_model"> <div hidden>{{driverForm.get('veh_model')?.value}}</div>
                </th>
            </tr>
        </thead>
        <thead>
            <tr>
                <th>
                    <mat-label>{{'DATE' | translate}}</mat-label>
                </th>
                <th colspan="2">
                    <mat-label>{{date | date: 'fullDate'}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'SIGNATURE' | translate}}</mat-label>
                </th>
                <th colspan="2"></th>
            </tr>
        </thead>
        <thead><tr><th colspan="6"></th></tr></thead>
    </table>
</div>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
