import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
})
export class SearchComponent implements OnInit {
  companyId = '';
  constructor(private cookieAuthService: CookieAuthService) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.search.valueChanges
    .pipe(debounceTime(1000))
    .subscribe((value) => {
      this.searchEmitter.emit(value)});
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  search = new UntypedFormControl("");

  @Output("search") searchEmitter = new EventEmitter<string>();
}
