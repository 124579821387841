import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticatedGuard } from './@shared/authentication/auth-guard.service';
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./@pages/home/home.module').then((m) => m.HomeModule),
    canActivate: [isAuthenticatedGuard],
  },
  {
    path: 'provider',
    loadChildren: () => import('./@pages/provider/provider.module').then((m) => m.ProviderModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'remittance',
    loadChildren: () => import('./@pages/remittance/remittance.module').then((m) => m.RemittanceModule),
    canActivate: [isAuthenticatedGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./@pages/reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'details_portfolio',
    loadChildren: () => import('./@pages/details-portfolio/details-portfolio.module').then((m) => m.DetailsPortfolioModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'details_purchase/:id_invoice',
    loadChildren: () => import('./@pages/details-purchases/details-purchases.module').then((m) => m.DetailsPurchasesModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'selectDB',
    loadChildren: () => import('./@pages/selectDB/selectDB.module').then((m) => m.SelectDBModule),
    canActivate: [isAuthenticatedGuard],
  },
  {
    path: 'sales',
    loadChildren: () => import('./@pages/quotations/quotations.module').then((m) => m.QuotationsModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'purchases',
    loadChildren: () => import('./@pages/purchases/purchases.module').then((m) => m.PurchasesModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: '',
    loadChildren: () => import('./@pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./@pages/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'details/:id_factura/:company/:type',
    loadChildren: () => import('./@pages/details/details.module').then((m) => m.DetailsModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'customer',
    loadChildren: () => import('./@pages/customer/customer.module').then((m) => m.CustomerModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'inventory',
    loadChildren: () => import('./@pages/inventory/inventory.module').then((m) => m.InventoryModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'inventory_movements',
    loadChildren: () => import('./@pages/inventory-movements/inventory-movements.module').then((m) => m.InventoryMovementsModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'sellers',
    loadChildren: () => import('./@pages/sellers/sellers.module').then((m) => m.SellersModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'portfolio',
    loadChildren: () => import('./@pages/portfolio/portfolio.module').then((m) => m.PortfolioModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'analysis',
    loadChildren: () => import('./@pages/analysis/analysis.module').then((m) => m.AnalysisModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'warehouse',
    loadChildren: () => import('./@pages/warehouse/warehouse.module').then((m) => m.WarehouseModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./@pages/users/users.module').then((m) => m.UsersModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./@pages/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'details_notas/:id_factura/:id_nota',
    loadChildren: () => import('./@pages/details-notas/details-notas.module').then((m) => m.DetailsNotasModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'details_refunds/:id_invoice/:id_refund',
    loadChildren: () => import('./@pages/details-refunds/details-refunds.module').then((m) => m.DetailsRefundsModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'tributary',
    loadChildren: () => import('./@pages/tributary/tributary.module').then((m) => m.TributaryModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'password/:id',
    loadChildren: () => import('./@pages/password/password.module').then((m) => m.PasswordModule)
  },
  {
    path: 'accountsPayable',
    loadChildren: () => import('./@pages/accounts-payable/accounts-payable.module').then((m) => m.AccountsPayableModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'details_accounts_payable',
    loadChildren: () => import('./@pages/details-accounts-payable/details-accounts-payable.module').then((m) => m.DetailsAccountsPayableModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'imports',
    loadChildren: () => import('./@pages/import-files/import-files.module').then((m) => m.ImportFilesModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: 'treasury',
    loadChildren: () => import('./@pages/treasury/treasury.module').then((m) => m.TreasuryModule),
    canActivate: [isAuthenticatedGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }