import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "thousandExcelPipe",
})
export class ThousandExcelPipe implements PipeTransform {
  transform(number: number) {
    if (number) {
      return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return "0.000";
    }
  }
}
