import { createReducer, on } from "@ngrx/store";
import { CompaniesState } from "src/app/@interfaces/company.interface";
import { IStorageCompany } from "../../@interfaces/company.interface";
import {
  loadCompanies,
  loadedCompanies,
  loadCompaniesFailure
} from "../actions/user-companies.actions";

const initialCompanies: IStorageCompany[] = [];

export const initialState: CompaniesState = {
  loading: false,
  companies: initialCompanies,
  error: "",
};

export const companiesReducer = createReducer(
  initialState,
  on(loadCompanies, (state) => {
    return { ...state, loading: true };
  }),
  on(loadedCompanies, (state, { companies }) => {
    return { ...state, loading: false, companies: companies };
  }),
  on(loadCompaniesFailure, (state, { error }) => {
    return { ...state, loading: false, error: error };
  })
);
