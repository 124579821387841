export const environment = {
  production: true,
  database: 'graphql' ,
  graphql_api_url: 'https://graphql.v1-insight.com/graphql',
  api_url: 'https://graphql.v1-insight.com/api',
  api_to_calculate: 'https://v1-insight-api-to-calculate-production.up.railway.app/api',
  userIdKey: "1C8BMU6fTW6fa5w",
  rolIdKey: "Li8M1P3iNkF8Bg7",
  userSellerIdKey: "0Ok75sR4L0vMoXF",
};
