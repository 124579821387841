import { Pipe, PipeTransform } from '@angular/core';
import { IVentaQuery } from '../../../../@interfaces/venta.interface';

@Pipe({
  name: 'filterFullDateSales'
})
export class FilterFullDateSales implements PipeTransform {
  transform(sales: IVentaQuery[], searchValue: string, date1: Date, date2: Date) {
    if (!sales || !searchValue  || !(date1 && date2)) {
      return sales;
    }
    return sales.filter((sale) => {
        if (sale.factura!.length > 0 && sale.factura![0].invoiced_date!) {
          return(
            sale.factura!.length > 0 &&
            (new Date(sale.factura![0].invoiced_date!) >= date1 &&
            new Date(sale.factura![0].invoiced_date!) <= date2) &&
            (sale.codigo.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            sale.articulo.filter(article => article.descripcion.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
            sale.articulo.filter(article => article.unidad!.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
            sale.articulo.filter(article => article.grupo?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())))
          )
      }else {
          return(
            sale.factura!.length > 0 &&
            (new Date(sale.factura![0].createdAt) >= date1 &&
            new Date(sale.factura![0].createdAt) <= date2) &&
            (sale.codigo.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            sale.articulo.filter(article => article.descripcion.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
            sale.articulo.filter(article => article.unidad!.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
            sale.articulo.filter(article => article.grupo?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())))
          )
        }
    })
  }
}
