import { Component, OnInit, ViewChild, OnDestroy, Input } from "@angular/core";
import { IQuotation } from "src/app/@interfaces/quotation.interface";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { IValidNotas } from "../../../@interfaces/notas.interface";
import { EventEmitterService } from "src/app/@core/menu/emitterFuncions.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { QuotationsByUserService } from "src/app/@pages/quotations/quotations-byuser.service";
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.css"],
})
export class TabsComponent implements OnInit, OnDestroy {
  @Input() lengthAll = 0;
  @Input() lengthBy1 = 0;
  @Input() lengthBy2 = 0;
  @Input() lengthBy3 = 0;
  @Input() lengthBy4 = 0;
  pageSize = 4;
  pageSizeOptions: number[] = [4, 8, 12];
  pageEvent: PageEvent = {
    length: this.lengthBy1,
    pageIndex: 0,
    pageSize: this.pageSize,
  };
  QuotationListAll: Array<IQuotation> = [];
  QuotationListByStatus1: Array<IQuotation> = [];
  QuotationListByStatus2: Array<IQuotation> = [];
  QuotationListByStatus3: Array<IQuotation> = [];
  QuotationListByStatus4: Array<IQuotation> = [];

  notes: Array<IValidNotas> = [];
  companyId = "";
  user = "";
  rol = "";
  disableButton = false;
  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private quotations: QuotationsService,
    private quotationsByUser: QuotationsByUserService,
    private eventEmitterService: EventEmitterService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit() {
    this.getAuthValues();
    this.data();
    this.quotations
    .subsNewQuot()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result != null) {
        this.router
        .navigateByUrl("/card", { skipLocationChange: true })
        .then(() => {
          this.router.navigate(["/sales"]);
        });
      }
    });
    this.validButtons();
    this.toggleExpandCard();
    this.getEvent();
  }
  
  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((name: string) => {
      this.toggleExpandCard();
    });
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 250);
  }

  validButtons() {
    if (this.rol === "1" || this.rol === "3") {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  data() {
    if (this.rol === "1" || this.rol === "3") {
      this.quotations
      .getAllInvoicesByStatus(1, 0, 4)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.QuotationListByStatus1 = result;
      });
    } else {
      this.quotationsByUser
      .getAllInvoicesByStatusByUser(1, 0, 4, parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.QuotationListByStatus1 = result;
      });
    }
  }

  searchQuotations(value: string, estado: number) {
    if (this.rol === "1" || this.rol === "3") {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotations
        .findQuotations(estado!, value, this.pageEvent.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    } else {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotationsByUser
        .findQuotationsByUser(
          estado!,
          value,
          this.pageEvent.pageSize,
          parseInt(this.user!)
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    }
  }

  async handleSearch(value: string, estado: number) {
    if (value) {
      switch (estado) {
        case 0:
          this.QuotationListAll = await this.searchQuotations(value, estado);
          this.QuotationListAll.forEach((element) => {
            this.quotations
            .getValideNote(parseInt(element.id_factura!.toString()))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {
              this.notes = result;
            });
          });
        break;
        case 1:
          this.QuotationListByStatus1 = await this.searchQuotations(
            value,
            estado
          );
        break;
        case 2:
          this.QuotationListByStatus2 = await this.searchQuotations(
            value,
            estado
          );
        break;
        case 3:
          this.QuotationListByStatus3 = await this.searchQuotations(
            value,
            estado
          );
        break;
        case 4:
          this.QuotationListByStatus4 = await this.searchQuotations(
            value,
            estado
          );
          this.QuotationListByStatus4.forEach((element) => {
            this.quotations
            .getValideNote(parseInt(element.id_factura!.toString()))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {
              this.notes = result;
            });
          });
        break;
      }
    } else {
      this.setOriginal(estado);
    }
  }

  setOriginal(estado: number) {
    let event = { index: 0 };
    switch (estado) {
      case 0:
        event.index = 4;
        this.selectTab(event);
      break;
      case 1:
        event.index = 0;
        this.selectTab(event);
      break;
      case 2:
        event.index = 3;
        this.selectTab(event);
      break;
      case 3:
        event.index = 1;
        this.selectTab(event);
      break;
      case 4:
        event.index = 2;
        this.selectTab(event);
      break;
    }
  }

  //Dialog
  openDialog(register: boolean): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { register },
    });
  }

  getQuotations(estado: number, pageIndex: number, pageSize: number) {
    if (this.rol === "1" || this.rol === "3") {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotations
        .getAllInvoicesByStatus(estado, pageIndex, pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    } else {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotationsByUser
        .getAllInvoicesByStatusByUser(
          estado,
          pageIndex,
          pageSize,
          parseInt(this.user!)
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    }
  }

  getAllQuotations(pageIndex: number, pageSize: number) {
    if (this.rol === "1" || this.rol === "3") {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotations
        .getAllInvoices(pageIndex, pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    } else {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotationsByUser
        .getAllInvoicesByUser(pageIndex, pageSize, parseInt(this.user!))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    }
  }

  async capturePage(event: any, estado?: number) {
    if (estado) {
      switch (estado) {
        case 1:
          this.QuotationListByStatus1 = await this.getQuotations(
            estado,
            event.pageIndex,
            event.pageSize
          );
        break;
        case 2:
          this.QuotationListByStatus2 = await this.getQuotations(
            estado,
            event.pageIndex,
            event.pageSize
          );
        break;
        case 3:
          this.QuotationListByStatus3 = await this.getQuotations(
            estado,
            event.pageIndex,
            event.pageSize
          );
        break;
        case 4:
          this.QuotationListByStatus4 = await this.getQuotations(
            estado,
            event.pageIndex,
            event.pageSize
          );
          this.QuotationListByStatus4.forEach((element) => {
            this.quotations
            .getValideNote(parseInt(element.id_factura!.toString()))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {
              this.notes = result;
            });
          });
        break;
      }
    } else {
      this.QuotationListAll = await this.getAllQuotations(
        event.pageIndex,
        event.pageSize
      );
      this.QuotationListAll.forEach((element) => {
        this.quotations
        .getValideNote(parseInt(element.id_factura!.toString()))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.notes = result;
        });
      });
    }
  }

  async selectTab(event: any) {
    switch (event.index) {
      case 0:
        this.pageEvent.length === this.lengthBy1;
        this.QuotationListByStatus1 = await this.getQuotations(
          1,
          0,
          this.pageEvent.pageSize
        );
      break;
      case 1:
        this.pageEvent.length === this.lengthBy3;
        this.QuotationListByStatus3 = await this.getQuotations(
          3,
          0,
          this.pageEvent.pageSize
        );
      break;
      case 2:
        this.pageEvent.length === this.lengthBy4;
        this.QuotationListByStatus4 = await this.getQuotations(
          4,
          0,
          this.pageEvent.pageSize
        );
        this.QuotationListByStatus4.forEach((element) => {
          this.quotations
          .getValideNote(parseInt(element.id_factura!.toString()))
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result) => {
            this.notes = result;
          });
        });
      break;
      case 3:
        this.pageEvent.length === this.lengthBy2;
        this.QuotationListByStatus2 = await this.getQuotations(
          2,
          0,
          this.pageEvent.pageSize
        );
      break;
      case 4:
        this.pageEvent.length === this.lengthAll;
        this.QuotationListAll = await this.getAllQuotations(
          0,
          this.pageEvent.pageSize
        );
        this.QuotationListAll.forEach((element) => {
          this.quotations
          .getValideNote(parseInt(element.id_factura!.toString()))
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result) => {
            this.notes = result;
          });
        });
      break;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
