import { Component, Input, OnInit } from "@angular/core";
import { ChartOptions, ChartType } from "chart.js";
import { Label, MultiDataSet } from "ng2-charts";

@Component({
  selector: "app-pie",
  templateUrl: "./pie.component.html",
})
export class PieComponent implements OnInit {
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };

  @Input() pieChartLabels: Label[] = [];
  @Input() pieChartData: MultiDataSet = [];

  public pieChartType: ChartType = "pie";

  constructor() {}

  ngOnInit(): void {}
}
