import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from 'src/app/@interfaces/user.interface';

@Pipe({
  name: 'filterUser'
})
export class FilterUserPipe implements PipeTransform {
  
  transform(users: IUser[], searchValue: string): IUser[] {
    if (!users || !searchValue) {
      return users;
    }
    return users.filter(user =>
      user.id?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      user.document?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      user.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      user.username?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      user.email?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      user.roles[0].name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      user.status?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      user.companies[0]?.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
  }
}
