<mat-dialog-content>
    <form [formGroup]="registerForm" > 

        <div>                            
        <input class="form-control" formControlName='article' name="article" placeholder="{{'Please, write to search for an item' | translate}}" 
        [matAutocomplete]="autoArticle">

            <mat-autocomplete #autoArticle="matAutocomplete" [displayWith]="displayFnArticle">

                <mat-option  *ngFor="let articles of ArticleList"
                    [value]="articles">
                    {{articles.codigo}} - {{articles.descripcion}} - {{'Tax' | translate}} ({{articles.tax![0].value}})
                </mat-option>
            </mat-autocomplete>
        </div>

        <div class="row">
            <div class="col">
                <button mat-raised-button mat-dialog-close>
                    {{'Close' | translate}}
                </button>
            </div>
            <div class="col">
                <button mat-raised-button [mat-dialog-close]="registerForm.controls['article'].value">
                    OK
                </button>
            </div>
        </div>
        
    </form>
</mat-dialog-content>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)" >
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>



