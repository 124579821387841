<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/22.1.6/css/dx.common.css" />
<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/22.1.6/css/dx.light.css" />
<section>
    <div class="container">
        <div class="carousel">
            <input type="radio" name="slides" checked="checked" id="slide-1">
            <input type="radio" name="slides" id="slide-2">
            <input type="radio" name="slides" id="slide-3">
            <input type="radio" name="slides" id="slide-4">
            <input type="radio" name="slides" id="slide-5">
            <input type="radio" name="slides" id="slide-6">
            <input type="radio" name="slides" id="slide-7">
            <ul class="carousel__slides">
                <li class="carousel__slide">
                    <figure>
                        <div>
                            <main class="graph-containter">
                                <app-dollars-per-client-graph [dollarPerClientData]="dollarPerClientData" [notesByCode]="notesByCode"></app-dollars-per-client-graph>
                            </main>
                        </div>
                    </figure>
                </li>
                <li class="carousel__slide">
                    <figure>
                        <div>
                            <main class="graph-containter">
                                <app-liter-per-client-graph [literPerClientData]="literPerClientData" [notesByCode]="notesByCode" [typePres]="typePres"></app-liter-per-client-graph>
                            </main>
                        </div>
                    </figure>
                </li>
                <li class="carousel__slide">
                    <figure>
                        <div>
                            <main class="graph-containter">
                                <app-liter-per-item-graph [literPerItemData]="literPerItemData" [notesByDescription]="notesByDescription" [typeGraph]="'1'" [typePres]="typePres" [months]="months"></app-liter-per-item-graph>
                            </main>
                        </div>
                    </figure>
                </li>
                <li class="carousel__slide">
                    <figure>
                        <div>
                            <main class="graph-containter">
                                <app-liter-per-item-graph [literPerItemData]="literPerItemData" [notesByDescription]="notesByDescription" [typeGraph]="'2'" [typePres]="typePres" [months]="months"></app-liter-per-item-graph>
                            </main>
                        </div>
                    </figure>
                </li>
                <li class="carousel__slide">
                    <figure>
                        <div>
                            <main class="graph-containter">
                                <app-liter-per-group-graph [literPerGroupData]="literPerGroupData" [notesByGroup]="notesByGroup" [typeGraph]="'1'" [typePres]="typePres" ></app-liter-per-group-graph>
                            </main>
                        </div>
                    </figure>
                </li>
                <li class="carousel__slide">
                    <figure>
                        <div>
                            <main class="graph-containter">
                                <app-liter-per-group-graph [literPerGroupData]="literPerGroupData" [notesByGroup]="notesByGroup" [typeGraph]="'2'" [typePres]="typePres" ></app-liter-per-group-graph>
                            </main>
                        </div>
                    </figure>
                </li>
                <li class="carousel__slide">
                    <figure>
                        <div>
                            <main class="graph-containter">
                                <app-liter-per-month-graph [typePres]="typePres" [orderCustomerQuots]="orderCustomerQuots" [notas]="notas"></app-liter-per-month-graph>
                            </main>
                        </div>
                    </figure>
                </li>
            </ul>    
            <ul class="carousel__thumbnails">
                <li id="link1" (mouseenter)="toggleDefault(1)" (mouseleave)="toggleDefault(1)">
                    <label for="slide-1"><img src="../../../../../assets/screens/1.png" alt=""></label>
                    <dx-popover target="#link1" position="top" [width]="300" [(visible)]="defaultVisible1!" [showTitle]="true" title="{{'Top 10 Customers ($$$)' | translate}}">
                        <div *dxTemplate="let data = model; of: 'content'">
                            {{'Top 10 Customers ($$$)' | translate}}
                        </div>
                    </dx-popover>
                </li>
                <li id="link2" (mouseenter)="toggleDefault(2)" (mouseleave)="toggleDefault(2)">
                    <label for="slide-2"><img src="../../../../../assets/screens/2.png" alt=""></label>
                    <dx-popover target="#link2" position="top" [width]="300" [(visible)]="defaultVisible2!" [showTitle]="true" title="{{'Top 10 Customers' | translate}} ({{typePres | translate}})">
                        <div *dxTemplate="let data = model; of: 'content'">
                            {{'Top 10 Customers' | translate}} ({{typePres | translate}})
                        </div>
                    </dx-popover>
                </li>
                <li id="link3" (mouseenter)="toggleDefault(3)" (mouseleave)="toggleDefault(3)">
                    <label for="slide-3"><img src="../../../../../assets/screens/3.png" alt=""></label>
                    <dx-popover target="#link3" position="top" [width]="300" [(visible)]="defaultVisible3!" [showTitle]="true" title="{{'Top 10 Items' | translate}} ({{typePres | translate}})">
                        <div *dxTemplate="let data = model; of: 'content'">
                            {{'Top 10 Items' | translate}} ({{typePres | translate}})
                        </div>
                    </dx-popover>
                </li>
                <li id="link4" (mouseenter)="toggleDefault(4)" (mouseleave)="toggleDefault(4)">
                    <label for="slide-4"><img src="../../../../../assets/screens/4.png" alt=""></label> 
                    <dx-popover target="#link4" position="top" [width]="300" [(visible)]="defaultVisible4!" [showTitle]="true" title="{{'Top 10 Items (Quantities)' | translate}}">
                        <div *dxTemplate="let data = model; of: 'content'">
                            {{'Top 10 Items (Quantities)' | translate}}
                        </div>
                    </dx-popover>
                </li>
                <li id="link5" (mouseenter)="toggleDefault(5)" (mouseleave)="toggleDefault(5)">
                    <label for="slide-5"><img src="../../../../../assets/screens/5.png" alt=""></label>
                    <dx-popover target="#link5" position="top" [width]="300" [(visible)]="defaultVisible5!" [showTitle]="true" title="{{typePres | translate}} {{' '}} {{'per group' | translate}}">
                        <div *dxTemplate="let data = model; of: 'content'">
                            {{typePres | translate}} {{' '}} {{'per group' | translate}}
                        </div>
                    </dx-popover>
                </li>
                <li id="link6" (mouseenter)="toggleDefault(6)" (mouseleave)="toggleDefault(6)">
                    <label for="slide-6"><img src="../../../../../assets/screens/6.png" alt=""></label>
                    <dx-popover target="#link6" position="top" [width]="300" [(visible)]="defaultVisible6!" [showTitle]="true" title="{{typePres | translate}} {{' '}} {{'per group' | translate}}">
                        <div *dxTemplate="let data = model; of: 'content'">
                            {{typePres | translate}} {{' '}} {{'per group' | translate}}
                        </div>
                    </dx-popover>
                </li>
                <li id="link7" (mouseenter)="toggleDefault(7)" (mouseleave)="toggleDefault(7)">
                    <label for="slide-7"><img src="../../../../../assets/screens/7.png" alt=""></label>
                    <dx-popover target="#link7" position="top" [width]="300" [(visible)]="defaultVisible7!" [showTitle]="true" title="{{typePres | translate}} {{' '}} {{'per month' | translate}}">
                        <div *dxTemplate="let data = model; of: 'content'">
                            {{typePres | translate}} {{' '}} {{'per month' | translate}}
                        </div>
                    </dx-popover>
                </li>
            </ul>
        </div>
    </div>
</section>

<app-drill-items-graph [allDrillData]="drillData" [notesByDescription]="notesByDescription"></app-drill-items-graph> 









