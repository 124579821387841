import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConverseArrayService {
  constructor() {}

  groupArraysByProperty(
    arr: any[][],
    propertyIndex: number
  ): { [key: string]: any[][] } {
    const groups: { [key: string]: any[][] } = {};
    for (const array of arr) {
      const propertyValue = array[propertyIndex].toString();
      if (!groups[propertyValue]) {
        groups[propertyValue] = [];
      }
      groups[propertyValue].push(array);
    }
    return groups;
  }

  orderArrayByType(array: any[][], type: string) {
    return array.sort((a, b) => {
      if (a[5] === type) {
        return -1;
      } else {
        return a[5] > b[5] ? 1 : -1;
      }
    });
  }

  reduceArrays(array: any[]) {
    return array.filter((item, index) => {
      return array.indexOf(item) === index;
    });
  }
  
  filterArrayByCustomer(arrayTopInit: any[], element: string) {
    return arrayTopInit.filter((item: any) => item.customer === element);
  }
}
