import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ApiService } from "src/app/@graphql/services/api.service";
import {
  GET_PURCHASE_INVOICE,
  GET_SINGLE_TRIBUTARY_VALUES,
  GET_PURCHASES_INVOICE_BY_STATUS30,
  GET_PURCHASES_INVOICE_BY_STATUS10,
  GET_ALL_REFUNDS,
  GET_SINGLE_REFUND,
  GET_REFUNDS_BYPURCHASEID,
  VALID_REFUNDS,
  GET_PURCHASES_INVOICES_BY_STATUS,
} from "../../@graphql/operations/query";
import { map } from "rxjs/internal/operators/map";
import {
  CANCEL_PURCHASE,
  CREATE_PURCHASE,
  CREATE_REFUND,
  UPDATE_PURCHASE,
  UPDATE_STATUS_PURCHASE,
} from "../../@graphql/operations/mutation";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class PurchasesService extends ApiService {
  constructor(
    apollo: Apollo,
    private cookieAuthService: CookieAuthService
  ) {
    super(apollo);
  }

  //All

  listPurchasesByStatus10() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PURCHASES_INVOICE_BY_STATUS10, { company }).pipe(
      map((result: any) => {
        return result.getPurchaseByStatus10;
      })
    );
  }

  listPurchasesByStatus30() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PURCHASES_INVOICE_BY_STATUS30, { company }).pipe(
      map((result: any) => {
        return result.getPurchaseByStatus30;
      })
    );
  }

  listPurchasesInvoiceByStatus(status: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PURCHASES_INVOICES_BY_STATUS, {
      status,
      company,
    }).pipe(
      map((result: any) => {
        return result.getAllPurchasesByStatus;
      })
    );
  }

  getPurchaseInvoice(id_invoice: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PURCHASE_INVOICE, { id_invoice, company }).pipe(
      map((result: any) => {
        return result.getSinglePurchase;
      })
    );
  }

  createPurchase(
    written_invoice: string,
    id_provider: number,
    tax: number,
    withholdingTax: number,
    icaTax: number,
    customerCity: string,
    providerCity: string,
    id_warehouse: number[],
    codigo: string[],
    quantity: number[],
    price: number[],
    discount: number[],
    subtotal: number[],
    total: number[],
    createdAt: string,
    dueDate: string,
    tax_incl: string,
    clause: string,
    xml: string,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_PURCHASE, {
      written_invoice,
      id_provider,
      tax,
      withholdingTax,
      icaTax,
      customerCity,
      providerCity,
      id_warehouse,
      codigo,
      quantity,
      price,
      discount,
      subtotal,
      total,
      createdAt,
      dueDate,
      tax_incl,
      clause,
      xml,
      id_user,
      company,
    }).pipe(
      map((result: any) => {
        return result.createPurchase;
      })
    );
  }

  updatePurchase(
    written_invoice: string,
    id_invoice: number,
    tax: number,
    withholdingTax: number,
    icaTax: number,
    customerCity: string,
    providerCity: string,
    id_provider: number,
    id_purchases: number[],
    id_purchaseDelete: number[],
    id_warehouse: number[],
    codigo: string[],
    quantity: number[],
    price: number[],
    discount: number[],
    subtotal: number[],
    total: number[],
    createdAt: string,
    dueDate: string,
    tax_incl: string,
    clause: string,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_PURCHASE, {
      written_invoice,
      id_invoice,
      tax,
      withholdingTax,
      icaTax,
      customerCity,
      providerCity,
      id_provider,
      id_purchases,
      id_purchaseDelete,
      id_warehouse,
      codigo,
      quantity,
      price,
      discount,
      subtotal,
      total,
      createdAt,
      dueDate,
      tax_incl,
      clause,
      id_user,
      company,
    }).pipe(
      map((result: any) => {
        return result.updatePurchase;
      })
    );
  }

  updateStatusPurchase(id_invoice: number, cancelled: Boolean, file: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_STATUS_PURCHASE, {
      id_invoice,
      cancelled: cancelled,
      file: file,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateStatusPurchased;
      })
    );
  }

  getSingleTributaryValues(id_value: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_SINGLE_TRIBUTARY_VALUES, { id_value, company }).pipe(
      map((result: any) => {
        return result.getSingleTributaryValues;
      })
    );
  }

  createRefund(
    num_invo: number,
    id_warehouse: number[],
    id_article: number[],
    code: string[],
    quantity: number[],
    refund_type: string,
    concept: string,
    price: number[],
    subtotal: number[],
    discount: number[],
    total: number,
    withholdingTax: number,
    icaTax: number,
    createAt: string,
    dueDate: string,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_REFUND, {
      num_invo,
      id_warehouse,
      id_article,
      code,
      quantity,
      refund_type,
      concept,
      price,
      subtotal,
      discount,
      total,
      withholdingTax,
      icaTax,
      createAt,
      dueDate,
      company,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.createRefund;
      })
    );
  }

  getAllRefunds() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_REFUNDS, { company }).pipe(
      map((result: any) => {
        return result.getAllRefunds;
      })
    );
  }

  getSingleRefund(id_refund: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_SINGLE_REFUND, { id_refund, company }).pipe(
      map((result: any) => {
        return result.getSingleRefund;
      })
    );
  }

  getRefundsByPurchaseID(num_invo: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_REFUNDS_BYPURCHASEID, { num_invo, company }).pipe(
      map((result: any) => {
        return result.getRefundsByPurchaseID;
      })
    );
  }

  validRefund(num_invo: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(VALID_REFUNDS, { num_invo, company }).pipe(
      map((result: any) => {
        return result.validRefunds;
      })
    );
  }

  cancelPurchase(id_invoice: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CANCEL_PURCHASE, { id_invoice, company }).pipe(
      map((result: any) => {
        return result.cancelPurchase;
      })
    );
  }
}
