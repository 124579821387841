import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnInit,
} from "@angular/core";
import {
  DxDataGridComponent,
  DxLinearGaugeComponent,
} from "devextreme-angular";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { EventEmitterService } from "../../../../@core/menu/emitterFuncions.service";

@Component({
  selector: "app-bar-indicator",
  templateUrl: "./bar-indicator.component.html",
  styleUrls: ["./bar-indicator.component.css"],
})
export class BarIndicatorComponent implements OnChanges, OnInit {
  @Input() meta = 0;
  @Input() today = 0;
  @Input() pastMonth = 0;
  @Input() completed = 0;
  @Input() projection = 0;
  @Input() typePres: string = "All";
  unsuscribe$ = new Subject();

  firstPercent = 0; // 50%
  secondPercent = 0; // 75%

  setMeta = 0;
  setToday = 0;
  setPastMonth = 0;
  setCompleted = 0;
  setProjection = 0;

  @ViewChild("linearGauge", { static: false }) dataLinear:
    | DxLinearGaugeComponent
    | undefined;
  @ViewChild("linearGauge2", { static: false }) dataLinear2:
    | DxLinearGaugeComponent
    | undefined;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid:
    | DxDataGridComponent
    | undefined;

  ngOnInit(): void {
    this.getEvent();
  }

  constructor(private eventEmitterService: EventEmitterService) {}

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
      .pipe(takeUntil(this.unsuscribe$))
      .subscribe((res) => {
        setTimeout(() => {
          this.render();
        }, 250);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["meta"]) {
      const values: number = this.meta;
      setTimeout(() => {
        this.setMeta = values;
      }, 500);
      this.firstPercent = (values * 50) / 100;
      this.secondPercent = (values * 75) / 100;
      this.render();
    }
    if (changes["today"]) {
      const values: number = this.today;
      this.setToday = values;
    }
    if (changes["pastMonth"]) {
      const values: number = this.pastMonth;
      this.setPastMonth = values;
    }
    if (changes["completed"]) {
      const values: number = this.completed;
      this.setCompleted = values;
    }
    if (changes["projection"]) {
      const values: number = this.projection;
      this.setProjection = values;
    }
  }

  render() {
    if (this.dataLinear?.instance) {
      this.dataLinear?.instance.render();
    }
    if (this.dataLinear2?.instance) {
      this.dataLinear2?.instance.render();
    }
  }

  customizeText(arg: any) {
    return `${arg.valueText}`;
  }
}
