<mat-dialog-content>
  <div class="row">
    <img
      src="../../../../../assets/icons/icono.png"
      onerror="this.src='assets/img/cantfindimage.jpg'"
      style="margin-left: 1px; width: 20px; height: 20px"
    />
    <div class="col-md-2" style="margin-top: 10px">
      <h2 mat-dialog-tittle>{{ "New Purchase" | translate }}</h2>
    </div>
  </div>
  <form [formGroup]="purchaseForm">
    <div class="row" style="justify-content: center" id="container-top">
      <div class="col" id="div-responsive">
        <mat-form-field>
          <mat-label>{{ "Purchase number" | translate }}</mat-label>
          <input
            type="text"
            matInput
            formControlName="written_invoice"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="col" id="div-responsive">
        <mat-form-field>
          <mat-label>{{ "Type provider" | translate }}</mat-label>
          <ng-container formArrayName="itemRows">
            <input
              id="input-top"
              type="text"
              matInput
              [matAutocomplete]="autoProvider"
              [formControl]="newProvider"
            />
          </ng-container>
          <mat-autocomplete
            #autoProvider="matAutocomplete"
            [displayWith]="displayFnProvider"
            (optionSelected)="onProviderSelected($event)"
          >
            <mat-option
              *ngFor="let provider of providersList"
              [value]="provider"
            >
              {{ provider.nombre }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div
        class="col"
        id="div-responsive"
        *ngIf="companyObject.countries[0].name === 'Colombia'"
      >
        <mat-form-field>
          <mat-label>{{ "Select provider city" | translate }}</mat-label>
          <input
            type="text"
            matInput
            [formControl]="citiesProviderForm"
            name="city"
            [matAutocomplete]="autoProviderCity"
          />
          <mat-autocomplete
            #autoProviderCity="matAutocomplete"
            [displayWith]="displayFnCity"
            (optionSelected)="onCitiesProvider($event)"
          >
            <mat-option
              *ngFor="let city of filteredOptionsProviderCities | async"
              [value]="city"
            >
              {{ city.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div
        class="col"
        id="div-responsive"
        *ngIf="companyObject.countries[0].name === 'Colombia'"
      >
        <mat-form-field>
          <mat-label>{{ "Select customer city" | translate }}</mat-label>
          <input
            type="text"
            matInput
            [formControl]="citiesCustomerForm"
            name="city"
            [matAutocomplete]="autoCustomerCity"
          />
          <mat-autocomplete
            #autoCustomerCity="matAutocomplete"
            [displayWith]="displayFnCity"
            (optionSelected)="onCitiesCustomer($event)"
          >
            <mat-option
              *ngFor="let city of filteredOptionsCustomerCities | async"
              [value]="city"
            >
              {{ city.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div
        class="col"
        id="div-responsive"
        *ngIf="purchaseForm.get('tax_include')?.value === false"
      >
        <mat-form-field>
          <mat-label>{{ "Tax" | translate }} (%)</mat-label>
          <input
            type="number"
            matInput
            formControlName="tax"
            (keyup)="calculation()"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="col" id="div-responsive">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Date" | translate }}</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="date" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col" id="div-responsive">
        <mat-form-field appearance="fill">
          <mat-label>{{ "Due Date" | translate }}</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="dueDate" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <mat-checkbox (change)="calculation()" formControlName="clause">{{'Exclusion Clause' | translate}}</mat-checkbox>
    <table class="table table-bordered">
      <thead class="thead-dark">
        <tr>
          <th>
            <mat-label>{{'Warehouse' | translate}}</mat-label>
          </th>
          <th>
            <mat-label>{{ "Articles" | translate }}</mat-label>
          </th>
          <th>
            <mat-label>{{ "Quantities" | translate }}</mat-label>
          </th>
          <th>
            <mat-label>{{ "Prices" | translate }}</mat-label>
          </th>
          <th>
            <mat-label>{{ "Discounts" | translate }}</mat-label>
          </th>
          <th>
            <mat-label>{{ "Subtotals" | translate }}</mat-label>
          </th>
          <th colspan="2">
            <mat-label>{{ "Options" | translate }}</mat-label>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container formArrayName="itemRows" class="row">
          <ng-container
            *ngFor="let itemrow of rowsArr.controls; let i = index"
            [formGroupName]="i"
          >
            <tr>
              <td data-label="Warehouse" style="width: 160px;">
                <select class="form-control" formControlName="warehouse" style="width: 160px;">
                    <option selected value="">
                        {{'Select warehouse' | translate}}
                    </option>
                    <option *ngFor="let warehouse of warehouseList" [value]="warehouse.id_almacen">
                        {{warehouse.nombre}}
                    </option>
                </select>
            </td>
              <td data-label="Articles" style="text-align: left; width: 400px">
                <input
                  type="text"
                  class="form-control"
                  name=""
                  placeholder="{{ 'Type Article' | translate }}"
                  [matAutocomplete]="autoArticle"
                  formControlName="article"
                  style="width: 400px"
                />
                <mat-autocomplete
                  #autoArticle="matAutocomplete"
                  [displayWith]="displayFnArticle"
                  (optionSelected)="calculation()"
                >
                  <mat-option
                    *ngFor="let articles of articleList"
                    [value]="articles"
                  >
                    {{ articles.codigo }} - {{ articles.descripcion }} -
                    {{ "Tax" | translate }} ({{ articles.tax![0].value }})
                  </mat-option>
                </mat-autocomplete>
              </td>
              <td data-label="Quantities">
                <input
                  class="form-control"
                  placeholder="0"
                  type="text"
                  (keyup)="calculation()"
                  formControlName="quantity"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="arrayvalid('quantity')"
                  >{{
                    "Quantity value
                                    must be 1 or higher" | translate
                  }}</span
                >
              </td>
              <td data-label="Prices">
                <input
                  class="form-control"
                  placeholder="0"
                  type="text"
                  (keyup)="calculation()"
                  formControlName="price"
                  style="width: 100px"
                />
                <span
                  class="form-text text-danger"
                  *ngIf="arrayvalid('price')"
                  >{{
                    "Price value must be 1
                                    or higher" | translate
                  }}</span
                >
              </td>
              <td data-label="Discounts">
                <input
                  class="form-control"
                  placeholder="0"
                  type="text"
                  (keyup)="calculation()"
                  formControlName="discount"
                  (keyup.enter)="addNewRow()"
                />
              </td>
              <td data-label="Subtotals" style="width: 100px">
                <div style="margin: 5px; text-align: center">
                  {{ itemrow.value.subtotal | currency : "USD" }}
                </div>
              </td>
              <td data-label="Add">
                <button mat-raised-button (click)="addNewRow()">+</button>
              </td>
              <td data-label="Delete">
                <button mat-raised-button (click)="deleteRow(i)">x</button>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
    <br />
    <div class="row">
      <div class="col-md-1">
        <h3>
          <strong>{{ "Subtotal" | translate }}: </strong
          >{{ totalWithoutTax | currency : "USD" }}
        </h3>
      </div>
      <div class="col-md-1">
        <h3>
          <strong>{{ "Tax" | translate }}: </strong
          >{{ totalTax | currency : "USD" }}
        </h3>
      </div>
      <div class="col-md-1">
        <h3>
          <strong>{{ "Total" | translate }}: </strong
          >{{ totalPurchase | currency : "USD" }}
        </h3>
      </div>
      <div
        class="col-md-1"
        *ngIf="companyObject.countries[0].name === 'Colombia'"
      >
        <h3>
          <strong>{{ "Withholding Tax" | translate }}: </strong
          >{{ withholdingTax | currency : "USD" }}
        </h3>
      </div>
      <div
        class="col-md-1"
        *ngIf="companyObject.countries[0].name === 'Colombia'"
      >
        <h3>
          <strong>{{ "Ica Tax" | translate }}: </strong
          >{{ icaTax | currency : "USD" }}
        </h3>
      </div>
      <div class="col-md-1">
        <h3>
          <strong>{{ "Total to pay" | translate }}: </strong
          >{{ totalPurchase - icaTax - withholdingTax | currency : "USD" }}
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
        <button mat-raised-button mat-dialog-close>
          {{ "Close" | translate }}
        </button>
      </div>
      <div class="col-md-1">
        <button mat-raised-button (click)="register()">
          {{ "Register" | translate }}
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>

<ngx-spinner
  type="ball-scale-multiple"
  template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />"
  bdColor="rgba(51,51,51,0.8)"
>
  <p style="font-size: 20px; color: #ffffff">{{ "Loading..." | translate }}</p>
</ngx-spinner>
