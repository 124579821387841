import { Injectable } from "@angular/core";
import { ITransfers } from "src/app/@interfaces/transfers.interface";

@Injectable({
  providedIn: "root",
})
export class TransferFiltersService {
  constructor() {}

  filterPreviusTransfers(transfers: ITransfers[]) {
    return transfers.filter(
      (item) =>
        item.original.type === "Cashier" && item.transferred.type === "Bank"
    );
  }
}
