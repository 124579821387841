import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { ProcessNotesService } from "../../../../@shared/components/services/charts/process-notes.service";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-average-sales",
  templateUrl: "./average-sales.component.html",
  styleUrls: ["./average-sales.component.css"],
})
export class AverageSalesComponent implements OnChanges {
  @Input() notas: Array<INotasGrap> = [];
  @Input() customerQuots: Array<IQuota> = [];
  @Input() numCustomers: number = 0;

  currentDollar = 0;
  pastDollar = 0;

  currentLiter = 0;
  pastLiter = 0;

  divideCurrent = 0;
  dividePast = 0;

  customersCurrents = 0;
  customersPasts = 0;

  percentCustomersCurrents = 0;
  percentCustomersPasts = 0;

  calculateDropSize = "";
  calculateUPT = "";
  calculateAveragePrice = "";
  calculateActivation = "";
  calculatePercentActivation = "";

  allNotas: Array<INotasGrap> = [];
  allQuots: Array<IQuota> = [];
  cantCustomer: number = 0;
  companyObject = {} as IStorageCompany;

  constructor(public analysis: ProcessNotesService, private cookieAuthService: CookieAuthService) {}

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "notas") {
        const values: Array<INotasGrap> = changes[item].currentValue;
        if (values.length > 0) {
          this.allNotas = values;
        }
      }
      if (item === "customerQuots") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.allQuots = values;
        }
      }
      if (item === "numCustomers") {
        const values: number = changes[item].currentValue;
        if (values >= 0) {
          this.cantCustomer = values;
        }
      }
      if ((this.allNotas.length > 0) || this.allQuots.length > 0 || this.cantCustomer >= 0) {
        this.calcAverage(this.allNotas, this.allQuots, this.cantCustomer);
      }
    }
  }

  calcAverage(notes: INotasGrap[], invoices: IQuota[], totalSellerCustomers: number) {
    let filterInvoices: IQuota[] = [];
    if (this.companyObject.external_electronic_invoicing === "true" || this.companyObject.internal_electronic_invoicing === "true") {
      filterInvoices = invoices.filter((data) => data.estado === 4 && data.billyInvoice![0].invoice_number !== "PENDING");
    } else {
      filterInvoices = invoices.filter((data) => data.estado === 4);
    }
    let firstArrayInvoices: number[] = [];
    let secondArrayInvoices: number[] = [];
    let firstArrayIdNotes: number[] = [];
    let secondArrayIdNotes: number[] = [];
    let firstArrayProcessNotes = [
      { fact: 0, articulo: "", cantidad: 0, litros: 0, subtotal: 0 },
    ];
    let secondArrayProcessNotes = [
      { fact: 0, articulo: "", cantidad: 0, litros: 0, subtotal: 0 },
    ];

    let firstSumQuantities = 0;
    let firstSumTotals = 0;
    let firstSumLiters = 0;

    let secondSumQuantities = 0;
    let secondSumTotals = 0;
    let secondSumLiters = 0;

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    let pastYear = currentDate.getFullYear();
    let pastMonth = currentDate.getMonth();

    let currentTotals: number[] = [0];
    let pastTotals: number[] = [0];

    let currentLiters: number[] = [0];
    let pastLiters: number[] = [0];

    let litersCurrent: number[] = [0];
    let litersPast: number[] = [0];

    let customerPast: string[] = [];
    let customerCurrent: string[] = [];

    let customersChangedCurrent: number = 0;
    let customersChangedPast: number = 0;

    let quotationsCurrent: IQuota[] = [];
    let quotationsPast: IQuota[] = [];

    if (pastMonth === 0) {
      pastMonth = 12;
      pastYear = pastYear - 1;
    }

    let createdDate: Date;
    let invoice_date: Date;

    filterInvoices.forEach(invoice => {
      if (new Date(invoice.createdAt).getFullYear() === currentYear && new Date(invoice.createdAt).getMonth() + 1 === currentMonth) {
        quotationsCurrent.push(invoice);
        customerCurrent.push(invoice.cliente[0].doc_nit);
      }
      if (new Date(invoice.createdAt).getFullYear() === pastYear && new Date(invoice.createdAt).getMonth() + 1 === pastMonth) {
        quotationsPast.push(invoice);
        customerPast.push(invoice.cliente[0].doc_nit);
      }
      if (invoice.estado === 4) {
        createdDate = new Date(invoice.createdAt);
        if (invoice.invoiced_date) {
          invoice_date = new Date(invoice.invoiced_date!);
        }else {
          invoice_date = createdDate;
        }
        if (invoice_date.getFullYear() === currentYear && invoice_date.getMonth() + 1 === currentMonth) {
          invoice.venta.forEach(sales => {
            currentTotals.push(
              sales.cantidad * sales.precio -
                (sales.descuento / 100) *
                  (sales.cantidad * sales.precio)
            );
            currentLiters.push(sales.cantidad);
            firstArrayInvoices.push(invoice.id_factura!);
            litersCurrent.push(
              sales.cantidad *
                parseFloat(sales.articulo[0].presentacion!)
            );
          });
        }
        if (invoice_date.getFullYear() === pastYear && invoice_date.getMonth() + 1 === pastMonth) {
          invoice.venta.forEach(sales => {
            pastTotals.push(
              sales.cantidad * sales.precio -
                (sales.descuento / 100) *
                  (sales.cantidad * sales.precio)
            );
            pastLiters.push(sales.cantidad);
            secondArrayInvoices.push(invoice.id_factura!);
            litersPast.push(
              sales.cantidad *
                parseFloat(sales.articulo[0].presentacion!)
            );
          })
        }
      }
    });

    customersChangedCurrent = this.calculateCustomersChanged(quotationsCurrent);
    customersChangedPast = this.calculateCustomersChanged(quotationsPast);

    let filterCustomerCurrent = customerCurrent.filter((item, index) => {
      return customerCurrent.indexOf(item) === index;
    });
    let filterCustomerPast = customerPast.filter((item, index) => {
      return customerPast.indexOf(item) === index;
    });

    this.customersCurrents = filterCustomerCurrent.length;
    this.customersPasts = filterCustomerPast.length;

    let calculatePercentCustomersCurrents = 0;
    let calculatePercentCustomersPasts = 0;

    if (totalSellerCustomers + customersChangedCurrent > filterCustomerCurrent.length) {
      calculatePercentCustomersCurrents =
        (filterCustomerCurrent.length / totalSellerCustomers) * 100;
    } else {
      calculatePercentCustomersCurrents =
        (filterCustomerCurrent.length /
          (totalSellerCustomers + customersChangedCurrent)) *
        100;
    }
    if (totalSellerCustomers + customersChangedPast > filterCustomerPast.length) {
      calculatePercentCustomersPasts =
        (filterCustomerPast.length / totalSellerCustomers) * 100;
    } else {
      calculatePercentCustomersPasts =
        (filterCustomerPast.length /
          (totalSellerCustomers + customersChangedPast)) *
        100;
    }

    this.percentCustomersCurrents = parseFloat(
      calculatePercentCustomersCurrents.toFixed(2)
    );
    this.percentCustomersPasts = parseFloat(
      calculatePercentCustomersPasts.toFixed(2)
    );

    let reduceFirstArrayInvoices = firstArrayInvoices.filter((item, index) => {
      return firstArrayInvoices.indexOf(item) === index;
    });
    let reduceSecondArrayInvoices = secondArrayInvoices.filter(
      (item, index) => {
        return secondArrayInvoices.indexOf(item) === index;
      }
    );

    notes.forEach((item) => {
      reduceFirstArrayInvoices.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          firstArrayIdNotes.push(parseInt(item.id_nota!.toString()));
        }
      });
      reduceSecondArrayInvoices.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          secondArrayIdNotes.push(parseInt(item.id_nota!.toString()));
        }
      });
    });

    firstArrayProcessNotes = this.analysis.getNotesByDescription(
      notes,
      firstArrayIdNotes
    );
    secondArrayProcessNotes = this.analysis.getNotesByDescription(
      notes,
      secondArrayIdNotes
    );

    firstArrayProcessNotes.forEach((item) => {
      firstSumQuantities += item.cantidad;
      firstSumTotals += item.subtotal;
      firstSumLiters += item.litros;
    });

    secondArrayProcessNotes.forEach((item) => {
      secondSumQuantities += item.cantidad;
      secondSumTotals += item.subtotal;
      secondSumLiters += item.litros;
    });

    ///////////////
    let sumTotalsCurrent = currentTotals!.reduce(
      (previus, current) => (current += previus)
    );
    sumTotalsCurrent = sumTotalsCurrent + firstSumTotals * 1;
    let avgTotalsCurrent = sumTotalsCurrent / reduceFirstArrayInvoices.length;
    this.currentDollar = parseFloat(avgTotalsCurrent.toFixed(2));

    let sumTotalsPast = pastTotals.reduce(
      (previus, current) => (current += previus)
    );
    sumTotalsPast = sumTotalsPast + secondSumTotals * 1;
    let avgTotalsPast = sumTotalsPast / reduceSecondArrayInvoices.length;
    this.pastDollar = parseFloat(avgTotalsPast.toFixed(2));
    ////////////////

    let sumLitersCurrent = currentLiters.reduce(
      (previus, current) => (current += previus)
    );
    sumLitersCurrent = sumLitersCurrent + firstSumQuantities * 1;
    let avgLitersCurrent = sumLitersCurrent / (currentLiters.length - 1);
    this.currentLiter = parseFloat(avgLitersCurrent.toFixed(2));

    let sumLitersPast = pastLiters.reduce(
      (previus, current) => (current += previus)
    );
    sumLitersPast = sumLitersPast + secondSumQuantities * 1;
    let avgLitersPast = sumLitersPast / (pastLiters.length - 1);
    this.pastLiter = parseFloat(avgLitersPast.toFixed(2));

    //////////////
    //Total venta dolares / litros mes actual
    let sumLitersCurrentMonth = litersCurrent.reduce(
      (previus, current) => (current += previus)
    );
    sumLitersCurrentMonth = sumLitersCurrentMonth + firstSumLiters * 1;
    let dividePastValues = sumTotalsCurrent / sumLitersCurrentMonth;
    this.divideCurrent = parseFloat(dividePastValues.toFixed(2));

    //Total venta dolares / litros mes pasado
    let sumLitersPastMonth = litersPast.reduce(
      (previus, current) => current + previus
    );
    sumLitersPastMonth = sumLitersPastMonth + secondSumLiters * 1;
    let divideCurrentValues = sumTotalsPast / sumLitersPastMonth;
    this.dividePast = parseFloat(divideCurrentValues.toFixed(2));
    //////////////
    this.calculateDropSize = this.calculate(
      this.currentDollar,
      this.pastDollar
    );
    this.calculateUPT = this.calculate(this.currentLiter, this.pastLiter);
    this.calculateAveragePrice = this.calculate(
      this.divideCurrent,
      this.dividePast
    );
    this.calculateActivation = this.calculate(
      this.customersCurrents,
      this.customersPasts
    );
    this.calculatePercentActivation = this.calculate(
      this.percentCustomersCurrents,
      this.percentCustomersPasts
    );
  }

  calculate(current: number, previus: number) {
    if (isNaN(current)) {
      current = 0;
    }
    if (isNaN(previus)) {
      previus = 0;
    }
    if (current === Infinity) {
      current = 0;
    }
    if (previus === Infinity) {
      previus = 0;
    }
    let result = 0;
    if (current === 0) {
      result = 0;
    } else if (previus === 0) {
      result = 100;
    } else {
      result = (current / previus) * 100;
    }
    return result.toFixed(2);
  }

  calculateCustomersChanged(quotations: IQuota[]) {
    let customersArray: string[] = [];
    quotations.forEach((quot) => {
      quot.cliente.forEach((customer) => {
        if (parseInt(customer.id_seller!.toString()) !== quot.id_seller) {
          customersArray.push(customer.nombre);
        }
      });
    });
    return this.reduceCustomerArray(customersArray).length;
  }

  reduceCustomerArray(array: string[]) {
    let reduce = array.filter((item, index) => {
      return array.indexOf(item) === index;
    });
    return reduce;
  }
}
