import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { IProvider } from "src/app/@interfaces/provider.interface";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProviderService } from "./provider.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-provider",
  templateUrl: "./provider.component.html",
  styleUrls: ["./provider.component.css"],
})
export class ProviderComponent implements OnInit {
  ProviderList: Array<IProvider> = [];
  companyId = '';
  length = 0;
  user = '';
  rol = '';
  unsubscribe$ = new Subject();
  constructor(
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private provider: ProviderService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinnerService.show();
    this.getAuthValues();
    this.provider
    .listProvider(0, 15)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.ProviderList = result.providers;
      this.length = result.count;
      this.spinnerService.hide();
    });
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
    this.companyId = company!.Id_company!.toString();
  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      data: { registerProvider: true },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
