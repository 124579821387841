<mat-dialog-content>
    <form [formGroup]="customerForm">
        <h3><strong>{{ 'Modify Client' | translate }}</strong></h3>
        <div class="row">
            <span>{{'Customer Type' | translate}}:</span>
            <select class="form-control" formControlName="cust_type" [compareWith]="compareCustomerType">
                <option selected value="">{{'Select customer type' | translate}}</option>
                <option *ngFor="let types of customerTypeList" [ngValue]="types" [disabled]="types.type === 'Prospecto'">
                    {{types.type}}
                </option>
            </select>
            <span class="form-text text-danger" *ngIf="textValid('cust_type')">{{'You must select a type' | translate}}</span> 
        </div>
        <div class="row">
            <div class="col-md-4">
                <span>{{'Document Type' | translate}}:</span>
                <select class="form-control" formControlName="types_docs" [compareWith]="compareDocType" (change)="updateSelectedType()">
                    <option selected value="">{{'Select Type' | translate}}</option>
                    <option *ngFor="let types of documenTypeList" [ngValue]="types">
                        {{types.description}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('types_docs')">{{'You must select a type' | translate}}</span>
            </div>
            <div class="col-md-3">
                <span> {{'Document Or Nit' | translate}}:</span>
                <input class="form-control" placeholder="{{'N/A' | translate}}" formControlName="doc_nit">
                <span class="form-text text-danger" *ngIf="textValid('doc_nit')">{{'The document must be wrote' | translate}}</span>
            </div>
            <div class="col-md-1">
                <span> {{'DV' | translate}}:</span>
                <input class="form-control" placeholder="{{'N/A' | translate}}" formControlName="dv">
                <span class="form-text text-danger" *ngIf="textValid('dv')">{{'The verification digit must be only 1 numbers' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Company Name' | translate}}:</span>
                <input class="form-control" placeholder="{{'N/A' | translate}}" formControlName="name">
                <span class="form-text text-danger" *ngIf="textValid('name')">{{'The name must be wrote' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Company Phone' | translate}}:</span>
                <input class="form-control" placeholder="{{'N/A' | translate}}" formControlName="phone">
                <span class="form-text text-danger" *ngIf="textValid('phone')">{{'The phone must be only numbers' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Company Email' | translate}}:</span>
                <input class="form-control" placeholder="{{'N/A' | translate}}" formControlName="email">
                <span class="form-text text-danger" *ngIf="textValid('email')">{{'The email is not a valid' | translate}}</span>
            </div>
            <div class="col-md-4" *ngIf="companyId === '7' || companyId === '9'">
                <label for="exampleFormControlFile1">RUT (PDF)</label>
                <input (change)="getFile($event)" type="file" class="form-control-file" formControlName="RUT">
                <span class="form-text text-danger" *ngIf="textValid('RUT')">{{'You must enter the RUT in PDF' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Company Country' | translate}}:</span>
                <select (change)="setCity(false)" class="form-control" formControlName="country" [compareWith]="compareCountry">
                    <option selected value="">{{'Select Country' | translate}}</option>
                    <option *ngFor="let country of countryList" [ngValue]="country">{{country.name}}</option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('country')">{{'The country is not valid' | translate}}</span>
            </div>
            <div class="col-md-4" *ngIf="this.customerForm.value.country.name === 'Colombia';">
                <span> {{'Select city' | translate}} </span>
                <select (change)="getCitySelect()" class="form-control" formControlName="citySelect" [compareWith]="compareCityBilly">
                    <option selected value="">{{'Select city' | translate}}</option>
                    <option *ngIf="customerForm.value.citySelect?.attributes" [ngValue]="customerForm.value.citySelect">{{customerForm.value.citySelect?.attributes.name}}</option>
                    <option *ngFor="let cities of billyCities.data" [ngValue]="cities">{{cities.attributes.name}}</option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('city')">{{'The city is not valid' | translate}}</span>
            </div>
            <div class="col-md-4" *ngIf="this.customerForm.value.country.name !== 'Colombia';">
                <span> {{'City' | translate}}:</span>
                <input class="form-control" [matAutocomplete]="autocompleteCity" placeholder="{{'Select city' | translate}}" formControlName="citySelect">
                <mat-autocomplete (optionSelected)="getCitySelect()" #autocompleteCity="matAutocomplete" [displayWith]="displayFnCity">
                    <mat-option *ngFor="let city of filterCities$ | async" [value]="city">
                        {{city}}
                    </mat-option>
                </mat-autocomplete>
                <span class="form-text text-danger" *ngIf="textValid('city')">{{"The city is not valid" | translate}}</span>        
            </div>
            <div class="col-md-4">
                <span>{{'Seller' | translate}}:</span>
                <select class="form-control" formControlName="seller" [compareWith]="compareSeller">
                    <option selected value="">{{'Select Seller' | translate}}</option>
                    <option *ngFor="let seller of sellerLists" [ngValue]="seller">
                        {{seller.seller.nombre}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('seller')">{{'You must select a seller' | translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <span> {{'Company Address' | translate}}:</span>
                <input class="form-control" placeholder="{{'N/A' | translate}}" formControlName="address">
                <span class="form-text text-danger" *ngIf="textValid('address')">{{'The address must be wrote' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Location' | translate}}:</span>
                <input class="form-control" placeholder="{{'N/A' | translate}}" formControlName="coordinates">
                <span class="form-text text-danger" *ngIf="textValid('coordinates')">{{'Location invalid' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Select Location' | translate}}:</span> &nbsp; <br>
                <button (click)="openDialog()" mat-raised-button>{{'Select Location' | translate}}</button>
            </div>
            <div class="col-md-4">
                <span>{{'Contributor Type' | translate}}:</span>
                <select class="form-control" formControlName="contributorType" [compareWith]="compareContributor">
                    <option selected value="">{{'Select Contributor Type' | translate}}</option>
                    <option *ngFor="let contributor of contributorTypeList" [ngValue]="contributor">
                        {{contributor.description}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('contributorType')">{{'You must select a contributor type' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span>{{'Segment' | translate}}:</span>
                <select class="form-control" formControlName="segment" [compareWith]="compareSegment">
                    <option selected value="">{{'Select Segment' | translate}}</option>
                    <option *ngFor="let segment of customerSegmentList" [ngValue]="segment">
                        {{segment.nombre}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('segment')">{{'You must select a segment' | translate}}</span>
            </div>
        </div>
    </form>
    <div>
        <span class="form-text text-danger ml-2">{{ clientErrorMessage | translate}}</span>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout.xs="column" id="buttons-div">
            <div id="buttons">
                <button (click)="validUpdate()" mat-raised-button>{{'Modify' | translate}}</button>
            </div>
            <div class="central-button">
                <button matTooltip="{{'VERIFICAR HABILITACION PARA FACTURACION ELECTRONICA' | translate}}" matTooltipPosition="above" [disabled]="!useElectronicBilling || !wasCreated" (click)="verifyPayerSent()" mat-raised-button>{{'EI Verify' | translate}}</button>
            </div>
            <div id="buttons">
                <button mat-raised-button [mat-dialog-close]=false>{{'Cancel' | translate}}</button>
            </div>
        </div>
    </div>
    </mat-dialog-content>
    
    <ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)" >
        <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
    </ngx-spinner>
    