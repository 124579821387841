import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { IProvider } from 'src/app/@interfaces/provider.interface';
import { IPurchaseInvoice } from 'src/app/@interfaces/purchaseInvoice.interface';
import { PurchasesService } from '../../../../../@pages/purchases/purchases.service';
import { takeUntil } from 'rxjs/operators';
import { IRefunds } from 'src/app/@interfaces/refunds.interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';

@Component({
  selector: 'app-refunds-list',
  templateUrl: './refunds-list.component.html',
  styleUrls: ['./refunds-list.component.css']
})
export class RefundsListComponent implements OnInit {
  @Input() purchaseInvoice = {} as IPurchaseInvoice;
  provider: UntypedFormControl = this.fb.control("", Validators.required);
  refundForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array(
      [
        this.fb.group({
          ids: [0],
          num_invo: [],
          refund_type: [, Validators.required],
          concept: [, Validators.required],
          total: ["", Validators.required],
          createAt: [, Validators.required],
          dueDate: [, Validators.required],
        }),
      ],
      Validators.required
    ),
  });
  get rowsArr() {
    return this.refundForm.get("itemRows") as UntypedFormArray;
  }
  refundsList: IRefunds[] = [];
  unsubscribe$ = new Subject();
  companyId = '';
  constructor(
    private fb: UntypedFormBuilder,
    private purchasesService: PurchasesService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private cookieAuthService: CookieAuthService
  ) { }

  ngOnInit(): void {
    this.getAuthValues();
    this.setPurchase(this.purchaseInvoice);
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  setPurchase(purchaseInvoice: IPurchaseInvoice) {
    const { provider } = purchaseInvoice;
    this.setRefund(purchaseInvoice, provider);
  }

  setRefund(purchaseInvoice: IPurchaseInvoice, provider: IProvider[]) {
    this.purchasesService
    .getRefundsByPurchaseID(parseInt(purchaseInvoice.id_invoice!.toString()))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.refundsList = result;
      this.editQuotation(
        purchaseInvoice,
        provider,
        this.refundsList
      );
    });
  }

  editQuotation(purchaseInvoice: IPurchaseInvoice, provider: IProvider[], refundsList: IRefunds[]) {
    this.refundForm.patchValue({
      tax: purchaseInvoice.tax,
    });
    this.provider.setValue(provider[0]);
    this.refundForm.setControl("itemRows", this.setRefundsValues(refundsList));
  }

  setRefundsValues(refundsList: IRefunds[]): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    for (let index = 0; index < refundsList.length; index++) {
      formArray.push(
        this.fb.group({
          ids: refundsList[index].id_refund,
          num_invo: refundsList[index].num_invo,
          refund_type: refundsList[index].refund_type,
          concept: refundsList[index].concept,
          total: refundsList[index].total,
          createAt: refundsList[index].createAt,
          dueDate: refundsList[index].dueDate,
        })
      );
    }
    return formArray;
  }

  displayFnProvider(provider: any) {
    return provider && provider ? provider.nombre : undefined;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
