<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/22.1.6/css/dx.common.css" />
<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/22.1.6/css/dx.light.css" />

<div id="list-demo">
  <div class="widget-container">
    <dx-list
      #list
      [dataSource]="data!"
      [height]="400"
      [selectionMode]="selectionModeValue"
      [selectAllMode]="selectAllModeVlaue"
      [showSelectionControls]="true"
      [selectedItemKeys]="[]"
      (onSelectionChanged)="selectedItems()"
    ></dx-list>
    <div class="selected-data">
      <button *ngIf="exportData" mat-raised-button (click)="export();">{{'Export' | translate}}</button>
      <button *ngIf="transfer" mat-raised-button (click)="register();">{{'Register' | translate}}</button>
      <button *ngIf="close" mat-raised-button mat-dialog-close="">{{'Close' | translate}}</button>
    </div>
  </div>
</div>