import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { IWarehouse } from 'src/app/@interfaces/warehouse.interface';
import { startWith, takeUntil } from 'rxjs/operators';
import { map } from "rxjs/internal/operators/map";
import { WarehouseService } from '../../../../@pages/warehouse/warehouse.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IArticles } from 'src/app/@interfaces/article.interface';
import { InventoryService } from '../../../../@pages/inventory/inventory.service';
import { ErrorsService } from '../../services/alerts/errors.service';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';
import { AlertsService } from '../../services/alerts/alerts.service';

@Component({
  selector: 'app-crud-warehouse-transfer',
  templateUrl: './crud-warehouse-transfer.component.html',
  styleUrls: ['./crud-warehouse-transfer.component.css']
})
export class CrudWarehouseTransferComponent implements OnInit {
  dateForm: UntypedFormControl = this.fb.control(new Date(), Validators.required);
  transferForm:  UntypedFormGroup = this.fb.group({
    article: ["", [Validators.required]],
    transferTo: ["", [Validators.required]],
    transferOf: ["", [Validators.required]],
    value: [0, [Validators.required, Validators.min(0.01), Validators.max(99999999.99)]],
  });
  filteredOptionsTransferTo: Observable<IWarehouse[]> | undefined;
  filteredOptionsTransferOf: Observable<IWarehouse[]> | undefined;
  warehouseList: IWarehouse[] = [];
  articleList: IArticles[] = [];
  unsubscribe$ = new Subject();
  user = '';

  constructor(
    private fb: UntypedFormBuilder,
    private warehouseService: WarehouseService,
    private spinnerService: NgxSpinnerService,
    private alertsService: AlertsService,
    private inventoryService: InventoryService,
    private errorsService: ErrorsService,
    private cookieAuthService: CookieAuthService
  ) { }

  async ngOnInit() {
    this.getAuthValues();
    this.data();
    this.filterInputs();
  }

  getAuthValues() {
    this.user = this.cookieAuthService.getUserId!;
  }

  data() {
    this.warehouseService
    .listWarehouses()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.warehouseList = result;
    })
    this.findArticles();
  }

  findArticles() {
    this.transferForm.get("article")?.valueChanges.subscribe((result) => {
      if(!result.__typename) {
        this.inventoryService
        .searchArticle(result, null, null, null)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.articleList = result.articles;
        })
      }
    })
  }

  filterInputs() {
    this.filteredOptionsTransferTo = this.transferForm.get("transferTo")?.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterP(value))
    );
    this.filteredOptionsTransferOf = this.transferForm.get("transferOf")?.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterP(value))
    );
  }

  _filterP(value: string): IWarehouse[] {
    const filterValueP = value.toString().toLowerCase();
    return this.warehouseList.filter((option) =>
      option.nombre.toLowerCase().toString().includes(filterValueP)
    );
  }

  displayFnWarehouse(warehouse: any) {
    return warehouse && warehouse ? warehouse.nombre : undefined;
  }
  
  displayFnArticle(article: any) {
    return article && article ? article.descripcion : undefined;
  }

  register() {
    this.spinnerService.show();
    if (this.transferForm.invalid === true) {
      this.spinnerService.hide();
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return;
    }else {
      const date = this.dateForm.value;
      this.warehouseService.createWarehouseTransfer(
        parseInt(this.transferForm.value.article.id_articulo),
        parseInt(this.transferForm.value.transferOf.id_almacen),
        parseInt(this.transferForm.value.transferTo.id_almacen),
        new Date(date).toDateString(),
        this.transferForm.value.value,
        parseInt(this.user!)
      ).pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.afterCreated();
      },(error) => {
        this.onCreatedError(error);
      })
    }
  }

  afterCreated() {
    this.spinnerService.hide();
    this.alertsService.showSuccessfullAlert("Transfers Created").then((result) => {
      if (result.isConfirmed) {
        this.alertsService.refresh("/table", "/inventory_movements");
      }
    });
  }

  onCreatedError(error: any) {
    const errorTransformed = this.errorsService.getErrorFromJson(error)
    this.spinnerService.hide();
    this.alertsService.showErrorAlert(errorTransformed.message.split(':')[1].trim())
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
