
  <div *ngIf="showMenu" class="header-container" [class.it-is-mobile]="mobileQuery?.matches">
    <mat-toolbar color="primary" class="mobile-toolbar">
    <button mat-icon-button class="example-icon" style="color: #73879C;" (click)="snav.toggle(); resizeTabs()" *ngIf="currentCompany">
      <mat-icon>menu</mat-icon>
    </button>
  
    <span class="example-spacer"></span>
    
    <a [routerLink]="['/home']" routerLinkActive="router-link-active">
      <picture>
        <source media="(min-width:0px)" srcset="../../../assets/img/v1insight.png">
        <img class="img-logo" mat-card-image src="">
      </picture>
    </a>
    
    <span class="example-spacer"></span>

    <div class="div-logoff" *ngIf="access">
      <button (click)="logOut(); snav.close()" mat-button class="boton">Log Out</button>
    </div>
    </mat-toolbar>
  
    <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery?.matches ? 56 : 0">
      <mat-sidenav #snav [mode]="mobileQuery?.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery?.matches"
        fixedTopGap="56" [opened]="false">
        <mat-nav-list *ngIf="access">
          <ng-container *ngFor="let rol of me.roles">
            <div class="circulo"
              style="background-image: url('../../../assets/icons/adminUser.jpg'); background-size: 100px;"></div>
            <div class="rolNameCenter">
              <i>{{me.name}}<br>{{rol.name}}</i>
            </div>
            <mat-tree class="tree-menu" [dataSource]="dataSource" [treeControl]="treeControl">
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <a mat-list-item [routerLink]="node.route" (click)="snav.close()">
                  <mat-icon>{{node.icon}}</mat-icon>
                  {{node.name | translate}}
                </a>
              </mat-tree-node>
              <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                <button style="color: white;" mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name" >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <a mat-list-item [routerLink]="node.route" (click)="snav.close()">
                  <mat-icon>{{node.icon}}</mat-icon>
                  {{node.name | translate}}
                </a>
              </mat-tree-node>
            </mat-tree>
            <div [ngSwitch]="currentCompany">
              <div *ngSwitchCase="1"><img class="centradol2" style="width: 250px; height: 50px;" src="../../../assets/img/v1rotate.png"></div>
              <div *ngSwitchCase="2"><img class="centradol2" style="width: 250px; height: 50px;" src="../../../assets/img/IbexportsBanner.png"></div>
              <div *ngSwitchCase="4"><img class="centradol2" style="width: 250px; height: 50px;" src="../../../assets/img/JensBanner.png"></div>
              <div *ngSwitchCase="5"><img class="centradol2" style="width: 250px; height: 100px;" src="../../../assets/img/logoVersa.png"></div>
              <div *ngSwitchCase="6"><img class="centradol2" style="width: 250px; height: 100px;" src="../../../assets/img/Parabricentro.png"></div>
              <div *ngSwitchCase="7"><img class="centradol2" style="width: 250px; height: 50px;" src="../../../assets/img/v1rotate.png"></div>
              <div *ngSwitchCase="8"><img class="centradol2" style="width: 250px; height: 100px;" src="../../../assets/img/Parabricentro.png"></div>
              <div *ngSwitchCase="9"><img class="centradol2" style="width: 250px; height: 100px;" src="../../../assets/img/v1rotate.png"></div>
            </div>
          </ng-container>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
    <div>
      <footer>
        <div style="text-align: center;">
          <label>{{companyName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{'Change Language' | translate}}: &nbsp;&nbsp;&nbsp;</label>
          <select #langSelect (change)="changeLang(langSelect.value)">
            <option *ngFor="let lang of languages" [value]="lang">{{ lang }}</option>
          </select>
          <label>&nbsp;&nbsp;&nbsp; V1-INSIGHT - &nbsp;Version&nbsp;:&nbsp;1.0&nbsp;</label>
        </div>
      </footer>
    </div> 
  </div>

  <div *ngIf="!showMenu">
    <router-outlet></router-outlet>
  </div>
