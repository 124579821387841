import { Pipe, PipeTransform } from '@angular/core';
import { IPurchaseInvoice } from 'src/app/@interfaces/purchaseInvoice.interface';

@Pipe({
  name: 'filterPPurchase'
})
export class FilterPPurchase implements PipeTransform {
  transform(purchases: IPurchaseInvoice[], provider: string): IPurchaseInvoice[] {
    if (!purchases || !provider) {
      return purchases;
    }
    return purchases.filter(purchase => 
        purchase.id_provider!.toString() === provider
    )
  }
}