import { Injectable, OnDestroy } from '@angular/core';
import { TreasuryService } from "src/app/@pages/treasury/treasury.service";
import { NgxSpinnerService } from "ngx-spinner";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorsService } from '../../services/alerts/errors.service';
import { AlertsService } from '../../services/alerts/alerts.service';

@Injectable({
  providedIn: "root",
})
export class TransfersService implements OnDestroy {
  unsubscribe$ = new Subject();
  constructor(
    private treasuryService: TreasuryService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private errorsService: ErrorsService,
    private alertsService: AlertsService
  ) {}

  async createSimpleTransfer(    
    type: string,
    date: string,
    custom_value: number,
    original_payment: number,
    transferred_payment: number
  ) {
    return new Promise<boolean>((resolve, reject) => {
      this.treasuryService
      .createTransfer(
        type,
        date,
        custom_value,
        original_payment,
        transferred_payment
      ).pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(true);
      },(error) => {
        const errorTransformed = this.errorsService.getErrorFromJson(error)
        this.spinnerService.hide();
        this.alertsService.showErrorAlert(errorTransformed.message.split(':')[1].trim())
        .then((closed) => {
          if (closed.isConfirmed) {
            reject(false);
          }
        });
      })
    })
  }

  validSuccessfullTransfers(successfullTransfers: boolean[]) {
    const allSuccessfull = successfullTransfers.every(item => item === true);
    if (allSuccessfull) {
      this.alertsService.showSuccessfullAlert("Transfers Created").then((result) => {
        if (result.isConfirmed) {
          this.router
          .navigateByUrl("/table", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/treasury"]);
          });
        }
      });
    } else {
      this.alertsService.showErrorAlert("Some transfers may not have been created");
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
