import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsPortfolioRoutingModule } from './details-portfolio-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchModule } from 'src/app/@shared/components/search/search.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DetailsPortfolioComponent } from './details-portfolio.component';
import { DialogModule } from '../../@shared/components/dialog/dialog.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterSQuots } from '../../@shared/pipe/graphs-pipes/quotations/seller.pipe';
import { SortModule } from '../../directives/sortDirective/sort.module';
import { PipesModule } from 'src/app/pipes.module';
import { FilterSCQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/seller-customer.pipe';
import { FilterCQuots } from 'src/app/@shared/pipe/graphs-pipes/quotations/customer.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [DetailsPortfolioComponent],
  imports: [
    CommonModule,
    DetailsPortfolioRoutingModule,
    MaterialModule,
    NgxPaginationModule,
    SearchModule,
    MatIconModule,
    TranslateModule,
    DialogModule,
    ReactiveFormsModule,
    SortModule,
    PipesModule,
    NgxSpinnerModule,
    FlexLayoutModule
  ],
  providers: [
    FilterSQuots,
    FilterSCQuots,
    FilterCQuots
  ]
})
export class DetailsPortfolioModule { }
