    <div id="img1">
        <app-average-sales [numCustomers]="numCustomers" [customerQuots]="quots" [notas]="notas"></app-average-sales>
    </div>
    <div class="row" id="img2">
        <div class="col-md-6">
            <app-sales-growth [customerQuots]="quots" [notas]="notas"></app-sales-growth>
        </div>
        <div class="col-md-6">
            <app-sales-target [monthTarjet]="monthTarjet" [id_seller]="id_seller" [customerQuots]="quots" [typePres]="typePres" [id_type]="id_type" [notas]="notas"></app-sales-target>
        </div>
    </div>
    <div id="img3">
        <div class="alert alert-info">
            {{'Sales to date' | translate}}
        </div>
        <div class="col-md-6">
            <app-sales-to-date [yearTarjet]="yearTarjet" [id_seller]="id_seller" [customerQuots]="quots" [id_type]="id_type" [notas]="notas"></app-sales-to-date>
        </div>
    </div>
    <div id="img4">
        <app-sales-by-region [presentationType]="typePres" [quotations]="quots" [notes]="notas"></app-sales-by-region>
    </div>
    <div id="img5">
        <app-quot-today [quotToday]="quotTodayV1"></app-quot-today>
    </div>
    <div id="img6">
        <app-sales-per-seller [quotToday]="quotTodayV1"></app-sales-per-seller>
    </div>

    <div hidden>
        <div *ngIf="companyId ==='1'">
            <img id="img" src="../../../../assets/img/v1rotateBlack.png">
        </div>

        <div *ngIf="companyId ==='2'">
            <img id="img" src="../../../../assets/img/Ibexports.png">
        </div>

        <div *ngIf="companyId ==='5'">
            <img id="img" src="../../../../assets/icons/letrasVersa.png">
        </div>

        <div *ngIf="companyId ==='6'">
            <img id="img" src="../../../../assets/img/Parabricentro.png">
        </div>

        <div *ngIf="companyId ==='7'">
            <img id="img" src="../../../../assets/img/v1rotateBlack.png">
        </div>

        <div *ngIf="companyId ==='8'">
            <img id="img" src="../../../../assets/img/Parabricentro.png"\>
        </div>

        <div *ngIf="companyId ==='4'">
            <img id="img" src="../../../../assets/img/Jens.png">
        </div>

        <div *ngIf="companyId ==='9'">
            <img id="img" src="../../../../assets/img/v1rotateBlack.png">
        </div>
    </div>