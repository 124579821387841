<div style="display: block" *ngIf="barChartMulData.length !== 0">
    <canvas baseChart
        [datasets]= "barChartData"
        [data]="barChartMulData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [chartType]="barChartType">
    </canvas>
</div>

<div style="display: block" *ngIf="barChartMulData.length === 0">
    <canvas baseChart
        [datasets]= "barChartData"
        [data]="barChartSingleData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [chartType]="barChartType">
    </canvas>
</div>
