import { Component, Input, OnInit } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Label, MultiDataSet, SingleDataSet } from "ng2-charts";

@Component({
  selector: "app-bar-chart",
  templateUrl: "./barras.component.html",
  styleUrls: ["./barras.component.css"],
})
export class BarrasComponent implements OnInit {
  @Input() horizontal: boolean = false;

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: "end",
        aling: "end",
      },
    },
  };

  @Input() barChartLabels: Label[] = [];
  @Input() barChartData: ChartDataSets[] = [];
  @Input() barChartMulData: MultiDataSet = [];
  @Input() barChartSingleData: SingleDataSet = [];

  public barChartType: ChartType = "bar";
  public barChartLegend = true;

  constructor() {}

  ngOnInit(): void {}
}
