import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ValidateMultipleTypesService {
  constructor() {}

  isNumberOrObject(item: any, property: string) {
    if (typeof item === "number") {
      return item;
    } else if (typeof item === "object") {
      return item[property];
    } else {
      return "N/A";
    }
  }
}
