import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ChartDataSets } from "chart.js";
import { Label, SingleDataSet } from "ng2-charts";
import { IDollarPerClientData, ITopCustomerNotes } from "src/app/@interfaces/Analysis/dollar-per-client.interface";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { AnalysisService } from "../../../analysis.service";
import { CalculateAnalysis } from "src/app/@shared/components/services/charts/calculate-analysis.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { ConverseArrayService } from "src/app/@shared/components/services/data-types/converse-array.service";

@Component({
  selector: "app-dollars-per-client-graph",
  templateUrl: "./dollars-per-client-graph.component.html",
  styleUrls: ["./dollars-per-client-graph.component.css"],
})
export class DollarsPerClientGraphComponent implements OnChanges {
  @Input() dollarPerClientData = {} as IDollarPerClientData;
  @Input() notesByCode: ITopCustomerNotes[] = [];

  dollarPerClientDataToUse = {} as IDollarPerClientData;
  notesByCodeToUse: ITopCustomerNotes[] = [];

  dataTopCustomerLabel: Label[] = [];
  dataTopCustomer: SingleDataSet = [];
  barChartDataSets: ChartDataSets[] = [
    {
      label: "Total $:",
      backgroundColor: "#0C3871",
      hoverBackgroundColor: "#0F4D9D",
    },
  ];
  companyObject = {} as IStorageCompany;

  constructor(
    private analysisService: AnalysisService, 
    private calculateAnalysis: CalculateAnalysis,
    private cookieAuthService: CookieAuthService,
    private converseArrayService: ConverseArrayService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "dollarPerClientData") {
        const values: IDollarPerClientData = changes[item].currentValue;
        if (values) {
          this.dollarPerClientDataToUse = values;
        }
      }
      if (item === "notesByCode") {
        const note: Array<ITopCustomerNotes> = changes[item].currentValue;
        if (note.length > 0) {
          this.notesByCodeToUse = note;
        }
      }
      if (this.dollarPerClientDataToUse.initialData || (this.notesByCodeToUse.length > 0)) {
        this.topCalculation(this.dollarPerClientDataToUse, this.notesByCodeToUse);
      }
    }
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  topCalculation(dollarPerClientData: IDollarPerClientData, notesByCode: ITopCustomerNotes[]) {
    const reduceNotes = this.analysisService.reduceNotes(notesByCode);
    const customers = this.converseArrayService.reduceArrays(dollarPerClientData.customersArray);
    const reduceInitialData = this.analysisService.reduceArrayInit(dollarPerClientData.initialData);
    const calculatedByElement = this.calculateAnalysis.calculateByElementCustomer(customers, reduceInitialData, reduceNotes, 'invoice', 'article', 'value', 'subtotal');
    const arrayFiltered = calculatedByElement.arrayFiltered;
    const totals = calculatedByElement.totals;
    arrayFiltered.push({ amount: totals, customer: "Total" });
    this.constructFinalArray(arrayFiltered);
  }

  constructFinalArray(arrayFiltered: any[]) {
    const sortArray = this.calculateAnalysis.constructFinalArray(arrayFiltered, "amount", "customer", 20);
    this.dataTopCustomerLabel = sortArray.chartLabels;
    this.dataTopCustomer = sortArray.chartData;
  }
}
