import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { startWith, map, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { IWarehouse } from "src/app/@interfaces/warehouse.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { INotas } from "../../../../../../@interfaces/notas.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-ver-debito",
  templateUrl: "./ver-debito.component.html",
  styleUrls: ["./ver-debito.component.css"],
})
export class VerDebitoComponent implements OnInit, OnDestroy {
  @Input() id_fact: number = 0;
  totalQuot: number = 0;
  newCustomer: UntypedFormControl = this.fb.control("", Validators.required);
  registerForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array(
      [
        this.fb.group({
          aidi: [0],
          note_fact: [],
          codigo: [, Validators.required],
          tipo_nota: [, Validators.required],
          concepto: [, Validators.required],
          precio: [, Validators.required],
          subtotal: [0],
          total: ["", Validators.required],
          date: [, Validators.required],
          dueDate: [, Validators.required],
        }),
      ],
      Validators.required
    ),
  });
  get rowsArr() {
    return this.registerForm.get("itemRows") as UntypedFormArray;
  }
  QuotationToModify = {} as IQuota;
  noteToSee = {} as INotas;
  filteredOptionsCustomer: Observable<ICustomer[]> | undefined;
  idsArray = [
    {
      id: [],
    },
  ];
  CustomersList: Array<ICustomer> = [];
  WarehouseList: Array<IWarehouse> = [];
  unsubscribe$ = new Subject();
  companyId = '';

  constructor(
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    public quotation: QuotationsService,
    public warehouseS: WarehouseService,
    private fb: UntypedFormBuilder,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getAuthValues()
    this.filteredValues();
    this.QuotationToModify = await this.getQuotation();
    this.setQuotation(this.QuotationToModify);
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  getQuotation() {
    return new Promise<IQuota>((resolve, reject) => {
      this.quotation
      .getQuotation(this.id_fact)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result)
      })
    })
  }

  setQuotation(quotation: IQuota) {
    const { cliente } = quotation;
    this.setDebit(quotation, cliente);
  }

  setDebit(quotation: IQuota, cliente: ICustomer[]) {
    this.quotation
    .getDebito(parseInt(this.id_fact.toString()))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.noteToSee = result;
      this.editQuotation(
        quotation,
        cliente,
        this.noteToSee
      );
    });
  }

  filteredValues() {
    this.filteredOptionsCustomer = this.newCustomer.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterC(value))
    );
  }

  editQuotation(quotation: any, customer: ICustomer[], notas: any) {
    this.registerForm.patchValue({
      tax: quotation.tax,
    });
    this.newCustomer.setValue(customer[0]);
    this.registerForm.setControl("itemRows", this.setVenta(notas));
  }

  setVenta(notas: any): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    for (let index = 0; index < notas.length; index++) {
      this.idsArray[0].id = notas[index].id_nota;
      formArray.push(
        this.fb.group({
          aidi: notas[index].id_nota,
          note_fact: notas[index].note_fact,
          tipo_nota: notas[index].tipo_nota,
          concepto: notas[index].concepto,
          precio: notas[index].precio,
          subtotal: notas[index].subtotal,
          total: notas[index].total,
          date: notas[index].createAt,
          dueDate: notas[index].dueDate,
        })
      );
    }
    return formArray;
  }

  private _filterC(value: string): ICustomer[] {
    const filterValueC = value.toString().toLowerCase();
    return this.CustomersList.filter((option) =>
      option.doc_nit.toLowerCase().toString().includes(filterValueC) ||
      option.nombre.toLowerCase().toString().includes(filterValueC)
    );
  }

  displayFnCustomer(customer: any) {
    return customer && customer ? customer.nombre : undefined;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
