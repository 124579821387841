import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { catchError } from "rxjs/operators";
import { FinancialReportsService } from "../financial-reports/financial-reports.service";
import { of } from "rxjs";
import { AlertsService } from "src/app/@shared/components/services/alerts/alerts.service";

@Component({
  selector: "app-processing",
  templateUrl: "./processing.component.html",
  styleUrls: ["./processing.component.css"],
})
export class ProcessingComponent implements OnInit {
  dialogData: any;
  chartAccounts: any = [];
  isComplete: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ProcessingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private financialReportsService: FinancialReportsService,
    private alertService: AlertsService,
  ) {
    this.getDialogData(data);
  }

  getDialogData(data: any) {
    this.dialogData = data;
  }

  ngOnInit(): void {
    this.processReports();
  }

  async processReports() {
    switch (this.dialogData.report) {
      case "Accounting Movements":
        await this.getAccountingMovements();
      break;
    }
  }

  async getAccountingMovements() {
    const getChartAccountsResponse =
      await this.financialReportsService.getChartAccounts(
        this.dialogData.dateSelected.start!,
        this.dialogData.dateSelected.end!,
        this.dialogData.companyId
      );
    getChartAccountsResponse
      .pipe(
        catchError((err) => {
          this.alertService.showWarningAlert(
            "Error generating file, please try later"
          );
          this.closeDialog();
          return of(err);
        })
      )
      .subscribe((res: any) => {
        if (res.data.length > 0) {
          this.chartAccounts = res.data;
          this.isComplete = true;
        } else {
          this.alertService.showWarningAlert(
            "There are not results for the date selected, please try selecting other"
          );
          this.closeDialog();
        }
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
