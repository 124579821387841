import { Pipe, PipeTransform } from '@angular/core';
import { IQuotation } from 'src/app/@interfaces/quotation.interface';
import { IVentaQuery } from '../../../../@interfaces/venta.interface';

@Pipe({
  name: 'filterDSales'
})
export class FilterDSales implements PipeTransform {
  transform(sales: IVentaQuery[], date1: Date, date2: Date): IVentaQuery[] {
    return sales.filter((sale) => { 
      if (sale.factura!.length > 0 && sale.factura![0].invoiced_date!) {
        return(
          sale.factura!.length > 0 &&
          (new Date(sale.factura![0].invoiced_date!) >= date1 &&
          new Date(sale.factura![0].invoiced_date!) <= date2)
        )
      }else {
        return(
          sale.factura!.length > 0 &&
          (new Date(sale.factura![0].createdAt) >= date1 &&
          new Date(sale.factura![0].createdAt) <= date2)
        )
      }
    })
  }
}
