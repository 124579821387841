
<mat-tab-group #tabs mat-align-tabs="center" dynamicHeight="true" (selectedTabChange)="selectTab($event)">

     <mat-tab  label="{{'Purchases' | translate}}" >
      <div class="row">
        <div class="col" style="margin-left: 20px;">
          <a (click)="openDialog(true)"><button mat-raised-button class="Warn">{{'Register' | translate}}</button></a>
        </div>
        <div class="col-6">
          <mat-paginator [length]="this.purchasesListBy1.length" [showFirstLastButtons]="true" [pageSize]="4"
          [pageSizeOptions]="[4,8,12]" (page)="OnPageChange1($event)"></mat-paginator>
        </div>
        <div class="col" style="text-align: right; margin-right: 20px;">
          <app-search (search)="handleSearch($event, 1)"></app-search>
        </div>
      </div>
   
      <div class="row">
        <div *ngFor="let purchase of pageSliceBy1" class="col-lg-3">
          <app-purchase-card [purchaseInvoice]="purchase" [refunds]="refunds" [cardType]="1"></app-purchase-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab  label="{{'Voided' | translate}}" >
      <div class="row">
        <div class="col" style="margin-left: 20px;">
          <a (click)="openDialog(true)"><button mat-raised-button class="Warn">{{'Register' | translate}}</button></a>
        </div>
        <div class="col-6">
          <mat-paginator [length]="this.purchasesListBy2.length" [showFirstLastButtons]="true" [pageSize]="4"
          [pageSizeOptions]="[4,8,12]" (page)="OnPageChange2($event)"></mat-paginator>
        </div>
        <div class="col" style="text-align: right; margin-right: 20px;">
          <app-search (search)="handleSearch($event, 2)"></app-search>
        </div>
      </div>
   
      <div class="row">
        <div *ngFor="let purchase of pageSliceBy2" class="col-lg-3">
          <app-purchase-card [purchaseInvoice]="purchase" [refunds]="refunds" [cardType]="1"></app-purchase-card>
        </div>
      </div>
    </mat-tab>

</mat-tab-group>
  
<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
              
