/*global $, document, window, setTimeout, navigator, console, location*/
$(document).ready(function () {
  "use strict";
  // form switch
  $("input").blur(function () {
    // label effect
    if ($(this).val().length > 0) {
      $(this).siblings("label").addClass("active");
    } else {
      $(this).siblings("label").removeClass("active");
    }
  });

  $("a.switch").click(function (e) {
    $(this).toggleClass("active");
    e.preventDefault();
    if ($("a.switch").hasClass("active")) {
      $(this)
        .parents(".form-peice")
        .addClass("switched")
        .siblings(".form-peice")
        .removeClass("switched");
    } else {
      $(this)
        .parents(".form-peice")
        .removeClass("switched")
        .siblings(".form-peice")
        .addClass("switched");
    }
  });
});
