import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { AnalysisService } from "../analysis/analysis.service";
import { CustomerService } from "../customer/customer.service";
import { NgxSpinnerService } from "ngx-spinner";
import { InventoryService } from "../inventory/inventory.service";
import { IPresentationTypes } from "../../@interfaces/presentationTypes";
import { EventEmitterService } from "src/app/@core/menu/emitterFuncions.service";
import { Subject } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { FilterSQuots } from "../../@shared/pipe/graphs-pipes/quotations/seller.pipe";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder
} from "@angular/forms";
import { SellersService } from "../sellers/sellers.service";
import { ISeller } from "../../@interfaces/seller.interface";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { FilterPTQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/presentation.pipe";
import { FilterPTNotes } from "src/app/@shared/pipe/graphs-pipes/notes/presentation.pipe";
import { HomeService } from './home.service';
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { FilterBySearchService } from "src/app/@shared/components/services/filters/filterBySearch.service";
import { GraphicQueriesService } from "src/app/@shared/components/services/charts/graphic-queries.service";
import { ConverseDateService } from "src/app/@shared/components/services/data-types/converse-date.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, OnDestroy {
  sellerList: Array<ISeller> = [];
  quotToday: Array<IQuota> = [];
  quotTodayPrimary: Array<IQuota> = [];

  quotaFilterTypes: Array<IQuota> = [];
  quotaListPrimary: Array<IQuota> = [];
  quots: Array<IQuota> = [];

  notasFilterTypes: Array<INotasGrap> = [];
  notasListPrimary: Array<INotasGrap> = [];
  notas: Array<INotasGrap> = [];

  presentationTypes: IPresentationTypes[] = [];
  companyId = '';
  numCustomers: number = 0;
  months: number = 0;
  user = '';
  rol = '';

  typePres: string = "All";
  id_type: number | null = null;
  id_seller: number | null = null;
  monthTarjet: number = 0;
  yearTarjet: number = 0;

  date = new Date();
  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;
  registerForm: UntypedFormGroup = this.fb.group({
    seller: [, Validators.required],
    typeForm: [, Validators.required],
  });

  constructor(
    private inventory: InventoryService,
    private spinnerService: NgxSpinnerService,
    public analysis: AnalysisService,
    public customer: CustomerService,
    private eventEmitterService: EventEmitterService,
    private analysisService: AnalysisService,
    private filterSQuots: FilterSQuots,
    private fb: UntypedFormBuilder,
    private seller: SellersService,
    private filterSearchService: FilterBySearchService,
    private filterPTQuots: FilterPTQuots,
    private filterPTNotes: FilterPTNotes,
    private dialog: MatDialog,
    private graphicQueriesService: GraphicQueriesService,
    private homeService: HomeService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService,
    private converseDateService: ConverseDateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinnerService.show();
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.data();
    this.dataFilters();
    this.getCustomerQuantities();
    this.toggleExpandCard();
    this.getEvent();
    this.handleSearch();
    this.getGoals(null, null);
    this.spinnerService.hide();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  async getGoals(id_seller: number | null, id_type: number | null) {
    this.monthTarjet = 0; this.yearTarjet = 0;
    this.monthTarjet = await this.homeService.getGoal(id_seller!, "MONTH", id_type!);
    this.yearTarjet = await this.homeService.getGoal(id_seller!, "YEAR",  id_type!);
  }

  handleSearch() {
    this.registerForm
    .get("seller")
    ?.valueChanges.pipe(debounceTime(300), takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        if (!result.__typename) {
          const array = this.filterSearchService.filterBySeller(
            this.quotaFilterTypes,
            result
          );
          const arrayToday = this.filterSearchService.filterBySeller(
            this.quotTodayPrimary,
            result
          );
          this.setValuesBySearch(
            array.quotArray,
            arrayToday.quotArray
          );
        }
      } else {
        this.setValuesBySearch(
          this.quotaFilterTypes,
          this.quotTodayPrimary
        );
      }
    });
  }

  setValuesBySearch(quotsArray: IQuota[], quotsToday: IQuota[]) {
    if (quotsArray.length <= 0) {
      this.quots = this.analysisService.quotsNull();
      this.monthDiff(this.analysisService.quotsNull());
    } else {
      this.quots = quotsArray;
      this.monthDiff(quotsArray);
    }
    if (quotsToday.length <= 0) {
      this.quotToday = this.analysisService.quotsNull();
    } else {
      this.quotToday = quotsToday;
    }
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((name: string) => {
      this.toggleExpandCard();
    });
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 500);
  }

  filterTypes(event: any, quots: IQuota[], notes: INotasGrap[]) {
    this.registerForm.get("seller")?.setValue("");
    this.id_seller = null;
    this.id_type = parseInt(event.value.id_type);
    this.typePres = event.value.value;
    let filterQuots: IQuota[] = this.filterPTQuots.transform(quots, this.id_type.toString());
    let filterNotes: INotasGrap[] = this.filterPTNotes.transform(notes, this.id_type.toString());
    filterQuots = filterQuots.length === 0 ? this.analysis.quotsNull() : filterQuots;
    filterNotes = filterNotes.length === 0 ? this.analysis.notesNull() : filterNotes;
    this.quots = filterQuots;
    this.quotaFilterTypes = filterQuots;
    this.notas = filterNotes;
    this.notasFilterTypes = filterNotes;
    this.monthDiff(filterQuots);
    this.getGoals(this.id_seller!, this.id_type);
  }

  filterQuots(quotsToday: IQuota[], quots: IQuota[], seller: string) {
    let filter: IQuota[] = [], filterToday: IQuota[] = [];
    if (seller !== null) {
      filter = this.filterSQuots.transform(quots, seller);
      filterToday = this.filterSQuots.transform(quotsToday, seller);
      if (filter.length === 0) {
        this.quots = this.analysisService.quotsNull();
        this.monthDiff(this.analysisService.quotsNull());
      } else {
        this.quots = filter;
        this.monthDiff(filter);
      }
      if (filterToday.length === 0) {
        this.quotToday = this.analysisService.quotsNull();
      } else {
        this.quotToday = filterToday;
      }
    } else {
      this.quots = quots;
      this.quotToday = quotsToday;
      this.monthDiff(quots);
    }
  }

  update(quots: IQuota[], quotsToday: IQuota[]) {
    this.spinnerService.show();
    const sellerObj = this.registerForm.get("seller")?.value;
    let id_seller = null;
    if (sellerObj) {
      id_seller = sellerObj.id_seller;
    }
    this.id_seller = parseInt(id_seller);
    this.getCustomerQuantitiesBySeller(parseInt(id_seller));
    this.filterQuots(quotsToday, quots, id_seller);
    this.getGoals(this.id_seller!, this.id_type!);
    this.spinnerService.hide();
  }

  getCustomerQuantitiesBySeller(id_seller: number) {
    this.customer
    .countCustomerListBySearch(id_seller, false)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.numCustomers = result;
    });
  }

  reset(quots: IQuota[], quotsToday: IQuota[], notes: INotasGrap[]) {
    this.spinnerService.show();
    this.getCustomerQuantities();
    this.typePres = "All";

    this.quots = quots;
    this.quotaFilterTypes = quots;
    this.quotaListPrimary = quots;

    this.quotToday = quotsToday;
    this.quotTodayPrimary = quotsToday;

    this.notas = notes;
    this.notasFilterTypes = notes;
    this.notasListPrimary = notes;
    this.monthDiff(quots);
    this.getGoals(null, null);
    this.clean();
    this.spinnerService.hide();
  }

  clean() {
    this.registerForm.get("seller")?.setValue("");
    this.registerForm.get("typeForm")?.setValue("");
    this.id_type = null;
    this.id_seller = null;
  }

  data() {
    const id_user = this.rol === "1" || this.rol === "3" ? null : parseInt(this.user!);
    const dateRange = this.converseDateService.getDatesRange(this.date, 1);
    this.graphicQueriesService
    .getAllHomeInvoices(dateRange.reducedDate, dateRange.currentDate, id_user)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.quotaListPrimary = result.length === 0 ? this.analysisService.quotsNull() : result;
      this.quots = result.length === 0 ? this.analysisService.quotsNull() : result;
      this.quotaFilterTypes = result.length === 0 ? this.analysisService.quotsNull() : result;
      this.monthDiff(result.length === 0 ? this.analysisService.quotsNull() : result);
    })

    this.graphicQueriesService
    .getQuotToday(id_user)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.quotTodayPrimary = result.length === 0 ? this.analysisService.quotsNull() : result;
      this.quotToday = result.length === 0 ? this.analysisService.quotsNull() : result;
    });

    this.graphicQueriesService
    .getAllGraphicNotes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.notas = result.length === 0 ? this.analysisService.notesNull() : result;
      this.notasFilterTypes = result.length === 0 ? this.analysisService.notesNull() : result;
      this.notasListPrimary = result.length === 0 ? this.analysisService.notesNull() : result;
    });
  }

  dataFilters() {
    this.seller
    .listSellers()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.sellerList = result;
    });

    this.inventory
    .getPresentations()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.presentationTypes = result;
    });
  }

  getCustomerQuantities() {
    if (this.rol === "1" || this.rol === "3") {
      this.customer
      .countCustomerList(false)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.numCustomers = result;
      });
    } else {
      this.customer
      .countCustomerListByUser(parseInt(this.user!), false)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.numCustomers = result;
      });
    }
  }

  monthDiff(quotas: IQuota[]) {
    let arrayDates: string[] = [];
    quotas.forEach((data) => {
      if (data.estado === 4) {
        arrayDates.push(data.createdAt);
      }
    });
    const sortArray = arrayDates.sort();
    const months = this.calcDiff(new Date(sortArray[0]), this.date) + 1;
    this.months = months;
  }

  calcDiff(d1: any, d2: any) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  displayFnSeller(seller: any) {
    return seller && seller ? seller.nombre : undefined;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openDialogMails(registerMailsHome: boolean): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { registerMailsHome },
    });
  }
}
