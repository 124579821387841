<div class="header" *ngIf="!showDialog">
    <h3>{{'PLEASE SELECT WHAT YOU WANT TO IMPORT' | translate}}</h3>
</div>
<div class="header" *ngIf="showDialog">
    <h3>{{'FOLLOW THE INSTRUCTIONS' | translate}}</h3>
</div>
<div class="slider" *ngIf="(showDialog && (currentReport === 'Invoices' || currentReport === 'Purchases'))">
    <mat-slide-toggle
        class="example-margin"
        color="warn"
        [checked]="nitWithDigit"
        (change)="nitSlideChanged($event)"
        disabled="false">
        {{ "Enable if the NIT's contains the digit verification without hyphen" | translate }}
    </mat-slide-toggle>
</div>
<div class="slider alert alert-danger" *ngIf="currentReport === 'Invoices' && nitWithDigit && showDialog">
    {{'ALERT: IF YOU ENABLE THIS, THE SYSTEM WILL SEARCH FOR CUSTOMERS AND SELLERS ELIMINATING THE LAST NUMBER OF THE NIT.' | translate}}
</div>
<div class="padre">
    <div class="hijo">
        <table *ngIf="!showDialog" class="table table-bordered">
            <tbody>
                <tr *ngFor="let reports of reportList; let i = index">
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        {{reports | translate}}
                    </td>
                    <td>
                        <a class="goLink" (click)="select(reports)">
                            {{'Import' | translate}}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="showDialog">
            <h1 mat-dialog-title>{{ 'Upload File' | translate }}</h1>
            <div *ngIf="currentReport === 'Invoices'">
                {{ 'Please select a type' | translate }}<br>
                <mat-form-field>
                    <mat-label>{{'Type' | translate}}</mat-label>
                    <mat-select disableRipple [formControl]="typeForm">
                        <mat-option *ngFor="let type of typeList" [value]="type">
                            {{type | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <a class="guide-link" (click)="generateTxt()">{{ 'File guide' | translate }}</a><br>
            <ng-container *ngIf="currentReport === 'Prospects'">
                <a class="guide-link" (click)="exportList('Document Types')">{{'Document Types List' | translate}}</a><br>
                <a class="guide-link" (click)="exportList('Contributors')">{{'Contributors List' | translate}}</a><br>
                <a class="guide-link" (click)="exportList('Segments')">{{'Segments List' | translate}}</a><br>
                <a class="guide-link" (click)="exportList('Sellers')">{{'Sellers List' | translate}}</a><br>
            </ng-container>
            <div mat-dialog-content>
                {{ 'Please upload your file' | translate }}
                <form [formGroup]="uploadForm">
                    <input (change)="getFile($event)" type="file" id="inputFile" formControlName="excel"/> 
                    <button mat-icon-button (click)="reset(true, false)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </form>
                <div *ngIf="companyObject.countries[0].name === 'Colombia' && currentReport === 'Purchases'">
                    {{ 'Please select a customer city' | translate }}
                    <mat-form-field>
                        <mat-label>{{'City' | translate}}</mat-label>
                        <mat-select disableRipple [formControl]="citiesForm">
                            <mat-option *ngFor="let city of cities.data" [value]="city.attributes.name">
                                {{city.attributes.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="progress">
                    <mat-progress-bar
                        class="progress-bar"
                        [ngClass]="{'green-progress-bar': registerComplete}"
                        color="warn"
                        mode="determinate"
                        [value]="(currentItem / totalItems) * 100">
                    </mat-progress-bar>
                </div>
                <div>
                    <ng-container *ngIf="initImport">
                        <div class="complete-info">
                            <p class="text-complete">{{ currentItem }} {{'/'}} {{ totalItems }} {{ 'Registered items' | translate }}</p> 
                            <mat-icon *ngIf="registerComplete" class="complete-icon">done_all</mat-icon>
                        </div>
                        <mat-form-field class="textarea-field">
                            <mat-label>{{ 'Register Logs' | translate}}</mat-label> 
                            <textarea matInput
                                      readonly
                                      cdkTextareaAutosize
                                      #autosize="cdkTextareaAutosize"
                                      cdkAutosizeMinRows="1"
                                      cdkAutosizeMaxRows="5"
                                      [value]="registerLogs.join('\n')">
                            </textarea>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!initImport">
                        <p>{{ totalItems }} {{ 'Records found' | translate }}</p>
                    </ng-container>
                </div>
            </div>
            <div class="buttons-div" mat-dialog-actions>
                <button class="back-button" (click)="reset(true, true)" mat-raised-button>{{ 'Back' | translate }}</button>
                <button class="back-button" mat-raised-button (click)="exportExample()">{{ 'Download Example' | translate }}</button>
                <button mat-raised-button (click)="whichArrayToConstruct(jsonData); initImport = true">{{ 'Next' | translate }}</button>
            </div>
        </div>
    </div>
</div> 

<table class="table table-striped" id="example-import-invoices" hidden>
    <thead>
        <tr>
            <th>{{'ID INVOICE' | translate}}</th>
            <th>{{'TAX INCLUDED' | translate}}</th>
            <th>{{'TAX %' | translate}}</th>
            <th>{{'NIT/NAME CUSTOMER' | translate}}</th>
            <th>{{'NIT SELLER' | translate}}</th>
            <th>{{'ID WAREHOUSE' | translate}}</th>
            <th>{{'CREATED DATE' | translate}}</th>
            <th>{{'DUE DATE' | translate}}</th>
            <th>{{'ARTICLE CODE' | translate}}</th>
            <th>{{'QUANTITY' | translate}}</th>
            <th>{{'PRICE' | translate}}</th>
            <th>{{'DISCOUNT' | translate}}</th>
            <th>{{'SUBTOTAL' | translate}}</th>
            <th>{{'TOTAL' | translate}}</th>
            <th>{{'OBSERVATIONS' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{{1}}</td>
            <td>{{1}}</td>
            <td>{{0}}</td>
            <td>{{'12345789-0'}}</td>
            <td>{{'99999999-8'}}</td>
            <td>{{1}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'EXAMPLE-CODE-1'}}</td>
            <td>{{10}}</td>
            <td>{{100}}</td>
            <td>{{0}}</td>
            <td>{{1160}}</td>
            <td>{{1755}}</td>
            <td>{{'N/A'}}</td>
        </tr>
        <tr>
            <td>{{1}}</td>
            <td>{{1}}</td>
            <td>{{0}}</td>
            <td>{{'12345789-0'}}</td>
            <td>{{'99999999-8'}}</td>
            <td>{{1}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'EXAMPLE-CODE-2'}}</td>
            <td>{{5}}</td>
            <td>{{100}}</td>
            <td>{{0}}</td>
            <td>{{595}}</td>
            <td>{{1755}}</td>
            <td>{{'N/A'}}</td>
        </tr>
        <tr>
            <td>{{2}}</td>
            <td>{{2}}</td>
            <td>{{19}}</td>
            <td>{{'98765432-1'}}</td>
            <td>{{'88888888-9'}}</td>
            <td>{{1}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'EXAMPLE-CODE-3'}}</td>
            <td>{{8}}</td>
            <td>{{70}}</td>
            <td>{{0}}</td>
            <td>{{666.4}}</td>
            <td>{{1094.8}}</td>
            <td>{{'EXAMPLE OBSERVATION'}}</td>
        </tr>
        <tr>
            <td>{{2}}</td>
            <td>{{2}}</td>
            <td>{{19}}</td>
            <td>{{'98765432-1'}}</td>
            <td>{{'88888888-9'}}</td>
            <td>{{1}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'EXAMPLE-CODE-4'}}</td>
            <td>{{4}}</td>
            <td>{{90}}</td>
            <td>{{0}}</td>
            <td>{{428.4}}</td>
            <td>{{1094.8}}</td>
            <td>{{'EXAMPLE OBSERVATION'}}</td>
        </tr>
    </tbody>
</table>

<table class="table table-striped" id="example-import-collections" hidden>
    <thead>
        <tr>
            <th>{{'NUMBER' | translate}}</th>
            <th>{{'INVOICE' | translate}}</th>
            <th>{{'YEAR' | translate}}</th>
            <th>{{'TYPE CASHIER OR BANK' | translate}}</th>
            <th>{{'ID CASHIER OR BANK' | translate}}</th>
            <th>{{'PAYMENT TYPE' | translate}}</th>
            <th>{{'DATE' | translate}}</th>
            <th>{{'VALUE' | translate}}</th>
            <th>{{'REFERENCE' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{{1}}</td>
            <td>{{1}}</td>
            <td>{{2023}}</td>
            <td>{{1}}</td>
            <td>{{19}}</td>
            <td>{{'Credit' | translate}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'1160'}}</td>
            <td>{{'EXAMPLE REFERENCE'}}</td>
        </tr>
        <tr>
            <td>{{2}}</td>
            <td>{{1}}</td>
            <td>{{2023}}</td>
            <td>{{1}}</td>
            <td>{{19}}</td>
            <td>{{'Cancellation' | translate}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'595'}}</td>
            <td>{{'EXAMPLE REFERENCE'}}</td>
        </tr>
        <tr>
            <td>{{3}}</td>
            <td>{{'I-100-01'}}</td>
            <td>{{2023}}</td>
            <td>{{2}}</td>
            <td>{{18}}</td>
            <td>{{'Credit' | translate}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'666.4'}}</td>
            <td>{{'EXAMPLE REFERENCE'}}</td>
        </tr>
        <tr>
            <td>{{4}}</td>
            <td>{{'I-100-01'}}</td>
            <td>{{2023}}</td>
            <td>{{2}}</td>
            <td>{{18}}</td>
            <td>{{'Cancellation' | translate}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'428.4'}}</td>
            <td>{{'EXAMPLE REFERENCE'}}</td>
        </tr>
    </tbody>
</table>

<table class="table table-striped" id="example-import-notes" hidden>
    <thead>
        <tr>
            <th>{{'NUMBER' | translate}}</th>
            <th>{{'INVOICE ID' | translate}}</th>
            <th>{{'INVOICE YEAR' | translate}}</th>
            <th>{{'NOTE TYPE' | translate}}</th>
            <th>{{'CONCEPT' | translate}}</th>
            <th>{{'DATE' | translate}}</th>
            <th>{{'DUE DATE' | translate}}</th>
            <th>{{'ID WAREHOUSE' | translate}}</th>
            <th>{{'ARTICLE CODE' | translate}}</th>
            <th>{{'QUANTITY' | translate}}</th>
            <th>{{'PRICE' | translate}}</th>
            <th>{{'DISCOUNT' | translate}}</th>
            <th>{{'SUBTOTAL' | translate}}</th>
            <th>{{'TOTAL' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{{1}}</td>
            <td>{{250}}</td>
            <td>{{2023}}</td>
            <td>{{'Credito' | translate}}</td>
            <td>{{'Emission by error' | translate}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{1}}</td>
            <td>{{'KN62826'}}</td>
            <td>{{10}}</td>
            <td>{{200}}</td>
            <td>{{0}}</td>
            <td>{{2000}}</td>
            <td>{{132005}}</td>
        </tr>
        <tr>
            <td>{{1}}</td>
            <td>{{250}}</td>
            <td>{{2023}}</td>
            <td>{{'Credito' | translate}}</td>
            <td>{{'Emission by error' | translate}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{1}}</td>
            <td>{{'58SYN'}}</td>
            <td>{{25}}</td>
            <td>{{5200.20}}</td>
            <td>{{0}}</td>
            <td>{{130005}}</td>
            <td>{{132005}}</td>
        </tr>
        <tr>
            <td>{{2}}</td>
            <td>{{'I-100-01'}}</td>
            <td>{{2023}}</td>
            <td>{{'Debit' | translate}}</td>
            <td>{{'Correction of quantities' | translate}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{1}}</td>
            <td>{{'KN52104 GAL'}}</td>
            <td>{{15}}</td>
            <td>{{30000}}</td>
            <td>{{0}}</td>
            <td>{{450000}}</td>
            <td>{{540018}}</td>
        </tr>
        <tr>
            <td>{{2}}</td>
            <td>{{'I-100-01'}}</td>
            <td>{{2023}}</td>
            <td>{{'Debit' | translate}}</td>
            <td>{{'Correction of quantities' | translate}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{1}}</td>
            <td>{{'AL4997'}}</td>
            <td>{{60}}</td>
            <td>{{1500.3}}</td>
            <td>{{0}}</td>
            <td>{{90018}}</td>
            <td>{{540018}}</td>
        </tr>
    </tbody>
</table>

<table class="table table-striped" id="example-import-purchases" hidden>
    <thead>
        <tr>
            <th>{{'NUMBER' | translate}}</th>
            <th>{{'ID PURCHASE' | translate}}</th>
            <th>{{'PROVIDER NIT' | translate}}</th>
            <th>{{'DATE' | translate}}</th>
            <th>{{'DUE DATE' | translate}}</th>
            <th>{{'TAX %' | translate}}</th>
            <th>{{'ID WAREHOUSE' | translate}}</th>
            <th>{{'ARTICLE CODE' | translate}}</th>
            <th>{{'QUANTITY' | translate}}</th>
            <th>{{'PRICE' | translate}}</th>
            <th>{{'DISCOUNT' | translate}}</th>
            <th>{{'SUBTOTAL' | translate}}</th>
            <th>{{'WITHHOLDING TAX VALUE' | translate}}</th>
            <th>{{'ICA TAX VALUE' | translate}}</th>
            <th>{{'TOTAL' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{{1}}</td>
            <td>{{'COMPRA9952'}}</td>
            <td>{{'6520120354'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'19'}}</td>
            <td>{{1}}</td>
            <td>{{'EXAMPLE-CODE-1'}}</td>
            <td>{{10}}</td>
            <td>{{100}}</td>
            <td>{{0}}</td>
            <td>{{1160}}</td>
            <td>{{0}}</td>
            <td>{{0}}</td>
            <td>{{1785}}</td>
        </tr>
        <tr>
            <td>{{1}}</td>
            <td>{{'COMPRA9952'}}</td>
            <td>{{'6520120354'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'19'}}</td>
            <td>{{1}}</td>
            <td>{{'EXAMPLE-CODE-2'}}</td>
            <td>{{5}}</td>
            <td>{{100}}</td>
            <td>{{0}}</td>
            <td>{{595}}</td>
            <td>{{0}}</td>
            <td>{{0}}</td>
            <td>{{1785}}</td>
        </tr>
        <tr>
            <td>{{2}}</td>
            <td>{{'COMPRA5422'}}</td>
            <td>{{'9658326999'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'19'}}</td>
            <td>{{1}}</td>
            <td>{{'EXAMPLE-CODE-3'}}</td>
            <td>{{8}}</td>
            <td>{{70}}</td>
            <td>{{0}}</td>
            <td>{{666.4}}</td>
            <td>{{0}}</td>
            <td>{{0}}</td>
            <td>{{1094.8}}</td>
        </tr>
        <tr>
            <td>{{2}}</td>
            <td>{{'COMPRA5422'}}</td>
            <td>{{'9658326999'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'10/15/2023'}}</td>
            <td>{{'19'}}</td>
            <td>{{1}}</td>
            <td>{{'EXAMPLE-CODE-4'}}</td>
            <td>{{4}}</td>
            <td>{{90}}</td>
            <td>{{0}}</td>
            <td>{{428.4}}</td>
            <td>{{0}}</td>
            <td>{{0}}</td>
            <td>{{1094.8}}</td>
        </tr>
    </tbody>
</table>

<table class="table table-striped" id="example-import-prospects" hidden>
    <thead>
        <tr>
            <th>{{'NUMBER' | translate}}</th>
            <th>{{'DOCUMENT TYPE' | translate}}</th>
            <th>{{'NIT' | translate}}</th>
            <th>{{'VERIFICATION DIGIT' | translate}}</th>
            <th>{{'NAME' | translate}}</th>
            <th>{{'ADDRESS' | translate}}</th>
            <th>{{'PHONE' | translate}}</th>
            <th>{{'EMAIL' | translate}}</th>
            <th>{{'COUNTRY' | translate}}</th>
            <th>{{'CITY' | translate}}</th>
            <th>{{'COORDINATES' | translate}}</th>
            <th>{{'CONTRIBUTOR ID' | translate}}</th>
            <th>{{'SELLER ID' | translate}}</th>
            <th>{{'SEGMENT ID' | translate}}</th>
            <th>{{'OBSERVATIONS' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{{'OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
            <td>{{'OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
            <td>{{'OBLIGATORY' | translate}}</td>
            <td>{{'OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
            <td>{{'OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
            <td>{{'NOT OBLIGATORY' | translate}}</td>
        </tr>
        <tr>
            <td>{{1}}</td>
            <td>{{'null' | translate}}</td>
            <td>{{'null' | translate}}</td>
            <td>{{'null' | translate}}</td>
            <td>{{'Juan Carlos Rodriguez' | translate}}</td>
            <td>{{'null' | translate}}</td>
            <td>{{'null' | translate}}</td>
            <td>{{'null' | translate}}</td>
            <td>{{'Colombia' | translate}}</td>
            <td>{{'Bogotá, D.C.' | translate}}</td>
            <td>{{'null' | translate}}</td>
            <td>{{'null' | translate}}</td>
            <td>{{1}}</td>
            <td>{{'null' | translate}}</td>
            <td>{{'null' | translate}}</td>
        </tr>      
    </tbody>
</table>

<table class="table table-striped" id="document-type-list" hidden>
    <thead>
        <tr>
            <th>{{'ID' | translate}}</th>
            <th>{{'DOCUMENT DESCRIPTION' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let listDocumenType of listDocumenTypes">
            <td>{{listDocumenType.id_docs}}</td>
            <td>{{listDocumenType.description}}</td>
        </tr>    
    </tbody>
</table>

<table class="table table-striped" id="contributors-list" hidden>
    <thead>
        <tr>
            <th>{{'ID' | translate}}</th>
            <th>{{'CONTRIBUTOR DESCRIPTION' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let listContributorType of listContributorTypes">
            <td>{{listContributorType.id_contributor}}</td>
            <td>{{listContributorType.description}}</td>
        </tr>    
    </tbody>
</table>

<table class="table table-striped" id="segments-list" hidden>
    <thead>
        <tr>
            <th>{{'ID' | translate}}</th>
            <th>{{'SEGMENT DESCRIPTION' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let listCustomerSegment of listCustomerSegments">
            <td>{{listCustomerSegment.id_segment}}</td>
            <td>{{listCustomerSegment.nombre}}</td>
        </tr>    
    </tbody>
</table>

<table class="table table-striped" id="sellers-list" hidden>
    <thead>
        <tr>
            <th>{{'ID' | translate}}</th>
            <th>{{'SELLER DESCRIPTION' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let listSeller of listSellers">
            <td>{{listSeller.id_seller}}</td>
            <td>{{listSeller.seller.nombre}}</td>
        </tr>    
    </tbody>
</table>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
