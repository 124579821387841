<mat-dialog-content>
    <h2><strong>{{'Opening' | translate}} </strong></h2>
    <form [formGroup]="openingForm">
        <div>
            <span><strong>{{'Quantity Initial' | translate}}:</strong></span>
            <input class="form-control" placeholder="0" type="number" formControlName="quantity">
            <span class="form-text text-danger" *ngIf="textValid('quantity')">{{'You must type a correct quantity' | translate}}</span>
        </div>
        <div>
            <span><strong>{{'Value Initial' | translate}}:</strong></span>
            <input class="form-control" placeholder="0" type="number" formControlName="value">
            <span class="form-text text-danger" *ngIf="textValid('value')">{{'You must type a correct value' | translate}}</span>
        </div>
    </form>
    <div>
        <button class="buttons" mat-raised-button (click)="update()">{{'Update' | translate}}</button>
        <button class="buttons close-button" mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
    </div>
</mat-dialog-content>
