<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/21.2.5/css/dx.common.css" />
<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/21.2.5/css/dx.dark.css" /> 

<div fxLayoutAlign="center center" *ngIf="setCompleted >= setMeta" style="text-align: center">
  <span class="word-green">{{'Objective completed' | translate}}!.</span>
</div>
<div *ngIf="setCompleted < setMeta" fxLayoutAlign="center center" style="text-align: center">
  <span class="word-grey">{{'Sales Target' | translate}}.</span>
</div>

<div>
  <dx-linear-gauge id="linearGauge" [value]="setCompleted" [subvalues]="[setCompleted]" #linearGauge>
    <dxo-scale [startValue]="0" [endValue]="setMeta" [tickInterval]="setMeta">
    </dxo-scale>
    <dxo-range-container>
      <dxi-range [startValue]="0" [endValue]="firstPercent" color="#E31818"></dxi-range>
      <dxi-range [startValue]="firstPercent" [endValue]="secondPercent" color="#EAF119"></dxi-range>
      <dxi-range [startValue]="secondPercent" [endValue]="setMeta" color="#2DF119"></dxi-range>
    </dxo-range-container>
  
    <ng-container *ngIf="setCompleted >= 0 && setCompleted <= firstPercent">
      <dxo-value-indicator color="#E31818"></dxo-value-indicator>
    </ng-container>
  
    <ng-container *ngIf="setCompleted >= firstPercent && setCompleted <= secondPercent">
      <dxo-value-indicator color="#EAF119"></dxo-value-indicator>
    </ng-container>
  
    <ng-container *ngIf="setCompleted >= secondPercent">
      <dxo-value-indicator color="#2DF119"></dxo-value-indicator>
    </ng-container>
  
    <dxo-subvalue-indicator type="textCloud" color="#06244F">
      <dxo-text [customizeText]="customizeText">
        <dxo-format type="largeNumber" [precision]="1"></dxo-format>
      </dxo-text>
    </dxo-subvalue-indicator>
    <dxo-size [height]="60"></dxo-size>
  </dx-linear-gauge>
</div>

<div style="margin-bottom: 10px;">
  <dx-linear-gauge id="linearGauge2" [value]="setProjection" #linearGauge2>
    <dxo-value-indicator type="rhombus" color="#06244F"> </dxo-value-indicator>
    <dxo-range-container [offset]="10">
      <dxi-range [startValue]="0" [endValue]="setProjection" color="#2DF119"></dxi-range>
    </dxo-range-container>
    <dxo-scale [startValue]="0" [endValue]="setProjection" [tickInterval]="setProjection">
      <dxo-label [customizeText]="customizeText"></dxo-label>
    </dxo-scale>
    <dxo-size [height]="40"></dxo-size>
  </dx-linear-gauge>

  <div fxLayoutAlign="center center" style="text-align: center">
    <span class="word-grey">{{'Projection' | translate}}: {{setProjection}}</span>
  </div>
</div>

<table class="table">
  <tbody>
    <tr>
      <td class="word-grey">{{'Goal' | translate}}: {{setMeta}} {{typePres}}</td>
      <td class="word-grey">{{'Month Today' | translate}}: {{setCompleted}} {{typePres}}</td>
    </tr>
    <tr>
      <td class="word-grey">{{'Today' | translate}}: {{setToday}} {{typePres}}</td>
      <td class="word-grey">{{'Past Month' | translate}}: {{setPastMonth}} {{typePres}}</td>
    </tr>
  </tbody>
</table>
    

