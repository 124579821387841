<mat-dialog-content>
    <div class="row">
        <form [formGroup]="currencyForm">
            <div class="col">
                <select class="form-control" formControlName="currency">
                    <option selected value="">{{'Select currency' | translate}}</option>
                    <option *ngFor="let currencies of currencyList.data" [ngValue]="currencies.attributes.ccy">{{currencies.attributes.ccy}} - {{currencies.attributes.ccyNm}}</option>
                </select>
            </div>
        </form>
    </div>
    <div class="row">
        <div class="col" id="buttons">
            <button mat-raised-button [mat-dialog-close]="currencyForm.controls['currency'].value">{{'Save' | translate}}</button>
        </div>
        <div class="col" id="buttons">
            <button mat-raised-button (click)="closeDialog()">{{'Cancel' | translate}}</button>
        </div>
    </div>
</mat-dialog-content>
