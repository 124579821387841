<mat-dialog-content>
<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/21.2.5/css/dx.common.css" />
<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/21.2.5/css/dx.light.css" />
<script>window.jQuery || document.write(decodeURIComponent('%3Cscript src="js/jquery.min.js"%3E%3C/script%3E'))</script>

<div class="row" style="margin-bottom: 20px;">
  <div class="col">
    <button style="float: left; margin-right: 20px;" mat-raised-button #tooltip="matTooltip" 
      matTooltip="{{'Use your current location or look up coordinates in the adjacent field like this' | translate}}:
                  {{'Latitude, longitude' | translate}}
                  {{'Example: 4.5981, -74.0799' | translate}}" 
      matTooltipPosition="right" aria-tooltip="Button that displays and hides a tooltip triggered by other buttons"> {{'Help' | translate}} </button>
  </div>
  <div class="col">
    <button style="float: right;" mat-raised-button [mat-dialog-close]="coords">X</button>
  </div>
</div>

<div class="row">
  <div class="col" style="min-width: 100%;">
    <form class="example-form">
      <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{'Search Coordinates (Latitude, longitude)' | translate}}</mat-label>
            <input placeholder="Type Coordinates" matInput [ngModel]="adress" (ngModelChange)="adress = $event">
      </mat-form-field>
    </form>
  </div>
  <div class="col">
    <button style="margin-right: 20px; margin-bottom: 20px;" (click)="search()" mat-raised-button>OK</button>
  </div>
  <div class="col">
    <span></span>
  </div>
</div>

<div class="row" style="margin-bottom: 20px">
  <div class="col-sm">
    <dx-map
    provider="bing"
    [height]="340"
    width="100%"
    [controls]="true"
    [markerIconSrc]="markerUrl"
    [markers]="markers"
    [controls]="true"
    >
    </dx-map>
  </div>
</div>

<div class="options">
<button style="margin-right: 20px; margin-bottom: 20px;" (click)="getCoords(true, false)" mat-raised-button>{{'Use Current Location' | translate}}</button>
<button style="margin-right: 20px; margin-bottom: 20px;" (click)="getCoords(false, true)" mat-raised-button>{{'Use Custom Location' | translate}}</button>
</div>
</mat-dialog-content>
