import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ICountry } from "src/app/@interfaces/country.interface";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { IDocumenType } from "src/app/@interfaces/authentication/documenType";
import { NgxSpinnerService } from "ngx-spinner";
import { SellersService } from "../../../../../@pages/sellers/sellers.service";
import { CustomerService } from "../../../../../@pages/customer/customer.service";
import { ISeller } from "../../../../../@interfaces/seller.interface";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UsersService } from "src/app/@pages/users/users.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-modify-seller",
  templateUrl: "./modify-seller.component.html",
  styleUrls: ["./modify-seller.component.css"],
})
export class ModifySellerComponent implements OnInit, OnDestroy {
  @Input() id_seller: number = 0;
  countryList: Array<ICountry> = [];
  documenTypeList: Array<IDocumenType> = [];
  sellerModify = {} as ISeller;
  sellerForm: UntypedFormGroup = this.fb.group({
    doc_nit: ['', [Validators.required, Validators.pattern(this.validatorS.documentPattern)]],
    types_docs: ['', [Validators.required]],
    name: ['', [Validators.required, Validators.maxLength(60)]],
    email: ['', [Validators.required, Validators.pattern(this.validatorS.emailPattern), Validators.maxLength(50)]],
    country: ['', Validators.required],
  });
  unsubscribe$ = new Subject();

  companyId = '';
  constructor(
    private translate: TranslateService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private seller: SellersService,
    private customer: CustomerService,
    private userService: UsersService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.seller
    .getOneSeller(this.id_seller)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.sellerModify = result;
      this.setSeller(this.sellerModify);
    });
    this.customer
    .listCountries()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.countryList = result;
    });
    this.userService
    .listDocumenTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.documenTypeList = result;
    });
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  setSeller(sellerSets: ISeller) {
    this.sellerForm.patchValue({
      doc_nit: sellerSets.doc_nit,
      types_docs: sellerSets.types_docs![0],
      name: sellerSets.nombre,
      email: sellerSets.email,
      country: sellerSets.country![0],
    });
  }

  update() {
    this.sellerForm.markAllAsTouched();
    if (this.sellerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant(
          "Please, fill in all the required fields!"
        ),
      });
      return;
    }

    this.seller
    .updateSellers(
      this.id_seller,
      this.sellerForm.value.doc_nit,
      parseInt(this.sellerForm.value.types_docs.id_docs),
      this.sellerForm.value.name,
      this.sellerForm.value.email,
      parseInt(this.sellerForm.value.country.id_country)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.nombre !== "sellerExist") {
        Swal.fire(
          this.translate.instant("success"),
          this.translate.instant("Seller updated successfully"),
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            this.router
            .navigateByUrl("/table", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/sellers"]);
            });
          }
        });
      } else if (result.nombre === "sellerExist") {
        Swal.fire(
          this.translate.instant("Seller was not updated"),
          this.translate.instant(
            "This document seller already exist registered"
          ),
          "warning"
        );
      } else {
        Swal.fire(
          "error",
          this.translate.instant("Something was wrong"),
          "error"
        );
      }
    });
  }

  textValid(text: string) {
    return (
      this.sellerForm.get(text)?.invalid && this.sellerForm.get(text)?.touched
    );
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  compareDocType(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_docs === item2.id_docs;
  }

  compareCountry(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_country === item2.id_country;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
