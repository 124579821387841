
<mat-tab-group #tabs mat-align-tabs="center" dynamicHeight="true" (selectedTabChange)="selectTab($event)">

    <mat-tab label="{{'Pending' | translate}}">
      <div class="row">
        <div class="col" style="margin-left: 20px;">
          <button [disabled]="disableButton" mat-raised-button [matMenuTriggerFor]="menu">{{'Config' | translate}}  <mat-icon class="black-icon">settings</mat-icon></button>
            <mat-menu #menu="matMenu">
              <a (click)="openDialogMails(true, listMails, 'Portfolio')"><button mat-menu-item class="Warn">{{'Mails List' | translate}}</button></a>
              <a (click)="openDialogBanks(true, listBanks,'Portfolio')"><button mat-menu-item class="Warn">{{'Bank List' | translate}}</button></a>
              <a (click)="openDialogCashiers(true, listCashiers,'Portfolio')"><button mat-menu-item class="Warn">{{'Cashiers List' | translate}}</button></a>
            </mat-menu>
        </div>
        <div class="col-6">
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-paginator [length]="lengthBy10"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent = $event; capturePage($event, 10)"
            aria-label="Select page">
            </mat-paginator>
          </div>
        </div>
        <div class="col" style="text-align: right; margin-right: 20px;">
          <app-search (search)="handleSearch($event, 10)"></app-search>
        </div>
      </div>

      <div class="row"> 
        <div *ngFor="let quotation of QuotationListByStatus10" class="col-lg-3">
          <app-card [cardType]="2" [quotation]='quotation'></app-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{'Cancelled Admon' | translate}}">
      <div class="row">
        <div class="col" style="margin-left: 20px;">
          <button [disabled]="disableButton" mat-raised-button [matMenuTriggerFor]="menu">{{'Config' | translate}}  <mat-icon class="black-icon">settings</mat-icon></button>
            <mat-menu #menu="matMenu">
              <a (click)="openDialogMails(true, listMails,'Portfolio')"><button mat-menu-item class="Warn">{{'Mails List' | translate}}</button></a>
              <a (click)="openDialogBanks(true, listBanks,'Portfolio')"><button mat-menu-item class="Warn">{{'Bank List' | translate}}</button></a>
              <a (click)="openDialogCashiers(true, listCashiers,'Portfolio')"><button mat-menu-item class="Warn">{{'Cashiers List' | translate}}</button></a>
            </mat-menu>
        </div>
        <div class="col-6">
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-paginator [length]="lengthBy30"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent = $event; capturePage($event, 30)"
            aria-label="Select page">
            </mat-paginator>
          </div>
        </div>
        <div class="col" style="text-align: right; margin-right: 20px;">
          <app-search (search)="handleSearch($event, 30)"></app-search>
        </div>
      </div>

      <div class="row"> 
        <div *ngFor="let quotation of QuotationListByStatus30" class="col-lg-3"> 
          <app-card [cardType]="2" [quotation]='quotation'></app-card>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>

  <ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
  </ngx-spinner>
              