<div class="register-container">
    <a><button mat-raised-button class="Warn" (click)="openDialog()">{{'Register' | translate}}</button></a>
</div>

<div class="buttons-container">
    <button [disabled]="disableButton" mat-raised-button [matMenuTriggerFor]="menu">{{'Details' | translate}}  
        <mat-icon class="black-icon">add_circle</mat-icon>
    </button>
      <mat-menu #menu="matMenu">
        <a (click)="openDialogGroups(true, groupsList)"><button mat-menu-item class="Warn">{{'Groups' | translate}}</button></a>
        <a (click)="openDialogPresen(true, presentationList)"><button mat-menu-item class="Warn">{{'Presentations' | translate}}</button></a>
        <a (click)="openDialogWare(true, warehouseList)"><button mat-menu-item class="Warn">{{'Warehouses' | translate}}</button></a>
      </mat-menu>
</div>

<div *ngIf="inventoryList.length > 0">
    <app-table [inventory]="inventoryList" [showTable]="3" [length]="length" [orderField]="orderField" [orderType]="orderType"></app-table>
</div>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>