import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Component, Input, OnInit } from "@angular/core";
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.css"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class StepperComponent implements OnInit {
  @Input() status: Number | undefined;
  @Input() typeStepper: Number | undefined;
  @Input() isProspective: boolean = false;
  isLinear = false;
  companyId = '';
  constructor(private cookieAuthService: CookieAuthService) {}

  ngOnInit(): void {
    this.getAuthValues();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }
}
