import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { IRefunds } from "src/app/@interfaces/refunds.interface";
import { PurchasesService } from '../../../../@pages/purchases/purchases.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { AlertsService } from "../../services/alerts/alerts.service";

@Injectable({
  providedIn: "root",
})
export class RefundsService {
  unsubscribe$ = new Subject();

  constructor(
    private spinnerService: NgxSpinnerService,
    private purchasesService: PurchasesService,
    private alertsService: AlertsService,
    private cookieAuthService: CookieAuthService
  ) 
  {}

  createRefund(id_purchase: number, refundForm: UntypedFormGroup, rowsArr: UntypedFormArray, totalPurchase: number, totalWithholding: number, totalIcaTax: number) {
    let refund = {} as IRefunds;
    if (this.validForm(refundForm)) {
        refund = this.setRefundValues(rowsArr)
        refund.num_invo = id_purchase;
        refund.refund_type = "Refund";
        refund.concept = refundForm.value.concept;
        refund.createAt = refundForm.value.date;
        refund.dueDate = refundForm.value.dueDate;
        refund.total = totalPurchase;
        refund.withholdingTax = totalWithholding;
        refund.icaTax = totalIcaTax;
        this.spinnerService.hide()
        this.saveOnDataBase(refund);
    }
  }

  validForm(refundForm: UntypedFormGroup) {
    if (refundForm.invalid === true) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!")
      return false;
    }else {
      return true;
    }
  }

  validFormArray(rowsArr: UntypedFormArray, index: number) {
    if (parseFloat(rowsArr.value[index].subtotal.toFixed(2)) > parseFloat(rowsArr.value[index].subtotal2.toFixed(2))) {
      this.alertsService.showErrorAlert("The subtotal cannot be higher than the current one.!")
      return false;
    }
    else if (rowsArr.value[index].quantity > rowsArr.value[index].quantity2) {
      this.alertsService.showErrorAlert("If you wish to add more products, please create a new invoice.!");
      return false;
    } else {
      return true;
    }
  }

  setRefundValues(rowsArr: UntypedFormArray) {
    let refund = {} as IRefunds;
    refund.refunds_values = [{
      id_warehouse: [],
      id_article: [],
      code: [],
      quantity: [],
      price: [],
      subtotal: [],
      discount: [],
    }]
    for (let index = 0; index < rowsArr.length; index++) {
      if (this.validFormArray(rowsArr, index)) {
        if (parseFloat(rowsArr.value[index].subtotalNegative) < 0) {
          refund.refunds_values[0].id_warehouse.push(
            parseInt(rowsArr.value[index].warehouse.id_almacen)
          );
          refund.refunds_values[0].id_article.push(
            parseInt(rowsArr.value[index].article.id_articulo)
          );
          refund.refunds_values[0].code.push(
            rowsArr.value[index].article.codigo
          );
          if (Math.sign(parseFloat(rowsArr.value[index].quantityNegative)) === -1) {
            refund.refunds_values[0].quantity.push(
              parseFloat(rowsArr.value[index].quantityNegative)
            );
          } else if (Math.sign(parseFloat(rowsArr.value[index].quantityNegative)) === 1) {
            refund.refunds_values[0].quantity.push(
              parseFloat(rowsArr.value[index].quantityNegative) * -1
            );
          }
          refund.refunds_values[0].price.push(
            parseFloat(rowsArr.value[index].priceNegative)
          );
          refund.refunds_values[0].subtotal.push(
            parseFloat(rowsArr.value[index].subtotalNegative)
          );
          refund.refunds_values[0].discount.push(
            parseFloat(rowsArr.value[index].discount)
          );
        }
      }
    }
    return refund;
  }

  async saveOnDataBase(refund: IRefunds) {
    const user = this.cookieAuthService.getUserId!;
    this.spinnerService.show();
    this.purchasesService
    .createRefund(
      refund.num_invo,
      refund.refunds_values[0].id_warehouse,
      refund.refunds_values[0].id_article,
      refund.refunds_values[0].code,
      refund.refunds_values[0].quantity,
      refund.refund_type,
      refund.concept,
      refund.refunds_values[0].price,
      refund.refunds_values[0].subtotal,
      refund.refunds_values[0].discount,
      refund.total,
      refund.withholdingTax,
      refund.icaTax,
      refund.createAt,
      refund.dueDate,
      parseInt(user)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        this.spinnerService.hide();
        this.alertsService.showSuccessfullAlert("Refund has been created successfully")
        .then((res) => {
          if (res.isConfirmed) {
            this.alertsService.refresh("/card", "/purchases")
          }
        });
      } else {
        this.spinnerService.hide();
        this.alertsService.showErrorAlert("Something was wrong")
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
