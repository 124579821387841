import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class ValidatorsService {
  public nombrePattern: string = '([a-zA-Z]+) ([a-zA-Z]+)';
  public emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
  public documentPattern: string = '^[0-9]+';
  public quantityPattern: string = '[+-]?([0-9]*[.])?[0-9]+';
  public blanks: string = '/^\S*$/';
  public decimals: string = '/^\d+(\.\d{1,2})?$/';
  //uppercase letters, lowercase letters, numbers, special character ($@$!%*?&+#)
  public passwordPattern: string =
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&+#])[A-Za-zd$@$!%*?&+#].{8,}";
  constructor() {}

  equalFields(text1: string, text2: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const pass1 = formGroup.get(text1)?.value;
      const pass2 = formGroup.get(text2)?.value;
      if (pass1 !== pass2) {
        formGroup.get(text2)?.setErrors({ unqual: true });
        return { unqual: true };
      } else {
        formGroup.get(text2)?.setErrors(null);
      }
      return null;
    };
  }
}
