import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { BillyCurrencies } from "src/app/@interfaces/Billy/billy.interface";
import { BillyService } from "src/app/@pages/billyService/billy.service";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-select-currency",
  templateUrl: "./select-currency.component.html",
  styleUrls: ["./select-currency.component.css"],
})
export class SelectCurrencyComponent implements OnInit {
  @Input() currency: boolean = false;
  currencyList = {} as BillyCurrencies;
  currencyForm: UntypedFormGroup = this.fb.group({
    currency: [""],
  });
  unsubscribe$ = new Subject();
  constructor(
    private billy: BillyService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.billy
    .getCurrencies()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.currencyList = result;
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
