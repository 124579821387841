import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportFilesComponent } from './import-files.component';
import { ImportFilesRoutingModule } from './import-files-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ServicesModule } from '../../@shared/components/services/services.module';

@NgModule({
  declarations: [ImportFilesComponent],
  imports: [
    CommonModule,
    ImportFilesRoutingModule,
    MaterialModule,
    MatIconModule,
    DialogModule,
    TranslateModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ServicesModule
  ],
})
export class ImportFilesModule { }
