import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { IPresentationTypes } from "../../../../../@interfaces/presentationTypes";
import { InventoryService } from "../../../../../@pages/inventory/inventory.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-crud-presentations",
  templateUrl: "./crud-presentations.component.html",
  styleUrls: ["./crud-presentations.component.css"],
})
export class CrudPresentationsComponent implements OnInit, OnDestroy {
  registerForm: UntypedFormGroup = this.fb.group({
    presentation: ["", [Validators.required]],
  });

  @Input() listPresentations: Array<IPresentationTypes> = [];
  unsubscribe$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private inventory: InventoryService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.inventory
    .getPresentations()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.listPresentations = result;
    });
  }

  remove(id: number, index: number) {
    Swal.fire({
      title: this.translate.instant("Are you sure?"),
      text: this.translate.instant("You won not be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5400",
      cancelButtonColor: "rgb(0, 0, 0)",
      confirmButtonText: "Confirm",
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.inventory
        .removePresentation(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (!result) {
            Swal.fire(
              "Error",
              this.translate.instant("Something was wrong"),
              "error"
            );
          } else {
            Swal.fire(
              this.translate.instant("Good"),
              this.translate.instant("Presentation removed"),
              "success"
            ).then((data) => {
              if (data.isConfirmed) {
                this.router
                .navigateByUrl("/dialog", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate(["/inventory"]);
                });
                this.inventory
                .getPresentations()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((result) => {
                  this.listPresentations = result;
                });
              }
            });
          }
        });
      }
    });
  }

  update() {
    if (this.registerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Presentation Invalid, Please Check!"),
      });
      return;
    }
    this.inventory
    .createPresentations(this.registerForm.get("presentation")?.value)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        Swal.fire({
          title: this.translate.instant("Created"),
          text: this.translate.instant("Presentation created successfully!"),
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#f44336",
          confirmButtonText: "Ok",
        }).then((data) => {
          if (data.isConfirmed) {
            this.router
            .navigateByUrl("/dialog", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/inventory"]);
            });
            this.inventory
            .getPresentations()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {
              this.listPresentations = result;
            });
          }
        });
      } else {
        Swal.fire(
          this.translate.instant("Something was wrong"),
          this.translate.instant("Failed"),
          "error"
        );
      }
    });
  }

  textValid(text: string, index?: number) {
    return (
      this.registerForm.get(text)?.invalid &&
      this.registerForm.get(text)?.touched
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
