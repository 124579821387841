import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DetailsNotasRoutingModule } from './details-notas-routing.module';
import { DetailsNotasComponent } from './details-notas.component';
import { MaterialModule } from 'src/app/material.module';
import { StepperModule } from 'src/app/@shared/components/stepper/stepper.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [DetailsNotasComponent],
  imports: [
    CommonModule,
    DetailsNotasRoutingModule,
    MaterialModule,
    StepperModule,
    NgxQRCodeModule,
    NgxPrintModule,
    MatIconModule,
    DialogModule,
    TranslateModule
  ]
})
export class DetailsNotasModule { }
