<mat-dialog-content>
    <form [formGroup]="modifyForm">
        <h2><strong>{{'Modify' | translate}}</strong></h2>
        <div class="text-margin">
            <span><strong>{{'Document Type' | translate}}:</strong></span>
            <input readonly class="form-control" type="text" placeholder="{{'Document Type' | translate}}" formControlName="document_type">
            <span class="form-text text-danger" *ngIf="textValid('document_type')">{{'You must type valid account' | translate}}</span>
        </div>
        <div class="text-margin">
            <span><strong>{{'Inventory' | translate}}:</strong></span>
            <input class="form-control" type="text" placeholder="{{'Inventory' | translate}}" formControlName="inventory">
            <span class="form-text text-danger" *ngIf="textValid('inventory')">{{'You must type valid account' | translate}}</span>
        </div>
        <div class="text-margin">
            <span><strong>{{'Service' | translate}}:</strong></span>
            <input maxlength="12" class="form-control" type="text" placeholder="{{'Service' | translate}}" formControlName="service">
            <span class="form-text text-danger" *ngIf="textValid('service')">{{'You must type valid account' | translate}}</span>
        </div>
        <div class="text-margin">
            <span><strong>{{'Tax' | translate}}</strong></span>
            <input maxlength="12" class="form-control" type="text" placeholder="{{'Tax' | translate}}" formControlName="tax">
            <span class="form-text text-danger" *ngIf="textValid('tax')">{{'You must type valid account' | translate}}</span>
        </div>
        <div class="text-margin">
            <span><strong>{{'Withholding Tax' | translate}}:</strong></span>
            <input maxlength="12" class="form-control" type="text" placeholder="{{'Withholding' | translate}}" formControlName="withholding">
            <span class="form-text text-danger" *ngIf="textValid('withholding')">{{'You must type valid account' | translate}}</span>
        </div>
        <div class="text-margin">
            <span><strong>{{'Ica' | translate}}:</strong></span>
            <input maxlength="12" class="form-control" type="text" placeholder="{{'Ica' | translate}}" formControlName="ica">
            <span class="form-text text-danger" *ngIf="textValid('ica')">{{'You must type valid account' | translate}}</span>
        </div>
        <div class="text-margin">
            <span><strong>{{'Total' | translate}}:</strong></span>
            <input maxlength="12" class="form-control" type="text" placeholder="{{'Total' | translate}}" formControlName="total">
            <span class="form-text text-danger" *ngIf="textValid('total')">{{'You must type valid account' | translate}}</span>
        </div>
        <div class="row" id="buttons-div">
            <div class="col" id="buttons">
                <button (click)="update()" mat-raised-button>{{'Update' | translate}}</button>
            </div>
            <div class="col" id="buttons">
                <button mat-raised-button [mat-dialog-close]>{{'Cancel' | translate}}</button>
            </div>
        </div>
    </form>
</mat-dialog-content>