<div id="home">
  <mat-tab-group #tabs mat-align-tabs="center">
    <ng-container>
        <mat-tab label="{{'Home' | translate}}">
          <mat-accordion>
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'Filters' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{'Display filters' | translate}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-sm">
                  <form [formGroup]="registerForm">
                    <div>                            
                      <input class="form-control" formControlName='seller' name="seller" placeholder="{{'Filter by seller' | translate}}" 
                      [matAutocomplete]="autoSeller"  style="width: 300px;">
                      <mat-autocomplete #autoSeller="matAutocomplete" [displayWith]="displayFnSeller">
                          <mat-option  *ngFor="let seller of sellerList"
                              [value]="seller">
                              {{seller.nombre}}
                          </mat-option>
                      </mat-autocomplete>
                      </div>
                  </form>
                </div>
                <div style="margin-top: 20px;">
                  <button (click)="update(quotaFilterTypes, quotTodayPrimary)" style="margin-left: 20px;" mat-raised-button>{{'Update' | translate}}</button>    
                  <button (click)="reset(quotaListPrimary, quotTodayPrimary, notasListPrimary)" style="margin-left: 20px;" mat-raised-button>{{'Reset' | translate}}</button>
                </div>
              </div>   
            </mat-expansion-panel>
          </mat-accordion>
            <div [formGroup]="registerForm" fxLayout="row" fxLayoutAlign="space-around center">
              <div fxLayoutAlign="start center"><button mat-raised-button (click)="openDialogMails(true)" >{{'Send Report' | translate}}</button></div>
                <div fxLayoutAlign="center center">
                <mat-form-field appearance="fill" style="margin-left: 20px; margin-top: 20px;">
                  <mat-label>{{'Choose an presentation' | translate}}</mat-label>
                  <mat-select formControlName="typeForm" (selectionChange)="filterTypes($event, quotaListPrimary, notasListPrimary)">
                    <mat-option *ngFor="let types of presentationTypes; let i = index" [value]="types">
                      {{types.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <app-graph-v1 [quots]="quots" 
                          [quotTodayV1]="quotToday"
                          [notas]="notas"
                          [typePres]="typePres" 
                          [id_type]="id_type" 
                          [id_seller]="id_seller"
                          [monthTarjet]="monthTarjet"
                          [yearTarjet]="yearTarjet"
                          [months]="months" 
                          [numCustomers]="numCustomers">
            </app-graph-v1>
        </mat-tab>
    </ng-container>
  </mat-tab-group>

  <ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
      <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
  </ngx-spinner>
</div>

