import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { ProcessNotesService } from "../../../../@shared/components/services/charts/process-notes.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-sales-growth",
  templateUrl: "./sales-growth.component.html",
  styleUrls: ["./sales-growth.component.css"],
})
export class SalesGrowthComponent implements OnChanges {
  @Input() notas: Array<INotasGrap> = [];
  @Input() customerQuots: Array<IQuota> = [];

  percentage = 0;

  currentYear = new Date().getFullYear();
  daysAtToday = this.getDays();

  allNotas: Array<INotasGrap> = [];
  allQuots: Array<IQuota> = [];
  companyObject = {} as IStorageCompany;

  constructor(public analysis: ProcessNotesService, private cookieAuthService: CookieAuthService) {}

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "notas") {
        const values: Array<INotasGrap> = changes[item].currentValue;
        if (values.length > 0) {
          this.allNotas = values;
        }
      }
      if (item === "customerQuots") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.allQuots = values;
        }
      }
      if (this.allQuots.length > 0 || (this.allNotas.length > 0)) {
        this.calcPercents(this.allQuots, this.allNotas);
      }
    }
  }

  calcPercents(invoices: IQuota[], notas: INotasGrap[]) {
    let filterInvoices: IQuota[] = []
    if (this.companyObject.external_electronic_invoicing === "true" || this.companyObject.internal_electronic_invoicing === "true") {
      filterInvoices = invoices.filter((data) => data.estado === 4 && data.billyInvoice![0].invoice_number !== "PENDING");
    } else {
      filterInvoices = invoices.filter((data) => data.estado === 4);
    }
    let arrayFacts1: number[] = [];
    let arrayFacts2: number[] = [];
    let arrayIdNotas1: number[] = [];
    let arrayIdNotas2: number[] = [];
    let arrayGroupValues1 = [
      { fact: 0, articulo: "", cantidad: 0, litros: 0, subtotal: 0 },
    ];
    let arrayGroupValues2 = [
      { fact: 0, articulo: "", cantidad: 0, litros: 0, subtotal: 0 },
    ];
    let sum1 = 0;
    let sum2 = 0;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const pastYear = currentYear - 1;

    let currentYearTotal: number[] = [0];
    let pastYearTotal: number[] = [0];
    let finalGrowth = 0;
    let created;
    let invoice_date;

    filterInvoices.forEach(invoice => {
      if (invoice.estado === 4) {
        created = new Date(invoice.createdAt);
        if (invoice.invoiced_date) {
          invoice_date = new Date(invoice.invoiced_date!);
        } else {
          invoice_date = created;
        }
        if (invoice_date.getFullYear() === currentYear) {
          arrayFacts1.push(invoice.id_factura!);
          invoice.venta.forEach(sale => {
            currentYearTotal.push(
              sale.cantidad *
                parseFloat(sale.articulo[0].presentacion!)
            );
          });
        }
        if (invoice_date.getFullYear() === pastYear) {
          arrayFacts2.push(invoice.id_factura!);
          invoice.venta.forEach(sale => {
            pastYearTotal.push(
              sale.cantidad *
                parseFloat(sale.articulo[0].presentacion!)
            );
          });
        }
      }
    });

    let groupFacts1 = arrayFacts1.filter((item, index) => {
      return arrayFacts1.indexOf(item) === index;
    });
    let groupFacts2 = arrayFacts2.filter((item, index) => {
      return arrayFacts2.indexOf(item) === index;
    });

    notas.forEach((item) => {
      groupFacts1.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          arrayIdNotas1.push(parseInt(item.id_nota!.toString()));
        }
      });
      groupFacts2.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          arrayIdNotas2.push(parseInt(item.id_nota!.toString()));
        }
      });
    });

    arrayGroupValues1 = this.analysis.getNotesByDescription(notas, arrayIdNotas1);
    arrayGroupValues2 = this.analysis.getNotesByDescription(notas, arrayIdNotas2);

    arrayGroupValues1.forEach((item) => {
      sum1 += item.litros;
    });

    arrayGroupValues2.forEach((item) => {
      sum2 += item.litros;
    });

    let sumCurrentYearTotal = currentYearTotal.reduce(
      (previus, current) => (current += previus)
    );
    let sumPastYearTotal = pastYearTotal.reduce(
      (previus, current) => (current += previus)
    );

    sumCurrentYearTotal = sumCurrentYearTotal + sum1 * 1;
    sumPastYearTotal = sumPastYearTotal + sum2 * 1;

    let calcLastYear = (sumPastYearTotal / 360) * this.daysAtToday;
    let calcToDate = sumCurrentYearTotal * 120;
    let divide = calcToDate / calcLastYear;
    finalGrowth = divide;
    this.percentage = parseInt(finalGrowth.toFixed());
  }

  getDays(): number {
    let currentDate = new Date();
    let firstDate = new Date("01/01/2022");

    let milisegundosDia = 24 * 60 * 60 * 1000;

    let milisegundosTranscurridos = Math.abs(
      firstDate.getTime() - currentDate.getTime()
    );

    let diasTranscurridos = Math.round(
      milisegundosTranscurridos / milisegundosDia
    );

    return diasTranscurridos;
  }
}
