import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { IMailList } from "src/app/@interfaces/lists.interface";
import { PortfolioService } from "../../../../../@pages/portfolio/portfolio.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { HomeService } from "../../../../../@pages/home/home.service";

@Component({
  selector: "app-crud-mails-home",
  templateUrl: "./crud-mails-home.component.html",
  styleUrls: ["./crud-mails-home.component.css"],
})
export class CrudMailsHomeComponent implements OnInit, OnDestroy {
  registerForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array([
      this.fb.group({
        mails: ['', [Validators.required, Validators.pattern(this.validatorS.emailPattern), Validators.maxLength(50)]],
      })
    ]),
    group_mails: ['Home', [Validators.required]],
  });

  get rowsArr() {
    return this.registerForm.get("itemRows") as UntypedFormArray;
  }
  unsubscribe$ = new Subject();
  listMails: Array<IMailList> = [];

  treeBoxValue: string[] = [];

  constructor(
    private homeService: HomeService,
    private spinnerService: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private portfolio: PortfolioService,
    private router: Router,
    private validatorS: ValidatorsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.portfolio
    .getMailList("home")
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.listMails = result;
    });
  }

  updateSelect(valueSelected: any) {
    this.treeBoxValue = valueSelected.component.getSelectedNodeKeys();
  }

  clickPrintScreen(listMails: Array<IMailList>) {
    this.spinnerService.show();
    const logo = $("#img").attr("src");
    const img1 = document.getElementById("img1");
    const img2 = document.getElementById("img2");
    const img3 = document.getElementById("img3");
    const img4 = document.getElementById("img4");
    const img5 = document.getElementById("img5");
    const img6 = document.getElementById("img6");
    let mail: Array<any> = [];
    listMails.forEach((element) => {
      mail.push(element.mail);
    });
    this.homeService.printScreen(
      img1!,
      img2!,
      img3!,
      img4!,
      img5!,
      img6!,
      logo!,
      mail.toString()
    );
  }

  remove(id: number, index: number) {
    Swal.fire({
      title: this.translate.instant("Are you sure?"),
      text: this.translate.instant("You won not be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5400",
      cancelButtonColor: "rgb(0, 0, 0)",
      confirmButtonText: "Confirm",
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.portfolio
        .removeMailList(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (!result) {
            Swal.fire(
              "Error",
              this.translate.instant("Something was wrong"),
              "error"
            );
          } else {
            Swal.fire(
              this.translate.instant("Good"),
              this.translate.instant("Mail removed"),
              "success"
            ).then((data) => {
              if (data.isConfirmed) {
                this.router
                .navigateByUrl("/dialog", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate(["/home"]);
                });
                this.portfolio
                .getMailList("home")
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((result) => {
                  this.listMails = result;
                });
              }
            });
          }
        });
      }
    });
  }

  update() {
    if (this.registerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Mail Invalid, Please Check!"),
      });
      return;
    }
    const group = this.registerForm.value.group_mails;
    for (let index = 0; index < this.rowsArr.value.length; index++) {
      this.portfolio
      .createMailList(this.rowsArr.value[index].mails, group)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          Swal.fire({
            title: this.translate.instant("Created"),
            text: this.translate.instant("Mail created successfully!"),
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#f44336",
            confirmButtonText: "Ok",
          }).then((data) => {
            if (data.isConfirmed) {
              this.router
              .navigateByUrl("/dialog", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["/home"]);
              });
              this.portfolio
              .getMailList("home")
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((result) => {
                this.listMails = result;
              });
            }
          });
        } else {
          Swal.fire(
            this.translate.instant("Something was wrong"),
            this.translate.instant("Failed"),
            "error"
          );
        }
      });
    }
  }

  arrayvalid(text: string) {
    return this.rowsArr.get(text)?.invalid && this.rowsArr.get(text)?.touched;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
