import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from '../../../../@interfaces/quotation.interface';

@Pipe({
  name: 'filterFullDateQuots'
})
export class FilterFullDateQuots implements PipeTransform {
  transform(quotations: IQuota[], searchValue: string, date1: Date, date2: Date): IQuota[] {
    if (!quotations || !searchValue || !(date1 && date2)) {
        return quotations;
    }
    return quotations.filter((quotation) => {
        if (quotation.invoiced_date) {
            return(
              (new Date(quotation.invoiced_date!) >= date1 &&
              new Date(quotation.invoiced_date!) <= date2) &&
              (quotation.cliente[0].nombre?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
              quotation.seller![0].nombre?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
            )
        }else {
            return(
              (new Date(quotation.createdAt) >= date1 &&
              new Date(quotation.createdAt) <= date2) &&
              (quotation.cliente[0].nombre?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
              quotation.seller![0].nombre?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
            )
        }
    }) 
  }
}

