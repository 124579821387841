<mat-dialog-content>
    <form [formGroup]="customerForm">
        <div class="row justify-content-start">
            <h3><strong>{{'Register Customer' | translate}}</strong></h3>
        </div>
        <div class="row">
            <span>{{'Customer Type' | translate}}:</span>
            <select class="form-control" formControlName="cust_type">
                <option selected value="">{{'Select customer type' | translate}}</option>
                <option *ngFor="let types of customerTypeList" [ngValue]="types">
                    {{types.type}}
                </option>
            </select>
            <span class="form-text text-danger" *ngIf="textValid('cust_type')">{{'You must select a type' |
                translate}}</span>
        </div>
        <div class="row">
            <div class="col-md-4">
                <span>{{'Document Type' | translate}}:</span>
                <select class="form-control" formControlName="types_docs" (change)="updateSelectedType()">
                    <option selected value="">{{'Select Type' | translate}}</option>
                    <option *ngFor="let types of documenTypeList" [ngValue]="types">
                        {{types.description}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('types_docs')">{{'You must select a type' |
                    translate}}</span>
            </div>
            <div class="col-md-3">
                <span> {{'Document Or Nit' | translate}}:</span>
                <input class="form-control" placeholder="{{'Document/Nit' | translate}}" formControlName="doc_nit">
                <span class="form-text text-danger" *ngIf="textValid('doc_nit')">{{'The document must be wrote' |
                    translate}}</span>
            </div>
            <div class="col-md-1">
                <span> {{'DV' | translate}}:</span>
                <input class="form-control" placeholder="{{'DV' | translate}}" formControlName="dv">
                <span class="form-text text-danger" *ngIf="textValid('dv')">{{'The verification digit must be only 1 numbers' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Company Name' | translate}}:</span>
                <input class="form-control" [matAutocomplete]="autoCustomer" placeholder="{{'Name' | translate}}"
                    formControlName="name">
                <mat-autocomplete (optionSelected)="onSelectCustomer($event.option.value);"
                    #autoCustomer="matAutocomplete" [displayWith]="displayFnCustomer">
                    <mat-option *ngFor="let customer of customerList" [value]="customer">
                        {{customer.nombre}}
                    </mat-option>
                </mat-autocomplete>
                <span class="form-text text-danger" *ngIf="textValid('name')">{{'The name must be wrote' |
                    translate}}</span>
            </div>

            <div class="col-md-4">
                <span> {{'Observations' | translate}}:</span>
                <input class="form-control" placeholder="{{'Observations' | translate}}" formControlName="observations">
            </div>

            <div class="col-md-4">
                <span> {{'Company Phone' | translate}}:</span>
                <input class="form-control" placeholder="{{'Phone' | translate}}" formControlName="phone">
                <span class="form-text text-danger" *ngIf="textValid('phone')">{{'The phone must be only numbers' |
                    translate}}</span>
                <span class="form-text text-danger" *ngIf="textValid('phone')">{{'The phone must be only 15 numbers' |
                    translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Company Email' | translate}}:</span>
                <input class="form-control" placeholder="{{'Email' | translate}}" formControlName="email">
                <span class="form-text text-danger" *ngIf="textValid('email')">{{'The email is not a valid' |
                    translate}}</span>
            </div>
            <div class="col-md-4" *ngIf="companyId === '7' || companyId === '9'">
                <label for="exampleFormControlFile1">RUT (PDF)</label>
                <input (change)="getFile($event)" type="file" class="form-control-file" formControlName="RUT">
                <span class="form-text text-danger" *ngIf="textValid('RUT')">{{'You must enter the RUT in PDF' |
                    translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Company Country' | translate}}:</span>
                <select class="form-control" formControlName="country">
                    <option selected value="">{{'Select Country' | translate}}</option>
                    <option *ngFor="let country of countryList" [ngValue]="country">{{country.name}}</option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('country')">{{'The country is not valid' |
                    translate}}</span>
            </div>
            <div class="col-md-4" *ngIf="this.countrySelected === '170'">
                <span> {{'Select city' | translate}} </span>
                <select (change)="getCitySelect()" class="form-control" formControlName="citySelect">
                    <option selected value="">{{'Select city' | translate}}</option>
                    <option *ngFor="let cities of billyCities.data" [ngValue]="cities">{{cities.attributes.name}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('city')">{{'The city is not valid' |
                    translate}}</span>
            </div>
            <div class="col-md-4" *ngIf="this.countrySelected !== '170'">
                <span> {{'City' | translate}}:</span>
                <input class="form-control" [matAutocomplete]="autocompleteCity" placeholder="{{'Select city' | translate}}" formControlName="citySelect">
                <mat-autocomplete (optionSelected)="getCitySelect()" #autocompleteCity="matAutocomplete" [displayWith]="displayFnCity">
                    <mat-option *ngFor="let city of filterCities$ | async" [value]="city">
                        {{city}}
                    </mat-option>
                </mat-autocomplete>
                <span class="form-text text-danger" *ngIf="textValid('city')">{{"The city is not valid" | translate}}</span>
            </div>
            <div class="col-md-4">
                <span>{{'Seller' | translate}}:</span>
                <select class="form-control" formControlName="seller">
                    <option selected value="">{{'Select Seller' | translate}}</option>
                    <option *ngFor="let seller of sellerLists" [ngValue]="seller">
                        {{seller.seller.nombre}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('seller')">{{'You must select a seller' |
                    translate}}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <span> {{'Company Address' | translate}}:</span>
                <input class="form-control" placeholder="{{'Address' | translate}}" formControlName="address">
                <span class="form-text text-danger" *ngIf="textValid('address')">{{'The address must be wrote' |
                    translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Location' | translate}}:</span>
                <input class="form-control" placeholder="{{'Location' | translate}}" formControlName="coordinates">
                <span class="form-text text-danger" *ngIf="textValid('coordinates')">{{'Location invalid' |
                    translate}}</span>
            </div>

            <div class="col-md-4">
                <span> {{'Select Location' | translate}}:</span> &nbsp; <br>
                <button (click)="openDialog()" mat-raised-button>{{'Select Location' | translate}}</button>
            </div>
            <div class="col-md-4">
                <span>{{'Contributor Type' | translate}}:</span>
                <select class="form-control" formControlName="contributorType">
                    <option selected value="">{{'Select Contributor Type' | translate}}</option>
                    <option *ngFor="let contributor of contributorTypeList" [ngValue]="contributor">
                        {{contributor.description}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('contributorType')">{{'You must select a contributor type' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span>{{'Segment' | translate}}:</span>
                <select class="form-control" formControlName="segment">
                    <option selected value="">{{'Select Segment' | translate}}</option>
                    <option *ngFor="let segment of customerSegmentList" [ngValue]="segment">
                        {{segment.nombre}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('segment')">{{'You must select a segment' |
                    translate}}</span>
            </div>
        </div>
        <span class="form-text text-danger ml-2">{{ clientErrorMessage | translate}}</span>
        <div class="row">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayout.xs="column" id="buttons-div">
                <div id="buttons">
                    <button (click)="register()" mat-raised-button>{{'Register' | translate}}</button>
                </div>
                <div class="central-button">
                    <button mat-raised-button [mat-dialog-close]=false>{{'Cancel' | translate}}</button>
                </div>
                <div id="customerAlert" class="alert alert-danger" *ngIf="customerExistsName">
                    {{"ALREADY EXISTS A CUSTOMER CALLED" | translate}}: {{customerExistsName}}!
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
