import { Component, Input, OnInit } from "@angular/core";
import { ChartDataSets, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";

@Component({
  selector: "app-line",
  templateUrl: "./line.component.html",
  styleUrls: ["./line.component.css"],
})
export class LineComponent implements OnInit {
  @Input() lineChartData: ChartDataSets[] = [];

  @Input() lineChartLabels: Label[] = [];

  @Input() lineChartColors: Color[] = [];

  lineChartOptions = {
    responsive: true,
  };
  lineChartLegend = true;
  lineChartPlugins = [];
  public lineChartType: ChartType = "line";

  constructor() {}

  ngOnInit(): void {}
}
