import { Injectable } from "@angular/core";
import { BillyService } from "../billyService/billy.service";
import { QuotationsService } from "../quotations/quotations.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { IContFact } from "src/app/@interfaces/contfac.interface";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { IGraphQToBilly } from "src/app/@interfaces/Billy/billy.interface";
import { Driver } from "src/app/@interfaces/driver.interface";
import { Subject } from "rxjs";
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from "@techiediaries/ngx-qrcode";
import { takeUntil } from "rxjs/operators";
import { OperationalReportingService } from "../reports/pages/operational-reporting/operational-reporting.service";
import { GenerateDetailsPDFService } from "../../@shared/components/services/files/pdf/generate-details-pdf.service";
import { GenerateLetterheadPdfService } from "../../@shared/components/services/files/pdf/generate-letterhead-pdf.service";

@Injectable({
  providedIn: "root",
})
export class CalculateDetailsService {
  subtotal: number = 0;
  taxCalculated: number = 0;
  discount: number = 0;
  total: number = 0;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrUrl = "";

  invoiceObject = {} as IQuota;
  invoiceCount = {} as IContFact;
  sales: Array<IVentaQuery> = [];
  billyInvoiceData? = {} as IGraphQToBilly;

  driverObject = {} as Driver;
  rateBCV: number | undefined;

  unsubscribe$ = new Subject();
  constructor(
    private billy: BillyService,
    private quotationService: QuotationsService,
    private operationalReportingService: OperationalReportingService,
    private generateDetailsPDFService: GenerateDetailsPDFService,
    private generateLetterheadPdfService: GenerateLetterheadPdfService
  ) {}

  async setDocument(invoiceID: number, company: string, type: string) {
    this.invoiceObject = await this.getInvoice(invoiceID, company);
    this.qrUrl = await this.setQRCode(invoiceID, company, type);
    this.invoiceCount = this.invoiceObject.contfac![0];
    this.sales = this.invoiceObject.venta;
    this.sales.forEach((element) => {
      const saleValues = this.calculateSales(element);
      this.taxCalculated += saleValues.calculatedTax;
      this.subtotal += saleValues.subtotal;
      this.discount += saleValues.discount;
      this.total += saleValues.totals;
    });
    this.driverObject = await this.setDriverData(
      this.invoiceObject.num_fac,
      company
    );
    this.rateBCV = this.driverObject?.tasa_c! || 0;
    await this.generatePDFByType(type, company);
  }

  async generatePDFByType(type: string, company: string) {
    if (type === "1") {
      await this.generateDetailsPDFService.generateInvoicePDF(
        this.invoiceObject,
        this.invoiceCount,
        this.sales,
        this.subtotal,
        this.taxCalculated,
        this.discount,
        this.total,
        this.qrUrl,
        company
      );
    } else {
      this.generateLetterheadPdfService.generateLetterheadPDF(
        this.invoiceObject,
        this.invoiceCount,
        this.sales,
        this.subtotal,
        this.taxCalculated,
        this.discount,
        this.total,
        this.rateBCV!,
        this.driverObject
      );
    }
  }

  calculateSales(element: IVentaQuery) {
    let calculatedSales = {} as any;
    if (this.invoiceObject.tax_incl === "false") {
      calculatedSales = this.calculateOnTaxIncludedFalse(element);
    } else if (this.invoiceObject.tax_incl === "true") {
      calculatedSales = this.calculateOnTaxIncludedTrue(element);
    }
    return {
      calculatedTax: calculatedSales!.calculatedTax,
      subtotal: calculatedSales!.subtotal,
      discount: calculatedSales!.discount,
      totals: calculatedSales!.totals,
    };
  }

  calculateOnTaxIncludedFalse(element: IVentaQuery) {
    let discount = 0;
    let subtotal = 0;
    let discountedSubtotal = 0;
    let totals = 0;
    let calculatedTax = 0;
    let tax = 0;
    discount = (element.descuento / 100) * (element.cantidad * element.precio);
    tax = this.operationalReportingService.getTaxPercent(
      element,
      this.invoiceObject
    );
    subtotal = element.cantidad * element.precio;
    discountedSubtotal = subtotal - discount;
    calculatedTax = discountedSubtotal * tax;
    totals = discountedSubtotal + calculatedTax;
    return {
      calculatedTax: calculatedTax,
      subtotal: subtotal,
      discount: discount,
      totals: totals,
    };
  }

  calculateOnTaxIncludedTrue(element: IVentaQuery) {
    let discount = 0;
    let subtotal = 0;
    let totals = 0;
    let calculatedTax = 0;
    let tax = 0;
    tax = element.articulo[0].tax![0].value;
    subtotal = element.cantidad * (element.precio / (tax + 1));
    discount = (element.descuento / 100) * subtotal;
    subtotal = subtotal - discount;
    calculatedTax = subtotal * tax;
    totals = subtotal + calculatedTax;
    return {
      calculatedTax: calculatedTax,
      subtotal: subtotal,
      discount: discount,
      totals: totals,
    };
  }

  getInvoice(invoiceID: number, company: string) {
    return new Promise<IQuota>((resolve, reject) => {
      this.quotationService
        .getQuotation(invoiceID, company)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((invoice) => {
          resolve(invoice);
        });
    });
  }

  async setQRCode(invoiceID: number, company: string, type: string) {
    if (
      this.invoiceObject.estado === 4 &&
      (company === "7" || company === "9")
    ) {
      return await this.setBillyQR(
        this.invoiceObject.billyInvoice![0],
        invoiceID,
        company,
        type
      );
    } else {
      return (
        "https://v1-insight.com/#/details/" +
        invoiceID +
        "/" +
        company +
        "/" +
        type
      );
    }
  }

  async setBillyQR(
    billyGraphQL: IGraphQToBilly,
    invoiceID: number,
    company: string,
    type: string
  ) {
    return new Promise<string>((resolve, reject) => {
      this.billy
        .getInvoice(billyGraphQL!.id_billy.toString(), company)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (result) => {
            this.billyInvoiceData = billyGraphQL;
            resolve(result.data.attributes.pdfUrl);
          },
          (error) => {
            this.billyInvoiceData = undefined;
            resolve(
              "https://v1-insight.com/#/details/" +
                invoiceID +
                "/" +
                company +
                "/" +
                type
            );
          }
        );
    });
  }

  async setDriverData(num_fac: string, company: string) {
    return new Promise<Driver>((resolve, reject) => {
      this.quotationService
        .getDriver(num_fac, company)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (result) => {
            if (result) {
              resolve(result);
            } else {
              resolve(this.setNullDriver());
            }
          },
          (error) => {
            reject(this.setNullDriver());
          }
        );
    });
  }

  setNullDriver(): Driver {
    return {
      chofer: "-",
      cin: "-",
      destino: "-",
      id_chofer: 0,
      invoice: "-",
      permiso_rasda: "-",
      peso_neto: "-",
      placa: "-",
      son: "-",
      tasa_c: 0,
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
