import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import {
  ICompany,
  IStorageCompany,
} from "src/app/@interfaces/company.interface";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { UsersService } from "../users/users.service";
import Swal from "sweetalert2";
import { MediaMatcher } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IUserSeller } from "src/app/@interfaces/userSeller.interface";
import { BillyService } from "../billyService/billy.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { AppState } from "src/app/state/app,state";
import { Store } from "@ngrx/store";
import { selectCompaniesList } from "src/app/state/selectors/user-companies.selectors";

export var company: Array<ICompany> = [];

@Component({
  selector: "app-selectDB",
  templateUrl: "./selectDB.component.html",
  styleUrls: ["./selectDB.component.css"],
})
export class SelectDBComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  companyList$: Observable<IStorageCompany[]> = new Observable();
  unsubscribe$ = new Subject();
  initialRoute: string = "";

  constructor(
    private translate: TranslateService,
    private userService: UsersService,
    media: MediaMatcher,
    private authService: AuthService,
    private router: Router,
    private billyService: BillyService,
    private cookieAuthService: CookieAuthService,
    private store: Store<AppState>
  ) {
    this.mobileQuery = media.matchMedia("(max-width: 600px)");
  }

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.initialRoute = await this.userService.getInitialRouteByRole();
    this.setCompanies();
  }

  setCompanies() {
    this.companyList$ = this.store.select(selectCompaniesList);
  }

  /*
  1 = V1-rotate-LLC
  2 = Ibexports
  3 = Predeterminado
  4 = Jens Automotive Products
  5 = Versa Cars
  6 = Parabricentro Del Caribe
  7 = V-One Colombia
  8 = Comercializadora De Vidrios
  */

  selection(company: IStorageCompany) {
    Swal.fire(
      this.translate.instant("Activated!"),
      company.name + " " + this.translate.instant("has been activated"),
      "success"
    ).then(async (res) => {
      if (res.isConfirmed) {
        try {
          const userId = this.cookieAuthService.getUserId!;
          const userSellerId = await this.getUserSeller(
            parseInt(userId.toString()),
            company.Id_company!.toString()
          );
          this.cookieAuthService.setLocalStorageCompany(company, userSellerId);
          this.cookieAuthService.setUserSellerId();
          this.cookieAuthService.setCompanyObject();
          this.billyService.assignToken();
          this.router.navigate([this.initialRoute]);
        } catch (error) {
          console.error(error);
        }
      }
    });
  }

  private async getUserSeller(
    id_user: number,
    company: string
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.userService!.getSellerByUser(id_user, company)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (result: IUserSeller[]) => {
            if (result[0]) {
              resolve(result[0].id_userSeller.toString());
            } else {
              resolve("");
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
