import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import {
  GET_COMPANIES,
  GET_COMPANIES_BY_USER,
} from "src/app/@graphql/operations/query";
import { ApiService } from "src/app/@graphql/services/api.service";

@Injectable({
  providedIn: "root",
})
export class CompanyService extends ApiService {
  constructor(apollo: Apollo) {
    super(apollo);
  }

  getAllCompanies() {
    return this.query(GET_COMPANIES).pipe(
      map((result: any) => {
        return result.getAllCompanies;
      })
    );
  }

  getAllCompaniesByUser(userId: number) {
    return this.query(GET_COMPANIES_BY_USER, { userId }).pipe(
      map((result) => {
        return result.getAllCompaniesByUser;
      })
    );
  }
}
