import { Pipe, PipeTransform } from '@angular/core';
import { IPurchasesInvoices } from '../../@interfaces/purchaseInvoice.interface';

@Pipe({
  name: 'filterPurchase'
})
export class FilterPurchasePipe implements PipeTransform {
  transform(purchases: IPurchasesInvoices[], searchValue: string): IPurchasesInvoices[] {
    if (!purchases || !searchValue) {
      return purchases;
    }
    return purchases.filter(purchase =>
        purchase.id_invoice?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        purchase.num_invo?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        purchase.createdAt?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        purchase.tax?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        purchase.provider[0].nombre?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        purchase.purchases[0].total?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        purchase.contpurchase![0].written_invoice?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    }
}
