import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CodifyAuthVariablesService {
  constructor() {}

  setCodifyItem(value: string, secretKey: string) {
    const concatenatedString = `${secretKey}${value}`;
    return btoa(concatenatedString);
  }

  getDecodedItem(value: string, secretKey: string) {
    try {
      const decodedString = atob(value);
      if (decodedString.startsWith(secretKey)) {
        return decodedString.slice(secretKey.length);
      } else {
        return '';
      }
    } catch (error) {
      return '';
    }
  }
}
