
<div style="width: 100%; height: 100%;" *ngIf="typeGraph === '1'">
    <div style="width: 100%; height: 100%;"  #divGrafica>
        <app-multiple-pane indicator="Lts" nameTop="Promedio" [nameBot]="typePres | translate" [data]="dataLiters"></app-multiple-pane>
    </div>
</div>

<div style="width: 100%; height: 100%;" *ngIf="typeGraph === '2'">
    <div style="width: 100%; height: 100%;"  #divGrafica>
        <app-multiple-pane indicator="Cts" nameTop="Promedio" nameBot="Cantidades" [data]="dataQuants"></app-multiple-pane>
    </div>
</div>    

