export function getbusinessDays(currentMonth:number, endDay:number){
    const currentDate = new Date();
    const startDay = 1;

    const arrayDates: Date[] = [];
    const arrDatesLbs: Date[] = [];

    for (let day = startDay; day <= endDay; day++) {
        const date = new Date(currentDate.getFullYear(), currentMonth, day);
        arrayDates.push(date);
        if (date.getDay() !== 0) {
            arrDatesLbs.push(date);
        }
    }
    return arrDatesLbs.length;
}