import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from "@angular/core";
import { EventEmitterService } from "../../../../@core/menu/emitterFuncions.service";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-average-indicator",
  templateUrl: "./average-indicator.component.html",
  styleUrls: ["./average-indicator.component.css"],
})
export class AverageIndicatorComponent implements OnChanges {
  @Input() currentMonthDollar = 0;
  @Input() pastMonthDollar = 0;

  @Input() currentMonthLiter = 0;
  @Input() pastMonthLiter = 0;

  @Input() divideCurrent = 0;
  @Input() dividePast = 0;

  @Input() customersCurrents = 0;
  @Input() customersPasts = 0;

  @Input() percentCustomersCurrents = 0;
  @Input() percentCustomersPasts = 0;

  @Input() calculateDropSize = "";
  @Input() calculateUPT = "";
  @Input() calculateAveragePrice = "";
  @Input() calculateActivation = "";
  @Input() calculatePercentActivation = "";

  customOptions: OwlOptions = {
    loop: true,
    margin: 10,
    navText: [
      '<i class="fa-chevron-left"> Prev </i>',
      '<i class="fa-chevron-right"> Next </i>',
    ],
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      500: {
        items: 2,
        nav: true,
      },
      600: {
        items: 3,
        nav: true,
      },
      900: {
        items: 4,
        nav: true,
      },
      1200: {
        items: 5,
        nav: true,
      },
    },
  };

  constructor(private eventEmitterService: EventEmitterService) {}

  ngOnChanges(changes: SimpleChanges): void {
    for (let item in changes) {
      if (item === "currentMonthDollar") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.currentMonthDollar = 0;
        }
      }
      if (item === "pastMonthDollar") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.pastMonthDollar = 0;
        }
      }
      if (item === "currentMonthLiter") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.currentMonthLiter = 0;
        }
      }
      if (item === "pastMonthLiter") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.pastMonthLiter = 0;
        }
      }
      if (item === "divideCurrent") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.divideCurrent = 0;
        }
      }
      if (item === "dividePast") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.dividePast = 0;
        }
      }
      if (item === "customersCurrents") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.customersCurrents = 0;
        }
      }
      if (item === "customersPasts") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.customersPasts = 0;
        }
      }
      if (item === "percentCustomersCurrents") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.percentCustomersCurrents = 0;
        }
      }
      if (item === "percentCustomersPasts") {
        const value: number = changes[item].currentValue;
        if (isNaN(value)) {
          this.percentCustomersPasts = 0;
        }
      }
    }
  }

  changeItem() {
    this.eventEmitterService.onChangeCarousel();
  }

  calculate(current: number, previus: number) {
    let result = 0;
    result = current - previus;
    return result.toFixed(2);
  }
}
