import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/internal/operators";
import { ApiService } from "src/app/@graphql/services/api.service";
import {
  GET_QUOTATIONS_BYUSER,
  GET_QUOTATION_BY_STATUS3_BYUSER,
  ORDER_CUSTOMER_BYUSER,
  FIND_QUOTATIONS_BYUSER,
  COUNT_ALL_QUOTATIONS_BYUSER,
  COUNT_QUOTATIONS_BYSTATUS_BYUSER,
  GET_ALL_INVOICES_BYUSER,
  GET_ALL_INVOICES_BYSTATUS_BYUSER,
  GET_ALL_INVOICES_BYSTATUS_INVOICED_BYUSER,
  COUNT_QUOTATIONS_BYSTATUS_INVOICED_BYUSER,
} from "../../@graphql/operations/queryByUser";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class QuotationsByUserService extends ApiService {
  constructor(
    apollo: Apollo,
    private cookieAuthService: CookieAuthService
  ) {
    super(apollo);
  }

  //Invoices By User

  listQuotationsByUser(id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_QUOTATIONS_BYUSER, { company, id_user }).pipe(
      map((result: any) => {
        return result.getAllQuotationsByUser;
      })
    );
  }

  listQuotationsByStatus3ByUser(id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_QUOTATION_BY_STATUS3_BYUSER, {
      company,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.getQuotationsByStatus3ByUser;
      })
    );
  }

  getOrderCustomerByUser(id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(ORDER_CUSTOMER_BYUSER, { company, id_user }).pipe(
      map((result: any) => {
        return result.getQuotOrderCustomerByUser;
      })
    );
  }

  //Pagination
  countAllQuotationsByUser(id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_ALL_QUOTATIONS_BYUSER, { company, id_user }).pipe(
      map((result: any) => {
        return result.countAllQuotationsByUser;
      })
    );
  }

  countQuotationsByStatusByUser(estado: number, id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_QUOTATIONS_BYSTATUS_BYUSER, {
      estado,
      company,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.countQuotationsByStatusByUser;
      })
    );
  }

  countQuotationsByStatusInvoicedByUser(
    estado_factura: number,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_QUOTATIONS_BYSTATUS_INVOICED_BYUSER, {
      estado_factura,
      company,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.countQuotationsByStatusInvoicedByUser;
      })
    );
  }

  findQuotationsByUser(
    estado: number,
    value: string,
    size: number,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(FIND_QUOTATIONS_BYUSER, {
      estado,
      value,
      size,
      company,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.findQuotationsByUser;
      })
    );
  }

  getAllInvoicesByUser(page: number, size: number, id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_INVOICES_BYUSER, {
      company,
      page,
      size,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.getAllInvoicesByUser;
      })
    );
  }

  getAllInvoicesByStatusByUser(
    estado: number,
    page: number,
    size: number,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_INVOICES_BYSTATUS_BYUSER, {
      company,
      estado,
      page,
      size,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.getAllInvoicesByStatusByUser;
      })
    );
  }

  getAllInvoicesByStatusInvoicedByUser(
    estado_factura: number,
    page: number,
    size: number,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_INVOICES_BYSTATUS_INVOICED_BYUSER, {
      company,
      estado_factura,
      page,
      size,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.getAllInvoicesByStatusInvoicedByUser;
      })
    );
  }
}
