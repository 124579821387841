import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/@shared/authentication/auth.service';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit {

  constructor(private authService: AuthService) { }

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
  }
}
