import { Injectable, OnDestroy } from "@angular/core";
import { PurchasesService } from "src/app/@pages/purchases/purchases.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IRefunds } from "src/app/@interfaces/refunds.interface";
import { TranslateService } from "@ngx-translate/core";
import { AlertsService } from "../../../services/alerts/alerts.service";

@Injectable({
  providedIn: "root",
})
export class PayPurchaseService implements OnDestroy {
  unsubscribe$ = new Subject();
  constructor(
    private purchasesService: PurchasesService,
    private translate: TranslateService,
    private alertsService: AlertsService
  ) {}

  getInvoice(id_invoice: number) {
    return new Promise<any>((resolve, reject) => {
        this.purchasesService
        .getPurchaseInvoice(id_invoice)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
            resolve(result);
        });
    });
  }

  getRefunds(id_invoice: number) {
    return new Promise<number>((resolve, reject) => {
      let total = 0;
      this.purchasesService
      .getRefundsByPurchaseID(id_invoice)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IRefunds[]) => {
        result.forEach((r) => {
          total += r.total * -1;
        });
        resolve(total);
      });
    });
  }

  validations(value: number, missingPaid: number) {
    if(value > parseFloat(missingPaid.toFixed(2))) {
      this.alertsService.showErrorAlert(`${this.translate.instant(
        "You cannot make a payment greater than the amount due!"
      )} ($${(parseFloat(missingPaid.toFixed(2))).toFixed(2)})`,)
      return false;
    }else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
