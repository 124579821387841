<mat-dialog-content>
    <div class="row">
        <img src="../../../../../assets/icons/icono.png" onerror="this.src='assets/img/cantfindimage.jpg'" class="img-logo">
        <div class="col-md-2" class="title">
            <h2 mat-dialog-tittle>{{'New Advance Payment' | translate}}</h2>
        </div>
    </div>
    <form [formGroup]="registerForm">
        <div class="row" id="container-top">
            <div class="col" id="div-responsive">
                <mat-form-field>
                    <mat-label>{{'Type customer' | translate}}</mat-label>
                    <input (keyup)="disableProviderForm()" id="input-top" type="text" matInput [matAutocomplete]="autoCustomer" [formControl]="customerForm">
                    <mat-autocomplete (optionSelected)="disableProviderForm()" #autoCustomer="matAutocomplete" [displayWith]="displayFnCustomer">
                        <mat-option *ngFor="let customer of customerList" [value]="customer">
                            {{customer.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col" id="div-responsive">
                <mat-form-field>
                    <mat-label>{{'Type provider' | translate}}</mat-label>
                    <input (keyup)="disableCustomerForm()" id="input-top" type="text" matInput [matAutocomplete]="autoProvider" [formControl]="providerForm">
                    <mat-autocomplete (optionSelected)="disableCustomerForm()" #autoProvider="matAutocomplete" [displayWith]="displayFnProvider">
                        <mat-option *ngFor="let provider of providerList" [value]="provider">
                            {{provider.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col" id="div-responsive">
                <mat-form-field appearance="fill">
                   <mat-label> {{'Payment Type' | translate}} </mat-label>
                   <mat-select formControlName="payment" [compareWith]="comparePayment">
                       <mat-option *ngFor="let payment of paymentsList" [value]="payment">
                           {{payment.description}}
                       </mat-option>
                   </mat-select>
                 </mat-form-field>
               <span class="form-text text-danger" *ngIf="textValid('seller')">{{'You must select a seller' | translate}}</span>
           </div>
        </div>
        <div class="row" id="container-top">
           <div class="col" id="div-responsive">
                <mat-form-field appearance="fill">
                    <mat-label>{{'Date' | translate}}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col" id="div-responsive">
                <mat-form-field>
                    <mat-label>{{'Value' | translate}}</mat-label>
                    <input type="number" matInput formControlName="value" autocomplete="off">  
                </mat-form-field>
            </div>
            <div class="col" id="div-responsive">
                <mat-form-field>
                    <mat-label>{{'ID Reference' | translate}}</mat-label>
                    <input matInput formControlName="id_ref" autocomplete="off">  
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" class="buttons-div">
            <div>
                <button class="register-button" mat-raised-button (click)="validRegister()">{{'Register' | translate}}</button>
            </div>
            <div>
                <button mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
            </div>
        </div>
    </form>
</mat-dialog-content>
