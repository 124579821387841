<dx-bullet
    #bullet
    class="bullet"
    [startScaleValue]="0"
    [endScaleValue]="target"
    [value]="value"
    [target]="target"
    [color]="color"
    >
</dx-bullet>

