import { Injectable } from "@angular/core";
import { IDetails } from "../../@interfaces/details/details.interface";
import { INote } from "src/app/@interfaces/details/note.interface";
import { ITableData } from "src/app/@interfaces/details/table.interface";
import { IInvoicePayments } from "src/app/@interfaces/payments.interface";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { PortfolioService } from '../portfolio/portfolio.service';
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DetailsPortfolioService {
  date = new Date();
  unsubscribe$ = new Subject()
  constructor(
    private portfolioService: PortfolioService
  ) {}

  calculateDaysPastDue(allTotal: number, totalByItem: number, totalWitoutPaysByItem: number, details: IDetails[]) {
    let daysPastDue = 0, daysBetweenDates = 0, participation = 0, daysWeighted = 0, daysWeightedByItem = 0, participationByItem = 0;
    details.forEach((data) => {
      const canceledPayment = this.getCanceledPaymet(data, data.totalPaid!);
      daysBetweenDates = this.validDaysBetweenDates(
        canceledPayment,
        data.totalPaid!,
        new Date(data.dueDate)
      );
      participation = this.validParticipation(
        data.total,
        data.totalPaid!,
        allTotal,
        totalByItem
      );
      daysWeighted = this.calculateDaysWeighted(
        daysBetweenDates,
        participation
      );
      participationByItem = this.validParticipationByItem(
        data.total,
        data.totalPaid!,
        totalByItem,
        totalWitoutPaysByItem
      );
      daysWeightedByItem = this.calculateDaysWeighted(
        daysBetweenDates,
        participationByItem
      );
      data.cancelledDate = canceledPayment.date ? canceledPayment.date : "";
      data.daysBetweenDates = parseFloat(daysBetweenDates.toFixed(4));
      data.participation = parseFloat(participation.toFixed(4));
      data.daysPastDue = parseFloat(daysWeighted.toFixed(4));
      data.daysWeighted = parseFloat(daysWeightedByItem.toFixed(4));
      data.participationByItem = parseFloat(participationByItem.toFixed(4));
      daysPastDue += daysWeightedByItem;
    });
    return {
      daysPastDue: parseFloat(daysPastDue.toFixed(2)),
      details: details,
    };
  }

  getCanceledPaymet(details: IDetails, total: number) {
    let canceledPayment = {} as IInvoicePayments;
    if (total <= 0) {
      if (details.paid?.length! > 0) {
        details.paid?.forEach((payment) => {
          if (payment.type === "Cancellation") {
            canceledPayment = payment;
          }
        });
      }
    }
    return canceledPayment;
  }

  validParticipation(totalInvoice: number, totalPaid: number, allTotal: number, totalByItem: number) {
    let participation = 0;
    if (totalByItem <= 0) {
      participation = this.calculateParticipation(totalInvoice, allTotal);
    } else {
      participation = this.calculateParticipation(totalPaid, allTotal);
    }
    return participation;
  }

  validParticipationByItem(totalInvoice: number, totalPaid: number, totalByItem: number, totalWitoutPaysByItem: number) {
    let participation = 0;
    if (totalByItem <= 0) {
      participation = this.calculateParticipation(totalInvoice, totalWitoutPaysByItem);
    } else {
      participation = this.calculateParticipation(totalPaid, totalByItem);
    }
    return participation;
  }

  validDaysBetweenDates(canceledPayment: IInvoicePayments, total: number, dueDate: Date) {
    let daysBetweenDates = 0;
    if (total <= 0) {
      if (canceledPayment.date) {
        daysBetweenDates = this.getDaysBetweenDates(
          new Date(dueDate),
          new Date(canceledPayment.date)
        );
      } else {
        daysBetweenDates = this.getDaysBetweenDates(
          new Date(dueDate),
          new Date(dueDate)
        );
      }
    } else {
      daysBetweenDates = this.getDaysBetweenDates(new Date(dueDate), this.date);
    }
    return daysBetweenDates;
  }

  getLastPayment(details: IDetails[], total: number) {
    let lastPayment: IInvoicePayments[] = [];
    let orderByDate: IInvoicePayments[] = [];
    if (total <= 0) {
      details.forEach((detail) => {
        detail.paid!.forEach((payments) => {
          lastPayment.push(payments);
        });
      });
      orderByDate = this.orderPaymentsByDate(lastPayment);
    }
    return orderByDate;
  }

  getDaysBetweenDates(dateInit: Date, dateEnd: Date) {
    const milisecondsInit = dateInit.getTime();
    const milisecondsEnd = dateEnd.getTime();
    const difference = milisecondsEnd - milisecondsInit;
    return difference / (1000 * 60 * 60 * 24);
  }

  calculateParticipation(totalPaid: number, allTotal: number) {
    const divideValues = totalPaid / allTotal;
    return divideValues * 100;
  }

  calculateDaysWeighted(participation: number, daysBetweenDates: number) {
    const multiplyValues = participation * daysBetweenDates;
    return multiplyValues / 100;
  }

  calculateAllTotal(customers: string[], arrayInit: ITableData[], notesC: INote[], notesD: INote[], pays: IInvoicePayments[], includePays: boolean) {
    let total = 0;
    customers.forEach((customer) => {
      let filterByCustomer = arrayInit.filter(
        (data: any) => data.customer === customer
      );
      const totalCustomer = this.calculateByCustomer(
        filterByCustomer,
        notesC,
        notesD,
        pays,
        includePays
      );
      total += totalCustomer;
    });
    return total;
  }

  calculateByCustomer(filterByCustomer: ITableData[], notesC: INote[], notesD: INote[], pays: IInvoicePayments[], includePays: boolean) {
    let totalCustomer = 0;
    filterByCustomer.forEach((filtered) => {
      const totalsDetails = this.getTotalsFromDetails(
        filtered,
        notesC,
        notesD,
        pays
      );
      totalCustomer += totalsDetails.totalInvoice;
      totalCustomer += totalsDetails.totalDebit;
      totalCustomer -= totalsDetails.totalCredit;
      if (includePays) {
        totalCustomer -= totalsDetails.totalPayment;
      }
    });
    return totalCustomer;
  }

  getTotalsFromDetails(filtered: ITableData, notesC: INote[], notesD: INote[], pays: IInvoicePayments[]) {
    let totalDebit = 0, totalCredit = 0, totalInvoice = 0, totalPayment = 0;
    filtered.details?.forEach((detail) => {
      totalInvoice = detail.total;
      notesC.forEach((C) => {
        if (C.fact === detail.invoice) {
          totalCredit += C.total * -1;
        }
      });
      notesD.forEach((D) => {
        if (D.fact === detail.invoice) {
          totalDebit += D.total;
        }
      });
      pays.forEach((P) => {
        if (P.id_factura.toString() === detail.invoice) {
          totalPayment += P.value;
        }
      });
    });
    return {
      totalDebit: totalDebit,
      totalCredit: totalCredit,
      totalInvoice: totalInvoice,
      totalPayment: totalPayment,
    };
  }

  processNotes(notes: INotasGrap[], facts: number[], type: string) {
    let note: INote[] = [];
    let abr = "";
    if (type === "Credito") {
      abr = "CN";
    } else if (type === "Debito") {
      abr = "DN";
    }
    facts.forEach((num) => {
      notes.forEach((nota) => {
        if (nota.note_fact.toString() === num.toString() && nota.tipo_nota === type) {
          note.push({
            fact: num.toString(),
            code: abr + "-" + nota.id_nota?.toString(),
            total: nota.total,
          });
        }
      });
    });
    return note;
  }

  processPays(payments: IInvoicePayments[], facts: number[]) {
    let pays: IInvoicePayments[] = [];
    facts.forEach((num) => {
      payments.forEach((pay) => {
        if (pay.id_factura.toString() === num.toString() && pay.status === 3) {
          pays.push(pay);
        }
      });
    });
    return pays;
  }

  calculateADD(data: IDetails[], allTotal: number, allTotalWitoutPays: number) {
    let ADD = 0;
    const lastPayment = this.getLastPayment(data, allTotal);
    const DSO = this.calculateDSO(
      lastPayment,
      data,
      allTotal,
      allTotalWitoutPays
    );
    const BPDSO = this.calculateBPDSO(
      lastPayment,
      data,
      allTotal,
      allTotalWitoutPays
    );
    ADD = parseFloat(DSO.toFixed(2)) - parseFloat(BPDSO.toFixed(2));
    return {
      ADD: parseFloat(ADD.toFixed(2)),
      DSO: parseFloat(DSO.toFixed(2)),
      BPDSO: parseFloat(BPDSO.toFixed(2)),
    };
  }

  getTotalDaysInvoiced(data: IDetails[], dateEnd: Date) {
    let daysInvoiced = 0;
    const orderDetails = this.orderDetailsByDate(data);
    const dateInit = new Date(orderDetails[0].createdDate);
    daysInvoiced = this.getDaysBetweenDates(dateInit, dateEnd);
    return daysInvoiced;
  }

  calculateDSO(lastPayment: IInvoicePayments[], data: IDetails[], allTotal: number, allTotalWitoutPays: number) {
    let DSO = 0, daysInvoiced = 0;
    if (allTotal <= 0 && lastPayment.length > 0) {
      daysInvoiced = this.getTotalDaysInvoiced(data, new Date(lastPayment[0].cancelled_date.split("T")[0]));
    } else {
      daysInvoiced = this.getTotalDaysInvoiced(data, this.date);
    }
    if (allTotalWitoutPays === 0) {
      DSO = 0;
    } else {
      if (allTotal <= 0) {
        DSO = (lastPayment[0]?.value / allTotalWitoutPays) * daysInvoiced;
      } else {
        DSO = (allTotal / allTotalWitoutPays) * daysInvoiced;
      }
    }
    return DSO;
  }

  calculateBPDSO(lastPayment: IInvoicePayments[], data: IDetails[], allTotal: number, allTotalWitoutPays: number) {
    let BPDSO = 0, daysInvoiced = 0, totalExpired = 0;
    if (allTotal <= 0 && lastPayment.length > 0) {
      daysInvoiced = this.getTotalDaysInvoiced(
        data,
        new Date(lastPayment[0].cancelled_date.split("T")[0])
      );
      totalExpired = this.getTotalExpired(
        data,
        new Date(lastPayment[0].cancelled_date.split("T")[0])
      );
    } else {
      daysInvoiced = this.getTotalDaysInvoiced(data, this.date);
      totalExpired = this.getTotalExpired(data, this.date);
    }
    if (allTotalWitoutPays === 0) {
      BPDSO = 0;
    } else {
      BPDSO = (totalExpired / allTotalWitoutPays) * daysInvoiced;
    }
    return BPDSO;
  }

  getTotalExpired(data: IDetails[], endDate: Date) {
    let total = 0;
    data.forEach((element) => {
      if (new Date(element.dueDate) > endDate && element.totalPaid !== 0) {
        total = total + element.totalPaid!;
      }
    });
    return total;
  }

  orderDetailsByDate(data: IDetails[]) {
    data.sort((a, b) => {
      if ( new Date(a.createdDate.split("T")[0]).getTime() > new Date(b.createdDate.split("T")[0]).getTime()) {
        return 1;
      }
      if (new Date(a.createdDate.split("T")[0]).getTime() < new Date(b.createdDate.split("T")[0]).getTime()) {
        return -1;
      }
      return 0;
    });
    return data;
  }

  orderPaymentsByDate(data: IInvoicePayments[]) {
    data.sort((a, b) => {
      if (new Date(a.cancelled_date.split("T")[0]).getTime() < new Date(b.cancelled_date.split("T")[0]).getTime()) {
        return 1;
      }
      if (new Date(a.cancelled_date.split("T")[0]).getTime() > new Date(b.cancelled_date.split("T")[0]).getTime()) {
        return -1;
      }
      return 0;
    });
    return data;
  }

  getAllPayments(year: number) {
    return new Promise<IInvoicePayments[]>((resolve, reject) => {
      this.portfolioService
      .getAllArInvoicePayments(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      })
    })
  }

  getAllNotes(year: number) {
    return new Promise<INotasGrap[]>((resolve, reject) => {
      this.portfolioService
      .getAllArNotes(year)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      })
    })
  }

  getAllInvoices(year: number, id_user: number) {
    return new Promise<IQuota[]>((resolve, reject) => {
      this.portfolioService
      .getAllArInvoices(year, id_user)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      })
    })
  }
}
