import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ChartDataSets } from "chart.js";
import { Label, SingleDataSet } from "ng2-charts";
import { AnalysisService } from "../../../analysis.service";
import { ILiterPerClientData } from "src/app/@interfaces/Analysis/liter-per-client.interface";
import { ITopCustomerNotes } from "src/app/@interfaces/Analysis/dollar-per-client.interface";
import { ICompany } from "src/app/@interfaces/company.interface";
import { CalculateAnalysis } from "src/app/@shared/components/services/charts/calculate-analysis.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { ConverseArrayService } from '../../../../../@shared/components/services/data-types/converse-array.service';

@Component({
  selector: "app-liter-per-client-graph",
  templateUrl: "./liter-per-client-graph.component.html",
  styleUrls: ["./liter-per-client-graph.component.css"],
})
export class LiterPerClientGraphComponent implements OnChanges {
  @Input() typePres: string = "All";
  @Input() literPerClientData = {} as ILiterPerClientData;
  @Input() notesByCode: ITopCustomerNotes[] = [];

  literPerClientDataToUse = {} as ILiterPerClientData;
  notesByCodeToUse: ITopCustomerNotes[] = [];

  TopCustomerLitroLabel: Label[] = [];
  TopCustomerLitro: SingleDataSet = [];
  barChartDataSets6: ChartDataSets[] = [
    {
      label: "Cantidad",
      backgroundColor: "#0C3871",
      hoverBackgroundColor: "#0F4D9D",
    },
  ];
  companyObject = {} as ICompany;
  companyId = '';

  constructor(
    private analysis: AnalysisService, 
    private calculateAnalysis: CalculateAnalysis,
    private cookieAuthService: CookieAuthService,
    private converseArrayService: ConverseArrayService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "literPerClientData") {
        const values: ILiterPerClientData = changes[item].currentValue;
        if (values) {
          this.literPerClientDataToUse = values;
        }
      }
      if (item === "notesByCode") {
        const note: Array<ITopCustomerNotes> = changes[item].currentValue;
        if (note.length > 0) {
          this.notesByCodeToUse = note;
        }
      }
      if (this.literPerClientDataToUse.initialData || this.notesByCodeToUse.length > 0) {
        this.topCalculation(this.literPerClientDataToUse, this.notesByCodeToUse);
      }
    }
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  topCalculation(literPerClientData: ILiterPerClientData, notesByCode: ITopCustomerNotes[]) {
    const reduceNotes = this.analysis.reduceNotes(notesByCode);
    const reduceCustomers = this.converseArrayService.reduceArrays(literPerClientData.customersArray);
    const reduceInitialArray = this.analysis.reduceArrayLiters(literPerClientData.initialData);
    const calculatedByElement = this.calculateAnalysis.calculateByElementCustomer(reduceCustomers, reduceInitialArray, reduceNotes, 'item', 'code', 'calculation', 'litros');
    const arrayFiltered = calculatedByElement.arrayFiltered;
    const totals = calculatedByElement.totals;
    arrayFiltered.push({ amount: totals, customer: "Total" });
    this.constructFinalArray(arrayFiltered);
  }

  constructFinalArray(arrayFiltered: any[]) {
    const sortArray = this.calculateAnalysis.constructFinalArray(arrayFiltered, "amount", "customer", 20);
    this.TopCustomerLitroLabel = sortArray.chartLabels;
    this.TopCustomerLitro = sortArray.chartData;
  }

}
