import { IStorageCompany } from './../../@interfaces/company.interface';
import { createAction, props } from "@ngrx/store";

export const loadCompanies = createAction(
  "[Companies List] Load Companies",
  props<{ user: number }>()
);

export const loadedCompanies = createAction(
  "[Companies List] Loaded Success",
  props<{ companies: IStorageCompany[] }>()
);

export const loadCompaniesFailure = createAction(
  "[Companies List] Load Companies Failure",
  props<{ error: any }>()
);
