<h2 mat-dialog-title>{{ 'Reset password' | translate }}</h2>
<mat-dialog-content>
  <header>
    <p>{{ 'Please fill in the fields below to reset your password' | translate }}</p>
  </header>
  <section>
    <form [formGroup]="forgotPasswordForm">
      <div class="mb-4">
        <select
          formControlName="document_type"
          *ngIf="types$ | async as types"
          class="form-control"
        >
          <option value="" selected>{{ 'Select document type' | translate }}</option>
          <option *ngFor="let type of types" [value]="type.id_docs">
            {{ type.description | translate }}
          </option>
        </select>
      </div>
      <div class="mb-4">
        <input
          class="form-control"
          [placeholder]="'Document number' | translate"
          formControlName="document"
        />
      </div>
      <span class="form-text text-danger">{{ errorMessage }}</span>
      <div class="button">
        <button class="button--white mr-4" mat-raised-button mat-dialog-close>{{ 'Cancel' | translate}}</button>
        <button class="button--orange" mat-raised-button (click)="resetPassword()">{{ 'Confirm' | translate}}</button>
      </div>
    </form>
  </section>
</mat-dialog-content>
