import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { MaterialModule } from 'src/app/material.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchModule } from '../search/search.module';
import { FilterUserPipe } from '../../pipe/filter-user.pipe';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FilterSellerPipe } from '../../pipe/filter-seller.pipe';
import { FilterActivitiesPipe } from '../../pipe/filter-activities.pipe';
import { PipesModule } from 'src/app/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TableIdPerPagePipe } from './table-id-per-page.pipe';
import { NoResultsModule } from './no-results/no-results.module';


@NgModule({
  declarations: [
    TableComponent,
    FilterUserPipe,
    TableIdPerPagePipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxPaginationModule,
    SearchModule,
    MatIconModule,
    TranslateModule,
    PipesModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    NoResultsModule
  ],
  exports: [
    TableComponent
  ],
  providers: [
    FilterUserPipe,
    FilterSellerPipe,
    FilterActivitiesPipe
  ]
})
export class TableModule { }
