import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandsPipe'
})
export class ThousandsPipe implements PipeTransform {

  transform(number: any) {
    if (number) {
      const parts = number?.toString().split('.');
      const integerPart = parts[0];
      const decimalPart = parts[1] || '';
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if (decimalPart) {
        return formattedIntegerPart + "," + decimalPart;
      } else {
        return formattedIntegerPart + ',' + '00';
      } 
    }else {
      return '0.00';
    }
  }
}