import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from 'src/app/@interfaces/quotation.interface';

@Pipe({
  name: 'filterPTQuots'
})
export class FilterPTQuots implements PipeTransform {
  transform(quotations: IQuota[], presentation: string): IQuota[] {
    return quotations.map(quotation => ({
      ...quotation,
      venta: quotation.venta.filter(sale => sale.articulo[0].presentations![0].id_type.toString() === presentation)
    })).filter(quotation => quotation.venta.length > 0)
  }
}
