import { Injectable } from "@angular/core";
import * as _html2canvas from "html2canvas";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ApiService } from "src/app/@graphql/services/api.service";
import { Apollo } from "apollo-angular";
import { CREATE_GOAL, SEND_REPORTS_DASHBOARD, UPDATE_GOAL } from "../../@graphql/operations/mutation";
import { map } from "rxjs/internal/operators/map";
import { ImageConverseService } from "../../@shared/components/services/files/imageConverse.service";
import { NgxSpinnerService } from "ngx-spinner";
import { takeUntil } from 'rxjs/operators';
import { Subject } from "rxjs";
import { GET_GOAL } from "src/app/@graphql/operations/query";
import { AlertsService } from "src/app/@shared/components/services/alerts/alerts.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

const html2canvas: any = _html2canvas;
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: "root",
})
export class HomeService extends ApiService {
  date = new Date();
  unsubscribe$ = new Subject();

  constructor(
    apollo: Apollo,
    private imageConverseService: ImageConverseService,
    private spinnerService: NgxSpinnerService,
    private alertsService: AlertsService,
    private cookieAuthService: CookieAuthService
  ) {
    super(apollo);
  }

  getGoals(id_seller: number, type: string, id_type: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_GOAL, { id_seller, type, id_type, company }).pipe(
      map((result: any) => {
        return result.getGoalV1;
      })
    );
  }

  createGoals(
    goal: number,
    id_seller: number | null,
    type: string,
    id_type: number,
    date: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_GOAL, {
      goal,
      id_seller,
      type,
      id_type,
      date,
      company,
    }).pipe(
      map((result: any) => {
        return result.createGoalV1;
      })
    );
  }

  updateGoals(
    goal: number,
    id_seller: number | null,
    type: string,
    id_type: number,
    date: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_GOAL, {
      goal,
      id_seller,
      type,
      id_type,
      date,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateGoalV1;
      })
    );
  }

  sendReportPdf(file: string, copy: string) {
    return this.mutation(SEND_REPORTS_DASHBOARD, { file, copy }).pipe(
      map((result: any) => {
        return result.sendReportDashboardEmail;
      })
    );
  }

  getBase64DOM(img: HTMLElement) {
    return new Promise<string>((resolve, reject) => {
      html2canvas(img).then((res: any) => {
        resolve(res.toDataURL());
      });
    });
  }

  async printScreen(img1: HTMLElement, img2: HTMLElement, img3: HTMLElement, img4: HTMLElement, img5: HTMLElement, img6: HTMLElement, logo: string, copy: string) {
    const baseImage1 = await this.getBase64DOM(img1);
    const baseImage2 = await this.getBase64DOM(img2);
    const baseImage3 = await this.getBase64DOM(img3);
    const baseImage4 = await this.getBase64DOM(img4);
    const baseImage5 = await this.getBase64DOM(img5);
    const baseImage6 = await this.getBase64DOM(img6);
    const pdf = await this.createPDF(
      baseImage1,
      baseImage2,
      baseImage3,
      baseImage4,
      baseImage5,
      baseImage6,
      logo,
      true
    );
    pdf.getBase64((result) => {
      this.sendReportPdf(result, copy).subscribe((res) => {
        this.spinnerService.hide();
      });
      this.spinnerService.hide();
    });
  }

  async createPDF(img1: string, img2: string, img3: string, img4: string, img5: string, img6: string, logo: string, open: boolean) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const companyName = companyObject!.name;
    const pdfDefinition: any = {
      content: [
        {
          table: {
            widths: ["*", "*", "*"],
            heights: [25, 25, 25],
            body: [
              [
                {
                  style: "imageStyle",
                  image: await this.imageConverseService.getBase64ImageFromURL(
                    logo
                  ),
                  width: 110,
                  height: 30,
                  border: [false, false, false, false],
                  alignment: "center",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text:
                    "RESUMEN EJECUTIVO" +
                    "\n" +
                    companyName +
                    "\n" +
                    "Periodo Comprendido del " +
                    "01/01/" +
                    this.date.getFullYear() +
                    " al " +
                    this.date.toLocaleDateString(),
                  alignment: "center",
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ["*"],
            heights: [25],
            body: [
              [
                {
                  image: img1,
                  width: 500,
                  height: 100,
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ["*"],
            heights: [25],
            body: [
              [
                {
                  image: img2,
                  width: 500,
                  height: 150,
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ["*"],
            heights: [25],
            body: [
              [
                {
                  image: img3,
                  width: 500,
                  height: 200,
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ["*"],
            heights: [25],
            body: [
              [
                {
                  image: img4,
                  width: 500,
                  height: 200,
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ["*"],
            heights: [25],
            body: [
              [
                {
                  image: img5,
                  width: 500,
                  height: 500,
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ["*"],
            heights: [25],
            body: [
              [
                {
                  image: img6,
                  width: 500,
                  height: 200,
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true,
          color: "black",
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
        textStyle: {
          fontSize: 7,
          color: "#363636",
          bold: true,
          margin: [0, 15, 0, 15],
        },
        imageStyle: {
          margin: [0, 5, 0, 15],
        },
      },
    };
    const pdf = pdfMake.createPdf(pdfDefinition);
    if (open === true) {
      pdf.open();
    }
    return pdf;
  }

  getGoal(id_seller: number, type: string, id_type: number) {
    return new Promise<number>((resolve, reject) => {
      this.getGoals(id_seller, type, id_type)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        resolve(result.goal)
      }, (error) => {
        reject(0)
      });
    })
  }

  goalError(typeId: number, sellerId: number) {
    if (typeId === null || sellerId === null) {
      this.alertsService.showErrorAlert("Must filter by seller and presentation");
      return false;
    }else {
      return true;
    }
  }
}
