import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from '../../../../@interfaces/quotation.interface';

@Pipe({
  name: 'filterSQuots'
})
export class FilterSQuots implements PipeTransform {
  transform(quotaions: IQuota[], seller: string): IQuota[] {
    return quotaions.filter(quotation => 
      quotation.id_seller?.toString() === seller 
    )
  }
}
