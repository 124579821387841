import { Pipe, PipeTransform } from '@angular/core';
import { IResultProducts } from 'src/app/@interfaces/csv/csv.interface';

@Pipe({
  name: 'filterArticleSellerCsv'
})
export class FilterArticleSellerCsvPipe implements PipeTransform {

  transform(articles: IResultProducts[], item: string,seller:string): IResultProducts[] {
    return articles.filter(articles =>
        articles.cod_barras!.toString().toLocaleLowerCase() === item && 
        articles.cod_vendedor.toString() === seller)
  }
}