import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import {
  GET_PROVIDER,
  GET_ALL_PROVIDER,
  SEARCH_PROVIDER,
  GET_PROVIDER_BY_NIT,
} from "src/app/@graphql/operations/query";
import { ApiService } from "src/app/@graphql/services/api.service";
import { map } from "rxjs/internal/operators/map";
import {
  CREATE_PROVIDER,
  UPDATE_PROVIDER,
} from "src/app/@graphql/operations/mutation";
import {
  GET_ALL_DECLARANT_TYPES,
  GET_SINGLE_DECLARANT_TYPE,
  GET_ALL_REGIME_TYPES,
  GET_SINGLE_REGIME_TYPE,
  GET_DECLARANT_TYPES_BYREGIME,
  GET_ALL_COMPANY_TYPE,
} from "../../@graphql/operations/query";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class ProviderService extends ApiService {

  constructor(apollo: Apollo, private cookieAuthService: CookieAuthService) {
    super(apollo);
  }

  searchProvider(value: string, page: number | null, size: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_PROVIDER, { company, value, page, size }).pipe(
      map((result: any) => {
        return result.searchProvider;
      })
    );
  }

  listProvider(page: number | null, size: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_PROVIDER, { company, page, size }).pipe(
      map((result: any) => {
        return result.getAllProviders;
      })
    );
  }

  getProvider(id_provider: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PROVIDER, {
      id_provider: id_provider,
      company: company,
    }).pipe(
      map((result: any) => {
        return result.getSingleProvider;
      })
    );
  }

  registerProvider(
    doc_nit: string,
    id_docs: number,
    nombre: string,
    direccion: string,
    telefono: string,
    email: string,
    id_country: number,
    city: string,
    id_contributor: number,
    id_regime: number,
    id_declarant: number,
    id_type: number,
    activity_code: number,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_PROVIDER, {
      doc_nit,
      id_docs,
      nombre,
      direccion,
      telefono,
      email,
      id_country,
      city,
      id_contributor,
      id_regime,
      id_declarant,
      id_type,
      activity_code,
      company,
      id_user,
    }).pipe(
      map((result: any) => {
        return result.createProvider;
      })
    );
  }

  updateProvider(
    id_provider: number,
    doc_nit: string,
    id_docs: number,
    nombre: string,
    direccion: string,
    telefono: string,
    email: string,
    id_country: number,
    city: string,
    id_contributor: number,
    id_regime: number,
    id_declarant: number,
    id_type: number,
    activity_code: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_PROVIDER, {
      id_provider,
      doc_nit,
      id_docs,
      nombre,
      direccion,
      telefono,
      email,
      id_country,
      city,
      id_contributor,
      id_regime,
      id_declarant,
      id_type,
      activity_code,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateProvider;
      })
    );
  }

  getAllDeclarantTypes() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_DECLARANT_TYPES, { company: company }).pipe(
      map((result: any) => {
        return result.getAllDeclaratTypes;
      })
    );
  }

  getDeclarantByRegime(id_regime: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_DECLARANT_TYPES_BYREGIME, {
      id_regime: id_regime,
      company: company,
    }).pipe(
      map((result: any) => {
        return result.getDeclarantByRegime;
      })
    );
  }

  getSingleDeclarantType(id_declarant: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_SINGLE_DECLARANT_TYPE, {
      id_declarant: id_declarant,
      company: company,
    }).pipe(
      map((result: any) => {
        return result.getSingleDeclaratType;
      })
    );
  }

  getAllRegimeTypes() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_REGIME_TYPES, { company: company }).pipe(
      map((result: any) => {
        return result.getAllRegimeTypes;
      })
    );
  }

  getSingleRegimeType(id_regime: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_SINGLE_REGIME_TYPE, {
      id_regime: id_regime,
      company: company,
    }).pipe(
      map((result: any) => {
        return result.getSingleRegimeType;
      })
    );
  }

  getAllCompanyTypes() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_COMPANY_TYPE, { company: company }).pipe(
      map((result: any) => {
        return result.getAllCompanyTypes;
      })
    );
  }

  getProviderByNit(document: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PROVIDER_BY_NIT, { document: document, company: company }).pipe(
      map((result: any) => {
        return result.getProviderByNit;
      })
    );
  }
}
