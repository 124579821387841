import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { AuthStatus } from "src/app/@enums/authentication/auth-status.enum";
import { LOGIN } from "src/app/@graphql/operations/mutation";
import { ApiService } from "src/app/@graphql/services/api.service";
import { IUser } from "src/app/@interfaces/user.interface";
import { UsersService } from "src/app/@pages/users/users.service";
import { CookieAuthService } from "../storage-variables/cookie-auth.service";
import { CookieService } from "ngx-cookie-service";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class AuthService extends ApiService {
  private _accesMenu = new BehaviorSubject<boolean | null>(null);
  public _accesMenu$ = this._accesMenu.asObservable();
  private unsubscribe$ = new Subject();

  private _currentUser: BehaviorSubject<IUser | undefined> =
    new BehaviorSubject<IUser | undefined>(undefined);
  private _authStatus: BehaviorSubject<AuthStatus | undefined> =
    new BehaviorSubject<AuthStatus | undefined>(undefined);
  public currentUser$: Observable<IUser | undefined> =
    this._currentUser.asObservable();
  public authStatus$: Observable<AuthStatus | undefined> =
    this._authStatus.asObservable();

  constructor(
    apollo: Apollo,
    private router: Router,
    private userService: UsersService,
    private cookieAuthService: CookieAuthService,
    private cookieService: CookieService,
    private spinnerService: NgxSpinnerService,
  ) {
    super(apollo);
  }

  private syncroAuthValues(user: IUser | undefined, status: AuthStatus) {
    this._currentUser.next(user);
    this._authStatus.next(status);
  }

  async checkAuthStatus() {
    const company = this.cookieAuthService.getCompanyObject;
    const isCompanySelected = !!company?.Id_company;
    const jwt = this.cookieAuthService.getToken;
    if (!jwt) return this.router.navigateByUrl("/login");
    await this.userService
      .me(jwt)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: async (res) => {
          await this.syncroAuthValues(res, AuthStatus.authenticated);
          if (this.router.url === "/login" || this.router.url === "/") {
            return !isCompanySelected
              ? this.router.navigateByUrl("/selectDB")
              : this.router.navigateByUrl(
                  await this.userService.getInitialRouteByRole()
                );
          }
          return;
        },
        error: () => {
          this.logOut();
        },
      });
    return;
  }

  login(email: string, password: string) {
    return this.mutation(LOGIN, { email, password }).pipe(
      map((result: any) => {
        const now = new Date();
        now.setHours(now.getHours() + 8);
        this._currentUser.next(result.login.user);
        this._authStatus.next(AuthStatus.authenticated);
        return result;
      })
    );
  }

  logOut() {
    this.cookieService.delete('userIds', '/');
    this.cookieService.delete('companyItems', '/');
    this.cookieAuthService.resetAuthVariables();
    localStorage.clear();
    this.syncroAuthValues(undefined, AuthStatus.notAuthenticated);
    this.spinnerService.hide();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.router.navigate(["login"]);
  }
}
