import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IContFact } from 'src/app/@interfaces/contfac.interface';
import { IQuotation } from 'src/app/@interfaces/quotation.interface';
import { AuthService } from 'src/app/@shared/authentication/auth.service';
import { QuotationsService } from '../quotations/quotations.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { INotasGrap } from '../../@interfaces/notas.interface';
import { IValoresSingle } from 'src/app/@interfaces/valores.interface';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';

@Component({
  selector: 'app-details-notas',
  templateUrl: './details-notas.component.html',
  styleUrls: ['./details-notas.component.css']
})
export class DetailsNotasComponent implements OnInit, OnDestroy {
  id_notA: number = 0;
  id_quotation: number = 0;
  subtotal : number = 0;
  taxCalc: number = 0;
  discount: number = 0;
  total: number = 0;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = '';
  quotations = {} as IQuotation;
  noteObject = {} as INotasGrap;
  values: IValoresSingle[] = [];
  contFact = {} as IContFact;
  companyId = '';
  unsubscribe$ = new Subject();

  constructor(
    private router: ActivatedRoute, 
    private quotationService: QuotationsService, 
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.setDocument();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId  = company!.Id_company!.toString();
  }

  setDocument(){
    this.router.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.id_quotation = params.id_factura;
      this.id_notA = params.id_nota;
      this.value = 'https://v1-insight.com/#/details_notas/' + this.id_quotation + '/' + this.id_notA;
      this.quotationService.getQuotation(this.id_quotation).pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
        this.quotations = data;
        this.contFact = this.quotations.contfac![0];
        this.quotationService.getSingleNote(parseInt(this.id_notA.toString())).pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
          this.noteObject = result;
          this.values = this.noteObject.valor_notas!;
          this.values.forEach(element => {
            let disco = 0;
            let subtotal = 0;
            let discountedSubtotal = 0;
            let subCalc = 0;
            let calcTax = 0
            let tax = 0;
            if (this.quotations.tax_incl === 'false') {
              disco = (element.descuento / 100) * (element.cantidad * element.precio);
              if (element.articulo[0].tax![0].value === 0) {
                tax = this.quotations.tax / 100;
                subtotal = element.cantidad * element.precio;
                discountedSubtotal = subtotal - disco;
                calcTax = discountedSubtotal * tax;
                subCalc = discountedSubtotal + calcTax;
              } else {
                tax = element.articulo[0].tax![0].value;
                subtotal = element.cantidad * element.precio;
                discountedSubtotal = subtotal - disco;
                calcTax = discountedSubtotal * tax;
                subCalc = discountedSubtotal + calcTax;
              }
            } else if (this.quotations.tax_incl === 'true') {
              tax = element.articulo[0].tax![0].value;
              subtotal = element.cantidad * (element.precio / (tax + 1));
              disco = (element.descuento / 100) * subtotal;
              subtotal = subtotal - disco;
              calcTax = subtotal * tax;
              subCalc = (subtotal + calcTax);
            }
            this.taxCalc += calcTax;
            this.subtotal += subtotal;
            this.discount += disco;
            this.total += subCalc;  
          })
        })
      })
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  print() {
    window.print();
  }
}
