import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { IQuotation } from "src/app/@interfaces/quotation.interface";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { PageEvent } from "@angular/material/paginator";
import { QuotationsService } from "../quotations/quotations.service";
import { AuthService } from "../../@shared/authentication/auth.service";
import { IMailList } from "../../@interfaces/lists.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EventEmitterService } from "src/app/@core/menu/emitterFuncions.service";
import { QuotationsByUserService } from "../quotations/quotations-byuser.service";
import { IPaymentsList } from "../../@interfaces/payments.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.css"],
})
export class PortfolioComponent implements OnInit, OnDestroy {
  lengthBy10 = 0;
  lengthBy30 = 0;
  pageSize = 4;
  pageSizeOptions: number[] = [4, 8, 12];
  pageEvent: PageEvent = {
    length: this.lengthBy10,
    pageIndex: 0,
    pageSize: this.pageSize,
  };

  QuotationListByStatus10: Array<IQuotation> = [];
  QuotationListByStatus30: Array<IQuotation> = [];

  companyId = '';
  user = '';
  rol = '';
  listBanks: Array<IPaymentsList> = [];
  listCashiers: Array<IPaymentsList> = [];
  listMails: Array<IMailList> = [];
  disableButton = false;
  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;

  constructor(
    private eventEmitterService: EventEmitterService,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private quotations: QuotationsService,
    private quotationsByUser: QuotationsByUserService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.spinnerService.show();
    this.data();
    this.validButtons();
    this.toggleExpandCard();
    this.getEvent();
    this.getInvoiceCounts();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
    this.companyId = company!.Id_company!.toString();
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((name: string) => {
      this.toggleExpandCard();
    });
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 250);
  }

  data() {
    if (this.rol === "1" || this.rol === "3") {
      this.quotations
      .getAllInvoicesByStatusInvoiced(10, 0, 4)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.QuotationListByStatus10 = result;
      });
    } else {
      this.quotationsByUser
      .getAllInvoicesByStatusInvoicedByUser(10, 0, 4, parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.QuotationListByStatus10 = result;
      });
    }
  }

  getCountByStatus(estado: number) {
    if (this.rol === "1" || this.rol === "3") {
      return new Promise<number>((resolve, reject) => {
        this.quotations
        .countQuotationsByStatusInvoiced(estado)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: number) => {
          resolve(result);
        });
      });
    } else {
      return new Promise<number>((resolve, reject) => {
        this.quotationsByUser
        .countQuotationsByStatusInvoicedByUser(estado, parseInt(this.user!))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: number) => {
          resolve(result);
        });
      });
    }
  }

  async getInvoiceCounts() {
    this.lengthBy10 = await this.getCountByStatus(10);
    this.pageEvent.length = this.lengthBy10;
    this.lengthBy30 = await this.getCountByStatus(30);
    this.spinnerService.hide();
  }

  validButtons() {
    if (this.rol === "1" || this.rol === "3") {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  openDialogMails(registerMails: boolean, listMails: IMailList[], groupMails: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { registerMails, listMails, groupMails },
    });
  }

  openDialogBanks(registerBanks: boolean, listBanks: IPaymentsList[], groupPayment: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { registerBanks, listBanks, groupPayment },
    });
  }

  openDialogCashiers(registerCashiers: boolean, listCashiers: IPaymentsList[], groupCashier: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { registerCashiers, listCashiers, groupCashier },
    });
  }

  searchQuotations(value: string) {
    if (this.rol === "1" || this.rol === "3") {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotations
        .findQuotations(4, value, this.pageEvent.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    } else {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotationsByUser
        .findQuotationsByUser(4, value, this.pageEvent.pageSize, parseInt(this.user!))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    }
  }

  async handleSearch(value: string, estado: number) {
    if (value) {
      switch (estado) {
        case 10:
          this.QuotationListByStatus10 = await this.searchQuotations(value);
        break;
        case 30:
          this.QuotationListByStatus30 = await this.searchQuotations(value);
        break;
      }
    } else {
      this.setOriginal(estado);
    }
  }

  setOriginal(estado: number) {
    let event = { index: 0 };
    switch (estado) {
      case 10:
        event.index = 0;
        this.selectTab(event);
      break;
      case 30:
        event.index = 1;
        this.selectTab(event);
      break;
    }
  }

  getInvoices(estado: number, pageIndex: number, pageSize: number) {
    if (this.rol === "1" || this.rol === "3") {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotations
        .getAllInvoicesByStatusInvoiced(estado, pageIndex, pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    } else {
      return new Promise<IQuotation[]>((resolve, reject) => {
        this.quotationsByUser
        .getAllInvoicesByStatusInvoicedByUser(estado, pageIndex, pageSize, parseInt(this.user!))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation[]) => {
          resolve(result);
        });
      });
    }
  }

  async capturePage(event: any, estado?: number) {
    switch (estado) {
      case 10:
        this.QuotationListByStatus10 = await this.getInvoices(
          estado,
          event.pageIndex,
          event.pageSize
        );
      break;
      case 30:
        this.QuotationListByStatus30 = await this.getInvoices(
          estado,
          event.pageIndex,
          event.pageSize
        );
      break;
    }
  }

  async selectTab(event: any) {
    switch (event.index) {
      case 0:
        this.pageEvent.length === this.lengthBy10;
        this.QuotationListByStatus10 = await this.getInvoices(
          10,
          0,
          this.pageEvent.pageSize
        );
      break;
      case 1:
        this.pageEvent.length === this.lengthBy30;
        this.QuotationListByStatus30 = await this.getInvoices(
          30,
          0,
          this.pageEvent.pageSize
        );
      break;
    }
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
