  <mat-dialog-content>
    <h2><strong>{{'Companies of' | translate}} {{userModify.name}} </strong></h2>
    <form>
        <table class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>
                        <mat-label>Item</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Company' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Prefix' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Options' | translate}}</mat-label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let companies of userModify.companies; let i = index">
                    <td data-label="Item">
                        {{i+1}}
                    </td>
                    <td data-label="Company">
                        {{companies.name}}
                    </td>
                    <td>
                        <ng-container *ngFor="let prefix of userModify.prefixes">
                            <ng-container *ngIf="prefix.id_company.toString() === companies.Id_company.toString()">
                                {{prefix.prefix}} - {{'Voucher' | translate}}: {{prefix.voucher}}
                            </ng-container>
                        </ng-container>
                    </td>
                    <td data-label="Options">
                        <button (click)="remove(userModify.id ,companies.Id_company)" mat-raised-button>
                             x
                        </button>
                    </td>
                </tr>       
            </tbody>
        </table><br>
        <form [formGroup]="companyForm">
            <div>
                <mat-form-field class="select-container" appearance="fill">
                    <mat-label>{{'Add new company' | translate}}</mat-label>
                        <mat-select (selectionChange)="onOptionSelected($event)" formControlName="companie">
                            <mat-option class="options" *ngFor="let company of companyList" [value]="company">
                            {{company.name}}
                            </mat-option>
                        </mat-select>
                </mat-form-field>
            </div>
            <div>
                <ng-container *ngIf="prefixList.length > 0">
                    <mat-form-field class="select-container" appearance="fill">
                        <mat-label>{{'Add company prefix' | translate}}</mat-label>
                            <mat-select formControlName="prefix">
                                <mat-option class="options" *ngFor="let prefix of prefixList" [value]="prefix">
                                {{prefix.prefix}} - {{'Voucher' | translate}}: {{prefix.voucher}}
                                </mat-option>
                            </mat-select>
                    </mat-form-field>
                </ng-container>
            </div>
            <button mat-raised-button (click)="update()" style="margin-left: 20px; margin-bottom: 20px;">{{'Add' | translate}}</button>
            <button mat-raised-button mat-dialog-close style="margin-left: 20px; margin-bottom: 20px;">{{'Close' | translate}}</button>
        </form>
    </form>
</mat-dialog-content>
