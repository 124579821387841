import { Pipe, PipeTransform } from '@angular/core';
import { IResultTabs } from 'src/app/@interfaces/csv/csv.interface';

@Pipe({
  name: 'filtersellerCsvAccountReceivables'
})
export class FilterSellerCsvAccountReceivablesPipe implements PipeTransform {

  transform(seller: IResultTabs[], searchValue: string): IResultTabs[] {
    return seller.filter(seller =>
        seller.cod_vendedor!.toString().toLocaleLowerCase()===(searchValue.toLocaleLowerCase()))
  }
}