import { Injectable } from "@angular/core";
import { IQuotation } from "src/app/@interfaces/quotation.interface";
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
} from "@angular/forms";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class RegisterSalesService {
  constructor(private translate: TranslateService) {}

  setQuotationData(registerForm: UntypedFormGroup, rowsArr: UntypedFormArray, newCustomer: UntypedFormControl) {
    let setQuotation = {} as IQuotation;
    let total: number = 0;
    setQuotation.tax = registerForm.value.tax;
    setQuotation.cliente = [
      {
        id_cliente: 0,
        nombre: "",
        doc_nit: "",
        direccion: "",
        telefono: "",
        email: "",
        delivery: "",
        country: [],
      },
    ];
    setQuotation.cliente[0].id_cliente = parseInt(newCustomer.value.id_cliente);
    setQuotation.createdAt = registerForm.value.date;
    setQuotation.dueDate = registerForm.value.dueDate;
    setQuotation.seller = [
      { id_seller: 0, nombre: "", doc_nit: "", email: "", country: [] },
    ];
    setQuotation.seller[0] = registerForm.value.seller;
    setQuotation.tax_incl = new Boolean(registerForm.value.tax_incl).toString();
    for (let x = 0; x < rowsArr.value.length; x++) {
      total = total + parseFloat(rowsArr.value[x].subtotal);
    }
    setQuotation.venta = [
      {
        id_venta: [],
        codigo: [],
        cantidad: [],
        precio: [],
        descuento: [],
        subtotal: [],
        id_almacen: [],
        total: [],
      },
    ];
    for (let index = 0; index < rowsArr.value.length; index++) {
      this.validItems(rowsArr.value[index].article.codigo);
      setQuotation.venta[0]?.codigo.push(rowsArr.value[index].article.codigo);
      setQuotation.venta[0]?.cantidad.push(
        parseFloat(rowsArr.value[index].quantity)
      );
      setQuotation.venta[0]?.precio.push(
        parseFloat(rowsArr.value[index].price)
      );
      setQuotation.venta[0]?.descuento.push(
        parseFloat(rowsArr.value[index].discount)
      );
      setQuotation.venta[0]?.subtotal.push(
        parseFloat(rowsArr.value[index].subtotal)
      );
      setQuotation.venta[0]?.id_almacen.push(
        parseInt(rowsArr.value[index].warehouse)
      );
      setQuotation.venta[0]?.total.push(total);
    }
    return setQuotation;
  }

  validItems(item: any) {
    if (!item) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Some of the items do not exist"),
      });
      return;
    }
  }
}
