import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { IWarehouseArticle } from 'src/app/@interfaces/warehouseArticle.interface';
import { InventoryService } from '../../../../../../@pages/inventory/inventory.service';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertsService } from 'src/app/@shared/components/services/alerts/alerts.service';

@Component({
  selector: 'app-update-warehouse-openings',
  templateUrl: './update-warehouse-openings.component.html',
  styleUrls: ['./update-warehouse-openings.component.css']
})
export class UpdateWarehouseOpeningsComponent implements OnInit, OnDestroy {
  @Input() opening = {} as IWarehouseArticle;
  openingForm = new UntypedFormGroup({
    value: new UntypedFormControl(0),
    quantity: new UntypedFormControl(0),
  });
  currentQuantity = 0;
  unsubscribe$ = new Subject();
  constructor(
    private inventoryService: InventoryService,
    private alertsService: AlertsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.setOpening();
  }

  setOpening() {
    this.openingForm.patchValue({
      value: this.opening.value,
      quantity: this.opening.quantity,
    })
    this.currentQuantity = this.opening.quantity;
  }

  textValid(text: string) {
    return (
      this.openingForm.get(text)?.invalid && this.openingForm.get(text)?.touched
    );
  }

  update() {
    this.spinner.show();
    this.inventoryService.updateWarehouseOpening(
      this.opening.id_warehouse_article,
      parseFloat(this.openingForm.value.quantity),
      parseFloat(this.openingForm.value.value),
    ).pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.spinner.hide();
      this.currentQuantity = parseFloat(this.openingForm.value.quantity);
      this.alertsService.showSuccessfullAlert("The opening was correctly updated")
      .then((confirm) => {
        if (confirm.isConfirmed) {
          this.alertsService.refresh("/dialog", "/inventory");
        }
      })
    }, (error) => {
      this.spinner.hide();
      this.alertsService.showErrorAlert("Something was wrong")
      .then((confirm) => {
        console.error(error);
      });
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
