
<mat-dialog-content>
    <form [formGroup]="sellerForm">
        <div class="row justify-content-start">
            <h3><strong>{{'Register Seller' | translate}}</strong></h3>
        </div>
        <div class="row">
            <div class="col-md-4">
                <span>{{'Document Type' | translate}}:</span>
                <select class="form-control" formControlName="types_docs">
                    <option selected value="">{{'Select Type' | translate}}</option>
                    <option *ngFor="let types of documenTypeList" [ngValue]="types">
                        {{types.description}}
                    </option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('types_docs')">{{'You must select a type' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Document Or Nit' | translate}}:</span>
                <input class="form-control" placeholder="{{'Document/Nit' | translate}}" formControlName="doc_nit">
                <span class="form-text text-danger" *ngIf="textValid('doc_nit')">{{'The document must be wrote' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Name' | translate}}:</span>
                <input class="form-control" placeholder="{{'Name' | translate}}" formControlName="name">
                <span class="form-text text-danger" *ngIf="textValid('name')">{{'The name must be wrote' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Email' | translate}}:</span>
                <input class="form-control" placeholder="{{'Email' | translate}}" formControlName="email">
                <span class="form-text text-danger" *ngIf="textValid('email')">{{'The email is not a valid' | translate}}</span>
            </div>
            <div class="col-md-4">
                <span> {{'Country' | translate}}:</span>
                <select class="form-control" formControlName="country">
                    <option selected value="">{{'Select Country' | translate}}</option>
                    <option *ngFor="let country of countryList" [ngValue]="country">{{country.name}}</option>
                </select>
                <span class="form-text text-danger" *ngIf="textValid('country')">{{'The country is not valid' | translate}}</span>
            </div>
        </div>
        <div class="row">
        <div class="row" style="padding-left: 20px; margin-top:10px; margin-bottom: 10px;" id="buttons-div">
            <div class="col" id="buttons">
                <button (click)="register()" mat-raised-button>{{'Register' | translate}}</button>
            </div>
            <div class="col" id="buttons">
                <button mat-raised-button [mat-dialog-close]>{{'Cancel' | translate}}</button>
            </div>
        </div>
    </div>
    </form>
    </mat-dialog-content>
    
    <ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)" >
        <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
    </ngx-spinner>
    