import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerRoutingModule } from './customer-routing.module';
import { CustomerComponent } from './customer.component';
import { TableModule } from 'src/app/@shared/components/table/table.module';
import { SearchModule } from 'src/app/@shared/components/search/search.module';
import { MaterialModule } from 'src/app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [CustomerComponent],
  imports: [
    CommonModule,
    CustomerRoutingModule,
    TableModule,
    SearchModule,
    MaterialModule,
    MatIconModule,
    DialogModule,
    TranslateModule,
    NgxSpinnerModule
  ]
})
export class CustomerModule { }
