import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { IArticles } from "src/app/@interfaces/article.interface";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { InventoryService } from "src/app/@pages/inventory/inventory.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.css"],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() filtro: UntypedFormControl = this.fb.control('', Validators.required);;
  @Input() currentWarehouse: number = 0;
  registerForm: UntypedFormGroup = this.fb.group({
    article: [, Validators.required]
  });
  ArticleList: Array<IArticles> = [];
  companyId = '';
  unsubscribe$ = new Subject();

  constructor(
    private articleS: InventoryService,
    public dialog: MatDialog,
    public quotation: QuotationsService,
    public warehouseS: WarehouseService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<FilterComponent>,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.getInputValues();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  getInputValues() {
    this.registerForm
    .get("article")
    ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
    .subscribe((value: any) => {
      if (typeof value === "string") {
        this.articleS
        .searchArticle(value, parseInt(this.currentWarehouse?.toString()), null, null)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.ArticleList = result.articles;
        });
      }
    });
  }

  displayFnArticle(article: any) {
  return article && article
    ? article.descripcion +
      new String(` - (`) +
      article.tax[0].value +
      new String(`)`)
    : undefined;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
