import { Pipe, PipeTransform } from "@angular/core";
import { IMovementInventory } from "src/app/@interfaces/movement-inventory.interface";

@Pipe({
  name: "filterInventoryToAccounting",
})
export class FilterInventoryToAccountingPipe implements PipeTransform {
  transform(
    inventoryMovements: IMovementInventory[],
    invoice: number,
    article: string,
    documentType: string
  ): number {
    return inventoryMovements
      .filter((item) =>
        item.id_invoice?.toString() === invoice?.toString() &&
        item.reference.toString() === article!.toString() &&
        item.document[0] === documentType
      )
      .map(element => element.unitary).shift() || 0
  }
}
