import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-meter",
  templateUrl: "./meter.component.html",
  styleUrls: ["./meter.component.css"],
})
export class MeterComponent implements OnChanges {
  @Input() value = 0;

  color = "";

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const values: number = changes.value.currentValue;
    if (values >= 0 && values < 75) {
      this.color = "#C00000";
    }
    if (values > 75 && values < 120) {
      this.color = "#EED503";
    }
    if (values > 120 && values <= 180) {
      this.color = "#2EBF00";
    }
    if (values > 180) {
      this.color = "#2EBF00";
    }
  }

  customizeText(arg: any) {
    return `${arg.valueText} %`;
  }

  customizeTooltip(arg: any) {
    return {
      text: `${arg.valueText} Lts`,
    };
  }
}
