import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { ProcessNotesService } from "../../../../../@shared/components/services/charts/process-notes.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { CalculateAnalysis } from '../../../../../@shared/components/services/charts/calculate-analysis.service';
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { ConverseArrayService } from '../../../../../@shared/components/services/data-types/converse-array.service';

@Component({
  selector: "app-liter-per-month-graph",
  templateUrl: "./liter-per-month-graph.component.html",
  styleUrls: ["./liter-per-month-graph.component.css"],
})
export class LiterPerMonthGraphComponent implements OnChanges {
  @Input() orderCustomerQuots: Array<IQuota> = [];
  @Input() notas: Array<INotasGrap> = [];
  @Input() typePres: string = "All";

  allQuotations: Array<IQuota> = [];
  allNotes: Array<INotasGrap> = [];
  companyObject = {} as IStorageCompany;

  barChartData: ChartDataSets[] = [
    {
      label: "Values",
      borderColor: "#0086C0",
      pointStyle: "rectRounded",
      pointRadius: 7,
      radius: 7,
      backgroundColor: "#0C3871",
      hoverBackgroundColor: "#0F4D9D",
    },
  ];

  barChartLabels: Label[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  constructor(
    private analysis: ProcessNotesService, 
    private calculateAnalysis: CalculateAnalysis,
    private cookieAuthService: CookieAuthService,
    private converseArrayService: ConverseArrayService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "orderCustomerQuots") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.allQuotations = values;
        }
      }
      if (item === "notas") {
        const values: Array<INotasGrap> = changes[item].currentValue;
        if (values.length > 0) {
          this.allNotes = values;
        }
      }

      if (this.allQuotations.length > 0 || (this.allNotes.length > 0)) {
        this.calculation(this.allQuotations, this.allNotes);
      }
    }
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  calculation(invoices: IQuota[], notes: INotasGrap[]) {
    const filterInvoices = this.calculateAnalysis.filterInvoices(invoices);
    const currentJanuary = this.getByMonthCurrentYear(filterInvoices, 1, notes);
    const currentFebruary = this.getByMonthCurrentYear(filterInvoices, 2, notes);
    const currentMarch = this.getByMonthCurrentYear(filterInvoices, 3, notes);
    const currentApril = this.getByMonthCurrentYear(filterInvoices, 4, notes);
    const currentMay = this.getByMonthCurrentYear(filterInvoices, 5, notes);
    const currentJune = this.getByMonthCurrentYear(filterInvoices, 6,notes);
    const currentJuly = this.getByMonthCurrentYear(filterInvoices, 7, notes);
    const currentAugust = this.getByMonthCurrentYear(filterInvoices, 8, notes);
    const currentSeptember = this.getByMonthCurrentYear(filterInvoices, 9, notes);
    const currentOctober = this.getByMonthCurrentYear(filterInvoices, 10, notes);
    const currentNovember = this.getByMonthCurrentYear(filterInvoices, 11, notes);
    const currentDecember = this.getByMonthCurrentYear(filterInvoices, 12, notes);

    this.barChartData[0].data = [
      parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentFebruary.toFixed(2)),
      parseFloat(currentMarch.toFixed(2)),
      parseFloat(currentApril.toFixed(2)),
      parseFloat(currentMay.toFixed(2)),
      parseFloat(currentJune.toFixed(2)),
      parseFloat(currentJuly.toFixed(2)),
      parseFloat(currentAugust.toFixed(2)),
      parseFloat(currentSeptember.toFixed(2)),
      parseFloat(currentOctober.toFixed(2)),
      parseFloat(currentNovember.toFixed(2)),
      parseFloat(currentDecember.toFixed(2)),
    ];
  }

  getByMonthCurrentYear(invoices: IQuota[], month: number, notes: INotasGrap[]): number {
    const invoiceValues = this.getInvoiceValues(invoices, month);
    const compareNumbers = this.converseArrayService.reduceArrays(invoiceValues.invoiceIds);
    const liters = this.getNoteValues(notes, compareNumbers);
    const totalLiters = invoiceValues.liters + liters * 1;
    return totalLiters;
  }

  getInvoiceValues(invoices: IQuota[], month: number) {
    const filteredInvoices = invoices.filter(invoice => (
      invoice.estado === 4 && 
      new Date(invoice.invoiced_date || invoice.createdAt).getMonth() + 1 === month
    ));
    const invoiceIds = filteredInvoices.map(invoice => invoice.id_factura!);
    const liters = filteredInvoices.reduce((totalLiters, invoice) => {
      return totalLiters + invoice.venta.reduce((subTotal, sale) => (
        subTotal + sale.cantidad * parseFloat(sale.articulo[0].presentacion!)
      ), 0);
    }, 0);
    return {
      invoiceIds,
      liters,
    };
  }

  getNoteIds(notes: INotasGrap[], compareNumbers: number[]) {
    let noteIds: number[] = [];
    notes.forEach((item) => {
      compareNumbers.forEach((number) => {
        if (item.note_fact === parseInt(number.toString())) {
          noteIds.push(parseInt(item.id_nota!.toString()));
        }
      });
    });
    return noteIds;
  }

  getNoteValues(notes: INotasGrap[], compareNumbers: number[]) {
    let liters = 0;
    const noteIds = this.getNoteIds(notes, compareNumbers);
    if (noteIds.length > 0) {
      const noteValues = this.analysis.getNotesByDescription(notes, noteIds);
      liters = noteValues.reduce((accumulator, vals) => {
        return accumulator + vals.litros;
      }, 0);
    }
    return liters;
  }
}
