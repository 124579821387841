import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  URL_CSV = "https://v-onerestphp.v-onerotate.com//csv";

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  }

  constructor(private http: HttpClient) { }

  handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  postToken(pass: any) {
    return this.http.post<any>(this.URL_CSV, pass, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  getTabs(token: string, tabla: string, query: string) {
    let httpData = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    }
    return this.http.get<any>(this.URL_CSV+`?token=${token}&tabla=${tabla}&query=${query}`, httpData)
    .pipe(
      catchError(this.handleError)
    );
  }
  getTabs2(token: string, tabla: string, query: string, date1:string, date2:string) {
    let httpData = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    }
    return this.http.get<any>(this.URL_CSV+`?token=${token}&tabla=${tabla}&query=${query}&date1=${date1}&date2=${date2}`, httpData)
    .pipe(
      catchError(this.handleError)
    );
  }
}
