<mat-dialog-content>
<form [formGroup]="registerForm">
    <h2><strong>{{'Register User' | translate}}</strong></h2>
    <div class="text-margin">
        <span><strong>{{'Name' | translate}}:</strong></span>
        <input class="form-control" type="text" placeholder="{{'Full name' | translate}}" formControlName="name">
        <span class="form-text text-danger" *ngIf="textValid('name')">{{'You must type only a name and a lastname' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Email' | translate}}:</strong></span>
        <input class="form-control" type="text" placeholder="{{'Email' | translate}}" formControlName="email">
        <span class="form-text text-danger" *ngIf="textValid('email')">{{'You must type a valid email' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Document Type' | translate}}:</strong></span>
        <select class="form-control" formControlName="types_docs">
            <option selected value="">{{'Select Type' | translate}}</option>
            <option *ngFor="let types of documenTypeList" [ngValue]="types">
                {{types.description}}
            </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('types_docs')">{{'You must select a type' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Document' | translate}}:</strong></span>
        <input maxlength="12" class="form-control" type="text" placeholder="{{'Document' | translate}}" formControlName="document">
        <span class="form-text text-danger" *ngIf="textValid('document')">{{'You must type only numbers' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Username' | translate}}</strong></span>
        <input class="form-control" type="text" placeholder="{{'Username' | translate}}" formControlName="username">
    </div>
    <div class="text-margin">
        <span><strong>{{'Rol' | translate}}:</strong></span>
        <select class="form-control" formControlName="rol">
            <option selected value="">{{'Select rol' | translate}}</option>
            <option *ngFor="let rol of rolList" [ngValue]="rol">
                {{rol.name}}
            </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('rol')">{{'You must select a rol' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Company' | translate}}:</strong></span>
        <select (change)="onOptionSelected()" class="form-control" formControlName="company">
            <option selected value="">{{'Select company' | translate}}</option>
            <option *ngFor="let company of companyList" [ngValue]="company">
                {{company.name}}
            </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('company')">{{'You must select a company' | translate}}</span>
    </div>
    <div class="text-margin" *ngIf="prefixList.length > 0">
        <span><strong>{{'Prefix' | translate}}:</strong></span>
        <select class="form-control" formControlName="prefix">
            <option selected value="">{{'Select prefix' | translate}}</option>
            <option *ngFor="let prefix of prefixList" [ngValue]="prefix">
                {{prefix.prefix}} - {{'Voucher' | translate}}: {{prefix.voucher}}
            </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('company')">{{'You must select a prefix' | translate}}</span>
    </div>
    <div class="text-margin">
        <span><strong>{{'Password' | translate}}:</strong></span>
        <input class="form-control" type="password" placeholder="{{'Password' | translate}}" formControlName="password">
    </div>
    <div class="text-margin">
        <span><strong>{{'Confirm Password' | translate}}:</strong></span>
        <input class="form-control" type="password" placeholder="{{'Confirm Password' | translate}}" formControlName="passwordConfirmation">
        <span class="form-text text-danger" *ngIf="textValid('passwordConfirmation')">{{'Passwords must be equals' | translate}}</span>
    </div>
    <div class="row" style="padding-left: 20px; margin-top:10px;" id="buttons-div">
        <div class="col" id="buttons">
            <button (click)="register()" mat-raised-button>{{'Register' | translate}}</button>
        </div>
        <div class="col" id="buttons">
            <button mat-raised-button [mat-dialog-close]>{{'Cancel' | translate}}</button>
        </div>
    </div>
</form>

</mat-dialog-content>
