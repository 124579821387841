import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryMovementsComponent } from './inventory-movements.component';
import { InventoryMovementsRoutingModule } from './inventory-movements-routing.module';
import { TableModule } from 'src/app/@shared/components/table/table.module';
import { MaterialModule } from 'src/app/material.module';
import { SearchModule } from 'src/app/@shared/components/search/search.module';
import { DialogModule } from '@angular/cdk/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NoResultsModule } from 'src/app/@shared/components/table/no-results/no-results.module';



@NgModule({
  declarations: [
    InventoryMovementsComponent
  ],
  imports: [
    CommonModule,
    InventoryMovementsRoutingModule,
    TableModule,
    MaterialModule,
    SearchModule,
    DialogModule,
    MatIconModule,
    TranslateModule,
    NgxSpinnerModule,
    NoResultsModule
  ]
})
export class InventoryMovementsModule { }
