import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/@graphql/services/api.service";
import {
  CREATE_SELLER,
  CREATE_USER_SELLER,
  UPDATE_SELLER,
} from "src/app/@graphql/operations/mutation";
import {
  GET_SINGLE_SELLER,
  GET_SELLERS,
  GET_SELLER_BYNIT,
} from "../../@graphql/operations/query";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class SellersService extends ApiService {
  constructor(
    apollo: Apollo,
    private cookieAuthService: CookieAuthService
  ) {
    super(apollo);
  }

  registerSellers(
    doc_nit: string,
    id_docs: number,
    nombre: string,
    email: string,
    id_country: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_SELLER, {
      doc_nit,
      id_docs,
      nombre,
      email,
      id_country,
      company,
    }).pipe(
      map((result: any) => {
        return result.createSeller;
      })
    );
  }

  registerUserSeller(id_user: number, id_seller: number, company: string) {
    return this.mutation(CREATE_USER_SELLER, {
      id_user,
      id_seller,
      company,
    }).pipe(
      map((result: any) => {
        return result.createUserSeller;
      })
    );
  }

  registerSellersByUser(
    doc_nit: string,
    id_docs: number,
    nombre: string,
    email: string,
    id_country: number,
    company: string
  ) {
    return this.mutation(CREATE_SELLER, {
      doc_nit,
      id_docs,
      nombre,
      email,
      id_country,
      company,
    }).pipe(
      map((result: any) => {
        return result.createSeller;
      })
    );
  }

  updateSellers(
    id_seller: number,
    doc_nit: string,
    id_docs: number,
    nombre: string,
    email: string,
    id_country: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_SELLER, {
      id_seller,
      doc_nit,
      id_docs,
      nombre,
      email,
      id_country,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateSeller;
      })
    );
  }

  getOneSeller(id_seller: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_SINGLE_SELLER, {
      id_seller: id_seller,
      company,
    }).pipe(
      map((result: any) => {
        return result.getSingleSeller;
      })
    );
  }

  getSellerByNit(document: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_SELLER_BYNIT, {
      document: document,
      company: company,
    }).pipe(
      map((result: any) => {
        return result.getSellerByNit;
      })
    );
  }

  listSellers() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_SELLERS, { company }).pipe(
      map((result: any) => {
        return result.getAllSellers;
      })
    );
  }
}
