<div class="demo-container">
    <dx-chart
        id="chart"
        [class]="isFirstLevel ? 'pointer-on-bars' : ''"
        [dataSource]="dataSource!"
        (onPointClick)="onPointClick($event, data)"
        [customizePoint]="customizePoint">
    <dxi-series type="bar"></dxi-series>
    <dxo-legend [visible]="false"></dxo-legend>
    <dxi-value-axis [showZero]="false"></dxi-value-axis>
    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" [zIndex]="5000">
    </dxo-tooltip>
    </dx-chart>
</div>

<div id="drill-down-title">
    <button (click)="onButtonClick(data)"  mat-raised-button>{{'Back' | translate}}</button>
</div>
