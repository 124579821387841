import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ProcessReportsDataService {
  constructor() {}

  splitSiigoID(id: string) {
    if (!/^\d+$/.test(id)) {
      return null;
    }
    const line = id.slice(0, 3);
    const group = id.slice(3, 7);
    const articleId = id.slice(7);
    return {
      line: line,
      group: group,
      articleId: articleId,
    };
  }
}
