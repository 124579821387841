import { Injectable } from "@angular/core";
import { CodifyAuthVariablesService } from "./codify-auth-variables.service";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { AppState } from "src/app/state/app,state";
import { Store } from "@ngrx/store";
import { loadCompanies } from "src/app/state/actions/user-companies.actions";

@Injectable({
  providedIn: "root",
})
export class CookieAuthService {
  private userIdKey = environment.userIdKey;
  private rolIdKey = environment.rolIdKey;
  private userSellerIdKey = environment.userSellerIdKey;

  private userId?: string | null;
  private rolId?: string | null;
  private token?: string | null;
  private userSellerId?: string | null;
  private companyObject?: IStorageCompany | null;

  get getUserId() {
    return this.userId;
  }

  get getRolId() {
    return this.rolId;
  }

  get getToken() {
    return this.token;
  }

  get getUserSellerId() {
    return this.userSellerId;
  }

  get getCompanyObject() {
    return this.companyObject;
  }

  constructor(
    private codifyAuthVariablesService: CodifyAuthVariablesService,
    private cookieService: CookieService,
    private store: Store<AppState>
  ) {}

  private getSecureVariables() {
    const secureFlag = environment.production;
    const now = new Date();
    now.setHours(now.getHours() + 8);
    return { secureFlag: secureFlag, now: now };
  }

  setSessionStorageUserIds(userId: number, rolId: number, token: string) {
    const secureVariables = this.getSecureVariables();
    const idsObject = {
      userId: this.codifyAuthVariablesService!.setCodifyItem(
        userId.toString(),
        this.userIdKey
      ),
      rolId: this.codifyAuthVariablesService!.setCodifyItem(
        rolId.toString(),
        this.rolIdKey
      ),
      token: token,
    };
    this.cookieService.set("userIds", JSON.stringify(idsObject), {
      expires: secureVariables.now,
      secure: secureVariables.secureFlag,
      sameSite: "Strict",
    });
  }

  setLocalStorageCompany(company: IStorageCompany, userSellerId: string) {
    const secureVariables = this.getSecureVariables();
    const companyItems = {
      userSellerId: this.codifyAuthVariablesService!.setCodifyItem(
        userSellerId,
        this.userSellerIdKey
      ),
      companyObject: JSON.stringify(company),
    };
    this.cookieService.set("companyItems", JSON.stringify(companyItems), {
      expires: secureVariables.now,
      secure: secureVariables.secureFlag,
      sameSite: "Strict",
    });
  }

  //User Object

  setUserId() {
    const cookieItem = this.cookieService.get("userIds");
    if (cookieItem) {
      const sessionObject = JSON.parse(cookieItem);
      this.userId = this.codifyAuthVariablesService!.getDecodedItem(
        sessionObject.userId,
        this.userIdKey
      );
      this.store.dispatch(loadCompanies({ user: parseInt(this.userId!) }));
    } else {
      this.userId = null;
    }
  }

  setRolId() {
    const cookieItem = this.cookieService.get("userIds");
    if (cookieItem) {
      const sessionObject = JSON.parse(cookieItem);
      this.rolId = this.codifyAuthVariablesService!.getDecodedItem(
        sessionObject.rolId,
        this.rolIdKey
      );
    } else {
      this.rolId = null;
    }
  }

  setToken() {
    const cookieItem = this.cookieService.get("userIds");
    if (cookieItem) {
      const sessionObject = JSON.parse(cookieItem);
      this.token = sessionObject.token;
    } else {
      this.token = null;
    }
  }

  //Company Object

  setUserSellerId() {
    const cookieItem = this.cookieService.get("companyItems");
    if (cookieItem) {
      const sessionObject = JSON.parse(cookieItem);
      this.userSellerId = this.codifyAuthVariablesService!.getDecodedItem(
        sessionObject.userSellerId,
        this.userSellerIdKey
      );
    } else {
      this.userSellerId = null;
    }
  }

  setCompanyObject() {
    const cookieItem = this.cookieService.get("companyItems");
    if (cookieItem) {
      const sessionObject = JSON.parse(cookieItem);
      this.companyObject = JSON.parse(sessionObject.companyObject);
    } else {
      this.companyObject = null;
    }
  }

  //Reset

  resetAuthVariables() {
    this.userId = null;
    this.rolId = null;
    this.token = null;
    this.userSellerId = null;
    this.companyObject = null;
  }
}
