import { Component, Input, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { IArticles } from "src/app/@interfaces/article.interface";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { IUser } from "src/app/@interfaces/user.interface";
import { DialogComponent } from "../dialog/dialog.component";
import { FilterUserPipe } from "../../pipe/filter-user.pipe";
import { ISeller } from "../../../@interfaces/seller.interface";
import { FilterSellerPipe } from "../../pipe/filter-seller.pipe";
import { CustomerService } from "../../../@pages/customer/customer.service";
import { Subject } from "rxjs";
import { InventoryService } from "../../../@pages/inventory/inventory.service";
import { first, takeUntil } from 'rxjs/operators';
import { IProvider } from "../../../@interfaces/provider.interface";
import { ProviderService } from "../../../@pages/provider/provider.service";
import { TableService } from "./table.service";
import { IActivitiesCiiu } from "../../../@interfaces/activitiesciiu.interface";
import { FilterActivitiesPipe } from "../../pipe/filter-activities.pipe";
import { ITreasury } from "src/app/@interfaces/treasury.interface";
import { TreasuryService } from "../../../@pages/treasury/treasury.service";
import { ITransfers } from "src/app/@interfaces/transfers.interface";
import { IWarehouseOutputAndEntry, IWarehouseTransfer } from "src/app/@interfaces/warehouse.interface";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { IInventoryReclassification } from "src/app/@interfaces/inventoryReclassification.interface";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { UsersService } from "src/app/@pages/users/users.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CookieAuthService } from '../../storage-variables/cookie-auth.service';

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatSort) sort: MatSort | any;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @Input() showTable: number = 0;
  @Input() user: Array<IUser> = [];
  @Input() inventory: Array<IArticles> = [];
  @Input() customer: Array<ICustomer> = [];
  @Input() seller: Array<ISeller> = [];
  @Input() provider: Array<IProvider> = [];
  @Input() activitiesCiiu: Array<IActivitiesCiiu> = [];
  @Input() advancePayments: Array<ITreasury> = [];
  @Input() transfers: Array<ITransfers> = [];
  @Input() warehouseTransfers: Array<IWarehouseTransfer> = [];
  @Input() warehouseOutputsAndEntries: Array<IWarehouseOutputAndEntry> = [];
  @Input() inventoryReclassifications: IInventoryReclassification[] = [];
  @Input() length = 0;
  @Input() types: any[] = [];
  @Input() orderType: string = "";
  @Input() orderField: string = "";
  @Input() showProspects: boolean = false;
  @Output() dataUpdated = new EventEmitter<boolean>(false);
  @Output() typeWarehouseAndEntries = new EventEmitter<object>();
  isRoleAdmin: boolean = false
  isRoleFullOperator: boolean = false
  filterValue = "";
  page: number = 1;
  tableUser: boolean = false;
  inventoryTable: boolean = false;
  deletePermission: boolean = false;
  firstUser: Array<IUser> = [];
  firstSeller: Array<ISeller> = [];
  showNull: boolean = false;
  firstActivitiesCiiu: Array<IActivitiesCiiu> = [];
  typeForm: UntypedFormControl = this.fb.control(1, Validators.required);
  pageSize = 15;
  pageSizeOptions: number[] = [15, 30, 50, 100];
  pageEvent: PageEvent = {
    length: this.length,
    pageIndex: 0,
    pageSize: this.pageSize,
  };

  currentPageIndex: PageEvent = {
    length: this.length,
    pageIndex: 0,
    pageSize: this.pageSize,
  };
  companyId = '';
  userId = '';
  rol = '';
  userSeller = '';
  unsubscribe$ = new Subject();

  constructor(
    private dialog: MatDialog,
    private filterUser: FilterUserPipe,
    private filterSeller: FilterSellerPipe,
    private customerService: CustomerService,
    private providerService: ProviderService,
    private inventoryService: InventoryService,
    private tableService: TableService,
    private filterActivities: FilterActivitiesPipe,
    private treasuryService: TreasuryService,
    private warehouseService: WarehouseService,
    private fb: UntypedFormBuilder,
    private userService: UsersService,
    private spinnerService: NgxSpinnerService,
    private changeDetectorRef: ChangeDetectorRef,
    private cookieAuthService: CookieAuthService
  ) {}
  
  async ngOnInit() {
    this.getAuthValues();
    this.deletePermission = this.rol === '1' ? true : false;
    this.typeForm.setValue(this.types[0]);
    this.handleInput();
    this.isRoleAdmin = await this.userService.isRoleAdmin()
    this.isRoleFullOperator = await this.userService.isRoleFullOperator();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.userId = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
    this.userSeller = this.cookieAuthService.getUserSellerId!;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.paginator?.pageSize) {
      this.paginator.pageSize = 15
      this.paginator.pageIndex = 0;
    }
  }

  setOrder(field: string) {
    this.orderField = field;
    if (this.orderType === "DESC") {
      this.orderType = "ASC";
    }else {
      this.orderType = "DESC";
    }
    this.order();
  }

  
  order() {
    switch(this.showTable) {
      case 3:
        this.inventoryService
        .listArticles(
          this.currentPageIndex.pageIndex, 
          this.currentPageIndex.pageSize, 
          this.orderField, this.orderType
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.inventory = result.articles;
        });
      break;
    }
  }

  
  handleInput() {
    this.typeForm.valueChanges.subscribe((value) => {
      if (value.value) {
        this.typeOutputAndEntries(value.id, this.currentPageIndex.pageIndex, this.currentPageIndex.pageSize)
      }
    })
  }

  typeOutputAndEntries(newValue: number, page: number, size: number) {
    this.typeWarehouseAndEntries.emit({newValue, page, size})
  }

  deactiveUser(id_user: number) {
    this.tableService.deactiveUser(id_user);
  }

  activateUser(id_user: number) {
    this.tableService.activateUser(id_user);
  }

  deleteAdvancePayments(id: number): void {
    this.tableService.deleteAdvancePayment(id);
  }

  deleteTransfers(id: number): void {
    this.tableService.deleteTransfer(id);
  }

  deleteWarehouseTransfer(
    id: number, transfer: IWarehouseTransfer
  ) {
    this.tableService.deleteWarehouseTransfer(id, transfer);
  }

  deleteWarehouseOutputOrEntry(
    id: number, movement: IWarehouseOutputAndEntry
  ) {
    this.tableService.deleteWarehouseOutputOrEntry(id, movement);
  }

  deleteInventoryReclassification(
    id: number, reclassification: IInventoryReclassification
  ) {
    this.tableService.deleteWInventoryReclassification(id, reclassification);
  }

  dialogModifyUser(modifyUser: boolean, id_user: number): void {
    this.dialog.open(DialogComponent, {
      data: { modifyUser, id_user },
    });
  }

  dialogModifyCustomer(modifyCustomer: boolean, id_customer: number): void {
    this.dialog.open(DialogComponent, {
      data: { modifyCustomer, id_customer },
    }).afterClosed().pipe(first()).subscribe((res) => {
      if (res) {
        this.dataUpdated.emit(true);
      }
    });
  }

  dialogModifyProvider(modifyProvider: boolean, id_provider: number): void {
    this.dialog.open(DialogComponent, {
      data: { modifyProvider, id_provider },
    });
  }

  dialogModifyActivity(modifyActivity: boolean, id_activity: number): void {
    this.dialog.open(DialogComponent, {
      data: { modifyActivity, id_activity },
    });
  }

  dialogModifySeller(modifySeller: boolean, id_seller: number): void {
    this.dialog.open(DialogComponent, {
      data: { modifySeller, id_seller },
    });
  }

  dialogModifyArticle(modifyArticle: boolean, id_article: number) {
    parseInt(id_article.toString());
    this.dialog.open(DialogComponent, {
      data: { modifyArticle, id_article },
    });
  }

  dialogDetailsArticle(detailsArticle: boolean,  article: IArticles) {
    this.dialog.open(DialogComponent, {
      data: { detailsArticle, article },
    });
  }

  handleSearch(value: string) {
    this.filterValue = value;
    if (value) {
      this.filter(this.paginator);
    } else {
      this.capturePage(this.paginator, this.showTable)
    }
  }

  filter(paginator: PageEvent) {
    this.spinnerService.show();
    let filter: IUser[] | ISeller[] | IActivitiesCiiu[] = [];
    this.currentPageIndex = paginator ? paginator : this.currentPageIndex;
    switch (this.showTable) {
      case 1:
        filter = this.filterUser.transform(this.firstUser, this.filterValue);
        this.user = filter;
        if (this.filter.length <= 0) {
          this.showNull = true;
        }
      break;
      case 2:
        if (this.rol === "1" || this.rol === "3") {
          if (this.showProspects) {
            this.customerService
            .searchProspect(
              this.filterValue,
              this.currentPageIndex.pageIndex, 
              this.currentPageIndex.pageSize)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((result: { count: number, customers: ICustomer[]}) => {
                this.customer = result.customers;
                this.length = result.count;
                this.spinnerService.hide();
                this.changeDetectorRef.detectChanges();
              });
          } else {
            this.customerService
            .searchClient(this.filterValue, this.currentPageIndex.pageIndex, this.currentPageIndex.pageSize)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result: { count: number, customers: ICustomer[]}) => {
              this.customer = result.customers;
              this.length = result.count;
              this.spinnerService.hide();
              this.changeDetectorRef.detectChanges();
            });
          }
        } else {
              this.customerService
              .searchCustomerByUserSeller(
                this.filterValue,
                parseInt(this.userSeller!),
                this.currentPageIndex.pageIndex, 
                this.currentPageIndex.pageSize
              )
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((result: { count: number, customers: ICustomer[]}) => {
                this.customer = result.customers;
                this.length = result.count;
                this.spinnerService.hide();
                this.changeDetectorRef.detectChanges();
              });
          }
      break;
      case 3:
        this.inventoryService
        .searchArticle(this.filterValue, null, this.currentPageIndex.pageIndex, this.currentPageIndex.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.inventory = result.articles;
          this.length = result.count;
          this.spinnerService.hide();
          this.changeDetectorRef.detectChanges();
        });
      break;
      case 4:
        filter = this.filterSeller.transform(
          this.firstSeller,
          this.filterValue
        );
        this.seller = filter;
        if (this.filter.length <= 0) {
          this.showNull = true;
        }
      break;
      case 5:
        this.providerService
        .searchProvider(this.filterValue, this.currentPageIndex.pageIndex, this.currentPageIndex.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.provider = result.providers;
          this.length = result.count;
          this.spinnerService.hide();
          this.changeDetectorRef.detectChanges();
        });
      break;
      case 6:
        filter = this.filterActivities.transform(
          this.firstActivitiesCiiu,
          this.filterValue
        );
        this.activitiesCiiu = filter;
        if (this.filter.length <= 0) {
          this.showNull = true;
        }
      break;
      case 7:
        this.treasuryService
        .searchAdvencePayments(this.filterValue, this.currentPageIndex.pageIndex, this.currentPageIndex.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.advancePayments = result.treasury;
          this.length = result.count;
          this.spinnerService.hide();
          this.changeDetectorRef.detectChanges();
        });
      break;
      case 8:
        this.treasuryService
        .searchTranfers(this.filterValue)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.transfers = result.transfers;
          this.length = result.count;
          this.spinnerService.hide();
          this.changeDetectorRef.detectChanges();
        });
      break;
      case 9:
        this.warehouseService
        .searchWarehouseTranfers(this.filterValue, this.currentPageIndex.pageIndex, this.currentPageIndex.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.warehouseTransfers = result.transfers;
          this.length = result.count
          this.spinnerService.hide();
          this.changeDetectorRef.detectChanges();
        });
      break;
      case 10:
        this.warehouseService
        .searchWarehouseOutputsAndEntries(
          this.filterValue, parseInt(this.typeForm.value.id),
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.warehouseOutputsAndEntries = result.warehouseOutputEntries;
          this.length = result.count;
          this.spinnerService.hide();
          this.changeDetectorRef.detectChanges();
        });
      break;
      case 11:
        this.warehouseService
        .searchInventoryReclassifications(this.filterValue)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.inventoryReclassifications = result.inventoryReclassification;
          this.length = result.count;
          this.spinnerService.hide();
          this.changeDetectorRef.detectChanges();
        });
      break;
      }
  }

  capturePage(event: PageEvent, table: number) {
    this.spinnerService.show();
    this.currentPageIndex = event ? event : this.currentPageIndex;
    const pagination = this.currentPageIndex
    switch (table) {
      case 2:
        if (this.rol === "1" || this.rol === "3") {
          this.customerService
          .listCustomers(pagination.pageIndex, pagination.pageSize, this.showProspects)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result: { count: number, customers: ICustomer[]}) => {
            this.length = result.count;
            this.customer = result.customers;
            this.changeDetectorRef.detectChanges();
            this.spinnerService.hide();
          });
        } else {
          this.customerService
          .listCustomersByUserSeller(
            pagination.pageIndex,
            pagination.pageSize,
            parseInt(this.userSeller!),
            this.showProspects
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result: { count: number, customers: ICustomer[]}) => {
            this.length = result.count;
            this.customer = result.customers;
            this.changeDetectorRef.detectChanges();
            this.spinnerService.hide();
          });
        }
      break;
      case 3:
        this.inventoryService
        .listArticles(
          pagination.pageIndex, pagination.pageSize,
          this.orderField, this.orderType
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: { count: number, articles: IArticles[]}) => {
          this.inventory = result.articles;
          this.length = result.count;
          this.changeDetectorRef.detectChanges();
          this.spinnerService.hide();
        });
      break;
      case 5:
        this.providerService
        .listProvider(pagination.pageIndex, pagination.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: { count: number, providers: IProvider[]}) => {
          this.provider = result.providers;
          this.length = result.count;
          this.changeDetectorRef.detectChanges();
          this.spinnerService.hide();
        });
      break;
      case 7:
        this.treasuryService
        .getAllAdvencePayments(pagination.pageIndex, pagination.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: { count: number, treasury: ITreasury[]}) => {
          this.advancePayments = result.treasury;
          this.length = result.count;
          this.changeDetectorRef.detectChanges();
          this.spinnerService.hide();
        });
      break;
      case 8:
        this.treasuryService
        .getAllTransfers(pagination.pageIndex, pagination.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: { count: number, transfers: ITransfers[]}) => {
          this.transfers = result.transfers;
          this.length = result.count;
          this.changeDetectorRef.detectChanges();
          this.spinnerService.hide();
        });
      break;
      case 9:
        this.warehouseService
        .getAllWarehouseTransfers(pagination.pageIndex, pagination.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: { count: number, transfers: IWarehouseTransfer[]}) => {
          this.warehouseTransfers = result.transfers;
          this.length = result.count;
          this.changeDetectorRef.detectChanges();
          this.spinnerService.hide();
        });
      break;
      case 10:
        this.warehouseService
        .getAllWarehouseOutputsAndEntries(
          parseInt(this.typeForm.value.id),
          pagination.pageIndex, pagination.pageSize
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: { count: number, warehouseOutputEntries: IWarehouseOutputAndEntry[]}) => {
          this.warehouseOutputsAndEntries = result.warehouseOutputEntries;
          this.length = result.count;
          this.changeDetectorRef.detectChanges();
          this.spinnerService.hide();
        });
      break;
      case 11:
        this.warehouseService
        .getAllInventoryReclassifications(pagination.pageIndex, pagination.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: { count: number, inventoryReclassification: IInventoryReclassification[] }) => {
          this.inventoryReclassifications = result.inventoryReclassification;
          this.length = result.count;
          this.changeDetectorRef.detectChanges();
          this.spinnerService.hide();
        });
      break;
    }
  }

  displayFnType(type: any) {
    return type && type ? type.value : undefined;
  }

  getTotalArticleQuantities(article: IArticles) {
    return article.warehouse_values?.reduce((value, item) => 
      value + item.avaliable_quantity!, 0
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
