<mat-tab-group #tabs mat-align-tabs="center" dynamicHeight="true">
    <mat-tab label="{{'Warehouse Transfers' | translate}}">
        <div class="table-container">
            <div class="register-button">
                <a><button mat-raised-button class="Warn" (click)="openTransferDialog()"> {{ "Transfers" | translate }} </button></a>
            </div>
            <div *ngIf="warehouseTransfers.length > 0">
                <app-table [warehouseTransfers]="warehouseTransfers" [showTable]="9" [length]="lenght[0]" ></app-table>
            </div> 
        </div>
    </mat-tab>
    <mat-tab label="{{'Inventory Outputs And Entries' | translate}}" *ngIf="isRoleAdmin" >
        <div class="table-container">
            <div class="register-button">
                <a><button mat-raised-button class="Warn" (click)="openOutputOrEntryDialog()">{{'Register' | translate}} {{ "Outputs Or Entries" | translate }} </button></a>
            </div>
            <div>
                <app-table [types]="types" (typeWarehouseAndEntries)="updateDataCounts($event)" [warehouseOutputsAndEntries]="warehouseOutputsAndEntries" [showTable]="10" [length]="lenght[1]" *ngIf="isRoleAdmin"></app-table>
            </div> 
        </div>
    </mat-tab>
    <mat-tab label="{{'Inventory Reclassifications' | translate}}">
        <div class="table-container">
            <div class="register-button">
                <a><button mat-raised-button class="Warn" (click)="openReclassificationDialog()">{{'Register' | translate}} {{ "Reclassification" | translate }} </button></a>
            </div>
            <div *ngIf="inventoryReclassifications.length > 0">
                <app-table [inventoryReclassifications]="inventoryReclassifications" [showTable]="11" [length]="lenght[2]"></app-table>
            </div> 
        </div>
    </mat-tab>
</mat-tab-group>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
