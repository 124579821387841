import { Component, OnInit, OnDestroy } from "@angular/core";
import { IActivitiesCiiu } from "../../@interfaces/activitiesciiu.interface";
import { takeUntil, startWith, map } from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import { TributaryService } from "./tributary.service";
import { DialogComponent } from "../../@shared/components/dialog/dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "../../@shared/authentication/auth.service";
import { ICities } from "../../@interfaces/cities.interface";
import {
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-tributary",
  templateUrl: "./tributary.component.html",
  styleUrls: ["./tributary.component.css"],
})
export class TributaryComponent implements OnInit, OnDestroy {
  activitiesList: IActivitiesCiiu[] = [];
  firstActivitiesList: IActivitiesCiiu[] = [];
  citiesList: ICities[] = [];
  unsubscribe$ = new Subject();
  companyObject = {} as IStorageCompany;
  citiesForm: UntypedFormControl = this.fb.control("", Validators.required);
  filteredOptionsCities: Observable<ICities[]> | undefined;

  constructor(
    private tributaryService: TributaryService,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.authService.checkAuthStatus();
    this.getAuthValues();
    this.filteredOptionsCities = this.citiesForm.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterC(value))
    );
    const country_id = this.companyObject.countries[0].id_country;
    this.tributaryService
    .getCitiesByCompany(parseInt(country_id!.toString()))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.citiesList = result;
    });
    if (this.companyObject.countries[0].name === "Colombia") {
      this.tributaryService
      .getActivitiesByCity(1)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.activitiesList = result;
        this.firstActivitiesList = result;
        this.spinnerService.hide();
      });
    }
    this.updateDataByCity();
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  updateDataByCity() {
    this.citiesForm.valueChanges.subscribe((result) => {
      if (typeof result === "object") {
        this.tributaryService
        .getActivitiesByCity(parseInt(result.id_city))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.activitiesList = result;
        });
      }
      if (!result) {
        this.activitiesList = this.firstActivitiesList;
      }
    });
  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      data: { registerActivity: true },
    });
  }

  displayFnCity(city: any) {
    return city && city ? city.name : undefined;
  }

  _filterC(value: string): ICities[] {
    const filterValueC = value.toString().toLowerCase();
    return this.citiesList.filter((option) =>
      option.name.toLowerCase().toString().includes(filterValueC)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
