import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from '../../../../@interfaces/quotation.interface';

@Pipe({
  name: 'filterFullQuots'
})
export class FilterFullQuots implements PipeTransform {
  transform(quotations: IQuota[], searchValue: string): IQuota[] {
    if (!quotations || !searchValue) {
        return quotations;
    }
    return quotations.filter(quotation => 
        quotation.cliente[0].nombre?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        quotation.seller![0].nombre?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    }
}

