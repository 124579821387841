import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortfolioComponent } from './portfolio.component';
import { ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { PortfolioRoutingModule } from './portfolio-routing.module';
import { SearchModule } from 'src/app/@shared/components/search/search.module'; 
import { CardModule } from 'src/app/@shared/components/card/card.module'; 
import { FilterQuotPipe } from 'src/app/@shared/pipe/filter-quot.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [PortfolioComponent],
  imports: [
    CommonModule,
    PortfolioRoutingModule,
    ReactiveFormsModule,
    MatIconModule,
    DialogModule,
    MaterialModule,
    TranslateModule,
    SearchModule,
    CardModule,
    NgxSpinnerModule
  ],
  providers: [
    FilterQuotPipe
  ]
})
export class PortfolioModule { }
