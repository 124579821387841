import { Injectable, EventEmitter, OnDestroy } from '@angular/core';    
    
@Injectable({    
  providedIn: 'root'    
})    
export class EventEmitterService implements OnDestroy { 
    
  invokeFirstComponentFunction = new EventEmitter();    
  invokeCompanyChange = new EventEmitter();  
  invokeGetItemList = new EventEmitter();
  invokeRegisterFromList = new EventEmitter();
  invokeEachItem = new EventEmitter();
  invokeRenderBullet = new EventEmitter();
  invokePaymentStatus = new EventEmitter();
  invokeCompletePayment = new EventEmitter();
  constructor() { }    
    
  onFirstComponentButtonClick() {    
    this.invokeFirstComponentFunction.emit();    
  }

  onCompanyChange() {    
    this.invokeCompanyChange.emit();    
  }

  onGetItemsFromList() {
    this.invokeGetItemList.emit();
  }

  onRegisterFromList() {
    this.invokeRegisterFromList.emit();
  }

  onSelectEachItem(items: any[], type?: string) {
    if (type) {
      this.invokeEachItem.emit({items: items, type: type});
    }else {
      this.invokeEachItem.emit(items);
    }
  }

  onChangeCarousel() {
    this.invokeRenderBullet.emit();
  }

  onUpdatePaymentStatus() {
    this.invokePaymentStatus.emit();
  }

  onCompletePayments() {
    this.invokeCompletePayment.emit();
  }

  ngOnDestroy(): void {
    this.invokeCompletePayment.next();
    this.invokeCompletePayment.complete();
    this.invokePaymentStatus.next();
    this.invokePaymentStatus.complete();
  }
}   