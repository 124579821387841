import { Injectable } from "@angular/core";
import { BillyCities } from "src/app/@interfaces/Billy/billy.interface";
import { BillyService } from "src/app/@pages/billyService/billy.service";
import { CitiesService } from "src/app/@pages/cities/cities.service";

@Injectable({
  providedIn: "root",
})
export class CitiesFormService {
  constructor(
    private citiesService: CitiesService,
    private billyService: BillyService
  ) {}

  setInitialCitiesFromStore() {
    const countries = ["United States", "Argentina"];
    this.getMainCountryCities(countries);
  }

  getMainCountryCities(countries: string[]) {
    const citiesPreloaded = localStorage.getItem(countries[0]);
    if (citiesPreloaded) return;
    countries.forEach((country) => {
      this.citiesService.postCities(country).subscribe((res) => {
        localStorage.setItem(country, JSON.stringify(res.data));
      });
    });
  }

  async getCityList(country: any) {
    let cityList = JSON.parse(localStorage.getItem(country.name)!);
    if (!cityList) {
      cityList = await this.getOtherCities(country.name);
    }
    return cityList;
  }

  getOtherCities(nameCountrySelected: string) {
    return new Promise<string[]>((resolve, reject) => {
      this.citiesService.postCities(nameCountrySelected).subscribe((result) => {
        resolve(result.data);
      });
    });
  }

  getBillyCities(countrySelected: string) {
    return new Promise<BillyCities>((resolve, reject) => {
      this.billyService
        .getCities(parseInt(countrySelected))
        .subscribe((result) => {
          if (result.data.length !== 0) {
            resolve(result);
          }
        });
    });
  }
}
