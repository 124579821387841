import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class ErrorsService {
  constructor() {}

  getErrorFromJson(error: any) {
    const jsonString = JSON.stringify(error);
    return JSON.parse(jsonString);
  }
}
