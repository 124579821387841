import { gql } from "apollo-angular";
import { NOTE_OBJECT, QUOTATIONS_OBJECT } from "./fragment";

export const GET_ALLHOME_INVOICES = gql`
    query getAllHomeInvoices($startDate: String, $endDate: String, $id_user: ID, $company: String) {
        getAllHomeInvoices(startDate: $startDate, endDate: $endDate, id_user: $id_user, company: $company) {
            ...QuotationsObject
        }
    }
    ${QUOTATIONS_OBJECT}
`;

export const GET_ALL_GRAPHIC_NOTES = gql`
    query getAllGraphicNotes($company: String) {
        getAllGraphicNotes(company: $company) {
            ...NoteObject
        }
    }
    ${NOTE_OBJECT}
`;

export const GET_INVOICES_THIS_MONTH = gql`
    query getQuotToday($id_user: ID, $company: String) {
        getQuotToday(id_user: $id_user, company: $company) {
            ...QuotationsObject
        }
    }
    ${QUOTATIONS_OBJECT}
`;

export const GET_ALLANALYSIS_INVOICES = gql`
    query getAllAnalysisInvoices($startDate: String, $endDate: String, $id_user: ID, $company: String) {
        getAllAnalysisInvoices(startDate: $startDate, endDate: $endDate, id_user: $id_user, company: $company) {
            ...QuotationsObject
        }
    }
    ${QUOTATIONS_OBJECT}
`