import { Component, Input, OnInit } from '@angular/core';
import { INotasGrap } from 'src/app/@interfaces/notas.interface';
import { IQuota } from 'src/app/@interfaces/quotation.interface';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';

@Component({
  selector: 'app-graph-v1',
  templateUrl: './graph-v1.component.html',
  styleUrls: ['./graph-v1.component.css']
})
export class GraphV1Component implements OnInit {

  @Input() quotTodayV1: Array<IQuota> = [];
  @Input() quots: Array<IQuota> = [];
  @Input() notas: Array<INotasGrap> = [];
  @Input() numCustomers: number = 0;
  @Input() months: number = 0;
  @Input() id_type: number | null = null;
  @Input() id_seller: number | null = null;
  @Input() monthTarjet: number = 0;
  @Input() yearTarjet: number = 0;
  @Input() typePres: string = "All";
  companyId = '';
  disableButton = false;

  constructor(
    private cookieAuthService: CookieAuthService
  ) { }

  ngOnInit(): void {
    this.getAuthValues();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }
}
