import { Injectable } from "@angular/core";
import {
  IResultProducts,
  IResultTabs,
} from "src/app/@interfaces/csv/csv.interface";
import { FilterCustomerSellerCsvAccountPipe } from "src/app/@shared/pipe/pipes-csv/account-receivables/filter-customer-seller.pipe";
import { FilterSellerCsvAccountReceivablesPipe } from "src/app/@shared/pipe/pipes-csv/account-receivables/filter-seller.pipe";
import { FilterArticleCsvPipe } from "src/app/@shared/pipe/pipes-csv/filter-article-csv.pipe";
import { FilterArticleSellerCsvPipe } from "src/app/@shared/pipe/pipes-csv/filter-article-seller-csv.pipe";
import { FilterCustArticleSellerCsvPipe } from "src/app/@shared/pipe/pipes-csv/filter-cust-article-seller-csv.pipe";
import { FilterCustomerArticleCsvPipe } from "src/app/@shared/pipe/pipes-csv/filter-customer-article-csv.pipe";
import { FilterCustomerCsvPipeAmount } from "src/app/@shared/pipe/pipes-csv/filter-customer-csv-amount.pipe";
import { FilterCustomerCsvPipe } from "src/app/@shared/pipe/pipes-csv/filter-customer-csv.pipe";
import { FilterCustomerSellerCsvPipe } from "src/app/@shared/pipe/pipes-csv/filter-customer-seller-csv.pipe";
import { FilterSellerCsvPipe } from "src/app/@shared/pipe/pipes-csv/filter-seller-csv.pipe";

@Injectable({
  providedIn: "root",
})
export class AnalysisCsvService {
  constructor(
    private customerCsv: FilterCustomerCsvPipe,
    private customerCsvAmount: FilterCustomerCsvPipeAmount,
    private articleCsv: FilterArticleCsvPipe,
    private sellerCsv: FilterSellerCsvPipe,
    private customerArticleCsv: FilterCustomerArticleCsvPipe,
    private customerSellerCsv: FilterCustomerSellerCsvPipe,
    private articleSellerCsv: FilterArticleSellerCsvPipe,
    private custArticleSellerCsv: FilterCustArticleSellerCsvPipe,
    private sellerAccountReceivables: FilterSellerCsvAccountReceivablesPipe,
    private customerSellerAccount: FilterCustomerSellerCsvAccountPipe
  ) {}

  filterAmount(
    docNit: string,
    data: IResultProducts[],
    codigoArt: string,
    codSeller: string
  ) {
    let dataFilter: Array<IResultProducts> = [];
    let filter: Array<IResultProducts> = [];
    if (docNit && codigoArt && codSeller) {
      filter = this.custArticleSellerCsv.transform(data, codigoArt, docNit, codSeller);
      dataFilter = this.validFilterAmount(filter, dataFilter);
    } else if (docNit && codigoArt) {
      filter = this.customerArticleCsv.transform(data, codigoArt, docNit);
      dataFilter = this.validFilterAmount(filter, dataFilter);
    } else if (docNit && codSeller) {
      filter = this.customerSellerCsv.transform(data, codSeller, docNit);
      dataFilter = this.validFilterAmount(filter, dataFilter);
    } else if (codigoArt && codSeller) {
      filter = this.articleSellerCsv.transform(data, codigoArt, codSeller);
      dataFilter = this.validFilterAmount(filter, dataFilter);
    } else if (docNit) {
      filter = this.customerCsvAmount.transform(data, docNit);
      dataFilter = this.validFilterAmount(filter, dataFilter);
    } else if (codigoArt) {
      filter = this.articleCsv.transform(data, codigoArt);
      dataFilter = this.validFilterAmount(filter, dataFilter);
    } else if (codSeller) {
      filter = this.sellerCsv.transform(data, codSeller);
      dataFilter = this.validFilterAmount(filter, dataFilter);
    } else {
      dataFilter = data;
    }
    return dataFilter;
  }

  validFilterAmount(filter: IResultProducts[], dataFilter: IResultProducts[]){
    return filter.length === 0 ? dataFilter = this.csvAmountNull() : dataFilter = filter;
  }

  filterAccountReceivables(
    data: IResultTabs[],
    docNit: string,
    codSeller: string
  ) {
    let dataFilter: Array<IResultTabs> = [];
    let filter: Array<IResultTabs> = [];
    if (docNit && codSeller) {
      filter = this.customerSellerAccount.transform(data, codSeller, docNit);
      if (filter.length === 0) {
        dataFilter = this.csvNull();
      } else {
        dataFilter = filter;
      }
    } else if (docNit) {
      filter = this.customerCsv.transform(data, docNit);
      if (filter.length === 0) {
        dataFilter = this.csvNull();
      } else {
        dataFilter = filter;
      }
    } else if (codSeller) {
      filter = this.sellerAccountReceivables.transform(data, codSeller);
      if (filter.length === 0) {
        dataFilter = this.csvNull();
      } else {
        dataFilter = filter;
      }
    } else {
      dataFilter = data;
    }
    return dataFilter;
  }

  orderByAmount(data: Array<IResultProducts>) {
    return data.sort(function (a, b) {
      if (a.cantidad < b.cantidad) {
        return 1;
      }
      if (a.cantidad > b.cantidad) {
        return -1;
      }
      return 0;
    });
  }

  csvAmountNull(): IResultProducts[] {
    const amount: IResultProducts[] = [
      {
        tipo_comprobante: "Sin Información",
        cantidad: 0,
        nit: 0,
        grupo_pdto: 0,
        cod_pdto: 0,
        cod_vendedor: 0,
        descripcion: "Sin Información",
        cod_barras: "Sin Información",
        cliente: "Sin Información",
        mes_doc: 0,
      },
    ];
    return amount;
  }

  csvNull(): IResultTabs[] {
    const csv: IResultTabs[] = [
      {
        val_secuencia: 0,
        nit: 0,
        nombre: "Sin Información",
        cod_vendedor: 0,
      },
    ];
    return csv;
  }
  
}
