import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { MaterialModule } from 'src/app/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FilterDPurchase } from 'src/app/@shared/pipe/purchase-pipes/date.pipe';
import { ReportsService } from './reports.service';
import { FilterInventoryToAccountingPipe } from 'src/app/@shared/pipe/pipes-csv/inventory-to-accounting.pipe';
import { ExcelDatePipe } from 'src/app/@shared/pipe/excel-date.pipe';
import { OperationalReportingComponent } from './pages/operational-reporting/operational-reporting.component';
import { FinancialReportsComponent } from './pages/financial-reports/financial-reports.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes.module';
import { DateFilterModule } from 'src/app/@shared/components/date-filter/date-filter.module';
import { SiigoTableModule } from 'src/app/@shared/components/export-excel/structures/chart-accounts/siigo/siigo-table/siigo-table.module';
import { ProcessingComponent } from './pages/processing/processing.component';
import { ServicesModule } from 'src/app/@shared/components/services/services.module';

@NgModule({
  declarations: [ReportsComponent, ExcelDatePipe, OperationalReportingComponent, FinancialReportsComponent, ProcessingComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    TranslateModule,
    DialogModule,
    MaterialModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    PipesModule,
    DateFilterModule,
    SiigoTableModule,
    ServicesModule
  ],
  providers: [
    ReportsService,
    FilterDPurchase,
    FilterInventoryToAccountingPipe,
    ExcelDatePipe,
    DatePipe
  ]
})
export class ReportsModule { }
