import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CitiesFilterService {
  constructor() {}

  filterCities(value: string, cityList: string[]): string[] {
    const filterValue = value?.toString().toLowerCase();
    return cityList
      ? cityList
          .filter((option) =>
            option.toLowerCase().toString().includes(filterValue)
          )
          .slice(0, 10)
      : [];
  }
}
