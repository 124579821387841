import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseCardComponent } from './purchase-card.component';

import { MaterialModule } from 'src/app/material.module';
import { StepperModule } from '../stepper/stepper.module';
import { RouterModule } from '@angular/router';
import { SearchModule } from '../search/search.module';
import { MatIconModule } from '@angular/material/icon';

import { MatCardModule} from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    PurchaseCardComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    StepperModule,
    RouterModule,
    SearchModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    TranslateModule,
    FormsModule,
    NgxSpinnerModule,
    FlexLayoutModule
  ],
  exports: [PurchaseCardComponent]
})
export class PurchaseCardModule { }
