<mat-dialog-content>
  <div class="row justify-content-md-center">
    <div class="row justify-content-md-center">
      <div class="col col-md-3">
        <a (click)="exportExcel(customersList)"
          ><button mat-raised-button class="Warn">
            {{ "Export" | translate }}
          </button></a
        >
      </div>
      <div class="col col-md-4">
        <a (click)="filter(customersList)"
          ><button mat-raised-button class="Warn">
            {{ "Preview" | translate }}
          </button></a
        >
      </div>
      <div class="col col-md-3">
        <a (click)="closeDialog()"
          ><button mat-raised-button class="Warn">
            {{ "Close" | translate }}
          </button></a
        >
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col-md-auto">
      <mat-form-field>
        <mat-label>{{ "Select seller" | translate }}</mat-label>
        <ng-container>
          <input
            id="input-top"
            type="text"
            matInput
            [matAutocomplete]="autoSeller"
            [formControl]="sellerForm"
          />
        </ng-container>
        <mat-autocomplete
          #autoSeller="matAutocomplete"
          [displayWith]="displayFnSeller"
        >
          <mat-option
            *ngFor="let seller of filteredOptionsSeller | async"
            [value]="seller"
          >
            {{ seller.nombre }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-md-auto">
      <a (click)="clean()"
        ><button mat-raised-button class="Warn">
          {{ "Clean" | translate }}
        </button></a
      >
    </div>
  </div>

  <div *ngIf="arrayExcel.length > 0">
    <table id="season-tble" class="table table-striped">
        <tr>
            <td>{{companyName}}</td>
        </tr>
        <tr>
            <th>{{'Document/Nit' | translate}}</th>
            <th *ngIf="this.companyId === '7' || this.companyId === '9'">{{'Verification digit' | translate}}</th>
            <th>{{'Name' | translate}}</th>
            <th>{{'Address' | translate}}</th>
            <th>{{'Phone' | translate}}</th>
            <th>{{'Email' | translate}}</th>
            <th>{{'Seller' | translate}}</th>
        </tr>
        <tr *ngFor="let customer of arrayExcel">
            <td>{{customer.doc_nit}}</td>
            <td *ngIf="this.companyId === '7' || this.companyId === '9'">{{customer.verification_digit}}</td>
            <td>{{customer.nombre}}</td>
            <td>{{customer.direccion}}</td>
            <td>{{customer.telefono}}</td>
            <td>{{customer.email}}</td>
            <ng-container *ngFor="let seller of customer.seller">
                <td>{{seller.nombre}}</td>
            </ng-container>
        </tr>
    </table>
  </div>
</mat-dialog-content>
