import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { ChartDataSets } from "chart.js";
import { Label, SingleDataSet } from "ng2-charts";
import { Subject } from "rxjs";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { Data } from "../../components/multiple-pane/multiple-pane.component";
import * as businessDays from "../../../../@core/menu/utils/getBusinessDays";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-quot-today",
  templateUrl: "./quot-today.component.html",
  styleUrls: ["./quot-today.component.css"],
})
export class QuotTodayComponent implements OnInit, OnChanges, OnDestroy {
  @Input() quotToday: Array<IQuota> = [];

  dataVentasTodayLabel: Label[] = [];
  dataVentasToday: SingleDataSet = [];
  barChartDataSets: ChartDataSets[] = [
    {
      label: "Total $",
      backgroundColor: "#0C3871",
      hoverBackgroundColor: "#0F4D9D",
    },
  ];

  barChartDataInv: SingleDataSet = [];
  barChartInvLabel: Label[] = [];
  barChartDataSets5: ChartDataSets[] = [
    {
      label: "Total $",
      backgroundColor: "#0C3871",
      hoverBackgroundColor: "#0F4D9D",
    },
  ];

  barChartDataDel: SingleDataSet = [];
  barChartDelLabel: Label[] = [];
  barChartDataSets6: ChartDataSets[] = [
    {
      label: "Total $",
      backgroundColor: "#0C3871",
      hoverBackgroundColor: "#0F4D9D",
    },
  ];

  barChartDataCants1: Data[] = [];
  barChartDataCants2: Data[] = [];
  barChartDataCants3: Data[] = [];

  quots: Array<IQuota> = [];
  companyId = '';
  unsubscribe$ = new Subject();

  currentMonth: any = "";

  constructor(private cookieAuthService: CookieAuthService) {}

  ngOnInit(): void {
    const currentDate = new Date();
    this.currentMonth = currentDate.getMonth();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "quotToday") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.quots = values;
        }
      }
      if (this.quots.length > 0) {
        this.calcQuotTodayV1(this.quots);
      }
    }
  }

  calcQuotTodayV1(quot: IQuota[]) {
    let labelVacio: Label[] = [];
    let dataVacio: SingleDataSet = [];

    let labelVacio1: Label[] = [];
    let dataVacio1: SingleDataSet = [];

    let labelVacio2: Label[] = [];
    let dataVacio2: SingleDataSet = [];

    let dataVacio3: Data[] = [];
    let dataVacio4: Data[] = [];
    let dataVacio5: Data[] = [];

    let arrayCant1: string[] = [];
    let arrayCant2: string[] = [];
    let arrayCant3: string[] = [];

    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let created;
    let invoice_date;
    let delivery_date;
    const datesArray: Date[] = [];
    const datesArray2: Date[] = [];

    quot.forEach((item) => {
      created = new Date(item.createdAt);
      if (item.invoiced_date) {
        invoice_date = new Date(item.invoiced_date!);
      } else {
        invoice_date = created;
      }
      if (item.delivery_date) {
        delivery_date = new Date(item.delivery_date!);
      } else {
        delivery_date = created;
      }
      if (item.estado === 4 && invoice_date.getMonth() + 1 === month && invoice_date.getFullYear() === year) {
        //Invoices
        const { total } = item.venta[0];
        dataVacio.push(total);
        if (item?.contfac) {
          labelVacio.push(item.contfac[0].invoice);
        }
        datesArray.push(invoice_date);
      }
      if ((item.estado === 1 || item.estado === 3 || item.estado === 4) && created.getMonth() + 1 === month && created.getFullYear() === year) {
        //Quotations
        labelVacio1.push(item.num_fac);
        const { total } = item.venta[0];
        dataVacio1.push(total);
        arrayCant1.push(
          created.getFullYear() +
            "/" +
            (created.getMonth() + 1) +
            "/" +
            created.getDate()
        );
      }
      if ((item.estado === 3 || item.estado === 4) && delivery_date.getMonth() + 1 === month && delivery_date.getFullYear() === year) {
        //Delivery
        const { total } = item.venta[0];
        dataVacio2.push(total);
        if (item?.contfac) {
          labelVacio2.push(item.contfac[0].delivery);
        }
        datesArray2.push(delivery_date);
      }
    });

    const sortDates = datesArray.sort((a, b) => a.getTime() - b.getTime());
    const sortDates2 = datesArray2.sort((a, b) => a.getTime() - b.getTime());
    sortDates.forEach((element) => {
      arrayCant3.push(
        element.getFullYear() +
          "/" +
          (element.getMonth() + 1) +
          "/" +
          element.getDate()
      );
    });
    sortDates2.forEach((element) => {
      arrayCant2.push(
        element.getFullYear() +
          "/" +
          (element.getMonth() + 1) +
          "/" +
          element.getDate()
      );
    });
    let elemento = [];
    let repetidos = [];
    let contador = 1;

    for (let index = 0; index < arrayCant1.length; index++) {
      if (arrayCant1[index + 1] === arrayCant1[index]) {
        contador++;
      } else {
        elemento.push(arrayCant1[index]);
        repetidos.push(contador);
        contador = 1;
      }
    }
    let value = 0;
    let businessDayQuots = 0;
    for (let g = 0; g < elemento.length; g++) {
      let day = new Date(elemento[g]);
      businessDayQuots = businessDays.getbusinessDays(
        this.currentMonth,
        day.getDate()
      );
      value += repetidos[g];
      const average = (value / businessDayQuots).toFixed(2);
      dataVacio3.push({
        item: elemento[g],
        avg: parseFloat(average),
        total: repetidos[g],
      });
    }

    let elemento2 = [];
    let repetidos2 = [];
    let contador2 = 1;
    for (let index = 0; index < arrayCant2.length; index++) {
      if (arrayCant2[index + 1] === arrayCant2[index]) {
        contador2++;
      } else {
        elemento2.push(arrayCant2[index]);
        repetidos2.push(contador2);
        contador2 = 1;
      }
    }
    let value2 = 0;
    let businessDayDeliverys = 0;
    for (let g = 0; g < elemento2.length; g++) {
      let day = new Date(elemento2[g]);
      businessDayDeliverys = businessDays.getbusinessDays(
        this.currentMonth,
        day.getDate()
      );
      value2 += repetidos2[g];
      const average = (value2 / businessDayDeliverys).toFixed(2);
      dataVacio4.push({
        item: elemento2[g],
        avg: parseFloat(average),
        total: repetidos2[g],
      });
    }

    let elemento3 = [];
    let repetidos3 = [];
    let contador3 = 1;
    for (let index = 0; index < arrayCant3.length; index++) {
      if (arrayCant3[index + 1] === arrayCant3[index]) {
        contador3++;
      } else {
        elemento3.push(arrayCant3[index]);
        repetidos3.push(contador3);
        contador3 = 1;
      }
    }
    let value3 = 0;
    let businessDayInvoices = 0;
    for (let g = 0; g < elemento3.length; g++) {
      let day = new Date(elemento3[g]);
      businessDayInvoices = businessDays.getbusinessDays(
        this.currentMonth,
        day.getDate()
      );
      value3 += repetidos3[g];
      let item = g + 1;
      const average = (value3 / businessDayInvoices).toFixed(2);
      dataVacio5.push({
        item: elemento3[g],
        avg: parseFloat(average),
        total: repetidos3[g],
      });
    }

    //Invoices
    this.barChartDataInv = dataVacio;
    this.barChartInvLabel = labelVacio;
    //Quotations
    this.dataVentasTodayLabel = labelVacio1;
    this.dataVentasToday = dataVacio1;
    //Delivery
    this.barChartDataDel = dataVacio2;
    this.barChartDelLabel = labelVacio2;
    //Quantities Quotations
    this.barChartDataCants1 = dataVacio3;
    //Quantities Delivery
    this.barChartDataCants2 = dataVacio4;
    //Quantities Invoice
    this.barChartDataCants3 = dataVacio5;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
