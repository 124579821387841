import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from "rxjs/operators";
import { UsersService } from "../../../@pages/users/users.service";
import { Subject } from "rxjs";
import { TreasuryService } from "src/app/@pages/treasury/treasury.service";
import { ErrorsService } from '../services/alerts/errors.service';
import { WarehouseService } from '../../../@pages/warehouse/warehouse.service';
import { IInventoryReclassification } from 'src/app/@interfaces/inventoryReclassification.interface';
import { IWarehouseOutputAndEntry, IWarehouseTransfer } from 'src/app/@interfaces/warehouse.interface';
import { AlertsService } from '../services/alerts/alerts.service';

@Injectable({
  providedIn: "root",
})
export class TableService implements OnDestroy {
  unsubscribe$ = new Subject();
  constructor(
    private userService: UsersService,
    private treasuryService: TreasuryService,
    private errorsService: ErrorsService,
    private warehouseService: WarehouseService,
    private alertsService: AlertsService,
  ) {}

  deactiveUser(id_user: number) {
    this.alertsService.showWarningAlert("Do you want desactivate this user?")
    .then((result) => {
      if (result.isConfirmed) {
        this.userService
        .updateUserStatus(id_user, 2)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) {
            this.alertsService.showSuccessfullAlert(
              result.name, "Disabled!", "has been disabled"
            )
          } else {
            this.alertsService.showErrorAlert("something was wrong")
          }
        });
      }
    });
  }

  activateUser(id_user: number) {
    this.alertsService.showWarningAlert("Do you want activate this user?")
    .then((result) => {
      if (result.isConfirmed) {
        this.userService
        .updateUserStatus(id_user, 1)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) {
            this.alertsService.showSuccessfullAlert(
              result.name, "Activated!", "has been activated"
            )
          } else {
            this.alertsService.showErrorAlert("something was wrong")
          }
        });
      }
    });
  }

  deleteAdvancePayment(id: number) {
    this.alertsService.showWarningAlert("Do you want remove this payment?")
    .then((result) => {
      if (result.isConfirmed) {
        this.treasuryService
        .deleteAdvencePayment(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((deleted) => {
          this.alertsService.showSuccessfullAlert(
            "This payment has been removed", "Removed!"
          ).then((confirmed) => {
            if (confirmed.isConfirmed) {
              this.alertsService.refresh("/table", "/treasury")
            }
          });
        },(error) => {
          const errorTransformed = this.errorsService.getErrorFromJson(error)
          this.alertsService.showErrorAlert(
            "something was wrong", errorTransformed.message.split(':')[1].trim()
          )
        })
      }
    })
  }

  deleteTransfer(id: number) {
    this.alertsService.showWarningAlert("Do you want remove this transfer?")
    .then((result) => {
      if (result.isConfirmed) {
        this.treasuryService
        .deleteTransfer(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((deleted) => {
          this.alertsService.showSuccessfullAlert(
            "This transfer has been removed", "Removed!"
          ).then((confirmed) => {
            if (confirmed.isConfirmed) {
              this.alertsService.refresh("/table", "/treasury")
            }
          });
        },(error) => {
          const errorTransformed = this.errorsService.getErrorFromJson(error)
          this.alertsService.showErrorAlert(
            "something was wrong", errorTransformed.message.split(':')[1].trim()
          )
        })
      }
    })
  }

  deleteWarehouseTransfer(
    id: number, transfer: IWarehouseTransfer
  ) {
    this.alertsService.showWarningAlert("Do you want remove this transfer?")
    .then((result) => {
      if (result.isConfirmed) {
        this.warehouseService
        .deleteWarehouseTransfer(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((deleted) => {
          this.alertsService.showSuccessfullAlert(
            "This transfer has been removed", "Removed!"
          ).then((confirmed) => {
            if (confirmed.isConfirmed) {
              this.alertsService.refresh("/table", "/inventory_movements")
            }
          });
        },(error) => {
          const errorTransformed = this.errorsService.getErrorFromJson(error)
          this.alertsService.showErrorAlert(
            "something was wrong", errorTransformed.message.split(':')[1].trim()
          )
        })
      }
    })
  }

  deleteWarehouseOutputOrEntry(
    id: number, movement: IWarehouseOutputAndEntry
  ) {
    this.alertsService.showWarningAlert("Do you want remove this movement?")
    .then((result) => {
      if (result.isConfirmed) {
        this.warehouseService
        .deleteWarehouseOutputOrEntry(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((deleted) => {
          this.alertsService.showSuccessfullAlert(
            "This movement has been removed", "Removed!"
          ).then((confirmed) => {
            if (confirmed.isConfirmed) {
              this.alertsService.refresh("/table", "/inventory_movements")
            }
          });
        },(error) => {
          const errorTransformed = this.errorsService.getErrorFromJson(error)
          this.alertsService.showErrorAlert(
            "something was wrong", errorTransformed.message.split(':')[1].trim()
          )
        })
      }
    })
  }

  deleteWInventoryReclassification(
    id: number, reclassification: IInventoryReclassification
  ) {
    this.alertsService.showWarningAlert("Do you want remove this reclassification?")
    .then((result) => {
      if (result.isConfirmed) {
        this.warehouseService
        .deleteInventoryReclassification(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((deleted) => {
          this.alertsService.showSuccessfullAlert(
            "This reclassification has been removed", "Removed!"
          ).then((confirmed) => {
            if (confirmed.isConfirmed) {
              this.alertsService.refresh("/table", "/inventory_movements")
            }
          });
        },(error) => {
          const errorTransformed = this.errorsService.getErrorFromJson(error)
          this.alertsService.showErrorAlert(
            "something was wrong", errorTransformed.message.split(':')[1].trim()
          )
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
