import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/@graphql/services/api.service";
import {
  GET_MAIL_LIST,
  GET_BANK_LIST,
  GET_CASHIERS_LIST,
  GET_INVOICE_PAYMENTS,
  GET_PAYMENT_BYID,
  GET_ALLPAYMENTS,
  GET_PURCHASE_PAYMENTS
} from "../../@graphql/operations/query";
import {
  CREATE_MAIL_LIST,
  UPDATE_MAIL_LIST,
  REMOVE_MAIL_LIST,
  CREATE_PAYMENTS,
  UPDATE_PAYMENTS,
  REMOVE_PAYMENTS,
  CREATE_INVOICE_PAYMENTS,
  REMOVE_INVOICE_PAYMENTS,
  ASSING_PAYMENTS,
  SEND_PAYMENT_MAIL,
  UPDATE_PAYMENT_STATUS,
} from "../../@graphql/operations/mutation";
import {
  GET_CASHIERS_LIST_BYUSER,
  GET_BANK_LIST_BYUSER,
} from "../../@graphql/operations/queryByUser";
import { GET_ALL_AR_INVOICEPAYMENTS, GET_ALL_AR_INVOICES, GET_ALL_AR_NOTES } from "src/app/@graphql/operations/ar&apAccountsQueries";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class PortfolioService extends ApiService {

  constructor(apollo: Apollo, private cookieAuthService: CookieAuthService) {
    super(apollo);
  }

  sendMailPayment(id_factura: number, file: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(SEND_PAYMENT_MAIL, { id_factura, file, company }).pipe(
      map((result: any) => {
        return result.sendPaymentsMail;
      })
    );
  }

  getMailList(group_mails: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_MAIL_LIST, { group_mails, company }).pipe(
      map((result: any) => {
        return result.getMailList;
      })
    );
  }

  createMailList(mail: string[], group_mails: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_MAIL_LIST, { mail, group_mails, company }).pipe(
      map((result: any) => {
        return result.createMailList;
      })
    );
  }

  updateMailList(id: number, mail: string[], group_mails: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_MAIL_LIST, {
      id,
      mail,
      group_mails,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateMail;
      })
    );
  }

  removeMailList(id: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_MAIL_LIST, { id, company }).pipe(
      map((result: any) => {
        return result.removeMail;
      })
    );
  }

  createPayments(type: string, description: string, id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_PAYMENTS, {
      type,
      description,
      id_user,
      company,
    }).pipe(
      map((result: any) => {
        return result.createPayments;
      })
    );
  }

  updatePayments(id: number, type: string, description: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_PAYMENTS, {
      id,
      type,
      description,
      company,
    }).pipe(
      map((result: any) => {
        return result.updatePayments;
      })
    );
  }

  removePayments(id: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_PAYMENTS, { id, company }).pipe(
      map((result: any) => {
        return result.removePayments;
      })
    );
  }

  getBankList() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_BANK_LIST, { company }).pipe(
      map((result: any) => {
        return result.getBankList;
      })
    );
  }

  getCashierList() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_CASHIERS_LIST, { company }).pipe(
      map((result: any) => {
        return result.getCashiersList;
      })
    );
  }

  getBankListByUser(id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_BANK_LIST_BYUSER, { id_user, company }).pipe(
      map((result: any) => {
        return result.getBankListByUser;
      })
    );
  }

  getCashierListByUser(id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_CASHIERS_LIST_BYUSER, { id_user, company }).pipe(
      map((result: any) => {
        return result.getCashiersListByUser;
      })
    );
  }

  createPaymentInvoice(
    id_factura: number,
    id_payment: number,
    type: string,
    date: string,
    value: number,
    id_ref: string,
    payment_description: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_INVOICE_PAYMENTS, {
      id_factura,
      id_payment,
      type,
      date,
      value,
      id_ref,
      payment_description,
      company,
    }).pipe(
      map((result: any) => {
        return result.invoicePayments;
      })
    );
  }

  removePaymentInvoice(id: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_INVOICE_PAYMENTS, { id, company }).pipe(
      map((result: any) => {
        return result.delInvoicePayments;
      })
    );
  }

  getPaymenInvoice(id_factura: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_INVOICE_PAYMENTS, { id_factura, company }).pipe(
      map((result: any) => {
        return result.getPaymentsInvoice;
      })
    );
  }

  getPaymenPurchase(id_invoice: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PURCHASE_PAYMENTS, { id_invoice, company }).pipe(
      map((result: any) => {
        return result.getPaymentsPurchases;
      })
    );
  }

  assingInvoicePays(id_factura: number, id_payment: number, type: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(ASSING_PAYMENTS, {
      id_factura,
      id_payment,
      type,
      company,
    }).pipe(
      map((result: any) => {
        return result.assignPayment;
      })
    );
  }

  removeInvoicePays(
    id_factura: number,
    id_payment: number,
    ids_delete: number[]
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_PAYMENTS, {
      id_factura,
      id_payment,
      ids_delete,
      company,
    }).pipe(
      map((result: any) => {
        return result.removePayments;
      })
    );
  }

  getPayment(id_payment: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PAYMENT_BYID, { id_payment, company }).pipe(
      map((result: any) => {
        return result.getPaymentsByID;
      })
    );
  }

  getAllPayments() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALLPAYMENTS, { company }).pipe(
      map((result: any) => {
        return result.getAllPayments;
      })
    );
  }

  updateStatusPayment(
    id_payinvoice: number,
    p_vendor: boolean,
    cancelled: boolean
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_PAYMENT_STATUS, {
      id_payinvoice,
      p_vendor,
      cancelled,
      company,
    }).pipe(
      map((result: any) => {
        return result.updatePaymentStatus;
      })
    );
  }

  getAllArInvoices(year: number, id_user: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_AR_INVOICES, { year, id_user, company }).pipe(
      map((result: any) => {
        return result.getAllArInvoices;
      })
    );
  }

  getAllArNotes(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_AR_NOTES, { year, company }).pipe(
      map((result: any) => {
        return result.getAllArNotes;
      })
    );
  }

  getAllArInvoicePayments(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_AR_INVOICEPAYMENTS, { year, company }).pipe(
      map((result: any) => {
        return result.getAllArInvoicePayments;
      })
    );
  }
}
