import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsAccountsPayableComponent } from './details-accounts-payable.component';
import { DetailsAccountsPayableRoutingModule } from './details-accounts-payable-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchModule } from 'src/app/@shared/components/search/search.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from '@angular/cdk/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { SortModule } from 'src/app/directives/sortDirective/sort.module';
import { FilterPPurchase } from 'src/app/@shared/pipe/purchase-pipes/provider.pipe';
import { PipesModule } from 'src/app/pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    DetailsAccountsPayableComponent
  ],
  imports: [
    CommonModule,
    DetailsAccountsPayableRoutingModule,
    MaterialModule,
    NgxPaginationModule,
    SearchModule,
    MatIconModule,
    TranslateModule,
    DialogModule,
    ReactiveFormsModule,
    SortModule,
    PipesModule,
    FlexLayoutModule
  ],
  providers: [FilterPPurchase]
})
export class DetailsAccountsPayableModule { }
