import { Injectable } from "@angular/core";
import { AlertsService } from "../alerts/alerts.service";

@Injectable({
  providedIn: "root",
})
export class ArrayValidationsService {
  constructor(private alertsService: AlertsService) {}

  validArraysEquals<T>(array1: T[], array2: T[]) {
    if (array1.length !== array2.length) {
      this.alertsService.showErrorAlert(`File does not match`);
      return false;
    }
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        this.alertsService.showErrorAlert(`File does not match`);
        return false;
      }
    }
    return true;
  }
}
