<mat-dialog-content>
  <div class="container">
    <div class="div-title">
      <mat-card>
        <mat-card-content>
          <mat-card-title>{{
            "How do you want to upload the purchase to system?" | translate
          }}</mat-card-title>
          <mat-card-subtitle>{{
            "To continue use one of two options:" | translate
          }}</mat-card-subtitle>
        </mat-card-content>
      </mat-card>
    </div>
    <br />
    <div class="row">
      <div class="cardXML col">
        <mat-card>
          <mat-card-header>
            <mat-card-title>{{
              "Preloading from an XML file" | translate
            }}</mat-card-title>
            <mat-card-subtitle>{{
              "If you have an XML file, please upload it in the field below and continue"
                | translate
            }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <div class="col" id="div-responsive">
              <form [formGroup]="registerForm">
                <label for="exampleFormControlFile1">{{
                  "XML File" | translate
                }}</label>
                <input
                  (change)="getFile($event)"
                  type="file"
                  class="form-control-file"
                  formControlName="XML"
                />
              </form>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div class="div-center">
              <button mat-dialog-close mat-raised-button class="orange-color">
                {{ "Back" | translate }}
              </button>
              <button
                (click)="openDialogWithXml(true)"
                mat-raised-button
                class="orange-color"
              >
                {{ "Next" | translate }}
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="cardNoXML col">
        <mat-card fxLayoutAlign="center center" style="min-height: 100%">
          <div>
            <mat-card-header>
              <mat-card-title>{{
                "Manual registration" | translate
              }}</mat-card-title>
              <mat-card-subtitle>{{
                "If you do not have a XML file you must use this option"
                  | translate
              }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
              <div class="div-center">
                <button mat-dialog-close mat-raised-button class="orange-color">
                  {{ "Back" | translate }}
                </button>
                <button
                  (click)="openDialogWithoutXml(true)"
                  mat-raised-button
                  class="orange-color"
                >
                  {{ "Next" | translate }}
                </button>
              </div>
            </mat-card-actions>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</mat-dialog-content>
