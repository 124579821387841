import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from "@angular/core";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ProcessNotesService } from "../../../../@shared/components/services/charts/process-notes.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { HomeService } from "../../home.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-sales-target",
  templateUrl: "./sales-target.component.html",
  styleUrls: ["./sales-target.component.css"],
})
export class SalesTargetComponent implements OnChanges, OnDestroy {
  @Input() customerQuots: Array<IQuota> = [];
  @Input() notas: Array<INotasGrap> = [];
  @Input() monthTarjet: number = 0;
  @Input() id_type: number | null = null;
  @Input() id_seller: number | null = null;
  @Input() typePres: string = "All";

  tarjet = 0;
  typeId: number | null = null;
  sellerId: number | null = null;

  complete = 0;
  todayLet = 0;
  pastLet = 0;
  projection = 0;
  recommendedTarget = 0;

  allQuots: Array<IQuota> = [];
  allNotas: Array<INotasGrap> = [];
  unsubscribe$ = new Subject();
  companyObject = {} as IStorageCompany;
  companyId = '';

  constructor(
    public homeService: HomeService,
    private translate: TranslateService,
    private processNotesService: ProcessNotesService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "customerQuots") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.allQuots = values;
        }
      }
      if (item === "notas") {
        const values: Array<INotasGrap> = changes[item].currentValue;
        if (values.length > 0) {
          this.allNotas = values;
        }
      }
      if (item === "id_type") {
        const values: number = changes[item].currentValue;
        this.typeId = values;
      }
      if (item === "id_seller") {
        const values: number = changes[item].currentValue;
        this.sellerId = values;
      }
      if(item === "monthTarjet") {
        const values: number = changes[item].currentValue;
        this.tarjet = values;
      }
      if (this.allQuots.length > 0 || (this.allNotas.length > 0)) {
        this.calcTarget(this.allQuots, this.allNotas, this.tarjet);
      }
    }
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject.Id_company!.toString();
  }

  save(invoices: IQuota[], notas: INotasGrap[]) {
    const validation = this.homeService.goalError(this.sellerId!, this.typeId!);
    if (validation) {
      this.homeService
      .updateGoals(
        parseFloat(this.tarjet.toString()),
        this.sellerId,
        "MONTH",
        this.typeId!,
        new Date().toDateString()
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        if (result !== null) {
          Swal.fire(
            this.translate.instant("success"),
            this.translate.instant("Done!, saved goal."),
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              this.calcTarget(invoices, notas, this.tarjet);
            }
          });
        }
      });
    }
    else {
      return;
    }
  }

  calcTarget(invoices: IQuota[], notas: INotasGrap[], tarjet: number) {
    tarjet = tarjet === null ? 0 : tarjet;
    let filterInvoices: IQuota[] = []
    if (this.companyObject.external_electronic_invoicing === "true" || this.companyObject.internal_electronic_invoicing === "true") {
      filterInvoices = invoices.filter((data) => data.estado === 4 && data.billyInvoice![0].invoice_number !== "PENDING");
    } else {
      filterInvoices = invoices.filter((data) => data.estado === 4);
    }
    let arrayFacts1: number[] = [];
    let arrayFacts2: number[] = [];
    let arrayFacts3: number[] = [];
    let arrayFacts4: number[] = [];

    let arrayIdNotas1: number[] = [];
    let arrayIdNotas2: number[] = [];
    let arrayIdNotas3: number[] = [];
    let arrayIdNotas4: number[] = [];

    let arrayGroupValues1 = [
      { fact: 0, articulo: "", cantidad: 0, litros: 0, subtotal: 0 },
    ];
    let arrayGroupValues2 = [
      { fact: 0, articulo: "", cantidad: 0, litros: 0, subtotal: 0 },
    ];
    let arrayGroupValues3 = [
      { fact: 0, articulo: "", cantidad: 0, litros: 0, subtotal: 0 },
    ];
    let arrayGroupValues4 = [
      { fact: 0, articulo: "", cantidad: 0, litros: 0, subtotal: 0 },
    ];

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();
    const pastYear = currentYear - 1;

    let pastMonth = currentDate.getMonth();
    let tarjetLet = 0;
    let completeLet = 0;

    let preTarget = 0;

    let litersAnt = 0;
    let litersToday = 0;

    let projection = 0;
    let days = 0;

    if (pastMonth === 0) {
      pastMonth = 12;
    }

    let firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    let lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    let arrayDates: Array<Date> = [];
    let arrDatesLbs: Array<Date> = [];
    let arrDatesAtToday: Array<Date> = [];

    while (firstDay <= lastDay) {
      arrayDates.push(new Date(firstDay));
      firstDay.setDate(firstDay.getDate() + 1);
    }

    arrayDates.forEach((element) => {
      if (element.getDay() !== 0) {
        arrDatesLbs.push(element);
      }
    });

    arrDatesLbs.forEach((element) => {
      if (element >= currentDate) {
        arrDatesAtToday.push(element);
      }
    });
    let divideTarjet = tarjet / arrDatesLbs.length;
    let created;
    let invoice_date;

    filterInvoices.forEach(invoice => {
      if (invoice.estado === 4) {
        created = new Date(invoice.createdAt);
        if (invoice.invoiced_date) {
          invoice_date = new Date(invoice.invoiced_date!);
        } else {
          invoice_date = created;
        }
        if (invoice_date.getFullYear() === currentYear && invoice_date.getMonth() + 1 === currentMonth) {
          arrayFacts1.push(invoice.id_factura!);
          invoice.venta.forEach(sale => {
            completeLet +=
              sale.cantidad *
              parseFloat(sale.articulo[0].presentacion!);
          });
        }
        if (invoice_date.getFullYear() === currentYear && invoice_date.getMonth() + 1 === pastMonth) {
          arrayFacts2.push(invoice.id_factura!);
          invoice.venta.forEach(sale => {
            litersAnt +=
              sale.cantidad *
              parseFloat(sale.articulo[0].presentacion!);
          });
        }
        if (invoice_date.getFullYear() === currentYear && invoice_date.getMonth() + 1 === currentMonth && invoice_date.getDate() === currentDay) {
          arrayFacts3.push(invoice.id_factura!);
          invoice.venta.forEach(sale => {
            litersToday +=
              sale.cantidad *
              parseFloat(sale.articulo[0].presentacion!);
          });
        }
        if (invoice_date.getFullYear() === pastYear) {
          arrayFacts4.push(invoice.id_factura!);
          invoice.venta.forEach(sale => {
            preTarget +=
              sale.cantidad *
              parseFloat(sale.articulo[0].presentacion!);
            tarjetLet = preTarget / 12;
          });
        }
      }
    });

    let groupFacts1 = arrayFacts1.filter((item, index) => {
      return arrayFacts1.indexOf(item) === index;
    });
    let groupFacts2 = arrayFacts2.filter((item, index) => {
      return arrayFacts2.indexOf(item) === index;
    });
    let groupFacts3 = arrayFacts3.filter((item, index) => {
      return arrayFacts3.indexOf(item) === index;
    });
    let groupFacts4 = arrayFacts4.filter((item, index) => {
      return arrayFacts4.indexOf(item) === index;
    });

    notas.forEach((item) => {
      groupFacts1.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          arrayIdNotas1.push(parseInt(item.id_nota!.toString()));
        }
      });
      groupFacts2.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          arrayIdNotas2.push(parseInt(item.id_nota!.toString()));
        }
      });
      groupFacts3.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          arrayIdNotas3.push(parseInt(item.id_nota!.toString()));
        }
      });
      groupFacts4.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          arrayIdNotas4.push(parseInt(item.id_nota!.toString()));
        }
      });
    });

    arrayGroupValues1 = this.processNotesService.getNotesByDescription(
      notas,
      arrayIdNotas1
    );
    arrayGroupValues2 = this.processNotesService.getNotesByDescription(
      notas,
      arrayIdNotas2
    );
    arrayGroupValues3 = this.processNotesService.getNotesByDescription(
      notas,
      arrayIdNotas3
    );
    arrayGroupValues4 = this.processNotesService.getNotesByDescription(
      notas,
      arrayIdNotas4
    );

    arrayGroupValues1.forEach((item) => {
      let lits = 0;
      lits += item.litros;
      completeLet = completeLet + lits * 1;
    });

    arrayGroupValues2.forEach((item) => {
      let lits = 0;
      lits += item.litros;
      litersAnt = litersAnt + lits * 1;
    });

    arrayGroupValues3.forEach((item) => {
      let lits = 0;
      lits += item.litros;
      litersToday = litersToday + lits * 1;
    });

    arrayGroupValues4.forEach((item) => {
      let lits = 0;
      let calcLits = 0;
      lits += item.litros;
      calcLits = lits / 12;
      tarjetLet = tarjetLet + calcLits * 1;
    });
    projection = divideTarjet * arrDatesAtToday.length + completeLet;

    this.recommendedTarget = parseFloat(tarjetLet.toFixed(2));
    this.complete = parseFloat(completeLet.toFixed(2));
    this.todayLet = parseFloat(litersToday.toFixed(2));
    this.pastLet = parseFloat(litersAnt.toFixed(2));
    this.projection = parseFloat(projection.toFixed(2));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
