import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { CitiesI } from "src/app/@interfaces/cities/cities.interface";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class CitiesService {
  API_URL = environment.api_url;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private http: HttpClient) {}

  handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error("An error occurred:", error.error);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    return throwError(
      () => new Error("Something bad happened; please try again later.")
    );
  }

  postCities(country: any) {
    const url = this.API_URL + "/cities/" + country;
    return this.http
      .get<CitiesI>(url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
}
