import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EventEmitterService } from "src/app/@core/menu/emitterFuncions.service";
import { QuotationsService } from "../quotations/quotations.service";
import { IQuota } from "../../@interfaces/quotation.interface";
import { ListData } from "src/app/@shared/components/list/list.component";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import * as XLSX from "xlsx";
import { NgxSpinnerService } from "ngx-spinner";
import { IArticles } from "../../@interfaces/article.interface";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { ReportsService } from "../reports/reports.service";
import { QuotationsByUserService } from "../quotations/quotations-byuser.service";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

export interface ArticleExport {
  description: string;
  presentation: string;
  cant: number;
  liters: number;
}

export interface CustomerExport {
  delivery: string[];
  num_doc: string;
  customer: string;
  city: string;
  cant: number;
}

@Component({
  selector: "app-remittance",
  templateUrl: "./remittance.component.html",
  styleUrls: ["./remittance.component.css"],
})
export class RemittanceComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;
  deliveryList: IQuota[] = [];
  data?: DataSource;
  list: ListData[] = [];
  articlesExport: ArticleExport[] = [];
  customersExport: CustomerExport[] = [];
  companyId = '';
  user = '';
  rol = '';
  name = "Report.xlsx";
  date = new Date();
  driverForm: UntypedFormGroup = this.fb.group({
    driver: [""],
    plate: [""],
    destination: [""],
    veh_model: [""],
  });
  companyName = '';

  constructor(
    private fb: UntypedFormBuilder,
    private eventEmitterService: EventEmitterService,
    private quotation: QuotationsService,
    private quotationByUser: QuotationsByUserService,
    private spinner: NgxSpinnerService,
    private reportsService: ReportsService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.toggleExpandCard();
    this.getEvent();
    this.getData();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.companyName = company!.name;
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  getData() {
    if (this.rol === "1" || this.rol === "3") {
      this.quotation
      .listQuotationsByStatus3()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.deliveryList = result;
        this.setList(this.deliveryList);
      });
    }else {
      this.quotationByUser
      .listQuotationsByStatus3ByUser(parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.deliveryList = result;
        this.setList(this.deliveryList);
      });
    }
  }

  setList(deliveryList: IQuota[]) {
    deliveryList.forEach((element) => {
      this.list.push({
        id: parseInt(element.id_factura!.toString()),
        text:
          element.contfac![0].delivery.toString() +
          " " +
          "-" +
          " " +
          element.cliente[0].nombre,
      });
    });
    this.data = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: this.list,
      }),
    });
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((name: string) => {
      this.toggleExpandCard();
    });
    this.eventEmitterService.invokeGetItemList
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((items) => {
      this.exportExcel();
    });
    this.eventEmitterService.invokeEachItem
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((items) => {
      this.filterData(items);
    });
  }

  filterData(items: any[]) {
    let resultDeliverys: IQuota[] = [];
    items.forEach((item) => {
      const filterDeliverys = this.deliveryList.filter(
        (data) => parseInt(data.id_factura!.toString()) === item
      );
      filterDeliverys.forEach((delivery) => {
        resultDeliverys.push(delivery);
      });
    });
    this.calculateData(resultDeliverys);
  }

  calculateData(deliverys: IQuota[]) {
    let arrayArticles: string[] = [];
    let arrayCustomers: string[] = [];
    let arrayData: any[] = [];
    deliverys.forEach((delivery) => {
      arrayCustomers.push(delivery.cliente[0].nombre);
      delivery.venta.forEach((sale) => {
        arrayArticles.push(sale.codigo);
        arrayData.push({
          delivery: delivery.contfac![0].delivery,
          docnit: delivery.cliente[0].doc_nit,
          customer: delivery.cliente[0].nombre,
          city: delivery.cliente[0].city,
          article: sale.articulo[0],
          quantity: sale.cantidad,
        });
      });
    });
    const groupArticles = arrayArticles.filter((item, index) => {
      return arrayArticles.indexOf(item) === index;
    });
    this.calculateArticles(groupArticles, arrayData);
    const groupCustomers = arrayCustomers.filter((item, index) => {
      return arrayCustomers.indexOf(item) === index;
    });
    this.calculateCustomers(groupCustomers, arrayData);
  }

  calculateArticles(articles: string[], data: any[]) {
    let array: ArticleExport[] = [];
    for (const article of articles) {
      let sumQuantitys = 0, articleObj = {} as IArticles;
      const filter = data.filter((elem) => elem.article.codigo! === article);
      filter.forEach((element) => {
        sumQuantitys += element.quantity;
        articleObj = element.article;
      });
      array.push(this.setArticleItems(articleObj, sumQuantitys))
    }
    this.articlesExport = array;
  }

  setArticleItems(article: IArticles, sumQuantitys: number) {
      let obj = {} as ArticleExport;
      obj = {
        description: article.descripcion,
        presentation: article.unidad!,
        cant: sumQuantitys,
        liters: sumQuantitys * parseFloat(article.presentacion!),
      };
      return obj;
  }

  calculateCustomers(customers: string[], data: any[]) {
    let array: CustomerExport[] = [];
    customers.forEach((customer) => {
      let sumQuantitys = 0, numDoc = "", customerName = "", city = "", delvs: string[] = [];
      const filter = data.filter((elem) => elem.customer === customer);
      filter.forEach((element) => {
        sumQuantitys += element.quantity;
        numDoc = element.docnit;
        customerName = element.customer;
        city = element.city;
        delvs.push(element.delivery);
      });
      const groupDelvs = delvs.filter((item, index) => {
        return delvs.indexOf(item) === index;
      });
      array.push({
        delivery: groupDelvs,
        num_doc: numDoc,
        customer: customerName,
        city: city,
        cant: sumQuantitys,
      });
    });
    this.customersExport = array;
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 250);
  }

  exportExcel() {
    this.spinner.show();
    const element = document.getElementById("season-tble");
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const book: XLSX.WorkBook = XLSX.utils.book_new();
    this.reportsService.export(book, worksheet, this.name);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
