<div class="div-filters" fxLayout="row" fxLayoutAlign="center center">
    <div>
        <form [formGroup]="registerForm">
            <div>                            
                <input class="form-control" formControlName='provider' name="provider" placeholder="{{'Filter by provider' | translate}}" 
                [matAutocomplete]="autoProvider"  style="width: 200px;">
                    <mat-autocomplete #autoProvider="matAutocomplete" [displayWith]="displayFnProvider">
                        <mat-option  *ngFor="let provider of providerList"
                            [value]="provider">
                            {{provider.nombre}}
                        </mat-option>
                    </mat-autocomplete>
            </div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>{{'Select year' | translate}}</mat-label>
                    <mat-select formControlName="year" (selectionChange)="data($event.value)">
                      <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
    
    <div>
        <button class="buttons" (click)="update(purchases, refunds, payments)" style="margin-left: 20px;" mat-raised-button>{{'Update' | translate}}</button>
        <button class="buttons" (click)="reset(purchases, refunds, payments);" style="margin-left: 20px;" mat-raised-button>{{'Reset' | translate}}</button>
    </div>
</div>

<div *ngIf="showTable === true">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th [appSort]="dataTable" data-order="asc" data-name="code">
                    <mat-label>{{'Code' | translate}}</mat-label>
                </th>
                <th [appSort]="dataTable" data-order="asc" data-name="customer">
                    <mat-label>{{'Name' | translate}}</mat-label>
                </th>
                <th [appSort]="dataTable" data-order="asc" data-name="total">
                    <mat-label>{{'Saldo' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of dataTable; let i = index">
                <td>
                    {{i + 1}}
                </td>
                <td>
                    {{item.code}}
                </td>
                <td>
                    {{item.customer}}
                </td>
                <td>
                    {{item.total | thousandsPipe}}
                </td>
                <td>
                    <a class="goLink" (click)="openDialog(item.details!)">
                        <mat-icon>list_alt</mat-icon>
                    </a>
                </td>
            </tr>
            <tr>
                <td colspan="3"></td>
                <td colspan="2">{{'Total: '}} {{total}}</td>
            </tr>
        </tbody> 
    </table>
</div>

<div *ngIf="showTable === false">
    <div class="alert alert-danger">
        {{'NO RESULTS FOR THIS SEARCH' | translate}}
    </div>
</div>
