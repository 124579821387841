import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WarehouseRoutingModule } from './warehouse-routing.module';
import { WarehouseComponent } from './warehouse.component';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [WarehouseComponent],
  imports: [
    CommonModule,
    MatIconModule,
    WarehouseRoutingModule,
    NgxSpinnerModule,
    TranslateModule
  ]
})
export class WarehouseModule { }
