import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from '../../../../@interfaces/quotation.interface';

@Pipe({
  name: 'filterIQuots'
})
export class FilterIQuots implements PipeTransform {
  transform(quotaions: IQuota[], item: string): IQuota[] {
    return quotaions.map(quotation => ({
      ...quotation,
      venta: quotation.venta.filter(sale => sale.codigo === item)
    }))
    .filter(quotation => {
      return quotation.venta.length > 0
    })
  }
}
