import { Component, OnInit, OnDestroy } from "@angular/core";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { AnalysisService } from "../analysis/analysis.service";
import { QuotationsService } from "../quotations/quotations.service";
import { PortfolioService } from "../portfolio/portfolio.service";
import { IInvoicePayments } from "src/app/@interfaces/payments.interface";
import { IContFact } from "src/app/@interfaces/contfac.interface";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { FilterSQuots } from "../../@shared/pipe/graphs-pipes/quotations/seller.pipe";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import { SellersService } from "../sellers/sellers.service";
import { ISeller } from "src/app/@interfaces/seller.interface";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IDetails } from "src/app/@interfaces/details/details.interface";
import { INote } from "src/app/@interfaces/details/note.interface";
import { ITableData } from "src/app/@interfaces/details/table.interface";
import { DetailsPortfolioService } from "./details-portfolio.service";
import * as XLSX from "xlsx";
import { CustomerService } from "../customer/customer.service";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { FilterSCQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/seller-customer.pipe";
import { FilterCQuots } from "src/app/@shared/pipe/graphs-pipes/quotations/customer.pipe";
import { ReportsService } from "../reports/reports.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CalculateAnalysis } from "src/app/@shared/components/services/charts/calculate-analysis.service";
import { ICustomerData, ICustomerDetailsData } from "src/app/@interfaces/details/customer-data.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { ConverseArrayService } from '../../@shared/components/services/data-types/converse-array.service';

@Component({
  selector: "app-details-portfolio",
  templateUrl: "./details-portfolio.component.html",
  styleUrls: ["./details-portfolio.component.css"],
})
export class DetailsPortfolioComponent implements OnInit, OnDestroy {
  date = new Date();
  registerForm: UntypedFormGroup = this.fb.group({
    seller: [, Validators.required],
    customer: [, Validators.required],
    year: [this.date.getFullYear(), Validators.required]
  });
  dataTable: ITableData[] = [];
  invoices: Array<IQuota> = [];
  notes: Array<INotasGrap> = [];
  payments: Array<IInvoicePayments> = [];
  contFac = {} as IContFact;
  user = '';
  rol = '';
  sellerList: Array<ISeller> = [];
  showTable: boolean = true;
  unsubscribe$ = new Subject();
  total = "";
  totalADD = "";
  totalDSO = "";
  totalBPDSO = "";
  customerList: Array<ICustomer> = [];
  years: number[] = [];

  constructor(
    public analysis: AnalysisService,
    public quotation: QuotationsService,
    public portfolio: PortfolioService,
    public dialog: MatDialog,
    private filterSQuots: FilterSQuots,
    private filterSCQuots: FilterSCQuots,
    private filterCQuots: FilterCQuots,
    private fb: UntypedFormBuilder,
    private seller: SellersService,
    private detailsPortfolioService: DetailsPortfolioService,
    private customers: CustomerService,
    private reportsService: ReportsService,
    private spinner: NgxSpinnerService,
    private calculateAnalysis: CalculateAnalysis,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService,
    private converseArrayService: ConverseArrayService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    await this.data(this.date.getFullYear());
    this.setYears();
    this.seller.listSellers().subscribe((result) => {
      this.sellerList = result;
    });
    this.getValuesFromHandleSearch();
  }

  getAuthValues() {
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  setYears() {
    for (let i = this.date.getFullYear(); i >= this.date.getFullYear() - 10; i--) {
      this.years.push(i);
    }
  }

  getValuesFromHandleSearch() {
    if (this.rol === "1" || this.rol === "3" ) {
      this.registerForm
      .get("customer")
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        if (!value.__typename) {
          this.customers
          .searchCustomer(value, null, null)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result: any) => {
            this.customerList = result.customers;
          });
        }
      });
    } else {
      this.registerForm
      .get("customer")
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: any) => {
        if (!value.__typename) {
        this.customers
          .searchCustomerByUser(value, parseInt(this.user!), null, null)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result: any) => {
            this.customerList = result;
          });
        }
      });
    }
  }

  update(invoices: IQuota[], notes: INotasGrap[], paymentS: IInvoicePayments[]) {
    let id_seller = null;
    let id_customer = null;
    const sellerObj = this.registerForm.get("seller")?.value;
    const customerObj = this.registerForm.get("customer")?.value;
    if (sellerObj) {
      id_seller = sellerObj.id_seller;
    }
    if (customerObj) {
      id_customer = customerObj.id_cliente;
    }
    this.filter(invoices, notes, paymentS, id_seller, id_customer);
  }

  filter(invoices: IQuota[], notes: INotasGrap[], paymentS: IInvoicePayments[], id_seller: string, id_customer: string) {
    let filterInvoices: IQuota[] = [];
    if (id_seller && id_customer) {
      filterInvoices = this.filterSCQuots.transform(invoices, id_customer, id_seller!);
      if (filterInvoices.length === 0) {
        this.showTable = false;
      } else {
        this.showTable = true;
        this.calculateData(filterInvoices, notes, paymentS);
      }
    } else if (id_customer) {
      filterInvoices = this.filterCQuots.transform(invoices, id_customer!);
      if (filterInvoices.length === 0) {
        this.showTable = false;
      } else {
        this.showTable = true;
        this.calculateData(filterInvoices, notes, paymentS);
      }
    } else if (id_seller) {
      filterInvoices = this.filterSQuots.transform(invoices, id_seller!);
      if (filterInvoices.length === 0) {
        this.showTable = false;
      } else {
        this.showTable = true;
        this.calculateData(filterInvoices, notes, paymentS);
      }
    } else {
      this.showTable = false;
    }
  }

  reset(invoices: IQuota[], notas: INotasGrap[], paymentS: IInvoicePayments[]) {
    this.calculateData(invoices, notas, paymentS);
    this.showTable = true;
  }

  setUserId() {
    let user: number | null;
    if (this.rol === "1" || this.rol === "3") {
      user = null;
    } else {
      user = parseInt(this.user!)
    }
    return user;
  }

  async data(year: number) {
    this.spinner.show();
    const user = this.setUserId();
    this.invoices = await this.detailsPortfolioService.getAllInvoices(year, user!);
    if (this.invoices.length > 0) {
      this.notes = await this.detailsPortfolioService.getAllNotes(year);
      this.payments = await this.detailsPortfolioService.getAllPayments(year);
      this.calculateData(this.invoices, this.notes, this.payments);
    } else{
      this.dataTable = [];
      this.total = '0';
      this.totalADD = '0';
      this.totalDSO = '0';
      this.totalBPDSO = '0';
      this.spinner.hide();
    }
  }

  getDataFromInvoices(invoices: IQuota[]) {
    let arrayCustomerName: string[] = [];
    let arrayInvoiceIds: number[] = [];
    let arrayInit: ITableData[] = [];
    invoices.forEach((invoice) => {
      let createdAt = "";
      arrayInvoiceIds.push(invoice.id_factura!);
      invoice.cliente.forEach((customer) => {
        arrayCustomerName.push(customer.nombre);
        invoice.seller!.forEach((seller) => {
          if (invoice.invoiced_date) {
            createdAt = invoice.invoiced_date!;
          } else {
            createdAt = invoice.createdAt;
          }
          arrayInit.push(this.setMainArray(customer, seller, invoice, createdAt));
        });
      });
    });
    return { arrayCustomerName: arrayCustomerName, arrayInvoiceIds: arrayInvoiceIds, arrayInit: arrayInit }
  }

  setMainArray(
    customer: ICustomer, seller: ISeller, 
    invoice: IQuota, createdAt: string
  ): ITableData {
    return {
      code: customer.doc_nit,
      customer: customer.nombre,
      seller: [seller.nombre],
      total: invoice.venta![0].total,
      details: [
        {
          invoice: invoice.id_factura!.toString(),
          invoice_number: invoice.contfac![0].invoice,
          electronic_number: invoice.billyInvoice![0].invoice_number,
          dueDate: invoice.dueDate,
          createdDate: createdAt,
          total: invoice.venta![0].total,
        },
      ],
    }
  }

  calculateTotals(
    customers: string[], arrayInit: ITableData[], creditNotes: INote[], 
    debitNotes: INote[], invoicePayments: IInvoicePayments[]
  ) {
    const allTotal = this.detailsPortfolioService.calculateAllTotal(
      customers,
      arrayInit,
      creditNotes,
      debitNotes,
      invoicePayments,
      true
    );
    const allTotalWitoutPays = this.detailsPortfolioService.calculateAllTotal(
      customers,
      arrayInit,
      creditNotes,
      debitNotes,
      invoicePayments,
      false
    );
    return { allTotal: allTotal, allTotalWitoutPays }
  }

  calculateData(invoices: IQuota[], notes: INotasGrap[], allPayments: IInvoicePayments[]) {
    let totalADD = 0, totalDSO = 0, totalBPDSO = 0;
    const invoiceData = this.getDataFromInvoices(invoices);
    const customers = this.converseArrayService.reduceArrays(invoiceData.arrayCustomerName);
    const groupInvoices = this.converseArrayService.reduceArrays(invoiceData.arrayInvoiceIds);
    const creditNotes = this.detailsPortfolioService.processNotes(notes, groupInvoices, "Credito");
    const debitNotes = this.detailsPortfolioService.processNotes(notes, groupInvoices, "Debito");
    const invoicePayments = this.detailsPortfolioService.processPays(allPayments, groupInvoices);
    const totalsCalculated = this.calculateTotals(
      customers, invoiceData.arrayInit, creditNotes, debitNotes, invoicePayments
    );
    const calculatedByCustomer = this.calculateByCustomer(
      customers, invoiceData.arrayInit, creditNotes, debitNotes, invoicePayments, totalsCalculated.allTotal
    );
    const calculateADD = this.detailsPortfolioService.calculateADD(
      calculatedByCustomer.allDetails, totalsCalculated.allTotal, totalsCalculated.allTotalWitoutPays
    );
    totalADD = calculateADD.ADD;
    totalDSO = calculateADD.DSO;
    totalBPDSO = calculateADD.BPDSO;
    this.dataTable = calculatedByCustomer.arrayEnd;
    this.total = new Intl.NumberFormat("de-DE").format(totalsCalculated.allTotal);
    this.totalADD = new Intl.NumberFormat("de-DE").format(totalADD);
    this.totalDSO = new Intl.NumberFormat("de-DE").format(totalDSO);
    this.totalBPDSO = new Intl.NumberFormat("de-DE").format(totalBPDSO);
    this.spinner.hide();
  }

  calculateDetails(
    filtered: ITableData, customerDetailsData: ICustomerDetailsData,
    allCreditNotes: INote[], allDebitNotes: INote[], allInvoicePayments: IInvoicePayments[]
  ) {
    filtered.details?.forEach((detail) => {
      customerDetailsData.invoice = detail.invoice;
      customerDetailsData.invoice_number = detail.invoice_number!;
      customerDetailsData.electronic_number = detail.electronic_number!;
      customerDetailsData.dueDate = detail.dueDate;
      customerDetailsData.createdDate = detail.createdDate;
      customerDetailsData.totalInvoice = parseFloat(detail.total.toFixed(2));
      allCreditNotes.forEach((credit) => {
        if (credit.fact === detail.invoice) {
          customerDetailsData.creditNotes.push(credit);
          customerDetailsData.totalCredit += parseFloat(credit.total.toFixed(2)) * -1;
        }
      });
      allDebitNotes.forEach((debit) => {
        if (debit.fact === detail.invoice) {
          customerDetailsData.debitNotes.push(debit);
          customerDetailsData.totalDebit += parseFloat(debit.total.toFixed(2));
        }
      });
      allInvoicePayments.forEach((payment) => {
        if (payment.id_factura.toString() === detail.invoice) {
          customerDetailsData.payments.push(payment);
          customerDetailsData.totalPayment += parseFloat(payment.value.toFixed(2));
        }
      });
      customerDetailsData.totalPaid = 
        customerDetailsData.totalInvoice + customerDetailsData.totalDebit - 
        customerDetailsData.totalCredit - customerDetailsData.totalPayment;
    });
    return customerDetailsData;
  }

  createDetailsObject(customerDetailsData: ICustomerDetailsData) {
    return {
      invoice: customerDetailsData.invoice,
      invoice_number: customerDetailsData.invoice_number,
      electronic_number: customerDetailsData.electronic_number,
      dueDate: customerDetailsData.dueDate,
      createdDate: customerDetailsData.createdDate,
      total: customerDetailsData.totalInvoice,
      totalPaid: parseFloat(customerDetailsData.totalPaid.toFixed(2)),
      paid: customerDetailsData.payments,
      creditNote: customerDetailsData.creditNotes,
      debitNote: customerDetailsData.debitNotes,
    }
  }

  calculateByCustomer(
    customers: string[], arrayInit: ITableData[], allCreditNotes: INote[], 
    allDebitNotes: INote[], allInvoicePayments: IInvoicePayments[], allTotal: number
  ) {
    let arrayEnd: ITableData[] = [];
    let allDetails: IDetails[] = [];
    customers.forEach((customer) => {
      let customerData: ICustomerData = {
        sellersArray: [],
        sellersFiltered: [],
        details: [],
        customer: '',
        totalCustomer: 0,
        documentCustomer: '',
        totalWitoutPaysByCustomer: 0
      }
      const filterByCustomer = arrayInit.filter((data: any) => data.customer === customer);
      filterByCustomer.forEach((filtered) => {
        let customerDetailsData: ICustomerDetailsData = {
          dueDate: '',
          createdDate: '',
          invoice_number: '',
          invoice: '',
          electronic_number: '',
          totalDebit: 0,
          totalCredit: 0,
          totalInvoice: 0,
          totalPayment: 0,
          totalPaid: 0,
          payments: [],
          creditNotes: [],
          debitNotes: []
        }
        const settedData = this.setCustomerData(
          customerData, filtered, customerDetailsData, allCreditNotes, 
          allDebitNotes, allInvoicePayments, allDetails
        )
        customerData = settedData.customerData;
        customerDetailsData = settedData.customerDetailsData;
        allDetails = settedData.allDetails;
      });
      const averagesByCustomer = this.calculateAverages(allTotal, customerData);
      arrayEnd.push(this.createFinalObject(
        customerData, averagesByCustomer.daysPastDueCalculated, 
        averagesByCustomer.calculateADDByCustomer
      ));
    });
    return { arrayEnd: arrayEnd, allDetails: allDetails }
  }

  setCustomerData(
    customerData: ICustomerData, filtered: ITableData, 
    customerDetailsData: ICustomerDetailsData, allCreditNotes: INote[], 
    allDebitNotes: INote[], allInvoicePayments: IInvoicePayments[],
    allDetails: IDetails[]
  ) {
    let detailsObject = {} as IDetails
    customerData.customer = filtered.customer;
    customerData.documentCustomer = filtered.code;
    customerData.sellersArray.push(filtered.seller![0]);
    customerData.sellersFiltered = this.converseArrayService.reduceArrays(customerData.sellersArray);
    customerDetailsData = this.calculateDetails(
      filtered, customerDetailsData, allCreditNotes, allDebitNotes, allInvoicePayments
    );
    customerData.totalCustomer += parseFloat(customerDetailsData.totalInvoice.toFixed(2));
    customerData.totalCustomer += parseFloat(customerDetailsData.totalDebit.toFixed(2));
    customerData.totalCustomer -= parseFloat(customerDetailsData.totalCredit.toFixed(2));
    customerData.totalCustomer -= parseFloat(customerDetailsData.totalPayment.toFixed(2));
    customerData.totalWitoutPaysByCustomer += parseFloat(customerDetailsData.totalInvoice.toFixed(2));
    customerData.totalWitoutPaysByCustomer += parseFloat(customerDetailsData.totalDebit.toFixed(2));
    customerData.totalWitoutPaysByCustomer -= parseFloat(customerDetailsData.totalCredit.toFixed(2));
    detailsObject = this.createDetailsObject(customerDetailsData);
    customerData.details.push(detailsObject);
    allDetails.push(detailsObject);
    return { customerData: customerData, customerDetailsData: customerDetailsData, allDetails: allDetails }
  }

  createFinalObject(customerData: ICustomerData, daysPastDueCalculated: any, calculateADDByCustomer: any) {
    return {
      code: customerData.documentCustomer,
      customer: customerData.customer,
      seller: customerData.sellersFiltered,
      total: "$" + customerData.totalCustomer.toFixed(2),
      details: daysPastDueCalculated.details,
      daysPastDue: daysPastDueCalculated.daysPastDue,
      DSO: calculateADDByCustomer.DSO,
      BPDSO: calculateADDByCustomer.BPDSO,
      ADD: calculateADDByCustomer.ADD,
    }
  }

  calculateAverages(allTotal: number, customerData: ICustomerData) {
    const daysPastDueCalculated = this.detailsPortfolioService.calculateDaysPastDue(
      allTotal,
      parseFloat(customerData.totalCustomer.toFixed(2)),
      parseFloat(customerData.totalWitoutPaysByCustomer.toFixed(2)),
      customerData.details
    );
    const calculateADDByCustomer = this.detailsPortfolioService.calculateADD(
      daysPastDueCalculated.details,
      parseFloat(customerData.totalCustomer.toFixed(2)),
      parseFloat(customerData.totalWitoutPaysByCustomer.toFixed(2))
    );
    return { daysPastDueCalculated: daysPastDueCalculated, calculateADDByCustomer: calculateADDByCustomer }
  }

  openDialog(details: IDetails[]) {
    this.dialog.open(DialogComponent, {
      data: { detailsPayment: details },
    });
  }

  displayFnSeller(seller: any) {
    return seller && seller ? seller.nombre : undefined;
  }

  export() {
    this.spinner.show();
    const element = document.getElementById("season-tble");
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const book: XLSX.WorkBook = XLSX.utils.book_new();
    this.reportsService.export(book, worksheet, "Report.xlsx");
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
