import { Pipe, PipeTransform } from '@angular/core';
import { IResultTabs } from 'src/app/@interfaces/csv/csv.interface';

@Pipe({
  name: 'filterCustomerCsv'
})
export class FilterCustomerCsvPipe implements PipeTransform {

  transform(customer: IResultTabs[], searchValue: string): IResultTabs[] {
    return customer.filter(customer =>
      customer.nit!.toString().toLocaleLowerCase()===(searchValue.toLocaleLowerCase()))
  }

}
