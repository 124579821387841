import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TaxValidationsService {
  constructor() {}

  getContributorTax(id_contributor: number, value: number) {
    return id_contributor === 7 ? 0 : value;
  }
}
