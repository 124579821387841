import { Pipe, PipeTransform } from '@angular/core';
import { IQuotation } from 'src/app/@interfaces/quotation.interface';
import { IVentaQuery } from '../../../../@interfaces/venta.interface';

@Pipe({
  name: 'filterCISales'
})
export class FilterCISales implements PipeTransform {
  transform(sales: IVentaQuery[], customer: string, item: string): IVentaQuery[] {
    return sales.filter(sale => 
        sale.factura!.length > 0 &&
        sale.codigo === item &&
        sale.factura![0].id_cliente?.toString() === customer
    )
  }
}
