import { Injectable } from "@angular/core";
import { IDateFormat } from "src/app/@interfaces/Siigo/format-date-siigo.interface";

@Injectable({
  providedIn: "root",
})
export class ConverseDateService {
  constructor() {}

  getDateSeparated(createdAt: string, invoiced_date?: string): IDateFormat {
    const date = invoiced_date ? new Date(invoiced_date!) : new Date(createdAt);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return {
      day: day.toString(),
      month: month.toString(),
      year: year.toString(),
    };
  }

  getFormattedDate(excelDate: number) {
    const utcDays = Math.floor(excelDate - 25569);
    const utcValue = utcDays * 86400;
    const localOffset = new Date().getTimezoneOffset() * 60;
    const dateInfo = new Date((utcValue + localOffset) * 1000);
    const month = dateInfo.getMonth() + 1;
    const day = dateInfo.getDate();
    const year = dateInfo.getFullYear();
    return `${month < 10 ? "0" + month : month}/${
      day < 10 ? "0" + day : day
    }/${year}`;
  }

  getDatesRange(date: Date, years: number) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const reducedYear = year - years;
    return {
      currentDate: `${year}/${month}/${day}`,
      reducedDate: `${reducedYear}/01/01`,
    };
  }
}
