import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import * as $ from "jquery";
import { ICompany } from "src/app/@interfaces/company.interface";
import { IRol } from "src/app/@interfaces/rol.interface";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import { CompanyService } from "../company/company.service";
import { IUser } from "src/app/@interfaces/user.interface";
import { HttpClient } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { Subject, of } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import { IDocumenType } from "src/app/@interfaces/authentication/documenType";
import { UsersService } from "../users/users.service";
import { ForgotPasswordComponent } from "src/app/@shared/components/dialog/authentication/forgot-password/forgot-password.component";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { AlertsService } from "src/app/@shared/components/services/alerts/alerts.service";

function importLoginAimation(): any {
  const animation = require("../login/animation.js");
  return animation;
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, OnDestroy {
  rolList: Array<IRol> = [];
  companyList: Array<ICompany> = [];
  userMe = {} as IUser;
  loginError: string = "";
  signUpError: string = "";
  documenTypeList: Array<IDocumenType> = [];
  forgotPasswordDialog: MatDialogConfig<ForgotPasswordComponent> = {};
  loginForm: UntypedFormGroup = this.fb.group({
    email: ["", Validators.required],
    password: ["", Validators.required],
  });
  registerForm: UntypedFormGroup = this.fb.group(
    {
      name: ["", [Validators.required, Validators.maxLength(50)]],
      username: ["", Validators.required],
      types_docs: ["", Validators.required],
      document: ["", [Validators.required, Validators.pattern(this.validatorS.documentPattern),],],
      email: ["", [Validators.required, Validators.pattern(this.validatorS.emailPattern)],],
      password: ["", [Validators.required, Validators.pattern(this.validatorS.passwordPattern),],],
      passwordConfirmation: ["", Validators.required],
    },
    {
      validators: [this.validatorS.equalFields("password", "passwordConfirmation")],
    }
  );
  unsubscribe$ = new Subject();
  constructor(
    private userService: UsersService,
    private companyS: CompanyService,
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    public dialog: MatDialog,
    public http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private translate: TranslateService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService,
    private alertsService: AlertsService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    $(document).ready(function () {
      "use strict";
      window.onload = function () {
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
          $(".form form label").addClass("fontSwitch");
        }
        $("input").focus(function () {
          $(this).siblings("label").addClass("active");
        });
      };
    });
    importLoginAimation();
    this.companyS
    .getAllCompanies()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.companyList = result;
    });

    this.userService
    .listDocumenTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      const docTypesNameToShow = ['NIT', 'NIT de Extranjería', 'Tarjeta de identidad', 'Cédula de ciudadanía', 'Cédula de extranjería', 'Pasaporte']
      this.documenTypeList = result.filter((type: any) => docTypesNameToShow.includes(type.description));
    });
  }

  login(): void {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      this.loginError = this.translate.instant('Email and password fields are required');
      return;
    }
    this.spinnerService.show();
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    this.authService.login(email, password)
    .pipe(takeUntil(this.unsubscribe$),
      catchError(err => {
        return this.onLoginErrors(err);
      }))
    .subscribe((result) => {
      this.onLoginSuccess(result);
    })
  }

  onLoginErrors(err: any) {
    if (err.graphQLErrors) {
      const [graphQLError] = err.graphQLErrors
      this.loginError = graphQLError.message;
      this.spinnerService.hide();
      return of(err.graphQLErrors)
    }
    this.spinnerService.hide();
    return of(err)
  }

  async onLoginSuccess(result: any) {
    if (result.login) {
      const user = result.login.user;
      const [rol] = user.roles;
      this.cookieAuthService.setSessionStorageUserIds(
        user.id, rol.Id_rol, result.login.token
      );
      this.cookieAuthService.setUserId();
      this.cookieAuthService.setRolId();
      this.cookieAuthService.setToken();
      this.authService.checkAuthStatus();
      this.spinnerService.hide();
    }
  }

  register() {
    if (this.registerForm.invalid == true) {
      this.registerForm.markAllAsTouched();
      this.signUpError = this.translate.instant("Please check your data and try again.");
      return;
    } else {
      this.userService
      .registerUser(
        parseFloat(this.registerForm.value.document),
        this.registerForm.value.name,
        this.registerForm.value.email,
        this.registerForm.value.username,
        this.registerForm.value.password,
        5,
        parseInt(this.registerForm.value.types_docs.id_docs),
        3,
        3
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result: any) => {
          if (result) {
            this.registerForm.reset();
            this.alertsService.showSuccessfullAlert("An administrator has received a notification to grant you access soon. You will be notified once you can log in with the corresponding role.", "User created successfully.")
          }
        },
        error: (err) => {
          if (err.graphQLErrors) {
            const [graphQLError] = err.graphQLErrors;
            return this.alertsService.showWarningAlert(this.translate.instant(graphQLError.message));
          }
            return this.alertsService.showErrorAlert(
            this.translate.instant('Something went wrong. Please check with an administrator')
        );
        }
      });
    }
  }

  resetPass() {
    this.dialog.open(ForgotPasswordComponent, {
      data: { forgotPassword: true, documentTypeList: this.documenTypeList }
    });
  }

  textValid(text: string) {
    return (
      this.registerForm.get(text)?.invalid &&
      this.registerForm.get(text)?.touched
    );
  }

  switch() {
    importLoginAimation();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
