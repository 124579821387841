import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from '../../../../@interfaces/quotation.interface';

@Pipe({
  name: 'filterCQuots'
})
export class FilterCQuots implements PipeTransform {
  transform(quotaions: IQuota[], customer: string): IQuota[] {
    return quotaions.filter(quotation => 
      quotation.id_cliente?.toString() === customer
    )
  }
}
