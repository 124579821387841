import { gql } from "apollo-angular";
import { NOTE_OBJECT, PAYMENT_INVOICE_OBJECT, PAYMENT_PURCHASES_OBJECT, PURCHASE_INVOICE_OBJECT, QUOTATIONS_OBJECT, REFUND_OBJECT } from "./fragment";

export const GET_ALL_AP_PURCHASES = gql`
    query getAllApPurchases($year: Int, $company: String) {
        getAllApPurchases(year: $year, company: $company) {
            ...PurchaseInvoiceObject
        }
    }
    ${PURCHASE_INVOICE_OBJECT}
`;

export const GET_ALL_AP_REFUNDS = gql`
    query getAllApRefunds($year: Int, $company: String) {
        getAllApRefunds(year: $year, company: $company) {
            ...RefundObject
        }
    }
    ${REFUND_OBJECT}
`;

export const GET_ALL_AP_PURCHASEPAYMENTS = gql`
    query getAllApPurchasePayments($year: Int, $company: String) {
        getAllApPurchasePayments(year: $year, company: $company) {
            ...PayPurchasesObject
        }
    }
    ${PAYMENT_PURCHASES_OBJECT}
`;

export const GET_ALL_AR_INVOICES = gql`
    query getAllArInvoices($year: Int, $id_user: Int, $company: String) {
        getAllArInvoices(year: $year, id_user: $id_user, company: $company) {
            ...QuotationsObject
        }
    }
    ${QUOTATIONS_OBJECT}
`;

export const GET_ALL_AR_NOTES = gql`
    query getAllArNotes($year: Int, $company: String) {
        getAllArNotes(year: $year, company: $company) {
            ...NoteObject
        }
    }
    ${NOTE_OBJECT}
`;

export const GET_ALL_AR_INVOICEPAYMENTS = gql`
    query getAllArInvoicePayments($year: Int, $company: String) {
        getAllArInvoicePayments(year: $year, company: $company) {
            ...PayInvoiceObject
        }
    }
    ${PAYMENT_INVOICE_OBJECT}
`;
