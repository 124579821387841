<mat-dialog-content>
    <h2><strong>{{'Presentation List' | translate}}</strong></h2>
    <form>
        <table *ngIf="listPresentations.length > 0" class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>
                        <mat-label>Item</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Presentation' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Options' | translate}}</mat-label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let present of listPresentations; let i = index">
                    <td data-label="Item">
                        {{i}}
                    </td>
                    <td data-label="Presentation">
                        {{present.value}}
                    </td>
                    <td data-label="Options">
                        <button (click)="remove(present.id_type, i)" mat-raised-button>
                             x
                        </button>
                    </td>
                </tr>       
            </tbody>
        </table><br>
        <div *ngIf="listPresentations.length <= 0" class="alert alert-danger">
            {{'NO RECORDS FOUND' | translate}}
        </div><br>
        <form [formGroup]="registerForm">
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th>
                            <mat-label>{{'Add Presentation' | translate}}</mat-label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="Add Presentation">
                            <input style="width: 400px;" class="form-control" type="text" formControlName="presentation">
                            <span class="form-text text-danger" *ngIf="textValid('presentation')">{{'Presentation invalid' | translate}}</span>
                        </td>
                    </tr>
                </tbody>
            </table><br>
            <button mat-raised-button (click)="update()" style="margin-left: 20px; margin-bottom: 20px;">{{'Add' | translate}}</button>
            <button mat-raised-button mat-dialog-close style="margin-left: 20px; margin-bottom: 20px">{{'Close' | translate}}</button>
        </form>
    </form>
</mat-dialog-content>

