import { Injectable } from "@angular/core";
import { ApiService } from "src/app/@graphql/services/api.service";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/internal/operators/map";
import {
  GET_ALLANALYSIS_INVOICES,
  GET_ALLHOME_INVOICES,
  GET_ALL_GRAPHIC_NOTES,
  GET_INVOICES_THIS_MONTH,
} from "src/app/@graphql/operations/graphicQueries";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class GraphicQueriesService extends ApiService {

  constructor(apollo: Apollo, private cookieAuthService: CookieAuthService) {
    super(apollo);
  }

  getAllHomeInvoices(startDate: string, endDate: string, id_user: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALLHOME_INVOICES, {
      startDate,
      endDate,
      id_user,
      company,
    }).pipe(
      map((result: any) => {
        return result.getAllHomeInvoices;
      })
    );
  }

  getAllGraphicNotes() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_GRAPHIC_NOTES, { company }).pipe(
      map((result: any) => {
        return result.getAllGraphicNotes;
      })
    );
  }

  getQuotToday(id_user: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_INVOICES_THIS_MONTH, { id_user, company }).pipe(
      map((result: any) => {
        return result.getQuotToday;
      })
    );
  }

  getAllAnalysisInvoices(startDate: string, endDate: string, id_user: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALLANALYSIS_INVOICES, {
      startDate,
      endDate,
      id_user,
      company,
    }).pipe(
      map((result: any) => {
        return result.getAllAnalysisInvoices;
      })
    );
  }
}
