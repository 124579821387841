import { Injectable } from '@angular/core';
import { billyErrors } from './billyErrors';

@Injectable({
  providedIn: 'root'
})

export class BillyErrorsService {
  billyErrors = billyErrors;

  findErrorByStatus(status: string, searchText: string): { title: string, detail: string } | null {
    for (let key of this.billyErrors) {
      if (status === key.status) {
        const error = key.errors.find((error) => error.detail === searchText);
        if (error) {
          return { title: error.title, detail: error.detail };
        }
      }
    }
    return null;
  }
}
