<mat-dialog-content>
    <div class="row">
        <img src="../../../../../assets/icons/icono.png" onerror="this.src='assets/img/cantfindimage.jpg'"
            style="margin-left: 1px; width: 20px; height: 20px;">
        <div class="col-md-2" style="margin-top: 10px">
            <h2 mat-dialog-tittle>{{'All Refunds' | translate}}</h2>
        </div>
    </div>
    <form [formGroup]="refundForm">
        <table class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>
                        <mat-label>Item</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Provider' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Concept' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Date' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Due Date' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Total' | translate}} {{'Price' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Details' | translate}}</mat-label>
                    </th>
                    
                </tr>
            </thead>
            <tbody>  
                <ng-container formArrayName="itemRows" class="row">
                    <ng-container *ngFor="let itemrow of rowsArr.controls; let i=index" [formGroupName]="i">
                    <tr>
                       <td data-label="Item">
                           {{i+1}}
                       </td>

                        <td data-label="Provider">
                            <input readonly type="text" matInput [matAutocomplete]="autoProvider" [formControl]="provider">
                            <mat-autocomplete #autoProvider="matAutocomplete" [displayWith]="displayFnProvider">
                            </mat-autocomplete>
                        </td>

                        <td data-label="Concept">  
                            <input readonly matInput  formControlName="concept" autocomplete="off">
                        </td>

                        <td data-label="Date">
                            <mat-datepicker #picker></mat-datepicker>
                            <input readonly matInput [matDatepicker]="picker" formControlName="createAt" autocomplete="off"> 
                        </td>

                        <td data-label="Due Date">
                            <mat-datepicker #picker2></mat-datepicker>
                            <input readonly matInput [matDatepicker]="picker2" formControlName="dueDate" autocomplete="off">
                        </td>

                        <td data-label="Total Price" style="min-height: 50px;">
                            <input readonly matInput  formControlName="total" autocomplete="off">
                        </td>
                        <td data-label="Details" style="min-height: 50px;">
                            <div style="float: right;">
                                <a [routerLink]="['/details_refunds',purchaseInvoice.id_invoice,rowsArr.value[i].ids]">
                                    <mat-icon (click)="closeDialog()" class="black-icon">launch</mat-icon>
                                </a>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </table><br>
        <div class="row">
            <div class="col-md-1">
                <button mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
            </div>
        </div>
    </form>
</mat-dialog-content>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)" >
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>



