import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IUser } from "src/app/@interfaces/user.interface";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { UsersService } from "./users.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit, OnDestroy {
  UserList: Array<IUser> = [];
  pageSliceAll = this.UserList.slice(0, 4);
  companyId = '';
  unsubscribe$ = new Subject();
  constructor(
    private user: UsersService,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.spinnerService.show();
    this.user
    .listUsers()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.UserList = result;
      this.spinnerService.hide();
    });
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  OnPageChangeAll(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.UserList.length) {
      endIndex = this.UserList.length;
    }
    this.pageSliceAll = this.UserList.slice(startIndex, endIndex);
  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      data: { user: true },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
