import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { PageEvent } from "@angular/material/paginator";
import { AuthService } from "../../@shared/authentication/auth.service";
import { IPaymentsList } from "../../@interfaces/payments.interface";
import { IMailList } from "src/app/@interfaces/lists.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { EventEmitterService } from "src/app/@core/menu/emitterFuncions.service";
import { PurchasesService } from "../purchases/purchases.service";
import { IPurchasesInvoices } from "src/app/@interfaces/purchaseInvoice.interface";
import { FilterPurchasePipe } from "../../@shared/pipe/filter-purchases.pipe";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-accounts-payable",
  templateUrl: "./accounts-payable.component.html",
  styleUrls: ["./accounts-payable.component.css"],
})
export class AccountsPayableComponent implements OnInit {
  PurchasesListByStatus10: Array<IPurchasesInvoices> = [];
  filterValueS10 = "";

  PurchasesListByStatus30: Array<IPurchasesInvoices> = [];
  filterValueS30 = "";

  pageSliceByS10 = this.PurchasesListByStatus10.slice();
  firstBy10: Array<IPurchasesInvoices> = [];

  pageSliceByS30 = this.PurchasesListByStatus10.slice();
  firstBy30: Array<IPurchasesInvoices> = [];

  companyId = '';
  user = '';
  rol = '';

  listBanks: Array<IPaymentsList> = [];
  listCashiers: Array<IPaymentsList> = [];
  listMails: Array<IMailList> = [];
  disableButton = false;
  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;

  constructor(
    private eventEmitterService: EventEmitterService,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private filterPurchase: FilterPurchasePipe,
    private purchases: PurchasesService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.spinner();
    this.data();
    this.validButtons();
    this.toggleExpandCard();
    this.getEvent();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((name: string) => {
      this.toggleExpandCard();
    });
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 250);
  }

  data() {
    this.purchases
    .listPurchasesByStatus10()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.PurchasesListByStatus10 = result;
      this.pageSliceByS10 = result.slice(0, 4);
    });
    this.purchases
    .listPurchasesByStatus30()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.PurchasesListByStatus30 = result;
      this.pageSliceByS30 = result.slice(0, 4);
    });
  }

  validButtons() {
    if (this.rol === "1" || this.rol === "3") {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  openDialogMails(registerMails: boolean, listMails: IMailList[], groupMails: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { registerMails, listMails, groupMails },
    });
  }

  openDialogBanks(registerBanks: boolean, listBanks: IPaymentsList[], groupPayment: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { registerBanks, listBanks, groupPayment },
    });
  }

  openDialogCashiers(registerCashiers: boolean, listCashiers: IPaymentsList[], groupCashier: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { registerCashiers, listCashiers, groupCashier },
    });
  }

  OnPageChangeByS10(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.PurchasesListByStatus10.length) {
      endIndex = this.PurchasesListByStatus10.length;
    }
    this.pageSliceByS10 = this.PurchasesListByStatus10.slice(
      startIndex,
      endIndex
    );
  }

  OnPageChangeByS30(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.PurchasesListByStatus30.length) {
      endIndex = this.PurchasesListByStatus30.length;
    }
    this.pageSliceByS30 = this.PurchasesListByStatus30.slice(
      startIndex,
      endIndex
    );
  }

  handleSearchS10(value: string) {
    if (!(this.firstBy10.length > this.pageSliceByS10.length)) {
      this.firstBy10 = this.pageSliceByS10;
    }
    if (value) {
      this.filterValueS10 = value;
      this.filter10();
    } else {
      this.pageSliceByS10 = this.firstBy10.slice(0, 4);
    }
  }

  handleSearchS30(value: string) {
    if (!(this.firstBy30.length > this.pageSliceByS30.length)) {
      this.firstBy30 = this.pageSliceByS30;
    }
    if (value) {
      this.filterValueS30 = value;
      this.filter30();
    } else {
      this.pageSliceByS30 = this.firstBy30.slice(0, 4);
    }
  }

  filter10() {
    const filter = this.filterPurchase.transform(
      this.PurchasesListByStatus10,
      this.filterValueS10
    );
    this.pageSliceByS10 = filter;
  }

  filter30() {
    const filter = this.filterPurchase.transform(
      this.PurchasesListByStatus30,
      this.filterValueS30
    );
    this.pageSliceByS30 = filter;
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
