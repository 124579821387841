import { Injectable } from "@angular/core";
import { IContFact } from "src/app/@interfaces/contfac.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { GenerateDetailsPDFService } from "./generate-details-pdf.service";
import { Driver } from "src/app/@interfaces/driver.interface";
import * as pdfMake from "pdfmake/build/pdfmake";

@Injectable({
  providedIn: "root",
})
export class GenerateLetterheadPdfService {
  constructor(private generateDetailsPDFService: GenerateDetailsPDFService) {}

  generateLetterheadPDF(
    invoiceObject: IQuota,
    invoiceCount: IContFact,
    sales: IVentaQuery[],
    subtotal: number,
    taxCalculated: number,
    discount: number,
    total: number,
    rateBCV: number,
    driverObject: Driver
  ) {
    const invoiceDate =
      this.generateDetailsPDFService.getInvoiceDate(invoiceObject);
    const items = this.getSales(sales, rateBCV, invoiceObject);
    const pdfDefinition: any = {
      content: [
        {
          style: "tableData",
          color: "#444",
          table: {
            widths: ["auto", "auto", "*", "auto", "auto"],
            headerRows: 2,
            body: [
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Nombre",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `${invoiceObject.cliente[0].nombre}`,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: ``,
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "N°. Control",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Cod. Cliente",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `${invoiceObject.cliente[0].doc_nit}`,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: ``,
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: this.getInvoiceStatus(invoiceObject),
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: this.getInvoiceNumber(invoiceCount, invoiceObject),
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Direccion",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `${invoiceObject.cliente![0].direccion}`,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: ``,
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "CUA",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `${invoiceDate}`,
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "N° RIF",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `${invoiceObject.cliente![0].email}`,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: ``,
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Telf",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `${invoiceObject.cliente![0].telefono}`,
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Ciudad",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: ``,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: ``,
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Condiciones de Pago",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: ``,
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: ``,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: ``,
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Vendedor",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `${invoiceObject.seller![0].nombre}`,
                  alignment: "right",
                },
              ],
            ],
          },
        },
        {
          style: "itemsTable",
          table: {
            widths: ["auto", "*", "auto", "auto", "auto", "auto", "auto"],
            body: items,
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
        {
          style: "tableData",
          color: "#444",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  style: "header",
                  border: [false, true, false, false],
                  text: "",
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
        {
          style: "tableData",
          color: "#444",
          table: {
            widths: [250, "auto"],
            headerRows: 1,
            body: [
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Base Imponible en Moneda Extranjera USA $",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `$ ${subtotal.toFixed(2)}`,
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: `Monto IVA USA $ ${invoiceObject.tax}`,
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `$ ${taxCalculated.toFixed(2)}`,
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Total Factura US$",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `$ ${total.toFixed(2)}`,
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Tipo de Cambio de Referencia BCV",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `${rateBCV}`,
                },
              ],
            ],
          },
        },
        invoiceObject.estado === 4
          ? {
              style: "tableData",
              color: "#444",
              table: {
                widths: ["auto"],
                body: [
                  [
                    {
                      style: "textStyle",
                      border: [true, true, true, true],
                      text: `
                        Esta factura se emite de conformidad con lo establecido en el articulo 25 de la Ley del IVA, Articulo 13, Num 14 de la Providencia
                        Administrativa SNAT-2011-00071, emitida por el SENIAT, que establece las Normas Generales sobre Facturas y otros Documentos,
                        en concordancia con lo establecido en el Convenio Cambiario Nro 1, Gaceta Oficial Extraordianria Nro 6.405 de la fecha 07/09/2018.
                        Las partes acuerdan que el pago de la misma puede hacerse en moneda extranjera o en moneda nacional, a la Tasa de Cambio fijada
                        por el BCV para la Fecha de su pago.
                      `,
                    },
                  ],
                ],
              },
              layout: {
                hLineColor: function (i: any, node: any) {
                  return "grey";
                },
                vLineColor: function (i: any, node: any) {
                  return "grey";
                },
              },
            }
          : {
              style: "textStyle",
              border: [false, false, false, false],
              text: "",
            },
        {
          style: "tableData",
          color: "#444",
          table: {
            widths: [100, "auto", "*", 100, "auto"],
            headerRows: 2,
            body: [
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Chofer:",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: driverObject.chofer,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Total Renglones",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `Bs ${(subtotal * rateBCV).toFixed(2)}`,
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "C.I.N°:",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: driverObject.cin,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "% Descuento",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `Bs ${(discount * rateBCV).toFixed(2)}`,
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Placa:",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: driverObject.placa,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Sub Total",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `Bs ${(subtotal * rateBCV).toFixed(2)}`,
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Permiso Rasda:",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: driverObject.permiso_rasda,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: `IVA ${invoiceObject.tax} %`,
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `Bs ${(taxCalculated * rateBCV).toFixed(2)}`,
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Destino:",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: driverObject.destino,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Total a Pagar",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: `Bs ${(total * rateBCV).toFixed(2)}`,
                  alignment: "right",
                },
              ],
              [
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "Peso Neto:",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: driverObject.peso_neto,
                  alignment: "right",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "center",
                },
                {
                  style: "header",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "left",
                },
                {
                  style: "textStyle",
                  border: [false, false, false, false],
                  text: "",
                  alignment: "right",
                },
              ],
            ],
          },
        },
        invoiceObject.estado === 4
          ? {
              style: "tableData",
              color: "#444",
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: "*** ESTA FACTURA VA SIN TACHADURAS NI ENMIENDAS ***",
                    },
                  ],
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: "Observaciones",
                    },
                  ],
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: "___________________________________",
                      alignment: "center",
                      margin: [0, 10, 0, 0],
                    },
                  ],
                  [
                    {
                      style: "textStyle",
                      border: [false, false, false, false],
                      text: "Recibi Conforme",
                      alignment: "center",
                    },
                  ],
                  [
                    {
                      style: "textStyle",
                      border: [false, false, false, false],
                      text: `SON: ${driverObject.son}`,
                      margin: [0, 10, 0, 10],
                    },
                  ],
                  [
                    {
                      style: "textStyle",
                      border: [true, true, true, true],
                      text: `
                        * Las Mercancias viajan por cuenta del comprador.
                        * Pasados ocho dias no admitimos reclamos.
                        * Se elige la ciudad de Caracas, para todos los efectos legales.
                        * La modificación del tipo de cambio, originará el respectivo ajuste fiscal
                        * Para pagos en Moneda Extranjera afectuar transferencia a la siguiente:
                        * BANCO OCEAN BANK. CUENTA N° 2517328605
                        * N° ABA: 0650011392. CODIGO SWIFT. OCBKUS3M
                      `,
                    },
                  ],
                ],
              },
              layout: {
                hLineColor: function (i: any, node: any) {
                  return "grey";
                },
                vLineColor: function (i: any, node: any) {
                  return "grey";
                },
              },
            }
          : {
              style: "textStyle",
              border: [false, false, false, false],
              text: "",
            },
      ],
      styles: this.getStyles(),
      pageMargins: [40, 130, 40, 40],
      pageSize: "B4",
    };
    const pdf = pdfMake.createPdf(pdfDefinition);
    pdf.open({}, window);
  }

  getInvoiceStatus(invoiceObject: IQuota) {
    let text = "";
    switch (invoiceObject.estado) {
      case 1:
        text = `N°. Cotizacion`;
        break;
      case 2:
        text = `N°. Cotizacion`;
        break;
      case 3:
        text = `N°. Factura`;
        break;
      case 4:
        text = `N°. Factura`;
        break;
      default:
        text = "N/A";
        break;
    }
    return text;
  }

  getInvoiceNumber(invoiceCount: IContFact, invoiceObject: IQuota) {
    let text = "";
    switch (invoiceObject.estado) {
      case 1:
        text = `${invoiceCount.quotation}`;
        break;
      case 2:
        text = `Rechazado`;
        break;
      case 3:
        text = `${invoiceCount.delivery}`;
        break;
      case 4:
        text = `${invoiceCount.invoice}`;
        break;
      default:
        text = "N/A";
        break;
    }
    return text;
  }

  getSales(sales: IVentaQuery[], rateBCV: number, invoiceObject: IQuota) {
    let array: any[] = [];
    sales.forEach((sale, index) => {
      const obj1 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: sale.articulo[0].codigo,
      };
      const obj2 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: sale.articulo[0].descripcion,
      };
      const obj3 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: sale.cantidad,
      };
      const obj4 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: sale.articulo[0].unidad,
      };
      const obj5 = {
        border: [false, false, false, false],
        style: "" + "textStyle",
        text: this.converseValues(sale.precio, rateBCV, invoiceObject),
      };
      const obj6 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: this.converseValues(sale.descuento, rateBCV, invoiceObject),
      };
      const obj7 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: this.converseValues(sale.subtotal, rateBCV, invoiceObject),
      };
      array.push([obj1, obj2, obj3, obj4, obj5, obj6, obj7]);
    });
    const headers = this.getSaleHeaders();
    array.unshift(headers.header);
    const missingItemRows = this.setMissingItemRows(sales.length)
    array = array.concat(missingItemRows);
    return array;
  }

  setMissingItemRows(lenght: number) {
    let items: any[] = [];
    for (let i = 0; i < 11 - lenght; i++) {
      const obj1 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: "",
      };
      const obj2 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: "",
      };
      const obj3 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: "",
      };
      const obj4 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: "",
      };
      const obj5 = {
        border: [false, false, false, false],
        style: "" + "textStyle",
        text: "",
      };
      const obj6 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: "",
      };
      const obj7 = {
        border: [false, false, false, false],
        style: "textStyle",
        text: "",
      };
      items.push([obj1, obj2, obj3, obj4, obj5, obj6, obj7]);
    }
    return items;
  }

  converseValues(value: number, rateBCV: number, invoiceObject: IQuota) {
    if (invoiceObject.estado === 2 || invoiceObject.estado === 3) {
      return `Bs ${(value * rateBCV).toFixed(2)}`;
    } else {
      return `$ ${value}`;
    }
  }

  getSaleHeaders() {
    return {
      header: [
        {
          border: [false, true, false, true],
          text: "Codigo",
          style: "tableHeader",
          alignment: "left",
        },
        {
          border: [false, true, false, true],
          text: "Descripcion",
          style: "tableHeader",
          alignment: "left",
        },
        {
          border: [false, true, false, true],
          text: "Cant",
          style: "tableHeader",
          alignment: "left",
        },
        {
          border: [false, true, false, true],
          text: "Unidad",
          style: "tableHeader",
          alignment: "left",
        },
        {
          border: [false, true, false, true],
          text: "Precio Unitario",
          style: "tableHeader",
          alignment: "left",
        },
        {
          border: [false, true, false, true],
          text: "% Dcto",
          style: "tableHeader",
          alignment: "left",
        },
        {
          border: [false, true, false, true],
          text: "Total",
          style: "tableHeader",
          alignment: "left",
        },
      ],
    };
  }

  getStyles() {
    return {
      header: {
        fontSize: 8,
        bold: true,
        color: "black",
      },
      tableData: {
        margin: [0, 5, 0, 15],
      },
      itemsTable: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: "black",
      },
      textStyle: {
        fontSize: 8,
        color: "#363636",
      },
    };
  }
}
