import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";
import { ReportsService } from "src/app/@pages/reports/reports.service";
import { takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../../dialog.component";

@Component({
  selector: "app-crud-accounting-accounts",
  templateUrl: "./crud-accounting-accounts.component.html",
  styleUrls: ["./crud-accounting-accounts.component.css"],
})
export class CrudAccountingAccountsComponent implements OnInit {
  accountsList: IAccountingAccounts[] = [];
  unsubscribe$ = new Subject();

  constructor(
    private reportsService: ReportsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.reportsService
      .getAllAccountingAccount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.accountsList = result;
      });
  }

  update(id_account: number) {
    this.dialog
    .open(DialogComponent, {
      data: { id_account: id_account },
      disableClose: true,
    })
    .afterClosed()
    .subscribe((res) => {
      this.dialog.open(DialogComponent, {
        data: { accountingAccounts: true },
      });
    });
  }
}
