import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IUser } from "src/app/@interfaces/user.interface";
import { UsersService } from "../users/users.service";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { CompanyService } from "src/app/@pages/company/company.service";
import { IRol } from "src/app/@interfaces/rol.interface";
import { ICompany } from "src/app/@interfaces/company.interface";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../../@shared/components/dialog/dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { AlertsService } from "src/app/@shared/components/services/alerts/alerts.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  profileForm: UntypedFormGroup = this.fb.group({
    name: this.route.snapshot.paramMap.get("id")?.slice(3),
  });

  userForm: UntypedFormGroup = this.fb.group({
    name: ["", Validators.required],
    username: ["", Validators.required],
    document: ["", [Validators.required]],
    email: ["", [Validators.required]],
    rol: [5, Validators.required],
    types_docs: ["", [Validators.required]],
  });

  UserList = {} as IUser;
  companyList: Array<ICompany> = [];
  rolList: Array<IRol> = [];
  unsubscribe$ = new Subject();
  profId: number = parseInt(this.profileForm.value.name);

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private user: UsersService,
    private companyS: CompanyService,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private alertsService: AlertsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinnerService.show();
    await this.authService.checkAuthStatus();
    this.user
      .getUser(this.profId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.UserList = result;
        this.setUser(this.UserList);
      });

    this.user
      .getRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.rolList = result;
      });

    this.companyS
      .getAllCompanies()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.companyList = result;
        this.spinnerService.hide();
      });
  }

  setUser(usetSets: IUser) {
    this.userForm.patchValue({
      name: usetSets.name,
      username: usetSets.username,
      document: usetSets.document,
      email: usetSets.email,
      rol: usetSets.roles[0],
      types_docs: usetSets.types_docs![0],
    });
  }

  update() {
    this.userForm.markAllAsTouched();
    if (this.userForm.invalid === true) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return;
    }
    this.user
      .updateUser(
        this.profId,
        this.userForm.value.document,
        this.userForm.value.name,
        this.userForm.value.email,
        this.userForm.value.username,
        parseInt(this.userForm.value.rol.Id_rol),
        1,
        parseInt(this.userForm.value.types_docs.id_docs),
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.alertsService.showSuccessfullAlert(
            this.userForm.value.name + " ",
            "Good",
            "updated successfully"
          );
          this.alertsService.refresh("/profile", "/user");
        } else {
          this.alertsService.showErrorAlert("something was wrong");
        }
      });
  }

  activateUser(id_user: number) {
    this.alertsService.showConfirmAlert("Do you want activate this user?")
    .then((result) => {
      if (result.isConfirmed) {
        this.user
          .updateUserStatus(id_user, 1)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result) => {
            if (result) {
              this.alertsService.showSuccessfullAlert(
                result.name + " ",
                "Activated!",
                "has been activated"
              )
            } else {
              this.alertsService.showErrorAlert("something was wrong")
            }
          });
      }
    });
  }

  dialogAddCompany(addCompanies: boolean, id_user: number) {
    let valuesForm = this.userForm.value;
    this.dialog.open(DialogComponent, {
      data: { addCompanies, id_user, valuesForm },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
