<mat-dialog-content>
  <div class="row justify-content-md-center">
    <div class="row justify-content-md-center">
      <div class="col col-md-3">
        <a (click)="exportExcel(quotationList)"><button mat-raised-button class="Warn">{{'Export' | translate}}</button></a>
      </div>
      <div class="col col-md-4">
        <a (click)="filter(quotationList)"><button mat-raised-button class="Warn">{{'Preview' | translate}}</button></a>
      </div>
      <div class="col col-md-3">
        <a (click)="closeDialog()"><button mat-raised-button class="Warn">{{'Close' | translate}}</button></a>
      </div>
    </div>
  </div>
  
  <div class="row justify-content-md-center">
    <div class="col-md-auto">
      <mat-form-field appearance="fill">
        <mat-label>{{'Enter a date range' | translate }}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" matTooltip="Info about the action" matTooltipClass="example-tooltip-red">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker> 
        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid start date' | translate}}</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid end date' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-auto">                      
      <mat-form-field>
          <mat-label>{{'Filter by seller' | translate}}</mat-label>
          <ng-container> 
              <input id="input-top" type="text" matInput [matAutocomplete]="autoSeller" [formControl]="sellerForm">
          </ng-container>
          <mat-autocomplete #autoSeller="matAutocomplete" [displayWith]="displayFnSeller">
              <mat-option *ngFor="let seller of filteredOptionsSeller | async" [value]="seller">
                  {{seller.nombre}}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-md-auto">                      
      <a (click)="clean()"><button mat-raised-button class="Warn">{{'Clean' | translate}}</button></a>
    </div>
  </div>

  <div *ngIf="arrayExcel.length > 0">
    <table id="season-tble" class="table table-striped">
      <tr>
        <td>{{companyName}}</td>
      </tr>
      <tr>
        <td>{{'-------------------------------------------------------'}}</td>
      </tr>
      <tr>
        <td>{{'REQUIREMENTS FINISHED PRODUCTS' | translate}}</td>
      </tr>
      <tr>
        <th>{{'Code' | translate}}</th>
        <th>{{'Description' | translate}}</th>
        <ng-container *ngFor="let item of arrayCustomers">
          <th>{{item}}</th>
        </ng-container>
        <th>{{'Total UDS' | translate}}</th>
        <th>{{'Liters' | translate}}</th>
      </tr>
      <tr *ngFor="let item of arrayExcel">
        <td>{{item.code}}</td>
        <td>{{item.description}}</td>
        <ng-container *ngFor="let values of item.values">
          <td>{{values}}</td>
        </ng-container>
        <td>{{item.total}}</td>
        <td id="liters">{{(item.total * item.presentation) | fixed}}</td>
      </tr>
      <tr>
        <td></td>
        <td>{{'TOTAL'}}</td>
        <ng-container *ngFor="let item of arrayTotals">
          <td>{{item}}</td>
        </ng-container>
        <td>{{total}}</td>
        <td>{{calcAllLiters()}}</td>
      </tr>
    </table>
  </div>

</mat-dialog-content>