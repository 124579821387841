import { Pipe, PipeTransform } from '@angular/core';
import { IVentaQuery } from '../../../../@interfaces/venta.interface';

@Pipe({
  name: 'filterFullSales'
})
export class FilterFullSales implements PipeTransform {
  transform(sales: IVentaQuery[], searchValue: string) {
    if (!sales || !searchValue) {
      return sales;
    }
    return sales.filter(sale =>
      sale.factura!.length > 0 &&
      sale.codigo.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      sale.articulo.filter(article => article.descripcion.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
      sale.articulo.filter(article => article.unidad!.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
      sale.articulo.filter(article => article.grupo?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    )
  }
}
