<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/21.2.5/css/dx.common.css" />
<link rel="stylesheet" type="text/css" href="https://cdn3.devexpress.com/jslib/21.2.5/css/dx.light.css" />

<script>window.jQuery || document.write(decodeURIComponent('%3Cscript src="js/jquery.min.js"%3E%3C/script%3E'))</script>
<div class="row">
  <div class="col-sm">
    <div id="carouselExampleControls2" class="carousel slide" data-ride="carousel" data-interval="false">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <dx-vector-map id="vector-map" [bounds]="[-180, 85, 180, -60]" (onClick)="markerClick($event)" #vectormap>
            <dxo-tooltip
              [enabled]="true"
              [customizeTooltip]="customizeTooltip"
              [zIndex]="5000"
            ></dxo-tooltip>
            <dxi-layer [dataSource]="worldMap" [hoverEnabled]="false"></dxi-layer>
            <dxi-layer
              [dataSource]="markers"
              [minSize]="20"
              [maxSize]="40"
              [sizeGroups]="[0, 5000, 24000, 100000]"
              [opacity]="0.8"
              name="bubbles"
              elementType="bubble"
              dataField="value"
            ></dxi-layer>
            <dxi-legend
              markerShape="circle"
              [customizeText]="customizeText"
              [customizeItems]="customizeItems"
            >
              <dxo-source layer="bubbles" grouping="size"></dxo-source>
            </dxi-legend>
          </dx-vector-map>
        </div>
        <div class="carousel-item">
          <dx-map
            provider="bing"
            [height]="430"
            width="100%"
            [controls]="true"
            [markerIconSrc]="markerUrl"
            [markers]="streetMarkers"
            [controls]="true"
            >
          </dx-map>
        </div>
        <div class="carousel-item">
          <dx-map
            provider="bing"
            [height]="430"
            width="100%"
            [controls]="true"
            [markerIconSrc]="prospectiveCustomersMarker"
            [markers]="prospectiveCustomers"
            [controls]="true"
            >
          </dx-map>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>

  <div class="col-sm mat-elevation-z8">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>#</th>
          <th>{{'Icon' | translate}}</th>
          <th [appSort]="arrayData" data-order="asc" data-name="customerName">{{'Address' | translate}}</th>
          <th [appSort]="arrayData" data-order="asc" data-name="usdAmount">YTD {{'Closed Sales' | translate}}<br>({{typePres}})</th>
          <th [appSort]="arrayData" data-order="asc" data-name="pastAmount">YTD {{'Pending Sales' | translate}}<br>({{typePres}})</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of arrayData; let i = index">
          <td>{{i}}</td>
          <td>
            <ng-container *ngIf="!item.isProspective">
              <img [src]="markerUrl" height="28" width="22" matTooltip="{{'Customer' | translate}}" matTooltipPosition="above"> 
            </ng-container>
            <ng-container *ngIf="item.isProspective">
              <img [src]="prospectiveCustomersMarker" height="28" width="19" matTooltip="{{'Prospective' | translate}}" matTooltipPosition="above"> 
            </ng-container>       
          </td>
          <td>{{item.customerName}}</td>
          <td>{{item.usdAmount}}</td>
          <td>{{item.pastAmount}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>



