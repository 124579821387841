import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DetailsPurchasesRoutingModule } from './details-purchases-routing.module';
import { DetailsPurchasesComponent } from './details-purchases.component';
import { MaterialModule } from '../../material.module';
import { StepperModule } from '../../@shared/components/stepper/stepper.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from '../../@shared/components/dialog/dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilterInventoryToAccountingPipe } from 'src/app/@shared/pipe/pipes-csv/inventory-to-accounting.pipe';


@NgModule({
  declarations: [
    DetailsPurchasesComponent
  ],
  imports: [
    CommonModule,
    DetailsPurchasesRoutingModule,
    MaterialModule,
    StepperModule,
    NgxQRCodeModule,
    NgxPrintModule,
    MatIconModule, 
    DialogModule,
    TranslateModule
  ],
  providers: [
    FilterInventoryToAccountingPipe,
  ]
})
export class DetailsPurchasesModule { }
