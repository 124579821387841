import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InventoryRoutingModule } from './inventory-routing.module';
import { InventoryComponent } from './inventory.component';
import { TableModule } from 'src/app/@shared/components/table/table.module';
import { MaterialModule } from 'src/app/material.module';
import { SearchModule } from 'src/app/@shared/components/search/search.module';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FilterInventoryToAccountingPipe } from 'src/app/@shared/pipe/pipes-csv/inventory-to-accounting.pipe';


@NgModule({
  declarations: [InventoryComponent],
  imports: [
    CommonModule,
    InventoryRoutingModule,
    TableModule,
    MaterialModule,
    SearchModule,
    DialogModule,
    MatIconModule,
    TranslateModule,
    NgxSpinnerModule
  ],
  providers: [
    FilterInventoryToAccountingPipe,
  ]
})
export class InventoryModule { }
