import { gql } from "apollo-angular";
import {
  ARTICLES_OBJECT,
  COMPANY_OBJECT,
  COUNTRY_OBJECT,
  QUOTATIONBYSTATUS_OBJECT,
  QUOTATIONS_OBJECT,
  ROL_OBJECT,
  USER_OBJECT,
  WAREHOUSE_OBJECT,
  NOTE_OBJECT,
  DRIVER_OBJECT,
  GOAL_OBJECT,
  BILLY_OBJECT,
  TAXES_OBJECT,
  TYPES_OBJECT,
  UNITS_OBJECT,
  DOCUMENTYPES_OBJECT,
  REQ_CUSTOMER_OBJECT,
  SELLER_OBJECT,
  MAILLIST_OBJECT,
  PAYMENTS_OBJECT,
  PAYMENT_INVOICE_OBJECT,
  GRUPOART_OBJECT,
  NOTE_VALID,
  PRESENTATIONS_OBJECT,
  BILLY_OBJECT_NOTES,
  REQ_PROVIDER_OBJECT,
  PURCHASE_INVOICE_OBJECT,
  DECLARANT_TYPES_OBJECT,
  REGIME_TYPES_OBJECT,
  TRIBUTARY_VALUES_OBJECT,
  COMPANY_TYPE_OBJECT,
  CITIES_OBJECT,
  ACTIVITIES_CIIU_OBJECT,
  PURCHASESBYSTATUS_OBJECT,
  PAYMENT_PURCHASES_OBJECT,
  ACCOUNTING_ACCOUNTS_OBJECT,
  CUSTOMER_SEGMENT_OBJECT,
  CONTRIBUTOR_TYPE_OBJECT,
  REFUND_OBJECT,
  VALID_REFUND,
  CUSTOMER_TYPE_OBJECT,
  TREASURY_OBJECT,
  USER_SELLER_OBJECT,
  REQ_CUSTOMER_OBJECT_COUNT,
  ARTICLES_OBJECT_COUNT,
  REQ_PROVIDER_OBJECT_COUNT,
  TREASURY_OBJECT_COUNT,
  TRANSFER_OBJECT_COUNT,
  WAREHOUSE_TRANSFER_OBJECT_COUNT,
  WAREHOUSE_OUTPUTS_ENTRIES_OBJECT_COUNT,
  INVENTORY_RECLASSIFICATION_OBJECT_COUNT
} from "./fragment";

export const GET_ARTICLE_ID_BY_CODE = gql`
  query getArticleIDByCode($code: String, $company: String) {
    getArticleIDByCode(code: $code, company: $company)
  }
`;

export const GET_PROVIDER_BY_NIT = gql`
  query getProviderByNit($document: String, $company: String) {
    getProviderByNit(document: $document, company: $company) {
      ...RequestProviderObj
    }
  }
  ${REQ_PROVIDER_OBJECT}
`;

export const VALID_BILLY_NOTES = gql`
  query validBillyNoteExists($note_number: String, $year: Int, $company: String) {
    validBillyNoteExists(note_number: $note_number, year: $year, company: $company)
  }
`
export const GETALL_WAREHOUSE_OUTPUTS_ENTRIES = gql`
  query getAllWarehouseOutputsAndEntries($type: Int, $page: Int, $size: Int, $company: String) {
    getAllWarehouseOutputsAndEntries(type: $type, page: $page, size: $size, company: $company) {
      ...WarehouseOutputsAndEntriesObject
    }
  }
  ${WAREHOUSE_OUTPUTS_ENTRIES_OBJECT_COUNT}
`;

export const SEARCH_WAREHOUSE_OUTPUTS_ENTRIES = gql`
  query searchWarehouseOutputsAndEntries($value: String, $type: Int, $company: String) {
    searchWarehouseOutputsAndEntries(value: $value, type: $type, company: $company) {
      ...WarehouseOutputsAndEntriesObject
    }
  }
  ${WAREHOUSE_OUTPUTS_ENTRIES_OBJECT_COUNT}
`;

export const SEARCH_INVENTORY_RECLASSIFICATIONS = gql`
  query searchInventoryReclassifications($value: String, $company: String) {
    searchInventoryReclassifications(value: $value, company: $company) {
      ...InventoryReclassificationObject
    }
  }
  ${INVENTORY_RECLASSIFICATION_OBJECT_COUNT}
`;

export const GET_ALL_INVENTORY_RECLASSIFICATIONS = gql`
  query getAllInventoryReclassifications($page: Int, $size: Int,$company: String) {
    getAllInventoryReclassifications(page: $page, size: $size, company: $company) {
      ...InventoryReclassificationObject
    }
  }
  ${INVENTORY_RECLASSIFICATION_OBJECT_COUNT}
`;

export const GET_ALL_WAREHOUSE_TRANSFER = gql`
  query getAllWarehouseTransfers($page: Int, $size: Int, $company: String) {
    getAllWarehouseTransfers(page: $page, size: $size, company: $company) {
      ...WarehouseTransferObject
    }
  }
  ${WAREHOUSE_TRANSFER_OBJECT_COUNT}
`;

export const SEARCH_WAREHOUSE_TRANSFER= gql`
  query searchWarehouseTranfers($value: String, $company: String, $page: Int, $size: Int) {
    searchWarehouseTranfers(value: $value, company: $company, page: $page, size: $size) {
      ...WarehouseTransferObject
    }
  }
  ${WAREHOUSE_TRANSFER_OBJECT_COUNT}
`;

export const GET_ALL_USER_SELLERS = gql`
  query getAllUserSellers($company: String) {
    getAllUserSellers(company: $company) {
      ...UserSellerObject
    }
  }
  ${USER_SELLER_OBJECT}
`;

export const GET_SINGLE_USER_SELLERS = gql`
  query getSingleUserSeller($company: String) {
    getSingleUserSeller(company: $company) {
      ...UserSellerObject
    }
  }
  ${USER_SELLER_OBJECT}
`;

export const GET_USER_SELLER_BY_USER = gql`
  query getUserSellerByUser($id_user:Int, $company: String) {
    getUserSellerByUser(id_user: $id_user, company: $company) {
      ...UserSellerObject
    }
  }
  ${USER_SELLER_OBJECT}
`;

export const GETALL_TRANSFERS = gql`
  query getAllTransfers($page: Int, $size: Int,$company: String) {
    getAllTransfers(page: $page, size: $size, company: $company) {
      ...TransferObjectCount
    }
  }
  ${TRANSFER_OBJECT_COUNT}
`;

export const SEARCH_TRANFERS = gql`
  query searchTranfers($value: String, $company: String, $page: Int, $size: Int) {
    searchTranfers(value: $value, company: $company, page: $page, size: $size) {
      ...TransferObjectCount
    }
  }
  ${TRANSFER_OBJECT_COUNT}
`;

export const GET_ADVANCEPAYMENTS_BY_TYPE = gql`
  query getAdvancePaymentsByType($id_payments: [ID], $id_customer: ID, $id_provider: ID, $type: String, $company: String) {
    getAdvancePaymentsByType(id_payments: $id_payments, id_customer: $id_customer, id_provider: $id_provider, type: $type, company: $company) {
      ...TreasuryObject
    }
  }
  ${TREASURY_OBJECT}
`;

export const SEARCH_ADVENCEPAYMENTS = gql`
  query searchAdvencePayments($value: String, $company: String, $page: Int, $size: Int) {
    searchAdvencePayments(value: $value, company: $company, page: $page, size: $size) {
      ...TreasuryObjectCount
    }
  }
  ${TREASURY_OBJECT_COUNT}
`;

export const GET_ALL_ADVENCEPAYMENTS = gql`
  query getAllAdvencePayments($page: Int, $size: Int, $company: String) {
    getAllAdvencePayments(page: $page, size: $size, company: $company) {
      ...TreasuryObjectCount
    }
  }
  ${TREASURY_OBJECT_COUNT}
`;

export const VALID_REFUNDS = gql`
  query validRefunds($num_invo: ID!, $company: String) {
    validRefunds(num_invo: $num_invo, company: $company) {
      ...ValidRefundObject
    }
  }
  ${VALID_REFUND}
`

export const GET_REFUNDS_BYPURCHASEID = gql`
  query getRefundsByPurchaseID($num_invo: ID!, $company: String) {
    getRefundsByPurchaseID(num_invo: $num_invo, company: $company) {
      ...RefundObject
    }
  }
  ${REFUND_OBJECT}
`

export const GET_ALL_REFUNDS = gql`
  query getAllRefunds($company: String) {
    getAllRefunds(company: $company) {
      ...RefundObject
    }
  }
  ${REFUND_OBJECT}
`;

export const GET_SINGLE_REFUND = gql`
  query getSingleRefund($id_refund: ID!, $company: String) {
    getSingleRefund(id_refund: $id_refund, company: $company) {
      ...RefundObject
    }
  }
  ${REFUND_OBJECT}
`;

export const GET_CUSTOMER_TYPE = gql`
  query getAllCustomerTypes($company: String) {
    getAllCustomerTypes(company: $company) {
      ...CustomerTypeObject
    }
  }
  ${CUSTOMER_TYPE_OBJECT}
`;

export const GET_ALL_CONTRIBUTOR_TYPES = gql`
  query getAllContributorTypes($company: String) {
    getAllContributorTypes(company: $company) {
      ...ContributorTypeObject
    }
  }
  ${CONTRIBUTOR_TYPE_OBJECT}
`;

export const GET_CUSTOMER_SEGMENT = gql`
  query getAllCustomerSegments($company: String) {
    getAllCustomerSegments(company: $company) {
      ...CustomerSegmentObject
    }
  }
  ${CUSTOMER_SEGMENT_OBJECT}
`;

export const VALID_CANCELLED_INVOICE = gql`
  query validCancelledInvoice($id_invoice: ID, $company: String) {
    validCancelledInvoice(id_invoice: $id_invoice, company: $company)
  }
`

export const GET_INVOICE_BY_BILLYNUMBER = gql`
  query getInvoiceByBillyNumber($invoice_number: String, $year: Int, $company: String) {
    getInvoiceByBillyNumber(invoice_number: $invoice_number, year: $year, company: $company) {
      ...QuotationsObject
    }
  } 
  ${QUOTATIONS_OBJECT}
`

export const GET_INVOICE_BY_CONTNUMBER = gql`
  query getInvoiceByContNumber($invoice_number: String, $company: String) {
    getInvoiceByContNumber(invoice_number: $invoice_number, company: $company) {
      ...QuotationsObject
    }
  } 
  ${QUOTATIONS_OBJECT}
`

export const VALID_INVOICEPAYMENT_EXITS = gql`
  query validInvoicePaymentExits($id_invoice: ID, $id_payment: ID, $company: String) {
    validInvoicePaymentExits(id_invoice: $id_invoice, id_payment: $id_payment, company: $company)
  } 
`

export const VALID_BILLY_INVOICES = gql`
  query validBillyInvoiceExists($invoice_number: String, $year: Int, $company: String) {
    validBillyInvoiceExists(invoice_number: $invoice_number, year: $year, company: $company)
  } 
`

export const GET_ACCOUNTING_ACCOUNT_BYID = gql`
  query getAccountingAccountById($id_account: ID, $company: String){
    getAccountingAccountById(id_account: $id_account, company: $company){
      ...AccountingAccountsObject
    }
  }
  ${ACCOUNTING_ACCOUNTS_OBJECT}
`

export const GET_ALL_ACCOUNTING_ACCOUNTS = gql`
  query getAllAccountingAccounts($company: String) {
    getAllAccountingAccounts(company: $company) {
      ...AccountingAccountsObject
    }
  }
  ${ACCOUNTING_ACCOUNTS_OBJECT}
`

export const COUNT_ALL_QUOTATIONS = gql`
  query countAllQuotations($company: String) {
    countAllQuotations(company: $company)
  }
`

export const COUNT_QUOTATIONS_BYSTATUS = gql`
  query countQuotationsByStatus($estado: Int, $company: String) {
    countQuotationsByStatus(estado: $estado, company: $company)
  }
`

export const COUNT_QUOTATIONS_BYSTATUS_INVOICED = gql`
  query countQuotationsByStatusInvoiced($estado_factura: Int, $company: String) {
    countQuotationsByStatusInvoiced(estado_factura: $estado_factura, company: $company)
  }
`

export const FIND_QUOTATIONS = gql`
  query findQuotations($estado: Int, $value: String, $size: Int, $company: String) {
    findQuotations(estado: $estado, value: $value, size: $size, company: $company) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`//THIS

export const GET_ALL_INVOICES = gql`
  query getAllInvoices($company: String, $page: Int, $size: Int) {
    getAllInvoices(company: $company, page: $page, size: $size) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`//THIS

export const GET_ALL_INVOICES_BYSTATUS = gql`
  query getAllInvoicesByStatus($company: String, $estado: Int, $page: Int, $size: Int) {
    getAllInvoicesByStatus(company: $company, estado: $estado, page: $page, size: $size) {
      ...QuotationByStatusObject
    }
  }
  ${QUOTATIONBYSTATUS_OBJECT}
`//THIS

export const GET_ALL_INVOICES_BYSTATUS_INVOICED = gql`
  query getAllInvoicesByStatusInvoiced($company: String, $estado_factura: Int, $page: Int, $size: Int) {
    getAllInvoicesByStatusInvoiced(company: $company, estado_factura: $estado_factura, page: $page, size: $size) {
      ...QuotationByStatusObject
    }
  }
  ${QUOTATIONBYSTATUS_OBJECT}
` 
//Payments

export const GET_ALL_PAYMENTS_PURCHASE = gql`
  query getAllPaymentPurchases($company: String) {
    getAllPaymentPurchases(company: $company) {
      ...PayPurchasesObject
    }
  }
  ${PAYMENT_PURCHASES_OBJECT}
`

export const GET_ACTIVITY = gql`
  query getActivity($activity_code: String, $city: String) {
    getActivity(activity_code: $activity_code, city: $city) {
      ...ActivitiesCiiuObject
    }
  }
  ${ACTIVITIES_CIIU_OBJECT}
`

export const GET_SINGLE_ACTIVITY = gql`
  query getSingleActivity($id_activity: ID) {
    getSingleActivity(id_activity: $id_activity) {
      ...ActivitiesCiiuObject
    }
  }
  ${ACTIVITIES_CIIU_OBJECT}
`

export const GET_ACTIVITIES_BYCITY = gql`
  query getActivitiesByCity($id_city: ID) {
    getActivitiesByCity(id_city: $id_city) {
      ...ActivitiesCiiuObject
    }
  }
  ${ACTIVITIES_CIIU_OBJECT}
`

export const GET_CITIES_BYCOMPANY = gql`
  query getCitiesByCountry($id_country: ID!) {
    getCitiesByCountry(id_country: $id_country) {
      ...CitiesObject
    }
  }
  ${CITIES_OBJECT}
`

export const GET_ALL_COMPANY_TYPE = gql`
  query getAllCompanyTypes($company: String) {
    getAllCompanyTypes(company: $company) {
      ...CompanyTypeObject
    }
  }
  ${COMPANY_TYPE_OBJECT}
`;

export const GET_SINGLE_TRIBUTARY_VALUES = gql`
  query getSingleTributaryValues($id_value: ID) {
    getSingleTributaryValues(id_value: $id_value) {
      ...TributaryValuesObject
    }
  }
  ${TRIBUTARY_VALUES_OBJECT}
`;

export const GET_ALL_DECLARANT_TYPES = gql`
  query getAllDeclaratTypes($company: String) {
    getAllDeclaratTypes(company: $company) {
      ...DeclarantTypesObject
    }
  }
  ${DECLARANT_TYPES_OBJECT}
`;

export const GET_DECLARANT_TYPES_BYREGIME = gql`
  query getDeclarantByRegime($id_regime: ID, $company: String) {
    getDeclarantByRegime(id_regime: $id_regime, company: $company) {
      ...DeclarantTypesObject
    }
  }
  ${DECLARANT_TYPES_OBJECT}
`;

export const GET_SINGLE_DECLARANT_TYPE = gql`
  query getSingleDeclaratType($id_declarant: ID, $company: String) {
    getSingleDeclaratType(id_declarant: $id_declarant, company: $company) {
      ...DeclarantTypesObject
    }
  }
  ${DECLARANT_TYPES_OBJECT}
`;

export const GET_ALL_REGIME_TYPES = gql`
  query getAllRegimeTypes($company: String) {
    getAllRegimeTypes(company: $company) {
      ...RegimeTypesObject
    }
  }
  ${REGIME_TYPES_OBJECT}
`;

export const GET_SINGLE_REGIME_TYPE = gql`
  query getSingleRegimeType($id_regime: ID, $company: String) {
    getSingleRegimeType(id_regime: $id_regime, company: $company) {
      ...RegimeTypesObject
    }
  }
  ${REGIME_TYPES_OBJECT}
`;

export const COUNT_CUSTOMER = gql`
  query countCustomer($company: String, $prospect: Boolean) {
    countCustomer(company: $company, prospect: $prospect)
  }
`;

export const COUNT_CUSTOMER_BYSEARCH = gql`
  query countCustomerBySearch($company: String, $id_seller: Int) {
    countCustomerBySearch(company: $company, id_seller: $id_seller)
  }
`;

export const GET_PURCHASE_INVOICE = gql`
  query getSinglePurchase($id_invoice: ID!, $company: String) {
    getSinglePurchase(id_invoice: $id_invoice, company: $company) {
      ...PurchaseInvoiceObject
    }
  }
  ${PURCHASE_INVOICE_OBJECT}
`;

export const GET_PURCHASES_INVOICES_BY_STATUS = gql`
  query getAllPurchasesByStatus($status: Int, $company: String) {
    getAllPurchasesByStatus(status: $status, company: $company) {
      ...PurchaseInvoiceObject
    }
  }
  ${PURCHASE_INVOICE_OBJECT}
`;

export const GET_PURCHASES_INVOICE_BY_STATUS10 = gql`
query getPurchaseByStatus10($company: String){
  getPurchaseByStatus10(company: $company){
   ...PurchasesByStatusObject
  }
}
${PURCHASESBYSTATUS_OBJECT}
`;

export const GET_PURCHASES_INVOICE_BY_STATUS30 = gql`
query getPurchaseByStatus30($company: String){
  getPurchaseByStatus30(company: $company){
   ...PurchasesByStatusObject
  }
}
${PURCHASESBYSTATUS_OBJECT}
`;

//Prvider query
export const GET_ALL_PROVIDER = gql`
  query getAllProviders($company: String, $page: Int, $size: Int) {
    getAllProviders(company: $company, page: $page, size: $size) {
      ...RequestProviderObjCount
    }
  }
  ${REQ_PROVIDER_OBJECT_COUNT}
`;

export const SEARCH_PROVIDER = gql`
  query searchProvider($company: String, $value: String, $page: Int, $size: Int) {
    searchProvider(company: $company, value: $value, page: $page, size: $size) {
      ...RequestProviderObjCount
    }
  }
  ${REQ_PROVIDER_OBJECT_COUNT}
`;

export const GET_PROVIDER = gql`
  query getSingleProvider($id_provider: ID!, $company: String) {
    getSingleProvider(id_provider: $id_provider, company: $company) {
      ...RequestProviderObj
    }
  }
  ${REQ_PROVIDER_OBJECT}
`;

export const GET_ALLPAYMENTS = gql`
  query getAllPayments($company: String) {
    getAllPayments(company: $company) {
      ...PayInvoiceObject
    }
  }
  ${PAYMENT_INVOICE_OBJECT}
`;

export const GET_PRESENTATIONS = gql`
  query getPresentations($company: String) {
    getPresentations(company: $company) {
      ...PresentationsObject
    }
  }
  ${PRESENTATIONS_OBJECT}
`;

export const GET_GRUPOART = gql`
  query getAllGrupoArt($company: String) {
    getAllGrupoArt(company: $company) {
      ...GrupoArtObject
    }
  }
  ${GRUPOART_OBJECT}
`;

export const GET_PAYMENT_BYID = gql`
  query getPaymentsByID($id_payment: ID!, $company: String) {
    getPaymentsByID(id_payment: $id_payment, company: $company) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

export const GET_INVOICE_PAYMENTS = gql`
  query getPaymentsInvoice($id_factura: ID, $company: String) {
    getPaymentsInvoice(id_factura: $id_factura, company: $company) {
      ...PayInvoiceObject
    }
  }
  ${PAYMENT_INVOICE_OBJECT}
`;

export const GET_PURCHASE_PAYMENTS = gql`
query getPaymentsPurchases($id_invoice: ID, $company: String){
  getPaymentsPurchases(id_invoice: $id_invoice, company: $company){
    ...PayPurchasesObject
  }
}
${PAYMENT_PURCHASES_OBJECT}
`;

export const GET_BANK_LIST = gql`
  query getBankList($company: String) {
    getBankList(company: $company) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

export const GET_CASHIERS_LIST = gql`
  query getCashiersList($company: String) {
    getCashiersList(company: $company) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

export const GET_MAIL_LIST = gql`
  query getMailList($group_mails: String, $company: String) {
    getMailList(group_mails: $group_mails, company: $company) {
      ...MailListObject
    }
  }
  ${MAILLIST_OBJECT}
`;

export const GET_SINGLE_SELLER = gql`
  query getSingleSeller($id_seller: ID!, $company: String) {
    getSingleSeller(id_seller: $id_seller, company: $company) {
      ...SellerObject
    }
  }
  ${SELLER_OBJECT}
`;

export const GET_SELLER_BYNIT = gql`
  query getSellerByNit($document: String, $company: String) {
    getSellerByNit(document: $document, company: $company) {
      ...SellerObject
    }
  }
  ${SELLER_OBJECT}
`;

export const GET_SELLERS = gql`
  query getAllSellers($company: String) {
    getAllSellers(company: $company) {
      ...SellerObject
    }
  }
  ${SELLER_OBJECT}
`;

export const GET_TAXES = gql`
  query getAllTaxes($company: String) {
    getAllTaxes(company: $company) {
      ...TaxesObject
    }
  }
  ${TAXES_OBJECT}
`;

export const GET_TYPES = gql`
  query getAllTypes($company: String) {
    getAllTypes(company: $company) {
      ...TypesObject
    }
  }
  ${TYPES_OBJECT}
`;

export const GET_UNITS = gql`
  query getAllUnits($company: String) {
    getAllUnits(company: $company) {
      ...UnitsObject
    }
  }
  ${UNITS_OBJECT}
`;

export const GET_BILLY = gql`
  query getBillyInvoice($id_fact: String, $company: String) {
    getBillyInvoice(id_fact: $id_fact, company: $company) {
      ...BillyObject
    }
  }
  ${BILLY_OBJECT}
`;

export const GET_BILLY_NOTES = gql`
  query getBillyNote($id_note: String, $company: String) {
    getBillyNote(id_note: $id_note, company: $company) {
      ...BillyObjectNotes
    }
  }
  ${BILLY_OBJECT_NOTES}
`;

export const GET_GOAL = gql`
  query getGoalV1($id_seller: Int, $type: String, $id_type: Int, $company: String) {
    getGoalV1(id_seller: $id_seller, type: $type, id_type: $id_type, company: $company) {
      ...GoalObject
    }
  }
  ${GOAL_OBJECT}
`;

export const GET_DRIVER = gql`
  query getDriverByQuotV1($invoice: String, $company: String) {
    getDriverByQuotV1(invoice: $invoice, company: $company) {
      ...DriverObject
    }
  }
  ${DRIVER_OBJECT}
`;

export const GET_QUOTATIONS = gql`
  query getAllQuotations($company: String) {
    getAllQuotations(company: $company) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`;

export const GET_NOTAS = gql`
  query getAllNotes($company: String) {
    getAllNotes(company: $company) {
      ...NoteObject
    }
  }
  ${NOTE_OBJECT}
`;

export const GET_VALIDE_NOTA = gql`
  query notesForValid($note_fact: Int!, $company: String) {
    notesForValid(note_fact: $note_fact, company: $company) {
      ...NoteValid
    }
  }
  ${NOTE_VALID}
`;

export const GET_SINGLE_NOTA = gql`
  query getSingleNote($id_nota: ID!, $company: String) {
    getSingleNote(id_nota: $id_nota, company: $company) {
      ...NoteObject
    }
  }
  ${NOTE_OBJECT}
`;

export const GET_NOTA_CREDITO = gql`
  query getNotaCredito($note_fact: Int!, $company: String) {
    getNotaCredito(note_fact: $note_fact, company: $company) {
      ...NoteObject
    }
  }
  ${NOTE_OBJECT}
`;

export const GET_NOTA_DEBITO = gql`
  query getNotaDebito($note_fact: Int!, $company: String) {
    getNotaDebito(note_fact: $note_fact, company: $company) {
      ...NoteObject
    }
  }
  ${NOTE_OBJECT}
`;

export const GET_QUOTATION = gql`
  query getSingleQuotation($id_factura: ID!, $company: String) {
    getSingleQuotation(id_factura: $id_factura, company: $company) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`;

export const GET_QUOTATION_BY_STATUS1 = gql`
  query getQuotationsByStatus1($company: String) {
    getQuotationsByStatus1(company: $company) {
      ...QuotationByStatusObject
    }
  }
  ${QUOTATIONBYSTATUS_OBJECT}
`;
export const GET_QUOTATION_BY_STATUS3 = gql`
  query getQuotationsByStatus3($company: String) {
    getQuotationsByStatus3(company: $company) {
      ...QuotationByStatusObject
    }
  }
  ${QUOTATIONBYSTATUS_OBJECT}
`;
export const GET_QUOTATION_BY_STATUS4 = gql`
  query getQuotationsByStatus4($company: String) {
    getQuotationsByStatus4(company: $company) {
      ...QuotationByStatusObject
    }
  }
  ${QUOTATIONBYSTATUS_OBJECT}
`;

//Customer query
export const GET_CUSTOMERS = gql`
  query getAllCustomers($company: String, $page: Int, $size: Int, $prospect: Boolean) {
    getAllCustomers(company: $company, page: $page, size: $size, prospect: $prospect) {
      ...RequestCustomerObjCount
    }
  }
  ${REQ_CUSTOMER_OBJECT_COUNT}
`;

export const SEARCH_CUSTOMER = gql`
  query searchCustomer($company: String, $value: String, $page: Int, $size: Int) {
    searchCustomer(company: $company, value: $value, page: $page, size: $size) {
      ...RequestCustomerObjCount
    }
  }
  ${REQ_CUSTOMER_OBJECT_COUNT}
`;

export const SEARCH_SINGLE_CUSTOMER = gql`
  query searchSingleCustomer($company: String, $value: String) {
    searchSingleCustomer(company: $company, value: $value) {
      ...RequestCustomerObj
    }
  }
  ${REQ_CUSTOMER_OBJECT}
`;

export const SEARCH_CLIENT = gql`
  query searchClient($company: String, $value: String, $page: Int, $size: Int) {
    searchClient(company: $company, value: $value, page: $page, size: $size) {
      ...RequestCustomerObjCount
    }
  }
  ${REQ_CUSTOMER_OBJECT_COUNT}
`;

export const SEARCH_PROSPECT = gql`
  query searchProspect($company: String, $value: String, $page: Int, $size: Int) {
    searchProspect(company: $company, value: $value, page: $page, size: $size) {
      ...RequestCustomerObjCount
    }
  }
  ${REQ_CUSTOMER_OBJECT_COUNT}
`;

export const GET_SINGLE_CUSTOMER = gql`
  query getSingleCustomer($id_cliente: ID!, $company: String) {
    getSingleCustomer(id_cliente: $id_cliente, company: $company) {
      ...RequestCustomerObj
    }
  }
  ${REQ_CUSTOMER_OBJECT}
`;

export const GET_CUSTOMER_BYNIT = gql`
  query getCustomerByNit($document: String, $company: String) {
    getCustomerByNit(document: $document, company: $company) {
      ...RequestCustomerObj
    }
  }
  ${REQ_CUSTOMER_OBJECT}
`;

export const GET_ARTICLES = gql`
  query getAllArticles($company: String, $page: Int, $size: Int, $orderField: String, $orderType: String) {
    getAllArticles(company: $company, page: $page, size: $size, orderField: $orderField, orderType: $orderType) {
      ...ArticlesObjectCount
    }
  }
  ${ARTICLES_OBJECT_COUNT}
`;

export const COUNT_ARTICLE = gql`
  query countArticle($company: String) {
    countArticle(company: $company)
  }
`;

export const SEARCH_ARTICLE = gql`
  query searchArticle($company: String, $value: String, $id_warehouse: Int, $page: Int, $size: Int) {
    searchArticle(company: $company, value: $value, id_warehouse: $id_warehouse, page: $page, size: $size) {
      ...ArticlesObjectCount
    }
  }
  ${ARTICLES_OBJECT_COUNT}
`;

export const SEARCH_ARTICLE_BY_PROVIDERCODES = gql`
  query searchArticleByProviderCodes($company: String, $value: String) {
    searchArticleByProviderCodes(company: $company, value: $value) {
      ...ArticlesObject
    }
  }
  ${ARTICLES_OBJECT}
`;

export const GET_ARTICLE = gql`
  query getArticle($id_articulo: ID!, $company: String) {
    getArticle(id_articulo: $id_articulo, company: $company) {
      ...ArticlesObject
    }
  }
  ${ARTICLES_OBJECT}
`;

//Warehouse Query
export const GET_WAREHOUSE = gql`
  query getAllWarehouse($company: String) {
    getAllWarehouse(company: $company) {
      ...WarehouseObject
    }
  }
  ${WAREHOUSE_OBJECT}
`;

//Graficos

export const ORDER_CUSTOMER = gql`
  query getQuotOrderCustomer($company: String) {
    getQuotOrderCustomer(company: $company) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`;

//Country Query
export const GET_COUNTRY = gql`
  query getAllCountries($company: String) {
    getAllCountries(company: $company) {
      ...CountryObject
    }
  }
  ${COUNTRY_OBJECT}
`;

export const FIND_COUNTRY = gql`
  query findCountry($name: String, $company: String) {
    findCountry(name: $name, company: $company) {
      ...CountryObject
    }
  }
  ${COUNTRY_OBJECT}
`;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Company Query
export const GET_COMPANIES = gql`
  query getAllCompanies {
    getAllCompanies {
      ...CompanyObject
    }
  }
  ${COMPANY_OBJECT}
`;

export const GET_COMPANIES_BY_USER = gql`
  query getAllCompaniesByUser($userId: Int) {
    getAllCompaniesByUser(userId: $userId) {
      ...CompanyObject
    }
  }
  ${COMPANY_OBJECT}
`;

export const GET_ROL = gql`
  query getAllRoles {
    getAllRoles {
      ...RolObject
    }
  }
  ${ROL_OBJECT}
`;

//User Query
export const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      ...UsersObject
    }
  }
  ${USER_OBJECT}
`;

export const GET_USERS = gql`
  query getAllUsers {
    getAllUsers {
      ...UsersObject
    }
  }
  ${USER_OBJECT}
`;

export const GET_ME = gql`
  query me {
    me {
      ...UsersObject
    }
  }
  ${USER_OBJECT}
`;

export const VERIFY_DOCUMENTS = gql`
  query verifyDoc($document_type: Int!, $document: Float!) {
    verifyDoc(document_type: $document_type, document: $document) {
      ...UsersObject
    }
  }
  ${USER_OBJECT}
`;

export const GET_ALL_DOC_TYPES = gql`
  query {
    getAllDocTypes {
      ...DocumentypesObject
    }
  }
  ${DOCUMENTYPES_OBJECT}
`;
