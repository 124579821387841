<div class="center">
    <mat-card id="invoice" class="card">
        <mat-card-header>
            <div *ngIf="companyId ==='1'" class="image-div">
                <img mat-card-image src="../../../assets/img/v1rotateBlack.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='2'" class="image-div">
                <img mat-card-image src="../../../assets/img/Ibexports.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='5'" class="image-div">
                <img mat-card-image src="../../../assets/img/logoVersa.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='6'" class="image-div">
                <img mat-card-image src="../../../assets/img/Parabricentro.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='7'" class="image-div">
                <img mat-card-image src="../../../assets/img/v1rotateBlack.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='8'" class="image-div">
                <img mat-card-image src="../../../assets/img/v1rotateBlack.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='9'" class="image-div">
                <img mat-card-image src="../../../assets/img/v1rotateBlack.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>
         
            <div style="padding: 25px 0px 0px 145px;">
                <div class="rectangulo">
                    <div class="center-number">
                        <table style="border:hidden">
                            <tr class="tr" style="border:hidden">
                                <td class="td">{{'Purchase' | translate}} No.</td>
                            </tr>
                            <tr *ngFor="let item of purchaseInvoice.contpurchase" class="tr">
                                <td class="td">
                                    <h3><strong>{{item.written_invoice}}</strong></h3>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <button class="transparent" [useExistingCss]="true" printSectionId="invoice" ngxPrint>
                <mat-icon>print</mat-icon>
            </button>
        </mat-card-header>
        <hr class="orangeColor">
        <table class="date">
            <tr class="tr">
                <th class="th">{{'Date' | translate}}/{{'Due Date' | translate}}</th>
            </tr>
            <tr class="tr">
                <td class="td" style="width: 200px;">{{purchaseInvoice.createdAt | date: 'short'}}</td>
            </tr>
            <tr class="tr">
                <td class="td" style="width: 200px;">{{purchaseInvoice.dueDate | date: 'short'}}</td>
            </tr>
        </table>
        <table class="customTable" *ngFor="let provider of purchaseInvoice.provider">
            <tr class="tr"> 
                <th class="th">{{'Name' | translate}}</th>
                <td class="td" style="width: 300px;">{{provider.nombre}}</td>
                <th class="th">{{'Email' | translate}}</th>
                <td class="td" style="width: 300px;">{{provider.email}}</td>
            </tr>
            <tr class="tr">
                <th class="th">{{'Address' | translate}}</th>
                <td class="td" style="width: 300px;">{{provider.direccion}}</td>
                <th class="th">{{'Phone' | translate}}</th>
                <td class="td" style="width: 300px;">{{provider.telefono}}</td>
            </tr>
            <tr>
                <th class="centerText">{{'-' | translate}}</th>
                <td colspan="3" style="width: 300px;">{{'-'}}</td>
            </tr>
        </table>
        <hr class="blackColor">
        <mat-card-content>
            <table class="table">
                <tr class="tr">
                    <th class="th">
                        <span style="color: white !important">Item</span>
                    </th>
                    <th class="th">
                        {{'Description' | translate}}
                    </th>
                    <th class="th">
                        {{'Quantity' | translate}}
                    </th>
                    <th *ngIf="this.purchaseInvoice.tax_incl === 'false'" class="th">
                        {{'Price' | translate}}
                    </th>
                    <th *ngIf="this.purchaseInvoice.tax_incl === 'true'" class="th">
                        {{'Price' | translate}} + {{'Tax' | translate}}
                    </th>
                    <th class="th">
                        {{'Subtotal' | translate}}
                    </th>
                </tr>
                <tr class="tr" *ngFor="let purchase of purchases; let i = index">
                    <td class="td">
                        {{i+1}}
                    </td>
                    <td *ngIf="this.purchaseInvoice.tax_incl === 'true'" class="td" style="width: 530px;">
                        {{purchase.articulo[0].descripcion}} + {{'Tax' | translate}} ({{purchase.articulo[0].tax![0].value}})%
                    </td>
                    <td *ngIf="this.purchaseInvoice.tax_incl === 'false'" class="td" style="width: 530px;">
                        <ng-container *ngIf="purchase.articulo[0].tax![0].value === 0">
                            {{purchase.articulo[0].descripcion}} + {{'Tax' | translate}} ({{purchaseInvoice.tax}})%
                        </ng-container>
                        <ng-container *ngIf="purchase.articulo[0].tax![0].value !== 0">
                            {{purchase.articulo[0].descripcion}} + {{'Tax' | translate}} ({{purchase.articulo[0].tax![0].value}})%
                        </ng-container>                            
                    </td>
                    <td class="td">
                        {{purchase.quantity}}
                    </td>
                    <td class="td">
                        ${{purchase.price}}
                    </td>
                    <td class="td">
                        ${{purchase.subtotal}}
                    </td>
                </tr>
            </table>
            <hr class="blackColor">
            <table>
                <tr class="tr">
                    <th class="th">{{'Subtotal' | translate}}</th>
                    <td class="td" style="width: 150px;">{{subtotal | currency: 'USD'}}
                    </td>
                </tr>
                <tr class="tr">
                    <th class="th">{{'Tax' | translate}}</th>
                    <td class="td" style="width: 150px;">{{taxCalc | currency: 'USD'}}</td>
                </tr>
                <tr class="tr">
                    <th class="th">{{'Discount' | translate}}</th>
                    <td class="td" style="width: 150px;">{{discount | currency: 'USD'}}
                    </td>
                </tr>
                <tr class="tr">
                    <th class="th">{{'Total' | translate}}</th>
                    <td class="td" style="width: 150px;">
                        {{total | currency: 'USD'}}
                    </td>
                </tr>
                <tr class="tr" *ngIf="companyObject.countries[0].name === 'Colombia'">
                    <th class="th">{{'Withholding Tax' | translate}}</th>
                    <td class="td" style="width: 150px;">{{withholdingTax | currency: 'USD'}}
                    </td>
                </tr>
                <tr class="tr" *ngIf="companyObject.countries[0].name === 'Colombia'">
                    <th class="th">{{'Ica Tax' | translate}}</th>
                    <td class="td" style="width: 150px;">{{icaTax | currency: 'USD'}}
                    </td>
                </tr>
                <tr class="tr">
                    <th class="th">{{'Total to pay' | translate}}</th>
                    <td class="td" style="width: 150px;">
                        {{total - icaTax - withholdingTax | currency: 'USD'}}
                    </td>
                </tr>
            </table>
            <hr class="orangeColor">
            <div class="qrDiv">
                <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value"
                    cssClass="bshadow" [scale]="4" [margin]="4" [width]="10"></ngx-qrcode>
            </div>
            <p class="customP">
                <strong>THANK YOU FOR YOUR BUSINESS!</strong><br><br>

                MAKE ALL CHECKS PAYABLE TO
                V-ONEROTATE<br><br>

                8211 NW 64 ST UNIT 7
                MIAMI , FLORIDA. 33166
                Phone:+1 (786) 678<br><br>

                THE SELLER (VONE) ARE RESPONSIBLE FOR THE GOODS UNTIL THE DELIVERY
                AT THE FREIGHT FORWARDER COMPANY OR DELIVER ADDRESS IN EEUU
            </p>
        </mat-card-content>
    </mat-card>
</div>