import { Pipe, PipeTransform } from '@angular/core';
import { IQuotation } from 'src/app/@interfaces/quotation.interface';
import { IVentaQuery } from '../../../../@interfaces/venta.interface';

@Pipe({
  name: 'filterISales'
})
export class FilterISales implements PipeTransform {
  transform(sales: IVentaQuery[], item: string): IVentaQuery[] {
    return sales.filter(sale => 
        sale.factura!.length > 0 &&
        sale.codigo === item
    )
  }
}
