import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/internal/operators/map";
import {
  CREATE_ARTICLE,
  UPDATE_ARTICLE,
  UPDATE_AMOUNT_VALUE_BY_ARTICLE,
  DELETE_WAREHOUSE_OPENING,
  SET_WAREHOUSE_OPENING,
  UPDATE_WAREHOUSE_OPENING,
  UPDATE_ARTICLE_QUANTITIES_TO_0,
} from "src/app/@graphql/operations/mutation";
import {
  GET_ARTICLE,
  GET_ARTICLES,
  GET_ARTICLE_ID_BY_CODE,
  SEARCH_ARTICLE_BY_PROVIDERCODES,
} from "src/app/@graphql/operations/query";
import { ApiService } from "src/app/@graphql/services/api.service";
import {
  GET_TAXES,
  GET_TYPES,
  GET_UNITS,
  GET_GRUPOART,
  COUNT_ARTICLE,
  SEARCH_ARTICLE,
  GET_PRESENTATIONS,
} from "../../@graphql/operations/query";
import {
  CREATE_PRESENTATIONS,
  CREATE_GROUPART,
  REMOVE_GROUPART,
  REMOVE_PRESENTATIONS,
} from "../../@graphql/operations/mutation";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class InventoryService extends ApiService {

  constructor(apollo: Apollo, private cookieAuthService: CookieAuthService) {
    super(apollo);
  }

  countArticleList() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(COUNT_ARTICLE, { company }).pipe(
      map((result: any) => {
        return result.countArticle;
      })
    );
  }

  searchArticle(value: string, id_warehouse: number | null, page: number | null, size: number | null) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_ARTICLE, { company, value, id_warehouse, page, size }).pipe(
      map((result: any) => {
        return result.searchArticle;
      })
    );
  }

  searchArticleByProviderCodes(value: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_ARTICLE_BY_PROVIDERCODES, { company, value }).pipe(
      map((result: any) => {
        return result.searchArticleByProviderCodes;
      })
    );
  }

  listTaxes() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_TAXES, { company }).pipe(
      map((result: any) => {
        return result.getAllTaxes;
      })
    );
  }

  listTypes() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_TYPES, { company }).pipe(
      map((result: any) => {
        return result.getAllTypes;
      })
    );
  }

  listUnits() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_UNITS, { company }).pipe(
      map((result: any) => {
        return result.getAllUnits;
      })
    );
  }

  listArticles(
    page: number | null,
    size: number | null,
    orderField: string,
    orderType: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ARTICLES, {
      company,
      page,
      size,
      orderField,
      orderType,
    }).pipe(
      map((result: any) => {
        return result.getAllArticles;
      })
    );
  }

  listGrupoArt() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_GRUPOART, { company }).pipe(
      map((result: any) => {
        return result.getAllGrupoArt;
      })
    );
  }

  getArticle(id_articulo: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ARTICLE, { id_articulo, company }).pipe(
      map((result: any) => {
        return result.getArticle;
      })
    );
  }

  createArticle(
    codigo: string,
    descripcion: string,
    presentacion: string,
    costo: number,
    cantidad: number,
    grupo: number,
    unidad: string,
    almacen: number,
    tax: number,
    tipo: number,
    unidad2: number,
    id_type2: number,
    alternativeCodes: string,
    quantityInitial: number,
    valueInitial: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_ARTICLE, {
      codigo,
      descripcion,
      presentacion,
      costo,
      cantidad,
      grupo,
      unidad,
      almacen,
      tax,
      tipo,
      unidad2,
      id_type2,
      alternativeCodes,
      quantityInitial,
      valueInitial,
      company,
    }).pipe(
      map((result: any) => {
        return result.createArticle;
      })
    );
  }

  updateArticle(
    id_articulo: number,
    codigo: string,
    descripcion: string,
    presentacion: string,
    costo: number,
    cantidad: number,
    grupo: number,
    unidad: string,
    tax: number,
    tipo: number,
    unidad2: number,
    id_type2: number,
    alternativeCodes: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_ARTICLE, {
      id_articulo,
      codigo,
      descripcion,
      presentacion,
      costo,
      cantidad,
      grupo,
      unidad,
      tax,
      tipo,
      unidad2,
      id_type2,
      alternativeCodes,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateArticle;
      })
    );
  }

  updateAmountAndValueAccumulated(
    code: string,
    cumulative_amount: number,
    cumulative_value: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_AMOUNT_VALUE_BY_ARTICLE, {
      code,
      cumulative_amount,
      cumulative_value,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateAmountAndValueAccumulated;
      })
    );
  }

  getPresentations() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PRESENTATIONS, { company }).pipe(
      map((result: any) => {
        return result.getPresentations;
      })
    );
  }

  createPresentations(value: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_PRESENTATIONS, { value, company }).pipe(
      map((result: any) => {
        return result.createPresentations;
      })
    );
  }

  removePresentation(id: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_PRESENTATIONS, { id, company }).pipe(
      map((result: any) => {
        return result.removePresentations;
      })
    );
  }

  createGroup(nombre: string, id_type: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_GROUPART, { nombre, id_type, company }).pipe(
      map((result: any) => {
        return result.createGroups;
      })
    );
  }

  removeGroup(id: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_GROUPART, { id, company }).pipe(
      map((result: any) => {
        return result.removeGroups;
      })
    );
  }

  setWarehouseOpening(
    article_id: number,
    warehouse_id: number,
    quantityInitial: number,
    valueInitial: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(SET_WAREHOUSE_OPENING, {
      article_id,
      warehouse_id,
      quantityInitial,
      valueInitial,
      company,
    }).pipe(
      map((result: any) => {
        return result.setWarehouseOpening;
      })
    );
  }

  updateWarehouseOpening(
    id_warehouse_article: number,
    quantityInitial: number,
    valueInitial: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_WAREHOUSE_OPENING, {
      id_warehouse_article,
      quantityInitial,
      valueInitial,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateWarehouseOpening;
      })
    );
  }

  deleteWarehouseOpening(id_warehouse_article: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(DELETE_WAREHOUSE_OPENING, {
      id_warehouse_article,
      company,
    }).pipe(
      map((result: any) => {
        return result.deleteWarehouseOpening;
      })
    );
  }

  getAricleIdByCode(code: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ARTICLE_ID_BY_CODE, { code, company }).pipe(
      map((result: any) => {
        return result.getArticleIDByCode;
      })
    );
  }

  updateArticleQuantitesTo0() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_ARTICLE_QUANTITIES_TO_0, {
      company,
    }).pipe(
      map((result: any) => {
        return result.updateArticleQuantitesTo0;
      })
    );
  }
}
