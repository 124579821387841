import { Pipe, PipeTransform } from '@angular/core';
import { IActivitiesCiiu } from '../../@interfaces/activitiesciiu.interface';

@Pipe({
  name: 'filterActivities'
})
export class FilterActivitiesPipe implements PipeTransform {
  transform(activities: IActivitiesCiiu[], searchValue: string): IActivitiesCiiu[] {
    if (!activities || !searchValue) {
      return activities;
    }
    return activities.filter(activity =>
        activity.ica_code?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        activity.activity_code?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        activity.activity_description?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        activity.actual_rate?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
        activity.cities[0].name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    }
}
