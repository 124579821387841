import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  IPaymentsList,
  IPurchasePayments,
} from "src/app/@interfaces/payments.interface";
import { PortfolioService } from "src/app/@pages/portfolio/portfolio.service";
import Swal, { SweetAlertResult } from "sweetalert2";
import { ValidatorsService } from "../../../../validator/validators.service";
import { NgxSpinnerService } from "ngx-spinner";
import {
  IPurchaseInvoice,
  IPurchasesInvoices,
} from "src/app/@interfaces/purchaseInvoice.interface";
import { AccountsPayableService } from "src/app/@pages/accounts-payable/accounts-payable.service";
import { PurchasesService } from "src/app/@pages/purchases/purchases.service";
import { PayPurchaseService } from "./pay-purchase.service";
import { ITreasury } from "src/app/@interfaces/treasury.interface";
import { PaymentsService } from "../../crud-quotation/payments/payments.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { CreatePDFPurchasesService } from "../../../services/files/pdf/createPDFpurchase.service";

@Component({
  selector: "app-pay-purchase",
  templateUrl: "./pay-purchase.component.html",
  styleUrls: ["./pay-purchase.component.css"],
})
export class PayPurchaseComponent implements OnInit {
  typesForm: UntypedFormGroup = this.fb.group({
    type: [0, [Validators.required]],
    payment: ["", [Validators.required]]
  });
  paymentsForm: UntypedFormGroup = this.fb.group({
    types: ["", [Validators.required]],
    paymentType: ["", [Validators.required]],
    value: [0, [Validators.required, Validators.pattern(this.validatorS.quantityPattern),],],
    date: ["", [Validators.required]],
    id_ref: [""],
    advancePayment: [""],
  });
  @Input() id_invoice: number = 0;
  @Input() id_provider: number = 0;
  listBanks: Array<IPaymentsList> = [];
  listCashiers: Array<IPaymentsList> = [];
  advancePayments: ITreasury[] = [];
  @Input() invoicePayments: Array<IPurchasePayments> = [];
  totalInvoice: number = 0;
  paidInvoice: number = 0;
  missingPaid: number = 0;
  sended: boolean = false;
  refresh: boolean = false;
  user = '';
  rol = '';
  invoiceObject = {} as IPurchaseInvoice;
  purchase = {} as IPurchasesInvoices;
  selectedCheckBoxIndex: number = -1
  paymentsObj = [
    { value: 1, viewValue: "Cash" },
    { value: 2, viewValue: "Current account consignment" },
    { value: 3, viewValue: "Savings account consignment" },
  ];
  typesObj = [
    { value: "Credit", viewValue: "Credit" },
    { value: "Cancellation", viewValue: "Cancellation" },
  ];
  unsubscribe$ = new Subject();

  constructor(
    private validatorS: ValidatorsService,
    private translate: TranslateService,
    private portfolio: PortfolioService,
    private accountsPay: AccountsPayableService,
    private fb: UntypedFormBuilder,
    private purchaseService: PurchasesService,
    private spinnerService: NgxSpinnerService,
    private createPDFService: CreatePDFPurchasesService,
    private payPurchaseService: PayPurchaseService,
    private paymentsService: PaymentsService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getAuthValues();
    const invoice = await this.payPurchaseService.getInvoice(this.id_invoice);
    const refundValues = await this.payPurchaseService.getRefunds(this.id_invoice);
    this.invoiceObject = invoice;
    this.purchase = invoice;
    this.totalInvoice += this.invoiceObject.purchases[0].total;
    this.totalInvoice -= refundValues;
    this.totalInvoice -= this.invoiceObject.withholdingTax!;
    this.totalInvoice -= this.invoiceObject.icaTax!;
    this.invoicePayments.forEach((element) => {
      this.paidInvoice += element.value;
    });
    this.listsData();
    this.updateTotalPaidAndPositiveBalance();
  }

  getAuthValues() {
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  async listsData() {
    this.listCashiers = await this.paymentsService.getCashierList();
    this.listBanks = await this.paymentsService.getBankList();
    this.getAdvancePayments();
  }

  async getAdvancePayments() {
    const paymentsIDS = this.invoiceObject.payment?.map((result) => result.id_payment);
    this.advancePayments = await this.paymentsService.getAdvancePayments(paymentsIDS!, null, this.id_provider, "Providers");
  }

  setAdvancePayment(payment: ITreasury, index: number, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedCheckBoxIndex = index;
      const paymentType = this.invoiceObject.payment?.filter((result) => result.id_payment === payment.paymentType.id_payment);
      this.paymentsForm.patchValue({
        paymentType: paymentType![0],
        value: this.paymentsService.getTotalWithAdvancePayment(this.missingPaid, this.calculateAvaliable(payment.value, payment.value_used)),
        date: new Date(payment.date),
        id_ref: payment.id_ref,
        advancePayment: payment
      }); 
    } else {
      this.resetPaymentsForm();
    }
  }

  resetPaymentsForm() {
    if (this.selectedCheckBoxIndex !== -1) {
      this.selectedCheckBoxIndex = -1;
      this.paymentsForm.patchValue({
        paymentType: "",
        value: "",
        date: "",
        id_ref: "" ,
        advancePayment: ""
      }); 
    }
  }

  updateStatusInvoice(id: number, p_vendor: boolean, cancelled: boolean) {
    this.changePaymentStatus(id, p_vendor, cancelled);
  }

  changePaymentStatus(id: number, p_vendor: boolean, cancelled: boolean) {
    Swal.fire({
      title: this.translate.instant("Are you sure?"),
      text: this.translate.instant("You won not be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5400",
      cancelButtonColor: "rgb(0, 0, 0)",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        this.updateStatus(id, p_vendor, cancelled);
      }
    });
  }

  updateStatus(id: number, p_vendor: boolean, cancelled: boolean) {
    this.accountsPay
    .updateStatusPurchasePayment(id, p_vendor, cancelled)
    .subscribe((result) => {
      if (result) {
        this.portfolio
        .getPaymenPurchase(this.id_invoice)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          this.invoicePayments = result;
        });
        Swal.fire(
          this.translate.instant("OK"),
          this.translate.instant("Status has been changed succesfully."),
          "success"
        ).then((data) => {
          this.afterUpdateStatus(data, cancelled);
        });
      }
    });
  }

  afterUpdateStatus(data: SweetAlertResult<any>, cancelled: boolean) {
    if (data.isConfirmed) {
      if (parseFloat(this.paidInvoice.toFixed(2)) < parseFloat(this.totalInvoice.toFixed(2))) {
        return;
      }
      if (!this.invoiceObject.payment) {
        return;
      }
      if (cancelled === true) {
        this.validPayments(this.invoicePayments);
      } else {
        return;
      }
    }
  }

  validPayments(invoicePayments: IPurchasePayments[]) {
    const valid = this.paymentsService.validCompletePayments(invoicePayments);
    if (valid === true) {
      this.changeStatus();
    } else {
      return;
    }
  }

  async changeStatus() {
    this.spinnerService.show();
    const imageElement = $("#img").attr("src");
    this.purchaseService
    .getPurchaseInvoice(this.purchase.id_invoice!)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async (result) => {
      this.afterChangeStatus(imageElement, result);
    });
  }

  async afterChangeStatus(imageElement: string | undefined, result: any) {
    this.purchase = result;
    const paymentsDescriptions: string[] = this.invoiceObject.payment!.map(
      (payment) => payment.description
    );
    const getPdf = await this.createPDFService.createPDF(
      false,
      "Cancelled",
      this.purchase,
      imageElement,
      paymentsDescriptions.join(", ")
    );
    getPdf?.getBase64((data) => {
      this.purchaseService
      .updateStatusPurchase(this.id_invoice, true, data)
      .subscribe((result) => {
        this.afterUpdateStatusInvoice(result);
      })
    })
  }

  afterUpdateStatusInvoice(result: any) {
    if (result) {
      this.spinnerService.hide();
      Swal.fire(
        this.translate.instant("Cancelled"),
        this.translate.instant("Status has been changed succesfully."),
        "success"
      );
      this.refresh = true;
    } else {
      Swal.fire(
        this.translate.instant("Something was wrong"),
        this.translate.instant("error trying to update the quotation"),
        "error"
      );
    }
  }

  createPaymentPurchase() {
    const value = parseFloat(this.paymentsForm.get("value")?.value);
    const advancePayment = this.paymentsForm.get("advancePayment")?.value;
    const advancePaymentValidations = advancePayment ? this.paymentsService.advancePaymentValidations(advancePayment, value) : true;
    const validations = this.paymentsService.createValidations(
      this.paymentsForm, value, 0,
      false, this.missingPaid, this.invoiceObject.payment!
    )
    const valueValidation = this.payPurchaseService.validations(value, this.missingPaid);
    if (validations && advancePaymentValidations && valueValidation) {
      this.accountsPay
      .createPaymentPurchases(
        this.id_invoice,
        parseInt(this.paymentsForm.get("paymentType")?.value.id_payment),
        this.paymentsForm.get("types")?.value,
        new Date(this.paymentsForm.get("date")?.value).toDateString(),
        parseFloat(this.paymentsForm.get("value")?.value),
        this.paymentsForm.get("id_ref")?.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          Swal.fire({
            title: this.translate.instant("Created"),
            text: this.translate.instant("Payment created successfully!"),
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#f44336",
            confirmButtonText: "Ok",
          }).then((data) => {
            this.afterCreatePayment(data, value, result, advancePayment);
          });
        } else {
          Swal.fire(
            this.translate.instant("Something was wrong"),
            this.translate.instant("Failed"),
            "error"
          );
        }
      });
    }else {
      return;
    }
  }

  afterCreatePayment(data: SweetAlertResult<any>, value: number, result: IPurchasePayments, advancePayment: ITreasury) {
    if (data.isConfirmed) {
      if (advancePayment) {
        this.paymentsService.relateAdvancePayment("Set", "Purchase", result.id_paypurchases, advancePayment.id_treasury, parseFloat(value.toString())).then((ok) => {
          this.getAdvancePayments();
          this.resetPaymentsForm();
        }).catch((error) => {
          console.error(error)
        });
      }
      this.paidInvoice += result.value;
      this.updateTotalPaidAndPositiveBalance();
      this.updatePayments();
      this.sended = true;
      this.refresh = true;
    }
  }

  updateTotalPaidAndPositiveBalance() {
    if ((this.totalInvoice - this.paidInvoice) < 0) {
      this.missingPaid = 0;
    } else {
      this.missingPaid = this.totalInvoice - this.paidInvoice;
    }
  }

  confirmDelete(id: number, paymentDelete: IPurchasePayments | null, type: boolean) {
    Swal.fire({
      title: this.translate.instant("Are you sure?"),
      text: this.translate.instant("You won not be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5400",
      cancelButtonColor: "rgb(0, 0, 0)",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        if (type) {
          this.removePaymentType(id);
        } else {
          this.removePayment(id, paymentDelete!);
        }
      }
    });
  }

  removePaymentType(id: number) {
    const filterPayments = this.invoicePayments.filter(
      (item) => item.paymentType[0].id_payment === id
    );
    const ids_delete = filterPayments.map((item) =>
      parseInt(item.id_paypurchases.toString())
    );
    const trasury_delete = filterPayments.map((item) => {
      return {
        id_paypurchases: parseInt(item.id_paypurchases.toString()),
        id_treasury: parseInt(item.treasury[0]?.id_treasury.toString()),
        value: item.value
      }
    })
    const values_delete = filterPayments.map((item) =>
      parseFloat(item.value.toString())
    );
    this.accountsPay
    .removePurchasePays(this.id_invoice, id, ids_delete)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      Swal.fire(
        this.translate.instant("Good"),
        this.translate.instant("Payment removed"),
        "success"
      ).then((data) => {
        this.afterRemovePaymentType(values_delete, data, trasury_delete);
      });
    },(error) => {
      Swal.fire(
        "Error",
        this.translate.instant("Something was wrong") + error,
        "error"
      );
    });
  }

  afterRemovePaymentType(values_delete: number[], data: SweetAlertResult<any>, trasury_delete: any[]) {
    if (data.isConfirmed) {
      if (values_delete.length > 0) {
        values_delete.forEach((value) => {
          this.paidInvoice -= value;
          this.missingPaid += value;
        });
      }
      if (trasury_delete.length > 0) {
        trasury_delete.forEach(value => {
          if (value.id_treasury) {
            this.paymentsService.relateAdvancePayment("Remove", "Purchase", value.id_paypurchases, value.id_treasury, parseFloat(value.value.toString())).then((ok) => {
              this.getAdvancePayments();
            }).catch((error) => {
              console.error(error)
            }); 
          }
        });
      }
      this.updatePayments();
      this.updateInvoice();
      this.refresh = true;
    }
  }

  removePayment(id: number, paymentDelete: IPurchasePayments) {
    this.accountsPay
    .removePaymentPurchases(id)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (!result) {
        Swal.fire(
          "Error",
          this.translate.instant("Something was wrong"),
          "error"
        );
      } else {
        Swal.fire(
          this.translate.instant("Good"),
          this.translate.instant("Payment removed"),
          "success"
        ).then((data) => {
          this.afterRemovePayment(result, data, paymentDelete);
        });
      }
    });
  }

  afterRemovePayment(result: any, data: SweetAlertResult<any>, paymentDelete: IPurchasePayments) {
    if (data.isConfirmed) {
      if (paymentDelete.treasury.length > 0) {
        this.paymentsService.relateAdvancePayment("Remove", "Purchase", paymentDelete.id_paypurchases, paymentDelete.treasury[0].id_treasury, parseFloat(paymentDelete.value.toString())).then((ok) => {
          this.getAdvancePayments();
        }).catch((error) => {
          console.error(error)
        });  
      }
      this.paidInvoice -= result.value;
      this.updateTotalPaidAndPositiveBalance();
      this.updatePayments();
      this.refresh = true;
    }
  }

  updatePayments() {
    this.accountsPay
    .getPaymenPurchase(this.id_invoice)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.invoicePayments = result;
    });
  }

  async updateInvoice() {
    this.invoiceObject = await this.payPurchaseService.getInvoice(this.id_invoice);
    this.getAdvancePayments();
  }

  addPaymentType() {
    if (this.typesForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Type Invalid, Please Check!"),
      });
      return;
    }
    this.accountsPay
    .assingPurchasePays(
      this.id_invoice,
      this.typesForm.get("payment")?.value,
      this.typesForm.get("type")?.value
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        Swal.fire({
          title: this.translate.instant("Created"),
          text: this.translate.instant("Payment added successfully!"),
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#f44336",
          confirmButtonText: "Ok",
        }).then((data) => {
          this.afterAddPaymentType(data);
        });
      } else {
        Swal.fire(
          this.translate.instant("Something was wrong"),
          this.translate.instant("Failed"),
          "error"
        );
      }
    });
  }

  afterAddPaymentType(data: SweetAlertResult<any>) {
    if (data.isConfirmed) {
      this.updateInvoice();
      this.refresh = true;
    }
  }

  textValid(text: string) {
    return (
      this.typesForm.get(text)?.invalid &&
      this.typesForm.get(text)?.touched
    );
  }

  textValid2(text: string) {
    return (
      this.paymentsForm.get(text)?.invalid &&
      this.paymentsForm.get(text)?.touched
    );
  }

  calculateAvaliable(total: number, used: number) {
    return parseFloat((total - used).toFixed(2));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
