import { Injectable } from "@angular/core";
import { IImportCustomers } from "src/app/@interfaces/Import-files/import-customers.interface";
import { CustomerService } from "../../../../@pages/customer/customer.service";
import { takeUntil } from "rxjs/operators";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { Subject } from "rxjs";
import { IUserSeller } from "src/app/@interfaces/userSeller.interface";
import { ICountry } from "src/app/@interfaces/country.interface";
import { NullValidationsService } from "../validations/null-validations.service";
import { AlertsService } from "../alerts/alerts.service";

@Injectable({
  providedIn: "root",
})
export class ImportProspectsService {
  unsubscribe$ = new Subject();
  constructor(
    private customerService: CustomerService,
    private alertsService: AlertsService,
    private nullValidationsService: NullValidationsService
  ) {}

  async getValuesFromProspectsArray(
    prospectsArray: { [key: string]: any[][] },
    element: string,
    listSellers: IUserSeller[]
  ) {
    let prospectObject = {} as IImportCustomers;
    const value = prospectsArray[element];
    for (let item of value) {
      prospectObject.documentType = parseInt(this.nullValidationsService.validateValues(item[1])!);
      prospectObject.nit = this.nullValidationsService.validateValues(item[2])!;
      prospectObject.verificationDigit = parseInt(this.nullValidationsService.validateValues(item[3])!);
      prospectObject.name = this.nullValidationsService.validateValues(item[4])!;
      prospectObject.address = this.nullValidationsService.validateValues(item[5])!;
      prospectObject.phone = this.nullValidationsService.validateValues(item[6])!;
      prospectObject.email = this.nullValidationsService.validateValues(item[7])!;
      const countryName = this.nullValidationsService.validateValues(item[8]);
      const countryObject = await this.findCountry(countryName!);
      prospectObject.countryId = countryObject.id_country!;
      prospectObject.city = this.nullValidationsService.validateValues(item[9])!;
      prospectObject.coordinates = this.nullValidationsService.validateValues(item[10])!;
      prospectObject.contributorId = parseInt(this.nullValidationsService.validateValues(item[11])!);
      prospectObject.sellerId = parseInt(this.nullValidationsService.validateValues(item[12])!);
      const userSellerObject = this.findUserSeller(
        prospectObject.sellerId!,
        listSellers
      );
      prospectObject.userId = userSellerObject.id_user!;
      prospectObject.userSellerId = userSellerObject.id_userSeller;
      prospectObject.segmentId = parseInt(this.nullValidationsService.validateValues(item[13])!);
      prospectObject.observations = this.nullValidationsService.validateValues(item[14])!;
    }
    return prospectObject;
  }

  findUserSeller(sellerId: number, listSellers: IUserSeller[]): IUserSeller {
    const item = listSellers.find(
      (item) => item.id_seller?.toString() === sellerId.toString()
    )!;
    if (!item) {
      this.alertsService
      .showErrorAlert("Cannot find seller: ", sellerId.toString())
    }
    return item;
  }

  async findCountry(countryName: string) {
    return new Promise<ICountry>((resolve, reject) => {
      this.customerService.findCountry(countryName).subscribe(
        (result) => {
          if (result) {
            resolve(result);
          } else {
            this.alertsService
              .showErrorAlert("Cannot find country: ", countryName)
              .then(() => reject("Cannot find country: " + countryName));
          }
        },
        (error) => {
          this.alertsService
            .showErrorAlert(
              "Error find country: ", countryName + " Error: " + error
            )
            .then(() =>
              reject("Error find country: " + countryName + " Error: " + error)
            );
        }
      );
    });
  }

  createProspect(prospectObject: IImportCustomers) {
    return new Promise<string>(async (resolve, reject) => {
      if (
        prospectObject.name &&
        prospectObject.countryId &&
        prospectObject.city &&
        prospectObject.sellerId
      ) {
        this.customerService
          .registerCustomerProspecto(
            prospectObject.nit,
            parseInt(prospectObject.documentType!.toString()),
            prospectObject.name,
            prospectObject.address,
            prospectObject.phone,
            prospectObject.email,
            parseInt(prospectObject.countryId.toString()),
            prospectObject.city,
            prospectObject.coordinates,
            "Nothing",
            parseInt(prospectObject.contributorId.toString()),
            parseInt(prospectObject.sellerId.toString()),
            parseInt(prospectObject.userId.toString()),
            parseInt(prospectObject.segmentId.toString()),
            0,
            3, //Prospect type
            parseInt(prospectObject.userSellerId.toString()),
            parseInt(prospectObject.verificationDigit.toString()),
            prospectObject.observations
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (customer: ICustomer) => {
              resolve(this.onCreateSuccessfull(prospectObject));
            },
            (error) => {
              reject(this.onCreateError(prospectObject.name, error));
            }
          );
      } else {
        this.alertsService
          .showErrorAlert(
            "Error constructing the prospect: ", prospectObject.name
          )
          .then((confirm) => {
            if (confirm.isConfirmed) {
              reject("Error constructing the prospect");
            }
          });
      }
    });
  }

  onCreateError(customer: string, error: string) {
    this.alertsService
      .showErrorAlert("Error uploading prospect: ", customer + error)
      .then((confirm) => {
        if (confirm.isConfirmed) {
          return error;
        } else {
          return "";
        }
      });
  }

  onCreateSuccessfull(prospectObject: IImportCustomers) {
    return (
      "Item id:" +
      " " +
      "[ " +
      prospectObject.name.toString() +
      " ]" +
      " >>>>>>>>>>>> " +
      "OK"
    );
  }
}
