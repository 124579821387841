
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BillyItems } from 'src/app/@interfaces/Billy/billy.interface';
import { BillyService } from 'src/app/@pages/billyService/billy.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class BillyItemService {
  billyItems = {} as BillyItems;
  constructor(private billy: BillyService, private translate: TranslateService, private spinnerService: NgxSpinnerService, private billyService: BillyService) { }

  sendDian(articleForm: UntypedFormGroup) {
    return new Promise<boolean> ((resolve, reject) => {
        this.billyItems = this.setBillyItem(articleForm);
        this.billy.postItems(this.billyItems).subscribe((result) => {
            if (result) {
                this.spinnerService.hide();
                Swal.fire(
                    this.translate.instant('Good'), this.translate.instant('Item send to Billy'), 'success'
                ).then((result) => {
                    if (result.isConfirmed) {
                        this.spinnerService.show();
                        resolve(result.isConfirmed);
                    }
                })
            }
        }, (error) => {
                this.spinnerService.hide();
                this.billyService.handleError(error);
        })
    })
  }

  setBillyItem(form: UntypedFormGroup) {
    let items = {} as BillyItems;
    items = {
      "data": {
        "type": "item",
        "id": form.value.code,
        "attributes": {
          "description": form.value.description,
          "taxes": [{
            "type": "tax",
            "id": form.value.tax.id_second,
          }],
          "typeId": form.value.type.id_second,
          "unitaryValue": parseFloat(form.value.price.toString()),
          "unitId": form.value.unitBilly.id_second,
        },
      }, 
    };
    return items;
  }
}
