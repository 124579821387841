import { gql } from "apollo-angular";
import {
  PAYMENTS_OBJECT,
  QUOTATIONBYSTATUS_OBJECT,
  QUOTATIONS_OBJECT,
  REQ_CUSTOMER_OBJECT,
  REQ_CUSTOMER_OBJECT_COUNT
} from "./fragment";

export const COUNT_ALL_QUOTATIONS_BYUSER = gql`
  query countAllQuotationsByUser($company: String, $id_user: Int) {
    countAllQuotationsByUser(company: $company, id_user: $id_user)
  }
`

export const COUNT_QUOTATIONS_BYSTATUS_BYUSER = gql`
  query countQuotationsByStatusByUser($estado: Int, $company: String, $id_user: Int) {
    countQuotationsByStatusByUser(estado: $estado, company: $company, id_user: $id_user)
  }
`

export const COUNT_QUOTATIONS_BYSTATUS_INVOICED_BYUSER = gql`
  query countQuotationsByStatusInvoicedByUser($estado_factura: Int, $company: String, $id_user: Int) {
    countQuotationsByStatusInvoicedByUser(estado_factura: $estado_factura, company: $company, id_user: $id_user)
  }
`

export const FIND_QUOTATIONS_BYUSER = gql`
  query findQuotationsByUser($estado: Int, $value: String, $size: Int, $company: String, $id_user: Int) {
    findQuotationsByUser(estado: $estado, value: $value, size: $size, company: $company, id_user: $id_user) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`//THIS

export const GET_ALL_INVOICES_BYUSER = gql`
  query getAllInvoicesByUser($company: String, $page: Int, $size: Int, $id_user: Int) {
    getAllInvoicesByUser(company: $company, page: $page, size: $size, id_user: $id_user) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`//THIS

export const GET_ALL_INVOICES_BYSTATUS_BYUSER = gql`
  query getAllInvoicesByStatusByUser($company: String, $estado: Int, $page: Int, $size: Int, $id_user: Int) {
    getAllInvoicesByStatusByUser(company: $company, estado: $estado, page: $page, size: $size, id_user: $id_user) {
      ...QuotationByStatusObject
    }
  }
  ${QUOTATIONBYSTATUS_OBJECT}
`//THIS

export const GET_ALL_INVOICES_BYSTATUS_INVOICED_BYUSER = gql`
  query getAllInvoicesByStatusInvoicedByUser($company: String, $estado_factura: Int, $page: Int, $size: Int, $id_user: Int) {
    getAllInvoicesByStatusInvoicedByUser(company: $company, estado_factura: $estado_factura, page: $page, size: $size, id_user: $id_user) {
      ...QuotationByStatusObject
    }
  }
  ${QUOTATIONBYSTATUS_OBJECT}
` 

export const GET_BANK_LIST_BYUSER = gql`
  query getBankListByUser($id_user: Int, $company: String) {
    getBankListByUser(id_user: $id_user, company: $company) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

export const GET_CASHIERS_LIST_BYUSER = gql`
  query getCashiersListByUser($id_user: Int, $company: String) {
    getCashiersListByUser(id_user: $id_user, company: $company) {
      ...PaymentsObject
    }
  }
  ${PAYMENTS_OBJECT}
`;

//Customers By Users

export const GET_CUSTOMERS_BY_USER_SELLER = gql`
  query getAllCustomersByUserSeller(
    $company: String,
    $page: Int,
    $size: Int,
    $id_userSeller: Int
  ) {
    getAllCustomersByUserSeller(
      company: $company,
      page: $page,
      size: $size,
      id_userSeller: $id_userSeller
    ) {
      ...RequestCustomerObjCount
    }
  }
  ${REQ_CUSTOMER_OBJECT_COUNT}
`;

export const COUNT_CUSTOMER_BYUSER = gql`
  query countCustomerByUser($company: String, $id_user: Int, $prospect: Boolean) {
    countCustomerByUser(company: $company, id_user: $id_user, prospect: $prospect)
  }
`;

export const SEARCH_CUSTOMER_BYUSER = gql`
  query searchCustomerByUser($company: String, $value: String, $id_user: Int, $page: Int, $size: Int) {
    searchCustomerByUser(company: $company, value: $value, id_user: $id_user, page: $page, size: $size) {
      ...RequestCustomerObj
    }
  }
  ${REQ_CUSTOMER_OBJECT}
`;

export const SEARCH_CUSTOMER_BY_USER_SELLER = gql`
  query searchCustomerByUserSeller($company: String, $value: String, $id_userSeller: Int, $page: Int, $size: Int) {
    searchCustomerByUserSeller(company: $company, value: $value, id_userSeller: $id_userSeller, page: $page, size: $size) {
      ...RequestCustomerObjCount
    }
  }
  ${REQ_CUSTOMER_OBJECT_COUNT}
`;

//Invoices By User

export const GET_QUOTATIONS_BYUSER = gql`
  query getAllQuotationsByUser($company: String, $id_user: Int) {
    getAllQuotationsByUser(company: $company, id_user: $id_user) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`;

export const GET_QUOTATION_BY_STATUS3_BYUSER = gql`
  query getQuotationsByStatus3ByUser($company: String, $id_user: Int) {
    getQuotationsByStatus3ByUser(company: $company, id_user: $id_user) {
      ...QuotationByStatusObject
    }
  }
  ${QUOTATIONBYSTATUS_OBJECT}
`;

export const ORDER_CUSTOMER_BYUSER = gql`
  query getQuotOrderCustomerByUser($company: String, $id_user: Int) {
    getQuotOrderCustomerByUser(company: $company, id_user: $id_user) {
      ...QuotationsObject
    }
  }
  ${QUOTATIONS_OBJECT}
`;
