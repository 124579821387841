import { Pipe, PipeTransform } from '@angular/core';
import { IQuotation } from 'src/app/@interfaces/quotation.interface';
import { IVentaQuery } from '../../../../@interfaces/venta.interface';

@Pipe({
  name: 'filterPresentationSales'
})
export class FilterPresentationSales implements PipeTransform {
  transform(sales: IVentaQuery[], type: string): IVentaQuery[] {
    return sales.filter(sale => 
      sale.articulo.filter(art => art.id_type2!.toString() === type.toString())
    )
  }
}

//quotation.venta.filter(sale => sale.codigo === item)
