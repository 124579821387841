<mat-dialog-content>
    <div class="row">
        <img src="../../../../../assets/icons/icono.png" onerror="this.src='assets/img/cantfindimage.jpg'"
            style="margin-left: 1px; width: 20px; height: 20px;">
        <div class="col-md-2" style="margin-top: 10px">
            <h2 mat-dialog-tittle>{{'All Debit Notes' | translate}}</h2>
        </div>
    </div>
    <form [formGroup]="registerForm">
        
        <table class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                   <th>
                       <mat-label>Item</mat-label>
                   </th>
                    <th>
                        <mat-label>{{'Customer' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Concept' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Date' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Due Date' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Total' | translate}} {{'Price' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Details' | translate}}</mat-label>
                    </th>
                    
                </tr>
            </thead>
            <tbody>  
                <ng-container formArrayName="itemRows" class="row">
                    <ng-container *ngFor="let itemrow of rowsArr.controls; let i=index" [formGroupName]="i">
                    <tr>
                        <td data-label="Item">
                            {{i+1}}
                        </td>
                        
                        <td data-label="Customer"> 
                            <input readonly type="text" matInput [matAutocomplete]="autoCustomer" [formControl]="newCustomer">
                            <mat-autocomplete #autoCustomer="matAutocomplete" [displayWith]="displayFnCustomer">
                                <mat-option *ngFor="let customer of filteredOptionsCustomer | async" [value]="customer">
                                    {{customer.nombre}}
                                </mat-option>
                            </mat-autocomplete>
                        </td>

                        <td data-label="Concept">  
                            <input readonly matInput  formControlName="concepto" autocomplete="off">
                        </td>

                        <td data-label="Date">
                            <mat-datepicker #picker></mat-datepicker>
                            <input readonly matInput [matDatepicker]="picker" formControlName="date" autocomplete="off"> 
                        </td>

                        <td data-label="Due Date">
                            <mat-datepicker #picker2></mat-datepicker>
                            <input readonly matInput [matDatepicker]="picker2" formControlName="dueDate" autocomplete="off">
                        </td>

                        <td data-label="Total Price" style="min-height: 50px;">
                            <input readonly matInput  formControlName="total" autocomplete="off">
                        </td>
                        <td data-label="Details" style="min-height: 50px;">
                            <div style="float: right;">
                                <a [routerLink]="['/details_notas',id_fact,rowsArr.value[i].aidi]">
                                    <mat-icon (click)="closeDialog()" mat-dialog-close-all class="black-icon">launch</mat-icon>
                                </a>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </table><br>
        <div class="row">
            <div class="col-md-1">
                <button mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>

            </div>
        </div>
    </form>
</mat-dialog-content>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>




