import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ICountry } from "src/app/@interfaces/country.interface";
import { CustomerService } from "src/app/@pages/customer/customer.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { IDocumenType } from "../../../../../@interfaces/authentication/documenType";
import { NgxSpinnerService } from "ngx-spinner";
import { SellersService } from "../../../../../@pages/sellers/sellers.service";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UsersService } from "src/app/@pages/users/users.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-register-seller",
  templateUrl: "./register-seller.component.html",
  styleUrls: ["./register-seller.component.css"],
})
export class RegisterSellerComponent implements OnInit, OnDestroy {
  countryList: Array<ICountry> = [];
  documenTypeList: Array<IDocumenType> = [];

  sellerForm: UntypedFormGroup = this.fb.group({
    doc_nit: ['', [Validators.required, Validators.pattern(this.validatorS.documentPattern)]],
    types_docs: ['', [Validators.required]],
    name: ['', [Validators.required, Validators.maxLength(60)]],
    email: ['', [Validators.required, Validators.pattern(this.validatorS.emailPattern), Validators.maxLength(50)]],
    country: ['', Validators.required],
  });
  unsubscribe$ = new Subject();
  companyId = '';

  constructor(
    private translate: TranslateService,
    private seller: SellersService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private router: Router,
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private customer: CustomerService,
    private userService: UsersService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.customer
    .listCountries()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.countryList = result;
    });

    this.userService
    .listDocumenTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.documenTypeList = result;
    });
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  register() {
    this.sellerForm.markAllAsTouched();
    if (this.sellerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant(
          "Please, fill in all the required fields!"
        ),
      });
      return;
    }
    this.seller
    .registerSellers(
      this.sellerForm.value.doc_nit,
      parseInt(this.sellerForm.value.types_docs.id_docs),
      this.sellerForm.value.name,
      this.sellerForm.value.email,
      parseInt(this.sellerForm.value.country.id_country)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.nombre !== "sellerExist") {
        Swal.fire(
          this.translate.instant("success"),
          this.translate.instant("Seller created successfully"),
          "success"
        );
        this.router
        .navigateByUrl("/table", { skipLocationChange: true })
        .then(() => {
          this.router.navigate(["/sellers"]);
        });
        this.sellerForm.reset();
      } else if (result.nombre === "sellerExist") {
        Swal.fire(
          this.translate.instant("Seller was not created"),
          this.translate.instant("This Seller already exist"),
          "warning"
        );
      } else {
        Swal.fire(
          "error",
          this.translate.instant("Something was wrong"),
          "error"
        );
      }
    });
  }

  textValid(text: string) {
    return (
      this.sellerForm.get(text)?.invalid && this.sellerForm.get(text)?.touched
    );
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
