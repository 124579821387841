import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder,
} from "@angular/forms";
import { ICities } from "../../../../../@interfaces/cities.interface";
import { TributaryService } from "../../../../../@pages/tributary/tributary.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { IStorageCompany } from "src/app/@interfaces/company.interface";

@Component({
  selector: "app-register-activities",
  templateUrl: "./register-activities.component.html",
  styleUrls: ["./register-activities.component.css"],
})
export class RegisterActivitiesComponent implements OnInit, OnDestroy {
  activityForm: UntypedFormGroup = this.fb.group({
    ica_code: ["", [Validators.required]],
    activity_code: ["", [Validators.required]],
    activity_description: ["", [Validators.required]],
    actual_rate: ["", [Validators.required, Validators.maxLength(60)]],
    city: ["", [Validators.required, Validators.maxLength(100)]],
  });
  citiesList: ICities[] = [];
  unsubscribe$ = new Subject();
  companyObject = {} as IStorageCompany;

  constructor(
    private fb: UntypedFormBuilder,
    private tributaryService: TributaryService,
    private translate: TranslateService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    const country_id = this.companyObject.countries[0].id_country;
    this.tributaryService
    .getCitiesByCompany(parseInt(country_id!.toString()))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.citiesList = result;
    });
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  textValid(text: string) {
    return (
      this.activityForm.get(text)?.invalid &&
      this.activityForm.get(text)?.touched
    );
  }

  register() {
    this.activityForm.markAllAsTouched();
    if (this.activityForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant(
          "Please, fill in all the required fields!"
        ),
      });
      return;
    } else {
      this.spinnerService.show();
      this.saveOnDataBase();
    }
  }

  saveOnDataBase() {
    this.tributaryService
    .createActivity(
      parseInt(this.activityForm.value.city.id_city),
      this.activityForm.value.ica_code,
      this.activityForm.value.activity_code,
      this.activityForm.value.activity_description,
      parseFloat(this.activityForm.value.actual_rate)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.nombre !== "activityExist") {
        this.spinnerService.hide();
        Swal.fire(
          this.translate.instant("success"),
          this.translate.instant("Activity created successfully"),
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            this.router
            .navigateByUrl("/table", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/tributary"]);
            });
          }
        });
      } else if (result.nombre === "activityExist") {
        this.spinnerService.hide();
        Swal.fire(
          this.translate.instant("Activity was not created"),
          this.translate.instant("This activity already exist"),
          "warning"
        );
      } else {
        this.spinnerService.hide();
        Swal.fire(
          "error",
          this.translate.instant("Something was wrong"),
          "error"
        );
      }
    },(error) => {
      this.spinnerService.hide();
      Swal.fire(
        "error",
        this.translate.instant("Something was wrong"),
        "error"
      );
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
