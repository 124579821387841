import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseTabsComponent } from './purchase-tabs.component';
import { MaterialModule } from 'src/app/material.module';
import { StepperModule } from '../stepper/stepper.module';
import { CardModule } from '../card/card.module';
import { FormsModule } from '@angular/forms'
import { SearchModule } from '../search/search.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { DialogModule } from '../dialog/dialog.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PurchaseCardModule } from '../purchase-card/purchase-card.module';
import { FilterPurchasePipe } from '../../pipe/filter-purchases.pipe';


@NgModule({
  declarations: [
    PurchaseTabsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    StepperModule,
    PurchaseCardModule,
    FormsModule,
    SearchModule,
    NgxPaginationModule,
    DialogModule,
    MatIconModule,
    TranslateModule,
    NgxSpinnerModule
  ],
  exports: [PurchaseTabsComponent],
  providers: [
    FilterPurchasePipe
  ]
})
export class PurchaseTabsModule { }
