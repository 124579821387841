import { Component, OnInit } from "@angular/core";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { IPurchaseInvoice } from "src/app/@interfaces/purchaseInvoice.interface";
import { IPurchasePayments } from "src/app/@interfaces/payments.interface";
import { IContPurchase } from "src/app/@interfaces/contpurch.interface";
import { IProvider } from "src/app/@interfaces/provider.interface";
import { Subject } from "rxjs";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { AccountsPayableService } from "../accounts-payable/accounts-payable.service";
import { MatDialog } from "@angular/material/dialog";
import { ProviderService } from "../provider/provider.service";
import { PurchasesService } from "../purchases/purchases.service";
import { takeUntil } from "rxjs/operators";
import { FilterPPurchase } from "src/app/@shared/pipe/purchase-pipes/provider.pipe";
import { IDetails } from "src/app/@interfaces/details/details.interface";
import { ITableData } from "src/app/@interfaces/details/table.interface";
import { IRefunds } from "src/app/@interfaces/refunds.interface";
import { DetailsAccountsPayableService } from "./details-accounts-payable.service";
import { INote } from "src/app/@interfaces/details/note.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-details-accounts-payable",
  templateUrl: "./details-accounts-payable.component.html",
  styleUrls: ["./details-accounts-payable.component.css"],
})
export class DetailsAccountsPayableComponent implements OnInit {
  date = new Date();
  registerForm: UntypedFormGroup = this.fb.group({
    provider: [, Validators.required],
    year: [this.date.getFullYear(), Validators.required]
  });
  dataTable: ITableData[] = [];
  purchases: Array<IPurchaseInvoice> = [];
  payments: Array<IPurchasePayments> = [];
  refunds: Array<IRefunds> = [];
  contPurchases = {} as IContPurchase;
  user = '';
  rol = '';
  providerList: Array<IProvider> = [];
  years: number[] = [];
  showTable: boolean = true;
  unsubscribe$ = new Subject();
  total = "";

  constructor(
    public purchasesService: PurchasesService,
    public accountsPayableService: AccountsPayableService,
    public dialog: MatDialog,
    private filterPPurchase: FilterPPurchase,
    private fb: UntypedFormBuilder,
    private providerService: ProviderService,
    private detailsAccountsPayableService: DetailsAccountsPayableService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    await this.data(this.date.getFullYear());
    this.setYears();
      this.providerService.listProvider(0, null).pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
        this.providerList = result.providers;
      });
  }

  getAuthValues() {
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  setYears() {
    for (let i = this.date.getFullYear(); i >= this.date.getFullYear() - 10; i--) {
      this.years.push(i);
    }
  }

  update(purchases: IPurchaseInvoice[], refunds: IRefunds[], paymentS: IPurchasePayments[]) {
    let filterPurchases: IPurchaseInvoice[] = [];
    let id_provider = null;
    const providerObj = this.registerForm.get("provider")?.value;
    if (providerObj) {
      id_provider = providerObj.id_provider;
    }
    filterPurchases = this.filterPPurchase.transform(purchases, id_provider!);
    if (filterPurchases.length === 0) {
      this.showTable = false;
    } else {
      this.showTable = true;
      this.calculation(filterPurchases, refunds, paymentS);
    }
  }

  reset(purchases: IPurchaseInvoice[], refunds: IRefunds[], paymentS: IPurchasePayments[]) {
    this.calculation(purchases, refunds, paymentS);
    this.showTable = true;
  }

  async data(year: number) {
    this.purchases = await this.detailsAccountsPayableService.getAllPurchases(year);
    if (this.purchases.length > 0) {
      this.payments = await this.detailsAccountsPayableService.getAllPayments(year);
      this.refunds = await this.detailsAccountsPayableService.getAllRefunds(year);
      this.calculation(this.purchases, this.refunds, this.payments);
    }else {
      this.dataTable = [];
      this.total = '0';
    }
  }

  calculation(purchases: IPurchaseInvoice[], allRefunds: IRefunds[], paymentS: IPurchasePayments[]) {
    let arrayProviders: string[] = [];
    let arrayPurchases: number[] = [];
    let arrayInit: ITableData[] = [];
    let arrayEnd: ITableData[] = [];
    let refunds: INote[] = [];
    let paymentsArray: IPurchasePayments[] = [];
    let detailsObj = {} as IDetails;
    let sumTotalProviders = 0;

    purchases.forEach((purchase) => {
      arrayPurchases.push(purchase.id_invoice!);
      purchase.provider.forEach((provider) => {
        arrayProviders.push(provider.nombre);
        arrayInit.push({
          code: provider.doc_nit!,
          customer: provider.nombre,
          total:
            purchase.purchases![0].total -
            purchase.icaTax! -
            purchase.withholdingTax!,
          details: [
            {
              invoice: purchase.id_invoice!.toString(),
              invoice_number: purchase.contpurchase![0].written_invoice,
              dueDate: purchase.dueDate,
              createdDate: purchase.createdAt,
              total:
                purchase.purchases![0].total -
                purchase.icaTax! -
                purchase.withholdingTax!,
            },
          ],
        });
      });
    });

    let reduceProviders = arrayProviders.filter((item, index) => {
      return arrayProviders.indexOf(item) === index;
    });

    let reducePurchases = arrayPurchases.filter((item, index) => {
      return arrayPurchases.indexOf(item) === index;
    });

    refunds = this.detailsAccountsPayableService.processRefunds(allRefunds, reducePurchases);

    paymentsArray = this.processPays(paymentS, reducePurchases);

    reduceProviders.forEach((provider) => {
      let totalProvider = 0;
      let customeR = "";
      let documentCustomer = "";
      let details: IDetails[] = [];
      let filterByProvider = arrayInit.filter(
        (data: any) => data.customer === provider
      );
      filterByProvider.forEach((filtered) => {
        let invoice = "";
        let invoice_number = "";
        let dueDate = "";
        let createdDate = "";
        let totalPaid = 0;
        let totalInvoice = 0;
        let totalPayment = 0;
        let totalRefund = 0;
        let payments: IPurchasePayments[] = [];
        let refundByItem: INote[] = [];
        customeR = filtered.customer;
        documentCustomer = filtered.code;
        filtered.details?.forEach((detail) => {
          invoice = detail.invoice;
          invoice_number = detail.invoice_number!;
          dueDate = detail.dueDate;
          createdDate = detail.createdDate;
          totalInvoice = detail.total;
          refunds.forEach((C) => {
            if (C.fact.toString() === detail.invoice) {
              refundByItem.push(C);
              totalRefund += parseFloat(C.total.toFixed(2)) * -1;
            }
          });
          paymentsArray.forEach((P) => {
            if (P.id_invoice.toString() === detail.invoice) {
              payments.push(P);
              totalPayment += P.value;
            }
          });
          totalPaid = (totalInvoice - totalPayment) - totalRefund;
        });
        totalProvider += totalInvoice;
        totalProvider -= totalRefund;
        totalProvider -= totalPayment;
        detailsObj = {
          invoice: invoice,
          invoice_number: invoice_number,
          electronic_number: 'PENDING',
          dueDate: dueDate,
          createdDate: createdDate,
          total: totalInvoice,
          totalPaid: totalPaid,
          purchasePaid: payments,
          creditNote: refundByItem,
        };
        details.push(detailsObj);
      });
      sumTotalProviders += totalProvider;
      arrayEnd.push({
        code: documentCustomer,
        customer: customeR,
        total: "$" + totalProvider.toFixed(2),
        details: details,
      });
      this.dataTable = arrayEnd;
    });
    this.total = new Intl.NumberFormat("de-DE").format(sumTotalProviders);
  }

  processPays(payments: IPurchasePayments[], purchases: number[]) {
    let pays: IPurchasePayments[] = [];
    purchases.forEach((num) => {
      payments.forEach((pay) => {
        if (pay.id_invoice.toString() === num.toString() && pay.status === 3) {
          pays.push(pay);
        }
      });
    });
    return pays;
  }

  openDialog(details: IDetails[]) {
    this.dialog.open(DialogComponent, {
      data: { detailsPayment: details },
    });
  }

  displayFnProvider(provider: any) {
    return provider && provider ? provider.nombre : undefined;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
