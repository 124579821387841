<mat-dialog-content>
  <form [formGroup]="providerForm">
    <div class="row justify-content-start">
      <h3>
        <strong>{{ "Register Provider" | translate }}</strong>
      </h3>
    </div>
    <div class="row">
      <div class="col-md-4">
        <span>{{ "Document Type" | translate }}:</span>
        <select class="form-control" formControlName="types_docs">
          <option selected value="">{{ "Select Type" | translate }}</option>
          <option *ngFor="let types of documenTypeList" [ngValue]="types">
            {{ types.description }}
          </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('types_docs')">{{"You must select a type" | translate}}</span>
      </div>
      <div class="col-md-4">
        <span> {{ "Document Or Nit" | translate }}:</span>
        <input class="form-control" placeholder="{{ 'Document/Nit' | translate }}" formControlName="doc_nit"/>
        <span class="form-text text-danger" *ngIf="textValid('doc_nit')">{{"The document must be wrote" | translate}}</span>
      </div>
      <div class="col-md-4">
        <span> {{ "Provider Name" | translate }}:</span>
        <input class="form-control" placeholder="{{ 'Name' | translate }}" formControlName="name"/>
        <span class="form-text text-danger" *ngIf="textValid('name')">{{"The name must be wrote" | translate}}</span>
      </div>
      <div class="col-md-4">
        <span> {{ "Provider Phone" | translate }}:</span>
        <input class="form-control" placeholder="{{ 'Phone' | translate }}" formControlName="phone"/>
        <span class="form-text text-danger" *ngIf="textValid('phone')">{{"The phone must be only numbers" | translate}}</span>
        <span class="form-text text-danger" *ngIf="textValid('phone')">{{"The phone must be only 15 numbers" | translate}}</span>
      </div>
      <div class="col-md-4">
        <span> {{ "Provider Email" | translate }}:</span>
        <input class="form-control" placeholder="{{ 'Email' | translate }}" formControlName="email"/>
        <span class="form-text text-danger" *ngIf="textValid('email')">{{"The email is not a valid" | translate}}</span>
      </div>
      <div class="col-md-4">
        <span> {{ "Provider Country" | translate }}:</span>
        <select class="form-control" formControlName="country">
          <option selected value="">{{ "Select Country" | translate }}</option>
          <option *ngFor="let country of countryList" [ngValue]="country">
            {{ country.name }}
          </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('country')">{{"The country is not valid" | translate}}</span>
      </div>
      <div class="col-md-4" *ngIf="this.countrySelected === '170'">
        <span> {{ "Select city" | translate }} </span>
        <select (change)="getCitySelect()" class="form-control" formControlName="citySelect">
          <option selected value="">{{ "Select city" | translate }}</option>
          <option *ngFor="let cities of billyCities.data" [ngValue]="cities">
            {{ cities.attributes.name }}
          </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('city')">{{"The city is not valid" | translate}}</span>
      </div>
      <div class="col-md-4" *ngIf="this.countrySelected !== '170'">
        <span> {{'City' | translate}}:</span>
        <input class="form-control" [matAutocomplete]="autocompleteCity" placeholder="{{'Select city' | translate}}" formControlName="citySelect">
        <mat-autocomplete (optionSelected)="getCitySelect()" #autocompleteCity="matAutocomplete" [displayWith]="displayFnCity">
            <mat-option *ngFor="let city of filterCities$ | async" [value]="city">
                {{city}}
            </mat-option>
        </mat-autocomplete>
        <span class="form-text text-danger" *ngIf="textValid('city')">{{"The city is not valid" | translate}}</span>
      </div>
      <div class="col-md-4">
        <span> {{ "Provider Address" | translate }}:</span>
        <input class="form-control" placeholder="{{ 'Address' | translate }}" formControlName="address"/>
        <span class="form-text text-danger" *ngIf="textValid('address')">{{"The address must be wrote" | translate}}</span>
      </div>
      <div class="col-md-4" *ngIf="companyObject.countries[0].name === 'Colombia'">
        <span> {{ "Company Type" | translate }}:</span>
        <select class="form-control" formControlName="type">
          <option selected value="">{{ "Select type" | translate }}</option>
          <option *ngFor="let type of companyTypeList" [ngValue]="type">
            {{ type.name | translate }}
          </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('type')">{{"The type is not valid" | translate}}</span>
      </div>
      <div class="col-md-4" *ngIf="companyObject.countries[0].name === 'Colombia'">
        <span> {{ "Activity Code" | translate }}:</span>
        <input class="form-control" placeholder="{{ 'Code' | translate }}" formControlName="activity_code"/>
        <span class="form-text text-danger" *ngIf="textValid('activity_code')">{{ "The code must be wrote" | translate }}</span>
      </div>
      <div class="col-md-4">
        <span>{{'Contributor Type' | translate}}:</span>
        <select class="form-control" formControlName="contributorType">
            <option selected value="">{{'Select Contributor Type' | translate}}</option>
            <option *ngFor="let contributor of contributorTypeList" [ngValue]="contributor">
                {{contributor.description}}
            </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('contributorType')">{{'You must select a contributor type' | translate}}</span>
      </div>
      <div class="col-md-4" *ngIf="companyObject.countries[0].name === 'Colombia'">
        <span> {{ "Regime" | translate }}:</span>
        <select (change)="filterDeclarantList()" class="form-control" formControlName="regime">
          <option selected value="">{{ "Select regime" | translate }}</option>
          <option *ngFor="let regime of regimeList" [ngValue]="regime">
            {{ regime.name | translate }}
          </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('regime')">{{"The regime is not valid" | translate}}</span>
      </div>
      <div *ngIf="declarantsList.length > 0" class="col-md-4" class="col-md-4">
        <span> {{ "Declarant Type" | translate }}:</span>
        <select class="form-control" formControlName="declarant">
          <option selected value="">
            {{ "Select declarant" | translate }}
          </option>
          <option *ngFor="let declarant of declarantsList" [ngValue]="declarant">
            {{ declarant.name | translate }} - {{ declarant.type | translate }}
          </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('declarant')">{{"The declarant is not valid" | translate}}</span>
      </div>
    </div>
    <div class="row">
      <div class="row" style="padding-left: 20px; margin-top: 10px; margin-bottom: 10px" id="buttons-div">
        <div class="col" id="buttons">
          <button (click)="register()" mat-raised-button>
            {{ "Register" | translate }}
          </button>
        </div>
        <div class="col" id="buttons">
          <button mat-raised-button [mat-dialog-close]>
            {{ "Cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<ngx-spinner
  type="ball-scale-multiple"
  template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />"
  bdColor="rgba(51,51,51,0.8)"
>
  <p style="font-size: 20px; color: #ffffff">{{ "Loading..." | translate }}</p>
</ngx-spinner>
