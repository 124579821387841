
<div class="padre">
    <h3>{{'PLEASE SELECT THE COMPANY YOU WOULD LIKE TO WORK FOR' | translate}}</h3>
</div>
<div class="padre">
    <div class="hijo">
        <table class="table table-bordered">
   
            <tbody>
                <tr *ngFor="let companies of companyList$ | async; let i = index">
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        {{companies.name}}
                    </td>
                    <td>
                        <a class="goLink" (click)="selection(companies)">
                            {{'Pick' | translate}}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>

  <ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
  </ngx-spinner>

    
