import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReportsService } from 'src/app/@pages/reports/reports.service';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';
import * as XLSX from "xlsx";
@Component({
  selector: 'app-siigo-table',
  templateUrl: './siigo-table.component.html',
  styleUrls: ['./siigo-table.component.css']
})
export class SiigoTableComponent implements OnChanges {
  @Input() chartAccountsData: any[] = [];
  @Input() headerDateRange: string = '';
  companyName: string = ''

  constructor(private cookieAuthService: CookieAuthService, private reportsService: ReportsService) {
    const { name } = this.cookieAuthService.getCompanyObject!;
    this.companyName = name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartAccountsData?.currentValue?.length > 0) {
      setTimeout(() => {
        this.exportChartAccountsExcel();
      }, 2500);
    }
  }

  async exportChartAccountsExcel() {
    const element = document.getElementById("tabSiigo");
    let mainSheet: XLSX.WorkSheet | any = XLSX.utils.table_to_sheet(element);
    const book: XLSX.WorkBook = XLSX.utils.book_new();
    mainSheet = await this.setFormatCellsForDate(mainSheet);
    this.reportsService.export(book, mainSheet, "MovimientosContables.xlsx");
  }

  async setFormatCellsForDate(mainSheet: XLSX.WorkSheet) {
    return new Promise((resolve, reject) => {
      try {
        this.chartAccountsData.forEach((item, index) => {
        const date = item.dateToDay;
        mainSheet["AL" + (index + 6).toString()].z = "";
        mainSheet["AL" + (index + 6).toString()].v = date;
        mainSheet["AL" + (index + 6).toString()].t = "s";
      })
        resolve(mainSheet)
      } catch (error) {
        reject(error)
      }
    })
  }
}
