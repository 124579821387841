import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { CustomerService } from "./customer.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { ICustomerSegment } from "src/app/@interfaces/customerSegment.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnInit, OnDestroy {
  showProspects = false;
  CustomerListProspect: Array<ICustomer> = [];
  CustomerList: Array<ICustomer> = [];
  companyId = '';
  length = 0;
  user = '';
  rol = '';
  userSeller = '';
  unsubscribe$ = new Subject();
  customerSegmentList: Array<ICustomerSegment> = [];
  disableButton = false;
  constructor(
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private customers: CustomerService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}
  
  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.spinnerService.show();
    this.loadData();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
    this.userSeller = this.cookieAuthService.getUserSellerId!;
  }
  
  toggleDataDisplay(): void {
    this.showProspects = !this.showProspects;
    this.loadData();
  }
  
  loadData(): void {
    if (this.rol === "1" || this.rol === "3") {
      this.customers
        .listCustomers(0, 15, this.showProspects)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.length = result.count
          this.CustomerList = result.customers;
          this.spinnerService.hide();
        });
      } else {
      this.customers
        .listCustomersByUserSeller(0, 15, parseInt(this.userSeller!), this.showProspects)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.CustomerList = result.customers;
          this.length = result.count;
          this.spinnerService.hide();
        });
    }
  }
 
  openDialog(): void {
    this.dialog.open(DialogComponent, {
      data: { registerCustomer: true },
    }).afterClosed().pipe(first()).subscribe((res) => {
      if (res !== false) {
        this.loadNewData(true)
      }
    })
  }

  openDialogSegments(registerSegments: boolean, listSegments: ICustomerSegment[]) {
    this.dialog.open(DialogComponent, {
      data: { registerSegments, listSegments },
    })
  }

  loadNewData(updated: boolean) {
    if (updated) {
      this.loadData();
    }
  }

  get getLabelButton(): string {
    return this.showProspects ?  'Show Clients'  :  'Show Prospects';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
