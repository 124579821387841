import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ApiService } from "src/app/@graphql/services/api.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import {
  ITopCustomer,
  ITopCustomerNotes,
} from "src/app/@interfaces/Analysis/dollar-per-client.interface";
import { ProcessNotesService } from "../../@shared/components/services/charts/process-notes.service";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { ITopLiters } from "src/app/@interfaces/Analysis/liter-per-client.interface";
import { IInvoicesMapsData } from "src/app/@interfaces/Home/sales-by-region.interface";
import { CalculateAnalysis } from "../../@shared/components/services/charts/calculate-analysis.service";

@Injectable({
  providedIn: "root",
})
export class AnalysisService extends ApiService {
  modelCompany: string = "";
  date = new Date();

  constructor(
    apollo: Apollo,
    private processNotes: ProcessNotesService,
    private calculateAnalysis: CalculateAnalysis
  ) {
    super(apollo);
  }

  quotsNull(): IQuota[] {
    const quots: IQuota[] = [
      {
        id_factura: 0,
        num_fac: "Nothing",
        venta: [
          {
            id_venta: 0,
            num_fac: "Nothing",
            codigo: "Nothing",
            cantidad: 0,
            precio: 0,
            descuento: 0,
            subtotal: 0,
            total: 0,
            factura: [],
            almacen: [
              {
                id_almacen: 0,
                nombre: "Nothing",
                estado: 0,
              },
            ],
            articulo: [
              {
                id_articulo: 0,
                codigo: "Nothing",
                descripcion: "0",
                presentacion: "Nothing",
                costo: 0,
                cantidad: 0,
                grupo: 0,
                unidad: "Nothing",
                almacen: [
                  {
                    id_almacen: 0,
                    nombre: "Nothing",
                    estado: 0,
                  },
                ],
                grupoArt: [
                  {
                    id_grupo: 0,
                    nombre: "N/A",
                    presentations: [],
                  },
                ],
              },
            ],
          },
        ],
        tax: 0,
        estado: 4,
        id_cliente: 0,
        cliente: [
          {
            id_cliente: 0,
            direccion: "Nothing",
            doc_nit: "Nothing",
            email: "Nothing",
            delivery: "Nothing",
            nombre: "Nothing",
            telefono: "Nothing",
            country: [
              {
                name: "Nothing",
              },
            ],
            customer_type: {
              id_customerType: 0,
              type: "Nothing",
            },
          },
        ],
        createdAt: this.date.toLocaleDateString(),
        dueDate: this.date.toLocaleDateString(),
        invoiced_date: this.date.toLocaleDateString(),
        seller: [
          {
            id_seller: 0,
            nombre: "Nothing",
            email: "Nothing",
            doc_nit: "Nothing",
          },
        ],
        estado_factura: 0,
        tax_incl: "Nothing",
        billyInvoice: [
          {
            id_primary: 0,
            id_fact: "Nothing",
            id_billy: "Nothing",
            invoice_number: "Nothing",
            voucher: 1
          },
        ],
      },
    ];
    return quots;
  }

  notesNull() {
    const notesNull: INotasGrap[] = [
      {
        id_nota: 0,
        note_fact: 0,
        tipo_nota: "N/A",
        concepto: "N/A",
        total: 0,
        createAt: "N/A",
        dueDate: "N/A",
        valor_notas: [
          {
            id_articulo: 0,
            warehouse: {
              id_almacen: 0,
              nombre: "Nothing",
              estado: 0,
            },
            codigo: "N/A",
            cantidad: 0,
            precio: 0,
            subtotal: 0,
            descuento: 0,
            articulo: [],
          },
        ],
      },
    ];
    return notesNull;
  }

  reduceArrayInit(arrayTopInit: ITopCustomer[]) {
    const result: { [key: string]: ITopCustomer } = {};
    for (const object of arrayTopInit) {
      const key = object.invoice + object.article;
      if (result[key]) {
        result[key].value += object.value;
      } else {
        result[key] = { ...object };
      }
    }
    return Object.values(result);
  }

  reduceArrayLiters(arrayTopInit: ITopLiters[]) {
    const result: { [key: string]: ITopLiters } = {};
    for (const object of arrayTopInit) {
      const key = object.item + object.article;
      if (result[key]) {
        result[key].calculation += object.calculation;
      } else {
        result[key] = { ...object };
      }
    }
    return Object.values(result);
  }

  reduceArrayMaps(arrayTopInit: IInvoicesMapsData[]) {
    const result: { [key: string]: IInvoicesMapsData } = {};
    for (const object of arrayTopInit) {
      const key = object.invoice + object.article;
      if (result[key]) {
        result[key].calculation += object.calculation;
        result[key].pastCalculation += object.pastCalculation;
      } else {
        result[key] = { ...object };
      }
    }
    return Object.values(result);
  }

  reduceNotes(arrayGroupNotes: ITopCustomerNotes[]) {
    const result: { [key: string]: ITopCustomerNotes } = {};
    for (const object of arrayGroupNotes) {
      const key = object.fact + object.articulo;
      if (result[key]) {
        result[key].cantidad += object.cantidad;
        result[key].litros += object.litros;
        result[key].subtotal += object.subtotal;
      } else {
        result[key] = { ...object };
      }
    }
    return Object.values(result);
  }

  getNoteValues(notes: INotasGrap[], arrayInvoices: number[]) {
    const noteIds = this.calculateAnalysis.getNoteValues(notes, arrayInvoices);
    return this.processNotes.getNotesPerMaps(notes, noteIds.arrayMultipleIds);
  }
}
