import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NullValidationsService {
  constructor() {}

  validateValues(value: any) {
    return value.toString().toLocaleLowerCase().trim() === "null"
      ? null
      : value.toString().trim();
  }
}
