

<mat-tab-group #tabs mat-align-tabs="center">
  <mat-tab label="{{'Analysis' | translate}}"><br>
    <mat-accordion>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'Filters' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            {{'Display filters' | translate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        
        <div class="row">
          <div class="col-sm">
            <mat-form-field appearance="fill">
              <mat-label>{{'Enter a date range' | translate }}</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker> 
            
              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid start date' | translate}}</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid end date' | translate}}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm">
            <form [formGroup]="registerForm"> 
              <div class="autocomplete-container">                            
                <input class="form-control input-option" formControlName='customer' name="customer" placeholder="{{'Filter by customer' | translate}}" 
                [matAutocomplete]="autoCustomer">
                  <mat-autocomplete class="select-options" #autoCustomer="matAutocomplete" [displayWith]="displayFnCustomer">
                      <mat-option class="multiline-mat-option" *ngFor="let customer of customerList"
                        [value]="customer">
                        {{customer.nombre}}
                      </mat-option>
                  </mat-autocomplete>
              </div>
              <div class="autocomplete-container">                            
                <input class="form-control input-option" formControlName='item' name="item" placeholder="{{'Filter by article' | translate}}" 
                [matAutocomplete]="autoArticle">
                  <mat-autocomplete class="select-options" #autoArticle="matAutocomplete" [displayWith]="displayFnArticle">
                      <mat-option class="multiline-mat-option" *ngFor="let article of articleList"
                        [value]="article">
                        {{article.descripcion}}
                      </mat-option>
                  </mat-autocomplete>
              </div>
            </form>
          </div>

          <div class="col-sm">
            <form [formGroup]="registerForm">
              <div class="autocomplete-container">                            
                <input class="form-control input-option" formControlName='seller' name="seller" placeholder="{{'Filter by seller' | translate}}" 
                [matAutocomplete]="autoSeller">
                <mat-autocomplete class="select-options" #autoSeller="matAutocomplete" [displayWith]="displayFnSeller">
                    <mat-option class="multiline-mat-option" *ngFor="let seller of sellerList"
                      [value]="seller">
                      {{seller.nombre}}
                    </mat-option>
                </mat-autocomplete>
              </div>
            </form>
          </div>

          <div class="button-div">
            <button class="button" (click)="update(quotaList, notasListPrimary)" mat-raised-button>{{'Update' | translate}}</button>
            <button class="button" (click)="reset(quotaListPrimary, notasListPrimary);" mat-raised-button>{{'Reset' | translate}}</button>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>

    <div fxLayout="column" fxLayoutAlign="space-around center">
      <form [formGroup]="registerForm">
        <mat-form-field appearance="fill" class="presentation-select">
          <mat-label>{{'Choose an presentation' | translate}}</mat-label>
          <mat-select formControlName="presentation" (selectionChange)="filterTypes($event, quotaListPrimary, notasListPrimary)">
            <mat-option *ngFor="let types of presentationTypes; let i = index" [value]="types">
              {{types.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{'Select year' | translate}}</mat-label>
          <mat-select formControlName="year" (selectionChange)="data($event.value)">
            <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <app-grap-v1  [orderCustomerQuots]="orderCustomerQuots"
                  [notas]="notas"
                  [months]="months"
                  [typePres]="typePres">
    </app-grap-v1>
  </mat-tab>
<!-- Comments while the host is being fixed -->
  <!-- <mat-tab label="{{'Analysis CSV' | translate}}" *ngIf="compa ==='8' || compa ==='6'">
    <mat-accordion style="margin-bottom: 20px;">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'Filters' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            {{'Click to display filters' | translate}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-sm">
            <mat-form-field appearance="fill">
              <mat-label>{{'Enter a date range' | translate }}</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker> 
            
              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid start date' | translate}}</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid end date' | translate}}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm">
            <form [formGroup]="registerForm"> 
              <div>                            
                <input class="form-control" formControlName='customer' name="customer" placeholder="{{'Filter by customer' | translate}}" 
                [matAutocomplete]="autoCustomer"  style="width: 300px;">
                    <mat-autocomplete #autoCustomer="matAutocomplete" [displayWith]="displayFnCustomer">
                        <mat-option  *ngFor="let customer of customerList"
                            [value]="customer">
                            {{customer.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <div>                            
                  <input class="form-control" formControlName='item' name="item" placeholder="{{'Filter by article' | translate}}" 
                  [matAutocomplete]="autoArticle"  style="width: 300px;">
                      <mat-autocomplete #autoArticle="matAutocomplete" [displayWith]="displayFnArticle">
                          <mat-option  *ngFor="let article of articleList"
                              [value]="article">
                              {{article.descripcion}}
                          </mat-option>
                      </mat-autocomplete>
                  </div>
            </form>
          </div>
          <div class="col-sm">
            <form [formGroup]="registerForm">
              <div>                            
                <input class="form-control" formControlName='seller' name="seller" placeholder="{{'Filter by seller' | translate}}" 
                [matAutocomplete]="autoSeller"  style="width: 300px;">
                    <mat-autocomplete #autoSeller="matAutocomplete" [displayWith]="displayFnSeller">
                        <mat-option  *ngFor="let seller of sellerList"
                            [value]="seller">
                            {{seller.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </form>
          </div>

          <div style="margin-top: 20px;">
            <button (click)="updateCsv()" style="margin-left: 20px;" mat-raised-button>{{'Update' | translate}}</button>
            <button (click)="resetCsv();" style="margin-left: 20px;" mat-raised-button>{{'Reset' | translate}}</button>
          </div>
        </div>   
      </mat-expansion-panel>
    </mat-accordion>
    <app-csv 
      [Ctas_cobrar]="Ctas_cobrar" 
      [Cli_csv]="Cli_csv" 
      [Cat_Art_csv]="Cat_Art_csv"
      [Val_Art_csv]="Val_Art_csv"
      [Product_Days]="Product_Days"
      [Product_Inven]="Product_Inven"
      [month]="month"
      [Dollar_customer_iva]="Dollar_customer_iva"
    ></app-csv>
  </mat-tab> -->
<!-- Comments while the host is being fixed -->
</mat-tab-group>
<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
