
<div id="gauge-demo">
    <div id="gauge-container">
        <div class="center-section">
        <dx-circular-gauge id="speed-gauge" [value]="value">
            <dxo-size [width]="260"></dxo-size>
            <dxo-value-indicator
                type="twoColorNeedle"
                color="none"
                [secondFraction]="0.24"
                [secondColor]="color">
            </dxo-value-indicator>
            <dxo-geometry [startAngle]="225" [endAngle]="315"> </dxo-geometry>
            <dxo-scale
            [startValue]="0"
            [endValue]="180"
            [tickInterval]="10"
            [minorTickInterval]="10"
            >
            </dxo-scale>
        </dx-circular-gauge>
        <ng-container *ngIf="value >= 0 && value < 75">
            <div class="speed-value">
                <span>{{ value }}%</span>
            </div>
        </ng-container>
        <ng-container *ngIf="value > 75 && value < 120">
            <div class="speed-value1">
                <span>{{ value }}%</span>
            </div>
        </ng-container>
        <ng-container *ngIf="value > 120 && value <= 180">
            <div class="speed-value2">
                <span>{{ value }}%</span>
            </div>
        </ng-container>
        <ng-container *ngIf="value > 180">
            <div class="speed-value2">
                <span>180%</span>
            </div>
        </ng-container>
        </div>
    </div>
</div>





