import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { IMailList } from "src/app/@interfaces/lists.interface";
import { PortfolioService } from "../../../../../@pages/portfolio/portfolio.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-crud-mails",
  templateUrl: "./crud-mails.component.html",
  styleUrls: ["./crud-mails.component.css"],
})
export class CrudMailsComponent implements OnInit, OnDestroy {
  registerForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array([
      this.fb.group({
        mails: ['', [Validators.required, Validators.pattern(this.validatorS.emailPattern), Validators.maxLength(50)]],
      })
    ]),
    group_mails: ['', [Validators.required]],
  });

  get rowsArr() {
    return this.registerForm.get("itemRows") as UntypedFormArray;
  }
  unsubscribe$ = new Subject();
  @Input() listMails: Array<IMailList> = [];
  @Input() groupMails: string = "";

  constructor(
    private fb: UntypedFormBuilder,
    private mailList: PortfolioService,
    private router: Router,
    private validatorS: ValidatorsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.mailList
    .getMailList(this.groupMails)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.listMails = result;
    });
    this.setGroupMails(this.groupMails);
  }

  remove(id: number, index: number) {
    Swal.fire({
      title: this.translate.instant("Are you sure?"),
      text: this.translate.instant("You won not be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5400",
      cancelButtonColor: "rgb(0, 0, 0)",
      confirmButtonText: "Confirm",
    }).then((confirm) => {
      if (confirm.isConfirmed) {
        this.mailList
        .removeMailList(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (!result) {
            Swal.fire(
              "Error",
              this.translate.instant("Something was wrong"),
              "error"
            );
          } else {
            Swal.fire(
              this.translate.instant("Good"),
              this.translate.instant("Mail removed"),
              "success"
            ).then((data) => {
              if (data.isConfirmed) {
                if (this.groupMails === "Portfolio") {
                  this.router
                  .navigateByUrl("/dialog", { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate(["/portfolio"]);
                  });
                } else {
                  this.router
                  .navigateByUrl("/dialog", { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate(["/accountsPayable"]);
                  });
                }
                this.mailList
                .getMailList(this.groupMails)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((result) => {
                  this.listMails = result;
                });
              }
            });
          }
        });
      }
    });
  }

  update() {
    if (this.registerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Mail Invalid, Please Check!"),
      });
      return;
    }
    const group = this.registerForm.value.group_mails;
    for (let index = 0; index < this.rowsArr.value.length; index++) {
      this.mailList
      .createMailList(this.rowsArr.value[index].mails, group)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          Swal.fire({
            title: this.translate.instant("Created"),
            text: this.translate.instant("Mail created successfully!"),
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#f44336",
            confirmButtonText: "Ok",
          }).then((data) => {
            if (data.isConfirmed) {
              if (this.groupMails === "Portfolio") {
                this.router
                .navigateByUrl("/dialog", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate(["/portfolio"]);
                });
              } else {
                this.router
                .navigateByUrl("/dialog", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate(["/accountsPayable"]);
                });
              }
              this.mailList
              .getMailList(this.groupMails)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((result) => {
                this.listMails = result;
              });
            }
          });
        } else {
          Swal.fire(
            this.translate.instant("Something was wrong"),
            this.translate.instant("Failed"),
            "error"
          );
        }
      });
    }
  }

  arrayvalid(text: string) {
    return this.rowsArr.get(text)?.invalid && this.rowsArr.get(text)?.touched;
  }

  setGroupMails(groupMails: string) {
    this.registerForm.patchValue({
      group_mails: groupMails,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
