import { Pipe, PipeTransform } from '@angular/core';
import { IResultProducts } from 'src/app/@interfaces/csv/csv.interface';

@Pipe({
  name: 'filtersellerCsv'
})
export class FilterSellerCsvPipe implements PipeTransform {

  transform(seller: IResultProducts[], searchValue: string): IResultProducts[] {
    return seller.filter(seller =>
        seller.cod_vendedor!.toString().toLocaleLowerCase()===(searchValue.toLocaleLowerCase()))
  }
}