<mat-dialog-content>
<form *ngIf="companyId == '1' || companyId == '2' || companyId == '5' || companyId == '6' || companyId == '7' || companyId == '8' || companyId == '9'">
    <h2><strong>{{'Delivery' | translate}}</strong></h2>
    <div class="text-margin">
        <span><strong>{{'Send to' | translate}}:</strong></span>
        <input class="form-control" [(ngModel)]="deliveryTxt" type="text" name="deliveryTxt" autocomplete="off">
    </div>
    <div style="margin-bottom: 20px;">
        <button mat-raised-button (click)="validation(1);" mat-dialog-close>{{'Save Changes' | translate}}</button>
        <button mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
    </div>
</form>


<form [formGroup]="registerForm" *ngIf="companyId == '4'">
    <h3><strong>{{'Delivery' | translate}}</strong></h3>
    <div class="row">
        <div class="col-md-4">
            <span> {{'Quotation' | translate}}:</span>
            <input class="form-control" formControlName="invoice" readonly>
            <span class="form-text text-danger" *ngIf="textValid('invoice')">{{'Quotation' | translate}}</span>
        </div>
        <div class="col-md-4">
            <span> {{'Driver' | translate}}:</span>
            <input class="form-control" placeholder="{{'Type the driver`s name' | translate}}" formControlName="chofer">
            <span class="form-text text-danger" *ngIf="textValid('chofer')">{{'Name of driver required' | translate}}</span>
        </div>
        <div class="col-md-4">
            <span> C.I.N:</span>
            <input class="form-control" placeholder="{{'Type the driver`s C.I.N' | translate}}" formControlName="cin">
            <span class="form-text text-danger" *ngIf="textValid('cin')">{{'C.I.N of driver required' | translate}}</span>
        </div>
        <div class="col-md-4">
            <span> {{'Plate' | translate}}:</span>
            <input class="form-control" placeholder="{{'Type the driver`s plate' | translate}}" formControlName="placa">
            <span class="form-text text-danger" *ngIf="textValid('placa')">{{'Plate of driver required' | translate}}</span>
        </div>
        <div class="col-md-4">
            <span> {{'Rasda Permit' | translate}}:</span>
            <input class="form-control" placeholder="{{'Type the driver`s rasda permit' | translate}}" formControlName="permiso_rasda">
            <span class="form-text text-danger" *ngIf="textValid('permiso_rasda')">{{'Rasda Permit of driver required' | translate}}</span>
        </div>
        <div class="col-md-4">
            <span> {{'Destination' | translate}}:</span>
            <input class="form-control" placeholder="{{'Type the destination' | translate}}" formControlName="destino">
            <span class="form-text text-danger" *ngIf="textValid('destino')">{{'Destination required' | translate}}</span>
        </div>
        <div class="col-md-4">
            <span> {{'Net Weight' | translate}}:</span>
            <input class="form-control" placeholder="{{'Type the net weight' | translate}}" formControlName="peso_neto">
            <span class="form-text text-danger" *ngIf="textValid('peso_neto')">{{'Net Weight required' | translate}}</span>
        </div> 
        <div class="col-md-4">
            <span> {{'Exchange rate' | translate}}:</span>
            <input class="form-control" placeholder="{{'Type the exchange rate' | translate}}" formControlName="tasa_c" (keyup)="calculation()">
            <span class="form-text text-danger" *ngIf="textValid('tasa_c')">{{'Exchange rate required' | translate}}</span>
        </div>
        <div class="col-md-4">
            <span> {{'ARE' | translate}}:</span>
            <textarea class="form-control" placeholder="{{'Type the totals in letters for the invoice' | translate}}" formControlName="son" id="" cols="2" rows="2"></textarea>
            <span class="form-text text-danger" *ngIf="textValid('son')">{{'ARE required' | translate}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <form>
                <span>{{'Send to' | translate}}:</span>
                <input class="form-control" [(ngModel)]="deliveryTxt" type="text" name="deliveryTxt" autocomplete="off">
            </form>
        </div>
    </div>
    <div id="center-responsive" class="row" style="padding-left: 20px; margin-top:10px;">
        <div class="col-md-2">
            <button (click)="validation(2)" mat-raised-button>{{"Register" | translate}}</button>
        </div>
        <div class="col-md-3">
            <button mat-raised-button [mat-dialog-close]>{{'Cancel' | translate}}</button>
        </div>
        <div class="col-md-2">
            <p>{{'Dollars' | translate}}:</p>
            <strong>{{dollars | currency: 'USD'}}</strong>
        </div>
        <div class="col-md-2">
            <p>{{'Bolivars' | translate}}:</p>
            <strong>{{calcDevise | currency: 'Bs': "symbol"}}</strong>
        </div>
    </div>
</form>
</mat-dialog-content>

