import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsService } from './alerts/alerts.service';
import { ErrorsService } from './alerts/errors.service';
import { CalculateAnalysis } from './charts/calculate-analysis.service';
import { GraphicQueriesService } from './charts/graphic-queries.service';
import { ProcessNotesService } from './charts/process-notes.service';
import { ConverseArrayService } from './data-types/converse-array.service';
import { ConverseDateService } from './data-types/converse-date.service';
import { CreatePDFService } from './files/pdf/createPDF.service';
import { CreatePDFPurchasesService } from './files/pdf/createPDFpurchase.service';
import { GenerateDetailsPDFService } from './files/pdf/generate-details-pdf.service';
import { GenerateLetterheadPdfService } from './files/pdf/generate-letterhead-pdf.service';
import { ConverseFilesService } from './files/converse-files.service';
import { GenerateTxtService } from './files/generate-txt.service';
import { ImageConverseService } from './files/imageConverse.service';
import { CitiesFilterService } from './filters/cities-filter.service';
import { FilterBySearchService } from './filters/filterBySearch.service';
import { TransferFiltersService } from './filters/transfer-filters.service';
import { CitiesFormService } from './forms/cities-form.service';
import { CancelImportsService } from './imports/cancel-imports.service';
import { ImportNotesService } from './imports/import-notes.service';
import { ImportProspectsService } from './imports/import-prospects.service';
import { ImportPurchasesService } from './imports/import-purchases.service';
import { CalculateOperationalReportingService } from './reports/calculate-operational-reporting.service';
import { ProcessReportsDataService } from './reports/process-reports-data.service';
import { ArrayValidationsService } from './validations/array-validations.service';
import { ArticleValidationsService } from './validations/article-validations.service';
import { NullValidationsService } from './validations/null-validations.service';
import { TaxValidationsService } from './validations/tax-validations.service';
import { ValidateMultipleTypesService } from './validations/validate-multiple-types.service';


@NgModule({
  imports: [CommonModule],
  providers: [
    AlertsService,
    ErrorsService,
    CalculateAnalysis,
    GraphicQueriesService,
    ProcessNotesService,
    ConverseArrayService,
    ConverseDateService,
    CreatePDFService,
    CreatePDFPurchasesService,
    GenerateDetailsPDFService,
    GenerateLetterheadPdfService,
    ConverseFilesService,
    GenerateTxtService,
    ImageConverseService,
    CitiesFilterService,
    FilterBySearchService,
    TransferFiltersService,
    CitiesFormService,
    CancelImportsService,
    ImportNotesService,
    ImportProspectsService,
    ImportPurchasesService,
    CalculateOperationalReportingService,
    ProcessReportsDataService,
    ArrayValidationsService,
    ArticleValidationsService,
    NullValidationsService,
    TaxValidationsService,
    ValidateMultipleTypesService
  ],
})
export class ServicesModule {}