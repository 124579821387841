<mat-dialog-content>
    <mat-accordion class="example-headers-align" multi *ngFor="let item of details">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title *ngIf="item.electronic_number === 'PENDING'">
              {{'Invoice' | translate}} {{item.invoice_number}}
            </mat-panel-title>
            <mat-panel-title *ngIf="item.electronic_number !== 'PENDING'">
              {{'Invoice' | translate}} {{item.electronic_number}}
            </mat-panel-title>
            <mat-panel-description>
              {{'Click for details' | translate}}
              <mat-icon>monetization_on</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p>{{'Created Date:' | translate}} {{item.createdDate | date: 'short' }}</p>
          <p>{{'Due Date:' | translate}} {{item.dueDate | date: 'short' }}</p>
          <p>{{'Days past due:' | translate}} {{item.daysBetweenDates}}</p>
          <p>{{'Total Invoice:' | translate}} {{item.total | currency: 'USD'}}</p>
          <p>{{'Pending:' | translate}} {{item.totalPaid! | currency: 'USD'}}</p>
          <p>{{'Completed:' | translate}} {{item.total - item.totalPaid! | currency: 'USD'}}</p>
      
          <ng-container *ngIf="item.creditNote">
            <ng-container *ngIf="item.creditNote!.length > 0">
              <p>{{'Credit Notes' | translate}}:</p>
              <table mat-table  [dataSource]="item.creditNote!" class="mat-elevation-z8">
                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef> {{'Code' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                  </ng-container>
                  <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef> {{'Total' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsNotes"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsNotes"></tr>
                </table>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="item.debitNote">
            <ng-container *ngIf="item.debitNote!.length > 0">
              <p>{{'Debit Notes' | translate}}:</p>
              <table mat-table  [dataSource]="item.debitNote!" class="mat-elevation-z8">
                  <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef> {{'Code' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.code}} </td>
                  </ng-container>
                  <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef> {{'Total' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsNotes"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsNotes"></tr>
                </table>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="item.paid">
            <ng-container *ngIf="item.paid!.length > 0">
              <p>{{'Payments' | translate}}:</p>
              <table mat-table  [dataSource]="item.paid!" class="mat-elevation-z8">
                  <ng-container matColumnDef="identifier" sticky>
                      <th mat-header-cell *matHeaderCellDef> {{'ID' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{element.identifier}} </td>
                    </ng-container>
                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> {{'Type' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.type | translate}} </td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> {{'Date' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date: 'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> {{'Value' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsPays"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsPays"></tr>
                </table>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="item.purchasePaid">
            <ng-container *ngIf="item.purchasePaid!.length > 0">
              <p>{{'Payments' | translate}}:</p>
              <table mat-table  [dataSource]="item.purchasePaid!" class="mat-elevation-z8">
                  <ng-container matColumnDef="identifier" sticky>
                      <th mat-header-cell *matHeaderCellDef> {{'ID' | translate}} </th>
                      <td mat-cell *matCellDef="let element"> {{element.identifier}} </td>
                    </ng-container>
                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> {{'Type' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.type | translate}} </td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> {{'Date' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date: 'shortDate'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> {{'Value' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="columnsPays"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsPays"></tr>
                </table>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="item.creditNote">
            <ng-container *ngIf="item.creditNote!.length <= 0">
              <div class="alert alert-danger">
                  {{'NO CREDIT NOTES' | translate}}
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="item.debitNote">
            <ng-container *ngIf="item.debitNote!.length <= 0">
              <div class="alert alert-danger">
                  {{'NO DEBIT NOTES' | translate}}
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="item.paid">
            <ng-container *ngIf="item.paid!.length <= 0">
              <div class="alert alert-danger">
                  {{'NO PAYMENTS' | translate}}
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="item.purchasePaid">
            <ng-container *ngIf="item.purchasePaid!.length <= 0">
              <div class="alert alert-danger">
                  {{'NO PAYMENTS' | translate}}
              </div>
            </ng-container>
          </ng-container>
          
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>

