import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DateFilterComponent implements OnInit {
  rangeForm!: FormGroup;
  currentDate!: string;
  defaultValue = 'Today';
  @Output() datepickerRange = new EventEmitter<{ start: string | null; end: string | null, valid: boolean }>();

  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef, private datePipe: DatePipe, private translate: TranslateService) { 
    this.currentDate = this.datePipe.transform(new Date(), 'M-d-yyyy')!;
  }

  ngOnInit() {
    this.rangeForm = this.fb.group({
      dateRange: this.fb.group({
        start: [this.currentDate, Validators.required],
        end: [this.currentDate, Validators.required]
      })
    })
    const rangeForm = this.rangeForm.get('dateRange');
    const startValue = rangeForm?.get('start')?.value
    const endValue = rangeForm?.get('end')?.value
    if (startValue === this.currentDate
       && endValue === this.currentDate) {
      this.defaultValue = this.translate.instant("Today")
      rangeForm?.patchValue({ start: this.defaultValue })
    }
    rangeForm?.valueChanges.subscribe((date) => {
      const eventValue = { ...date, valid: rangeForm?.valid };
      this.getDatepickerRange(eventValue);
    })
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  getDatepickerRange(value: { start: Date | null; end: Date | null; valid: boolean }) {
    const date = { 
      start: this.datePipe.transform(value.start, 'M-d-yyyy'),
      end: this.datePipe.transform(value.end, 'M-d-yyyy'), 
      valid: value.valid
    }
    this.datepickerRange.emit(date);
  }
}
