import { Injectable, OnDestroy } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { CustomerService } from '../../../../../@pages/customer/customer.service';
import { PortfolioService } from "src/app/@pages/portfolio/portfolio.service";
import { IInvoicePayments, IPaymentsList, IPurchasePayments } from "src/app/@interfaces/payments.interface";
import { UntypedFormGroup } from "@angular/forms";
import { TreasuryService } from '../../../../../@pages/treasury/treasury.service';
import { ITreasury } from "src/app/@interfaces/treasury.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { AlertsService } from "../../../services/alerts/alerts.service";

@Injectable({
  providedIn: "root",
})
export class PaymentsService implements OnDestroy {
  unsubscribe$ = new Subject();

  constructor(
    private quotationService: QuotationsService,
    private translate: TranslateService,
    private customerService: CustomerService,
    private portfolioService: PortfolioService,
    private treasuryService: TreasuryService,
    private alertsService: AlertsService,
    private cookieAuthService: CookieAuthService
  ) {}

  getCreditValues(id_factura: number) {
    return new Promise<number>((resolve, reject) => {
      let total = 0;
      this.quotationService
      .getCredito(parseInt(id_factura.toString()))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((credit: INotasGrap[]) => {
        credit.forEach((C) => {
          total += C.total * -1;
        });
        resolve(total);
      });
    });
  }

  getDebitsValues(id_factura: number) {
    return new Promise<number>((resolve, reject) => {
      let total = 0;
      this.quotationService
      .getDebito(parseInt(id_factura.toString()))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((debit: INotasGrap[]) => {
        debit.forEach((D) => {
          total += D.total;
        });
        resolve(total);
      });
    });
  }

  getInvoice(id_factura: number) {
    return new Promise<any>((resolve, reject) => {
      this.quotationService
      .getQuotation(id_factura)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      });
    });
  }

  getAdvancePayments(id_payments: number[], id_customer: number | null, id_provider: number | null, type: string) {
    return new Promise<ITreasury[]>((resolve, reject) => {
      this.treasuryService
      .getAdvancePaymentsByType(id_payments, id_customer, id_provider, type)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      })
    })
  }

  getPositiveBalance(id_customer: number) {
    return new Promise<number>((resolve, reject) => {
      this.customerService
      .getCustomer(id_customer)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (result) => {
          resolve(result.positive_balance);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getCashierList() {
    const rol = this.cookieAuthService.getRolId!;
    const user = this.cookieAuthService.getUserId!;
    return new Promise<IPaymentsList[]>((resolve, reject) => {
      if (rol === "1" || rol === "3") {
        this.portfolioService
        .getCashierList()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result)
        });
      }else {
        this.portfolioService
        .getCashierListByUser(parseInt(user))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result)
        });
      }
    })
  }

  async getBankList() {
    const rol = this.cookieAuthService.getRolId!;
    const user = this.cookieAuthService.getUserId!;
    return new Promise<IPaymentsList[]>((resolve, reject) => {
      if (rol === "1" || rol === "3") {
        this.portfolioService
        .getBankList()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result)
        });
      }else {
        this.portfolioService
        .getBankListByUser(parseInt(user))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          resolve(result)
        });
      }
    })
  }

  updatePositiveBalanceByInvoice(id_factura: number, positiveBalance: number) {
    this.quotationService
    .updatePositiveBalanceInvoice(id_factura, positiveBalance)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
    },(error) => {
      Swal.fire(
        "error",
        this.translate.instant("Something was wrong"),
        error
      );
    });
  }

  validCompletePayments(invoicePayments: IInvoicePayments[] | IPurchasePayments[]) {
    const valid = invoicePayments.map((pay) => {
      return pay.status;
    });
    let total = valid.reduce((a, b) => a + b, 0);
    return total === invoicePayments.length * 3 ? true : false;
  }

  getDescriptionPayment(valuePositiveBalance: boolean, paymentsForm: UntypedFormGroup) {
    let descriptionPayment = "";
    if (valuePositiveBalance === true) {
      descriptionPayment = "positive_balance";
    } else {
      descriptionPayment = paymentsForm.get("types")?.value;
    }
    return descriptionPayment;
  }

  createValidations(
    paymentsForm: UntypedFormGroup, value: number, positiveBalanceValue: number, 
    valuePositiveBalance: boolean, totalPaidQuot: number, paymentType: IPaymentsList[]): boolean {
    if (paymentsForm.invalid) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return false;
    }
    else if (value <= 0) {
      this.alertsService.showErrorAlert("You cannot make a payment of less than 0!");
      return false;
    }
    else if (value > positiveBalanceValue && valuePositiveBalance) {
      this.alertsService.showErrorAlert(
        "You cannot make a payment greater than the balance in favor that the client has!"
      );
      return false;
    }
    else if ((totalPaidQuot === 0) && (valuePositiveBalance)) {
      this.alertsService.showErrorAlert(
        "You cannot make the payment with the balance in favor!"
      );
      return false;
    }
    else if (paymentType.length <= 0) {
      this.alertsService.showErrorAlert("There is no payment on this invoice!");
      return false;
    }else {
      return true;
    }
  }

  updatePositiveBalanceByCustomer(id_customer: number, positiveBalance: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.customerService
      .updatePositiveBalance(id_customer, positiveBalance)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result.nombre !== "customerExist") {
          Swal.fire(
            this.translate.instant("success"),
            this.translate.instant("Customer updated successfully"),
            "success"
          ).then(async (data) => {
            if (data.isConfirmed) {
              resolve(true)
            }
          });
        } else {
          Swal.fire(
            "error",
            this.translate.instant("Something was wrong"),
            "error"
          );
        }
      });
    })
  }

  getTotalWithAdvancePayment(totalPaid: number, advancePaymentValue: number) {
    return totalPaid >= advancePaymentValue ? advancePaymentValue : totalPaid;
  }

  advancePaymentValidations(advancePayment: ITreasury, value: number) {
    const avaliable = parseFloat((advancePayment.value - advancePayment.value_used).toFixed(2));
    if ((value > avaliable) && advancePayment) {
      this.alertsService.showErrorAlert(
        "You cannot make a payment greater than the advance payment that the client has!"
      );
      return false;
    }else if (value <= 0 && advancePayment) {
      this.alertsService.showErrorAlert(
        "You cannot make the payment with the advance payment!"
      );
      return false;
    }else {
      return true;
    }
  }

  relateAdvancePayment(action: string, type: string, id_payment: number, id_treasury: number, value: number) {
    return new Promise<boolean>(async (resolve, reject) => {
      if (action === "Set") {
        type === "Invoice" ?
        resolve(await this.setRelateInvoice(id_payment, id_treasury, value)) :
        resolve(await this.setRelatePurchase(id_payment, id_treasury, value))
      }else {
        type === "Invoice" ?
        resolve(await this.removeRelateInvoice(id_payment, id_treasury, value)) :
        resolve(await this.removeRelatePurchase(id_payment, id_treasury, value))
      }
    })
  }

  setRelateInvoice(id_payinvoice: number, id_treasury: number, value: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.treasuryService
      .setInvoicePaymentTreasury(id_payinvoice, id_treasury, value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(true)
      },(error) => {
        console.error(error)
      })
    })
  }

  removeRelateInvoice(id_payinvoice: number, id_treasury: number, value: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.treasuryService
      .deleteInvoicePaymentTreasury(id_payinvoice, id_treasury, value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(true)
      },(error) => {
        console.error(error)
      })
    })
  }

  setRelatePurchase(id_paypurchases: number, id_treasury: number, value: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.treasuryService
      .setPurchasePaymentTreasury(id_paypurchases, id_treasury, value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(true)
      },(error) => {
        console.error(error)
      })
    })
  }

  removeRelatePurchase(id_paypurchases: number, id_treasury: number, value: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.treasuryService
      .deletePurchasePaymentTreasury(id_paypurchases, id_treasury, value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(true)
      },(error) => {
        console.error(error)
      })
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
