import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { ProfileRoutingModule } from "./profile-routing.module";
import { ProfileComponent } from "./profile.component";
import { MatIconModule } from "@angular/material/icon";
import { DialogModule } from "src/app/@shared/components/dialog/dialog.module";
import { MaterialModule } from "src/app/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    ReactiveFormsModule,
    MatIconModule,
    DialogModule,
    MaterialModule,
    TranslateModule,
    NgxSpinnerModule,
  ],
  exports: [ProfileComponent],
})
export class ProfileModule {}
