<mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="start center">
    <img fxFlexAlign="start" class="logo" src="../../../../../assets/icons/icono.png" onerror="this.src='assets/img/cantfindimage.jpg'"/>
    <div>
      <h2 mat-dialog-tittle>{{ "Transfers" | translate }}</h2>
    </div>
  </div>

  <div class="selected-data">
    <span class="caption">{{"Transfers and Consignments" | translate}}</span>
  </div>
  <div class="custom-container">
    <form [formGroup]="customTransferForm" fxflex fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayout.sm="column">
      <div class="custom-items-container">
        <mat-form-field class="custom-items-container">
          <mat-label>{{'Value' | translate}}</mat-label>
          <input type="number" matInput formControlName="value" autocomplete="off">  
        </mat-form-field>
      </div>
      <div class="custom-items-container transfer-item">
        <mat-form-field class="custom-items-container"> 
          <mat-label>{{'Transfer Of' | translate}}</mat-label>
          <input id="input-top" type="text" matInput [matAutocomplete]="autoTransferOf" formControlName="transferOf">
          <mat-autocomplete #autoTransferOf="matAutocomplete" [displayWith]="displayFnPaymentType">
              <mat-option *ngFor="let payment of filteredOptionsTransferOf | async" [value]="payment">
                  {{payment.description}}
              </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="trasnfer-icon">
        <mat-icon> 360</mat-icon>
      </div>
      <div class="custom-items-container transfer-item">
        <mat-form-field class="custom-items-container">
          <mat-label>{{'Transfer To' | translate}}</mat-label>
          <input id="input-top" type="text" matInput [matAutocomplete]="autoTransferTo" formControlName="transferTo">
          <mat-autocomplete #autoTransferTo="matAutocomplete" [displayWith]="displayFnPaymentType">
              <mat-option *ngFor="let payment of filteredOptionsTransferTo | async" [value]="payment">
                  {{payment.description}}
              </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="custom-items-container transfer-button">
        <button class="register-custom-button" mat-raised-button (click)="registerCustom();">{{'Register' | translate}}</button>
      </div>
    </form>
  </div>

  <div class="selected-data">
    <span class="caption">{{"Date" | translate}}</span>
  </div>
  <div class="inputs-container" fxflex fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.xs="column" fxLayout.sm="column">
    <div class="date-container">
      <mat-form-field appearance="fill">
        <mat-label>{{'Date' | translate}}</mat-label>
        <input class="date-input" matInput [matDatepicker]="picker" [formControl]="dateForm">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="custom-items-container transfer-button">
      <button mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
    </div>
  </div>
</mat-dialog-content>
