<div class="alert alert-info">
    {{'Sales target this month' | translate}} -------- {{typePres + ' '}} {{'this month' | translate}}
</div>
<app-bar-indicator [projection]="projection" [typePres]="typePres" [meta]="tarjet" [completed]="complete" [today]="todayLet" [pastMonth]="pastLet"></app-bar-indicator>

<div>
    <div style="text-align: center;">   
        <div class="row">
            <div class="col">
                <span>{{'Set Goal' | translate}}:</span>
                <input class="form-control" placeholder="{{'Type Goal' | translate}}" type="text" [ngModel]="tarjet" (ngModelChange)="tarjet = $event">
            </div>
            <div class="col">
                <a matTooltip="This is obtained from your last year's liters / 12">?</a>
                <p>{{'Recommended Goal' | translate}}: {{recommendedTarget}}</p>
            </div>
        </div>
    </div>
    <div style="text-align: center; margin-top: 10px;">
        <button (click)="save(allQuots, allNotas)" mat-raised-button>{{'Save' | translate}}</button>
    </div>
</div>