import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ConverseFilesService } from "./converse-files.service";

@Injectable({
  providedIn: "root",
})
export class GenerateTxtService {
  lang = this.translate.currentLang;
  constructor(
    private translate: TranslateService,
    private converseFilesService: ConverseFilesService
  ) {}

  generateTxtGuide() {
    const text =
      this.lang === "en"
        ? `
        NOTES:
        *NO FIELD MUST BE EMPTY*.
        *IN THE INVOICES AND NOTES, IF THE ITEMS HAVE VAT VALUE, VERIFY WELL THESE VALUES, OR IT CAN GENERATE ERRONEOUS VALUES*.
        *IN THE PROSPECTS, THE FIELDS THAT ARE NOT OBLIGATORY MUST HAVE THE VALUE NULL IF THEY DO NOT HAVE ANOTHER VALUE.*

        INVOICES:
        ID INVOICE: Identifier number of each invoice, if you use DIAN invoicing you must enter the invoice number here.
        TAX INCLUDED: If the items have TAX included in the price put 1 otherwise put 2.
        TAX %: If in TAX INCLUDED put 2 put the TAX percentage that applies for the whole invoice in general 
        (this value must be equal in all the values of each item of each invoice), 
        if no TAX applies put 0, if you put 1 in TAX INCLUDED put 0.
        NIT CUSTOMER: The identification number of the customer as registered in the application.
        NIT SELLER: The identification number of the vendor as registered in the application.
        ID WAREHOUSE: ID number of the warehouse to which the item belongs.
        CREATED DATE: Invoice creation date in MM/DD/YYYYY format.
        DUE DATE: Invoice expiration date in MM/DD/YYYYY format.
        ARTICLE CODE: Item code.
        QUANTITY: Quantity to be invoiced for each item.
        PRICE: Unit price of each item.
        DISCOUNT: If discount applies per item, enter the discount percentage, otherwise enter 0.
        SUBTOTAL: Subtotal of each item applying TAX and discount.
        TOTAL: The total of the whole invoice.

        COLLECTIONS:
        NUMBER: Sequential number of each record.
        INVOICE: Invoice number, if you use DIAN invoicing you must enter the invoice number corresponding to the payment, 
        otherwise you must enter the invoice number generated by the application (I-XXX-XXX).
        YEAR: Year of invoice.
        TYPE CASHIER OR BANK: 1 If it is cash, 2 if it is a current account deposit, 3 if it is a savings account deposit.
        ID CASHIER OR BANK: ID of the cash or bank to which the payment corresponds, this must be previously registered in the system.
        PAYMENT TYPE: If the payment is a credit memo put "Credit memo", if it is a cancellation put "Cancellation".
        DATE: Date of creation of the payment in MM/DD/YYYYYYY format.
        VALUE: Value of the payment.
        REFERENCE: if you have any reference number of the payment put it here, otherwise put N

        NOTES:
        NUMBER: Identifier number of each note, if you use DIAN invoicing you must enter the note number here.
        INVOICE ID: Invoice number, if you use DIAN invoicing you must enter the invoice number corresponding to the note,
        otherwise you must enter the invoice number generated by the application (I-XXX-XXX).
        INVOICE YEAR: Year of invoice.
        NOTE TYPE: Type of note to register "Credito" or "Debit"
        CONCEPT: The reason of the note.
        DATE: The created date of the note in MM/DD/YYYYY format.
        DUE DATE: The due date of the note in MM/DD/YYYYY format.
        ARTICLE CODE: Item code.
        QUANTITY: Quantity to be processed for each item.
        PRICE: Unit price of each item.
        DISCOUNT: If discount applies per item, enter the discount percentage, otherwise enter 0.
        SUBTOTAL: Subtotal of each item applying TAX and discount.
        TOTAL: The total of the whole note.
        
        PURCHASES:
        NUMBER: Sequential number of each record.
        ID PURCHASE: Identifier number of each purchase.
        PROVIDER NIT: The identification number of the provider as registered in the application.
        DATE: The created date of the purchase in MM/DD/YYYYY format.
        DUE DATE: The due date of the purchase in MM/DD/YYYYY format.
        TAX %: The TAX percentage that applies for the whole purchase in general
        (this value must be equal in all the values of each item of each purchase),
        ARTICLE CODE: Item code.
        QUANTITY: Quantity to be processed for each item.
        PRICE: Unit price of each item.
        DISCOUNT: If discount applies per item, enter the discount percentage, otherwise enter 0.
        SUBTOTAL: Subtotal of each item applying TAX and discount.
        WITHHOLDING TAX VALUE: The total witholding tax of the whole purchase.
        ICA TAX VALUE: The total ica of the whole purchase.
        TOTAL: The total of the whole purchase.

        PROSPECTS:
        NUMBER (OBLIGATORY): Sequential number of each record.
        DOCUMENT TYPE (NOT OBLIGATORY): Prospect's identification type id (see respective list of id's).
        NIT (NOT OBLIGATORY): Prospect's identification number.
        VERIFICATION DIGIT (NOT OBLIGATORY): Verification digit of the nit.
        NAME (OBLIGATORY): Prospect's full name.
        ADDRESS (NOT OBLIGATORY): Prospect's address.
        PHONE (NOT OBLIGATORY): Prospect's phone.
        EMAIL (NOT OBLIGATORY): Prospect's email.
        COUNTRY (OBLIGATORY): Prospect's residency country.
        CITY (OBLIGATORY): Prospect's residency city.
        COORDINATES (NOT OBLIGATORY): Prospect's coordinates.
        CONTRIBUTOR ID (NOT OBLIGATORY): Prospect's contributor id (see respective list of id's).
        SELLER ID (OBLIGATORY): Prospect's seller id (see respective list of id's).
        SEGMENT ID (NOT OBLIGATORY): Prospect's segment id (see respective list of id's).
        OBSERVATIONS (NOT OBLIGATORY): Prospect's observations.
        `
        : `
        NOTAS:
        *NINGUN CAMPO DEBE IR VACIO*
        *EN LAS FACTURAS Y NOTAS, SI LOS ITEMS LLEVAN VALOR DE IVA, VERIFICAR BIEN DICHOS VALORES, O PUEDE GENERAR VALORES ERRONEOS*
        *EN LOS PROSPECTOS LOS CAMPOS QUE NO SON OBLIGATORIOS DEBEN IR CON EL VALOR NULL SI ESTE NO LLEVA OTRO VALOR*

        FACTURAS:
        ID FACTURA: Numero identificador de cada factura, si usa facturación con la DIAN deberá colocar el numero de la factura aquí.
        IVA INCLUIDO: SI los ítems llevan el IVA incluido en el precio poner 1 si no poner 2.
        IVA %: Si en IVA INCLUIDO puso 2 ponga el porcentaje de IVA que se aplica para toda la factura en general 
        (este valor debe ser igual en todos los valores de cada item de cada factura), 
        si no aplica ningún IVA ponga 0, si puso 1 en IVA INCLUIDO ponga 0.
        NIT CLIENTE: EL numero de identificación del cliente como esta registrado en la aplicacion.
        NIT VENDEDOR: EL numero de identificación del vendedor como esta registrado en la aplicación.
        ID ALMACEN: Numero ID del almacen al que pertenece el item.
        FECHA CREACION: Fecha de creacion de la factura en formato MM/DD/YYYY.
        FECHA VENCIMIENTO: Fecha de vencimiento de la factura en formato MM/DD/YYYY.
        CODIGO ARTICULO: Codigo del articulo.
        CANTIDAD: Cantidad a facturar de cada item.
        PRECIO: Precio unitario de cada item.
        DESCUENTO: Si aplica descuento por item ponga el porcentaje de este, de lo contrario ponga 0.
        SUBTOTAL: Subtotal de cada ítem aplicando IVA y descuento.
        TOTAL: EL total de toda la factura.

        CARTERA:
        NUMERO: Numero en secuencia de cada registro.
        FACTURA: Numero de la factura, si usa facturacion con la DIAN debera poner el numero de la factura correspondiente al pago, 
        de lo contrario debera poner el numero de factura generado por la aplicacion (I-XXX-XXX).
        AÑO: Año de la factura.
        TIPO CAJA O BANCO: 1 Si es efectivo, 2 si es consignacion cuenta corriente, 3 si es consignacion cuenta de ahorros.
        ID CAJA O BANCO: ID de la caja o banco a la que corresponda el pago, esta debe estar previamente registrada en el sistema.
        TIPO DE PAGO: Si el pago es un abono poner "Abono", si es cancelacion poner "Cancelacion".
        FECHA: Fecha de creacion del pago en formato MM/DD/YYYYY.
        VALOR: Valor del pago.
        REFERENCIA: si tiene algún numero de referencia del pago pongalo aca, de lo contrario poner N

        NOTAS:
        NUMERO: Numero identificador de cada nota, si usa facturación con la DIAN deberá colocar el numero de la nota aquí.
        ID FACTURA: Numero de la factura, si usa facturacion con la DIAN debera poner el numero de la factura correspondiente a la nota, 
        de lo contrario debera poner el numero de factura generado por la aplicacion (I-XXX-XXX).
        AÑO FACTURA: Año de la factura.
        TIPO NOTA: Tipo de nota a registrar "Credito" o "Debito".
        CONCEPTO: La razon de la nota.
        FECHA: Fecha de creacion de la nota en formato MM/DD/YYYYY.
        FECHA VENCIMIENTO: Fecha de vencimiento de la nota en formato MM/DD/YYYYY.
        CODIGO ARTICULO: Codigo del articulo.
        CANTIDAD: Cantidad a procesar por cada item.
        PRECIO: Precio unitario de cada item.
        DESCUENTO: Si aplica descuento por item ponga el porcentaje de este, de lo contrario ponga 0.
        SUBTOTAL: Subtotal de cada ítem aplicando IVA y descuento.
        TOTAL: EL total de toda la nota.

        COMPRAS:
        NUMERO: Numero en secuencia de cada registro.
        ID COMPRA: Numero identificador de cada compra.
        NIT PROVEEDOR: Numero de identificacion del proveedor como esta registrado en la aplicacion.
        FECHA: Fecha de creacion de la compra.
        FECHA: VENCIMIENTO: Fecha de vencimiento de la compra.
        IVA %: Porcentaje de tax que aplica a toda la compra en general
        (este valor debe ser igual en todos los valores de cada item de cada factura).
        CODIGO ARTICULO: Codigo del articulo.
        CANTIDAD: Cantidad a procesar por cada item.
        PRECIO: Precio unitario de cada item.
        DESCUENTO: Si aplica descuento por item ponga el porcentaje de este, de lo contrario ponga 0.
        SUBTOTAL: Subtotal de cada ítem aplicando IVA y descuento.
        VALOR RETEFUENTE: Total de retefuente para toda la compra.
        VALOR ICA: Total de ica para toda la compra.
        TOTAL: El total de toda la compra.

        PROSPECTOS: 
        NUMERO (OBLIGATORIO): Numero en secuencia de cada registro.
        TIPO DOCUMENTO (NO OBLIGATORIO): ID del tipo de identificacion del prospecto (ver la respectiva lista de id's).
        NIT: Numero de identificacion del prospecto.
        DIGITO VERIFICACION (NO OBLIGATORIO): Digito de verificaion del NIT.
        NOMBRE (OBLIGATORIO): Nombre del prospecto.
        DIRECCION (NO OBLIGATORIO): Direccion del prospecto.
        TELEFONO (NO OBLIGATORIO): Telefono del prospecto.
        CORREO (NO OBLIGATORIO): Correo del prospecto.
        PAIS (OBLIGATORIO): Pais de residencia del prospecto.
        CIUDAD (OBLIGATORIO): Ciudad de residencia del prospecto.
        COORDENADAS (NO OBLIGATORIO): Coordenadas del prospecto.
        ID CONTRIBUYENTE (NO OBLIGATORIO): Id de contribuyente del prospecto (ver la respectiva lista de id's).
        ID VENDEDOR (OBLIGATORIO): Id de vendedor del prospecto (ver la respectiva lista de id's).
        ID SEGMENTO (NO OBLIGATORIO): Id de segmento del prospecto (ver la respectiva lista de id's).
        OBSERVACIONES (NO OBLIGATORIO): Observaciones del prospecto.
        `;
    this.converseFilesService.generateTxt(text);
  }
}
