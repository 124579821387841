import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IContFact } from "src/app/@interfaces/contfac.interface";
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from "@techiediaries/ngx-qrcode";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { Driver } from "../../@interfaces/driver.interface";
import { IGraphQToBilly } from "src/app/@interfaces/Billy/billy.interface";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IQuota, IQuotation } from "../../@interfaces/quotation.interface";
import { CalculateDetailsService } from "./calculate-details.service";

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.css"],
})
export class DetailsComponent implements OnInit, OnDestroy {
  id_quotation: number = 0;
  subtotal: number = 0;
  taxCalc: number = 0;
  discount: number = 0;
  total: number = 0;
  tasaBCV: number | undefined;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "";
  quotation = {} as IQuotation;
  quotations = {} as IQuota;
  ContFact = {} as IContFact;
  Sales: Array<IVentaQuery> = [];
  DriverObj = {} as Driver;
  billyGraphQL?: IGraphQToBilly;
  unsubscribe$ = new Subject();
  message: string = "Loading...";

  constructor(
    private router: ActivatedRoute,
    private calculateDetailsService: CalculateDetailsService,
  ) {}

  ngOnInit(): void {
    this.setDocument();
  }

  setDocument() {
    this.router.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.id_quotation = params.id_factura;
        const company = params.company;
        const type = params.type;
        this.calculateDetailsService
          .setDocument(this.id_quotation, company, type)
          .then(() => {
            this.message = "Completed!";
          });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
