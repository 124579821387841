import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { QuotationsByUserService } from "./quotations-byuser.service";
import { QuotationsService } from "./quotations.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-quotations",
  templateUrl: "./quotations.component.html",
  styleUrls: ["./quotations.component.css"],
})
export class QuotationsComponent implements OnInit, OnDestroy {
  companyId = '';
  user = '';
  rol = '';
  lengthAll = 0;
  lengthBy1 = 0;
  lengthBy2 = 0;
  lengthBy3 = 0;
  lengthBy4 = 0;
  unsubscribe$ = new Subject();
  constructor(
    private spinnerService: NgxSpinnerService,
    private quotationsByUser: QuotationsByUserService,
    private quotationsService: QuotationsService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit() {
    this.authService.checkAuthStatus();
    this.getAuthValues();
    this.getCounts();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  getCounts() {
    this.spinnerService.show();
    if (this.rol === "1" || this.rol === "3") {
      this.quotationsService
      .countAllQuotations()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthAll = result;
      });
      this.quotationsService
      .countQuotationsByStatus(1)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthBy1 = result;
      });
      this.quotationsService
      .countQuotationsByStatus(2)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthBy2 = result;
      });
      this.quotationsService
      .countQuotationsByStatus(3)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthBy3 = result;
      });
      this.quotationsService
      .countQuotationsByStatus(4)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthBy4 = result;
        this.spinnerService.hide();
      });
    } else {
      this.quotationsByUser
      .countAllQuotationsByUser(parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthAll = result;
      });
      this.quotationsByUser
      .countQuotationsByStatusByUser(1, parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthBy1 = result;
      });
      this.quotationsByUser
      .countQuotationsByStatusByUser(2, parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthBy2 = result;
      });
      this.quotationsByUser
      .countQuotationsByStatusByUser(3, parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthBy3 = result;
      });
      this.quotationsByUser
      .countQuotationsByStatusByUser(4, parseInt(this.user!))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.lengthBy4 = result;
        this.spinnerService.hide();
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
