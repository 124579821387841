import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { IWarehouse } from "src/app/@interfaces/warehouse.interface";
import { InventoryService } from "src/app/@pages/inventory/inventory.service";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import { ITaxes } from "src/app/@interfaces/taxes.interface";
import { ITypes } from "src/app/@interfaces/types.interface";
import { IUnits } from "src/app/@interfaces/units.interface";
import { BillyItems } from "../../../../../@interfaces/Billy/billy.interface";
import { IGrupoArt } from "src/app/@interfaces/grupoart.interface";
import { BillyItemService } from "./billyItems.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { IArticles } from "src/app/@interfaces/article.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { AlertsService } from "../../../services/alerts/alerts.service";

@Component({
  selector: "app-register-article",
  templateUrl: "./register-article.component.html",
  styleUrls: ["./register-article.component.css"],
})
export class RegisterArticleComponent implements OnInit, OnDestroy {
  warehouseList: Array<IWarehouse> = [];
  taxesList: Array<ITaxes> = [];
  typesList: Array<ITypes> = [];
  unitsList: Array<IUnits> = [];
  grupoartList: Array<IGrupoArt> = [];
  articleForm: UntypedFormGroup = this.fb.group({
    code: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
    description: ['', Validators.required],
    presentation: ['', [Validators.required, Validators.pattern(this.validatorS.quantityPattern)]],
    price: ['', [Validators.required, Validators.pattern(this.validatorS.quantityPattern)]],
    quantity: ['', [Validators.required, Validators.pattern(this.validatorS.quantityPattern)]],
    group: ['', Validators.required],
    unit: ['', Validators.required],
    warehouse: ['', Validators.required],
    tax: ['', Validators.required],
    type: ['', Validators.required],
    unitBilly: ['', Validators.required],
    quantityInitial: ['', [Validators.required, Validators.pattern(this.validatorS.quantityPattern)]],
    valueInitial: ['', [Validators.required, Validators.pattern(this.validatorS.quantityPattern)]],
  })
  unsubscribe$ = new Subject();
  companyId = '';
  companyObject = {} as IStorageCompany;
  billyItems = {} as BillyItems;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  alternativeCodes: string[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private warehouseS: WarehouseService,
    private inventoryS: InventoryService,
    private dialog: MatDialog,
    private billyItemsService: BillyItemService,
    private spinnerService: NgxSpinnerService,
    private alertsService: AlertsService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.warehouseS
    .listWarehouses()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.warehouseList = result;
    });

    this.inventoryS
    .listTaxes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.taxesList = result;
    });

    this.inventoryS
    .listTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.typesList = result;
    });

    this.inventoryS
    .listUnits()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.unitsList = result;
    });

    this.inventoryS
    .listGrupoArt()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.grupoartList = result;
    });
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject!.Id_company!.toString();
  }

  register() {
    this.articleForm.markAllAsTouched();
    if (this.articleForm.invalid === true) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return;
    } else {
      if (this.companyObject.external_electronic_invoicing === "true") {
        this.spinnerService.show();
        this.billyItemsService.sendDian(this.articleForm).then((result) => {
          if (result) {
            this.saveOnDataBase();
          }
        });
      } else {
        this.saveOnDataBase();
      }
    }
  }

  saveOnDataBase() {
    this.inventoryS
    .createArticle(
      this.articleForm.value.code,
      this.articleForm.value.description,
      this.articleForm.value.presentation,
      this.articleForm.value.price,
      this.articleForm.value.quantity,
      parseInt(this.articleForm.value.group.id_grupo),
      this.articleForm.value.unit,
      parseInt(this.articleForm.value.warehouse.id_almacen),
      parseInt(this.articleForm.value.tax.id_tax),
      parseInt(this.articleForm.value.type.id_type),
      parseInt(this.articleForm.value.unitBilly.id_unit),
      parseInt(this.articleForm.value.group.presentations[0].id_type),
      this.alternativeCodes.toString(),
      this.articleForm.value.quantityInitial,
      this.articleForm.value.valueInitial
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: IArticles) => {
      if (result.descripcion !== "articleExist") {
        this.spinnerService.hide();
        this.alertsService.showSuccessfullAlert("Article registered successfully")
        .then((result) => {
          if (result.isConfirmed) {
            this.alertsService.refresh("/table", "/inventory")
          }
        });
      } else if (result.descripcion === "articleExist") {
        this.spinnerService.hide();
        this.alertsService.showWarningAlert("This article already exist")
      } else {
        this.spinnerService.hide();
        this.alertsService.showErrorAlert("Something was wrong")
      }
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  textValid(text: string) {
    return (
      this.articleForm.get(text)?.invalid && this.articleForm.get(text)?.touched
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();
    if (value) {
      this.alternativeCodes.push(value);
    }
  }

  remove(alternativeCode: string): void {
    const index = this.alternativeCodes.indexOf(alternativeCode);
    if (index >= 0) {
      this.alternativeCodes.splice(index, 1);
    }
  }
}
