import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/@graphql/services/api.service";
import {
  GET_ALL_PAYMENTS_PURCHASE,
  GET_PURCHASE_PAYMENTS,
} from "../../@graphql/operations/query";
import {
  CREATE_PURCHASES_PAYMENTS,
  REMOVE_PURCHASE_PAYMENTS,
  ASSING_PAYMENTS_PURCHASE,
  UPDATE_PAYMENT_PURCHASES_STATUS,
  SEND_PAYMENT_PURCHASE_MAIL,
  REMOVE_PAYMENTS_PURCHASE,
} from "../../@graphql/operations/mutation";
import { GET_ALL_AP_PURCHASEPAYMENTS, GET_ALL_AP_PURCHASES, GET_ALL_AP_REFUNDS } from "src/app/@graphql/operations/ar&apAccountsQueries";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class AccountsPayableService extends ApiService {
  constructor(apollo: Apollo, private cookieAuthService: CookieAuthService) {
    super(apollo);
  }

  getAllPayments() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_PAYMENTS_PURCHASE, { company }).pipe(
      map((result: any) => {
        return result.getAllPaymentPurchases;
      })
    );
  }

  sendMailPurchases(id_invoice: number, file: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(SEND_PAYMENT_PURCHASE_MAIL, {
      id_invoice,
      file,
      company,
    }).pipe(
      map((result: any) => {
        return result.sendPaymentPurchasesMail;
      })
    );
  }

  createPaymentPurchases(
    id_invoice: number,
    id_payment: number,
    type: string,
    date: string,
    value: number,
    id_ref: string
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_PURCHASES_PAYMENTS, {
      id_invoice,
      id_payment,
      type,
      date,
      value,
      id_ref,
      company,
    }).pipe(
      map((result: any) => {
        return result.invoicePaymentPurchases;
      })
    );
  }

  removePaymentPurchases(id: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_PURCHASE_PAYMENTS, { id, company }).pipe(
      map((result: any) => {
        return result.delPurchasePayments;
      })
    );
  }

  getPaymenPurchase(id_invoice: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_PURCHASE_PAYMENTS, { id_invoice, company }).pipe(
      map((result: any) => {
        return result.getPaymentsPurchases;
      })
    );
  }

  assingPurchasePays(
    id_invoice: number,
    id_payment: number,
    type: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(ASSING_PAYMENTS_PURCHASE, {
      id_invoice,
      id_payment,
      type,
      company,
    }).pipe(
      map((result: any) => {
        return result.assignPaymentPurchase;
      })
    );
  }

  updateStatusPurchasePayment(
    id_paypurchases: number,
    p_vendor: boolean,
    cancelled: boolean
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_PAYMENT_PURCHASES_STATUS, {
      id_paypurchases,
      p_vendor,
      cancelled,
      company,
    }).pipe(
      map((result: any) => {
        return result.updatePaymentPurchasesStatus;
      })
    );
  }

  removePurchasePays(
    id_invoice: number,
    id_payment: number,
    ids_delete: number[]
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_PAYMENTS_PURCHASE, {
      id_invoice,
      id_payment,
      ids_delete,
      company,
    }).pipe(
      map((result: any) => {
        return result.removePaymentsPurchase;
      })
    );
  }

  getAllApPurchases(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_AP_PURCHASES, { year, company }).pipe(
      map((result: any) => {
        return result.getAllApPurchases;
      })
    );
  }

  getAllApRefunds(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_AP_REFUNDS, { year, company }).pipe(
      map((result: any) => {
        return result.getAllApRefunds;
      })
    );
  }

  getAllApPurchasePayments(year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_AP_PURCHASEPAYMENTS, { year, company }).pipe(
      map((result: any) => {
        return result.getAllApPurchasePayments;
      })
    );
  }
}
