<mat-dialog-content>
    <h2><strong>{{'Mails List' | translate}}</strong></h2>
    <form>
        <form [formGroup]="registerForm">
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th>
                            <mat-label>{{'Add Mails' | translate}}</mat-label>
                        </th>
                        <th>
                            <mat-label>{{'Options' | translate}}</mat-label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container formArrayName="itemRows" class="row">
                        <ng-container *ngFor="let itemrow of rowsArr.controls; let i=index" [formGroupName]="i">
                            <tr>
                                <td data-label="Add Mails">
                                    <input class="form-control" type="text" formControlName="mails">
                                    <span class="form-text text-danger" *ngIf="arrayvalid('mails')">{{'Mail invalid' | translate}}</span>
                                </td>
                                <td class="tdAdd">
                                    <button mat-raised-button (click)="update()">{{'Add' | translate}}</button>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table><br>
        </form>
        <table *ngIf="listMails.length > 0" class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>
                        <mat-label>Item</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Mail' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Options' | translate}}</mat-label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let mails of listMails; let i = index">
                    <td data-label="Item" class="tdItems">
                        {{i+1}}
                    </td>
                    <td data-label="Mail" class="tdEmails">
                        {{mails.mail}}
                    </td>
                    <td data-label="Options" class="tdRemove">
                        <button (click)="remove(mails.id_mails, i)" mat-raised-button>
                             x
                        </button>
                    </td>
                </tr>       
            </tbody>
        </table><br>
        <div *ngIf="listMails.length <= 0" class="alert alert-danger">
            {{'NO RECORDS FOUND' | translate}}
        </div><br>
        <button mat-raised-button mat-dialog-close class="buttonClose">{{'Close' | translate}}</button>
        <button mat-raised-button (click)="clickPrintScreen(listMails)" class="buttonSend">{{'Send Report' | translate}}</button>
    </form>
</mat-dialog-content>
<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>

