import { gql } from "apollo-angular";
import { INVENTORY_MOVEMENTS_OBJECT } from "./fragment";

export const GET_ALL_INVENTORY_MOVEMENTS = gql`
query getInventoryMovementsByDate($startDate:String, $endDate:String, $company: String) {
      getInventoryMovementsByDate(startDate: $startDate, endDate: $endDate, company: $company) {
    ...InventoryMovementsObject
  }
}
  ${INVENTORY_MOVEMENTS_OBJECT}
`;