import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreasuryComponent } from './treasury.component';
import { TreasuryRoutingModule } from './treasury-routing.module';
import { TableModule } from 'src/app/@shared/components/table/table.module';
import { SearchModule } from 'src/app/@shared/components/search/search.module';
import { MaterialModule } from 'src/app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';



@NgModule({
  declarations: [
    TreasuryComponent
  ],
  imports: [
    CommonModule,
    TreasuryRoutingModule,
    TableModule,
    SearchModule,
    MaterialModule,
    MatIconModule,
    DialogModule,
    TranslateModule,
    NgxSpinnerModule,
  ],
  exports: [
    TreasuryComponent
  ]
})
export class TreasuryModule { }
