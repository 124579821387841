import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { CompanyService } from '../../@pages/company/company.service';
import { loadCompanies, loadCompaniesFailure } from "../actions/user-companies.actions";

@Injectable()
export class CompaniesEffects {
  constructor(private actions$: Actions, private companyService: CompanyService) {}

  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompanies),
      switchMap((action) =>
        this.companyService.getAllCompaniesByUser(action.user).pipe(
          map((companies) => ({
            type: "[Companies List] Loaded Success",
            companies,
          })),
          catchError((error) => of(loadCompaniesFailure({ error })))
        )
      )
    )
  );
}
