import { Component, Injectable, Input, OnInit } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Label, MultiDataSet, SingleDataSet } from "ng2-charts";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-bar-chart",
  templateUrl: "./bar-chart.component.html",
  styles: [],
})
export class BarChartComponent implements OnInit {
  @Input() horizontal: boolean = false;

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: "end",
        aling: "end",
      },
    },
  };

  @Input() barChartLabels: Label[] = [];
  @Input() barChartData: ChartDataSets[] = [];
  @Input() barChartMulData: MultiDataSet = [];
  @Input() barChartSingleData: SingleDataSet = [];

  public barChartType: ChartType = "bar";
  public barChartLegend = true;

  constructor(public quotation: QuotationsService) {}

  ngOnInit(): void {
    if (this.horizontal) {
      this.barChartType = "horizontalBar";
    }
  }
}
