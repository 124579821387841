<div fxFill fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="cardType == 1">
    <mat-card class="card">
        <div>
            <div style="float: right;">
                <a [routerLink]="['/details_purchase',purchaseInvoice.id_invoice]" target="_blank">
                    <mat-icon class="black-icon">visibility</mat-icon>
                </a>
            </div>
            <div>
                <a style="float: left" (click)="openDialog()">
                    <mat-icon  class="black-icon">mode</mat-icon>
                </a>  
            </div>          
            <div class="div-center">
                <div class="header-image" *ngIf="companyId ==='1'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='2'" mat-card-avatar>
                    <img mat-card-image src="../../../../assets/img/Ibexports.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image" *ngIf="companyId ==='5'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/VersaNoLetras.png);">
                    <img mat-card-image src="../../../../assets/icons/letrasVersa.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='6'" mat-card-avatar>
                    <img mat-card-image src="../../../../assets/img/Parabricentro.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image" *ngIf="companyId ==='7'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='8'" mat-card-avatar>
                    <img mat-card-image src="../../../../assets/img/Parabricentro.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="div-center">
                    <div class="header-image2" *ngIf="companyId ==='4'" mat-card-avatar>
                        <img mat-card-image src="../../../../assets/img/Jens.png" style="margin-top: 6px;"
                            onerror="this.src='assets/img/cantfindimage.jpg'">
                    </div>
                </div>

                <div class="header-image" *ngIf="companyId ==='9'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>
            </div>
        </div>

        <div style="margin-bottom: 20px; margin-top: 25px;">
            <div *ngFor="let contpurchase of purchaseInvoice.contpurchase" class="div-center">
                <mat-card-title>{{contpurchase.written_invoice}}</mat-card-title>
            </div>
            <div class="div-center">
                <mat-card-subtitle *ngIf="purchaseInvoice.state === 1">{{'Purchase' | translate}}</mat-card-subtitle>
                <mat-card-subtitle *ngIf="purchaseInvoice.state !== 1">{{'Voided' | translate}}</mat-card-subtitle>
            </div>
            <div class="div-center">
                <mat-card-subtitle>{{purchaseInvoice.num_invo}}</mat-card-subtitle>
            </div>
        </div>

        <mat-card-content>
            <ul>
                <li *ngFor="let provider of purchaseInvoice.provider">
                    <strong>{{'Provider' | translate}}: </strong>{{provider.nombre}}
                </li>
                <li *ngFor="let purchases of purchaseInvoice.purchases | slice:0:1;">
                    <strong>{{'Total' | translate}}: </strong>{{purchases.total}}
                </li>
                <li>
                    <strong>{{'Date' | translate}}: </strong>{{purchaseInvoice.createdAt | date: 'short'}}
                </li>
                <li>
                    <strong>{{'Due Date' | translate}}: </strong>{{purchaseInvoice.dueDate | date: 'short'}}
                </li>
            </ul>
        </mat-card-content>
        <mat-card-actions>
            <div *ngIf="purchaseInvoice.state === 1" class="div-center">
                <div class="refund-button">
                    <button (click)="refundsDialog(purchaseInvoice)" [matBadgeHidden]="haveRefund" matBadgeColor="warn" matBadge="X" class="refund orange-color" mat-raised-button>{{'Refunds' | translate}}</button>
                </div>
                <div class="refund-button">
                    <button (click)="cancelPurchase(purchaseInvoice.id_invoice!, purchaseInvoice.purchases)" class="refund" mat-raised-button class="orange-color">{{'Void' | translate}}</button>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</div>

<div fxFill fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="cardType == 2">
    <mat-card class="card">
        <div>   
            <div>
                <a style="float: left" (click)="configPurchases(purchaseInvoice.id_invoice!, true, invoicePayments, purchaseInvoice.provider[0].id_provider!)">
                    <mat-icon class="black-icon">settings</mat-icon>
                </a>  
            </div>  
            <div style="float: right;">
                <a [routerLink]="['/details_purchase',purchaseInvoice.id_invoice]" target="_blank">
                    <mat-icon class="black-icon">visibility</mat-icon>
                </a>
            </div> 

            <div class="div-center">
                <div class="header-image" *ngIf="companyId ==='1'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img id="img" mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='2'" mat-card-avatar>
                    <img id="img" mat-card-image src="../../../../assets/img/Ibexports.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image" *ngIf="companyId ==='5'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/VersaNoLetras.png);">
                    <img id="img" mat-card-image src="../../../../assets/icons/letrasVersa.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='6'" mat-card-avatar>
                    <img id="img" mat-card-image src="../../../../assets/img/Parabricentro.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image" *ngIf="companyId ==='7'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img id="img" mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='8'" mat-card-avatar>
                    <img id="img" mat-card-image src="../../../../assets/img/Parabricentro.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="div-center">
                    <div class="header-image2" *ngIf="companyId ==='4'" mat-card-avatar>
                        <img id="img" mat-card-image src="../../../../assets/img/Jens.png" style="margin-top: 6px;"
                            onerror="this.src='assets/img/cantfindimage.jpg'">
                    </div>
                </div>

                <div class="header-image" *ngIf="companyId ==='9'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img id="img" mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>
            </div>
        </div>
    
        <div class="div-stepper">
            <app-stepper [typeStepper]="2" [status]='purchaseInvoice.state_invoice'>
            </app-stepper>
        </div> 
        <div>
            <div *ngFor="let contpurchase of purchaseInvoice.contpurchase" class="div-center">
                <mat-card-title>{{contpurchase.written_invoice}}</mat-card-title>
            </div>
            <div class="div-center">
                <mat-card-subtitle>{{'Purchase' | translate}}</mat-card-subtitle>
            </div>
            <div class="div-center">
                <mat-card-subtitle>{{purchaseInvoice.num_invo}}</mat-card-subtitle>
            </div>
        </div>
        <mat-card-content>
            <ul>
                <li *ngFor="let provider of purchaseInvoice.provider">
                    <strong>{{'Provider' | translate}}: </strong>{{provider.nombre}}
                </li>
                <li *ngFor="let purchases of purchaseInvoice.purchases | slice:0:1;">
                    <strong>{{'Total' | translate}}: </strong>{{purchases.total}}
                </li>
                <li>
                    <strong>{{'Date' | translate}}: </strong>{{purchaseInvoice.createdAt | date: 'short'}}
                </li>
                <li>
                    <strong>{{'Due Date' | translate}}: </strong>{{purchaseInvoice.dueDate | date: 'short'}}
                </li>
            </ul>
        </mat-card-content>
        <mat-card-actions>
            <div class="div-center">
                <div [ngSwitch]="purchaseInvoice.state_invoice">
                    <div class="boton_notas2" *ngSwitchCase=10>
                        <button (click)="generateReport(true)" class="boton2" mat-raised-button class="orange-color">{{'Generate Report' | translate}}</button>
                    </div>
                    <div class="boton_notas2" *ngSwitchCase=30>
                        <button (click)="generateReport(true)" class="boton2" mat-raised-button class="orange-color">{{'Generate Report' | translate}}</button>
                    </div>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</div>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>