<div style="float:left; padding: 25px 0px 0px 10px">
    <a><button mat-raised-button class="Warn" (click)="openDialog()">{{'Register' | translate}}</button></a>
</div>

<div *ngIf="ProviderList.length > 0">
    <app-table [provider]="ProviderList" [showTable]="5" [length]="length"></app-table>
</div>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
