<mat-dialog-content>
  <form [formGroup]="activityForm">
    <div class="row">
      <div class="col-md-4">
        <span> {{ "ICA Code" | translate }}:</span>
        <input
          class="form-control"
          placeholder="{{ 'Code' | translate }}"
          formControlName="ica_code"
        />
        <span class="form-text text-danger" *ngIf="textValid('ica_code')">{{
          "The code must be wrote" | translate
        }}</span>
      </div>
      <div class="col-md-4">
        <span> {{ "Activity Code" | translate }}:</span>
        <input
          class="form-control"
          placeholder="{{ 'Code' | translate }}"
          formControlName="activity_code"
        />
        <span
          class="form-text text-danger"
          *ngIf="textValid('activity_code')"
          >{{ "The code must be wrote" | translate }}</span
        >
      </div>
      <div class="col-md-4">
        <span> {{ "Activity Description" | translate }}:</span>
        <input
          class="form-control"
          placeholder="{{ 'Description' | translate }}"
          formControlName="activity_description"
        />
        <span
          class="form-text text-danger"
          *ngIf="textValid('activity_description')"
          >{{ "The description must be wrote" | translate }}</span
        >
      </div>
      <div class="col-md-4">
        <span> {{ "Actual Rate" | translate }}:</span>
        <input
          class="form-control"
          placeholder="{{ 'Rate' | translate }}"
          formControlName="actual_rate"
        />
        <span class="form-text text-danger" *ngIf="textValid('actual_rate')">{{
          "The rate must be wrote" | translate
        }}</span>
      </div>
      <div class="col-md-4">
        <span>{{ "City" | translate }}:</span>
        <select
          class="form-control"
          formControlName="city"
          [compareWith]="compareCity"
        >
          <option selected value="">{{ "Select city" | translate }}</option>
          <option *ngFor="let city of citiesList" [ngValue]="city">
            {{ city.name }}
          </option>
        </select>
        <span class="form-text text-danger" *ngIf="textValid('city')">{{
          "You must select a city" | translate
        }}</span>
      </div>
    </div>
    <div class="row">
      <div
        class="row"
        style="padding-left: 20px; margin-top: 10px; margin-bottom: 10px"
        id="buttons-div"
      >
        <div class="col" id="buttons">
          <button (click)="update()" mat-raised-button>
            {{ "Register" | translate }}
          </button>
        </div>
        <div class="col" id="buttons">
          <button mat-raised-button [mat-dialog-close]>
            {{ "Cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<ngx-spinner
  type="ball-scale-multiple"
  template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />"
  bdColor="rgba(51,51,51,0.8)"
>
  <p style="font-size: 20px; color: #ffffff">{{ "Loading..." | translate }}</p>
</ngx-spinner>
