<mat-dialog-content>
    <h2><strong>{{'Payments Type List' | translate}}</strong></h2>
    <form>
        <table *ngIf="invoiceObject.payment" class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>
                        <mat-label>Item</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Payment' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Type' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'ID Reference' | translate}}</mat-label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of invoiceObject.payment; let i = index">
                    <td data-label="Item">
                        {{i + 1}}
                    </td>
                    <td data-label="Payment">
                        {{item.description}}
                    </td>
                    <td data-label="Type">
                        {{item.type | translate}}
                    </td>
                    <td data-label="Options">
                        <button mat-flat-button class="my-fab" color="warn" (click)="confirmDelete(item.id_payment, null, true)"><mat-icon class="icon-fab">delete</mat-icon></button>
                    </td>
                </tr>     
            </tbody>
        </table><br>

        <div *ngIf="!(invoiceObject.payment)" class="alert alert-danger">
            {{'NO PAYMENTS HAVE BEEN REGISTERED YET' | translate}}
        </div><br>

        <form [formGroup]="typesForm">
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th>
                            <mat-label>{{'Select type of payment' | translate}}</mat-label>
                        </th>
                        <th>
                            <mat-label>{{'Select a bank or cashier' | translate}}</mat-label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="Select type of payment">
                            <select class="form-control" formControlName="type">
                                <option selected value="">{{'Select type' | translate}}</option>
                                <option *ngFor="let obj of paymentsObj" [ngValue]="obj.value">
                                    {{obj.viewValue | translate}}
                                </option>
                            </select>
                            <span class="form-text text-danger" *ngIf="textValid('type')">{{'You must select a type' | translate}}</span>
                        </td>
                        <td data-label="Select an option">
                            <ng-container *ngIf="this.typesForm.get('type')!.value === 2 ||  this.typesForm.get('type')!.value === 3">
                                <select class="form-control" formControlName="payment">
                                    <option selected value="">{{'Select bank' | translate}}</option>
                                    <option *ngFor="let bank of listBanks" [ngValue]="bank.id_payment">
                                        {{bank.description}}
                                    </option>
                                </select>
                                <span class="form-text text-danger" *ngIf="textValid('payment')">{{'You must select a bank' | translate}}</span>
                            </ng-container>
                            <ng-container *ngIf="this.typesForm.get('type')!.value === 1">
                                <select class="form-control" formControlName="payment">
                                    <option selected value="">{{'Select cashier' | translate}}</option>
                                    <option *ngFor="let cashier of listCashiers" [ngValue]="cashier.id_payment">
                                        {{cashier.description}}
                                    </option>
                                </select>
                                <span class="form-text text-danger" *ngIf="textValid('payment')">{{'You must select a cashier' | translate}}</span>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table><br>
        </form>
            <button mat-raised-button (click)="addPaymentType()" style="margin-left: 20px; margin-bottom: 20px;">{{'Add' | translate}}</button>
            <button mat-raised-button mat-dialog-close [mat-dialog-close]="{id_invoice: id_invoice, sended: sended}" style="margin-left: 20px; margin-bottom: 20px">{{'Close' | translate}}</button>
    </form>

    <h2><strong>{{'Payments Made'  | translate}}</strong></h2>
    <form>
        <div *ngIf="invoicePayments.length > 0">
            <ng-container *ngFor="let list of invoicePayments; let i = index">
                <mat-accordion>
                    <mat-expansion-panel hideToggle>
                      <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxLayoutAlign="start center" >
                            {{list.type | translate}} - ${{list.value}}
                        </mat-panel-title>
                        <mat-panel-description fxLayout="row" fxLayoutAlign="end center">
                            <button mat-flat-button class="my-fab" color="warn" (click)="confirmDelete(list.id_paypurchases, list, false)"><mat-icon class="icon-fab">delete</mat-icon></button>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <div class="row align-items-center">
                        <div class="col">
                            <p><strong>{{'Transaction No' | translate}}: </strong> {{list.id_correlative}} - <strong>{{'ID' | translate}}: </strong> {{list.identifier}}</p>
                            <p><strong>{{'Date' | translate}}: </strong> {{list.date}}</p>
                            <p><strong>{{'ID Reference' | translate}}: </strong> {{list.paymentDetails.id_ref}}</p>
                            <p><strong>{{'Payment Type' | translate}}: </strong> {{list.paymentType[0].type | translate}} - {{list.paymentType[0].description}}</p>
                        </div>
                        <div class="col" *ngIf="rol === '1' || rol === '3'">
                            <app-stepper [status]="list.status" [typeStepper]="3"></app-stepper>
                            <button *ngIf="list.status === 1" mat-button (click)="updateStatusInvoice(list.id_paypurchases, true, false)">Next</button>
                            <button *ngIf="list.status === 2" mat-button (click)="updateStatusInvoice(list.id_paypurchases, false, true)">Next</button>
                        </div>
                      </div>
                    </mat-expansion-panel>
                </mat-accordion><br>
            </ng-container>
        </div>
        <div *ngIf="invoicePayments.length <= 0" class="alert alert-danger">
            {{'NO PAYMENTS HAVE BEEN MADE' | translate}}
        </div><br>
        <div class="row custom-cols">
            <div class="col">
                <h3><strong>{{'Total' | translate}}: </strong>{{totalInvoice | currency: 'USD'}}</h3>
            </div>
            <div class="col">
                <h3><strong>{{'Paid' | translate}}: </strong>{{paidInvoice | currency: 'USD'}}</h3>
            </div>
            <div class="col">
                <h3><strong>{{'Missing' | translate}}: </strong>{{missingPaid | currency: 'USD'}}</h3>
            </div>
        </div><br>
        <form [formGroup]="paymentsForm">
            <mat-accordion multi>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{'Advance Payments' | translate}}
                        </mat-panel-title>
                        <mat-panel-description>
                          {{'By providers and bank or cashier' | translate}}
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <table class="table table-advance-payments">
                        <thead class="thead-dark">
                            <th>
                                <mat-label>{{'Payment Type' | translate}}</mat-label>
                            </th>
                            <th>
                                <mat-label>{{'Provider' | translate}}</mat-label>
                            </th>
                            <th>
                                <mat-label>{{'Total' | translate}}</mat-label>
                            </th>
                            <th>
                                <mat-label>{{'Available' | translate}}</mat-label>
                            </th>
                            <th>
                                <mat-label>{{'ID Reference' | translate}}</mat-label>
                            </th>
                            <th>
                                <mat-label>{{'Use?' | translate}}</mat-label>
                            </th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of advancePayments; let i = index">
                                <td data-label="Payment Type">
                                    {{payment.paymentType!.description}} 
                                </td>
                                <ng-container *ngIf="payment.provider">
                                    <td data-label="Provider">
                                        {{payment.provider.nombre}}
                                    </td>
                                </ng-container>
                                <td data-label="Value">
                                    {{payment.value}}
                                </td>
                                <td data-label="Available">
                                    {{calculateAvaliable(payment.value, payment.value_used)}}
                                </td>
                                <td data-label="Reference">
                                    {{payment.id_ref}}
                                </td>
                                <td data-label="Use?">
                                    <mat-checkbox (change)="setAdvancePayment(payment, i, $event)" [checked]="i === selectedCheckBoxIndex"></mat-checkbox>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                </mat-expansion-panel>
            </mat-accordion>
            <table class="table table-payments table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th>
                            <mat-label>{{'Select type of payment' | translate}}</mat-label>
                        </th>
                        <th>
                            <mat-label>{{'Value' | translate}}</mat-label>
                        </th>
                        <th>
                            <mat-label>{{'ID Reference' | translate}}</mat-label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="Select type of payment">
                            <select class="form-control text-input" formControlName="types">
                                <option selected value="">{{'Select Type' | translate}}</option>
                                <option *ngFor="let types of typesObj" [ngValue]="types.value">
                                    {{types.viewValue | translate}}
                                </option>
                            </select>
                            <span class="form-text text-danger" *ngIf="textValid2('types')">{{'You must select a type' | translate}}</span>
                        </td>
                        <td data-label="Value">
                            <input class="form-control text-input" type="text" formControlName="value">
                            <span class="form-text text-danger" *ngIf="textValid2('value')">{{'Value invalid' | translate}}</span>
                        </td>
                        <td data-label="ID Reference">
                            <input class="form-control text-input" type="text" formControlName="id_ref">
                        </td>
                    </tr>
                </tbody>
                <thead class="thead-dark">
                    <tr>
                        <th>
                            <mat-label>{{'Select a bank or cashier' | translate}}</mat-label>
                        </th>
                        <th colspan="2">
                            <mat-label>{{'Date' | translate}}</mat-label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="invoiceObject.payment">
                        <ng-container *ngIf="selectedCheckBoxIndex === -1">
                            <td data-label="Select a bank or cashier">
                                <select class="form-control text-input" class="form-control" formControlName="paymentType">
                                    <option selected value="">{{'Select Type' | translate}}</option>
                                    <option *ngFor="let types of invoiceObject.payment" [ngValue]="types">
                                        {{types.description | translate}}
                                    </option>
                                </select>
                                <span class="form-text text-danger" *ngIf="textValid2('paymentType')">{{'You must select a type' | translate}}</span>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="selectedCheckBoxIndex !== -1">
                            <td>
                                <strong>{{'DISABLED' | translate}}</strong>
                            </td>
                        </ng-container>
                        <td data-label="Date" colspan="2">
                            <mat-form-field appearance="fill">
                                <mat-label>{{'Date' | translate}}</mat-label>
                                <input class="date-input" matInput [matDatepicker]="picker" formControlName="date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </td>
                    </tr>
                </tbody>
            </table><br>
        </form>
            <button mat-raised-button (click)="createPaymentPurchase()" style="margin-left: 20px; margin-bottom: 20px;">{{'Add' | translate}}</button>
            <button mat-raised-button mat-dialog-close [mat-dialog-close]="{id_invoice: id_invoice, sended: sended, refresh: refresh}" style="margin-left: 20px; margin-bottom: 20px">{{'Close' | translate}}</button>
    </form>

</mat-dialog-content> 
