import { Pipe, PipeTransform } from '@angular/core';
import { IQuotation } from 'src/app/@interfaces/quotation.interface';

@Pipe({
  name: 'filterQuot'
})
export class FilterQuotPipe implements PipeTransform {
  transform(quotations: IQuotation[], searchValue: string): IQuotation[] {
    if (!quotations || !searchValue) {
      return quotations;
    }
    return quotations.filter(quotations =>
      quotations.id_factura?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      quotations.num_fac?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      quotations.createdAt?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      quotations.tax?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      quotations.estado?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      quotations.cliente[0].nombre?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      quotations.venta[0].total?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      quotations.contfac![0].invoice?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) || 
      quotations.contfac![0].delivery?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      quotations.billyInvoice![0].invoice_number?.toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
  }
}
