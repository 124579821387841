import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthStatus } from "src/app/@enums/authentication/auth-status.enum";
import { CookieAuthService } from "../storage-variables/cookie-auth.service";

export const isAuthenticatedGuard: CanActivateFn = (route, state) => {
  const cookieAuthService = inject(CookieAuthService);
  const token = cookieAuthService.getToken;
  const router = inject(Router);
  const urlToGo = state.url;
  let authStatus = token ? AuthStatus.authenticated : AuthStatus.notAuthenticated;
  if (authStatus === AuthStatus.authenticated) {
    return true;
  }
  localStorage.setItem('path', urlToGo);
  router.navigate(["login"]);
  return false;
}