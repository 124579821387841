<meta name="viewport" content=" user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
<div *ngIf="data.register">
    <app-register></app-register>
</div>
<div *ngIf="data.id_factura">
    <app-modify [id_factura]="data.id_factura"></app-modify>
</div>
<div *ngIf="data.company">
    <div *ngIf="data.companies.length > 0">
        <h4>Select company that you wish to see</h4>
        <ng-container *ngFor="let company of data.companies">
            <button mat-button (click)="giveCompany(company)">{{company.name}}</button>
        </ng-container>
    </div>
</div>
<div *ngIf="data.user">
    <app-register-user></app-register-user>
</div>
<div *ngIf="data.modifyUser">
    <app-modify-user [id_user]="data.id_user"></app-modify-user>
</div>
<div *ngIf="data.registerCustomer">
    <app-register-customer></app-register-customer>
</div>
<div *ngIf="data.modifyCustomer">
    <app-modify-customer [id_customer]="data.id_customer"></app-modify-customer>
</div>
<div *ngIf="data.registerSeller">
    <app-register-seller></app-register-seller>
</div>
<div *ngIf="data.modifySeller">
    <app-modify-seller [id_seller]="data.id_seller"></app-modify-seller>
</div>
<div *ngIf="data.registerArticle">
    <app-register-article></app-register-article>
</div>
<div *ngIf="data.modifyArticle">
    <app-modify-article [id_article]="data.id_article"></app-modify-article>
</div>
<div *ngIf="data.deliveryData">
    <app-delivery [dataD]="data.deliveryData"></app-delivery>
</div>
<div *ngIf="data.filtro">
    <app-filter [filtro]="data.newArticle" [currentWarehouse]="data.currentWarehouse"></app-filter>
</div>
<div *ngIf="data.boolC === true">
    <app-credit-note [id_fact]="data.credit"></app-credit-note>
</div>
<div *ngIf="data.boolD === true">
    <app-debit-note [id_fact]="data.debit"></app-debit-note>
</div>
<div *ngIf="data.vercredit">
    <app-ver-credito [id_fact]="data.vercredit"></app-ver-credito>
</div>
<div *ngIf="data.verdebit">
    <app-ver-debito [id_fact]="data.verdebit"></app-ver-debito>
</div>
<div *ngIf="data.addCompanies">
    <app-add-company [id_user]="data.id_user" [valuesForm]="data.valuesForm"></app-add-company>
</div>
<div *ngIf="data.validAdress">
    <app-get-adress></app-get-adress>
</div>
<div *ngIf="data.registerMails">
    <app-crud-mails [listMails]="data.listMails" [groupMails]="data.groupMails"></app-crud-mails>
</div>
<div *ngIf="data.registerBanks">
    <app-crud-banks [listBanks]="data.listBanks" [groupPayment]="data.groupPayment"></app-crud-banks>
</div>
<div *ngIf="data.registerCashiers">
    <app-crud-cashiers [listCashiers]="data.listCashiers" [groupCashier]="data.groupCashier"></app-crud-cashiers>
</div>
<div *ngIf="data.registerGroups">
    <app-crud-groups [listGroups]="data.listGroups"></app-crud-groups>
</div>
<div *ngIf="data.registerPresen">
    <app-crud-presentations [listPresentations]="data.listPresen"></app-crud-presentations>
</div>
<div *ngIf="data.registerWare">
    <app-crud-warehouse [listWare]="data.listWare"></app-crud-warehouse>
</div>
<div *ngIf="data.configInvoice">
    <app-payments [id_factura]="data.identify_invoice" [invoicePayments]="data.invoicePayments" [id_customer]="data.id_customer"></app-payments>
</div>
<div *ngIf="data.configInvoicePurchase">
    <app-pay-purchase [id_invoice]="data.identify_invoice" [id_provider]="data.idProvider" [invoicePayments]="data.invoicePayments"></app-pay-purchase>
</div>
<div *ngIf="data.quotationList">
    <app-export-excel [quotationList]="data.quotationList"></app-export-excel>
</div>
<div *ngIf="data.currency === true">
    <app-select-currency [currency]="data.currency" ></app-select-currency>
</div>
<div *ngIf="data.detailsArticle">
    <app-details-article [article]="data.article"></app-details-article>
</div>
<div *ngIf="data.detailsPayment">
    <app-detail-payment [details]="data.detailsPayment" ></app-detail-payment>
</div>
<div *ngIf="data.registerMailsHome">
    <app-crud-mails-home></app-crud-mails-home>
</div>
<div *ngIf="data.modifyProvider">
    <app-modify-provider [id_provider]="data.id_provider"></app-modify-provider>
</div>
<div *ngIf="data.registerProvider">
    <app-register-provider></app-register-provider>
</div>
<div *ngIf="data.registrationModePurchase">
    <app-registration-mode></app-registration-mode>
</div>
<div *ngIf="data.registerPurchase && !(data.purchaseForm)">
    <app-register-purchase [XMLFIle]="data.XMLfile"></app-register-purchase>
</div>
<div *ngIf="data.registerPurchase && data.purchaseForm">
    <app-register-purchase [XMLFIle]="data.XMLfile" [purchaseForm]="data.purchaseForm" [newProvider]="data.newProvider"
        [totalPurchase]="data.totalPurchase" [totalTax]="data.totalTax" [totalWithoutTax]="data.totalWithoutTax" 
        [withholdingTax]="data.withholdingTax" [uvtValue]="data.uvtValue" [icaTax]="data.icaTax"
        [providerCity]="data.providerCity" [customerCity]="data.customerCity"></app-register-purchase>
</div>
<div *ngIf="data.id_purchaseInvoice">
    <app-modify-purchase [id_invoice]="data.id_purchaseInvoice"></app-modify-purchase>
</div>
<div *ngIf="data.registerActivity">
    <app-register-activities></app-register-activities>
</div>
<div *ngIf="data.modifyActivity">
    <app-modify-activities [id_activity]="data.id_activity"></app-modify-activities>
</div>
<div *ngIf="data.customersList">
    <app-customers-by-seller [customersList]="data.customersList"></app-customers-by-seller>
</div>
<div *ngIf="data.accountingAccounts">
    <app-crud-accounting-accounts></app-crud-accounting-accounts>
</div>
<div *ngIf="data.id_account">
    <app-modify-accounting-accounts [id_account]="data.id_account"></app-modify-accounting-accounts>
</div>
<div *ngIf="data.registerSegments">
    <app-crud-customer-segment [listSegments]="data.listSegments"></app-crud-customer-segment>
</div>
<div *ngIf="data.refunds">
    <app-refunds [purchaseInvoice]="data.purchase"></app-refunds>
</div>
<div *ngIf="data.refundsToFind">
    <app-refunds-list [purchaseInvoice]="data.refundsToFind"></app-refunds-list>
</div>
<div *ngIf="data.registerAdvencePayments">
    <app-register-treasury></app-register-treasury>
</div>
<div *ngIf="data.registerTransfers">
    <app-transfers></app-transfers>
</div>
<div *ngIf="data.registerWarehouseTransfer">
    <app-crud-warehouse-transfer></app-crud-warehouse-transfer>
</div>
<div *ngIf="data.registerWarehouseOutputOrEntry">
    <app-crud-warehouse-output-and-entry [types]="data.types"></app-crud-warehouse-output-and-entry>
</div>
<div *ngIf="data.registerReclassification">
    <app-crud-inventory-reclassification></app-crud-inventory-reclassification>
</div>
<div *ngIf="data.warehouseOpenings">
    <app-warehouse-openings [article]="data.article"></app-warehouse-openings>
</div>
<div *ngIf="data.modifyWarehouseOpenings">
    <app-update-warehouse-openings [opening]="data.opening"></app-update-warehouse-openings>
</div>
<div *ngIf="data.forgotPassword">
    <app-forgot-password [documentTypes]="data.documentTypeList"></app-forgot-password>
</div>
