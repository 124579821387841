import { Component, Input, OnInit } from "@angular/core";

export interface Data {
  item: string;
  avg: number;
  total: number;
}

@Component({
  selector: "app-multiple-pane",
  templateUrl: "./multiple-pane.component.html",
  styleUrls: ["./multiple-pane.component.css"],
})
export class MultiplePaneComponent implements OnInit {
  @Input() data: Data[] = [];

  valueText: string = "";
  @Input() indicator: string = "";
  @Input() nameTop: string = "";
  @Input() nameBot: string = "";

  constructor() {}

  ngOnInit(): void {}

  customizeText() {
    return `${this.valueText}`;
  }

  customizeTooltip(arg: any) {
    return {
      text: `${arg.seriesName}: ${arg.value} ( ${arg.argument} )`,
    };
  }
}
