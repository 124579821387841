import { Pipe, PipeTransform } from '@angular/core';
import { IResultProducts } from 'src/app/@interfaces/csv/csv.interface';

@Pipe({
  name: 'filterCustomerSellerCsv'
})
export class FilterCustomerSellerCsvPipe implements PipeTransform {

  transform(articles: IResultProducts[], seller: string,customer:string): IResultProducts[] {
    return articles.filter(articles =>
        articles.nit!.toString().toLocaleLowerCase() === (customer.toLocaleLowerCase())&& 
        articles.cod_vendedor.toString() === seller)
  }

}