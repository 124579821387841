import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SelectDBRoutingModule } from "./selectDB-routing.module";
import { SelectDBComponent } from "./selectDB.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "src/app/material.module";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [SelectDBComponent],
  imports: [
    CommonModule,
    SelectDBRoutingModule,
    ReactiveFormsModule,
    MatIconModule,
    MaterialModule,
    TranslateModule,
    NgxSpinnerModule,
  ],
  exports: [SelectDBComponent],
})
export class SelectDBModule {}
