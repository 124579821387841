import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ConverseFilesService } from "src/app/@shared/components/services/files/converse-files.service";
import * as XLSX from "xlsx";
import { ReportsService } from "../reports/reports.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { ImportFIlesService } from "./import-files.service";
import { ImportNotesService } from "src/app/@shared/components/services/imports/import-notes.service";
import { ImportPurchasesService } from "src/app/@shared/components/services/imports/import-purchases.service";
import { BillyService } from "../billyService/billy.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { BillyCities } from "src/app/@interfaces/Billy/billy.interface";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { IImportPurchases } from "src/app/@interfaces/Import-files/import-purchases.interface";
import { IImportNotes } from "src/app/@interfaces/Import-files/import-notes.interface";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { ImportProspectsService } from "src/app/@shared/components/services/imports/import-prospects.service";
import { CustomerService } from '../customer/customer.service';
import { UsersService } from "../users/users.service";
import { IDocumenType } from "src/app/@interfaces/authentication/documenType";
import { IContributorType } from "src/app/@interfaces/contributorTypes.interface";
import { ICustomerSegment } from "src/app/@interfaces/customerSegment.interface";
import { IUserSeller } from "src/app/@interfaces/userSeller.interface";
import { IImportInvoices } from "src/app/@interfaces/Import-files/import-invoices.interface";
import { GenerateTxtService } from "src/app/@shared/components/services/files/generate-txt.service";
import { ArrayValidationsService } from "src/app/@shared/components/services/validations/array-validations.service";
import { ConverseDateService } from "src/app/@shared/components/services/data-types/converse-date.service";
import { ConverseArrayService } from "src/app/@shared/components/services/data-types/converse-array.service";

@Component({
  selector: "app-imports-files",
  templateUrl: "./import-files.component.html",
  styleUrls: ["./import-files.component.css"],
})
export class ImportFilesComponent implements OnInit {
  reportList: string[] = [];
  typeList: string[] = [];
  uploadForm: UntypedFormGroup = this.fb.group({
    excel: [],
  });
  showDialog: boolean = false;
  jsonData: { [key: string]: any[][] } = {};
  totalItems = 0;
  currentItem = 0;
  currentReport = "";
  registerLogs: string[] = [];
  registerComplete: boolean = false;
  initImport = false;
  lang = this.translate.currentLang;
  nitWithDigit: boolean = false;
  citiesForm: UntypedFormControl = this.fb.control('', Validators.required);
  typeForm: UntypedFormControl = this.fb.control('', Validators.required);
  cities = {} as BillyCities;
  listDocumenTypes: IDocumenType[] = [];
  listContributorTypes: IContributorType[] = [];
  listCustomerSegments: ICustomerSegment[] = [];
  listSellers: IUserSeller[] = [];
  unsubscribe$ = new Subject();
  companyObject = {} as IStorageCompany;
  invoicesHeader = this.lang === 'es' ?
   ['ID FACTURA', 'IVA INCLUIDO', 'IVA %', 'NIT/NOMBRE CLIENTE', 'NIT VENDEDOR', 'ID ALMACEN', 'FECHA CREACION', 'FECHA VENCIMIENTO', 'CODIGO ARTICULO', 'CANTIDAD', 'PRECIO', 'DESCUENTO', 'SUBTOTAL', 'TOTAL', 'OBSERVACIONES'] :
   ['ID INVOICE', 'TAX INCLUDED', 'TAX %', 'NIT/NAME CUSTOMER', 'NIT SELLER', 'ID WAREHOUSE', 'CREATED DATE', 'DUE DATE', 'ARTICLE CODE', 'QUANTITY', 'PRICE', 'DISCOUNT', 'SUBTOTAL', 'TOTAL', 'OBSERVATIONS']
  collectionsHeader = this.lang === 'en' ?
   ['NUMBER', 'INVOICE', 'YEAR', 'TYPE CASHIER OR BANK', 'ID CASHIER OR BANK', 'PAYMENT TYPE', 'DATE', 'VALUE', 'REFERENCE'] :
   ['NUMERO', 'FACTURA', 'AÑO', 'TIPO CAJA O BANCO', 'ID CAJA O BANCO', 'TIPO DE PAGO', 'FECHA', 'VALOR', 'REFERENCIA']
  notesHeader = this.lang === 'en' ?
   ['NUMBER', 'INVOICE ID', 'INVOICE YEAR', 'NOTE TYPE', 'CONCEPT', 'DATE', 'DUE DATE', 'ID WAREHOUSE', 'ARTICLE CODE', 'QUANTITY', 'PRICE', 'DISCOUNT', 'SUBTOTAL', 'TOTAL'] :
   ['NUMERO', 'ID FACTURA', 'AÑO FACTURA', 'TIPO NOTA', 'CONCEPTO', 'FECHA', 'FECHA VENCIMIENTO', 'ID ALMACEN', 'CODIGO ARTICULO', 'CANTIDAD', 'PRECIO', 'DESCUENTO', 'SUBTOTAL', 'TOTAL']
  purchasesHeader = this.lang === 'en' ?
   ['NUMBER', 'ID PURCHASE', 'PROVIDER NIT', 'DATE', 'DUE DATE', 'TAX %', 'ID WAREHOUSE', 'ARTICLE CODE', 'QUANTITY', 'PRICE', 'DISCOUNT', 'SUBTOTAL', 'WITHHOLDING TAX VALUE', 'ICA TAX VALUE', 'TOTAL'] :
   ['NUMERO', 'ID COMPRA', 'NIT PROVEEDOR', 'FECHA', 'FECHA VENCIMIENTO', 'IVA %', 'ID ALMACEN', 'CODIGO ARTICULO', 'CANTIDAD', 'PRECIO', 'DESCUENTO', 'SUBTOTAL', 'VALOR RETEFUENTE', 'VALOR ICA', 'TOTAL']
  prospectsHeader = this.lang === 'en' ?
   ['NUMBER', 'DOCUMENT TYPE', 'NIT', 'VERIFICATION DIGIT', 'NAME', 'ADDRESS', 'PHONE', 'EMAIL', 'COUNTRY', 'CITY', 'COORDINATES', 'CONTRIBUTOR ID', 'SELLER ID', 'SEGMENT ID', 'OBSERVATIONS'] :
   ['NUMERO', 'TIPO DOCUMENTO', 'NIT', 'DIGITO VERIFICACION', 'NOMBRE', 'DIRECCION', 'TELEFONO', 'CORREO', 'PAIS', 'CIUDAD', 'COORDENADAS', 'ID CONTRIBUYENTE', 'ID VENDEDOR', 'ID SEGMENTO', 'OBSERVACIONES']
  constructor(
    private fb: UntypedFormBuilder,
    private converseFilesService: ConverseFilesService,
    private reportsService: ReportsService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private importFIlesService: ImportFIlesService,
    private generateTxtService: GenerateTxtService,
    private importNotesService: ImportNotesService,
    private importPurchasesService: ImportPurchasesService,
    private billy: BillyService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService,
    private importProspectsService: ImportProspectsService,
    private customerService: CustomerService,
    private userService: UsersService,
    private arrayValidationsService: ArrayValidationsService,
    private converseDateService: ConverseDateService,
    private converseArrayService: ConverseArrayService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.getAuthValues();
    this.nitWithDigit = localStorage.getItem("NitWithDigit") === "true" ? true : false;
    this.setReportList();
  }

  setReportList() {
    const rol = this.cookieAuthService.getRolId!;
    switch (rol) {
      case "1":
        this.reportList = ["Invoices", "Collections", "Notes", "Purchases", "Prospects"];
        this.typeList = ["Orders/Quotations", "Deliveries", "Invoices"];
      break;
      case "2":
        this.reportList = ["Invoices", "Prospects"];
        this.typeList = ["Orders/Quotations", "Deliveries"];
      break;
      case "3":
        this.reportList = ["Invoices", "Collections", "Notes", "Purchases", "Prospects"];
        this.typeList = ["Orders/Quotations", "Deliveries"];
      break;
    }
  }

  citiesData() {
    this.billy
    .getCities(170)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.data.length !== 0) {
        this.cities = result;
      }
    });
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  select(report: string) {
    switch (report) {
      case "Invoices":
      case "Collections":
      case "Notes":
        this.showDialog = true;
        this.currentReport = report;
      break;
      case "Purchases":
        if (this.companyObject.countries[0].name === 'Colombia') {
          this.citiesData();
        }
        this.showDialog = true;
        this.currentReport = report;
      break;
      case "Prospects":
        this.documentTypesData();
        this.contributorsData();
        this.segmentsData();
        this.sellersData();
        this.showDialog = true;
        this.currentReport = report;
      break
      default:
        this.showDialog = false;
        this.currentReport = '';
      break;
    }
  }

  documentTypesData() {
    this.userService.listDocumenTypes().subscribe((result) => {
      this.listDocumenTypes = result;
    })
  }

  contributorsData() {
    this.customerService.listContributorTypes().subscribe((result) => {
      this.listContributorTypes = result;
    })
  }

  segmentsData() {
    this.customerService.listCustomerSegment().subscribe((result) => {
      this.listCustomerSegments = result;
    })
  }

  sellersData() {
    this.userService.getAllSellerUser().subscribe((result) => {
      this.listSellers =  result;
    })
  }

  nitSlideChanged(event: any) {
    localStorage.setItem("NitWithDigit", event.checked);
    this.nitWithDigit = event.checked;
  }

  async getFile(event: any) {
    this.reset(false, false);
    const fileTransformed = await this.converseFilesService
    .handleFile(
      event,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
    .catch((err) => {
      if (err) {
        console.error(err);
        this.uploadForm.get("excel")?.reset();
      }
    });
    this.validIfExcelMatches(fileTransformed!);
  }

  validIfExcelMatches(fileTransformed: any[]) {
    switch (this.currentReport) {
      case "Invoices":
        if (this.arrayValidationsService.validArraysEquals(fileTransformed![0], this.invoicesHeader)) {
          this.formatDatesInvoice(fileTransformed!);
        } else {
          return;
        }
      break;
      case "Collections":
        if (this.arrayValidationsService.validArraysEquals(fileTransformed![0], this.collectionsHeader)) {
          this.formatDatesCollections(fileTransformed!);
        } else {
          return;
        }
      break;
      case "Notes":
        if (this.arrayValidationsService.validArraysEquals(fileTransformed![0], this.notesHeader)) {
          this.formatDatesNotes(fileTransformed!);
        } else {
          return;
        }
      break;
      case "Purchases":
        if (this.arrayValidationsService.validArraysEquals(fileTransformed![0], this.purchasesHeader)) {
          this.formatDatesPurchase(fileTransformed!);
        } else {
          return;
        }
      break;
      case "Prospects":
        if (this.arrayValidationsService.validArraysEquals(fileTransformed![0], this.prospectsHeader)) {
          this.formatProspects(fileTransformed!);
        } else {
          return;
        }
      break;
      default: 
        return;
      break;
    }
  }

  formatDatesCollections(fileTransformed: any[]) {
    fileTransformed.splice(0, 1);
    const jsonData = fileTransformed!.map((row) => {
      const createdDate = row[6];
      const formattedCreatedDate = typeof createdDate === 'number' ? this.converseDateService.getFormattedDate(createdDate) : createdDate;
      row[6] = formattedCreatedDate;
      return row;
    });
    this.filterAndGroupData(jsonData, this.collectionsHeader.length);
  }

  formatDatesInvoice(fileTransformed: any[]) {
    fileTransformed.splice(0, 1);
    const jsonData = fileTransformed!.map((row) => {
      const createdDate = row[6];
      const dueDate = row[7];
      const formattedCreatedDate = typeof createdDate === 'number' ? this.converseDateService.getFormattedDate(createdDate) : createdDate;
      const formattedDueDate = typeof dueDate === 'number' ? this.converseDateService.getFormattedDate(dueDate) : dueDate;
      row[6] = formattedCreatedDate;
      row[7] = formattedDueDate;
      return row;
    });
    this.filterAndGroupData(jsonData, this.invoicesHeader.length);
  }

  formatDatesNotes(fileTransformed: any[]) {
    fileTransformed.splice(0, 1);
    const jsonData = fileTransformed!.map((row) => {
      const createdDate = row[5];
      const dueDate = row[6];
      const formattedCreatedDate = typeof createdDate === 'number' ? this.converseDateService.getFormattedDate(createdDate) : createdDate;
      const formattedDueDate = typeof dueDate === 'number' ? this.converseDateService.getFormattedDate(dueDate) : dueDate;
      row[5] = formattedCreatedDate;
      row[6] = formattedDueDate;
      return row;
    });
    this.filterAndGroupData(jsonData, this.notesHeader.length);
  }

  formatDatesPurchase(fileTransformed: any[]) {
    fileTransformed.splice(0, 1);
    const jsonData = fileTransformed!.map((row) => {
      const createdDate = row[3];
      const dueDate = row[4];
      const formattedCreatedDate = typeof createdDate === 'number' ? this.converseDateService.getFormattedDate(createdDate) : createdDate;
      const formattedDueDate = typeof dueDate === 'number' ? this.converseDateService.getFormattedDate(dueDate) : dueDate;
      row[3] = formattedCreatedDate;
      row[4] = formattedDueDate;
      return row;
    });
    this.filterAndGroupData(jsonData, this.purchasesHeader.length);
  }

  formatProspects(fileTransformed: any[]) {
    fileTransformed.splice(0, 1);
    this.filterAndGroupData(fileTransformed, this.prospectsHeader.length);
  }

  filterAndGroupData(jsonData: any[], filterLenght: number) {
    const filterJsonData = jsonData.filter(
      (array) => array.length === filterLenght
    );
    const sortedArray = this.converseArrayService.orderArrayByType(filterJsonData, "Abono");
    const orderIds = sortedArray.map(subarray => subarray[0]).sort((a, b) => a - b);
    const reorganizedArray: any[][] = sortedArray.map((subarray, index) => [orderIds[index], ...subarray.slice(1)]);
    const groupArray = this.converseArrayService.groupArraysByProperty(
      this.currentReport === "Collections" ? reorganizedArray : filterJsonData,
      0
    );
    const quantityItems = Object.keys(groupArray).length;
    this.totalItems = quantityItems;
    this.jsonData = groupArray;
  }

  validatePreviusActions() {
    const isFirstCountryColombia = this.companyObject.countries[0].name === 'Colombia';
    const isInvalidCityForm = this.citiesForm.invalid;
    const isCurrentReportPurchase = this.currentReport === 'Purchases';
    if (isFirstCountryColombia) {
      if (isCurrentReportPurchase && isInvalidCityForm) {
        return false;
      }else {
        return true;
      }
    }else {
      return true;
    }
  }

  whichArrayToConstruct(arrayFromExcel: { [key: string]: any[][] }) {
    const validations = this.validatePreviusActions();
    if(!validations) {
      return;
    }else {
      switch(this.currentReport) {
        case "Invoices":
          this.constructInvoiceArray(arrayFromExcel);
        break;
        case "Collections":
          this.constructCollectionsArray(arrayFromExcel);
        break;
        case "Notes":
          this.constructNotesArray(arrayFromExcel);
        break;
        case "Purchases":
          this.constructPurchasesArray(arrayFromExcel);
        break;
        case "Prospects":
          this.constructProspectsArray(arrayFromExcel);
        break;
      }
    }
  }

  async constructCollectionsArray(array: { [key: string]: any[][] }) {
    this.registerLogs = [];
    for (let item in array) {
      let itemID = 0, invoiceID = "", paymentTypeID = 0, paymentMethod = 0, year = 0;
      let paymentType = "", createdDate = "", valuePaymet = 0, reference = "";
      const value = array[item];
      for (let coll of value) {
        itemID = coll[0];
        invoiceID = coll[1];
        year = coll[2];
        paymentMethod = coll[3];
        paymentTypeID = coll[4];
        paymentType = this.translate.instant(coll[5].trim());
        createdDate = new Date(coll[6]).toDateString();
        valuePaymet = coll[7];
        reference = coll[8];
      }
      const log = await this.importFIlesService.createPayment(
        itemID,
        invoiceID,
        paymentTypeID,
        paymentMethod,
        paymentType,
        createdDate,
        year,
        valuePaymet,
        reference
      );
      this.registerLogs.push(log);
      this.currentItem = parseInt(item);
    }
    this.registerComplete = this.currentItem === this.totalItems ? true : false;
  }

  async constructInvoiceArray(invoicesArray: { [key: string]: any[][] }) {
    this.registerLogs = [];
    let currentIndex = 0;
    const type = this.typeForm.value;
    for (let item in invoicesArray) {
      const invoiceObject = await this.getValuesFromInvoicesArray(invoicesArray, item, type);
      const log = await this.importFIlesService.createInvoice(
        invoiceObject
      );
      this.registerLogs.push(log);
      currentIndex += 1;
      this.currentItem = parseInt(currentIndex.toString());
    }
    this.registerComplete = this.currentItem === this.totalItems ? true : false;
  }

  async getValuesFromInvoicesArray(
    invoicesArray: { [key: string]: any[][] }, item: string, type: string
  ) {
    let invoiceObject = {} as IImportInvoices;
    invoiceObject.warehouseID = [];
    invoiceObject.articleCode = [];
    invoiceObject.quantity = [];
    invoiceObject.price = [];
    invoiceObject.discount = [];
    invoiceObject.subtotal = [];
    invoiceObject.total = [];
    const value = invoicesArray[item];
    for (let inv of value) {
      invoiceObject.invoiceID = inv[0];
      invoiceObject.taxIncluded = inv[1] === 1 ? "true" : "false";
      invoiceObject.taxPercent = inv[2];
      invoiceObject.idCustomer = await this.importFIlesService.getIdCustomer(
        this.nitWithDigit ? inv[3].toString().slice(0, -1) : inv[3].toString()
      );
      invoiceObject.idSeller = await this.importFIlesService.getIdSeller(
        this.nitWithDigit ? inv[4].toString().slice(0, -1) : inv[4].toString()
      );
      invoiceObject.createdDate = new Date(inv[6]).toISOString();
      invoiceObject.year = new Date(inv[6]).getFullYear();
      invoiceObject.dueDate = new Date(inv[7]).toISOString();
      invoiceObject.warehouseID.push(parseInt(inv[5].toString().trim()));
      invoiceObject.articleCode.push(inv[8].toString().trim());
      invoiceObject.quantity.push(parseFloat(inv[9].toString().trim()));
      invoiceObject.price.push(parseFloat(inv[10].toString().trim()));
      invoiceObject.discount.push(parseFloat(inv[11].toString().trim()));
      invoiceObject.subtotal.push(parseFloat(inv[12].toString().trim()));
      invoiceObject.total.push(parseFloat(inv[13].toString().trim()));
      invoiceObject.observations = inv[14].toString().trim();
      invoiceObject.type = type;
    }
    return invoiceObject;
  }

  async constructPurchasesArray(purchasesArray: { [key: string]: any[][] }) {
    this.registerLogs = [];
    let currentIndex = 0;
    for (let element in purchasesArray) {
      const purchaseObject = await this.getValuesFromPurchasesArray(purchasesArray, element);
      const log = await this.importPurchasesService.createPurchase(
        purchaseObject, this.citiesForm.value
      );
      this.registerLogs.push(log);
      currentIndex += 1;
      this.currentItem = parseInt(currentIndex.toString());
    }
    this.registerComplete = this.currentItem === this.totalItems ? true : false;
  }

  async getValuesFromPurchasesArray(purchasesArray: { [key: string]: any[][] }, element: string) {
    let purchaseObject = {} as IImportPurchases;
    purchaseObject.warehouseID = [];
    purchaseObject.articleCode = [];
    purchaseObject.quantity = [];
    purchaseObject.price = [];
    purchaseObject.discount = [];
    purchaseObject.subtotal = [];
    purchaseObject.total = [];
    const value = purchasesArray[element];
    for (let item of value) {
      purchaseObject.number = item[0];
      purchaseObject.purchaseID = item[1].toString();
      purchaseObject.nitProvider = this.nitWithDigit ? item[2].toString().slice(0, -1) : item[2].toString();
      purchaseObject.provider = await this.importPurchasesService.getProvider(purchaseObject.nitProvider);
      purchaseObject.date = new Date(item[3]).toISOString();
      purchaseObject.dueDate = new Date(item[4]).toISOString();    
      purchaseObject.taxPercent = item[5];
      purchaseObject.warehouseID.push(parseInt(item[6].toString().trim()));
      purchaseObject.articleCode.push(item[7].toString().trim());
      purchaseObject.quantity.push(parseFloat(item[8].toString().trim()));
      purchaseObject.price.push(parseFloat(item[9].toString().trim()));
      purchaseObject.discount.push(parseFloat(item[10].toString().trim()));
      purchaseObject.subtotal.push(parseFloat(item[11].toString().trim()));
      purchaseObject.withholding = parseFloat(item[12].toString().trim());
      purchaseObject.ica = parseFloat(item[13].toString().trim());
      purchaseObject.total.push(parseFloat(item[14].toString().trim()));
    }
    return purchaseObject;
  }

  async constructNotesArray(notesArray: { [key: string]: any[][] }) {
    this.registerLogs = [];
    let currentIndex = 0;
    for (let element in notesArray) {
      const noteObject = this.getValuesFromNotesArray(notesArray, element);
      const log = await this.importNotesService.createNote(noteObject);
      this.registerLogs.push(log);
      currentIndex += 1;
      this.currentItem = parseInt(currentIndex.toString());
    }
    this.registerComplete = this.currentItem === this.totalItems ? true : false;
  }

  getValuesFromNotesArray(notesArray: { [key: string]: any[][] }, element: string) {
    let noteObject = {} as IImportNotes;
    noteObject.warehouseID = [];
    noteObject.articleCode = [];
    noteObject.quantity = [];
    noteObject.price = [];
    noteObject.discount = [];
    noteObject.subtotal = [];
    noteObject.total = [];
    const value = notesArray[element];
    for (let item of value) {
      noteObject.noteID = item[0];
      noteObject.invoiceID = item[1];
      noteObject.invoiceYear = item[2];
      noteObject.noteType = item[3].toString().trim();
      noteObject.concept = item[4].toString().trim();
      noteObject.createdDate = new Date(item[5]).toISOString();
      noteObject.dueDate = new Date(item[6]).toISOString();
      noteObject.warehouseID.push(parseInt(item[7].toString().trim()));
      noteObject.articleCode.push(item[8].toString().trim());
      noteObject.quantity.push(this.importNotesService.converseValuesByType(item[9], noteObject.noteType));
      noteObject.price.push(this.importNotesService.converseValuesByType(item[10], noteObject.noteType));
      noteObject.discount.push(parseFloat(item[11].toString().trim()));
      noteObject.subtotal.push(this.importNotesService.converseValuesByType(item[12], noteObject.noteType));
      noteObject.total.push(this.importNotesService.converseValuesByType(item[13], noteObject.noteType));
    }
    return noteObject;
  }

  async constructProspectsArray(prospectsArray: { [key: string]: any[][] }) {
    this.registerLogs = [];
    let currentIndex = 0;
    for (let element in prospectsArray) {
      const prospectObject = await this.importProspectsService.getValuesFromProspectsArray(prospectsArray, element, this.listSellers);
      const log = await this.importProspectsService.createProspect(prospectObject);
      this.registerLogs.push(log);
      currentIndex += 1;
      this.currentItem = parseInt(currentIndex.toString());
    }
    this.registerComplete = this.currentItem === this.totalItems ? true : false;
  }

  exportExample() {
    let table: HTMLElement;
    let firstCell = '', secondCell = '';
    switch (this.currentReport) {
      case "Invoices":
        table = document.getElementById("example-import-invoices")!;
        firstCell = 'G';
        secondCell = 'H'
      break;
      case "Collections":
        table = document.getElementById("example-import-collections")!;
        firstCell = 'G'
      break;
      case "Notes":
        table = document.getElementById("example-import-notes")!;
        firstCell = 'F'
        secondCell = 'G'
      break;
      case "Purchases":
        table = document.getElementById("example-import-purchases")!;
        firstCell = 'D'
        secondCell = 'E'
      break;
      case "Prospects":
        table = document.getElementById("example-import-prospects")!;
      break;
    }
    this.constructSheets(table!, firstCell, secondCell);
  }

  exportList(listName: string) {
    let table: HTMLElement;
    switch (listName) {
      case "Document Types":
        table = document.getElementById("document-type-list")!;
      break;
      case "Contributors":
        table = document.getElementById("contributors-list")!;
      break;
      case "Segments":
        table = document.getElementById("segments-list")!;
      break;
      case "Sellers":
        table = document.getElementById("sellers-list")!;
      break;
    }
    this.constructSheets(table!, '', '', listName + ' List.xlsx' );
  }

  constructSheets(table: HTMLElement, firstCell: string, secondCell: string, fileName?: string) {
    this.spinner.show();
    const cellNumbers = [2,3,4,5];
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    let mainSheet = XLSX.utils.table_to_sheet(table!);
    mainSheet = this.formatSheetsDate(mainSheet, cellNumbers, firstCell, secondCell, "10/15/2023");
    this.reportsService.export(
      workBook,
      mainSheet,
      fileName ? fileName : "Example " + this.currentReport + ".xlsx"
    );
  }

  formatSheetsDate(
    mainSheet: XLSX.WorkSheet, cellNumbers: number[], 
    firstCell: string, secondCell: string, date: string
  ) {
    cellNumbers.forEach(element => {
      if (firstCell) {
        mainSheet[firstCell + element.toString()].z = "";
        mainSheet[firstCell + element.toString()].v = date;
        mainSheet[firstCell + element.toString()].t = "s";
      }
      if (secondCell) {
        mainSheet[secondCell + element.toString()].z = "";
        mainSheet[secondCell + element.toString()].v = date;
        mainSheet[secondCell + element.toString()].t = "s";
      }
    });
    return mainSheet;
  }

  generateTxt() {
    this.generateTxtService.generateTxtGuide();
  }

  reset(resetForm: boolean, back: boolean) {
    this.jsonData = {};
    this.registerLogs = [];
    this.totalItems = 0;
    this.currentItem = 0;
    this.initImport = false;
    this.registerComplete = false;
    if (resetForm) {
      this.uploadForm.get("excel")?.reset();
      this.citiesForm.setValue('');
    }
    if (back) {
      this.showDialog = !this.showDialog;
    }
  }
}
