import { Component, Input, ViewChild } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import { DxListComponent } from "devextreme-angular";
import { EventEmitterService } from "src/app/@core/menu/emitterFuncions.service";

export interface ListData {
  id: number;
  text: string;
}

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.css"],
})
export class ListComponent {
  @Input() data?: DataSource;
  @Input() exportData: boolean = false;
  @Input() transfer: boolean = false;
  @Input() close: boolean = false;
  @Input() type: string = "";
  selectAllModeVlaue = "page";
  @Input() selectionModeValue = "all";
  @ViewChild("list", { static: false }) list?: DxListComponent;

  constructor(private eventEmitterService: EventEmitterService) {}

  selectedItems() {
    this.eventEmitterService.onSelectEachItem(this.list!.selectedItemKeys, this.type);
  }

  export() {
    this.eventEmitterService.onGetItemsFromList();
  }

  register() {
    this.eventEmitterService.onRegisterFromList();
  }
}
