<div class="row">
    <div class="col-md-6">
        <div class="alert alert-info">
            {{'Quotations per seller (This Month)' | translate}}
        </div>
        <div style="display: block; max-width: 80%; max-height: 80%;" #divGrafica>
            <app-stacked-bar [data]="data"></app-stacked-bar>
        </div>
    </div>
</div>


