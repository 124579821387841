import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../../@shared/authentication/auth.service";
import { EventEmitterService } from "src/app/@core/menu/emitterFuncions.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;
  constructor(
    private eventEmitterService: EventEmitterService,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.toggleExpandCard();
    this.getEvent();
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((name: string) => {
        this.toggleExpandCard();
      });
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 250);
  }
}
