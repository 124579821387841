import { Component, OnInit } from "@angular/core";
import { Marker } from "src/app/@interfaces/marker.interface";
import { MatDialog } from "@angular/material/dialog";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-get-adress",
  templateUrl: "./get-adress.component.html",
  styleUrls: ["./get-adress.component.css"],
})
export class GetAdressComponent implements OnInit {
  adress = "";
  markerUrl =
    "https://js.devexpress.com/Demos/RealtorApp/images/map-marker.png";
  markers: Marker[] = [];

  longitude = localStorage.getItem("centerLongitude");
  latitude = localStorage.getItem("centerLatitude");

  lat = 0;
  lng = 0;

  coords: any;
  keys = {};

  companyId = '';

  constructor(public dialog: MatDialog, private translate: TranslateService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  getCoords(current: boolean, custom: boolean) {
    if (current === true) {
      this.longitude = localStorage.getItem("centerLongitude");
      this.latitude = localStorage.getItem("centerLatitude");
      let cord = {
        adress: this.adress,
        coordenada: this.latitude! + "," + " " + this.longitude!,
      };
      this.coords = cord;
      Swal.fire(
        this.translate.instant("success"),
        this.translate.instant("You can close this window or look for another address."),
        "success"
      );
    } else if (custom === true) {
      let cord = {
        adress: this.adress,
        coordenada: this.adress,
      };
      this.coords = cord;
      Swal.fire(
        this.translate.instant("success"),
        this.translate.instant("You can close this window or look for another address."),
        "success"
      );
    }
  }

  search() {
    this.markers = [
      {
        location: this.adress,
        tooltip: {
          isShown: true,
          text: `${this.adress}`,
        },
      },
    ];
  }
}
