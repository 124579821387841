import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { ChartDataSets } from "chart.js";
import { Color, Label } from "ng2-charts";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ProcessNotesService } from "../../../../@shared/components/services/charts/process-notes.service";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { HomeService } from "../../home.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-sales-to-date",
  templateUrl: "./sales-to-date.component.html",
  styleUrls: ["./sales-to-date.component.css"],
})
export class SalesToDateComponent implements OnChanges, OnDestroy {
  @Input() customerQuots: Array<IQuota> = [];
  @Input() notas: Array<INotasGrap> = [];
  @Input() yearTarjet: number = 0;
  @Input() id_type: number | null = null;
  @Input() id_seller: number | null = null;

  currentYear = new Date().getFullYear();
  lastYear = this.currentYear - 1;
  twoDigits = this.getLastTwoDigits(this.currentYear.toString());

  tarjet = 0;
  typeId: number | null = null;
  sellerId: number | null = null;

  allQuots: Array<IQuota> = [];
  allNotas: Array<INotasGrap> = [];
  unsubscribe$ = new Subject();

  lineChartData: ChartDataSets[] = [
    {
      label: "Sales to date",
      borderColor: "#0086C0",
      pointStyle: "rectRounded",
      pointRadius: 7,
      radius: 7,
    },
    {
      label: "Goal",
      borderColor: "#0FBB48",
      pointStyle: "triangle",
      pointRadius: 7,
      radius: 7,
    },
    {
      label: "Previous year",
      borderColor: "#C0B400",
      pointStyle: "rectRot",
      pointRadius: 7,
      radius: 7,
    },
  ];
  companyObject = {} as IStorageCompany;

  lineChartLabels: Label[] = [
    "January " + this.twoDigits,
    "February " + this.twoDigits,
    "March " + this.twoDigits,
    "April " + this.twoDigits,
    "May " + this.twoDigits,
    "June " + this.twoDigits,
    "July " + this.twoDigits,
    "August " + this.twoDigits,
    "September " + this.twoDigits,
    "October " + this.twoDigits,
    "November " + this.twoDigits,
    "December " + this.twoDigits,
  ];

  lineChartColors: Color[] = [
    {
      borderColor: "#FF0000",
    },
    {
      borderColor: "#0FBB48",
    },
    {
      borderColor: "#C0B400",
    },
  ];

  constructor(
    public homeService: HomeService,
    private translate: TranslateService,
    private processNotesService: ProcessNotesService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "customerQuots") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.allQuots = values;
        }
      }
      if (item === "notas") {
        const values: Array<INotasGrap> = changes[item].currentValue;
        if (values.length > 0) {
          this.allNotas = values;
        }
      }
      if (item === "id_type") {
        const values: number = changes[item].currentValue;
        this.typeId = values;
      }
      if (item === "id_seller") {
        const values: number = changes[item].currentValue;
        this.sellerId = values;
      }
      if(item === "yearTarjet") {
        const values: number = changes[item].currentValue;
        this.tarjet = values;
      }
      if (this.allQuots.length > 0 || (this.allNotas.length > 0)) {
        this.calcSalesToDate(this.allQuots, this.allNotas, this.tarjet);
      }
    }
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  save(invoices: IQuota[],  notas: INotasGrap[]) {
    const validation = this.homeService.goalError(this.sellerId!, this.typeId!);
    if (validation) {
      this.homeService
      .updateGoals(
        parseFloat(this.tarjet.toString()),
        this.sellerId,
        "YEAR",
        this.typeId!,
        new Date().toDateString()
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        if (result != null) {
          Swal.fire(
            this.translate.instant("success"),
            this.translate.instant("Done!, saved goal."),
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              this.calcSalesToDate(invoices, notas, this.tarjet);
            }
          });
        }
      });
    }else {
      return;
    }
  }

  calcSalesToDate(invoices: IQuota[], notas: INotasGrap[], tarjet: number) {
    tarjet = tarjet === null ? 0 : tarjet;
    let filterInvoices: IQuota[] = [];
    if (this.companyObject.external_electronic_invoicing === "true" || this.companyObject.internal_electronic_invoicing === "true") {
      filterInvoices = invoices.filter((data) => data.estado === 4 && data.billyInvoice![0].invoice_number !== "PENDING");
    } else {
      filterInvoices = invoices.filter((data) => data.estado === 4);
    }
    const currentJanuary = this.getByMonthCurrentYear(filterInvoices, 1, notas);
    const currentFebruary = this.getByMonthCurrentYear(filterInvoices, 2, notas);
    const currentMarch = this.getByMonthCurrentYear(filterInvoices, 3, notas);
    const currentApril = this.getByMonthCurrentYear(filterInvoices, 4, notas);
    const currentMay = this.getByMonthCurrentYear(filterInvoices, 5, notas);
    const currentJune = this.getByMonthCurrentYear(filterInvoices, 6, notas);
    const currentJuly = this.getByMonthCurrentYear(filterInvoices, 7, notas);
    const currentAugust = this.getByMonthCurrentYear(filterInvoices, 8, notas);
    const currentSeptember = this.getByMonthCurrentYear(filterInvoices, 9, notas);
    const currentOctober = this.getByMonthCurrentYear(filterInvoices, 10, notas);
    const currentNovember = this.getByMonthCurrentYear(filterInvoices, 11, notas);
    const currentDecember = this.getByMonthCurrentYear(filterInvoices, 12, notas);

    const pastJanuary = this.getByMonthPastYear(filterInvoices, 1, notas);
    const pastFebruary = this.getByMonthPastYear(filterInvoices, 2, notas);
    const pastMarch = this.getByMonthPastYear(filterInvoices, 3, notas);
    const pastApril = this.getByMonthPastYear(filterInvoices, 4, notas);
    const pastMay = this.getByMonthPastYear(filterInvoices, 5, notas);
    const pastJune = this.getByMonthPastYear(filterInvoices, 6, notas);
    const pastJuly = this.getByMonthPastYear(filterInvoices, 7, notas);
    const pastAugust = this.getByMonthPastYear(filterInvoices, 8, notas);
    const pastSeptember = this.getByMonthPastYear(filterInvoices, 9, notas);
    const pastOctober = this.getByMonthPastYear(filterInvoices, 10, notas);
    const pastNovember = this.getByMonthPastYear(filterInvoices, 11, notas);
    const pastDecember = this.getByMonthPastYear(filterInvoices, 12, notas);

    const targetJanuary = this.getTargetByMonth(filterInvoices, 1, notas, tarjet);
    const targetFebruary = this.getTargetByMonth(filterInvoices, 2, notas, tarjet);
    const targetMarch = this.getTargetByMonth(filterInvoices, 3, notas, tarjet);
    const targetApril = this.getTargetByMonth(filterInvoices, 4, notas, tarjet);
    const targetMay = this.getTargetByMonth(filterInvoices, 5, notas, tarjet);
    const targetJune = this.getTargetByMonth(filterInvoices, 6, notas, tarjet);
    const targetJuly = this.getTargetByMonth(filterInvoices, 7, notas, tarjet);
    const targetAugust = this.getTargetByMonth(filterInvoices, 8, notas, tarjet);
    const targetSeptember = this.getTargetByMonth(filterInvoices, 9, notas, tarjet);
    const targetOctober = this.getTargetByMonth(filterInvoices, 10, notas, tarjet);
    const targetNovember = this.getTargetByMonth(filterInvoices, 11, notas, tarjet);
    const targetDecember = this.getTargetByMonth(filterInvoices, 12, notas, tarjet);

    this.lineChartData[0].data = [
      parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentApril.toFixed(2)) +
        parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentMay.toFixed(2)) +
        parseFloat(currentApril.toFixed(2)) +
        parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentJune.toFixed(2)) +
        parseFloat(currentMay.toFixed(2)) +
        parseFloat(currentApril.toFixed(2)) +
        parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentJuly.toFixed(2)) +
        parseFloat(currentJune.toFixed(2)) +
        parseFloat(currentMay.toFixed(2)) +
        parseFloat(currentApril.toFixed(2)) +
        parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentAugust.toFixed(2)) +
        parseFloat(currentJuly.toFixed(2)) +
        parseFloat(currentJune.toFixed(2)) +
        parseFloat(currentMay.toFixed(2)) +
        parseFloat(currentApril.toFixed(2)) +
        parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentSeptember.toFixed(2)) +
        parseFloat(currentAugust.toFixed(2)) +
        parseFloat(currentJuly.toFixed(2)) +
        parseFloat(currentJune.toFixed(2)) +
        parseFloat(currentMay.toFixed(2)) +
        parseFloat(currentApril.toFixed(2)) +
        parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentOctober.toFixed(2)) +
        parseFloat(currentSeptember.toFixed(2)) +
        parseFloat(currentAugust.toFixed(2)) +
        parseFloat(currentJuly.toFixed(2)) +
        parseFloat(currentJune.toFixed(2)) +
        parseFloat(currentMay.toFixed(2)) +
        parseFloat(currentApril.toFixed(2)) +
        parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentNovember.toFixed(2)) +
        parseFloat(currentOctober.toFixed(2)) +
        parseFloat(currentSeptember.toFixed(2)) +
        parseFloat(currentAugust.toFixed(2)) +
        parseFloat(currentJuly.toFixed(2)) +
        parseFloat(currentJune.toFixed(2)) +
        parseFloat(currentMay.toFixed(2)) +
        parseFloat(currentApril.toFixed(2)) +
        parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
      parseFloat(currentDecember.toFixed(2)) +
        parseFloat(currentNovember.toFixed(2)) +
        parseFloat(currentOctober.toFixed(2)) +
        parseFloat(currentSeptember.toFixed(2)) +
        parseFloat(currentAugust.toFixed(2)) +
        parseFloat(currentJuly.toFixed(2)) +
        parseFloat(currentJune.toFixed(2)) +
        parseFloat(currentMay.toFixed(2)) +
        parseFloat(currentApril.toFixed(2)) +
        parseFloat(currentMarch.toFixed(2)) +
        parseFloat(currentFebruary.toFixed(2)) +
        parseFloat(currentJanuary.toFixed(2)),
    ];

    this.lineChartData[1].data = [
      parseFloat(targetJanuary.toFixed(2)),
      parseFloat(targetFebruary.toFixed(2)),
      parseFloat(targetMarch.toFixed(2)),
      parseFloat(targetApril.toFixed(2)),
      parseFloat(targetMay.toFixed(2)),
      parseFloat(targetJune.toFixed(2)),
      parseFloat(targetJuly.toFixed(2)),
      parseFloat(targetAugust.toFixed(2)),
      parseFloat(targetSeptember.toFixed(2)),
      parseFloat(targetOctober.toFixed(2)),
      parseFloat(targetNovember.toFixed(2)),
      parseFloat(targetDecember.toFixed(2)),
    ];

    this.lineChartData[2].data = [
      parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastFebruary.toFixed(2)) + 
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastApril.toFixed(2)) +
        parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastMay.toFixed(2)) +
        parseFloat(pastApril.toFixed(2)) +
        parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastJune.toFixed(2)) +
        parseFloat(pastMay.toFixed(2)) +
        parseFloat(pastApril.toFixed(2)) +
        parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastJuly.toFixed(2)) +
        parseFloat(pastJune.toFixed(2)) +
        parseFloat(pastMay.toFixed(2)) +
        parseFloat(pastApril.toFixed(2)) +
        parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastAugust.toFixed(2)) +
        parseFloat(pastJuly.toFixed(2)) +
        parseFloat(pastJune.toFixed(2)) +
        parseFloat(pastMay.toFixed(2)) +
        parseFloat(pastApril.toFixed(2)) +
        parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastSeptember.toFixed(2)) +
        parseFloat(pastAugust.toFixed(2)) +
        parseFloat(pastJuly.toFixed(2)) +
        parseFloat(pastJune.toFixed(2)) +
        parseFloat(pastMay.toFixed(2)) +
        parseFloat(pastApril.toFixed(2)) +
        parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastOctober.toFixed(2)) +
        parseFloat(pastSeptember.toFixed(2)) +
        parseFloat(pastAugust.toFixed(2)) +
        parseFloat(pastJuly.toFixed(2)) +
        parseFloat(pastJune.toFixed(2)) +
        parseFloat(pastMay.toFixed(2)) +
        parseFloat(pastApril.toFixed(2)) +
        parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastNovember.toFixed(2)) +
        parseFloat(pastOctober.toFixed(2)) +
        parseFloat(pastSeptember.toFixed(2)) +
        parseFloat(pastAugust.toFixed(2)) +
        parseFloat(pastJuly.toFixed(2)) +
        parseFloat(pastJune.toFixed(2)) +
        parseFloat(pastMay.toFixed(2)) +
        parseFloat(pastApril.toFixed(2)) +
        parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
      parseFloat(pastDecember.toFixed(2)) +
        parseFloat(pastNovember.toFixed(2)) +
        parseFloat(pastOctober.toFixed(2)) +
        parseFloat(pastSeptember.toFixed(2)) +
        parseFloat(pastAugust.toFixed(2)) +
        parseFloat(pastJuly.toFixed(2)) +
        parseFloat(pastJune.toFixed(2)) +
        parseFloat(pastMay.toFixed(2)) +
        parseFloat(pastApril.toFixed(2)) +
        parseFloat(pastMarch.toFixed(2)) +
        parseFloat(pastFebruary.toFixed(2)) +
        parseFloat(pastJanuary.toFixed(2)),
    ];
  }

  getByMonthCurrentYear(invoices: IQuota[], month: number, notas: INotasGrap[]): number {
    let arrayGroupValues = [
      {
        fact: 0,
        articulo: "",
        cantidad: 0,
        litros: 0,
        subtotal: 0,
      },
    ];
    let arrayIdNotas: number[] = [];
    let liters = 0;
    let litersN = 0;
    let arrayFacts: number[] = [];
    let created;
    let invoice_date;

    invoices.forEach(invoice => {
      created = new Date(invoice.createdAt);
      if (invoice.invoiced_date) {
        invoice_date = new Date(invoice.invoiced_date!);
      } else {
        invoice_date = created;
      }
      if (invoice_date.getFullYear() === this.currentYear) {
        if (invoice.estado === 4) {
          if (invoice_date.getMonth() + 1 === month) {
            arrayFacts.push(invoice.id_factura!);
            invoice.venta.forEach(sale => {
              liters +=
                sale.cantidad *
                parseFloat(sale.articulo[0].presentacion!);
            });
          }
        }
      }
    });

    let groupFacts = arrayFacts.filter((item, index) => {
      return arrayFacts.indexOf(item) === index;
    });
    notas.forEach((item) => {
      groupFacts.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          arrayIdNotas.push(parseInt(item.id_nota!.toString()));
        }
      });
    });

    if (arrayIdNotas.length > 0) {
      arrayGroupValues = this.processNotesService.getNotesByDescription(
        notas,
        arrayIdNotas
      );
      arrayGroupValues.forEach((vals) => {
        litersN = litersN + vals.litros;
      });
    }

    liters = liters + litersN * 1;
    return liters;
  }

  getByMonthPastYear(invoices: IQuota[], month: number, notas: INotasGrap[]): number {
    let arrayGroupValues = [
      {
        fact: 0,
        articulo: "",
        cantidad: 0,
        litros: 0,
        subtotal: 0,
      },
    ];
    let arrayIdNotas: number[] = [];
    let litersN = 0;
    let arrayFacts: number[] = [];
    let liters = 0;
    let created;
    let invoice_date;

    invoices.forEach(invoice => {
      created = new Date(invoice.createdAt);
      if (invoice.invoiced_date) {
        invoice_date = new Date(invoice.invoiced_date!);
      } else {
        invoice_date = created;
      }
      if (invoice_date.getFullYear() === this.lastYear) {
        if (invoice.estado === 4) {
          if (invoice_date.getMonth() + 1 === month) {
            arrayFacts.push(invoice.id_factura!);
            invoice.venta.forEach(sale => {
              liters +=
                sale.cantidad *
                parseFloat(sale.articulo[0].presentacion!);
            });
          }
        }
      }
    });

    let groupFacts = arrayFacts.filter((item, index) => {
      return arrayFacts.indexOf(item) === index;
    });
    notas.forEach((item) => {
      groupFacts.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          arrayIdNotas.push(parseInt(item.id_nota!.toString()));
        }
      });
    });

    if (arrayIdNotas.length > 0) {
      arrayGroupValues = this.processNotesService.getNotesByDescription(
        notas,
        arrayIdNotas
      );
      arrayGroupValues.forEach((vals) => {
        litersN = litersN + vals.litros;
      });
    }
    liters = liters + litersN * 1;
    return liters;
  }

  getTargetByMonth(invoices: IQuota[], month: number, notas: INotasGrap[], goal: number): number {
    let arrayGroupValues = [
      {
        fact: 0,
        articulo: "",
        cantidad: 0,
        litros: 0,
        subtotal: 0,
      },
    ];
    let arrayIdNotas: number[] = [];
    let litersN = 0;
    let preLitersN = 0;
    let arrayFacts: number[] = [];
    let target = 0;
    let preTarget = 0;
    let created;
    let invoice_date;

    invoices.forEach(invoice => {
      created = new Date(invoice.createdAt);
      if (invoice.invoiced_date) {
        invoice_date = new Date(invoice.invoiced_date!);
      } else {
        invoice_date = created;
      }
      if (invoice.estado === 4) {
        if (invoice_date.getFullYear() === this.lastYear) {
          arrayFacts.push(invoice.id_factura!);
          invoice.venta.forEach(sale => {
            preTarget +=
              sale.cantidad *
              parseFloat(sale.articulo[0].presentacion!);
            target = (preTarget / 12) * month;
          });
        }
      }
    });

    let groupFacts = arrayFacts.filter((item, index) => {
      return arrayFacts.indexOf(item) === index;
    });
    notas.forEach((item) => {
      groupFacts.forEach((obj) => {
        if (item.note_fact === parseInt(obj.toString())) {
          arrayIdNotas.push(parseInt(item.id_nota!.toString()));
        }
      });
    });

    if (arrayIdNotas.length > 0) {
      arrayGroupValues = this.processNotesService.getNotesByDescription(
        notas,
        arrayIdNotas
      );
      arrayGroupValues.forEach((vals) => {
        preLitersN = litersN + vals.litros;
        litersN = (preLitersN / 12) * month;
      });
    }
    target = (goal / 12) * month;
    return target;
  }

  getLastTwoDigits(year: string): string {
    let digits = "";
    digits = year.substring(2);
    return digits;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
