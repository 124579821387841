import {
  Component,
  Input,
  SimpleChanges,
  OnChanges
} from "@angular/core";
import { DrillData } from "../../../components/drill-down/drill-down.component";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { IDrillData, IDrillInitData, IDrillQuantitiesData, IDrillTopData } from "src/app/@interfaces/Analysis/drill.interface";
import { ITopCustomerNotes } from "src/app/@interfaces/Analysis/dollar-per-client.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { ConverseArrayService } from '../../../../../@shared/components/services/data-types/converse-array.service';

@Component({
  selector: "app-drill-items-graph",
  templateUrl: "./drill-items-graph.component.html",
  styleUrls: ["./drill-items-graph.component.css"],
})
export class DrillItemsGraphComponent implements OnChanges {
  @Input() allDrillData = {} as IDrillData;
  @Input() notesByDescription: ITopCustomerNotes[] = [];

  allDrillDataToUse = {} as IDrillData;
  notesByDescriptionToUse: ITopCustomerNotes[] = [];

  drillData: DrillData[] = [];
  companyObject = {} as IStorageCompany;

  constructor(
    private cookieAuthService: CookieAuthService,
    private converseArrayService: ConverseArrayService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getAuthValues();
    for (let item in changes) {
      if (item === "allDrillData") {
        const values: IDrillData = changes[item].currentValue;
        if (values) {
          this.allDrillDataToUse = values;
        }
      }
      if (item === "notesByDescription") {
        const note: Array<ITopCustomerNotes> = changes[item].currentValue;
        if (note.length > 0) {
          this.notesByDescriptionToUse = note;
        }
      }

      if (this.allDrillDataToUse.initialData || (this.notesByDescriptionToUse.length > 0)) {
        this.drillCalculation(this.allDrillDataToUse, this.notesByDescriptionToUse);
      }
    }
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  drillCalculation(allDrillData: IDrillData, notesByDescription: ITopCustomerNotes[]) {
    const reduceArticles = this.converseArrayService.reduceArrays(allDrillData.articlesArray);
    const reduceGroups = this.converseArrayService.reduceArrays(allDrillData.groupsArray);
    let totalQuantities = this.calculation(reduceArticles, allDrillData.initialData, notesByDescription);
    totalQuantities = totalQuantities.sort((a, b) => b.quantities - a.quantities);
    const separatedData = this.separateByGroup(reduceGroups, totalQuantities);
    this.drillData = separatedData.sort((a, b) => b.items.length - a.items.length);
  }

  calculation(reduceArticles: string[], initialData: IDrillInitData[], notesByDescription: ITopCustomerNotes[]): IDrillQuantitiesData[] {
    return reduceArticles.map(element => {
      const filterByElement = initialData.filter(data => data.item === element);
      const calculatedByElement = this.calculateByElement(filterByElement);
      const updatedCalculatedByElement = this.applyNoteValues({ ...calculatedByElement}, notesByDescription);
      return {
        quantities: updatedCalculatedByElement.amount,
        item: updatedCalculatedByElement.name,
        group: updatedCalculatedByElement.group,
      };
    });
  }

  calculateByElement(filterByElement: IDrillInitData[]) {
    const result = filterByElement.reduce(
      (accumulator, element) => {
        accumulator.amount += element.quantity;
        accumulator.name = element.item;
        accumulator.code = element.code;
        accumulator.group = element.group;
        return accumulator;
      },
      { amount: 0, name: "", code: "", group: "" }
    );
    const litersCalculated = result.amount * filterByElement[0].liter;
    return {
      amount: litersCalculated,
      name: result.name,
      code: result.code,
      group: result.group,
    };
  }

  applyNoteValues(calculatedByElement: IDrillTopData, notesByDescription: ITopCustomerNotes[]): IDrillTopData {
    const matchingItem = notesByDescription.find(item => item.articulo === calculatedByElement.name);
    if (matchingItem) {
      calculatedByElement.amount += matchingItem.litros * 1;
    }
    return calculatedByElement;
  }

  separateByGroup(reduceGroups: string[], totalQuantities: IDrillQuantitiesData[]): DrillData[] {
    return reduceGroups.map(group => {
      const filteredGroup = totalQuantities.filter(dato => dato.group === group);
      const items = filteredGroup.map(element => ({ name: element.item, value: element.quantities }));
      return { name: group, items: items };
    });
  }
}
