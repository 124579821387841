import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ImageConverseService {
  constructor() {}

  async getBase64ImageFromURL(imageElement: any) {
    const convertImgToBase64URL = (url: any) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
          let canvas: any = document.createElement("CANVAS");
          const ctx = canvas.getContext("2d");
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL();
          canvas = null;
          resolve(dataURL);
        };
        img.src = url;
      });
    };
    const image = await convertImgToBase64URL(imageElement);
    return image;
  }
}
