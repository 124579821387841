import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import {
  GETALL_WAREHOUSE_OUTPUTS_ENTRIES,
  GET_WAREHOUSE,
  SEARCH_WAREHOUSE_OUTPUTS_ENTRIES,
  GET_ALL_INVENTORY_RECLASSIFICATIONS,
  GET_ALL_WAREHOUSE_TRANSFER,
  SEARCH_INVENTORY_RECLASSIFICATIONS,
  SEARCH_WAREHOUSE_TRANSFER,
} from "src/app/@graphql/operations/query";
import { ApiService } from "src/app/@graphql/services/api.service";
import {
  CREATE_INVENTORY_RECLASSIFICATION,
  CREATE_WAREHOUSE,
  CREATE_WAREHOUSE_OUTPUTS_ENTRIES,
  CREATE_WAREHOUSE_TRANSFER,
  DELETE_WAREHOUSE_OUTPUTS_ENTRIES,
  DELETE_INVENTORY_RECLASSIFICATION,
  DELETE_WAREHOUSE_TRANSFER,
  REMOVE_WAREHOUSE,
  UPDATE_AVALIABLE_QUANTITY,
} from "src/app/@graphql/operations/mutation";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class WarehouseService extends ApiService {
  constructor(
    apollo: Apollo,
    private cookieAuthService: CookieAuthService
  ) {
    super(apollo);
  }

  createWarehouse(nombre: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_WAREHOUSE, { nombre, company }).pipe(
      map((result: any) => {
        return result.createWarehouse;
      })
    );
  }

  removeWarehouse(id: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(REMOVE_WAREHOUSE, { id, company }).pipe(
      map((result: any) => {
        return result.removeWarehouse;
      })
    );
  }

  listWarehouses() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_WAREHOUSE, { company }).pipe(
      map((result: any) => {
        return result.getAllWarehouse;
      })
    );
  }

  getAllWarehouseTransfers(page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_WAREHOUSE_TRANSFER, { page, size, company }).pipe(
      map((result: any) => {
        return result.getAllWarehouseTransfers;
      })
    );
  }

  searchWarehouseTranfers(value: string, page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_WAREHOUSE_TRANSFER, { value, company, page, size }).pipe(
      map((result: any) => {
        return result.searchWarehouseTranfers;
      })
    );
  }

  createWarehouseTransfer(
    id_article: number,
    original: number,
    transferred: number,
    date: string,
    value: number,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_WAREHOUSE_TRANSFER, {
      id_article,
      original_warehouse: original,
      transferred_warehouse: transferred,
      date,
      value,
      id_user,
      company,
    }).pipe(
      map((result: any) => {
        return result.createWarehouseTransfer;
      })
    );
  }

  deleteWarehouseTransfer(id_transfer: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(DELETE_WAREHOUSE_TRANSFER, {
      id_transfer,
      company,
    }).pipe(
      map((result: any) => {
        return result.deleteWarehouseTransfer;
      })
    );
  }

  createWarehouseOutputOrEntry(
    id_article: number,
    type: number,
    id_warehouse: number,
    date: string,
    amount: number,
    cost: number,
    description: string,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_WAREHOUSE_OUTPUTS_ENTRIES, {
      id_article,
      type,
      id_warehouse,
      date,
      amount,
      cost,
      description,
      id_user,
      company,
    }).pipe(
      map((result: any) => {
        return result.createWarehouseOutputsAndEntries;
      })
    );
  }

  deleteWarehouseOutputOrEntry(id_movement: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(DELETE_WAREHOUSE_OUTPUTS_ENTRIES, {
      id_movement,
      company,
    }).pipe(
      map((result: any) => {
        return result.deleteWarehouseOutputsAndEntries;
      })
    );
  }

  getAllWarehouseOutputsAndEntries(type: number, page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GETALL_WAREHOUSE_OUTPUTS_ENTRIES, {
      type,
      page,
      size,
      company,
    }).pipe(
      map((result: any) => {
        return result.getAllWarehouseOutputsAndEntries;
      })
    );
  }

  updateWarehouseTransfers(type: number, page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    this.setVariables(GETALL_WAREHOUSE_OUTPUTS_ENTRIES, { type, page, size, company })
  }

  searchWarehouseOutputsAndEntries(value: string, type: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_WAREHOUSE_OUTPUTS_ENTRIES, {
      value,
      type,
      company,
    }).pipe(
      map((result: any) => {
        return result.searchWarehouseOutputsAndEntries;
      })
    );
  }

  deleteInventoryReclassification(id_reclassification: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(DELETE_INVENTORY_RECLASSIFICATION, {
      id_reclassification,
      company,
    }).pipe(
      map((result: any) => {
        return result.deleteInventoryReclassification;
      })
    );
  }

  createInventoryReclassification(
    exit_article: number,
    entry_article: number,
    exit_warehouse: number,
    entry_warehouse: number,
    date: string,
    value: number,
    id_user: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(CREATE_INVENTORY_RECLASSIFICATION, {
      exit_article,
      entry_article,
      exit_warehouse,
      entry_warehouse,
      date,
      value,
      id_user,
      company,
    }).pipe(
      map((result: any) => {
        return result.createInventoryReclassification;
      })
    );
  }

  searchInventoryReclassifications(value: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(SEARCH_INVENTORY_RECLASSIFICATIONS, {
      value,
      company,
    }).pipe(
      map((result: any) => {
        return result.searchInventoryReclassifications;
      })
    );
  }

  getAllInventoryReclassifications(page: number, size: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.query(GET_ALL_INVENTORY_RECLASSIFICATIONS, {
      page,
      size,
      company,
    }).pipe(
      map((result: any) => {
        return result.getAllInventoryReclassifications;
      })
    );
  }

  updateAvaliableQuantity(
    id_article: number,
    id_warehouse: number,
    quantity: number,
    operation_type: number
  ) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject?.Id_company;
    return this.mutation(UPDATE_AVALIABLE_QUANTITY, {
      id_article,
      id_warehouse,
      quantity,
      operation_type,
      company,
    }).pipe(
      map((result: any) => {
        return result.updateAvaliableQuantity;
      })
    );
  }
}
