import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from '../../../../@interfaces/quotation.interface';

@Pipe({
  name: 'filterSCQuots'
})
export class FilterSCQuots implements PipeTransform {
  transform(quotaions: IQuota[], customer: string, seller: string): IQuota[] {
    return quotaions.filter(quotation => 
      quotation.id_cliente?.toString() === customer &&
      quotation.id_seller?.toString() === seller
    )
  }
}
