import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { PortfolioService } from "src/app/@pages/portfolio/portfolio.service";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { takeUntil } from "rxjs/operators";
import Swal from "sweetalert2";
import { IQuotation } from "src/app/@interfaces/quotation.interface";
import { TranslateService } from "@ngx-translate/core";
import { CookieAuthService } from "../../../storage-variables/cookie-auth.service";

@Injectable({
  providedIn: "root",
})
export class CancelImportsService implements OnDestroy {
  unsubscribe$ = new Subject();

  constructor(
    private portfolio: PortfolioService,
    private quotationService: QuotationsService,
    private translate: TranslateService,
    private cookieAuthService: CookieAuthService
  ) {}

  cancelPayment(id: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.portfolio
      .updateStatusPayment(id, false, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        async (result) => {
          if (result) {
            resolve(true);
          } else {
            reject(false);
          }
        },
        (error) => {
          console.error(error);
          reject(false);
        }
      );
    });
  }

  cancelInvoice(idInvoice: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.quotationService
      .updateStatusInvoice(idInvoice, false, true, null)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (result) => {
          if (result) {
            resolve(true);
          } else {
            reject(false);
          }
        },
        (error) => {
          console.error(error);
          reject(false);
        }
      );
    });
  }

  getInvoiceIDFromCollections(invoice_number: string, year: number) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    return new Promise<number>((resolve, reject) => {
      if (companyObject!.external_electronic_invoicing === "true" || companyObject!.internal_electronic_invoicing === "true") {
        const initNumber = this.companyInvoiceName();
        this.quotationService
        .getInvoiceByBillyNumber(initNumber + invoice_number, year)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation) => {
          if (result) {
            resolve(result.id_factura!);
          } else {
            Swal.fire(
            "Error",
            this.translate.instant(`This invoice id does not exist`) +
              " : " +
              invoice_number +
              " " +
              this.translate.instant("Year: ") +
              year,
              "error"
            ).then((confirm) => {
                if (confirm.isConfirmed) {
                  reject(
                    new Error("This invoice id does not exist" + invoice_number)
                  );
                }
            });
          }
        });
      } else {
        this.quotationService
        .getInvoiceByContNumber(invoice_number)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: IQuotation) => {
          if (result) {
            resolve(result.id_factura!);
          } else {
            Swal.fire(
            "Error",
            this.translate.instant(`This invoice id does not exist`) +
              " : " +
              invoice_number +
              " " +
              "Year: " +
              year,
              "error"
            ).then((confirm) => {
              if (confirm.isConfirmed) {
                reject(
                  new Error("This invoice id does not exist" + invoice_number)
                );
              }
            });
          }
        });
      }
    });
  }

  companyInvoiceName() {
    const companyObject = this.cookieAuthService.getCompanyObject;
    let initNumber = "N/A";
    if (companyObject!.Id_company!.toString() === "7") {
      initNumber = "FEVO";
    }
    if (companyObject!.Id_company!.toString() === "9") {
      initNumber = "FE";
    }
    return initNumber;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
