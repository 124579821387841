import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { EventEmitterService } from "../../../@core/menu/emitterFuncions.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IPurchasesInvoices } from '../../../@interfaces/purchaseInvoice.interface';
import { PurchasesService } from "../../../@pages/purchases/purchases.service";
import { PageEvent } from "@angular/material/paginator";
import { DialogComponent } from "../dialog/dialog.component";
import { FilterPurchasePipe } from "../../pipe/filter-purchases.pipe";
import { NgxSpinnerService } from "ngx-spinner";
import { IRefunds } from "src/app/@interfaces/refunds.interface";
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";

@Component({
  selector: "app-purchase-tabs",
  templateUrl: "./purchase-tabs.component.html",
  styleUrls: ["./purchase-tabs.component.css"],
})
export class PurchaseTabsComponent implements OnInit {
  pageAll: number = 1;
  filterValue = "";

  purchasesListBy1: Array<IPurchasesInvoices> = [];
  pageSliceBy1 = this.purchasesListBy1.slice();
  firstBy1: Array<IPurchasesInvoices> = [];

  purchasesListBy2: Array<IPurchasesInvoices> = [];
  pageSliceBy2 = this.purchasesListBy2.slice();
  firstBy2: Array<IPurchasesInvoices> = [];

  refunds: IRefunds[] = [];
  companyId = '';
  user = '';
  rol = '';
  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;
  constructor(
    private dialog: MatDialog,
    private purchasesService: PurchasesService,
    private filterPurchasePipe: FilterPurchasePipe,
    private eventEmitterService: EventEmitterService,
    private spinnerService: NgxSpinnerService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit() {
    this.getAuthValues();
    this.getEvent();
    this.initialData();
    this.spinner();
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  spinner(): void {
    setTimeout(() => {
      this.spinnerService.hide();
      this.toggleExpandCard();
    }, 3000);
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((name: string) => {
      this.toggleExpandCard();
    });
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 250);
  }

  initialData() {
    this.purchasesService
    .listPurchasesInvoiceByStatus(1)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.purchasesListBy1 = result;
      this.pageSliceBy1 = result.slice(0, 4);
      this.validRefunds(this.pageSliceBy1);
    });
  }

  validRefunds(purchases: IPurchasesInvoices[]) {
    purchases.forEach(element => {
      this.purchasesService
      .validRefund(parseInt(element.id_invoice!.toString()))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.refunds = result;
      });
    });
  }

  OnPageChange1(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.purchasesListBy1.length) {
      endIndex = this.purchasesListBy1.length;
    }
    this.pageSliceBy1 = this.purchasesListBy1.slice(startIndex, endIndex);
    this.validRefunds(this.pageSliceBy1);
  }

  OnPageChange2(event: PageEvent): void {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.purchasesListBy2.length) {
      endIndex = this.purchasesListBy2.length;
    }
    this.pageSliceBy2 = this.purchasesListBy2.slice(startIndex, endIndex);
  }

  handleSearch(value: string, tab: number) {
    if (!(this.firstBy1.length > this.pageSliceBy1.length)) {
      this.firstBy1 = this.pageSliceBy1;
    }
    if (!(this.firstBy2.length > this.pageSliceBy2.length)) {
      this.firstBy2 = this.pageSliceBy2;
    }
    if (value) {
      this.filterValue = value;
      this.filterAll(tab);
    } else {
      this.pageSliceBy1 = this.firstBy1.slice(0, 4);
      this.validRefunds(this.pageSliceBy1);
      this.pageSliceBy2 = this.firstBy2.slice(0, 4);
    }
  }

  filterAll(tab: number) {
    switch(tab) {
      case 1:
        const filterBy1 = this.filterPurchasePipe.transform(
          this.purchasesListBy1,
          this.filterValue
        );
        this.pageSliceBy1 = filterBy1;
        this.validRefunds(this.pageSliceBy1);
      break;
      case 2:
        const filterBy2 = this.filterPurchasePipe.transform(
          this.purchasesListBy2,
          this.filterValue
        );
        this.pageSliceBy2 = filterBy2;
      break
    }
  }

  async selectTab(event: any) {
    switch (event.index) {
      case 0:
        this.initialData();
      break;
      case 1:
        this.setCancelledPurchases();
      break;
    }
  }

  setCancelledPurchases() {
    this.purchasesService
    .listPurchasesInvoiceByStatus(2)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.purchasesListBy2 = result;
      this.pageSliceBy2 = result.slice(0, 4);
    });
  }

  openDialog(registrationModePurchase: boolean): void {
    this.dialog.open(DialogComponent, {
      data: { registrationModePurchase },
      disableClose: true,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
