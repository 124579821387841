import { ActionReducerMap } from "@ngrx/store";
import { CompaniesState } from "../@interfaces/company.interface";
import { companiesReducer } from "./reducers/user-companies.reducers";
import { CompaniesEffects } from "./effects/user-companies.effects";

export interface AppState {
  companiesState: CompaniesState;
}

export const ROOT_REDUCER: ActionReducerMap<AppState> = {
  companiesState: companiesReducer
};

export const ROOT_EFFECTS = [CompaniesEffects];
