<mat-tab-group #tabs mat-align-tabs="center" dynamicHeight="true">
    <mat-tab label="{{'Advance Payments' | translate}}">
        <div class="register-button">
            <a><button mat-raised-button class="Warn" (click)="openDialog()"> {{ "Register" | translate }} </button></a>
        </div>
        <div *ngIf="advancePayments.length > 0">
            <app-table [advancePayments]="advancePayments" [showTable]="7" [length]="lenght1"></app-table>
        </div>        
    </mat-tab>

    <mat-tab label="{{'Transfers' | translate}}">
        <div class="register-button">
            <a><button mat-raised-button class="Warn" (click)="transfersDialog()"> {{ "Transfers" | translate }} </button></a>
        </div>
        <div *ngIf="transfers.length > 0; noResult">
            <app-table [transfers]="transfers" [showTable]="8" [length]="lenght2"></app-table>
        </div>
    </mat-tab>
</mat-tab-group>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>


