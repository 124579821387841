import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITreasury } from 'src/app/@interfaces/treasury.interface';
import { DialogComponent } from 'src/app/@shared/components/dialog/dialog.component';
import { TreasuryService } from './treasury.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/@shared/authentication/auth.service';
import { ITransfers } from 'src/app/@interfaces/transfers.interface';
import { EventEmitterService } from 'src/app/@core/menu/emitterFuncions.service';

@Component({
  selector: 'app-treasury',
  templateUrl: './treasury.component.html',
  styleUrls: ['./treasury.component.css']
})
export class TreasuryComponent implements OnInit, OnDestroy {
  advancePayments: ITreasury[] = [];
  transfers: ITransfers[] = [];
  lenght1: number = 0;
  lenght2: number = 0;
  unsubscribe$ = new Subject();
  @ViewChild("tabs", { static: false }) tabs?: any;
  constructor(
    private dialog: MatDialog,
    private treasuryService: TreasuryService,
    private spinnerService: NgxSpinnerService,
    private eventEmitterService: EventEmitterService,
    private authService: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.toggleExpandCard();
    this.getEvent();
    this.spinner();
    this.data();
  }

  getEvent() {
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((name: string) => {
      this.toggleExpandCard();
    });
  }

  toggleExpandCard() {
    setTimeout(() => {
      this.tabs!.realignInkBar();
    }, 250);
  }

  data() {
    this.treasuryService
    .getAllAdvencePayments(0, 15)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.advancePayments = result.treasury;
      this.lenght1 = result.count;
    });

    this.treasuryService
    .getAllTransfers(0, 15)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.transfers = result.transfers;
      this.lenght2 = result.count;
    });
  }

  openDialog() {
    this.dialog.open(DialogComponent, {
      data: { registerAdvencePayments: true },
    });
  }

  transfersDialog() {
    this.dialog.open(DialogComponent, {
      data: { registerTransfers: true },
    });
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
