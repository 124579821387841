import { Pipe, PipeTransform } from '@angular/core';
import { INotasGrap } from 'src/app/@interfaces/notas.interface';

@Pipe({
  name: 'filterINotes'
})
export class FilterINotes implements PipeTransform {
  transform(notes: INotasGrap[], item: string): INotasGrap[] {
    if (!notes) {
      return [];
    }
    return notes.map(note => ({
      ...note,
      valor_notas: note.valor_notas?.filter(value => value.articulo[0]?.codigo === item)
    })).filter(note => note.valor_notas?.length > 0)
  }
}
