import { Component, OnInit, Input, Injectable, OnChanges, SimpleChanges } from '@angular/core';
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { CalculateAnalysis } from 'src/app/@shared/components/services/charts/calculate-analysis.service';
import { IDollarPerClientData, ITopCustomerNotes } from 'src/app/@interfaces/Analysis/dollar-per-client.interface';
import { IDrillData } from 'src/app/@interfaces/Analysis/drill.interface';
import { ILiterPerClientData } from 'src/app/@interfaces/Analysis/liter-per-client.interface';
import { ILiterPerGroupData } from 'src/app/@interfaces/Analysis/liter-per-group.interface';
import { ILiterPerItemData } from 'src/app/@interfaces/Analysis/liter-per-item.interface';
import { ProcessNotesService } from 'src/app/@shared/components/services/charts/process-notes.service';

function importar(): any {
  const animation = require("../../carousel.js");
  return animation;
}

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-grap-v1",
  templateUrl: "./grap-v1.component.html",
  styleUrls: ["./grap-v1.component.css"],
})
export class GrapV1Component implements OnInit, OnChanges {
  @Input() orderCustomerQuots: Array<IQuota> = [];
  @Input() notas: Array<INotasGrap> = [];
  @Input() months: number = 0;
  @Input() typePres: string = "All";

  allInvoices: Array<IQuota> = [];
  allNotes: Array<INotasGrap> = [];

  dollarPerClientData = {} as IDollarPerClientData;
  drillData = {} as IDrillData;
  literPerClientData = {} as ILiterPerClientData;
  literPerGroupData = {} as ILiterPerGroupData;
  literPerItemData = {} as ILiterPerItemData;

  notesByDescription: ITopCustomerNotes[] = []; //single
  notesByCode: ITopCustomerNotes[] = []; //multiple
  notesByGroup: ITopCustomerNotes[] = []; //single

  defaultVisible1?: boolean;
  defaultVisible2?: boolean;
  defaultVisible3?: boolean;
  defaultVisible4?: boolean;
  defaultVisible5?: boolean;
  defaultVisible6?: boolean;
  defaultVisible7?: boolean;

  constructor(
    private calculateAnalysis: CalculateAnalysis,
    private processNotes: ProcessNotesService,
  ) {}

  ngOnInit(): void {
    importar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let item in changes) {
      if (item === "orderCustomerQuots") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.allInvoices = values;
        }
      }
      if (item === "notas") {
        const values: Array<INotasGrap> = changes[item].currentValue;
        if (values.length > 0) {
          this.allNotes = values;
        }
      }
      if (this.allInvoices.length > 0 || (this.allNotes.length > 0)) {
        this.extractDataFromInvoices(this.allInvoices, this.allNotes);
      }
    }
  }

  toggleDefault(id: number) {
    switch (id) {
      case 1:
        this.defaultVisible1 = !this.defaultVisible1;
        break;
      case 2:
        this.defaultVisible2 = !this.defaultVisible2;
        break;
      case 3:
        this.defaultVisible3 = !this.defaultVisible3;
        break;
      case 4:
        this.defaultVisible4 = !this.defaultVisible4;
        break;
      case 5:
        this.defaultVisible5 = !this.defaultVisible5;
        break;
      case 6:
        this.defaultVisible6 = !this.defaultVisible6;
        break;
      case 7:
        this.defaultVisible7 = !this.defaultVisible7;
        break;
    }
  }

  extractDataFromInvoices(invoices: IQuota[], notes: INotasGrap[]) {
    const allDataFromInvoices = this.calculateAnalysis.extractDataFromInvoices(invoices);
    const noteIds = this.calculateAnalysis.getNoteValues(notes, allDataFromInvoices.arrayInvoices);
    const byDescription = this.processNotes.getNotesByDescription(notes, noteIds.arraySingleIds);
    const byCode = this.processNotes.getNotesPerCode(notes, noteIds.arrayMultipleIds);
    const byGroup = this.processNotes.getNotesPerGroup(notes, noteIds.arraySingleIds);

    this.dollarPerClientData = allDataFromInvoices.dollarPerClientData;
    this.drillData = allDataFromInvoices.drillData;
    this.literPerClientData = allDataFromInvoices.literPerClientData;
    this.literPerGroupData = allDataFromInvoices.literPerGroupData;
    this.literPerItemData = allDataFromInvoices.literPerItemData;

    this.notesByDescription = byDescription;
    this.notesByCode = byCode;
    this.notesByGroup = byGroup;
  }
}
