<div fxFill fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="cardType == 1">
    <mat-card class="card" [ngClass]="{'card-shadow' :  isProspective}">
        <div>
            <div>
                <a class="pdf-options" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </a>
                <mat-menu #menu="matMenu">
                    <a mat-menu-item [routerLink]="['/details', quotation.id_factura, companyId, 1]" target="_blank">
                        <mat-icon class="black-icon">visibility</mat-icon>
                        <span>PDF</span>
                    </a>
                    <a mat-menu-item [routerLink]="['/details', quotation.id_factura, companyId, 2]" target="_blank">
                        <mat-icon class="black-icon">visibility</mat-icon>
                        <span>{{'Letterhead PDF' | translate}}</span>
                    </a>
                </mat-menu>
            </div>
            <div *ngIf="quotation.estado! === 1 || quotation.estado! === 3 || quotation.estado! === 2">
                <a style="float: left" (click)="openDialog(quotation.estado!)">
                    <mat-icon  class="black-icon">mode</mat-icon>
                </a>  
            </div>  
            <div *ngIf="quotation.estado! === 4">
                <a style="float: left" (click)="sendDian(quotation)">
                    <mat-icon *ngIf="quotation.estado! === 4" class="black-icon">send</mat-icon>
                </a>  
            </div>         
            
            <div class="div-center">
                <div class="header-image" *ngIf="companyId ==='1'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='2'" mat-card-avatar>
                    <img mat-card-image src="../../../../assets/img/Ibexports.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image" *ngIf="companyId ==='5'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/VersaNoLetras.png);">
                    <img mat-card-image src="../../../../assets/icons/letrasVersa.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='6'" mat-card-avatar>
                    <img mat-card-image src="../../../../assets/img/Parabricentro.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image" *ngIf="companyId ==='7'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='8'" mat-card-avatar>
                    <img mat-card-image src="../../../../assets/img/Parabricentro.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="div-center">
                    <div class="header-image2" *ngIf="companyId ==='4'" mat-card-avatar>
                        <img mat-card-image src="../../../../assets/img/Jens.png" style="margin-top: 6px;"
                            onerror="this.src='assets/img/cantfindimage.jpg'">
                    </div>
                </div>

                <div class="header-image" *ngIf="companyId ==='9'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>
            </div>
        </div>
    
        <div class="div-stepper">
            <app-stepper [isProspective]="isProspective" [typeStepper]="1" [status]='quotation.estado'>
            </app-stepper>
        </div>
        
    <div [ngSwitch]="quotation.estado">
        <div *ngSwitchCase=1>
            <div class="div-center">
                <mat-card-title>{{quotation.num_fac}}</mat-card-title>
            </div>
            <div class="div-center">
                <mat-card-subtitle>{{quotation.num_fac}}</mat-card-subtitle>
            </div>
        </div>

        <div *ngSwitchCase=2>
            <div class="div-center">
                <mat-card-title>{{quotation.num_fac}}</mat-card-title>
            </div>
            <div class="div-center">
                <mat-card-subtitle>{{quotation.num_fac}}</mat-card-subtitle>
            </div>
        </div>

        <div *ngSwitchCase=3>
            <div class="div-center">
                <ng-container *ngFor="let INV of quotation.contfac">    
                    <mat-card-title>{{INV.delivery}}</mat-card-title>
                </ng-container>
            </div>
            <div class="div-center">
                <mat-card-subtitle>{{quotation.num_fac}}</mat-card-subtitle>
            </div>
        </div>

        <div *ngSwitchCase=4>
            <div  class="div-center">
                <ng-container *ngFor="let INV of quotation.contfac">    
                    <mat-card-title>{{INV.invoice}}</mat-card-title>
                </ng-container>
            </div>
            <div class="div-center">
                <mat-card-subtitle>{{quotation.num_fac}}</mat-card-subtitle> 
            </div>
            <div class="div-center" *ngIf="companyObject.countries[0].name === 'Colombia'">
                <ng-container *ngFor="let item of quotation.billyInvoice">
                    <mat-card-subtitle>{{item.invoice_number}}</mat-card-subtitle> 
                </ng-container>
            </div>
        </div>

    </div>
        <mat-card-content>
            <ul>
                <li *ngFor="let customer of quotation.cliente">
                    <strong>{{'Customer' | translate}}: </strong>{{customer.nombre}}
                </li>
                <li *ngFor="let venta of quotation.venta | slice:0:1;">
                    <strong>{{'Total' | translate}}: </strong>{{venta.total}}
                </li>
                <ng-container [ngSwitch]="quotation.estado">
                    <li *ngSwitchCase=1>
                        <strong>{{'Date' | translate}}: </strong>{{quotation.createdAt | date: 'short'}}
                    </li>
                    <li *ngSwitchCase=2>
                        <strong>{{'Date' | translate}}: </strong>{{quotation.createdAt | date: 'short'}}
                    </li>
                    <ng-container *ngSwitchCase=3>
                        <li *ngIf="quotation.delivery_date">
                            <strong>{{'Date' | translate}}: </strong>{{quotation.delivery_date | date: 'short'}}
                        </li>
                        <li *ngIf="!(quotation.delivery_date)">
                            <strong>{{'Date' | translate}}: </strong>{{quotation.createdAt | date: 'short'}}
                        </li>
                    </ng-container>
                    <ng-container *ngSwitchCase=4>
                        <li *ngIf="quotation.invoiced_date">
                            <strong>{{'Date' | translate}}: </strong>{{quotation.invoiced_date | date: 'short'}}
                        </li>
                        <li *ngIf="!(quotation.invoiced_date)">
                            <strong>{{'Date' | translate}}: </strong>{{quotation.createdAt | date: 'short'}}
                        </li>
                    </ng-container>
                </ng-container>
                <li>
                    <strong>{{'Due Date' | translate}}: </strong>{{quotation.dueDate | date: 'short'}}
                </li>
            </ul>
        </mat-card-content>
        <mat-card-actions>
            <div class="div-center">
                <div [ngSwitch]="quotation.estado">
                    <div class="boton_notas2" *ngSwitchCase=1>
                        <button mat-raised-button class="orange-color"
                            (click)="validation(quotation.id_factura!,true,false,false)">{{'Reject' | translate}}</button>
                        <button [disabled]="disableButton" mat-raised-button class="orange-color"
                            (click)="openDialogDelivery(quotation.id_factura!);">{{'Delivery' | translate}}</button>
                        <button [disabled]="disableButton" class="boton3" mat-raised-button class="orange-color"
                            (click)="validation(quotation.id_factura!,false,true,false)">{{'Invoice' | translate}}</button> 
                    </div>
                    <div *ngSwitchCase=3>
                        <button [disabled]="disableButton" mat-raised-button class="orange-color"
                            (click)="validation(quotation.id_factura!,false,true,false)">{{'Invoice' | translate}}</button>
                        <button mat-raised-button class="orange-color"
                            (click)="validation(quotation.id_factura!,true,false,false)">{{'Reject' | translate}}</button>
                    </div>
                    <div class="boton_notas" *ngSwitchCase=4>
                        <button [disabled]="disableButton" class="boton1" [matBadgeHidden]="haveDebit" matBadgeColor="warn" matBadge="X" matBadgePosition="before" (click)="openDialogDebit(quotation.id_factura!);" mat-raised-button class="orange-color">{{'Debit Note' | translate}}</button>
                        <button [disabled]="disableButton" class="boton2" [matBadgeHidden]="haveCredit" matBadgeColor="warn" matBadge="X" (click)="openDialogCredit(quotation.id_factura!);"mat-raised-button class="orange-color">{{'Credit Note' | translate}}</button>
                    </div>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</div>

<div fxFill fxLayout="row wrap" fxLayoutAlign="space-around center" *ngIf="cardType == 2">
    <mat-card class="card">
        <div>   
            <div>
                <a style="float: left" (click)="configPayments(quotation.id_factura!, true, invoicePayments, quotation.cliente[0].id_cliente)">
                    <mat-icon class="black-icon">settings</mat-icon>
                </a>  
            </div>  

            <div class="div-center">
                <div class="header-image" *ngIf="companyId ==='1'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img id="img" mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='2'" mat-card-avatar>
                    <img id="img" mat-card-image src="../../../../assets/img/Ibexports.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image" *ngIf="companyId ==='5'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/VersaNoLetras.png);">
                    <img id="img" mat-card-image src="../../../../assets/icons/letrasVersa.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='6'" mat-card-avatar>
                    <img id="img" mat-card-image src="../../../../assets/img/Parabricentro.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image" *ngIf="companyId ==='7'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img id="img" mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="header-image2" *ngIf="companyId ==='8'" mat-card-avatar>
                    <img id="img" mat-card-image src="../../../../assets/img/Parabricentro.png" style="margin-top: 6px;"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>

                <div class="div-center">
                    <div class="header-image2" *ngIf="companyId ==='4'" mat-card-avatar>
                        <img id="img" mat-card-image src="../../../../assets/img/Jens.png" style="margin-top: 6px;"
                            onerror="this.src='assets/img/cantfindimage.jpg'">
                    </div>
                </div>

                <div class="header-image" *ngIf="companyId ==='9'" mat-card-avatar
                    style="background-image: url(../../../../assets/icons/icono.png);">
                    <img id="img" mat-card-image src="../../../../assets/img/v1rotateBlack.png"
                        onerror="this.src='assets/img/cantfindimage.jpg'">
                </div>
            </div>
        </div>
    
        <div class="div-stepper">
            <app-stepper [typeStepper]="2" [status]='quotation.estado_factura'>
            </app-stepper>
        </div> 
    <div [ngSwitch]="quotation.estado">
        <div *ngSwitchCase=4>
            <div  class="div-center">
                <ng-container *ngFor="let INV of quotation.contfac">    
                    <mat-card-title>{{INV.invoice}}</mat-card-title>
                </ng-container>
            </div>
            <div class="div-center">
                <mat-card-subtitle>{{quotation.num_fac}}</mat-card-subtitle> 
            </div>
            <div class="div-center" *ngIf="companyObject.countries[0].name === 'Colombia'">
                <ng-container *ngFor="let item of quotation.billyInvoice">
                    <mat-card-subtitle>{{item.invoice_number}}</mat-card-subtitle> 
                </ng-container>
            </div>
        </div>
    </div>
        <mat-card-content>
            <ul>
                <li *ngFor="let customer of quotation.cliente">
                    <strong>{{'Customer' | translate}}: </strong>{{customer.nombre}}
                </li>
                <li *ngFor="let venta of quotation.venta | slice:0:1;">
                    <strong>{{'Total' | translate}}: </strong>{{venta.total}}
                </li>
                <li *ngIf="quotation.invoiced_date">
                    <strong>{{'Date' | translate}}: </strong>{{quotation.invoiced_date | date: 'short'}}
                </li>
                <li *ngIf="!(quotation.invoiced_date)">
                    <strong>{{'Date' | translate}}: </strong>{{quotation.createdAt | date: 'short'}}
                </li>
                <li>
                    <strong>{{'Due Date' | translate}}: </strong>{{quotation.dueDate | date: 'short'}}
                </li>
            </ul>
        </mat-card-content>
        <mat-card-actions>
            <div class="div-center">
                <div [ngSwitch]="quotation.estado_factura">
                    <div class="boton_notas2" *ngSwitchCase=10>
                        <button (click)="generateReport(true)" class="boton2" mat-raised-button class="orange-color">{{'Generate Report' | translate}}</button>
                    </div>
                    <div class="boton_notas2" *ngSwitchCase=20>
                        <button (click)="generateReport(true)" class="boton2" mat-raised-button class="orange-color">{{'Generate Report' | translate}}</button>
                    </div>
                    <div class="boton_notas2" *ngSwitchCase=30>
                        <button (click)="generateReport(true)" class="boton2" mat-raised-button class="orange-color">{{'Generate Report' | translate}}</button>
                    </div>
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
</div>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>



