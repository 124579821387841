import { Injectable } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
} from "@angular/forms";
import { IPurchasesInvoices } from "../../../../../@interfaces/purchaseInvoice.interface";

@Injectable({
  providedIn: "root",
})
export class ModifyPurchaseService {
  constructor() {}

  setQuotationData(purchaseForm: UntypedFormGroup, rowsArr: UntypedFormArray, newProvider: UntypedFormControl) {
    let setPurchaseInvoice = {} as IPurchasesInvoices;
    var total: number = 0;
    setPurchaseInvoice.tax = purchaseForm.value.tax;
    setPurchaseInvoice.provider = [{ nombre: "" }];
    setPurchaseInvoice.provider[0].id_provider = parseInt(
      newProvider.value.id_provider
    );
    setPurchaseInvoice.createdAt = purchaseForm.value.date;
    setPurchaseInvoice.dueDate = purchaseForm.value.dueDate;
    setPurchaseInvoice.tax_incl = new Boolean(
      purchaseForm.value.tax_include
    ).toString();
    setPurchaseInvoice.clause = new Boolean(
      purchaseForm.value.clause
    ).toString();
    setPurchaseInvoice.contpurchase = [{ written_invoice: "" }];
    setPurchaseInvoice.contpurchase![0].written_invoice =
      purchaseForm.value.written_invoice;
    for (let x = 0; x < rowsArr.value.length; x++) {
      total = total + parseFloat(rowsArr.value[x].subtotal);
    }
    setPurchaseInvoice.purchases = [
      {
        id_purchases: [],
        id_warehouse: [],
        codigo: [],
        quantity: [],
        price: [],
        discount: [],
        subtotal: [],
        total: [],
        id_article: [],
      },
    ];
    for (let index = 0; index < rowsArr.value.length; index++) {
      if (rowsArr.value[index].ids) {
        setPurchaseInvoice.purchases[0].id_purchases.push(
          parseInt(rowsArr.value[index].ids)
        );
      }
      setPurchaseInvoice.purchases[0].id_warehouse.push(
        parseInt(rowsArr.value[index].warehouse.id_almacen)
      );
      setPurchaseInvoice.purchases[0].codigo.push(
        rowsArr.value[index].article.codigo
      );
      setPurchaseInvoice.purchases[0].quantity.push(
        parseFloat(rowsArr.value[index].quantity)
      );
      setPurchaseInvoice.purchases[0].price.push(
        parseFloat(rowsArr.value[index].price)
      );
      setPurchaseInvoice.purchases[0].discount.push(
        parseFloat(rowsArr.value[index].discount)
      );
      setPurchaseInvoice.purchases[0].subtotal.push(
        parseFloat(rowsArr.value[index].subtotal)
      );
      setPurchaseInvoice.purchases[0].total.push(total);
      setPurchaseInvoice.purchases[0].id_article.push(
        rowsArr.value[index].article.id_articulo,
      );
    }
    return setPurchaseInvoice;
  }
}
