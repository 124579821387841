import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BillyService } from "./@pages/billyService/billy.service";
import { CookieAuthService } from "./@shared/storage-variables/cookie-auth.service";
import { ApiToCalculateService } from "./@core/api-to-calculate/api-to-calculate.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "client";
  langs: string[] = [];
  constructor(
    private translate: TranslateService,
    private billyService: BillyService,
    private cookieAuthService: CookieAuthService
  ) {
    this.translate.setDefaultLang("es");
    this.translate.use("es");
    this.translate.addLangs(["es", "en"]);
    this.langs = this.translate.getLangs();
  }

  
  ngOnInit() {
    this.billyService.assignToken();
    this.cookieAuthService.setUserId();
    this.cookieAuthService.setRolId();
    this.cookieAuthService.setToken();
    this.cookieAuthService.setUserSellerId();
    this.cookieAuthService.setCompanyObject();
  }
}
