import { gql } from "apollo-angular";
import { PAYMENT_INVOICE_OBJECT, PAYMENT_PURCHASES_OBJECT, TRANSFER_OBJECT } from "./fragment";

export const GET_DAILY_INVOICES_AND_PAYMENTS = gql`
  query getDailyInvoicesAndPayments(
    $id_payment: [Int]
    $initialDate: String
    $endDate: String
    $company: String
  ) {
    getDailyInvoicesAndPayments(
      id_payment: $id_payment
      initialDate: $initialDate
      endDate: $endDate
      company: $company
    ) {
      ...PayInvoiceObject
    }
  }
  ${PAYMENT_INVOICE_OBJECT}
`;

export const GET_DAILY_PURCHASES_AND_PAYMENTS = gql`
    query getDailyPurchasesAndPayments(
        $id_payment: [Int], 
        $initialDate: String, 
        $endDate: String, 
        $company: String
    ) {
        getDailyPurchasesAndPayments(
            id_payment: $id_payment, 
            initialDate: $initialDate, 
            endDate: $endDate, 
            company: $company
        ) {
            ...PayPurchasesObject
        }
    }
    ${PAYMENT_PURCHASES_OBJECT}
`;

export const GET_DAILY_TRANSFERS = gql`
    query getDailyTransfers(
        $id_payment: [Int], 
        $initialDate: String, 
        $endDate: String, 
        $company: String
    ) {
        getDailyTransfers(
            id_payment: $id_payment, 
            initialDate: $initialDate, 
            endDate: $endDate, 
            company: $company
        ) {
            ...TransferObject
        }
    }
    ${TRANSFER_OBJECT}
`;

export const GET_LAST_30DAYS_INVOICE_PAYMENT_ACCUMULATED_VALUE = gql`
  query getLast30DaysInvoicePaymentAccumulatedValue(
    $id_payment: [Int]
    $initialDate: String
    $company: String
  ) {
      getLast30DaysInvoicePaymentAccumulatedValue(
        id_payment: $id_payment
        initialDate: $initialDate
        company: $company
      )
  }
`;

export const GET_LAST_30DAYS_PURCHASE_PAYMENT_ACCUMULATED_VALUE = gql`
    query getLast30DaysPurchasePaymentAccumulatedValue(
        $id_payment: [Int], 
        $initialDate: String, 
        $company: String
    ) {
        getLast30DaysPurchasePaymentAccumulatedValue(
            id_payment: $id_payment, 
            initialDate: $initialDate, 
            company: $company
        )
    }
`;

export const GET_LAST_30DAYS_TRANSFER_ACCUMULATED_VALUE = gql`
    query getLast30DaysTransferAccumulatedValue(
        $id_payment: [Int], 
        $initialDate: String, 
        $company: String
    ) {
        getLast30DaysTransferAccumulatedValue(
            id_payment: $id_payment, 
            initialDate: $initialDate, 
            company: $company
        )
    }
`;
