import { Component, Input, OnInit } from "@angular/core";
import { IAccountingAccounts } from "src/app/@interfaces/accountingAccounts.interface";
import { ReportsService } from "src/app/@pages/reports/reports.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-modify-accounting-accounts",
  templateUrl: "./modify-accounting-accounts.component.html",
  styleUrls: ["./modify-accounting-accounts.component.css"],
})
export class ModifyAccountingAccountsComponent implements OnInit {
  @Input() id_account = 0;
  accountingAccount = {} as IAccountingAccounts;
  unsubscribe$ = new Subject();
  modifyForm: UntypedFormGroup = this.fb.group({
    document_type: ["", [Validators.required]],
    inventory: ["", [Validators.required]],
    service: ["", [Validators.required]],
    tax: ["", [Validators.required]],
    withholding: ["", [Validators.required]],
    ica: ["", [Validators.required]],
    total: ["", [Validators.required]],
  });

  constructor(
    private reportsService: ReportsService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.reportsService
    .getAccountingAccountById(this.id_account)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.accountingAccount = result;
      this.setForm(this.accountingAccount);
    });
  }

  setForm(accountingAccount: IAccountingAccounts) {
    this.modifyForm.patchValue({
      document_type: this.translate.instant(accountingAccount.document_type),
      inventory: accountingAccount.inventory,
      service: accountingAccount.service,
      tax: accountingAccount.tax,
      withholding: accountingAccount.withholding,
      ica: accountingAccount.ica,
      total: accountingAccount.total,
    });
  }

  update() {
    if (this.modifyForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Please, fill in all the required fields!"),
      });
      return;
    } else {
      this.updateData();
    }
  }

  updateData() {
    this.reportsService
    .modifyAccountingAccount(
      this.id_account,
      this.modifyForm.value.inventory,
      this.modifyForm.value.service,
      this.modifyForm.value.tax,
      this.modifyForm.value.withholding,
      this.modifyForm.value.ica,
      this.modifyForm.value.total
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((res) => {
      Swal.fire(
        this.translate.instant("Good"),
        this.translate.instant("updated successfully"),
        "success"
      );
    },(error) => {
      Swal.fire(
        "Error",
        this.translate.instant("something was wrong"),
        "error"
      );
    });
  }

  textValid(text: string) {
    return (
      this.modifyForm.get(text)?.invalid && this.modifyForm.get(text)?.touched
    );
  }
}
