import { Pipe, PipeTransform } from '@angular/core';
import { IPurchaseInvoice } from 'src/app/@interfaces/purchaseInvoice.interface';

@Pipe({
  name: 'filterDPurchase'
})
export class FilterDPurchase implements PipeTransform {
  transform(purchases: IPurchaseInvoice[], date1: Date, date2: Date): IPurchaseInvoice[] {
    if (!purchases || (!date1 && !date2)) {
      return purchases;
    }
    return purchases.filter(purchase => 
        (new Date(purchase.createdAt!) >= date1 &&
        new Date(purchase.createdAt!) <= date2)
    )
  }
}
