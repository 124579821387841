import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper.component';
import { MaterialModule } from 'src/app/material.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [StepperComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MatIconModule,
    TranslateModule,
    FlexLayoutModule
  ],
  exports: [
    StepperComponent
  ]
})
export class StepperModule { }
