import { Component, OnChanges, SimpleChanges, Input } from "@angular/core";
import { DataItem, Service } from "./stacked-bar.service";

@Component({
  selector: "app-stacked-bar",
  providers: [Service],
  templateUrl: "./stacked-bar.component.html",
  styleUrls: ["./stacked-bar.component.css"],
})
export class StackedBarComponent implements OnChanges {
  dataSource?: DataItem[];
  colors: string[] = ["#6babac", "#e55253"];
  service: Service;
  isFirstLevel: boolean = true;
  @Input() data: DataItem[] = [];

  constructor(service: Service) {
    this.service = service;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let item in changes) {
      if (item === "data") {
        const values: Array<DataItem> = changes[item].currentValue;
        if (values.length > 0) {
          this.dataSource = this.service.filterData("", values);
        }
      }
    }
  }

  onButtonClick(data: DataItem[]) {
    if (!this.isFirstLevel) {
      this.isFirstLevel = true;
      this.dataSource = this.service.filterData("", data);
    }
  }

  onPointClick(e: any, data: DataItem[]) {
    if (this.isFirstLevel) {
      this.isFirstLevel = false;
      this.dataSource = this.service.filterData(
        e.target.originalArgument,
        data
      );
    }
  }

  customizePoint = () => {
    let pointSettings: any;
    pointSettings = {
      color: this.colors[Number(this.isFirstLevel)],
    };
    if (!this.isFirstLevel) {
      pointSettings.hoverStyle = {
        hatching: "none",
      };
    }
    return pointSettings;
  };

  customizeTooltip(arg: any) {
    return {
      text: `Item: ${arg.argument} -  Value: ${arg.value}`,
    };
  }
}
