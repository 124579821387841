import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { NgxSpinnerService } from "ngx-spinner";
import * as XLSX from "xlsx";
import { MatDialog } from "@angular/material/dialog";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SellersService } from "../../../../@pages/sellers/sellers.service";
import { ISeller } from "src/app/@interfaces/seller.interface";
import { startWith, map, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { ReportsService } from "src/app/@pages/reports/reports.service";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { INotas } from "src/app/@interfaces/notas.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

export interface Data {
  code: string;
  description: string;
  presentation: number;
  values: number[];
  total: number;
}

@Component({
  selector: "app-export-excel",
  templateUrl: "./export-excel.component.html",
  styleUrls: ["./export-excel.component.css"],
})
export class ExportExcelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() quotationList: Array<IQuota> = [];
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
    seller: new UntypedFormControl(),
  });
  sellerForm: UntypedFormControl = this.fb.control("", Validators.required);
  name = "Report.xlsx";
  arrayExcel: Data[] = [];
  sellerList: Array<ISeller> = [];
  filteredOptionsSeller: Observable<ISeller[]> | undefined;
  arrayCustomers: string[] = [];
  arrayTotals: number[] = [];
  total: number = 0;
  companyName = '';
  unsubscribe$ = new Subject();
  notes: Array<INotas> = [];

  constructor(
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private seller: SellersService,
    private fb: UntypedFormBuilder,
    private reportsService: ReportsService,
    private notesService: QuotationsService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
    this.spinner.hide();
    this.seller
      .listSellers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.sellerList = result;
      });

    this.filteredOptionsSeller = this.sellerForm.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );

    this.notesService
      .getNotes()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.notes = result;
      });
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyName = company!.name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let item in changes) {
      if (item === "quotationList") {
        const values: Array<IQuota> = changes[item].currentValue;
        if (values.length > 0) {
          this.quotationList = values;
        }
      }
    }
  }

  clean() {
    this.range.reset();
    this.sellerForm.setValue("");
  }

  filter(quots: IQuota[]) {
    let quotss: IQuota[] = [];
    let start: Date | null;
    let end: Date | null;
    const sellerid = this.sellerForm.value.id_seller;
    if (
      this.range.get("start")?.value !== null &&
      this.range.get("end")?.value !== null
    ) {
      start = new Date(
        this.range.get("start")?.value.getFullYear().toString() +
          "-" +
          (this.range.get("start")?.value.getMonth() + 1).toString() +
          "-" +
          this.range.get("start")?.value.getDate().toString()
      );
      end = new Date(
        this.range.get("end")?.value.getFullYear().toString() +
          "-" +
          (this.range.get("end")?.value.getMonth() + 1).toString() +
          "-" +
          this.range.get("end")?.value.getDate().toString()
      );
      end.setHours(23, 59, 59);
    } else {
      start = null;
      end = null;
    }
    if (start !== null && end !== null && sellerid !== undefined) {
      quotss = quots.filter(
        (data) =>
          new Date(data.createdAt) >= start! &&
          new Date(data.createdAt) <= end! &&
          data.id_seller?.toString() === sellerid
      );
    } else if (sellerid !== undefined) {
      quotss = quots.filter((data) => data.id_seller?.toString() === sellerid);
    } else if (start !== null && end !== null) {
      quotss = quots.filter(
        (data) =>
          new Date(data.createdAt) >= start! && new Date(data.createdAt) <= end!
      );
    } else {
      quotss = quots;
    }
    this.calcData(quotss);
  }

  calcData(quotss: IQuota[]) {
    this.arrayExcel = [];
    this.arrayTotals = [];
    this.total = 0;
    let allCustomers: string[] = [];
    let allArticles: string[] = [];
    let fullArticles: any[] = [];
    let arrayAnt: any[] = [];
    quotss.forEach((quot) => {
      let invoiceNote = this.notes.find(
        (note) => note.note_fact === parseInt(quot.id_factura!.toString())
      );
      if (!invoiceNote) {
        let client = "";
        let quantity = 0;
        let code = "";
        let description = "";
        let presentation = 0;
        quot.cliente.forEach((customer) => {
          allCustomers.push(customer.nombre);
          quot.venta.forEach((sale) => {
            sale.articulo!.forEach((article) => {
              allArticles.push(article.codigo!);
              client = customer.nombre;
              quantity = sale.cantidad;
              code = article.codigo!;
              description = article.descripcion;
              presentation = parseFloat(article.presentacion!);
              fullArticles.push({
                code: code,
                description: description,
                presentation: presentation,
              });
              arrayAnt.push({
                customer: client,
                quantity: quantity,
                code: code,
                description: description,
                presentation: presentation,
              });
            });
          });
        });
      }
    });

    this.arrayCustomers = allCustomers.filter((item, index) => {
      return allCustomers.indexOf(item) === index;
    });

    const arrayArticles = allArticles.filter((item, index) => {
      return allArticles.indexOf(item) === index;
    });

    const setObj = new Set();
    const arrayFullArticles: any[] = fullArticles.reduce((acc, article) => {
      const key = JSON.stringify(article);
      if (!setObj.has(key)) {
        setObj.add(key);
        setObj.add(article);
        acc.push(article);
      }
      return acc;
    }, []);

    let quantsCustomer = {} as any;
    let arrayQuantsCustomer: any[] = [];

    this.arrayCustomers.forEach((customer) => {
      const filterByCustomer = arrayAnt.filter(
        (dato: any) => dato.customer === customer
      );
      arrayArticles.forEach((article) => {
        let sumQuants = 0;
        let code = "";
        let description = "";
        let presentation = "";
        let client = "";
        const filterByArticle = filterByCustomer.filter(
          (dato: any) => dato.code === article
        );
        const filterFullArticles = arrayFullArticles.filter(
          (dato: any) => dato.code === article
        );
        filterByArticle.forEach((element) => {
          client = element.customer;
          code = element.code;
          description = element.description;
          presentation = element.presentation;
          sumQuants += element.quantity;
        });
        filterFullArticles.forEach((data) => {
          if (customer !== client) {
            quantsCustomer = {
              client: customer,
              code: data.code,
              description: data.description,
              presentation: data.presentation,
              sumQuants: 0,
            };
          } else {
            quantsCustomer = {
              client: client,
              code: code,
              description: description,
              presentation: presentation,
              sumQuants: sumQuants,
            };
          }
        });
        if (quantsCustomer.client !== "") {
          arrayQuantsCustomer.push(quantsCustomer);
        }
      });
    });

    let quantsArticle = {} as any;
    arrayArticles.forEach((article) => {
      const filterByArticle = arrayQuantsCustomer.filter(
        (dato: any) => dato.code === article
      );
      let code = "";
      let description = "";
      let presentation = "";
      let value: number[] = [];
      let total = 0;
      filterByArticle.forEach((element) => {
        code = element.code;
        description = element.description;
        presentation = element.presentation;
        value.push(element.sumQuants);
      });
      total = value.reduce((previus, current) => previus + current, 0);
      quantsArticle = {
        code: code,
        description: description,
        presentation: presentation,
        values: value,
        total: total,
      };
      this.arrayExcel.push(quantsArticle);
    });

    this.arrayCustomers.forEach((customer, index) => {
      let sum = 0;
      this.arrayExcel.forEach((element) => {
        sum += element.values[index];
      });
      this.arrayTotals.push(sum);
    });
    this.total = this.arrayTotals.reduce(
      (previus, current) => previus + current,
      0
    );
  }

  exportExcel(quots: IQuota[]) {
    this.spinner.show();
    this.filter(quots);
    const element = document.getElementById("season-tble");
    const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const book: XLSX.WorkBook = XLSX.utils.book_new();
    this.reportsService.export(book, worksheet, this.name);
  }

  calcAllLiters(): string {
    let total = 0;
    const cell = document.querySelectorAll("#liters");
    for (let i = 0; i < cell.length; i++) {
      total += parseFloat(cell[i].firstChild!.nodeValue!);
    }
    return total.toFixed(2);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  displayFnSeller(seller: any) {
    return seller && seller ? seller.nombre : undefined;
  }

  private _filter(value: string): ISeller[] {
    const filterValue = value.toString().toLowerCase();
    return this.sellerList.filter((option) =>
      option.nombre.toLowerCase().toString().includes(filterValue)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
