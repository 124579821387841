import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { DxBulletComponent } from "devextreme-angular";
import { EventEmitterService } from "../../../../@core/menu/emitterFuncions.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-bullet",
  templateUrl: "./bullet.component.html",
  styleUrls: ["./bullet.component.css"],
})
export class BulletComponent implements OnInit {
  @Input() value: number = 0;
  @Input() target: number = 0;
  @Input() color: string = "";
  @ViewChild("bullet", { static: false }) dataBullet:
    | DxBulletComponent
    | undefined;
  unsuscribe$ = new Subject();

  constructor(private eventEmitterService: EventEmitterService) {}

  ngOnInit(): void {
    this.getEvent();
  }

  getEvent() {
    this.eventEmitterService.invokeRenderBullet
    .pipe(takeUntil(this.unsuscribe$))
    .subscribe((res) => {
      setTimeout(() => {
        this.render();
      }, 250);
    });
    this.eventEmitterService.invokeFirstComponentFunction
    .pipe(takeUntil(this.unsuscribe$))
    .subscribe((res) => {
      setTimeout(() => {
        this.render();
      }, 250);
    });
  }

  render() {
    if (this.dataBullet?.instance) {
      this.dataBullet?.instance.render(); 
    }
  }
}
