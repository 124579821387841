import { Pipe, PipeTransform } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Pipe({
  name: 'tableIds'
})
export class TableIdPerPagePipe implements PipeTransform {

  transform(data: any[], paginator: PageEvent): any[] | null {
    const currentPage = paginator.pageIndex + 1;
    const rangeToCalculate = paginator.pageSize * currentPage;
    const range = [...Array(rangeToCalculate + 1).keys()]
    const ids = range.slice(Math.max(range.length - paginator.pageSize, 0))

    const dataWithIds = data.map((data: any, index: number) => {
      return { ...data, id: ids[index] }
    })
    if (dataWithIds.length === 0) return null;
    return dataWithIds
  }

}
