<mat-dialog-content>
    <h2><strong>{{'Accounting Accounts List' | translate}}</strong></h2>
    <form>
        <table *ngIf="accountsList.length > 0" class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>
                        <mat-label>Item</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Document Type' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Inventory' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Service' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Tax' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Withholding Tax' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Ica' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Total' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Options' | translate}}</mat-label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let list of accountsList; let i = index">
                    <td data-label="Item">
                        {{i + 1}}
                    </td>
                    <td data-label="Document Type">
                        {{list.document_type | translate}}
                    </td>
                    <td data-label="Inventory">
                        {{list.inventory}}
                    </td>
                    <td data-label="Service">
                        {{list.service}}
                    </td>
                    <td data-label="Tax">
                        {{list.tax}}
                    </td>
                    <td data-label="Withholding">
                        {{list.withholding}}
                    </td>
                    <td data-label="Ica">
                        {{list.ica}}
                    </td>
                    <td data-label="Total">
                        {{list.total}}
                    </td>
                    <td data-label="Options">
                        <a mat-dialog-close class="goLink" (click)="update(list.id_account)">
                            <mat-icon>mode</mat-icon>
                        </a>
                    </td>
                </tr>       
            </tbody>
        </table>
        <div *ngIf="accountsList.length <= 0" class="alert alert-danger">
            {{'NO RECORDS FOUND' | translate}}
        </div><br>
        <div>
            <button class="close-button" mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
        </div>
    </form>
</mat-dialog-content>