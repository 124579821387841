<mat-dialog-content>
    <h2><strong>{{'Bank List' | translate}}</strong></h2>
    <form>
        <table *ngIf="listBanks.length > 0" class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>
                        <mat-label>Item</mat-label>
                    </th>
                    <th>
                        <mat-label>ID</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Bank' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'User' | translate}}</mat-label>
                    </th>
                    <th>
                        <mat-label>{{'Options' | translate}}</mat-label>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let banks of listBanks; let i = index">
                    <td data-label="Item">
                        {{i + 1}}
                    </td>
                    <td data-label="ID">
                        {{banks.id_payment}}
                    </td>
                    <td data-label="Bank">
                        {{banks.description}}
                    </td>
                    <td data-label="User">
                        {{getUser(userList, banks.id_user!)}}
                    </td>
                    <td data-label="Options">
                        <button disabled (click)="remove(banks.id_payment, i)" mat-raised-button>
                             x
                        </button>
                    </td>
                </tr>       
            </tbody>
        </table><br>
        <div *ngIf="listBanks.length <= 0" class="alert alert-danger">
            {{'NO RECORDS FOUND' | translate}}
        </div><br>
        <form [formGroup]="registerForm">
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th>
                            <mat-label>{{'Add Banks' | translate}}</mat-label>
                        </th>
                        <th>
                            <mat-label>{{'User' | translate}}</mat-label>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="Add Banks">
                            <input style="width: 400px;" class="form-control" type="text" formControlName="bank">
                            <span class="form-text text-danger" *ngIf="textValid('bank')">{{'Bank invalid' | translate}}</span>
                        </td>
                        <td data-label="User">
                            <select class="form-control" formControlName="user">
                                <option selected value="">{{'Select User' | translate}}</option>
                                <option *ngFor="let user of userList" [ngValue]="user">
                                    {{user.name}}
                                </option>
                            </select>
                            <span class="form-text text-danger" *ngIf="textValid('user')">{{'You must select a type' | translate}}</span>
                        </td>
                    </tr>
                </tbody>
            </table><br>
            <button mat-raised-button (click)="update()" style="margin-left: 20px; margin-bottom: 20px;">{{'Add' | translate}}</button>
            <button mat-raised-button mat-dialog-close style="margin-left: 20px; margin-bottom: 20px">{{'Close' | translate}}</button>
        </form>
    </form>
</mat-dialog-content>

