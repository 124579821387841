<div class="div_buttons">
    <a><button mat-raised-button class="Warn" (click)="openDialog()">{{'Register' | translate}}</button></a>

    <button [disabled]="disableButton" mat-raised-button [matMenuTriggerFor]="menu">{{'Details' | translate}}  <mat-icon class="black-icon">add_circle</mat-icon></button>
      <mat-menu #menu="matMenu">
        <a (click)="openDialogSegments(true, customerSegmentList)"><button mat-menu-item class="Warn">{{'Segments' | translate}}</button></a>
      </mat-menu>
      <button mat-raised-button (click)="toggleDataDisplay()">
        {{ getLabelButton | translate}} 
      </button>
</div>

<div *ngIf="CustomerList.length > 0; else noData">
  <app-table (dataUpdated)="loadNewData($event)" [customer]="CustomerList" [showProspects]="showProspects" [showTable]=2 [length]="length"></app-table>
</div>

<ng-template #noData>
  <div class="alert alert-danger">
      {{ 'NO RESULTS FOR THIS SEARCH' | translate}}
  </div>
</ng-template>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
