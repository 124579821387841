import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/@shared/authentication/auth.service";
import { DialogComponent } from "src/app/@shared/components/dialog/dialog.component";
import { NgxSpinnerService } from "ngx-spinner";
import { ISeller } from "../../@interfaces/seller.interface";
import { SellersService } from "./sellers.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";

@Component({
  selector: "app-sellers",
  templateUrl: "./sellers.component.html",
  styleUrls: ["./sellers.component.css"],
})
export class SellersComponent implements OnInit, OnDestroy {
  SellerList: Array<ISeller> = [];
  companyId = '';
  unsubscribe$ = new Subject();
  constructor(
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private seller: SellersService,
    private authService: AuthService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.authService.checkAuthStatus();
    this.spinnerService.show();
    this.getAuthValues();
    this.seller
    .listSellers()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.SellerList = result;
      this.spinnerService.hide();
    });
  }

  getAuthValues() {
    const company = this.cookieAuthService.getCompanyObject;
    this.companyId = company!.Id_company!.toString();
  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      data: { registerSeller: true },
    });
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
