<dx-tree-map
  id="chart"
  [dataSource]="drillData"
  [interactWithGroup]="true"
  [maxDepth]="2"
  (onClick)="nodeClick($event)"
  (onDrill)="drill($event)"
>
  <dxo-colorizer palette="soft"></dxo-colorizer>
  <!-- <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" [zIndex]="5000">
  </dxo-tooltip> -->
</dx-tree-map>

<div id="drill-down-title">
  <span *ngFor="let drillInfo of drillInfos; let isLast = last">
    <span
      [ngClass]="{ link: !!drillInfo.node }"
      (click)="drillInfoClick(drillInfo.node)"
      >{{ drillInfo.text }}</span
    >
    {{ isLast ? "" : " > " }}
  </span>
</div>
