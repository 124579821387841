import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormArray,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ConverseFilesService } from "../../../services/files/converse-files.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../../dialog.component";
import { isArray } from "@apollo/client/cache/inmemory/helpers";
import { ProviderService } from "../../../../../@pages/provider/provider.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { InventoryService } from "../../../../../@pages/inventory/inventory.service";
import { IArticles } from "../../../../../@interfaces/article.interface";
import { IProvider } from "../../../../../@interfaces/provider.interface";
import Swal from "sweetalert2";
import { RegisterPurchaseService } from "../register-purchase/register-purchase.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { IStorageCompany } from "src/app/@interfaces/company.interface";

@Component({
  selector: "app-registration-mode",
  templateUrl: "./registration-mode.component.html",
  styleUrls: ["./registration-mode.component.css"],
})
export class RegistrationModeComponent implements OnInit, OnDestroy {
  uvtValue = 0;
  registerForm: UntypedFormGroup = this.fb.group({ XML: [] });
  newProvider: UntypedFormControl = this.fb.control("");
  purchaseForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array([
      this.fb.group({
        warehouse: [0],
        article: [],
        quantity: [0],
        price: [0],
        discount: [0],
        subtotal: [0],
      }),
    ]),
    written_invoice: [""],
    tax_include: false,
    clause: false,
    tax: [0],
    date: [],
    dueDate: [],
  });
  get rowsArr() {
    return this.purchaseForm.get("itemRows") as UntypedFormArray;
  }
  totalPurchase = 0;
  totalTax = 0;
  totalWithoutTax = 0;
  withholdingTax = 0;
  customerCity = "";
  providerCity = "";
  icaTax = 0;
  XMLfile: any;
  previsualize?: string;
  unsubscribe$ = new Subject();
  companyObject = {} as IStorageCompany;
  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private converseFilesService: ConverseFilesService,
    private dialog: MatDialog,
    private providerService: ProviderService,
    private inventoryService: InventoryService,
    private registerPurchaseService: RegisterPurchaseService,
    private cookieAuthService: CookieAuthService
  ) {}

  ngOnInit(): void {
    this.getAuthValues();
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
  }

  async getFile(event: any) {
    this.purchaseForm.reset();
    this.newProvider.reset();
    this.totalPurchase = 0;
    this.totalTax = 0;
    const fileTransformed = await this.converseFilesService
    .transformFile(event, "text/xml", true)
    .catch((err) => {
      if (err) {
        this.registerForm.get("XML")?.reset();
      }
    });
    if (fileTransformed) {
      Swal.fire(
        this.translate.instant("Warning"),
        this.translate.instant(
          `For the correct operation of this functionality, remember that both the supplier and the items in the purchase must be correctly registered in the system.`
        ) +
          `<br>` +
          this.translate.instant(
            `If not, you will have to correct some data in the purchase screen.`
          ),
        "warning"
      ).then((result) => {
        if (result.isConfirmed) {
          this.XMLfile = fileTransformed;
          this.destructObjectFromXmlFile(fileTransformed);
        }
      });
    }
  }

  destructObjectFromXmlFile(fileTransformed: any) {
    const jsonObject = this.converseFilesService.xmlToJs(atob(fileTransformed));
    const attachedDescription =
      jsonObject.AttachedDocument["cac:Attachment"]["cac:ExternalReference"][
        "cbc:Description"
      ];
    const jsonObjectInvoice = this.converseFilesService.xmlToJs(
      attachedDescription["cbc:Description"]
    );
    const jsonObjectItems = jsonObjectInvoice.Invoice["cac:InvoiceLine"];
    const jsonObjectProvider =
      jsonObjectInvoice.Invoice["cac:AccountingSupplierParty"]["cac:Party"][
        "cac:PartyLegalEntity"
      ];
    this.setPurchase(jsonObjectInvoice, jsonObjectProvider, jsonObjectItems);
  }

  async setPurchase(invoice: any, provider: any, items: any) {
    this.setInvoiceInfo(invoice);
    this.setItems(items);
    const invoiceCities =
      this.registerPurchaseService.getInvoiceCities(invoice);
    this.customerCity = invoiceCities.customerCity;
    this.providerCity = invoiceCities.providerCity;
    let providerObject: IProvider;
    const providerArray = await this.searchProvider(provider);
    if (providerArray.length > 0) {
      providerObject = providerArray[0];
    } else {
      providerObject = { nombre: this.translate.instant("NO PROVIDER FOUND") };
    }
    this.newProvider.setValue(providerObject);
    if (this.companyObject.countries[0].name === "Colombia") {
      this.withholdingTax =
        this.registerPurchaseService.calculationWithholdingTax(
          this.newProvider,
          this.totalWithoutTax,
          this.uvtValue,
          this.rowsArr,
          false
        );
      const resultCalculateIca =
        await this.registerPurchaseService.calculationIcaTax(
          this.customerCity,
          this.providerCity,
          this.newProvider,
          this.totalWithoutTax,
          this.uvtValue,
          this.rowsArr
        );
      this.icaTax = resultCalculateIca.icaTax;
      this.customerCity = resultCalculateIca.city;
    }
  }

  setInvoiceInfo(invoice: any) {
    const invoiceNumber = invoice.Invoice["cbc:ID"]["cbc:ID"];
    const dueDate = this.validDueDate(invoice.Invoice);
    const createdDate = invoice.Invoice["cbc:IssueDate"]["cbc:IssueDate"];
    const totalPurchase =
      invoice.Invoice["cac:LegalMonetaryTotal"]["cbc:TaxInclusiveAmount"][
        "cbc:TaxInclusiveAmount"
      ];
    const totalWithoutTax =
      invoice.Invoice["cac:LegalMonetaryTotal"]["cbc:TaxExclusiveAmount"][
        "cbc:TaxExclusiveAmount"
      ];
    this.totalWithoutTax = parseFloat(totalWithoutTax);
    this.totalPurchase = parseFloat(totalPurchase);
    this.purchaseForm.patchValue({
      written_invoice: invoiceNumber,
      tax: 0,
      date: this.transformDate(createdDate),
      dueDate: this.transformDate(dueDate),
      tax_include: false,
      clause: false
    });
  }

  validDueDate(invoice: any) {
    let dueDate: string;
    if (invoice["cac:PaymentMeans"]["cbc:PaymentDueDate"]) {
      dueDate =
        invoice["cac:PaymentMeans"]["cbc:PaymentDueDate"]["cbc:PaymentDueDate"];
    } else {
      dueDate = invoice["cbc:IssueDate"]["cbc:IssueDate"];
    }
    return dueDate;
  }

  transformDate(date: string): string {
    const actualDate = new Date();
    const splitDate = date.split("-");
    const dateObject = new Date(
      parseInt(splitDate[0]),
      parseInt(splitDate[1]) - 1,
      parseInt(splitDate[2])
    );
    dateObject.setHours(actualDate.getHours());
    return dateObject.toISOString();
  }

  searchProvider(provider: any) {
    const idProvider = provider["cbc:CompanyID"]["cbc:CompanyID"];
    return new Promise<IProvider[]>((resolve, reject) => {
      this.providerService
      .searchProvider(idProvider.toString(), null, null)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result.providers);
      });
    });
  }

  async setItems(items: any) {
    if (isArray(items)) {
      this.purchaseForm.setControl(
        "itemRows",
        await this.setItemsFromArray(items)
      );
    } else {
      this.purchaseForm.setControl(
        "itemRows",
        await this.setItemsFromObject(items)
      );
    }
  }

  async setItemsFromArray(items: any) {
    const formArray = new UntypedFormArray([]);
    for (const item of items) {
      formArray.push(await this.setItemObject(item));
    }
    if (this.companyObject.countries[0].name === "Colombia") {
      const baseTax = await this.registerPurchaseService.getTaxBase();
      this.uvtValue = baseTax[0].value;
    }
    return formArray;
  }

  async setItemsFromObject(item: any) {
    const formArray = new UntypedFormArray([]);
    formArray.push(await this.setItemObject(item));
    if (this.companyObject.countries[0].name === "Colombia") {
      const baseTax = await this.registerPurchaseService.getTaxBase();
      this.uvtValue = baseTax[0].value;
    }
    return formArray;
  }

  async setItemObject(item: any) {
    let taxAmount;
    const idItem =
      item["cac:Item"]["cac:StandardItemIdentification"]["cbc:ID"]["cbc:ID"];
    const descriptionItem =
      item["cac:Item"]["cbc:Description"]["cbc:Description"];
    const article = await this.setArticle(
      idItem.toString(),
      descriptionItem.toString()
    );
    const price = item["cac:Price"]["cbc:PriceAmount"]["cbc:PriceAmount"];
    const quantity = parseInt(
      item["cac:Price"]["cbc:BaseQuantity"]["cbc:BaseQuantity"]
    );
    const discount = 0;
    if (item["cac:TaxTotal"]) {
      taxAmount = item["cac:TaxTotal"]["cbc:TaxAmount"]["cbc:TaxAmount"];
    } else {
      taxAmount = "0";
    }
    this.totalTax = this.totalTax + parseFloat(taxAmount);
    const subtotal =
      parseFloat(taxAmount) +
      parseFloat(item["cbc:LineExtensionAmount"]["cbc:LineExtensionAmount"]);
    return this.fb.group({
      warehouse: article[0].almacen![0],
      article: article,
      quantity: quantity,
      discount: discount,
      price: price,
      subtotal: subtotal,
    });
  }

  async setArticle(idItem: string, descriptionItem: string) {
    let article: IArticles[];
    const articleByProviderId = await this.searchItemByProviderCode(
      idItem.toString()
    );
    if (articleByProviderId.length > 0) {
      article = articleByProviderId;
    } else {
      const articleById = await this.searchItem(idItem.toString());
      if (articleById.length > 0) {
        article = articleById;
      } else {
        const articleByDescription = await this.searchItem(
          descriptionItem.toString()
        );
        if (articleByDescription.length > 0) {
          article = articleByDescription;
        } else {
          article = [
            {
              id_articulo: 0,
              descripcion: this.translate.instant("NO ARTICLE FOUND"),
              tax: [{ value: 0 }],
              almacen: [{id_almacen: 0, nombre: 'NO WAREHOUSE FOUND', estado: 0}]
            },
          ];
        }
      }
    }
    return article;
  }

  searchItem(value: string) {
    return new Promise<IArticles[]>((resolve, reject) => {
      this.inventoryService
      .searchArticle(value, null, null, null)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: { count:number, articles: IArticles[]}) => {
        resolve(result.articles);
      });
    });
  }

  searchItemByProviderCode(value: string) {
    return new Promise<IArticles[]>((resolve, reject) => {
      this.inventoryService
      .searchArticleByProviderCodes(value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: IArticles[]) => {
        resolve(result);
      });
    });
  }

  async openDialogWithXml(registerPurchase: boolean): Promise<void> {
    const citiesParsed = await this.registerPurchaseService.converseCities(
      this.customerCity,
      this.providerCity
    );
    const totalTax = parseInt(this.newProvider.value.contributorType.id_contributor) === 4 ? 0 : this.totalTax;
    this.dialog.open(DialogComponent, {
      data: {
        registerPurchase: registerPurchase,
        XMLfile: this.XMLfile,
        purchaseForm: this.purchaseForm,
        newProvider: this.newProvider,
        totalPurchase: this.totalPurchase,
        totalTax: totalTax,
        totalWithoutTax: this.totalWithoutTax,
        withholdingTax: this.withholdingTax,
        icaTax: this.icaTax,
        customerCity: citiesParsed.parsedCustomerCity,
        providerCity: citiesParsed.parsedProviderCity,
        uvtValue: this.uvtValue,
      },
      disableClose: true,
    });
  }

  openDialogWithoutXml(registerPurchase: boolean): void {
    this.dialog.open(DialogComponent, {
      data: { registerPurchase: registerPurchase },
      disableClose: true,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
