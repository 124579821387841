import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemittanceComponent } from './remittance.component';
import { RemittanceRoutingModule } from './remittance-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ListModule } from 'src/app/@shared/components/list/list.module';

@NgModule({
  declarations: [RemittanceComponent],
  imports: [
    CommonModule,
    RemittanceRoutingModule,
    ReactiveFormsModule,
    MatIconModule,
    MaterialModule,
    TranslateModule,
    NgxSpinnerModule,
    ListModule
  ],
  exports: [
    RemittanceComponent
  ]
})
export class RemittanceModule { }
