<div style="float:left; padding: 25px 0px 0px 10px">
    <a><button mat-raised-button class="Warn" (click)="export()">{{'Report' | translate}}</button></a>
</div>

<div class="div-filters" fxLayout="row" fxLayoutAlign="center center">
    <ng-container *ngIf="rol === '1' || rol === '3' " >
        <div>
            <form [formGroup]="registerForm">
                <div>                            
                    <input class="form-control" formControlName='seller' name="seller" placeholder="{{'Filter by seller' | translate}}" 
                    [matAutocomplete]="autoSeller"  style="width: 200px;">
                        <mat-autocomplete #autoSeller="matAutocomplete" [displayWith]="displayFnSeller">
                            <mat-option  *ngFor="let seller of sellerList"
                                [value]="seller">
                                {{seller.nombre}}
                            </mat-option>
                        </mat-autocomplete>
                </div>
                <div>                            
                    <input class="form-control" formControlName='customer' name="customer" placeholder="{{'Filter by customer' | translate}}" 
                    [matAutocomplete]="autoCustomer"  style="width: 200px;">
                        <mat-autocomplete #autoCustomer="matAutocomplete" [displayWith]="displayFnSeller">
                            <mat-option  *ngFor="let customer of customerList"
                                [value]="customer">
                                {{customer.nombre}}
                            </mat-option>
                        </mat-autocomplete>
                </div>
                <div>
                    <mat-form-field appearance="fill" >
                        <mat-label>{{'Select year' | translate}}</mat-label>
                        <mat-select formControlName="year" (selectionChange)="data($event.value)">
                          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>
        
        <div>
            <button class="buttons" (click)="update(invoices, notes, payments)" style="margin-left: 20px;" mat-raised-button>{{'Update' | translate}}</button>
            <button class="buttons" (click)="reset(invoices, notes, payments);" style="margin-left: 20px;" mat-raised-button>{{'Reset' | translate}}</button>
        </div>
    </ng-container>
    <ng-container *ngIf="rol === '2'" >
        <form [formGroup]="registerForm">
            <div>
                <mat-form-field appearance="fill" >
                    <mat-label>{{'Select year' | translate}}</mat-label>
                    <mat-select formControlName="year" (selectionChange)="data($event.value)">
                      <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>

    </ng-container>
</div>

<div *ngIf="showTable === true">
    <table class="table table-bordered">
        <thead class="thead-dark">
            <tr>
                <th>
                    <mat-label>No.</mat-label>
                </th>
                <th [appSort]="dataTable" data-order="asc" data-name="code">
                    <mat-label>{{'Code' | translate}}</mat-label>
                </th>
                <th [appSort]="dataTable" data-order="asc" data-name="customer">
                    <mat-label>{{'Name' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Seller' | translate}}</mat-label>
                </th>
                <th [appSort]="dataTable" data-order="asc" data-name="total">
                    <mat-label>{{'Saldo' | translate}}</mat-label>
                </th>
                <th [appSort]="dataTable" data-order="asc" data-name="daysPastDue">
                    <mat-label>{{'Days past due' | translate}}</mat-label>
                </th>
                <th>
                    <mat-label>{{'Options' | translate}}</mat-label>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of dataTable; let i = index">
                <td>
                    {{i + 1}}
                </td>
                <td>
                    {{item.code}}
                </td>
                <td>
                    {{item.customer}}
                </td>
                <td>
                    <ng-container *ngFor="let seller of item.seller">
                        ~ {{seller}} 
                    </ng-container>
                </td>
                <td>
                    {{item.total | thousandsPipe}}
                </td>
                <td>
                    {{item.daysPastDue}}
                </td>
                <td>
                    <a class="goLink" (click)="openDialog(item.details!)">
                        <mat-icon>list_alt</mat-icon>
                    </a>
                </td>
            </tr>
            <tr>
                <td colspan="4"></td>
                <td colspan="1">{{'Total: '}} {{total}}</td>
                <td colspan="1">
                    <span data-toggle="tooltip" data-placement="top" title="DSO: Days Sales Outstanding: Días promedio entre emisión de factura y cobro">{{'DSO: '}} {{totalDSO}} {{' ----- '}}</span>
                    <span data-toggle="tooltip" data-placement="top" title="BPDSO: Best Possible Days Outstanding: Días promedio entre vencimiento de factura y cobro">{{'BPDSO: '}} {{totalBPDSO}}</span>
                </td>
                <td colspan="1" data-toggle="tooltip" data-placement="top" title="Average Due Date: Días promedio de cobro">{{'ADD: '}} {{totalADD}}</td>
            </tr>
        </tbody> 
    </table>
</div>

<div *ngIf="showTable === false">
    <div class="alert alert-danger">
        {{'NO RESULTS FOR THIS SEARCH' | translate}}
    </div>
</div>


<div *ngIf="dataTable.length > 0" hidden>
    <table id="season-tble" class="table table-striped">
      <tr>
        <th>{{'Numero' | translate}}</th>
        <th>{{'NIT' | translate}}</th>
        <th>{{'Cliente' | translate}}</th>
        <th>{{'Vendedor' | translate}}</th>
        <th>{{'Monto' | translate}}</th>
        <th>{{'Dias cartera' | translate}}</th>
        <th>{{'Emision' | translate}}</th>
        <th>{{'Vencimiento' | translate}}</th>
        <th>{{'Cancelado' | translate}}</th>
        <th>{{'Today' | translate}}</th>
        <th>{{'Dias' | translate}}</th>
        <th>{{'Participacion % Cartera' | translate}}</th>
        <th>{{'Participacion % Clientes' | translate}}</th>
        <th>{{'Dias Ponderado Cartera' | translate}}</th>
        <th>{{'Dias Ponderado Clientes' | translate}}</th>
      </tr>
      <ng-container *ngFor="let item of dataTable; let i = index">
        <tr>
            <td>{{i + 1}}</td>
            <td>{{item.code}}</td>
            <td>{{item.customer}}</td>
            <td>
                <ng-container *ngFor="let seller of item.seller">
                    ~ {{seller}} 
                </ng-container>
            </td>
            <td>{{item.total}}</td>
            <td>{{item.daysPastDue}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr *ngFor="let details of item.details">
            <td></td>
            <td>{{details.invoice_number}}</td>
            <td></td>
            <td></td>
            <td>{{details.totalPaid}}</td>
            <td></td>
            <td>{{details.createdDate | date : 'short'}}</td> 
            <td>{{details.dueDate | date : 'short'}}</td>
            <td>{{details.cancelledDate | date : 'short'}}</td>
            <td>{{date | date : 'short'}}</td>
            <td>{{details.daysBetweenDates}}</td>
            <td>{{details.participation}}</td>
            <td>{{details.participationByItem}}</td>
            <td>{{details.daysPastDue}}</td>
            <td>{{details.daysWeighted}}</td>
        </tr>
       </ng-container>
    </table>
  </div>

  <ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
  </ngx-spinner>

