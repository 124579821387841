import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsPayableComponent } from './accounts-payable.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { AccountsPayableRoutingModule } from './accounts-payable-routing.module';
import { SearchModule } from 'src/app/@shared/components/search/search.module'; 
import { CardModule } from 'src/app/@shared/components/card/card.module'; 
import { NgxSpinnerModule } from 'ngx-spinner';
import { PurchaseCardModule } from '../../@shared/components/purchase-card/purchase-card.module';
import { FilterPurchasePipe } from 'src/app/@shared/pipe/filter-purchases.pipe';


@NgModule({
  declarations: [
    AccountsPayableComponent
  ],
  imports: [
    CommonModule,
    AccountsPayableRoutingModule,
    ReactiveFormsModule,
    MatIconModule,
    DialogModule,
    MaterialModule,
    TranslateModule,
    SearchModule,
    CardModule,
    NgxSpinnerModule,
    PurchaseCardModule
  ],
  providers: [
    FilterPurchasePipe
  ]
})
export class AccountsPayableModule { }
