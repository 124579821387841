import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";
import { IDetails } from "src/app/@interfaces/details/details.interface";

@Component({
  selector: "app-detail-payment",
  templateUrl: "./detail-payment.component.html",
  styleUrls: ["./detail-payment.component.css"],
})
export class DetailPaymentComponent implements OnInit {
  @Input() details: IDetails[] = [];
  @Input() totalDSO = 0;
  @Input() totalBPDSO = 0;
  @ViewChild(MatAccordion) accordion?: MatAccordion;
  columnsNotes: string[] = ["code", "total"];
  columnsPays: string[] = ["identifier", "type", "date", "value"];
  constructor() {}

  ngOnInit(): void {}
}
