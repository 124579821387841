import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, take} from 'rxjs/operators';
import { IAuthResponse } from 'src/app/@interfaces/api-to-calculate/auth-response.interface';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiToCalculateService {
  private apiUrl: string = environment.api_to_calculate;
  private idUser: number = 0;
  private apiToken: string = "";
  private bodyApi!: object;

  constructor(private httpClient: HttpClient, private cookieAuthService: CookieAuthService) {
  }

  async setAuthApiProps() {
    this.idUser = parseInt(this.cookieAuthService.getUserId!);
    this.bodyApi = { id: this.idUser}
    await this.getAuthApiToken();
  }

  async getAuthApiToken() {
    return new Promise((resolve, reject) => {
      try {
        this.httpClient.get<IAuthResponse>(`${this.apiUrl}/auth/${this.idUser}`).pipe(take(1), catchError((err) => of(err))).subscribe((res: IAuthResponse) => {
          if (res && res.data) {
            this.apiToken = res.data;
            resolve(this.apiToken);
          } else {
            reject('Failed to retrieve auth token');
          }
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  get getApiUrl(): string {
    return this.apiUrl;
  }

  get getIdUser(): number {
    return this.idUser;
  }

  get getApiBody(): object {
    return this.bodyApi;
  }
  get getToken(): string {
    return this.apiToken;
  }
}
