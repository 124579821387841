
<mat-tab-group #tabs mat-align-tabs="center">
    <mat-tab label="{{'Financial reports' | translate}}">
        <div>
            <app-financial-reports></app-financial-reports>
        </div>
    </mat-tab>
    <mat-tab label="{{'Operational Reporting' | translate}}">
        <div>
            <app-operational-reporting></app-operational-reporting>
        </div>
    </mat-tab>
</mat-tab-group>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
