import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from '../../../../@interfaces/quotation.interface';

@Pipe({
  name: 'filterSICQuots'
})
export class FilterSICQuots implements PipeTransform {
  transform(quotaions: IQuota[], customer: string, item: string, seller: string): IQuota[] {
    return quotaions.map(quotation => ({
      ...quotation,
      venta: quotation.venta.filter(sale => sale.codigo === item)
    }))
    .filter(quotation => {
      return(
        quotation.id_cliente?.toString() === customer &&
        quotation.id_seller?.toString() === seller &&
        quotation.venta.length > 0
      )}
    )
  }
}
