<dx-chart
  id="chart"
  [dataSource]="data"
  defaultPane="bottomPane">

  <dxi-series pane="topPane" valueField="avg" [name]="nameTop">
    <dxo-label [visible]="true" [customizeText]="customizeText">
    </dxo-label>
  </dxi-series>

  <dxi-series type="bar" valueField="total" [name]="nameBot">
    <dxo-label [visible]="true" [customizeText]="customizeText">
    </dxo-label>
  </dxi-series>

  <dxo-common-series-settings
    argumentField="item"
  ></dxo-common-series-settings>

  <dxi-pane name="topPane"></dxi-pane>
  <dxi-pane name="bottomPane"></dxi-pane>

  <dxi-value-axis pane="bottomPane">
    <dxo-grid [visible]="true"></dxo-grid>
    <dxo-title [text]="nameBot"></dxo-title>
  </dxi-value-axis>

  <dxi-value-axis pane="topPane">
    <dxo-grid [visible]="true"></dxo-grid>
    <dxo-title [text]="nameTop"></dxo-title>
  </dxi-value-axis>
  
  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
  </dxo-legend>
  <dxo-export [enabled]="false"></dxo-export>

  <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip" [zIndex]="5000">
  </dxo-tooltip>
  
</dx-chart>