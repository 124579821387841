<div class="center">
    <mat-card id="invoice" class="card">
        <mat-card-header>
            <div *ngIf="companyId ==='1'" class="image-div">
                <img mat-card-image src="../../../assets/img/v1rotateBlack.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='2'" class="image-div">
                <img mat-card-image src="../../../assets/img/Ibexports.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='4'" class="image-divJE">
                <img mat-card-image src="../../../assets/img/Jens.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='5'" class="image-div">
                <img mat-card-image src="../../../assets/img/logoVersa.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='6'" class="image-div">
                <img mat-card-image src="../../../assets/img/Parabricentro.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='7'" class="image-div">
                <img mat-card-image src="../../../assets/img/v1rotateBlack.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='8'" class="image-div">
                <img mat-card-image src="../../../assets/img/Parabricentro.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="refundObject.refund_type === 'Refund'" style="padding: 25px 0px 0px 145px;">
                <div class="rectangulo">
                    <div class="center-number">
                        <table style="border:hidden">
                            <tr style="border:hidden">
                                <td>{{'Refund' | translate}} N°:</td>
                            </tr>
                            <tr>
                                <td>
                                    <h3><strong>RF - {{id_refund}}</strong></h3>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div style="padding: 25px 0px 0px 10px;">
                <div class="rectangulo" >
                    <div class="center-number">
                        <table style="border:hidden">
                            <tr style="border:hidden">
                                <td>{{'Affected Purchase' | translate}}</td>
                            </tr>
                            <tr>
                                <td>
                                    <h3><strong>{{contpurchase.written_invoice}}</strong></h3>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
           
            <button class="transparent" [useExistingCss]="true" printSectionId="invoice" ngxPrint>
                <mat-icon>print</mat-icon>
            </button>
        </mat-card-header>
        <hr class="orangeColor">
        <table class="date">
            <tr>
                <th class="centerText">{{'Date' | translate}}/{{'Due Date' | translate}}</th>
            </tr>
            <tr>
                <td style="width: 200px;">{{refundObject.createAt | date: 'short'}} </td>
            </tr>
            <tr>
                <td style="width: 200px;">{{refundObject.dueDate | date: 'short'}}</td>
            </tr>
        </table>
        <table class="customTable" *ngFor="let provider of purchaseInvoice.provider">
            <tr>
                <th class="centerText">{{'Name' | translate}}</th>
                <td style="width: 300px;">{{provider.nombre}}</td>
                <th class="centerText">{{'Email' | translate}}</th>
                <td style="width: 300px;">{{provider.email}}</td>
            </tr>
            <tr>
                <th class="centerText">{{'Address' | translate}}</th>
                <td style="width: 300px;">{{provider.direccion}}</td>
                <th class="centerText">{{'Phone' | translate}}</th>
                <td style="width: 300px;">{{provider.telefono}}</td>
            </tr>
            <tr>
                <th class="centerText">{{'Concept' | translate}}</th>
                <td colspan="3" style="width: 300px;">{{refundObject.concept}}</td>
            </tr>
        </table>
        <hr class="blackColor">
        <mat-card-content>
            <table class="table">
                <tr>
                    <th>
                        <span style="color: white !important">Item</span>
                    </th>
                    <th>
                        {{'Description' | translate}}
                    </th>
                    <th>
                        {{'Quantity' | translate}}
                    </th>
                    <th *ngIf="this.purchaseInvoice.tax_incl === 'false'" class="th">
                        {{'Price' | translate}}
                    </th>
                    <th *ngIf="this.purchaseInvoice.tax_incl === 'true'" class="th">
                        {{'Price' | translate}} + {{'Tax' | translate}}
                    </th>
                    <th>
                        {{'Subtotal' | translate}}
                    </th>
                </tr>

            <tr *ngFor="let value of values; let i = index">
                <td>
                    {{i+1}}
                </td>
                <td *ngIf="this.purchaseInvoice.tax_incl === 'true'" class="td" style="width: 530px;">
                    {{value.articulo![0].descripcion}} + {{'Tax' | translate}} ({{value.articulo![0].tax![0].value}})%
                </td>
                <td *ngIf="this.purchaseInvoice.tax_incl === 'false'" class="td" style="width: 530px;">
                    <ng-container *ngIf="value.articulo![0].tax![0].value === 0">
                        {{value.articulo![0].descripcion}} + {{'Tax' | translate}} ({{purchaseInvoice.tax}})%
                    </ng-container>
                    <ng-container *ngIf="value.articulo![0].tax![0].value !== 0">
                        {{value.articulo![0].descripcion}} + {{'Tax' | translate}} ({{value.articulo![0].tax![0].value}})%
                    </ng-container>                            
                </td>
                <td>
                    {{value.quantity}}
                </td>
                <td >
                    {{value.price}}
                </td>
                <td >
                    {{value.subtotal}}
                </td>
            </tr>
                
            </table>
            <hr class="blackColor">
            <table>
                <tr>
                    <th class="centerText">{{'Subtotal' | translate}}</th>
                    <td *ngIf="refundObject.refund_type === 'Refund'" class="centerText" style="width: 150px;">-{{subtotal | currency: 'USD'}}</td>
                </tr>
                <tr>
                    <th class="centerText">{{'Tax' | translate}}</th>
                    <td *ngIf="refundObject.refund_type === 'Refund'" class="centerText" style="width: 150px;">-{{taxCalculated | currency: 'USD'}}</td>
                </tr>
                <tr>
                    <th class="centerText">{{'Discount' | translate}}</th>
                    <td *ngIf="refundObject.refund_type === 'Refund'" class="centerText" style="width: 150px;">-{{discount | currency: 'USD'}}</td>
                </tr>
                <tr>
                    <th class="centerText">{{'Withholding Tax' | translate}}</th>
                    <td *ngIf="refundObject.refund_type === 'Refund'" class="centerText" style="width: 150px;">-{{refundObject.withholdingTax | currency: 'USD'}}</td>
                </tr>
                <tr>
                    <th class="centerText">{{'Ica Tax' | translate}}</th>
                    <td *ngIf="refundObject.refund_type === 'Refund'" class="centerText" style="width: 150px;">-{{refundObject.icaTax | currency: 'USD'}}</td>
                </tr>
                <tr>
                    <th class="centerText">{{'Total' | translate}}</th>
                    <td *ngIf="refundObject.refund_type === 'Refund'" class="centerText" style="width: 150px;">-{{total | currency: 'USD'}}</td>
                </tr>
            </table>
            <hr class="orangeColor">
            <div class="qrDiv">
                <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value"
                    cssClass="bshadow"></ngx-qrcode>
            </div>
            <p class="customP">
                <strong>THANK YOU FOR YOUR BUSINESS!</strong><br><br>

                MAKE ALL CHECKS PAYABLE TO
                V-ONEROTATE<br><br>

                8211 NW 64 ST UNIT 7
                MIAMI , FLORIDA. 33166
                Phone:+1 (786) 678<br><br>

                THE SELLER (VONE) ARE RESPONSIBLE FOR THE GOODS UNTIL THE DELIVERY
                AT THE FREIGHT FORWARDER COMPANY OR DELIVER ADDRESS IN EEUU
            </p>
        </mat-card-content>
    </mat-card>
</div>

