import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "exceldate",
})
export class ExcelDatePipe implements PipeTransform {
  transform(dateString: string): string {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 12);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (month + "/" + day + "/" + year + ":").toLocaleUpperCase();
  }
}
