import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuotationsRoutingModule } from './quotations-routing.module';
import { QuotationsComponent } from './quotations.component';
import { CardModule } from 'src/app/@shared/components/card/card.module';
import { TabsModule } from 'src/app/@shared/components/tabs/tabs.module';
import { StepperModule } from 'src/app/@shared/components/stepper/stepper.module';
import { MaterialModule } from 'src/app/material.module';
import { DialogComponent } from 'src/app/@shared/components/dialog/dialog.component';
import { DialogModule } from 'src/app/@shared/components/dialog/dialog.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchModule } from 'src/app/@shared/components/search/search.module';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [QuotationsComponent],
  imports: [
    CommonModule,
    QuotationsRoutingModule,
    CardModule,
    TabsModule,
    StepperModule,
    MaterialModule,
    DialogModule,
    ReactiveFormsModule,
    MatIconModule,
    SearchModule,
    NgxSpinnerModule,
    TranslateModule
  ]
})
export class QuotationsModule { }
