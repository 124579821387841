import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { UsersService } from "src/app/@pages/users/users.service";
import { IUser } from "src/app/@interfaces/user.interface";
import { CompanyService } from "src/app/@pages/company/company.service";
import { ICompany } from "src/app/@interfaces/company.interface";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SellersService } from "src/app/@pages/sellers/sellers.service";
import { IPrefix } from "src/app/@interfaces/prefix.interface";
import { MatSelectChange } from "@angular/material/select";
import { AlertsService } from "src/app/@shared/components/services/alerts/alerts.service";

@Component({
  selector: "app-add-company",
  templateUrl: "./add-company.component.html",
  styleUrls: ["./add-company.component.css"],
})
export class AddCompany implements OnInit, OnDestroy {
  deliveryTxt: string = "";
  @Input() id_user: number = 0;
  @Input() valuesForm: any = "";
  userModify = {} as IUser;
  companyList: Array<ICompany> = [];
  prefixList: IPrefix[] = [];
  companyForm: UntypedFormGroup = this.fb.group(
    {
      companie: ["", Validators.required],
      prefix: [""],
    }
  );
  unsubscribe$ = new Subject();
  constructor(
    private userS: UsersService,
    private companyS: CompanyService,
    private seller: SellersService,
    private fb: UntypedFormBuilder,
    private alertsService: AlertsService
  ) {}

  ngOnInit() {
    this.userS
    .getUser(this.id_user)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.userModify = result;
    });

    this.companyS
    .getAllCompanies()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.companyList = result;
    });
  }

  onOptionSelected(event: MatSelectChange) {
    const value = event.value;
    if (value.internal_electronic_invoicing === "true" && value.countries[0].name === "Colombia") {
      this.companyForm.get('prefix')?.setValidators(Validators.required);
      this.companyForm.get('prefix')?.updateValueAndValidity();
      this.prefixList = value.prefixes;
    }else {
      this.companyForm.get('prefix')?.clearValidators();
      this.companyForm.get('prefix')?.updateValueAndValidity();
      this.prefixList = [];
    }
  }

  update() {
    this.userS
    .addCompanies(
      parseInt(this.id_user.toString()),
      parseInt(this.companyForm.value.companie.Id_company),
      parseInt(this.companyForm.value.prefix.id)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        this.alertsService.showSuccessfullAlert("Added company")
        .then((result) => {
          if (result.isConfirmed) {
            if (this.valuesForm.rol.name === "Invoicing operator") {
              this.registerSeller(this.id_user, this.valuesForm, this.companyForm.value.companie);
            }
            this.alertsService.refresh("/dialog", "/user");
          }
        });
      } else {
        this.alertsService.showErrorAlert("Something was wrong");
      }
    });
  }

  remove(id_user: number, id_company: number) {
    this.userS
    .removeCompanies(
      parseInt(id_user.toString()),
      parseInt(id_company.toString())
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        this.alertsService.showErrorAlert("Something was wrong");
      } else {
        this.alertsService.showSuccessfullAlert("Company removed from this user")
        .then((result) => {
          if (result.isConfirmed) {
            this.alertsService.refresh("/dialog", "/user");
          }
        });
      }
    });
  }

  registerSeller(id_user: number, registerForm: any, newCompany: any) {
    this.seller
    .registerSellersByUser(
      registerForm.document.toString(),
      registerForm.types_docs.id_docs,
      registerForm.name,
      registerForm.email,
      parseInt(newCompany.countries[0].id_country),
      newCompany.Id_company
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.nombre !== "sellerExist") {
        this.alertsService.showSuccessfullAlert("Seller created successfully")
        .then((data) => {
          if (data.isConfirmed) {
            this.registerUserSeller(
              parseInt(id_user.toString()),
              parseInt(result.id_seller),
              newCompany.Id_company
            );
          }
        });
      } else if (result.nombre === "sellerExist") {
        this.alertsService.showWarningAlert("This Seller already exist")
      } else {
        this.alertsService.showErrorAlert("Something was wrong")
      }
    });
  }

  registerUserSeller(id_user: number, id_seller: number, company: string) {
    this.seller
    .registerUserSeller(id_user, id_seller, company)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.nombre !== "UserSellerExist") {
        this.alertsService.showSuccessfullAlert("Related user and seller successfully");
      } else if (result.nombre === "UserSellerExist") {
        this.alertsService.showWarningAlert("Related user and seller was not created");
      } else {
        this.alertsService.showErrorAlert("Something was wrong");
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
