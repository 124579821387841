import { ApiService } from "../../@graphql/services/api.service";
import { Injectable } from "@angular/core";
import {
  GET_CITIES_BYCOMPANY,
  GET_ACTIVITIES_BYCITY,
  GET_SINGLE_ACTIVITY,
  GET_ACTIVITY,
} from "../../@graphql/operations/query";
import { map } from "rxjs/internal/operators/map";
import {
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
} from "../../@graphql/operations/mutation";

@Injectable({
  providedIn: "root",
})
export class TributaryService extends ApiService {
  getSingleActivity(id_activity: number) {
    return this.query(GET_SINGLE_ACTIVITY, { id_activity: id_activity }).pipe(
      map((result: any) => {
        return result.getSingleActivity;
      })
    );
  }

  getCitiesByCompany(id_country: number) {
    return this.query(GET_CITIES_BYCOMPANY, { id_country: id_country }).pipe(
      map((result: any) => {
        return result.getCitiesByCountry;
      })
    );
  }

  getActivitiesByCity(id_city: number) {
    return this.query(GET_ACTIVITIES_BYCITY, { id_city: id_city }).pipe(
      map((result: any) => {
        return result.getActivitiesByCity;
      })
    );
  }

  getActivity(activity_code: string, city: string) {
    return this.query(GET_ACTIVITY, {
      activity_code: activity_code,
      city: city,
    }).pipe(
      map((result: any) => {
        return result.getActivity;
      })
    );
  }

  createActivity(
    id_city: number,
    ica_code: string,
    activity_code: string,
    activity_description: string,
    actual_rate: number
  ) {
    return this.mutation(CREATE_ACTIVITY, {
      id_city: id_city,
      ica_code: ica_code,
      activity_code: activity_code,
      activity_description: activity_description,
      actual_rate: actual_rate,
    }).pipe(
      map((result: any) => {
        return result.createActivity;
      })
    );
  }

  modifyActivity(
    id_activity: number,
    id_city: number,
    ica_code: string,
    activity_code: string,
    activity_description: string,
    actual_rate: number
  ) {
    return this.mutation(UPDATE_ACTIVITY, {
      id_activity: id_activity,
      id_city: id_city,
      ica_code: ica_code,
      activity_code: activity_code,
      activity_description: activity_description,
      actual_rate: actual_rate,
    }).pipe(
      map((result: any) => {
        return result.updateActivity;
      })
    );
  }
}
