<div class="container">
    <section id="formHolder">
 
       <div class="row">
 
          <!-- Brand Box -->
          <div class="col-sm-6 brand">
 
             <div class="heading">
                <h2>{{'Hello' | translate}}!</h2>
                <br>
                <p>{{'Create your account or login to get started' | translate}}!</p>
             </div>
 
             <div class="success-msg">
                <p>{{'Great! You are one of our members now' | translate}}</p>
                <a href="#" class="profile">{{'Back' | translate}}</a>
             </div>
          </div>
 
 
          <!-- Form Box -->
          <div class="col-sm-6 form">
 
             <!-- Login Form -->
             <div class="login form-peice">
                <form class="login-form" action="#" method="post" [formGroup]="loginForm">
                   <div class="form-group">
                      <label for="loginemail">{{'Email Adderss' | translate}}</label>
                      <input type="text" formControlName="email">
                    </div>
 
                   <div class="form-group">
                      <label for="loginPassword">{{'Password' | translate}}</label>
                      <input type="password" formControlName="password">
                    </div>
 
                    <span class="form-text text-danger">
                      {{loginError}}
                    </span>   
                   <div class="CTA">
                      <input style="margin-bottom: 10px;" (click)="login()" type="submit" value="{{'Login' | translate}}">
                      <a (click)="switch()" class="switch">{{'Register' | translate}}</a>&nbsp;&nbsp;
                      <a (click)="resetPass()" class="FG">{{'Forgot password' | translate}}?</a>
                   </div>

                </form>
             </div><!-- End Login Form -->
 
 
             <!-- Signup Form -->
             <div class="signup form-peice switched">
                <form [formGroup]="registerForm" class="signup-form" action="#" method="post">
 
                   <div class="form-group">
                      <label for="name">{{'Full Name' | translate}}</label>
                      <input type="text" formControlName="name" autocomplete="no">
                      <span class="form-text text-danger" *ngIf="textValid('name')">{{'You must type only a name and a lastname' | translate}}</span>
                    </div>
 
                   <div class="form-group">
                      <label for="email">{{'Email Adderss' | translate}}</label>
                      <input type="text" formControlName="email" autocomplete="no">
                      <span class="form-text text-danger" *ngIf="textValid('email')">{{'You must type a valid email' | translate}}</span>
                    </div>

                    <div class="form-group">
                     <label for="types_docs">{{'Document Type' | translate}}</label><br><br><br>
                     <select class="form-control" formControlName="types_docs">
                        <option selected value="">{{'Select Type' | translate}}</option>
                        <option *ngFor="let types of documenTypeList" [ngValue]="types">
                            {{types.description | translate}}
                        </option>
                    </select>
                     <span class="form-text text-danger" *ngIf="textValid('types_docs')">{{'You must select a type' | translate}}</span>
                   </div>

                   <div class="form-group">
                        <label for="document">{{'Document' | translate}}</label>
                        <input type="text" formControlName="document" maxlength="12" autocomplete="no">
                        <span class="form-text text-danger" *ngIf="textValid('document')">{{'You must type only numbers' | translate}}</span>
                    </div>
 
                   <div class="form-group">
                      <label for="user">{{'Username' | translate}}</label>
                      <input type="text" formControlName="username" autocomplete="no">
                      <span class="form-text text-danger" *ngIf="textValid('username')">{{'User Invalid' | translate}}</span>
                    </div>
 
                   <div class="form-group">
                      <label for="password">{{'Password' | translate}}&nbsp;&nbsp;<a matTooltip="{{'Password must have uppercase letters, lowercase letters, numbers, special character' | translate}} ($@$!%*?&+#)">?</a></label>
                      <input type="password" formControlName="password" autocomplete="no">
                      <span class="form-text text-danger" *ngIf="textValid('password')">{{'Password invalid' | translate}}</span>
                   </div>
 
                   <div class="form-group">
                      <label for="passwordCon">{{'Confirm Password' | translate}}</label>
                      <input type="password" formControlName="passwordConfirmation" autocomplete="no">
                      <span class="form-text text-danger" *ngIf="registerForm.controls.passwordConfirmation.errors">{{'Passwords must be equals' | translate}}</span>
                    </div>

                   <div class="CTA">
                     <span class="form-text text-danger">
                        {{signUpError}}
                     </span>   
                      <input style="margin-bottom: 10px;" (click)="register()" type="submit" value="{{'Register' | translate}}" id="submit">
                      <a (click)="switch()" class="switch">{{'Login' | translate}}</a>
                   </div>

                </form>
             </div><!-- End Signup Form -->
          </div>
       </div>
    </section>
 
    <footer>
       <p>
        {{'Thank you for using our web application' | translate}}.
       </p>
    </footer>
 
 </div>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
 




