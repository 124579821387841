import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from '../../../../@interfaces/quotation.interface';

@Pipe({
  name: 'filterCIQuots'
})
export class FilterCIQuots implements PipeTransform {
  transform(quotaions: IQuota[], customer: string, item: string): IQuota[] {
    return quotaions.map(quotation => ({
      ...quotation,
      venta: quotation.venta.filter(sale => sale.codigo === item)
    }))
    .filter(quotation => {
      return(
        quotation.id_cliente?.toString() === customer &&
        quotation.venta.length > 0
      )
    })
  }
}
