
<div style="width: 100%; height: 100%;" *ngIf="typeGraph === '1'">
    <div style="width: 100%; height: 100%;" #divGrafica>
        <app-bar-chart [barChartLabels]="dataTopSalesLabel" [barChartSingleData]="dataTopSales" [barChartData]="barChartDataSets3"></app-bar-chart>
    </div>
</div>
<div style="width: 100%; height: 100%;" *ngIf="typeGraph === '2'">
    <div style="width: 100%; height: 100%;" #divGrafica>
        <app-pie [pieChartLabels]="dataTopSalesLabel" [pieChartData]="multidataSales"></app-pie>
    </div>
</div>

    
