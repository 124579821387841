import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from '../../../../@interfaces/quotation.interface';

@Pipe({
  name: 'filterCDQuots'
})
export class FilterCDQuots implements PipeTransform {
  transform(quotaions: IQuota[], date1: Date, date2: Date, customer: string): IQuota[] {
    return quotaions.filter((quotation) => { 
      if (quotation.invoiced_date) {
        return(
          quotation.id_cliente?.toString() === customer &&
          (new Date(quotation.invoiced_date!) >= date1 &&
          new Date(quotation.invoiced_date!) <= date2) 
        )
      }else {
        return(
          quotation.id_cliente?.toString() === customer &&
          (new Date(quotation.createdAt) >= date1 &&
          new Date(quotation.createdAt) <= date2)
        )
      }
    })
  }
}
