import { Pipe, PipeTransform } from '@angular/core';
import { IQuota } from 'src/app/@interfaces/quotation.interface';

@Pipe({
  name: 'validPendingInvoicePipe'
})
export class ValidPendingInvoicePipe implements PipeTransform {
  transform(quotations: IQuota[]): IQuota[] {
    if (!quotations) {
      return quotations;
    }
    return quotations.filter(quotations =>
      quotations.billyInvoice![0].invoice_number?.toString() !== "PENDING")
  }
}
