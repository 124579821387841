import { Pipe, PipeTransform } from '@angular/core';
import { IResultProducts } from 'src/app/@interfaces/csv/csv.interface';

@Pipe({
  name: 'filterArticleCsv'
})
export class FilterArticleCsvPipe implements PipeTransform {

  transform(article: IResultProducts[], searchValue: string): IResultProducts[] {
    return article.filter(article =>
        article.cod_barras!.toString().toLocaleLowerCase()===(searchValue.toLocaleLowerCase()))
  }

}
