<div class="center">
    <mat-card id="invoice" class="card">
        <mat-card-header>
            <div *ngIf="companyId ==='1'" class="image-div">
                <img mat-card-image src="../../../assets/img/v1rotateBlack.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='2'" class="image-div">
                <img mat-card-image src="../../../assets/img/Ibexports.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='4'" class="image-divJE">
                <img mat-card-image src="../../../assets/img/Jens.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='5'" class="image-div">
                <img mat-card-image src="../../../assets/img/logoVersa.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='6'" class="image-div">
                <img mat-card-image src="../../../assets/img/Parabricentro.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='7'" class="image-div">
                <img mat-card-image src="../../../assets/img/v1rotateBlack.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="companyId ==='8'" class="image-div">
                <img mat-card-image src="../../../assets/img/Parabricentro.png"
                    onerror="this.src='assets/img/cantfindimage.jpg'">
            </div>

            <div *ngIf="noteObject.tipo_nota === 'Credito'" style="padding: 25px 0px 0px 145px;">
                <div class="rectangulo">
                    <div class="center-number">
                        <table style="border:hidden">
                            <tr style="border:hidden">
                                <td>{{'Credit Note' | translate}} N°:</td>
                            </tr>
                            <tr>
                                <td>
                                    <h3><strong>CN - {{id_notA}}</strong></h3>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div *ngIf="noteObject.tipo_nota === 'Debito'" style="padding: 25px 0px 0px 145px;">
                <div class="rectangulo">
                    <div class="center-number">
                        <table style="border:hidden">
                            <tr style="border:hidden">
                                <td>{{'Debit Note' | translate}} N°:</td>
                            </tr>
                            <tr>
                                <td>
                                    <h3><strong>DN - {{id_notA}}</strong></h3>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

        <div style="padding: 25px 0px 0px 10px;">
            <div class="rectangulo" >
                <div class="center-number">
                    <table style="border:hidden">
                        <tr style="border:hidden">
                            <td>{{'Affected Invoice' | translate}}</td>
                        </tr>
                        <tr>
                            <td>
                                <h3><strong>{{contFact.invoice}}</strong></h3>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
           
            <button class="transparent" [useExistingCss]="true" printSectionId="invoice" ngxPrint>
                <mat-icon>print</mat-icon>
            </button>
        </mat-card-header>
        <hr class="orangeColor">
        <table class="date">
            <tr>
                <th class="centerText">{{'Date' | translate}}/{{'Due Date' | translate}}</th>
            </tr>
            <tr>
                <td style="width: 200px;">{{noteObject.createAt | date: 'short'}} </td>
            </tr>
            <tr>
                <td style="width: 200px;">{{noteObject.dueDate | date: 'short'}}</td>
            </tr>
        </table>
        <table class="customTable" *ngFor="let customer of quotations.cliente">
            <tr>
                <th class="centerText">{{'Name' | translate}}</th>
                <td style="width: 300px;">{{customer.nombre}}</td>
                <th class="centerText">{{'Email' | translate}}</th>
                <td style="width: 300px;">{{customer.email}}</td>
            </tr>
            <tr>
                <th class="centerText">{{'Address' | translate}}</th>
                <td style="width: 300px;">{{customer.direccion}}</td>
                <th class="centerText">{{'Phone' | translate}}</th>
                <td style="width: 300px;">{{customer.telefono}}</td>
            </tr>
            <tr>
                <th class="centerText">{{'Concept' | translate}}</th>
                <td colspan="3" style="width: 300px;">{{noteObject.concepto | translate}}</td>
            </tr>
        </table>
        <hr class="blackColor">
        <mat-card-content>
            <table class="table">
                <tr>
                    <th>
                        <span style="color: white !important">Item</span>
                    </th>
                    <th>
                        {{'Description' | translate}}
                    </th>
                    <th>
                        {{'Quantity' | translate}}
                    </th>
                    <th *ngIf="this.quotations.tax_incl === 'false'" class="th">
                        {{'Price' | translate}}
                    </th>
                    <th *ngIf="this.quotations.tax_incl === 'true'" class="th">
                        {{'Price' | translate}} + {{'Tax' | translate}}
                    </th>
                    <th>
                        {{'Subtotal' | translate}}
                    </th>
                </tr>

            <tr *ngFor="let valor of values; let i = index">
                <td>
                    {{i+1}}
                </td>
                <td *ngIf="this.quotations.tax_incl === 'true'" class="td" style="width: 530px;">
                    {{valor.articulo[0].descripcion}} + {{'Tax' | translate}} ({{valor.articulo[0].tax![0].value}})%
                </td>
                <td *ngIf="this.quotations.tax_incl === 'false'" class="td" style="width: 530px;">
                    <ng-container *ngIf="valor.articulo[0].tax![0].value === 0">
                        {{valor.articulo[0].descripcion}} + {{'Tax' | translate}} ({{quotations.tax}})%
                    </ng-container>
                    <ng-container *ngIf="valor.articulo[0].tax![0].value !== 0">
                        {{valor.articulo[0].descripcion}} + {{'Tax' | translate}} ({{valor.articulo[0].tax![0].value}})%
                    </ng-container>                            
                </td>
                <td>
                    {{valor.cantidad}}
                </td>
                <td >
                    {{valor.precio}}
                </td>
                <td >
                    {{valor.subtotal}}
                </td>
            </tr>
                
            </table>
            <hr class="blackColor">
            <table>
                <tr>
                    <th class="centerText">{{'Subtotal' | translate}}</th>
                    <td *ngIf="noteObject.tipo_nota === 'Credito'" class="centerText" style="width: 150px;">-{{subtotal | currency: 'USD'}}</td>
                    <td *ngIf="noteObject.tipo_nota === 'Debito'" class="centerText" style="width: 150px;">{{subtotal | currency: 'USD'}}</td>
                </tr>
                <tr>
                    <th class="centerText">{{'Tax' | translate}}</th>
                    <td *ngIf="noteObject.tipo_nota === 'Credito'" class="centerText" style="width: 150px;">-{{taxCalc | currency: 'USD'}}</td>
                    <td *ngIf="noteObject.tipo_nota === 'Debito'" class="centerText" style="width: 150px;">{{taxCalc | currency: 'USD'}}</td>
                </tr>
                <tr>
                    <th class="centerText">{{'Discount' | translate}}</th>
                    <td *ngIf="noteObject.tipo_nota === 'Credito'" class="centerText" style="width: 150px;">-{{discount | currency: 'USD'}}</td>
                    <td *ngIf="noteObject.tipo_nota === 'Debito'" class="centerText" style="width: 150px;">{{discount | currency: 'USD'}}</td>
                </tr>
                <tr>
                    <th class="centerText">{{'Total' | translate}}</th>
                    <td *ngIf="noteObject.tipo_nota === 'Credito'" class="centerText" style="width: 150px;">-{{total | currency: 'USD'}}</td>
                    <td *ngIf="noteObject.tipo_nota === 'Debito'" class="centerText" style="width: 150px;">{{total | currency: 'USD'}}</td>
                </tr>
            </table>
            <hr class="orangeColor">
            <div class="qrDiv">
                <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value"
                    cssClass="bshadow"></ngx-qrcode>
            </div>
            <p class="customP">
                <strong>THANK YOU FOR YOUR BUSINESS!</strong><br><br>

                MAKE ALL CHECKS PAYABLE TO
                V-ONEROTATE<br><br>

                8211 NW 64 ST UNIT 7
                MIAMI , FLORIDA. 33166
                Phone:+1 (786) 678<br><br>

                THE SELLER (VONE) ARE RESPONSIBLE FOR THE GOODS UNTIL THE DELIVERY
                AT THE FREIGHT FORWARDER COMPANY OR DELIVER ADDRESS IN EEUU
            </p>
        </mat-card-content>
    </mat-card>
</div>

