export const billyErrors = [
  {
    "status": "400",
    "errors": [
      {
        "title": "Bad Request",
        "detail": "The request is incorrect"
      },
      {
        "title": "Bad Request",
        "detail": "The format is invalid"
      },
      {
        "title": "Bad Request",
        "detail": "The data is incomplete"
      },
      {
        "title": "Bad Request",
        "detail": "Invoice id is invalid"
      },
      {
        "title": "Bad Request",
        "detail": "The body is incorrect"
      },
      {
        "title": "Bad Request",
        "detail": "The cufe is required"
      },
      {
        "title": "Bad Request",
        "detail": "Query parameter `cufe` is required"
      },
      {
        "title": "Bad Request",
        "detail": "The invoice has already been canceled"
      },
      {
        "title": "Bad Request",
        "detail": "The quantity of items to be returned is invalid."
      },
      {
        "title": "Bad Request",
        "detail": "Invoice number is invalid"
      },
      {
        "title": "Bad Request",
        "detail": "Institution id is invalid"
      },
      {
        "title": "Bad Request",
        "detail": "The query parameter to search is incorrect"
      },
      {
        "title": "Bad Request",
        "detail": "RUT file (pdf) is required."
      },
      {
        "title": "Bad Request",
        "detail": "Verification digit doesn't match"
      },
      {
        "title": "Bad Request",
        "detail": "Email not allowed: Bounce"
      },
      {
        "title": "Bad Request",
        "detail": "idType must be 31 (NIT) or 50 (NITE). Sent: 2"
      },
      {
        "title": "Bad Request",
        "detail": "The city does not exist"
      },
      {
        "title": "Bad Request",
        "detail": "An office already exists in this city"
      },
      {
        "title": "Bad Request",
        "detail": "Headquarter already exists"
      },
      {
        "title": "Bad Request",
        "detail": "Verification digit doesn't match with document number"
      },
      {
        "title": "Bad Request",
        "detail": "idType is not valid"
      },
      {
        "title": "Bad Request",
        "detail": "The idNumber is already registered"
      },
      {
        "title": "Not Found",
        "detail": "Tax id not found."
      },
      {
        "title": "Bad Request",
        "detail": "name is required"
      }
    ]
  },
  {
    "status": "401",
    "errors": [
      {
        "title": "Unauthorized",
        "detail": "Unauthorized to access invoice in Radian"
      },
    ]
  },
  {
    "status": "403",
    "errors": [
      {
        "title": "Forbidden",
        "detail": "Access is prohibited"
      }
    ]
  },
  {
    "status": "404",
    "errors": [
      {
        "title": "Not Found",
        "detail": "The requested resource was not found"
      },
      {
        "title": "Not Found",
        "detail": "The requested article is not available"
      },
      {
        "title": "Not Found",
        "detail": "The requested client was not found"
      },
      {
        "title": "Not Found",
        "detail": "The requested invoice was not found"
      },
      {
        "title": "Not Found",
        "detail": "Cufe does not exist in the DIAN"
      },
      {
        "title": "Not Found",
        "detail": "Invoice id not found"
      },
      {
        "title": "Not Found",
        "detail": "Invoice not found"
      },
      {
        "title": "Not Found",
        "detail": "The item was not found on invoice."
      },
      {
        "title": "Not Found",
        "detail": "Invoice or market not found"
      },
      {
        "title": "Not Found",
        "detail": "Institution/Person not found"
      },
      {
        "title": "Not Found",
        "detail": "Item id not found"
      }
    ]
  },
  {
    "status": "409",
    "errors": [
      {
        "title": "Conflict",
        "detail": "Invoice with invoiceNumber SETT15166 already exists"
      }
    ]
  },
  {
    "status": "500",
    "errors": [
      {
        "title": "Internal Server Error",
        "detail": "Internal server error"
      },
      {
        "title": "Internal Server Error",
        "detail": "Internal server error processing client request"
      },
      {
        "title": "Internal Server Error",
        "detail": "Internal server error processing article request"
      }
    ]
  },
  {
    "status": "415",
    "errors": [
      {
        "title": "Unsupported Media Type",
        "detail": "type image not supported, only application/pdf"
      }
    ]
  }
]
