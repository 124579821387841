import { Pipe, PipeTransform } from '@angular/core';
import { IResultProducts } from 'src/app/@interfaces/csv/csv.interface';

@Pipe({
  name: 'filterCustomerAmountCsv'
})
export class FilterCustomerCsvPipeAmount implements PipeTransform {

  transform(customer: IResultProducts[], searchValue: string): IResultProducts[] {
    return customer.filter(customer =>
      customer.nit!.toString().toLocaleLowerCase()===(searchValue.toLocaleLowerCase()))
  }

}
